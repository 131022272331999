import { createSlice } from "@reduxjs/toolkit";
import { post, get, remove, put } from "../../../utils/app-utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../constants/index";
import { ROLE_TYPES } from "../../../constants/index";
import { getUserByCompetency } from "../Roster/rosterSlice";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";
const { isSpoc, isAdmin } = ROLE_TYPES;

const initialState = {
  loading: false,
  isFilterApplied: false,
  reportsConfig: {
    weekType: "ALL",
    competencyId: "",
  },
  pageCount: 0,
  totalCount: 0,
  userList: [],
  distributionListData: [],
  dlDataForConfig: {},
  allUserEmails: [],
  userListLoading: false,
  allExistingDls: [],
  managerInfo: [],
  isLoadingHrManager: false,
  adminAllUserAddRoleData: {},
};

export const createRole = createAsyncThunk(
  "createRole",
  async ({
    roleType,
    competencyId = [],
    userId,
    roleId,
    isManager = false,
  }) => {
    try {
      const competencyIdArray = competencyId?.map((item) => item.value);
      const url = `${BASE_URL}/admin/createRole`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { roleType, competencyId: competencyIdArray, userId, isManager }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deleteManagerRole = createAsyncThunk(
  "deleteManagerRole",
  async ({
    userId,
    roleId,
    role,
    pageNumber,
    competencyId,
    roleCompetencyIds,
    designationIds,
    query,
    dispatch,
    roleType,
  }) => {
    try {
      const response = await put(`${BASE_URL}/admin/manager/${userId}`, {
        queryParams: { role_id: roleId },
      });
      if (isAdmin(role)) {
        dispatch(
          fetchActiveHrSpoc({
            roleId,
            pageNumber,
            competencyId,
            roleCompetencyIds,
            designationIds,
            query,
            roleType,
          })
        );
      }
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deleteRole = createAsyncThunk(
  "deleteRole",
  async ({
    userRoleId,
    roleId,
    role,
    pageNumber,
    userByCompetencyPayload,
    competencyId,
    roleCompetencyIds,
    designationIds,
    query,
    keyvalue,
    roleType,
    dispatch,
  }) => {
    try {
      const response = await remove(`${BASE_URL}/admin/role/${userRoleId}`, {
        queryParams: { role_id: roleId },
      });
      if (isSpoc(role)) {
        dispatch(getUserByCompetency(userByCompetencyPayload));
      }
      if (isAdmin(role)) {
        dispatch(
          fetchActiveHrSpoc({
            roleId,
            pageNumber,
            competencyId,
            roleCompetencyIds,
            designationIds,
            query,
            roleType,
          })
        );
      }
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchActiveHrSpoc = createAsyncThunk(
  "fetchActiveHrSpoc",
  async ({
    pageNumber,
    roleId,
    competencyId,
    roleCompetencyIds,
    designationIds,
    query,
    signal,
    sortType,
    sortOrder,
    roleType,
  }) => {
    try {
      const designationIdsArray = designationIds?.map((item) => item.value);
      const competencyIdsArray =
        roleCompetencyIds.length > 0
          ? roleCompetencyIds?.map((item) => item.value)
          : [];
      const url = `${BASE_URL}/admin/role/active`;
      const response = await get(
        url,
        {
          queryParams: {
            pageNumber,
            role_id: roleId,
            roleCompetencyIds: competencyIdsArray,
            competencyId,
            designationIds: designationIdsArray,
            searchQuery: query,
            sortType,
            sortOrder,
            roleType,
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchAllDistributionList = createAsyncThunk(
  "fetchAllDistributionList",
  async ({ roleId, pageNumber }) => {
    try {
      const response = await get(`${BASE_URL}/admin/getDlEmailList`, {
        queryParams: { role_id: roleId, pageNumber },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const saveDistributionList = createAsyncThunk(
  "saveDistributionList",
  async ({ roleId, dlName, dlEmail, dlDescription, pageNumber, dispatch }) => {
    try {
      const response = await post(`${BASE_URL}/admin/createDlEmail`, {
        queryParams: {
          role_id: roleId,
          dlName,
          dlEmail,
          dlDescription,
        },
      });
      dispatch(fetchAllDistributionList({ roleId, pageNumber }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const editDistributionListById = createAsyncThunk(
  "editDistributionListById",
  async ({
    roleId,
    dlName,
    dlEmail,
    dlDescription,
    pageNumber,
    reportType,
    dlId,
    dispatch,
  }) => {
    try {
      const response = await put(`${BASE_URL}/admin/updateDlEmail/${dlId}`, {
        queryParams: {
          role_id: roleId,
          dlName,
          dlEmail,
          dlDescription,
          reportType,
        },
      });
      dispatch(fetchAllDistributionList({ roleId, pageNumber }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deleteDistributionListById = createAsyncThunk(
  "deleteDistributionListById",
  async ({ dlId, roleId, pageNumber, dispatch }) => {
    try {
      const response = await remove(`${BASE_URL}/admin/removeDlEmail/${dlId}`, {
        queryParams: {
          role_id: roleId,
        },
      });
      dispatch(fetchAllDistributionList({ roleId, pageNumber }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const configFetchAllDlListForAdmin = createAsyncThunk(
  "configFetchAllDlListForAdmin",
  async ({ roleId }) => {
    try {
      const response = await get(`${BASE_URL}/admin/allDlEmailList`, {
        queryParams: {
          role_id: roleId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const configFetchAllUserListForAdmin = createAsyncThunk(
  "configFetchAllUserListForAdmin",
  async ({ roleId, competencyId }) => {
    try {
      const response = await get(`${BASE_URL}/admin/users`, {
        queryParams: { role_id: roleId, competencyId: competencyId },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const configFetchDlAdminList = createAsyncThunk(
  "configFetchDlAdminList",
  async ({ roleId, pageNumber, reportType, competencyId }) => {
    try {
      const response = await get(`${BASE_URL}/admin/getReportDlConfigs`, {
        queryParams: { role_id: roleId, pageNumber, reportType, competencyId },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const configSaveDistributionList = createAsyncThunk(
  "configSaveDistributionList",
  async ({
    roleId,
    pageNumber,
    ttnMailingListIds = [],
    reportType,
    competencyId,
    checkWeekType,
    competencyIdFilter,
    userDetailsIds,
    dispatch,
  }) => {
    try {
      const ttnMailingListIdsArray = ttnMailingListIds?.map(
        (item) => item.value
      );
      const userDetailsIdsArray = userDetailsIds?.map((item) => item.value);
      const response = await post(
        `${BASE_URL}/admin/createReportDlConfig`,
        {
          queryParams: {
            role_id: roleId,
          },
        },
        {
          ttnMailingListIds: ttnMailingListIdsArray,
          reportType,
          competencyId,
          userDetailsIds: userDetailsIdsArray,
        }
      );
      dispatch(
        configFetchDlAdminList({
          roleId,
          reportType: checkWeekType,
          pageNumber,
          competencyId: competencyIdFilter,
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const configEditDistributionListById = createAsyncThunk(
  "configEditDistributionListById",
  async ({
    configId,
    roleId,
    ttnMailingListIds = [],
    checkWeekType,
    pageNumber,
    competencyIdFilter,
    userDetailsIds,
    dispatch,
  }) => {
    try {
      const ttnMailingListIdsArray = ttnMailingListIds?.map(
        (item) => item.value
      );
      const userDetailsIdsArray = userDetailsIds?.map((item) => item.value);
      const response = await put(
        `${BASE_URL}/admin/updateReportDlConfig/${configId}`,
        {
          queryParams: {
            role_id: roleId,
          },
        },
        {
          ttnMailingListIds: ttnMailingListIdsArray,
          userDetailsIds: userDetailsIdsArray,
        }
      );
      dispatch(
        configFetchDlAdminList({
          roleId,
          reportType: checkWeekType,
          pageNumber,
          competencyId: competencyIdFilter,
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const configDeleteDistributionListById = createAsyncThunk(
  "configDeleteDistributionListById",
  async ({
    configId,
    roleId,
    pageNumber,
    reportType,
    competencyIdFilter,
    dispatch,
  }) => {
    try {
      const response = await remove(
        `${BASE_URL}/admin/removeReportDlConfig/${configId}`,
        { queryParams: { role_id: roleId } },
        null
      );
      dispatch(
        configFetchDlAdminList({
          roleId,
          reportType,
          pageNumber,
          competencyId: competencyIdFilter,
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getIsManagerForCurrentUser = createAsyncThunk(
  "getIsManagerForCurrentUser",
  async ({ roleId, userId }) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/getIsManagerForCurrentUser`,
        {
          queryParams: {
            role_id: roleId,
            userId,
          },
        },
        {}
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getUserListForAdminAddRole = createAsyncThunk(
  "getUserListForAdminAddRole",
  async ({
    competencyId,
    designationIds,
    roleId,
    pageNumber,
    sortType,
    sortOrder,
    signal,
    gender,
    query,
  }) => {
    try {
      const designationIdsArray = designationIds?.map((item) => item.value);
      const response = await get(
        `${BASE_URL}/admin/all/users`,
        {
          queryParams: {
            competencyId,
            designationIds: designationIdsArray,
            pageNumber,
            sortType,
            sortOrder,
            role_id: roleId,
            query,
            gender,
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const adminSlice = createSlice({
  name: "Admin",
  initialState,
  reducers: {
    resetAdminState() {
      return {
        ...initialState,
      };
    },
    applyFilterStatus(state, action) {
      return {
        ...state,
        isFilterApplied: true,
      };
    },
    resetAdminFilterStatus(state) {
      return {
        ...state,
        isFilterApplied: false,
        reportsConfig: {
          ...state.reportsConfig,
          weekType: "ALL",
          competencyId: "",
        },
      };
    },
    changeDriveReport(state, action) {
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          weekType: action.payload,
        },
      };
    },
    changeConfigCompetencyStatus(state, action) {
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          competencyId: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(createRole.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while creating role"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message || "Removed from panel"}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="User role deletion failed"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteManagerRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteManagerRole.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message || "Removed from Manager"}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteManagerRole.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Manager deletion failed"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(fetchActiveHrSpoc.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActiveHrSpoc.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          pageCount: action.payload.pageCount,
          userList: action.payload.userList,
          totalCount: action.payload.totalUserRecordsCount,
        };
      })
      .addCase(fetchActiveHrSpoc.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchAllDistributionList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDistributionList.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          distributionListData: action.payload.data,
        };
      })
      .addCase(fetchAllDistributionList.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching DL !"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(saveDistributionList.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveDistributionList.fulfilled, (state, action) => {
        if (action?.payload?.code === 404) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={action?.payload?.message || "Error Saving DL"}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        } else {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={"DL is successfully saved"}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
        }
        state.loading = false;
      })
      .addCase(saveDistributionList.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while saving distribution list."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(editDistributionListById.pending, (state) => {
        state.loading = true;
      })
      .addCase(editDistributionListById.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={
              action?.payload?.data?.message || "DL is successfully edited"
            }
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(editDistributionListById.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while editing distribution list."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteDistributionListById.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDistributionListById.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message || "Removed from DL"}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteDistributionListById.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error in deleting DL!"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(configFetchAllDlListForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(configFetchAllDlListForAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            allExistingDls: action.payload?.dlDetails,
          };
        }
        return { ...state, loading: false };
      })
      .addCase(configFetchAllDlListForAdmin.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching config DL !"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(configFetchAllUserListForAdmin.pending, (state) => {
        state.userListLoading = true;
      })
      .addCase(configFetchAllUserListForAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            allUserEmails: action.payload.data,
            userListLoading: false,
          };
        }
        return { ...state, userListLoading: false };
      })
      .addCase(configFetchAllUserListForAdmin.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching user competency wise !"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.userListLoading = false;
      })
      .addCase(configFetchDlAdminList.pending, (state) => {
        state.loading = true;
      })
      .addCase(configFetchDlAdminList.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            dlDataForConfig: action.payload.data,
            loading: false,
          };
        }
        return { ...state, loading: false };
      })
      .addCase(configFetchDlAdminList.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching all existing DL !"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(configSaveDistributionList.pending, (state) => {
        state.loading = true;
      })
      .addCase(configSaveDistributionList.fulfilled, (state, action) => {
        if (action?.payload?.code === 404) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={action?.payload?.message || "Error Saving Config DL"}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        } else {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={
                action?.payload?.data?.message ||
                "Config DL is successfully saved"
              }
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
        }
        state.loading = false;
      })
      .addCase(configSaveDistributionList.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while saving config distribution list"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(configEditDistributionListById.pending, (state) => {
        state.loading = true;
      })
      .addCase(configEditDistributionListById.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={
                action?.payload?.data?.message ||
                "DL config is successfully edited"
              }
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            loading: false,
          };
        }
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(configEditDistributionListById.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while editing config distribution list"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(configDeleteDistributionListById.pending, (state) => {
        state.loading = true;
      })
      .addCase(configDeleteDistributionListById.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={
              action?.payload?.data?.message ||
              "DL config is successfully deleted"
            }
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(configDeleteDistributionListById.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={"Error in deleting config DL!"}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(getIsManagerForCurrentUser.pending, (state) => {
        state.isLoadingHrManager = true;
      })
      .addCase(getIsManagerForCurrentUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.managerInfo = action.payload?.isManager;
          state.isLoadingHrManager = false;
        }
        state.isLoadingHrManager = false;
      })
      .addCase(getIsManagerForCurrentUser.rejected, (state, action) => {
        state.isLoadingHrManager = false;
      })
      .addCase(getUserListForAdminAddRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserListForAdminAddRole.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.adminAllUserAddRoleData = action.payload.data;
        }
      })
      .addCase(getUserListForAdminAddRole.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  resetAdminState,
  changeDriveReport,
  resetAdminFilterStatus,
  applyFilterStatus,
  changeConfigCompetencyStatus,
} = adminSlice.actions;
export default adminSlice.reducer;
