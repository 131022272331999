import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddRounds from "./AddRounds";
import AddGroup from "./AddTagModal";
import NoData from "../NoData/index";
import AddCommentModal from "./AddCommentModal";
import Icon from "../../../components/SvgIcon/icon";
import Spinner from "../../../components/Spinner/index";
import TempUnavailableModal from "./TempUnavailableModal";
import Pagination from "../../../components/Pagination/index";
import SuspensionHistoryModal from "./SuspensionHistoryModal";
import DeleteBookingConfirmModal from "./DeleteBookingConfirmModal";
import PanelDetailsView from "../../../components/PanelDetailsView/PanelDetailsView";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal.js";

import {
  fetchUpcomingBookings,
  deletePanelGroupMapping,
} from "../../../redux/features/Roster/rosterSlice";

import {
  Chip,
  Grid,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import Suspend from "../../../assets/icons/suspend.svg";
import AddIcon from "../../../assets/icons/add-icon.svg";
import SortingIcon from "../../../assets/icons/sort-icon.svg";
import TempUnavailable from "../../../assets/icons/unavailable.svg";
import AscendingIcon from "../../../assets/icons/ascending-icon.svg";
import AddDottedIcon from "../../../assets/icons/dotted-add-icon.svg";
import CommentsActive from "../../../assets/icons/active-comments.svg";
import DescendingIcon from "../../../assets/icons/descending-icon.svg";
import CommentsInactive from "../../../assets/icons/inactive-comments.svg";

import { extractDate, deriveAbbreviatedName } from "../../../utils/app-utils";
import {
  MODULE_NAME,
  ROLE_TYPES,
  SORT_TYPE_CHECK_VALUE,
  ROSTER_FILTER_TAB_CHECK_VALUE
} from "../../../constants";

import styles from "./style.module.scss";

const { isHr, isSpoc } = ROLE_TYPES;

const ActiveTableComp = ({
  competencyId,
  keyvalue,
  setKeyValue,
  adminUsers,
  activePageNumber,
  setActivePageNumber,
  user,
  handleAddCommentModal,
  selectedPanel,
  setSelectedPanel,
  setCompetencyId,
  setShowAddCommentModal,
  showAddCommentModal,
  commentsForUniqueUser,
  commentsLoading,
  setShowSuspendedHistoryModal,
  showSuspendedHistoryModal,
  handleViewSuspensionHistoryModal,
  SuspendedUsersHistoryList,
  SuspendedUsersHistoryLoading,
  SuspendedUsersHistoryPageCount,
  joiningDate,
  subcompetency,
  sortBy,
  setSortBy,
  signal,
  handleSortType,
  panelGroupId,
  activedesignationId,
  searchValue,
  designationIds,
  loading,
  showTempUnavailableModal,
  setShowTempUnavailableModal,
  handleTempUnavailableModal,
  roundName,
  handleTabsChange,
  activeAccordion,
  setActiveAccordion,
}) => {
  const pageCount  = adminUsers?.pageCount;
  const userCount  = adminUsers?.userCount;
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.rosterReducer?.bookings);
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const loadBookings = useSelector(
    (state) => state.rosterReducer?.loadBookings
  );
  const loggedInUserDesignation = useSelector(
    (state) => state?.rosterReducer?.loggedInUserDesignation
  );
  const {
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    subCompetencyState,
    dateOfJoiningState,
    roundsNameState,
    tagsState,
  } = useSelector((state) => state.rosterReducer?.roster);

  const [editGroup, setEditGroup] = useState(false);
  const [deleteTagId, setDeleteTagId] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [deleteRoundId, setDeleteRoundId] = useState(null);
  const [showAddRounds, setShowAddRounds] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showDeleteBookingConfirmModal, setShowDeleteBookingConfirmModal] =
    useState(false);

  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const handleAddRounds = (data) => {
    setSelectedPanel(data);
    setShowAddRounds(true);
  };
  const handleGroup = (item) => {
    setEditGroup(true);
    setSelectedPanel(item);
  };
  const handleClose = () => {
    setShowDeleteConfirmModal(false);
    setDeleteTagId(null);
  };
  const deleteRoundMapping = (item) => {
    setDeleteRoundId(item.id);
    setShowDeleteBookingConfirmModal(true);
    dispatch(
      fetchUpcomingBookings({
        roleId: loginInfo.role_id,
        roundId: item.id,
      })
    );
  };
  const deleteGroupMapping = () => {
    if (deleteTagId) {
      dispatch(
        deletePanelGroupMapping({
          panelGroupId: deleteTagId,
          panelCompetencyId: loginInfo.current_role_competency_id,
          roleId: loginInfo.role_id,
          pageNumber: activePageNumber,
          query: searchValue,
          groupId:  keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
          keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
            ? tagsState
            : null,
          designationIds:activeDesignationIdsState,
          roundName: roundsNameState,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          activedesignationId:interviewerDesignationIdsState,
          dateOfJoining: dateOfJoiningState,
          subCompetency: subCompetencyState,
          dispatch,
        })
      );
    }
  };
  const getRoundDetail = (data, serial) =>
    data && data.length && data?.filter((item) => item.roundName === serial);

  const getItems = (item) => {
    if (isHr(user.current_role) || isSpoc(user.current_role))
      return (
        <TableCell className={styles.actionCellActive}>
          <Grid
            container
            justifyContent="right"
            alignItems="center"
            spacing={0.7}
          >
            {isSpoc(user.current_role) && (
              <Grid
                item
                xl={3}
                className={styles.tooltipAlign}
                alignItems="center"
              >
                <Tooltip title="Add Rounds" placement="top" arrow>
                  <Typography justifyContent="right" alignItems="center">
                    {" "}
                    <Icon
                      icon={AddIcon}
                      justifyContent="center"
                      width="16px"
                      height="16px"
                      leftPadding="14px"
                      rightPadding="16.2px"
                      click={() => handleAddRounds(item)}
                    />
                  </Typography>
                </Tooltip>
              </Grid>
            )}
            <Grid
              item
              xl={isHr(user.current_role) ? 12 : 3}
              className={styles.tooltipAlign}
            >
              <Tooltip title="Notes, Click to see" placement="top" arrow>
                <Typography>
                  <Icon
                    icon={
                      item?.is_comments_available
                        ? CommentsActive
                        : CommentsInactive
                    }
                    width="20.71px"
                    height="16px"
                    leftPadding="16.2px"
                    rightPadding="16.2px"
                    click={() => handleAddCommentModal(item)}
                  />
                </Typography>
              </Tooltip>
            </Grid>
            {isSpoc(user.current_role) && (
              <Grid item xl={3} className={styles.tooltipAlign}>
                <Tooltip
                  title="Mark Temporary Unavailable"
                  placement="top"
                  arrow
                >
                  <Typography justifyContent="right" alignItems="center">
                    <Icon
                      icon={TempUnavailable}
                      width="20.71px"
                      height="16px"
                      leftPadding="16.2px"
                      rightPadding="16.2px"
                      click={() => handleTempUnavailableModal(item)}
                    />
                  </Typography>
                </Tooltip>
              </Grid>
            )}

            {isSpoc(user.current_role) && (
              <Grid item xl={3} className={styles.tooltipAlign}>
                <Tooltip title="View Suspended History" placement="top" arrow>
                  <Typography justifyContent="right" alignItems="center">
                    <Icon
                      icon={Suspend}
                      width="20.71px"
                      height="16px"
                      leftPadding="16.2px"
                      rightPadding="16.2px"
                      click={() => handleViewSuspensionHistoryModal(item)}
                    />
                  </Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </TableCell>
      );
  };
  const displayAddIcon = (item) => {
    if (isSpoc(user.current_role))
      return (
        <Grid item className={styles.tooltipAlign} alignItems="center">
          <Tooltip title="Add Tags" placement="top" arrow>
            <Typography className={styles.addBtn}>
              {" "}
              <Icon
                icon={AddDottedIcon}
                alignItems="center"
                justifyContent="center"
                className={styles.actionIcons}
                click={() => handleGroup(item)}
              />
            </Typography>
          </Tooltip>
        </Grid>
      );
  };
  const showRoundDetails = (roundType, user) => {
    const roundDetails = getRoundDetail(user.rounds, roundType);
    if (roundDetails.length) {
      return roundDetails?.map((item, index) => (
        <>
          {isSpoc(loginInfo?.current_role) ? (
            <Chip
              label={
                item.isPairing
                  ? `${deriveAbbreviatedName(item.designationName)}(P)`
                  : `${deriveAbbreviatedName(item.designationName)}`
              }
              className={
                item.isPairing
                  ? `${styles.pairingChipStyle} `
                  : `${styles.individualChipStyle} `
              }
              key={index}
              onDelete={() => deleteRoundMapping(item)}
            />
          ) : (
            <Chip
              label={
                item.isPairing
                  ? `${deriveAbbreviatedName(item.designationName)}(P)`
                  : `${deriveAbbreviatedName(item.designationName)}`
              }
              className={
                item.isPairing
                  ? `${styles.pairingChipStyle} `
                  : `${styles.individualChipStyle} `
              }
              key={index}
            />
          )}
        </>
      ));
    }
  };
  const showTags = (user) => {
    if (user?.panelgroup) {
      return user.panelgroup?.map((panel, index) => (
        <>
          {isSpoc(loginInfo?.current_role) ? (
            <Chip
              sx={{
                "& .MuiChip-deleteIcon": {
                  color: "white",
                  "&:hover": {
                    color: "white",
                  },
                },
                m: 0,
              }}
              label={panel.groupName}
              className={`${styles.tagstextBadge}`}
              key={index}
              onDelete={() => {
                setShowDeleteConfirmModal(true);
                setDeleteTagId(panel.id);
              }}
            />
          ) : (
            <Chip
              sx={{ m: 0 }}
              label={panel.groupName}
              className={`${styles.tagstextBadge}`}
              key={index}
            />
          )}
        </>
      ));
    }
  };

  if (loading) return <Spinner />;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={"globalLayoutHeight"}
    >
      {adminUsers?.activePanelMembersList?.length ? (
        <Paper className={"globalTableLayoutStyle"}>
          <TableContainer
            className={`${styles.activeTable} globalLayoutHeight globalTableContainerAlign`}
          >
            <Table aria-label="active table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={"tableheading"}
                    align="left"
                    width={"20%"}
                    onClick={() => handleSortType(SORT_TYPE_CHECK_VALUE.NAME)}
                  >
                    Panel Member{" "}
                    {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.NAME ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    )}
                  </TableCell>

                  <TableCell
                    className={"tableheading"}
                    align="left"
                    onClick={() =>
                      handleSortType(SORT_TYPE_CHECK_VALUE.DATE_OF_JOINING)
                    }
                    width={"15%"}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Date of Joining{" "}
                    {sortBy.sortType !==
                    SORT_TYPE_CHECK_VALUE.DATE_OF_JOINING ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    )}
                  </TableCell>
                  <TableCell className={"tableheading"} align="left">
                    Round 1
                  </TableCell>
                  <TableCell className={"tableheading"} align="left">
                    Round 2
                  </TableCell>
                  <TableCell className={"tableheading"} align="left">
                    Managerial Round
                  </TableCell>
                  <TableCell align="left" className={"tableheading"}>
                    Tags
                  </TableCell>
                  {isSpoc(user?.current_role) ? (
                    <TableCell align="center" className={"tableheading"}>
                      Actions
                    </TableCell>
                  ) : (
                    <TableCell className={"tableheading"}>Action</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {adminUsers?.activePanelMembersList?.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{
                      border: 0,
                    }}
                  >
                    <TableCell
                      className={`${styles.tableBodyStyle} cursorPointer`}
                    >
                      <PanelDetailsView
                        profilePicUrl={user.profile_pic_url}
                        interviewerEmail={user.email}
                        firstName={user.first_name}
                        lastName={user.last_name}
                        designationName={user.designation_name}
                        employeeCode={user.employee_code}
                        isSpoc={user.is_spoc}
                        showSpocIcon={true}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <Typography className={"tablebody"}>
                        {extractDate(user?.date_of_joining)}{" "}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" className={styles.tags}>
                      <Grid
                        container
                        className={styles.tagsWrap}
                        rowSpacing={0.5}
                        columnSpacing={0.5}
                      >
                        {showRoundDetails("R1", user)}
                      </Grid>
                    </TableCell>

                    <TableCell align="left" className={styles.tags}>
                      <Grid
                        container
                        className={styles.tagsWrap}
                        rowSpacing={0.5}
                        columnSpacing={0.5}
                      >
                        {showRoundDetails("R2", user)}
                      </Grid>
                    </TableCell>

                    <TableCell align="left" className={styles.tags}>
                      <Grid
                        container
                        className={styles.tagsWrap}
                        rowSpacing={0.5}
                        columnSpacing={0.5}
                      >
                        {showRoundDetails("MR", user)}
                      </Grid>
                    </TableCell>

                    <TableCell align="left" className={styles.tags}>
                      <Grid
                        container
                        className={styles.tagsWrap}
                        rowSpacing={0.5}
                        columnSpacing={0.5}
                      >
                        {showTags(user)}
                        {displayAddIcon(user)}
                      </Grid>
                    </TableCell>

                    {getItems(user)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              pageCount={pageCount}
              userCount={userCount}
              handlePageClick={handlePageClick}
              activePageNumber={activePageNumber}
            />
          </TableContainer>
          {showAddCommentModal && (
            <AddCommentModal
              showAddCommentModal={showAddCommentModal}
              setShowAddCommentModal={setShowAddCommentModal}
              selectedPanel={selectedPanel}
              commentsForUniqueUser={commentsForUniqueUser}
              commentsLoading={commentsLoading}
              user={user}
              designationIds={designationIds}
              joiningDate={joiningDate}
              subcompetency={subcompetency}
              sortBy={sortBy}
              setSortBy={setSortBy}
              activePageNumber={activePageNumber}
              signal={signal}
              keyvalue={keyvalue}
              panelGroupId={panelGroupId}
              activedesignationId={activedesignationId}
              searchValue={searchValue}
            />
          )}
          {showSuspendedHistoryModal && (
            <SuspensionHistoryModal
              showSuspendedHistoryModal={showSuspendedHistoryModal}
              setShowSuspendedHistoryModal={setShowSuspendedHistoryModal}
              selectedPanel={selectedPanel}
              SuspendedUsersHistoryList={SuspendedUsersHistoryList}
              SuspendedUsersHistoryLoading={SuspendedUsersHistoryLoading}
              SuspendedUsersHistoryPageCount={SuspendedUsersHistoryPageCount}
            />
          )}
          {showAddRounds && (
            <AddRounds
              showAddRounds={showAddRounds}
              competencyId={competencyId}
              selectedPanel={selectedPanel}
              setShowAddRounds={setShowAddRounds}
              setCompetencyId={setCompetencyId}
              loginInfo={user}
              activePageNumber={activePageNumber}
              setActivePageNumber={setActivePageNumber}
              activeAccordion={activeAccordion}
              handleTabsChange={handleTabsChange}
              getRoundDetail={getRoundDetail}
              designationData={loggedInUserDesignation}
              activedesignationId={activedesignationId}
              subCompetency={subcompetency}
              designationIds={designationIds}
              panelGroupId={panelGroupId}
              searchValue={searchValue}
              sortBy={sortBy}
              signal={signal}
              dateOfJoining={joiningDate}
              keyvalue={keyvalue}
            />
          )}
          {showTempUnavailableModal && (
            <TempUnavailableModal
              show={showTempUnavailableModal}
              setShow={setShowTempUnavailableModal}
              selectedPanel={selectedPanel}
              sortBy={sortBy}
              competencyId={competencyId}
              activedesignationId={activedesignationId}
              query={searchValue}
              panelGroupId={panelGroupId}
              roundDesignationIds={designationIds}
              setActivePageNumber={setActivePageNumber}
              roundName={roundName}
              activePageNumber={activePageNumber}
              dateOfJoining={joiningDate}
              subCompetency={subcompetency}
              moduleName={MODULE_NAME.ROSTER}
              keyvalue={keyvalue}
            />
          )}
          {editGroup && (
            <AddGroup
              show={editGroup}
              setShow={setEditGroup}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              competencyId={competencyId}
              selectedPanel={selectedPanel}
              activedesignationId={activedesignationId}
              subCompetency={subcompetency}
              designationIds={designationIds}
              panelGroupId={panelGroupId}
              searchValue={searchValue}
              sortBy={sortBy}
              signal={signal}
              dateOfJoining={joiningDate}
              setCompetencyId={setCompetencyId}
              loginInfo={user}
              activePageNumber={activePageNumber}
              setActivePageNumber={setActivePageNumber}
              activeAccordion={activeAccordion}
              handleTabsChange={handleTabsChange}
              keyvalue={keyvalue}
            />
          )}
          {showDeleteBookingConfirmModal && isSpoc(user.current_role) && (
            <DeleteBookingConfirmModal
              loadBookings={loadBookings}
              showDeleteBookingConfirmModal={showDeleteBookingConfirmModal}
              setShowDeleteBookingConfirmModal={
                setShowDeleteBookingConfirmModal
              }
              deleteRoundId={deleteRoundId}
              activePageNumber={activePageNumber}
              searchValue={searchValue}
              designationIds={designationIds}
              sortBy={sortBy}
              dateOfJoining={joiningDate}
              subCompetency={subcompetency}
              bookings={bookings}
              signal={signal}
              panelGroupId={panelGroupId}
              roundName={roundName}
              activedesignationId={activedesignationId}
              keyvalue={keyvalue}
            />
          )}
          {showDeleteConfirmModal && (
            <DeleteConfirmationModal
              show={showDeleteConfirmModal}
              setShow={handleClose}
              clickConfirm={deleteGroupMapping}
            />
          )}
        </Paper>
      ) : (
        <NoData/>
      )}
    </Grid>
  );
};

export default ActiveTableComp;
