import React from "react";

import { Grid, Typography, Chip } from "@mui/material";

import { extractTime } from "../../../../utils/date-utils";

import styles from "./style.module.scss";

const PanelistSlotsAndBookings = (props) => {
  const { slots, notavailable, notprovided, pairedmemberData } = props;
  return (
    <>
      <Grid container direction={"row"}>
        <Grid item xs={6}>
          <Typography variant="body1" color="initial">
            Available Slots
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" color="initial">
            Existing Bookings
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        <Grid item xs={6}>
          <Grid container rowSpacing={1} columnSpacing={1}>
            {notprovided && (
              <Grid item>
                <Chip
                  label={"Not Provided"}
                  pill
                  bg=" "
                  className={`${styles.notProvidedSlotStyle}`}
                />
              </Grid>
            )}
            {notavailable && (
              <Grid item>
                <Chip
                  label={"Not Available"}
                  pill
                  bg=" "
                  className={`${styles.notAvailableStyle}`}
                />
              </Grid>
            )}

            {slots &&
              pairedmemberData.slotdetails &&
              pairedmemberData.slotdetails[0].slotTime.map((slot) => (
                <Grid item key={`${slot.startTime}-${slot.endTime}`}>
                  <Chip
                    label={`${extractTime(slot.startTime)}
                  -
                  ${extractTime(slot.endTime)}`}
                    pill
                    bg=" "
                    className={`${styles.availableSlotStyle}`}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={1} columnSpacing={1}>
            {pairedmemberData && pairedmemberData.booking !== null ? (
              pairedmemberData.booking.map((item, index) => (
                <Grid item key={index}>
                  <Chip
                    label={`${extractTime(item.startTime)}
                -
                ${extractTime(item.endTime)}`}
                    pill
                    bg=" "
                    className={`${styles.bookingsBadgeStyle}`}
                  />
                </Grid>
              ))
            ) : (
              <Grid item>
                <Chip
                  label={"No Booking"}
                  pill
                  bg=" "
                  className={`${styles.notProvidedSlotStyle}`}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PanelistSlotsAndBookings;
