import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/Authentication/authSlice";
import reportReducer from "./features/Reports/reportSlice";
import rosterReducer from "./features/Roster/rosterSlice";
import tagReducer from "./features/Tags/tagsSlice";
import panelGroupReducer from "./features/PanelGroup/panelGroup";
import availabilityReducer from "./features/Availability/availabilitySlice"
import documentReducer from './features/Document/documentSlice'
import adminReducer from './features/Admin/adminSlice'
import designationReducer from "./features/Designation/designationSlice";
import weekendDriveReducer from "./features/WeekendDrive/weekendDriveSlice";
import { persistReducer } from "redux-persist";
import hrDashboardReducer from "./features/HrDashboard/hrDashboardSlice";
const Storage = require("redux-persist/lib/storage").default;
const persistConfig = {
  key: "root",
  storage: Storage,
};
const reducer = combineReducers({
  // to be added
  authReducer,
  reportReducer,
  rosterReducer,
  tagReducer,
  panelGroupReducer,
  availabilityReducer,
  documentReducer,
  adminReducer,
  hrDashboardReducer,
  designationReducer,
  weekendDriveReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});
