import React, { useState } from "react";

import CustomToaster from "../../../components/Toaster";

import { Avatar, Grid, Chip, Typography, Link, Icon, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import toast from "react-hot-toast";

import { extractTime } from "../../../utils/date-utils";
import { colorSet, deriveAbbreviatedName } from "../../../utils/app-utils";
import {
  INTERVIEW_STATUS_OPTIONS_SHORTFORM,
  TOAST_TYPE,
  TOAST_TIME,
  TOAST_TITLE,
  INTERVIEW_STATUS_OPTIONS,
} from "./../../../constants";

import styles from "./style.module.scss";

const StyledBox = styled("div")(({ theme }) => ({
  border: "1px solid #ddd",
  height: "100%",
  boxSizing: "border-box",
  padding: "8px", // Add padding if needed
  borderBottom: "none", // Eliminate bottom border
  [theme.breakpoints.up("md")]: {
    borderBottom: "1px solid #ddd", // Add back bottom border on medium and larger screens
  },
  display: "flex",
  justifyContent: "space-between",
}));

const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const BookingOverlayTemplate = ({ bookingSlot, user }) => {
  const [collapseComment, setColapseComment] = useState(false);
  const [commentCharLimit, setCommentCharLimit] = useState(20);

  const upperCaseAndLowerCase = (word) => {
    const stringWithSpaces = word.replace(/_/g, " ");
    return stringWithSpaces
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
  };
  const handleCollapse = () => {
    if (collapseComment) {
      setColapseComment(false);
      setCommentCharLimit(90);
    } else {
      setCommentCharLimit(bookingSlot?.notes.length);
      setColapseComment(true);
    }
  };

  const badgeStyle = {
    backgroundColor: `${
      colorSet(
        INTERVIEW_STATUS_OPTIONS[bookingSlot?.interview_status],
        bookingSlot
      )?.backgroundColor
    }`,
    color: `${
      colorSet(
        INTERVIEW_STATUS_OPTIONS[bookingSlot?.interview_status],
        bookingSlot
      )?.color
    }`,
  };

  return (
    <>
      {user && (
        <Grid container marginTop={1} marginBottom={2} alignContent={"center"}>
          <Grid item xs={1.5}>
            <Avatar alt="user-profile-pic" src={user?.profile_pic_url} />
          </Grid>
          <Grid item xs={6.5}>
            <Grid container display={"flex"} direction={"column"}>
              <Grid>
                <Typography
                  className={styles.bookingOverlayHeading}
                >{`${user?.first_name} ${user?.last_name}`}</Typography>
              </Grid>
              <Grid>
                <Typography className={styles.bookingOverlaySubHeading}>
                  {user?.designation_name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            display={"flex"}
            alignContent={"center"}
            justifyContent={"flex-end"}
          >
            <Grid
              container
              display={"flex"}
              alignContent={"center"}
              justifyContent={"flex-end"}
            >
              <Grid item>
                <Chip
                  label={
                    bookingSlot?.isInterviewerDeleted
                      ? `${
                          INTERVIEW_STATUS_OPTIONS_SHORTFORM[
                            bookingSlot?.interviewer_status
                          ]
                        }`
                      : `${
                          INTERVIEW_STATUS_OPTIONS_SHORTFORM[
                            bookingSlot?.interview_status
                          ]
                        }`
                  }
                  className={`${styles.availableSlotStyle} ${colorSet(
                    bookingSlot?.interviewer_status,
                    bookingSlot?.isInterviewerDeleted
                  )}`}
                  sx={badgeStyle}
                ></Chip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Interview Mode
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                {" "}
                {upperCaseAndLowerCase(bookingSlot?.interviewMode)}
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Google Meet
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                {bookingSlot?.calendarEventId ? (
                  <Icon
                    component={CheckIcon}
                    color="success"
                    sx={{ fontSize: "12px" }}
                  />
                ) : (
                  <Icon
                    component={ClearIcon}
                    color="error"
                    sx={{ fontSize: "12px" }}
                  />
                )}
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Hiring Designation
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                <span>{bookingSlot?.bookingForRound} - </span>
                <DarkToolTip
                  placement="top"
                  title={bookingSlot?.roundDesignationName}
                >
                  <span>
                    {deriveAbbreviatedName(bookingSlot?.roundDesignationName)}
                  </span>
                </DarkToolTip>
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
        {"pairedInterviewerId" in bookingSlot ? (
          <Grid item xs={12}>
            <StyledBox>
              <Grid xs={6} sx={{ borderRight: 1 }}>
                <Typography
                  variant="body1"
                  className={styles.bookingOverlayItemHeading}
                >
                  Panel Pairing
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography
                  variant="body1"
                  className={styles.bookingOverlayItemSubHeading}
                >
                  <DarkToolTip
                    title={bookingSlot?.pairedInterviewerEmail}
                    placement="top"
                  >
                    <span>
                      {!bookingSlot?.pairedInterviewerId ? (
                        "NA"
                      ) : (
                        <span>
                          {bookingSlot?.pairedInterviewerEmail?.slice(0, 15)}...
                        </span>
                      )}
                    </span>
                  </DarkToolTip>
                </Typography>
              </Grid>
            </StyledBox>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Interview Time
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                {`${extractTime(bookingSlot?.startTime)} - ${extractTime(
                  bookingSlot?.endTime
                )}`}
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Provided Slot
              </Typography>
            </Grid>
            {!bookingSlot?.slotDetailsOfTheDay?.length && (
              <Grid xs={6} alignSelf={"flex-start"} paddingX={1}>
                <Chip
                  label={"Not Provided"}
                  className={`${styles.notProvidedSlotStyle}`}
                ></Chip>
              </Grid>
            )}
            {bookingSlot?.slotDetailsOfTheDay?.[0]?.slotTime.length === 0 &&
              bookingSlot?.slotDetailsOfTheDay?.[0]?.comments.length && (
                <Grid xs={6} alignSelf={"flex-start"} paddingX={1}>
                  <Chip
                    label={"Not Available"}
                    className={`${styles.notAvailableStyle}`}
                  ></Chip>
                </Grid>
              )}
            {bookingSlot?.slotDetailsOfTheDay?.[0]?.slotTime?.length === 1 && (
              <Grid xs={6}>
                <Typography
                  variant="body1"
                  className={styles.bookingOverlayItemSubHeading}
                >
                  {`${extractTime(
                    bookingSlot.slotDetailsOfTheDay?.[0]?.slotTime[0]?.startTime
                  )} - ${extractTime(
                    bookingSlot.slotDetailsOfTheDay?.[0]?.slotTime[0]?.endTime
                  )}`}
                </Typography>
              </Grid>
            )}
            {bookingSlot?.slotDetailsOfTheDay?.[0]?.slotTime?.length > 1 && (
              <>
                <Grid xs={6} flexWrap={"nowrap"}>
                  <Typography
                    variant="body1"
                    className={styles.bookingOverlayItemSubHeading}
                  >
                    {`${extractTime(
                      bookingSlot.slotDetailsOfTheDay?.[0]?.slotTime[0]?.startTime
                    )} - ${extractTime(
                      bookingSlot.slotDetailsOfTheDay?.[0]?.slotTime[0]?.endTime
                    )}`}
                    &nbsp; |&nbsp;
                    <DarkToolTip
                      title={bookingSlot?.slotDetailsOfTheDay?.[0]?.slotTime.map(
                        (item, index) =>
                          index > 0 && (
                            <Typography
                              variant="subtitle1"
                              key={item}
                              color={"white"}
                              fontSize={"12px"}
                            >{`${extractTime(item?.startTime)} - ${extractTime(
                              item?.endTime
                            )}`}</Typography>
                          )
                      )}
                      placement="right"
                      arrow
                    >
                      <Avatar
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor: "rgba(0, 0, 0, 0.5)",
                          fontSize: "11px",
                          cursor: "pointer",
                          display: "inline-flex",
                        }}
                        className="fw-bold extra-slots-badge"
                      >
                        +
                        {`${
                          bookingSlot?.slotDetailsOfTheDay?.[0]?.slotTime
                            ?.length - 1
                        }`}
                      </Avatar>
                    </DarkToolTip>
                  </Typography>
                </Grid>
              </>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Candidate Name
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography variant="body1">
                {bookingSlot?.candidateResumeLink?.length ? (
                  <Link
                    href={bookingSlot?.candidateResumeLink}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.bookingOverlayItemSubHeading}
                  >
                    {bookingSlot?.candidateName
                      ? bookingSlot?.candidateName
                      : "NA"}
                  </Link>
                ) : (
                  <Typography className={styles.bookingOverlayItemSubHeading}>
                    {bookingSlot?.candidateName
                      ? bookingSlot?.candidateName
                      : "NA"}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Candidate Email
              </Typography>
            </Grid>
            {bookingSlot?.candidateEmail ? (
              <Grid xs={6} flexDirection={"row"} flexWrap={"nowrap"}>
                <DarkToolTip
                  title={bookingSlot?.candidateEmail}
                  placement="top"
                >
                  <Typography
                    sx={{ display: "inline" }}
                    className={styles.bookingOverlayItemSubHeading}
                  >
                    {bookingSlot?.candidateEmail?.slice(0, 15)}...
                  </Typography>
                </DarkToolTip>
                <DarkToolTip title={"Copy To Clipboard"} placement="top">
                  <ContentCopyIcon
                    className="cursorPointer"
                    fontSize="12px"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        bookingSlot?.candidateEmail
                      );
                      toast.custom(
                        <CustomToaster
                          title={TOAST_TITLE.SUCCESS}
                          message={`Email copied : ${bookingSlot?.candidateEmail}`}
                          type={TOAST_TYPE.SUCCESS}
                          time={TOAST_TIME.TWO}
                        />
                      );
                    }}
                  />
                </DarkToolTip>{" "}
              </Grid>
            ) : (
              <Grid xs={6}>
                <Typography
                  variant="body1"
                  className={styles.bookingOverlayItemSubHeading}
                  align="left"
                >
                  NA
                </Typography>
              </Grid>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Candidate Contact
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                {bookingSlot?.candidateMobileNumber
                  ? bookingSlot?.candidateMobileNumber
                  : "NA"}
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Recruiter Name
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                {bookingSlot?.bookingAssignedBy}
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <Grid xs={6} sx={{ borderRight: 1 }}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemHeading}
              >
                Comments
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                {(bookingSlot?.notes &&
                  bookingSlot.notes.slice(0, commentCharLimit)) ||
                  "No Comments"}
              </Typography>
              <Typography
                variant="body1"
                className={styles.bookingOverlayItemSubHeading}
              >
                {bookingSlot?.notes && bookingSlot.notes?.length > 90 && (
                  <Box
                    className="tooltip-col fw-bold"
                    style={{ cursor: "pointer" }}
                    onClick={handleCollapse}
                  >
                    {!collapseComment && "...view more"}
                  </Box>
                )}
              </Typography>
            </Grid>
          </StyledBox>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Interview Mode</Typography>
            <Typography variant="body1">
              {" "}
              {upperCaseAndLowerCase(bookingSlot?.interviewMode)}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Google Meet</Typography>
            <Typography variant="body1">
              {bookingSlot?.calendarEventId ? (
                <Icon component={CheckIcon} />
              ) : (
                <Icon component={ClearIcon} />
              )}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Hiring Designation</Typography>
            <Typography variant="body1">
              <span>{bookingSlot?.bookingForRound} - </span>
              <DarkToolTip
                placement="top"
                title={bookingSlot?.roundDesignationName}
              >
                <span>
                  {deriveAbbreviatedName(bookingSlot?.roundDesignationName)}
                </span>
              </DarkToolTip>
            </Typography>
          </StyledBox>
        </Grid>
        {"pairedInterviewerId" in bookingSlot ? (
          <Grid item xs={12} md={6}>
            <StyledBox>
              <Typography variant="body1">Panel Pairing</Typography>
              <Typography variant="body1">
                <DarkToolTip
                  title={bookingSlot?.pairedInterviewerEmail}
                  placement="top"
                >
                  <span>
                    {!bookingSlot?.pairedInterviewerId ? (
                      "NA"
                    ) : (
                      <span>
                        {bookingSlot?.pairedInterviewerEmail?.slice(0, 15)}...
                      </span>
                    )}
                  </span>
                </DarkToolTip>
              </Typography>
            </StyledBox>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Interview Time</Typography>
            <Typography variant="body1">
              {`${extractTime(bookingSlot?.startTime)} - ${extractTime(
                bookingSlot?.endTime
              )}`}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Provided Slot</Typography>
            {!bookingSlot?.slotDetailsOfTheDay?.length && (
              <Chip
                label={"Not Provided"}
                className={`${styles.notProvidedSlotStyle}`}
              ></Chip>
            )}
            {bookingSlot?.slotDetailsOfTheDay[0]?.slotTime.length === 0 &&
              bookingSlot?.slotDetailsOfTheDay[0]?.comments.length && (
                <Chip
                  label={"Not Available"}
                  className={`${styles.notAvailableStyle}`}
                ></Chip>
              )}
            {bookingSlot?.slotDetailsOfTheDay[0]?.slotTime?.length === 1 && (
              <Typography variant="body1">{`${extractTime(
                bookingSlot.slotDetailsOfTheDay[0]?.slotTime[0]?.startTime
              )} - ${extractTime(
                bookingSlot.slotDetailsOfTheDay[0]?.slotTime[0]?.endTime
              )}`}</Typography>
            )}
            {bookingSlot?.slotDetailsOfTheDay[0]?.slotTime?.length > 1 && (
              <>
                <Typography variant="body1">
                  {`${extractTime(
                    bookingSlot.slotDetailsOfTheDay[0]?.slotTime[0]?.startTime
                  )} - ${extractTime(
                    bookingSlot.slotDetailsOfTheDay[0]?.slotTime[0]?.endTime
                  )}`}
                  &nbsp; |
                </Typography>
                <DarkToolTip
                  title={bookingSlot?.slotDetailsOfTheDay[0]?.slotTime.map(
                    (item, index) =>
                      index > 0 && (
                        <Typography
                          variant="subtitle1"
                          key={item}
                        >{`${extractTime(item?.startTime)} - ${extractTime(
                          item?.endTime
                        )}`}</Typography>
                      )
                  )}
                  placement="right"
                  arrow
                >
                  <Grid container style={{ cursor: "pointer" }}>
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: "rgba(0, 0, 0, 0.5)",
                        fontSize: "11px",
                      }}
                      className="fw-bold extra-slots-badge"
                    >
                      +
                      {`${
                        bookingSlot?.slotDetailsOfTheDay[0]?.slotTime?.length -
                        1
                      }`}
                    </Avatar>
                  </Grid>
                </DarkToolTip>
              </>
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Candidate Name</Typography>
            <Typography variant="body1">
              {bookingSlot?.candidateResumeLink?.length ? (
                <Link
                  className="resume-link"
                  href={bookingSlot?.candidateResumeLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {bookingSlot?.candidateName
                    ? bookingSlot?.candidateName
                    : "NA"}
                </Link>
              ) : (
                <Typography>
                  {bookingSlot?.candidateName
                    ? bookingSlot?.candidateName
                    : "NA"}
                </Typography>
              )}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Candidate Email</Typography>
            {bookingSlot?.candidateEmail ? (
              <>
                <DarkToolTip
                  title={bookingSlot?.candidateEmail}
                  placement="top"
                >
                  <span>{bookingSlot?.candidateEmail?.slice(0, 15)}...</span>
                </DarkToolTip>
                <DarkToolTip title={"Copy To Clipboard"} placement="top">
                  <ContentCopyIcon
                    className="cursor-pointer"
                    fontSize="small"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        bookingSlot?.candidateEmail
                      );
                      toast.custom(
                        <CustomToaster
                          title={TOAST_TITLE.SUCCESS}
                          message={`Email copied : ${bookingSlot?.candidateEmail}`}
                          type={TOAST_TYPE.SUCCESS}
                          time={TOAST_TIME.TWO}
                        />
                      );
                    }}
                  />
                </DarkToolTip>{" "}
              </>
            ) : (
              "NA"
            )}
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Candidate Contact</Typography>
            <Typography variant="body1">
              {bookingSlot?.candidateMobileNumber
                ? bookingSlot?.candidateMobileNumber
                : "NA"}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Typography variant="body1">Recruiter Name</Typography>
            <Typography variant="body1">
              {bookingSlot?.bookingAssignedBy}
            </Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledBox>
            <Typography variant="body1">Comments</Typography>
            <Typography variant="body1">
              {(bookingSlot?.notes &&
                bookingSlot.notes.slice(0, commentCharLimit)) ||
                "No Comments"}
            </Typography>
            <Typography variant="body1">
              {bookingSlot?.notes && bookingSlot.notes?.length > 90 && (
                <span
                  className="tooltip-col fw-bold"
                  style={{ cursor: "pointer" }}
                  onClick={handleCollapse}
                >
                  {!collapseComment && "...view more"}
                </span>
              )}
            </Typography>
          </StyledBox>
        </Grid>
      </Grid> */}
    </>
  );
};

export default BookingOverlayTemplate;
