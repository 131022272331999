import React from "react";

import { Grid } from "@mui/material";
import {
  PieChart,
  pieArcLabelClasses,
  pieArcClasses,
} from "@mui/x-charts/PieChart";

const sizing = {
  margin: { right: 5 },
  width: 280,
  legend: { hidden: true },
};

const ReportRoundWiseDashboard = ({ roundData }) => {
  const data = [
    {
      value: roundData?.currentlyInRound,
      label: "Individual Interviewers Pool",
    },
    { value: roundData?.pairingCount, label: "Paired Interviewers Pool" },
  ];
  const TOTAL = data?.map((item) => item.value)?.reduce((a, b) => a + b, 0);
  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PieChart
          series={[
            {
              innerRadius: 90,
              data,
              arcLabel: getArcLabel,
            },
          ]}
          colors={["#648FCB", "#83CCEE"]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontSize: 14,
              fontWeight: 700,
            },
            [`& .${pieArcClasses.root}`]: {
              fill: "white",
              fontSize: 14,
            },
          }}
          {...sizing}
        ></PieChart>
      </Grid>
    </Grid>
  );
};

export default ReportRoundWiseDashboard;
