import React from "react";

import WeekendSlotView from "./WeekendSlotView";
import PanelDetailsView from "../../../../components/PanelDetailsView/PanelDetailsView";

import { TableCell, TableRow } from "@mui/material";

import styles from "./style.module.scss";

const WeekendPanelMemberItem = ({
  index,
  user,
  extractRoundWiseEligibility,
  slotsforGivenDate,
  weekendDriveDate,
  rounds,
  booking,
  competencyId,
  slotsProvided,
  roundDesignationId,
  activePageNumber,
  searchValue,
  roundName,
  email,
  stateToogle,
  activedesignationId,
  keyValue,
  weekendDriveListUpdated,
  weekendDriveList,
  allowDriveCoordinatorsForBooking,
  panelGroupId,
  weekendDriveSelected,
  payloadForAllInWeekend,
  subCompetency,
  loginInfo,
  tagsBadge
}) => {
  return (
    <>
      <TableRow className={styles.tableRow} key={user.email}>
        <TableCell
          className={`tablebody cursorPointer ${styles.column1bodyFreeze}`}
          style={{ verticalAlign: "top" }}
          alignItems={"flex-start"}
        >
          <PanelDetailsView
            profilePicUrl={user.profile_pic_url}
            interviewerEmail={user.email}
            firstName={user.first_name}
            lastName={user.last_name}
            designationName={user.designation_name}
            employeeCode={user.employee_code}
            index={index}
          />
        </TableCell>

        <TableCell
          className={`tablebody cursorPointer ${styles.column2bodyFreeze}`}
          align="left"
        >
          {extractRoundWiseEligibility(user.rounds)}
        </TableCell>

        <TableCell align="left" className={`tablebody`}>
          {user?.panel_tags?.length && tagsBadge(user?.panel_tags)}
        </TableCell>

        {!!slotsforGivenDate?.length &&
          slotsforGivenDate?.map((item, index) => {
            return (
              <TableCell
                key={index}
                className={`tablebody`}
                sx={{ width: "10%" }}
                align="center"
              >
                <WeekendSlotView
                  item={item}
                  user={user}
                  email={user.email}
                  slotDetails={user.slotdetails || []}
                  booking={booking}
                  weekendDriveDate={weekendDriveDate}
                  rounds={user.rounds}
                  competencyId={competencyId}
                  slotsProvided={slotsProvided}
                  roundDesignationId={roundDesignationId}
                  activePageNumber={activePageNumber}
                  searchValue={searchValue}
                  roundName={roundName}
                  stateToogle={stateToogle}
                  activedesignationId={activedesignationId}
                  keyValue={keyValue}
                  slotsforGivenDate={slotsforGivenDate}
                  weekendDriveList={weekendDriveList}
                  weekendDriveListUpdated={weekendDriveListUpdated}
                  allowDriveCoordinatorsForBooking={
                    allowDriveCoordinatorsForBooking
                  }
                  panelGroupId={panelGroupId}
                  weekendDriveSelected={weekendDriveSelected}
                  subCompetency={subCompetency}
                  loginInfo={loginInfo}
                />
              </TableCell>
            );
          })}
      </TableRow>
    </>
  );
};

export default WeekendPanelMemberItem;
