import React from "react";

import { Grid, Card, Typography, Box } from "@mui/material";

import styles from "../style.module.scss";

function InterviewCard({
  text,
  number,
  index,
  handleClick,
  interviewCardWithBorder,
}) {
  const handleCardClick = () => {
    handleClick(index, text, number);
  };
  const isCardWithBorder = interviewCardWithBorder === index;

  return (
    <Box marginTop={2} width={"150px"}>
      <Card
        variant="outlined"
        className={`${styles.card} ${styles.border} ${
          isCardWithBorder === true ? styles.active : ""
        }`}
        onClick={handleCardClick}
      >
        <Grid item>
          <Typography variant="body2" color="#948B9E">
            {text}
          </Typography>
          <Typography variant="h5" color="#2E1C41">
            {number}
          </Typography>
        </Grid>
      </Card>
    </Box>
  );
}

export default InterviewCard;
