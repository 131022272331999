const env = process.env.REACT_APP_ENV || 'dev';
process.env.TZ = 'Asia/Kolkata';

let config = {};
if (process.env.NODE_ENV === 'production') {
  config = window.APP_CONFIG || config;
} else {
  config = require(`../configs/${env}/config.json`);
}
module.exports = config;
