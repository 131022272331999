import React from 'react';
import CentralError from "../CentralError/CentralError"

class ErrorBoundary extends React.Component {

    state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info){
    console.log(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <CentralError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
