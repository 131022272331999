import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TabularView from "./TabularView";
import GraphicalView from "./GraphicalView";
import Spinner from "../../../components/Spinner";
import ExportBookingDataExcelModal from "./ExportBookingDataExcelModal";
import ExportPanelistDataExcelModal from "./ExportPanelistDataExcelModal";

import { getPanelReportByCompetency } from "../../../redux/features/Reports/reportSlice";

import {
  Typography,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/AssessmentOutlined";

import tabularIcon from "../../../assets/icons/tabular-icon.svg";

import {
  ROLE_TYPES,
  SORT_ORDER,
  SORT_TYPE_CHECK_VALUE,
} from "../../../constants";

import styles from "./Basic.module.scss";
import theme from "../../../theme";

const { isHr, isSpoc } = ROLE_TYPES;

const Reports = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.reportReducer?.loading);
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const competencyList = useSelector((state) => state.authReducer.competency);
  const { designationWise, roundWise } = useSelector(
    (state) => state.reportReducer?.report
  );

  const [total, setTotal] = useState([]);
  const [roundData, setRoundData] = useState([]);
  const [showTabularView, setShowTabularView] = useState(false);
  const [showExportPanelistDataModal, setShowExportPanelistDataModal] =
    useState(false);
  const [showExportBookingDataModal, setShowExportBookingDataModal] =
    useState(false);
  const [sortBy, setSortBy] = useState({
    sortType: SORT_TYPE_CHECK_VALUE.DESIGNATION_NAME,
    sortOrder: SORT_ORDER.ASCENDING,
  });

  useEffect(() => {
    if (
      loginInfo.current_role &&
      (isHr(loginInfo.current_role) || isSpoc(loginInfo.current_role))
    ) {
      dispatch(
        getPanelReportByCompetency({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
        })
      );
    }
  }, [loginInfo, dispatch, sortBy.sortType, sortBy.sortOrder]);
  useEffect(() => {
    if (designationWise && designationWise.length) {
      const updatedCount = { totalCount: 0, inPanel: 0, notInPanel: 0 };
      designationWise?.map((data) => {
        updatedCount.totalCount = updatedCount.totalCount + data.totalCount;
        updatedCount.inPanel = updatedCount.inPanel + data.currentlyInPanel;
        updatedCount.notInPanel =
          updatedCount.notInPanel + (data.totalCount - data.currentlyInPanel);
        return data;
      });
      setTotal(Object.values(updatedCount));
    }
  }, [designationWise, setTotal]);
  useEffect(() => {
    if (roundWise && roundWise.length) {
      const updatedRoundData = [];

      roundWise.forEach((round) => {
        const findIndex = updatedRoundData.findIndex((data) => {
          return (
            data.roundName === round.roundName &&
            data.designationId === round.designationId
          );
        });

        if (findIndex > -1) {
          if (round.isPairing) {
            updatedRoundData[findIndex].pairingCount = round.currentlyInRound;
          } else {
            updatedRoundData[findIndex].currentlyInRound =
              round.currentlyInRound;
          }
        } else {
          if (round.isPairing) {
            updatedRoundData.push({
              ...round,
              currentlyInRound: 0,
              pairingCount: round.currentlyInRound,
            });
          } else {
            updatedRoundData.push({ ...round });
          }
        }
      });
      const dataTotalCount = updatedRoundData.reduce(
        (accumulator, item) => {
          accumulator.currentlyInRound += item.currentlyInRound || 0;
          accumulator.pairingCount += item.pairingCount || 0;
          return accumulator;
        },
        { currentlyInRound: 0, pairingCount: 0 }
      );
      setRoundData(dataTotalCount);
    }
  }, [roundWise, setRoundData]);

  const handleShowExportPanelistDataModal = () => {
    setShowExportPanelistDataModal(!showExportPanelistDataModal);
  };
  const handleShowExportBookingDataModal = () => {
    setShowExportBookingDataModal(!showExportBookingDataModal);
  };

  if (loading) return <Spinner />;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs container alignItems="center">
        <Grid item md={3} lg={4}>
          <Typography
            variant="h5"
            display="inline-flex"
            color={theme.palette.secondary.main}
            fontSize={20}
            className={"heading"}
          >
            {showTabularView ? `Designation wise Availability` : `Reports`}
          </Typography>
        </Grid>
        <Grid item xs container justifyContent="flex-end" spacing={2}>
          {(isHr(loginInfo.current_role) || isSpoc(loginInfo.current_role)) &&
            showExportPanelistDataModal && (
              <ExportPanelistDataExcelModal
                show={showExportPanelistDataModal}
                setShow={setShowExportPanelistDataModal}
                competencyList={competencyList}
              />
            )}
          {isHr(loginInfo.current_role) && showExportBookingDataModal && (
            <ExportBookingDataExcelModal
              show={showExportBookingDataModal}
              setShow={setShowExportBookingDataModal}
              competencyList={competencyList}
            />
          )}
          {isHr(loginInfo.current_role) && (
            <Grid
              item
              xs={12}
              sm={4}
              md={2.8}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                className={styles.buttonStyle}
                disableTouchRipple
                disableRipple
                disableElevation
                fullWidth
                onClick={() => handleShowExportBookingDataModal()}
              >
                WEEKDAYS BOOKING DATA
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={4} md={3}>
            {(isHr(loginInfo.current_role) ||
              isSpoc(loginInfo.current_role)) && (
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Button
                  variant="outlined"
                  className={styles.buttonStyle}
                  disableTouchRipple
                  disableRipple
                  disableElevation
                  fullWidth
                  onClick={() => handleShowExportPanelistDataModal()}
                >
                  WEEKDAYS PANELIST DATA
                </Button>
                <IconButton
                  aria-label="icon"
                  onClick={() => setShowTabularView(!showTabularView)}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  {showTabularView ? (
                    <Tooltip
                      title={"Graphical View"}
                      arrow={true}
                      placement="top"
                    >
                      <AssessmentIcon sx={{ color: "#DE1186" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={"Tabular View"}
                      arrow={true}
                      placement="top"
                    >
                      <img src={tabularIcon} alt="tabular-view" />
                    </Tooltip>
                  )}
                </IconButton>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
      {showTabularView ? (
        <TabularView
          roundWise={roundWise}
          designationWise={designationWise}
          loading={loading}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      ) : (
        <GraphicalView
          roundData={roundData}
          designationWise={designationWise}
          total={total}
        />
      )}
    </Grid>
  );
};

export default Reports;
