import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AutoCompleteComponent from "../../../../components/AutoComplete/index";
import SingleSelectComponent from "../../../../components/SingleSelect/SingleSelectComponent";

import {
  fetchPanelMembersAvailability,
  fetchPanelMembersAvailabilityWeekend,
  applyFilterStatus,
  resetAvailabilityFilterStatus,
  changeSlots,
  changeRounds,
  changeEligibleDesignationsIds,
  changeInterviewerDesignationsIds,
  changeTags,
  changeSubCompetency,
} from "../../../../redux/features/Availability/availabilitySlice";
import { getEligibleDesignationForWeekendDatewise } from "../../../../redux/features/WeekendDrive/weekendDriveSlice";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Divider,
  Slide,
  SwipeableDrawer,
  Autocomplete,
  Stack,
  TextField,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  SLOT_FILTER_OPTIONS,
  SUBCOMPETENCY_FILTERS_JS,
  ROUND_FILTER_OPTIONS,
  JS_COMPETENCY_ID,
  ROLE_TYPES,
  CHECK_TAB_KEY,
  SLOT_FILTER_OPTIONS_WEEKEND,
} from "../../../../constants/index";

import styles from "./style.module.scss";

const { isAdmin, isPanelMember, isHr } = ROLE_TYPES;

const FilterView = ({
  keyValue,
  setKeyValue,
  loginInfo,
  competencyId,
  setCompetencyId,
  startDateOfWeek,
  endDateOfWeek,
  activePageNumber,
  setActivePageNumber,
  searchValue,
  setSearchValue,
  slotsProvided,
  setSlotsProvided,
  roundName,
  setRoundName,
  activedesignationId,
  setActiveDesignationId,
  panelGroupId,
  setPanelGroupId,
  subCompetency,
  setSubCompetency,
  filterIcon,
  setFilterIcon,
  designationIds,
  setDesignationIds,
  eligibleDesignationList,
  signal,
  setIsFilterApplied,
  weekendDriveDate,
  weekendDriveSelected,
  selectedRecruiters,
  weekenddesignationIds,
  optionsRecruiterEmail,
  setOptionsRecruiterEmail,
  setSelectedRecruiters,
  providedSlots,
  roundsFilter,
  eligibleDesignationIds,
  interviewerDesignationIds,
  tagsFilter,
  subCompetencies,
}) => {
  const dispatch = useDispatch();
  const panelGroupList = useSelector((state) => state?.panelGroupReducer?.list);
  const getEligibleDesignationForWeekend = useSelector(
    (state) => state.weekendDriveReducer?.eligibleDesignationsWeekend
  );
  const activeDesignations = useSelector(
    (state) => state?.rosterReducer?.activeDesignation
  );
  const interviewerDesignationList = useSelector(
    (state) => state?.availabilityReducer?.interviewerDesignationList
  );

  const [optionCompetency, setOptionCompetency] = useState([]);
  const [optionCompetencyForHr, setOptionCompetencyForHr] = useState([]);

  useEffect(() => {
    if (loginInfo?.current_role_competency_id) {
      let activeDesignationsArray = [];
      for (const designation in activeDesignations) {
        activeDesignationsArray.push(
          activeDesignations[designation].name +
            ":" +
            designation +
            ":" +
            activeDesignations[designation].abbreviatedName
        );
      }
      activeDesignationsArray = activeDesignationsArray.sort();
      let sortedActiveDesignationList = activeDesignationsArray.reduce(
        (acc, item) => {
          acc[" " + item.split(":")[1]] = {
            name: item.split(":")[0],
            abbreviatedName: item.split(":")[2],
          };
          return acc;
        },
        {}
      );

      const competencyData = Object.entries(sortedActiveDesignationList);
      if (competencyData.length) {
        const optionDesignation = competencyData?.map((item) => {
          return {
            label: item[1].name,
            value: parseInt(item[0]),
          };
        });
        setOptionCompetency(optionDesignation);
      } else {
        setOptionCompetency([]);
      }
    }
  }, [
    activeDesignations,
    loginInfo?.current_role_competency_id,
    setOptionCompetency,
  ]);
  useEffect(() => {
    if (isHr(loginInfo?.current_role)) {
      if (interviewerDesignationList?.length) {
        const optionInterviewerDesignationList =
          interviewerDesignationList?.map((item) => {
            return {
              label: item?.designation_name,
              value: item?.designation_id,
            };
          });
        setOptionCompetencyForHr(optionInterviewerDesignationList);
      } else {
        setOptionCompetencyForHr([]);
      }
    }
  }, [interviewerDesignationList, loginInfo]);
  useEffect(() => {
    if (
      CHECK_TAB_KEY.WEEKEND === keyValue &&
      weekendDriveDate !== null &&
      weekendDriveSelected.length
    ) {
      dispatch(
        getEligibleDesignationForWeekendDatewise({
          roleId: loginInfo.role_id,
          driveId: weekendDriveSelected && weekendDriveSelected[0]?.id,
        })
      );
    }
  }, [
    dispatch,
    keyValue,
    loginInfo.role_id,
    weekendDriveDate,
    weekendDriveSelected,
  ]);

  const toggleDrawer = (newOpen) => () => {
    setFilterIcon(newOpen);
  };
  const handleRoundChange = (value) => {
    setActivePageNumber(1);
    setRoundName(value);
    dispatch(changeRounds(value));
  };
  const handleEligibleDesignationChange = (event) => {
    setDesignationIds(event);
    dispatch(changeEligibleDesignationsIds(event));
  };
  const handleInterviewerDesignationChange = (event) => {
    setActiveDesignationId(event);
    dispatch(changeInterviewerDesignationsIds(event));
  };
  const handleSlots = (value) => {
    setActivePageNumber(1);
    setSlotsProvided(value);
    dispatch(changeSlots(value));
  };
  const handleClose = (e) => {
    setFilterIcon(false);
  };
  const multiSelectOptions_Weekend = (
    getEligibleDesignationForWeekend = []
  ) => {
    return getEligibleDesignationForWeekend?.length
      ? getEligibleDesignationForWeekend?.reduce((acc, item) => {
          if (item) {
            acc.push({
              label: item.designationName,
              value: `${item.id}`,
              isPairing: false,
              designationId: item.id,
            });
            acc.push({
              label: item.designationName + "(P)",
              value: `${item.id}${"-P"}`,
              isPairing: true,
              designationId: item.id,
            });
          }
          return acc;
        }, [])
      : [];
  };
  const resetRosterFilter = (e) => {
    e.stopPropagation();
    setCompetencyId(
      isAdmin(loginInfo.current_role)
        ? ""
        : loginInfo.current_role_competency_id
    );
    setActivePageNumber(1);
    setSubCompetency([]);
    setPanelGroupId([]);
    setRoundName(ROUND_FILTER_OPTIONS.ALL);
    setSlotsProvided(SLOT_FILTER_OPTIONS.ALL);
    setDesignationIds([]);
    setActiveDesignationId([]);
    setIsFilterApplied(false);
    setSelectedRecruiters([]);
    setSearchValue("");
    dispatch(resetAvailabilityFilterStatus());
    if (CHECK_TAB_KEY.WEEKDAYS === keyValue) {
      dispatch(
        fetchPanelMembersAvailability({
          competencyId: loginInfo.current_role_competency_id,
          roundDesignationIds: [],
          periodFrom: new Date(startDateOfWeek).toISOString(),
          periodTo: new Date(endDateOfWeek).toISOString(),
          pageNumber: 1,
          roleId: loginInfo.role_id,
          query: "",
          slotsProvided: SLOT_FILTER_OPTIONS.ALL,
          roundName: ROUND_FILTER_OPTIONS.ALL,
          activedesignationId: [],
          panelGroupId: [],
          subCompetency: [],
          signal: signal,
        })
      );
    }
    if (
      CHECK_TAB_KEY.WEEKEND === keyValue &&
      weekendDriveDate !== null &&
      weekendDriveSelected.length
    ) {
      dispatch(
        fetchPanelMembersAvailabilityWeekend({
          competencyId: loginInfo?.current_role_competency_id,
          roundDesignationIds: [],
          slotsProvided: SLOT_FILTER_OPTIONS.ALL,
          roundName: ROUND_FILTER_OPTIONS.ALL,
          periodFrom: new Date(
            new Date(weekendDriveDate).setHours(0, 0, 0, 0)
          ).toISOString(),
          periodTo: new Date(
            new Date(weekendDriveDate).setHours(23, 59, 59, 59)
          ).toISOString(),
          roleId: loginInfo.role_id,
          pageNumber: activePageNumber,
          query: "",
          activedesignationId: [],
          signal: signal,
          keyValue: keyValue,
          panelGroupId: [],
          driveId: weekendDriveSelected && weekendDriveSelected[0]?.id,
          subCompetency: [],
          recruiterIds: [],
        })
      );
    }
  };
  const applyFilter = () => {
    dispatch(applyFilterStatus());
    if (keyValue === CHECK_TAB_KEY.WEEKDAYS) {
      dispatch(
        fetchPanelMembersAvailability({
          competencyId: loginInfo.current_role_competency_id,
          roundDesignationIds: eligibleDesignationIds,
          periodFrom: new Date(startDateOfWeek).toISOString(),
          periodTo: new Date(endDateOfWeek).toISOString(),
          pageNumber: activePageNumber,
          roleId: loginInfo.role_id,
          query: searchValue,
          slotsProvided: providedSlots,
          roundName: roundsFilter,
          activedesignationId: interviewerDesignationIds,
          panelGroupId: tagsFilter,
          subCompetency: subCompetencies,
        })
      );
    }
    if (
      CHECK_TAB_KEY.WEEKEND === keyValue &&
      weekendDriveDate !== null &&
      weekendDriveSelected.length
    ) {
      dispatch(
        fetchPanelMembersAvailabilityWeekend({
          competencyId: loginInfo?.current_role_competency_id,
          roundDesignationIds: eligibleDesignationIds,
          slotsProvided: providedSlots,
          roundName: roundsFilter,
          periodFrom: new Date(
            new Date(weekendDriveDate).setHours(0, 0, 0, 0)
          ).toISOString(),
          periodTo: new Date(
            new Date(weekendDriveDate).setHours(23, 59, 59, 59)
          ).toISOString(),
          roleId: loginInfo.role_id,
          pageNumber: activePageNumber,
          query: searchValue,
          activedesignationId: interviewerDesignationIds,
          signal: signal,
          keyValue: keyValue,
          panelGroupId: tagsFilter,
          driveId: weekendDriveSelected && weekendDriveSelected[0]?.id,
          subCompetency: subCompetencies,
          recruiterIds: selectedRecruiters,
        })
      );
    }
    setIsFilterApplied(true);
    handleClose();
  };

  const subCompetencyFilter = () => {
    if (loginInfo.current_role_competency_id === JS_COMPETENCY_ID) {
      return (
        <AutoCompleteComponent
          placeholder={"Competency"}
          label={"Sub Competency"}
          options={SUBCOMPETENCY_FILTERS_JS}
          onChange={(event) => {
            setSubCompetency(event);
            dispatch(changeSubCompetency(event));
          }}
          selectedValue={subCompetencies}
        />
      );
    }
  };
  const tagFilter = () => {
    function selectedPanelGroupIds() {
      const result = panelGroupList?.filter((item) =>
        tagsFilter?.includes(item.id)
      );
      return result;
    }
    return (
      <Stack spacing={3} sx={{ width: "100%" }}>
        <Autocomplete
          getOptionLabel={(option) => option?.group_name}
          limitTags={2}
          render
          value={selectedPanelGroupIds()}
          onChange={(event, newValue) => {
            const idArray = newValue.map((id) => id.id);
            setPanelGroupId(idArray);
            dispatch(changeTags(idArray));
          }}
          multiple
          id="tags-outlined"
          options={panelGroupList}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={"Tags"}
              placeholder={"Tag"}
            />
          )}
          PaperComponent={({ children }) => (
            <Paper
              style={{
                maxHeight: 150,
                overflowY: "auto",
                fontSize: "15px",
                fontFamily: "poppins",
              }}
            >
              {children}
            </Paper>
          )}
        />
      </Stack>
    );
  };
  const slotFilter = () => {
    return (
      <SingleSelectComponent
        options={
          keyValue === CHECK_TAB_KEY.WEEKDAYS
            ? SLOT_FILTER_OPTIONS
            : SLOT_FILTER_OPTIONS_WEEKEND
        }
        onChange={(event) => handleSlots(event.value)}
        label={"Select Slot"}
        value={providedSlots}
        disabled={!competencyId && isPanelMember(loginInfo.current_role)}
      />
    );
  };
  const roundFilter = () => {
    return (
      <SingleSelectComponent
        options={ROUND_FILTER_OPTIONS}
        onChange={(event) => handleRoundChange(event.value)}
        label={"Select Round"}
        value={roundsFilter}
      />
    );
  };
  const eligibleDesignationFilter = () => {
    return (
      <>
        {CHECK_TAB_KEY.WEEKDAYS === keyValue ? (
          <>
            <AutoCompleteComponent
              placeholder={"Designation"}
              label={"Eligibility Designation"}
              options={eligibleDesignationList}
              onChange={handleEligibleDesignationChange}
              selectedValue={eligibleDesignationIds}
            />
          </>
        ) : (
          <>
            <AutoCompleteComponent
              placeholder={"Designation"}
              label={"Eligibility Designation"}
              options={multiSelectOptions_Weekend(
                getEligibleDesignationForWeekend
              )}
              onChange={handleEligibleDesignationChange}
              selectedValue={eligibleDesignationIds}
            />
          </>
        )}
      </>
    );
  };
  const interviewerDesignationFilter = () => {
    return (
      <>
        <AutoCompleteComponent
          placeholder={"Designation"}
          label={"Interviewer Designation"}
          options={
            isHr(loginInfo?.current_role)
              ? optionCompetencyForHr
              : optionCompetency
          }
          onChange={handleInterviewerDesignationChange}
          selectedValue={interviewerDesignationIds}
        />
      </>
    );
  };
  const filterList = () => (
    <Box className={styles.filterContainer} role="presentation">
      {/* Filter components */}
      <Box mt={2} pt={6}>
        <Grid container>
          <Grid item xs={10} md={10} xl={10} lg={10}>
            <Typography
              variant="h6"
              gutterBottom
              pt={3}
              pl={3}
              className={styles.filtersHeading}
            >
              Filters
            </Typography>
          </Grid>
          <Grid item xs={2} md={2} xl={2} lg={2} p={2.5}>
            <IconButton onClick={(e) => handleClose(e)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />
        <Grid
          container
          mt={2}
          ml={0.3}
          pr={2}
          spacing={3}
          sx={{ flexGrow: 1, overflowY: "hidden" }}
        >
          <>
            <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
              {slotFilter()}
            </Grid>
            <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
              {roundFilter()}
            </Grid>
            <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
              {eligibleDesignationFilter()}
            </Grid>
            <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
              {interviewerDesignationFilter()}
            </Grid>

            <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
              {tagFilter()}
            </Grid>

            {loginInfo.current_role_competency_id === JS_COMPETENCY_ID && (
              <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
                {subCompetencyFilter()}
              </Grid>
            )}
          </>
        </Grid>
      </Box>

      <Grid container m={2} spacing={1}>
        <Grid
          item
          lg={5.5}
          xl={5.5}
          xs={5.5}
          md={5.5}
          display="flex"
          alignItems="flex-end"
        >
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={(e) => resetRosterFilter(e)}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            CLEAR
          </Button>
        </Grid>

        <Grid item lg={5.5} xl={5.5} xs={5.5} md={5.5}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            className={styles.searchBtn}
            onClick={(e) => applyFilter(e)}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            SEARCH
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={filterIcon}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Slide direction="left" in={filterIcon} mountOnEnter unmountOnExit>
          {filterList()}
        </Slide>
      </SwipeableDrawer>
    </>
  );
};

export default FilterView;
