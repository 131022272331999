import React, { useEffect } from "react";

import Icon from "../../../../components/SvgIcon/icon";

import {
  TextField,
  Grid,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import CloseIcon from "../../../../assets/icons/cancel-icon.svg";
import confirm from "../../../../assets/icons/check-icon.svg";
import EditIcon from "../../../../assets/icons/edit-select-icon.svg";

import { extractTime } from "../../../../utils/date-utils";
import { INTERVIEW_STATUS_CHECK } from "../../../../constants";

import dayjs from "dayjs";

import styles from "./style.module.scss";

export default function SlotTimeCard({
  index,
  selectedCardIndex,
  startTime,
  endTime,
  onSetOrUpdateSlotTime,
  onDeleteSlot,
  toggleTimePanel,
  validateSlotTime,
  confirmTimeSlot,
  editWeekendDrive,
  interviewStatus,
  showDeleteDesignationConfirmModal,
  bookings,
  openDeleteBookingModal,
}) {
  useEffect(() => {
    if (
      editWeekendDrive === null &&
      interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED
    ) {
      openDeleteBookingModal(interviewStatus);
    }
  }, [editWeekendDrive, interviewStatus]);

  return (
    <Grid
      style={{ height: "4rem" }}
      className={styles.slotsCard}
      xs={6}
      md={6}
      lg={12}
      xl={12}
      display={"flex"}
    >
      <Grid
        item
        xs={9.5}
        md={9.5}
        lg={9.5}
        xl={9.5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {selectedCardIndex === index ? (
          <Grid
            container
            spacing={1.5}
            alignItems="center"
            marginTop={0.5}
            paddingLeft={0.5}
          >
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label=""
                  ampm="true"
                  type="time"
                  closeOnSelect={false}
                  minutesStep={15}
                  views={["hours", "minutes"]}
                  value={dayjs(startTime)}
                  onChange={(date) => {
                    if (
                      validateSlotTime(startTime, endTime, date, "startTime")
                    ) {
                      onSetOrUpdateSlotTime(date, null, index);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "33px",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000000",
                    },
                  }}
                  className={styles.timeInput}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label={""}
                  ampm="true"
                  type="time"
                  closeOnSelect={false}
                  minutesStep={15}
                  views={["hours", "minutes"]}
                  value={dayjs(endTime)}
                  onChange={(event) => {
                    if (
                      validateSlotTime(startTime, endTime, event, "endTime")
                    ) {
                      onSetOrUpdateSlotTime(null, event, index);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "33px",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000000",
                    },
                  }}
                  className={styles.timeInput}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid item xs={6} md={6} lg={6} xl={6} paddingTop={1.5}>
              <Typography className={styles.slotsTextStyle}>
                {" "}
                {`${extractTime(startTime)} - ${extractTime(endTime)}`}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        md={2}
        lg={2}
        xl={2}
        justifyContent={"end"}
        alignItems={"center"}
        display="flex"
        position={"relative"}
      >
        {selectedCardIndex === index ? (
          <Grid item className={styles.tooltipAlign}>
            <Tooltip title="Confirm Slots" placement="top" arrow>
              <Typography onClick={() => confirmTimeSlot(index)}>
                <Icon
                  icon={confirm}
                  width="20.71px"
                  height="16px"
                  leftPadding="16.2px"
                  rightPadding="5.2px"
                />
              </Typography>
            </Tooltip>
          </Grid>
        ) : (
          <Grid item className={styles.tooltipAlign}>
            <Tooltip title="Edit Slots" placement="top" arrow>
              <Typography onClick={() => toggleTimePanel(index)}>
                <Icon
                  icon={EditIcon}
                  width="20.71px"
                  height="16px"
                  leftPadding="16.2px"
                  rightPadding="16.2px"
                />
              </Typography>
            </Tooltip>
          </Grid>
        )}
        <Grid item className={styles.tooltipAlign}>
          <IconButton
            aria-label="close"
            onClick={(e) => onDeleteSlot(index)}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{ position: "absolute", right: -21, top: -10 }}
          >
            {" "}
            <Tooltip title="Remove Slots" placement="top" arrow>
              <img src={CloseIcon} alt="close-icon" />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
