import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put } from "../../../utils/app-utils";
import { BASE_URL, INTERVIEW_STATUS_CHECK } from "../../../constants/index";
import { toast } from "react-hot-toast";
import format from "date-fns/format";
import CustomToaster from "../../../components/Toaster/index";
import { fetchPanelMembersAvailabilityWeekend } from "../Availability/availabilitySlice";
import {
  TOAST_TYPE,
  TOAST_TIME,
  TOAST_TITLE,
  SORT_TYPE_CHECK_VALUE,
  SORT_ORDER,
} from "../../../constants/index";

const initialState = {
  loading: false,
  isFilterApplied: true,
  weekendDrive: {
    filterStatus: INTERVIEW_STATUS_CHECK.SCHEDULED,
    activedesignationId: [],
    periodFrom: format(
      new Date().setMonth(new Date().getMonth() - 6),
      "yyyy-MM-dd"
    ),
    periodTo: format(
      new Date().setMonth(new Date().getMonth() + 6),
      "yyyy-MM-dd"
    ),
  },
  weekendDriveDetails: [],
  totalDriveCount: 0,
  loaderForBookingInWeekend: false,
  weekendDriveReport: {
    bookingData: [],
    hiring_designations: [],
    pageCount: 0,
    totalCount: 0,
  },
  existingWeekendDriveForUser: [],
  weekendAvailabilityLoading: false,
  filtersArray: [],
  weekendDriveLists: [],
  loadingForWeekendListByDate: false,
  weekendBookings: [],
  loadWeekendBookings: false,
  loaderForAvailableSlotsInWeekend: false,
  weekendDriveReportForAvailableSlots: [],
  loaderForSlotsInWeekend: false,
  weekendDriveReportForSlots: [],
  coordinatorList: [],
  eligibleDesignationsWeekend: [],
};

export const fetchWeekendDriveDetailsByCompetency = createAsyncThunk(
  "fetchWeekendDriveDetailsByCompetency",
  async ({
    competencyId,
    roleId,
    driveStatus,
    pageNumber,
    isPaginated,
    designationIds = [],
    periodFrom,
    periodTo,
    sortType,
    sortOrder,
    isMultiStatus,
  }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/drives/list`;
      const designationIdsArray = designationIds?.map((item) => item.value);
      const response = await get(url, {
        queryParams: {
          competencyId,
          role_id: roleId,
          driveStatus: driveStatus,
          pageNumber: pageNumber,
          isPaginated: isPaginated,
          designationIds: designationIdsArray.length ? designationIdsArray : [],
          periodFrom,
          periodTo,
          sortType,
          sortOrder,
          isMultiStatus,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchWeekendDriveByDate = createAsyncThunk(
  "fetchWeekendDriveByDate",
  async ({ competencyId, roleId, periodFrom, periodTo }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/getDriveByDate`;
      const response = await get(url, {
        queryParams: {
          competencyId,
          role_id: roleId,
          periodFrom,
          periodTo,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const reportWeekendDrive = createAsyncThunk(
  "reportWeekendDrive",
  async ({
    roleId,
    loginId,
    driveId,
    searchedValue,
    roundName,
    designationId = [],
    dayBeforeDrive,
    driveday,
    candidateIntStatus,
    pageNumber,
    bookingsView,
    isBookings,
    signal,
    panelUserIds,
    recruiterIds,
    timeSlots,
    calendarEventExists,
  }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/bookings`;
      const response = await get(
        url,
        {
          queryParams: {
            role_id: roleId,
            loginId,
            driveId: driveId,
            searchQuery: searchedValue,
            roundName,
            designationId: JSON.stringify(designationId),
            dayBeforeDrive,
            driveDay: driveday,
            candidateStatus: candidateIntStatus,
            bookingsView,
            pageNumber,
            isBookings,
            panelUserIds: JSON.stringify(panelUserIds),
            recruiterIds: JSON.stringify(recruiterIds),
            timeSlots: JSON.stringify(timeSlots),
            calendarEventExists,
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const reportWeekendDriveAvailableSlots = createAsyncThunk(
  "reportWeekendDriveAvailableSlots",
  async ({ roleId, driveId }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/panelistAvailability/report`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          driveId: driveId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const reportWeekendDriveSlots = createAsyncThunk(
  "reportWeekendDriveSlots",
  async ({ roleId, driveId }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/panelistSlots/report`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { driveId }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchWeekendDriveBookingByCompetency = createAsyncThunk(
  "fetchWeekendDriveBookingByCompetency",
  async ({
    competencyId,
    roleId,
    driveStatus,
    periodTo,
    periodFrom,
    designationIds,
  }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/upcomingBookings`;
      const response = await get(url, {
        queryParams: {
          competencyId,
          role_id: roleId,
          driveStatus,
          periodTo,
          periodFrom,
          designationIds,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchWeekendDriveCoordinatorListByCompetency = createAsyncThunk(
  "fetchWeekendDriveCoordinatorListByCompetency",
  async ({ competencyId, roleId }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/coordinator/list`;
      const response = await get(url, {
        queryParams: {
          competencyId,
          role_id: roleId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const saveWeekendDriveForSlot = createAsyncThunk(
  "saveWeekendDriveForSlot",
  async ({
    driveDate,
    designationIds = [],
    coordinatorIds = [],
    driveStatus,
    slotTime,
    competencyId,
    roleId,
    availabilityDeadlineDate,
    filterdriveStatus,
    pageNumber,
    isPaginated,
    availabilityReminderDates = [],
    dispatch,
  }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/coordinator/list`;
      const coordinatorIdsArray = coordinatorIds?.map((item) => item?.value);
      const designationIdsArray = designationIds?.map((item) => item?.value);
      const availabilityReminderDatesArray = availabilityReminderDates?.map(
        (item) => item?.value
      );
      const response = await post(
        `${BASE_URL}/weekend-drive/createDrive`,
        { queryParams: { role_id: roleId } },
        {
          driveDate,
          designationIds: designationIdsArray,
          coordinatorIds: coordinatorIdsArray,
          driveStatus,
          slotTime,
          competencyId,
          availabilityDeadlineDate,
          availabilityReminderDates: availabilityReminderDatesArray,
        }
      );
      dispatch(
        fetchWeekendDriveDetailsByCompetency({
          roleId,
          competencyId,
          driveStatus: filterdriveStatus,
          pageNumber: pageNumber,
          isPaginated: isPaginated,
          designationIds: [],
          periodFrom: format(
            new Date().setMonth(new Date().getMonth() - 6),
            "yyyy-MM-dd"
          ),
          periodTo: format(
            new Date().setMonth(new Date().getMonth() + 6),
            "yyyy-MM-dd"
          ),
          sortType: SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE,
          sortOrder: SORT_ORDER.DESCENDING,
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const editWeekendDriveForSlot = createAsyncThunk(
  "editWeekendDriveForSlot",
  async ({
    roleId,
    competencyId,
    slotTime,
    driveStatus,
    designationIds = [],
    coordinatorIds = [],
    reason,
    isSendMail = true,
    driveDate,
    weekendDriveId,
    availabilityDeadlineDate,
    filterdriveStatus,
    pageNumber,
    isPaginated,
    deleteDesignationsIds,
    availabilityReminderDates = [],
    setShowCoordinatorModal,
    dispatch,
  }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/updateDrive/${weekendDriveId}`;
      const coordinatorIdsArray = coordinatorIds?.map((item) => item?.value);
      const designationIdsArray = designationIds?.map((item) => item?.value);
      const availabilityReminderDatesArray = availabilityReminderDates?.map(
        (item) => item?.value
      );
      const response = await put(
        url,
        {
          queryParams: {
            role_id: roleId,
          },
        },
        {
          slotTime,
          driveDate,
          driveStatus,
          designationIds: designationIdsArray,
          coordinatorIds: coordinatorIdsArray,
          reason,
          sendCancelDriveNotificationEmail: isSendMail,
          availabilityDeadlineDate,
          deleteDesignationsIds,
          competencyId,
          availabilityReminderDates: availabilityReminderDatesArray,
        }
      );
      dispatch(
        fetchWeekendDriveDetailsByCompetency({
          roleId,
          competencyId,
          driveStatus: filterdriveStatus,
          pageNumber: pageNumber,
          isPaginated: isPaginated,
          designationIds: [],
          periodFrom: format(
            new Date().setMonth(new Date().getMonth() - 6),
            "yyyy-MM-dd"
          ),
          periodTo: format(
            new Date().setMonth(new Date().getMonth() + 6),
            "yyyy-MM-dd"
          ),
          sortType: SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE,
          sortOrder: SORT_ORDER.DESCENDING,
        })
      );

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchWeekendDriveFilterOptions = createAsyncThunk(
  "fetchWeekendDriveFilterOptions",
  async ({ roleId, driveId }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/filterOptions`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          driveId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchMarkedWeekendDrive = createAsyncThunk(
  "fetchMarkedWeekendDrive",
  async ({ roleId, dateOfAvailable, userPanelMappingId }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/getWeekendDriveAvailability`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          dateOfAvailable: dateOfAvailable,
          userPanelMappingId: userPanelMappingId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const markWeekendDriveAvailability = createAsyncThunk(
  "markWeekendDriveAvailability",
  async ({
    slotAvailability,
    panelMemberAvailabilityPayload,
    roleId,
    dispatch,
  }) => {
    try {
      let url = `${BASE_URL}/weekend-drive/slot/markWeekendDriveAvailability`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { slotAvailability }
      );
      dispatch(
        fetchPanelMembersAvailabilityWeekend(panelMemberAvailabilityPayload)
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const updateMarkedWeekendSlots = createAsyncThunk(
  "updateMarkedWeekendSlots",
  async ({
    slotAvailability,
    panelMemberAvailabilityPayload,
    roleId,
    dispatch,
  }) => {
    try {
      const response = await put(
        `${BASE_URL}/weekend-drive/updateWeekendDriveAvailability`,
        { queryParams: { role_id: roleId } },
        { slotAvailability }
      );
      dispatch(
        fetchPanelMembersAvailabilityWeekend(panelMemberAvailabilityPayload)
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getEligibleDesignationForWeekendDatewise = createAsyncThunk(
  "getEligibleDesignationForWeekendDatewise",
  async ({ roleId, driveId }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/eligible/driveDesignations`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          driveId: driveId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const weekendDriveSlice = createSlice({
  name: "WeekendDrive",
  initialState,
  reducers: {
    resetWeekendDriveState() {
      return {
        ...initialState,
      };
    },
    applyFilterStatus(state, action) {
      return {
        ...state,
        isFilterApplied: true,
      };
    },
    resetDriveFilterStatus(state) {
      return {
        ...state,
        isFilterApplied: true,
        weekendDrive: {
          ...state.weekendDrive,
          filterStatus: INTERVIEW_STATUS_CHECK.SCHEDULED,
          activedesignationId: [],
          periodFrom: format(
            new Date().setMonth(new Date().getMonth() - 6),
            "yyyy-MM-dd"
          ),
          periodTo: format(
            new Date().setMonth(new Date().getMonth() + 6),
            "yyyy-MM-dd"
          ),
        },
      };
    },
    changeFilterStatus(state, action) {
      return {
        ...state,
        weekendDrive: {
          ...state.weekendDrive,
          filterStatus: action.payload,
        },
      };
    },
    changePositionFilter(state, action) {
      return {
        ...state,
        weekendDrive: {
          ...state.weekendDrive,
          activedesignationId: action.payload,
        },
      };
    },
    fromDateFilter(state, action) {
      return {
        ...state,
        weekendDrive: {
          ...state.weekendDrive,
          periodFrom: action.payload,
        },
      };
    },
    toDateFilter(state, action) {
      return {
        ...state,
        weekendDrive: {
          ...state.weekendDrive,
          periodTo: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeekendDriveDetailsByCompetency.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchWeekendDriveDetailsByCompetency.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              weekendDriveDetails: action.payload.weekendDrives,
              pageCount: action.payload.pageCount,
              totalDriveCount: action.payload.totalCount,
              loading: false,
            };
          }
          return {
            ...state,
            loading: false,
          };
        }
      )
      .addCase(
        fetchWeekendDriveDetailsByCompetency.rejected,
        (state, action) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message="Error Fetching Weekend Drive list."
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          state.loading = false;
        }
      )
      .addCase(fetchWeekendDriveByDate.pending, (state) => {
        state.loadingForWeekendListByDate = true;
      })
      .addCase(fetchWeekendDriveByDate.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            weekendDriveLists: action.payload.weekendDriveByDate,
            loadingForWeekendListByDate: false,
          };
        }
        return {
          ...state,
          loadingForWeekendListByDate: false,
        };
      })
      .addCase(fetchWeekendDriveByDate.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching Weekend Drive By Date."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loadingForWeekendListByDate = false;
      })
      .addCase(reportWeekendDrive.pending, (state) => {
        state.loaderForBookingInWeekend = true;
      })
      .addCase(reportWeekendDrive.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            weekendDriveReport: action.payload.data,
            loaderForBookingInWeekend: false,
          };
        }
        return {
          ...state,
          loaderForBookingInWeekend: false,
        };
      })
      .addCase(reportWeekendDrive.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching Weekend Report."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loaderForBookingInWeekend = false;
      })
      .addCase(reportWeekendDriveAvailableSlots.pending, (state) => {
        state.loaderForAvailableSlotsInWeekend = true;
      })
      .addCase(reportWeekendDriveAvailableSlots.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            weekendDriveReportForAvailableSlots: action.payload.data,
            loaderForAvailableSlotsInWeekend: false,
          };
        }
        return {
          ...state,
          loaderForAvailableSlotsInWeekend: false,
        };
      })
      .addCase(reportWeekendDriveAvailableSlots.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching Weekend Report."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loaderForAvailableSlotsInWeekend = false;
      })
      .addCase(reportWeekendDriveSlots.pending, (state) => {
        state.loaderForSlotsInWeekend = true;
      })
      .addCase(reportWeekendDriveSlots.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            weekendDriveReportForSlots: action.payload.data,
            loaderForSlotsInWeekend: false,
          };
        }
        return {
          ...state,
          loaderForSlotsInWeekend: false,
        };
      })
      .addCase(reportWeekendDriveSlots.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching Weekend Drive Report."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loaderForSlotsInWeekend = false;
      })
      .addCase(fetchWeekendDriveBookingByCompetency.pending, (state) => {
        state.loadWeekendBookings = true;
      })
      .addCase(
        fetchWeekendDriveBookingByCompetency.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              weekendBookings: action.payload.data,
              loadWeekendBookings: false,
            };
          }
          return {
            ...state,
            loadWeekendBookings: false,
          };
        }
      )
      .addCase(
        fetchWeekendDriveBookingByCompetency.rejected,
        (state, action) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message="Error Fetching Weekend Drive Bookings."
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          state.loadWeekendBookings = false;
        }
      )
      .addCase(
        fetchWeekendDriveCoordinatorListByCompetency.pending,
        (state) => {
          state.loading = true;
        }
      )
      .addCase(
        fetchWeekendDriveCoordinatorListByCompetency.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              coordinatorList: action.payload.coordinatorList,
              loading: false,
            };
          }
          return {
            ...state,
            loading: false,
          };
        }
      )
      .addCase(
        fetchWeekendDriveCoordinatorListByCompetency.rejected,
        (state, action) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message="Error Fetching Coordinator List."
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          state.loading = false;
        }
      )
      .addCase(saveWeekendDriveForSlot.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveWeekendDriveForSlot.fulfilled, (state, action) => {
        if (action.payload.code === 404) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={action.payload.message}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            loading: false,
          };
        }
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(saveWeekendDriveForSlot.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={"Error Occurred While Creating Weekend Drive."}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(editWeekendDriveForSlot.pending, (state) => {
        state.loading = true;
      })
      .addCase(editWeekendDriveForSlot.fulfilled, (state, action) => {
        if (action.payload.code === 404) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={action.payload.message}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            loading: false,
          };
        }
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(editWeekendDriveForSlot.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={"Error Occurred While Updating Weekend Drive."}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(fetchWeekendDriveFilterOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWeekendDriveFilterOptions.fulfilled, (state, action) => {
        if (action.payload)
          return {
            ...state,
            filtersArray: action.payload.data,
            loading: false,
          };
        state.loading = false;
      })
      .addCase(fetchWeekendDriveFilterOptions.rejected, (state) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={"Error Occurred While fetching filter options."}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(fetchMarkedWeekendDrive.pending, (state) => {
        state.weekendAvailabilityLoading = true;
      })
      .addCase(fetchMarkedWeekendDrive.fulfilled, (state, action) => {
        if (action.payload)
          return {
            ...state,
            existingWeekendDriveForUser: action.payload.driveAvailabilityData,
            weekendAvailabilityLoading: false,
          };
        state.weekendAvailabilityLoading = false;
      })
      .addCase(fetchMarkedWeekendDrive.rejected, (state) => {
        state.weekendAvailabilityLoading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={"Error Occurred While Fetching Panelist Availability."}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(markWeekendDriveAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(markWeekendDriveAvailability.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={
                action?.payload?.data?.message ||
                "Successfully Marked Drive Availability."
              }
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            loading: false,
          };
        }
        state.loading = false;
      })
      .addCase(markWeekendDriveAvailability.rejected, (state) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Marking Weekend Drive Availability."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(updateMarkedWeekendSlots.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMarkedWeekendSlots.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={
                action?.payload?.data?.message ||
                "Successfully Updated Drive Availability."
              }
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            loading: false,
          };
        }
        state.loading = false;
      })
      .addCase(updateMarkedWeekendSlots.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Updating Weekend Drive Availability."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(getEligibleDesignationForWeekendDatewise.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getEligibleDesignationForWeekendDatewise.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              eligibleDesignationsWeekend: action.payload?.designations,
              loading: false,
            };
          }
          state.loading = false;
        }
      )
      .addCase(getEligibleDesignationForWeekendDatewise.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching Weekend Drive Designations."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      });
  },
});

export const {
  resetWeekendDriveState,
  applyFilterStatus,
  resetDriveFilterStatus,
  changeFilterStatus,
  changePositionFilter,
  fromDateFilter,
  toDateFilter,
} = weekendDriveSlice.actions;
export default weekendDriveSlice.reducer;
