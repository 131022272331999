import IconWrap from "../components/SvgIcon/icon";

import availabilityIcon from "../assets/icons/availability-icon.svg";
import documentRepositoryIcon from "../assets/icons/document-repository-icon.svg";
import reportIcon from "../assets/icons/report-icon.svg";
import SelectedDashboardIcon from "../assets/icons/dashboard-active.svg";
import DashboardIcon from "../assets/icons/dashboard-inactive.svg";
import SelectedDesignationIcon from "../assets/icons/designation-active.svg";
import DesignationIcon from "../assets/icons/designation-inactive.svg";
import WeekendDriveIcon from "../assets/icons/weekend-drive.svg";
import SelectedWeekendDriveIcon from "../assets/icons/weekend-drive-active.svg";
import selectedAvailabilityIcon from "../assets/icons/selectedAvailability-icon.svg";
import selectedReportIcon from "../assets/icons/selectedReports-icon.svg";
import selectedDocumentRepositoryIcon from "../assets/icons/selectedDocumentRepository-icon.svg";
import SelectedPanelPoolIcon from "../assets/icons/panel-pool-active.svg";
import PanelPoolIcon from "../assets/icons/panel-pool-inactive.svg";
import SelectedManageDlIcon from "../assets/icons/manage-dl-active.svg";
import ManageDlIcon from "../assets/icons/manage-dl-inactive.svg";

import { format, subDays } from "date-fns";

const config = require("./../../config");

export const BASE_URL = `${config.baseUrl}`;
export const REPORT_BUG_GOOGLE_FORM_URL = `${config.reportBugGoogleFormUrl}`;
export const AUTH_INFO = "AUTH_INFO";
export const PERSIST_ROOT = "persist:root";

export const ROLE_TYPES = {
  PANEL_MEMBER: {
    role: "PANEL_MEMBER",
  },
  HR: {
    role: "HR",
  },
  SPOC: {
    role: "SPOC",
  },
  ADMIN: {
    role: "ADMIN",
  },

  isAdmin: (currentRole) => currentRole === ROLE_TYPES.ADMIN.role,
  isHr: (currentRole) => currentRole === ROLE_TYPES.HR.role,
  isSpoc: (role) => role === ROLE_TYPES.SPOC.role,
  isPanelMember: (role) => role === ROLE_TYPES.PANEL_MEMBER.role,
};
export const ADMIN_ROLE_TYPES = {
  HR: "HR",
  SPOC: "SPOC",
  ADMIN: "ADMIN",
};
export const redirectToNewersWorldProfileByUserId = (userId) => {
  const linkUrl = `https://newersworld.tothenew.com/#/unifiedEmployeeView/${userId}`;
  const newTab = window.open(linkUrl, "_blank");
  if (newTab) {
    newTab.focus();
  }
};
export const ROUTE_CONSTANTS = {
  LOGIN: "/",
  DASHBOARD: "dashboard",
  ROSTER: "roster",
  MANAGE_DL: "AdminTab",
  REPORTS_CONFIGURATIONS: "AdminConfigDl",
  AVAILABILITY: "Availability",
  REPORTS: "Reports",
  DOCUMENT_REPOSITORY: "DocumentRepository",
  DESIGNATION: "Designation",
  REDIRECT: "Redirect",
  WEEKEND_DRIVE: "weekendDrive",
};
export const PUBLIC_ROUTES = ["login", "Redirect", ""];

export const getMenuItems = (role) => {
  const items = [
    ...(ROLE_TYPES.isHr(role)
      ? [
          {
            key: "DASHBOARD",
            label: "Dashboard",
            path: ROUTE_CONSTANTS.DASHBOARD,
            showSearch: false,
            icon: <IconWrap icon={DashboardIcon} />,
            selectedIcon: <IconWrap icon={SelectedDashboardIcon} />,
          },
        ]
      : []),
    ...(!ROLE_TYPES.isPanelMember(role)
      ? [
          {
            key: "ROSTER",
            label: "Panel Pool",
            path: ROUTE_CONSTANTS.ROSTER,
            showSearch: true,
            icon: <IconWrap icon={PanelPoolIcon} />,
            selectedIcon: <IconWrap icon={SelectedPanelPoolIcon} />,
          },
        ]
      : []),

    ...(ROLE_TYPES.isAdmin(role)
      ? [
          {
            key: "MANAGE_DL",
            label: "Manage DL",
            path: ROUTE_CONSTANTS.MANAGE_DL,
            showSearch: false,
            icon: <IconWrap icon={ManageDlIcon} />,
            selectedIcon: <IconWrap icon={SelectedManageDlIcon} />,
          },
          {
            key: "REPORTS_CONFIGURATIONS",
            label: "Reports Configurations",
            path: ROUTE_CONSTANTS.REPORTS_CONFIGURATIONS,
            showSearch: false,
            icon: <IconWrap icon={reportIcon} />,
            selectedIcon: <IconWrap icon={selectedReportIcon} />,
          },
        ]
      : []),
    ...(!ROLE_TYPES.isAdmin(role)
      ? [
          {
            key: "AVAILABILITY",
            label: "Availability",
            path: ROUTE_CONSTANTS.AVAILABILITY,
            showSearch: !ROLE_TYPES.isPanelMember(role) ? true : false,
            icon: <IconWrap icon={availabilityIcon} />,
            selectedIcon: <IconWrap icon={selectedAvailabilityIcon} />,
          },
          {
            key: "DOCUMENT_REPOSITORY",
            label: "Document Repository",
            path: ROUTE_CONSTANTS.DOCUMENT_REPOSITORY,
            showSearch: false,
            icon: <IconWrap icon={documentRepositoryIcon} />,
            selectedIcon: <IconWrap icon={selectedDocumentRepositoryIcon} />,
          },
        ]
      : []),
    ...(ROLE_TYPES.isSpoc(role)
      ? [
          {
            key: "DESIGNATIONS",
            label: "Designations",
            path: ROUTE_CONSTANTS.DESIGNATION,
            showSearch: false,
            icon: <IconWrap icon={DesignationIcon} />,
            selectedIcon: <IconWrap icon={SelectedDesignationIcon} />,
          },
        ]
      : []),
    ...(ROLE_TYPES.isHr(role) || ROLE_TYPES.isSpoc(role)
      ? [
          {
            key: "REPORTS",
            label: "Reports",
            path: ROUTE_CONSTANTS.REPORTS,
            showSearch: false,
            icon: <IconWrap icon={reportIcon} />,
            selectedIcon: <IconWrap icon={selectedReportIcon} />,
          },
        ]
      : []),
    ...(ROLE_TYPES.isHr(role) || ROLE_TYPES.isSpoc(role)
      ? [
          {
            key: "WEEKEND_DRIVE",
            label: "Weekend Drive",
            path: ROUTE_CONSTANTS.WEEKEND_DRIVE,
            showSearch: false,
            icon: <IconWrap icon={WeekendDriveIcon} />,
            selectedIcon: <IconWrap icon={SelectedWeekendDriveIcon} />,
          },
        ]
      : []),
  ];

  return items;
};
export const headersForBookingDetailsDataExport = [
  { header: "Competency", key: "competency" },
  { header: "Interviewer Name", key: "emp_name" },
  { header: "Interviewer Email", key: "email" },
  { header: "Tags", key: "tags" },
  { header: "Round", key: "round" },
  { header: "Booking Designation", key: "booking_designation" },
  { header: "Candidate Email", key: "candidate_email" },
  { header: "Pairing With", key: "pairing_with" },
  { header: "Booking Date", key: "booking_date" },
  { header: "Booking Time", key: "booking_time" },
  { header: "Recruiter Name", key: "booking_assigned_by" },
  { header: "Booking Status", key: "booking_status" },
  { header: "Booking Interviewer Status", key: "interviewer_status" },
];
export const headersForPanelistDataExport = [
  { header: "Name", key: "name" },
  { header: "Email", key: "email" },
  { header: "Designation", key: "designation" },
  { header: "Tags", key: "tags" },
  { header: "Slots Provided", key: "slots_provided" },
  { header: "Total Bookings", key: "bookings_total" },
  { header: "Interviews Scheduled", key: "scheduled" },
  { header: "Interviews Completed", key: "completed" },
  { header: "Slots Not Filled", key: "slots_not_filled" },
  { header: "Interviews To Be Scheduled", key: "to_be_scheduled" },
  {
    header: "Interviews Cancelled (Interviewer Not Available)",
    key: "interviewer_na",
  },
  {
    header: "Interviews Cancelled (Interviewer Rescheduled)",
    key: "interviewer_rescheduled",
  },
  {
    header: "Interviews Cancelled (Candidate Not Available)",
    key: "candidate_na",
  },
  {
    header: "Interviews Cancelled (Candidate Rescheduled)",
    key: "candidate_rescheduled",
  },
  { header: "Working Days", key: "total_slots" },
];

export const ROSTER_FILTER_TAB_CHECK_VALUE = {
  SUSPENDED: "suspended",
  ACTIVE: "active",
  TAGS: "tags",
  ALL: "all",
  ADMIN: "admin",
};

let currentDate = new Date();
export const DATE_OF_JOINING_FILTER_OPTIONS = [
  {
    key: "All Joining Dates",
    label: "All Joining Dates",
    value: "",
  },
  {
    key: "Less then 2 week",
    label: "Less then 2 week",
    value: format(subDays(currentDate, 14), "yyyy-MM-dd"),
  },
  {
    key: "Less then 1 month",
    label: "Less then 1 month",
    value: format(subDays(currentDate, 30), "yyyy-MM-dd"),
  },
  {
    key: "Less then 3 months",
    label: "Less then 3 months",
    value: format(subDays(currentDate, 90), "yyyy-MM-dd"),
  },
  {
    key: "Less then 6 months",
    label: "Less then 6 months",
    value: format(subDays(currentDate, 180), "yyyy-MM-dd"),
  },
  {
    key: "Custom",
    label: "Custom Date",
    value: "Custom",
  },
];
export const SUBCOMPETENCY_FILTERS_JS = [
  {
    label: "Fullstack",
    value: "Fullstack",
  },
  {
    label: "Frontend",
    value: "Frontend",
  },
  {
    label: "UI developer",
    value: "UI developer",
  },
  {
    label: "Backend",
    value: "Backend",
  },
];
export const JOINING_DATE_VALUES = {
  ALL: "All Joining Dates",
  CUSTOM: "Custom",
  LESS_THAN_TWO_WEEK: "Less then 2 week",
  LESS_THAN_ONE_MONTH: "Less then 1 month",
  LESS_THAN_THREE_MONTHS: "Less then 3 months",
  LESS_THAN_SIX_MONTHS: "Less then 6 months",
};
export const ROUND_FILTER_OPTIONS = {
  ALL: "All Rounds",
  R1: "Round 1",
  R2: "Round 2",
  MR: "Managerial Round",
};
export const sortArrayOfObjects = (arrName) => {
  return arrName.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};
export const JS_COMPETENCY_ID = 38835;

export const MONTH_NAME = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
export const FULL_MONTH_NAME = {
  1: "JANUARY",
  2: "FEBRUARY",
  3: "MARCH",
  4: "APRIL",
  5: "MAY",
  6: "JUNE",
  7: "JULY",
  8: "AUGUST",
  9: "SEPTEMBER",
  10: "OCTOBER",
  11: "NOVEMBER",
  12: "DECEMBER",
};
export const WEEK_DAYS = {
  MONDAY: { day: "Monday" },
  TUESDAY: { day: "Tuesday" },
  WEDNESDAY: { day: "Wednesday" },
  THURSDAY: { day: "Thursday" },
  FRIDAY: { day: "Friday" },
  SATURDAY: { day: "Saturday" },
};
export const SLOT_FILTER_OPTIONS = {
  ALL: "All Slots",
  NOT_AVAILABLE: "Not Available",
  NOT_PROVIDED: "Not Provided",
  PROVIDED: "Provided",
  UNUTILIZED: "Unutilized",
};
export const SLOT_FILTER_OPTIONS_WEEKEND = {
  ALL: "All Slots",
  SLOT_AVAILABLE: "Available",
  NOT_AVAILABLE: "Not Available",
  NOT_PROVIDED: "Not Provided",
};
export const INTERVIEW_STATUS_OPTIONS = {
  INTERVIEWER_NOT_AVAILABLE: "Interviewer Not Available",
  INTERVIEWER_RESCHEDULED: "Interviewer Rescheduled",
  CANDIDATE_NOT_AVAILABLE: "Candidate Not Available",
  CANDIDATE_RESCHEDULED: "Candidate Rescheduled",
  TO_BE_SCHEDULED: "To Be Scheduled",
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  INVALID: "Invalid",
};
export const INTERVIEW_STATUS_OPTIONS_SHORTFORM = {
  INTERVIEWER_NOT_AVAILABLE: "Interviewer NA",
  INTERVIEWER_RESCHEDULED: "Interviewer RS",
  CANDIDATE_NOT_AVAILABLE: "Candidate NA",
  CANDIDATE_RESCHEDULED: "Candidate RS",
  COMPLETED: "Completed",
  SCHEDULED: "Scheduled",
  INVALID: "Invalid",
  TO_BE_SCHEDULED: "To Be Scheduled",
  ACTIVE: "Pairing Removed",
};
export const ROSTER_FILTER_TABS = {
  SUSPENDED: "SUSPENDED",
  ACTIVE: "ACTIVE",
  TAGS: "TAGS",
  ALL: "ALL",
  ADMIN: "ADMIN",
};

export const SORT_TYPE_CHECK_VALUE = {
  NAME: "name",
  DATE_OF_JOINING: "dateOfJoining",
  DESIGNATION_NAME: "designationName",
  PANEL_COUNT: "panelCount",
  TOTAL_COUNT: "totalCount",
  DATE_TIME: "dateTime",
  COMPETENCY: "competency",
  DATE_OF_DRIVE: "dateOfDrive",
};
export const SORT_ORDER = {
  ASCENDING: "ASC",
  DESCENDING: "DESC",
};
export const ROLES = {
  HR: "HR",
  SPOC: "SPOC",
  ADMIN: "ADMIN",
};
export const INTERVIEW_STATUS_OPTIONS_CREATE_BOOKING_MODAL = {
  SCHEDULED: "Scheduled",
  TO_BE_SCHEDULED: "To Be Scheduled",
};
export const INTERVIEW_STATUS_OPTIONS_UPDATE_BOOKING_PAIRED_VIEW_MODAL = {
  INTERVIEWER_NOT_AVAILABLE: "Interviewer Not Available",
  SCHEDULED: "Scheduled",
  INVALID: "Invalid",
};
export const INTERVIEW_STATUS_OPTIONS_UPDATE_BOOKING_MODAL = {
  INTERVIEWER_NOT_AVAILABLE: "Interviewer Not Available",
  INTERVIEWER_RESCHEDULED: "Interviewer Rescheduled",
  CANDIDATE_NOT_AVAILABLE: "Candidate Not Available",
  CANDIDATE_RESCHEDULED: "Candidate Rescheduled",
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  INVALID: "Invalid",
};
export const INTERVIEW_MODE = {
  VIRTUAL: { label: "VIRTUAL", value: "Virtual" },
  IN_PERSON: { label: "IN_PERSON", value: "In Person" },
};
export const INTERVIEW_STATUS_CHECK = {
  SCHEDULED: "SCHEDULED",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  INVALID: "INVALID",
  TO_BE_SCHEDULED: "TO_BE_SCHEDULED",
  INTERVIEWER_RESCHEDULED: "INTERVIEWER_RESCHEDULED",
  INTERVIEWER_NOT_AVAILABLE: "INTERVIEWER_NOT_AVAILABLE",
  CANDIDATE_NOT_AVAILABLE: "CANDIDATE_NOT_AVAILABLE",
  CANDIDATE_RESCHEDULED: "CANDIDATE_RESCHEDULED",
};
export const INTERVIEW_STATUS_OPTIONS_SHORTFORM_EXPANDED = {
  INTERVIEWER_NOT_AVAILABLE: "Interviewer Not Available",
  INTERVIEWER_RESCHEDULED: "Interviewer Rescheduled",
  CANDIDATE_NOT_AVAILABLE: "Candidate Not Available",
  CANDIDATE_RESCHEDULED: "Candidate Rescheduled",
};
export const INTERVIEW_STATUS_OPTIONS_FOR_PAIRING_REMOVE = {
  INTERVIEWER_NOT_AVAILABLE: {
    label: "Interviewer Not Available",
    value: "INTERVIEWER_NOT_AVAILABLE",
  },
  INTERVIEWER_RESCHEDULED: {
    label: "Interviewer Rescheduled",
    value: "INTERVIEWER_RESCHEDULED",
  },
};
export const CREATE_PAIRING_OPTIONS = {
  YES: "Yes",
  NO: "No",
  REMOVE_PAIRING: "Remove Pairing",
};
export const TOAST_TYPE = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};
export const MODULE_NAME = {
  ROSTER: "ROSTER",
  AVAILABILITY: "AVAILABILITY",
};
export const staticHeadersForCsvExport = [
  { header: "Emp Id", key: "emp_id" },
  { header: "Name", key: "name" },
  { header: "Email", key: "email" },
  { header: "Mobile Number", key: "mobileNumber" },
  { header: "Designation", key: "designation" },
  { header: "Reporting Manager", key: "reporting_manager" },
  { header: "Mentor", key: "mentor" },
  { header: "Notes", key: "notes" },
];
export const TOAST_TIME = {
  ONE: "1000",
  TWO: "2000",
  THREE: "3000",
  FOUR: "4000",
};
export const TOAST_TITLE = {
  ERROR: "Error",
  SUCCESS: "Success",
  WARNING: "warning",
};
export const DRIVE_DAY_CONFIRMATION_STATUS = {
  PRESENT: "Present",
  RESCHEDULED: "Re-scheduled",
  NOT_RESPONDING: "Not Responding",
  NOT_INTERESTED: "Not Interested",
};

export const DAY_MINUS_ONE_CONFIRMATION_STATUS = {
  ABSENT: "Absent",
  PRESENT: "Present",
  RESCHEDULED: "Re-scheduled",
};

export const CANDIDATE_INTERVIEW_STATUS = {
  REJECT_TECH_1: "Reject- Tech 1",
  REJECT_TECH_2: "Reject- Tech 2",
  SELECT_TECH_1: "Select- Tech 1",
  SELECT_TECH_2: "Select- Tech 2",
  REJECT_HR: "Reject - HR",
  SELECT_HR: "Select - HR",
};
export const CANDIDATE_STATUSES = {
  DRIVE_DAY_CONFIRMATION_STATUS: "DRIVE_DAY_CONFIRMATION",
  DAY_MINUS_ONE_CONFIRMATION_STATUS: "DAY_BEFORE_CONFIRMATION",
  CANDIDATE_INTERVIEW_STATUS: "CANDIDATE_INTERVIEW_STATUS",
};
export const headersForBookingDetailsDataExportWeekend = [
  { header: "Drive Date", key: "drive_date" },
  { header: "Competency", key: "competency" },
  { header: "Panel's Email Id", key: "panel_email_id" },
  { header: "Pairing Panel's Email Id", key: "paired_email" },
  { header: "Available Slot", key: "booked_slot" },
  { header: "Recruiter's Email Id", key: "recruiter_email" },
  { header: "Hiring Level", key: "hiring_designation" },
  { header: "Candidate Name", key: "candidate_name" },
  { header: "Candidate's Mobile No.", key: "candidate_mobile_number" },
  { header: "Resume Link", key: "candidate_resume_link" },
  { header: "Candidate's Email", key: "candidate_email" },
  { header: "Interview Round", key: "round" },
  { header: "Meeting Links", key: "calendar_event_link" },
  { header: "Status", key: "candidate_interview_status" },
  { header: "Day -1 Confirmation", key: "day_minus_one_confirmation" },
  { header: "Drive Day Confirmation", key: "drive_day_confirmation" },
  { header: "Remarks", key: "notes" },
];

export const headersForAvailableSlotsDetailsDataExportWeekend = [
  { header: "Panel's Email Id", key: "panel_email_id" },
  { header: "Eligibility", key: "hiring_level" },
  { header: "Availability", key: "availability" },
];

export const headersForPanelistDataExportWeekend = [
  { header: "Name", key: "name" },
  { header: "Email", key: "email" },
  { header: "Designation", key: "designation" },
  { header: "Slots Provided", key: "slots_provided" },
  { header: "Total Bookings", key: "bookings_total" },
  { header: "Interviews Scheduled", key: "scheduled" },
  { header: "Interviews Completed", key: "completed" },
  {
    header: "Interviewer Not Available",
    key: "interviewer_na",
  },
  {
    header: "Interviewer Rescheduled",
    key: "interviewer_rescheduled",
  },
  {
    header: "Candidate Not Available",
    key: "candidate_na",
  },
  {
    header: "Candidate Rescheduled",
    key: "candidate_rescheduled",
  },
];
export const INTERVIEW_STATUS_WEEKEND_DRIVE = {
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  CANCELLED: "Cancel Drive",
};
export const INTERVIEW_STATUS_WEEKEND_DRIVE_FILTER = {
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled Drive",
};
export const formatString = (inputString) => {
  const separatedStrings = inputString.toLowerCase().split("_");
  const formattedString = separatedStrings
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
  return formattedString;
};
export const DASHBOARD_CARD_ICONS_COLOR = {
  AVAILABLE_SLOT: "#8C89FC",
  BOOKED_SLOT: "#7BD2EA",
  UNUTILISED_SLOT: "#6DDCBA",
  WEEKEND_DRIVE_DATA: "#DE1186",
  SUSPENDED_PANEL: "#2A88CC",
};
export const DASHBOARD_CARD_TITLE = {
  AVAILABLE_SLOT: "Provided Slots",
  BOOKED_SLOT: "Booked Slots",
  UNUTILISED_SLOT: "Unutilised Slots",
};
export const DASHBOARD_WEEKEND_DRIVE_CARD_TITLE = {
  DRIVES_CONDUCTED: "Total Drives Conducted",
  DRIVES_CREATED: "Drives Created",
  UPCOMING_DRIVES: "Upcoming Drives",
};
export const DASHBOARD_INTERVIEWS = {
  INTERVIEWS_STATUS_TO_INCLUDE: [
    "COMPLETED",
    "INVALID",
    "SCHEDULED",
    "TO_BE_SCHEDULED",
    "CANDIDATE_NOT_AVAILABLE",
    "CANDIDATE_RESCHEDULED",
    "INTERVIEWER_NOT_AVAILABLE",
    "INTERVIEWER_RESCHEDULED",
  ],
  INTERVIEWS_STATUS_TO_EXCLUDE: ["DRIVE_CANCELLED", "DRIVE_LEVELS_UPDATED"],
};
export const INTERVIEW_CARD_ORDER = [
  "SCHEDULED",
  "TO_BE_SCHEDULED",
  "COMPLETED",
  "INTERVIEWER_RESCHEDULED",
  "CANDIDATE_RESCHEDULED",
  "INTERVIEWER_NOT_AVAILABLE",
  "CANDIDATE_NOT_AVAILABLE",
  "INVALID",
];
export const KEY_MAPPING_INTERVIEW_STATUSES = {
  scheduled: "SCHEDULED",
  to_be_scheduled: "TO_BE_SCHEDULED",
  completed: "COMPLETED",
  interviewer_na: "INTERVIEWER_NOT_AVAILABLE",
  interviewer_rs: "INTERVIEWER_RESCHEDULED",
  candidate_na: "CANDIDATE_NOT_AVAILABLE",
  candidate_rs: "CANDIDATE_RESCHEDULED",
  invalid: "INVALID",
  // Adding missing statuses with a default count of "0"
  drive_cancelled: "DRIVE_CANCELLED",
  drive_levels_updated: "DRIVE_LEVELS_UPDATED",
};
export const ADMIN_DL_DRIVE_OPTIONS = {
  WEEKDAYS_BOOKINGS_REPORT: "Weekdays Bookings Report",
  WEEKEND_BOOKINGS_REPORT: "Weekend Bookings Report",
};
export const ADMIN_DL_DRIVE_VALUES = {
  WEEKDAYS_BOOKINGS_REPORT: "WEEKDAYS_BOOKINGS_REPORT",
  WEEKEND_BOOKINGS_REPORT: "WEEKEND_BOOKINGS_REPORT",
};
export const stepsWeekendDrive = [
  { key: 0, value: "Drive Details" },
  { key: 1, value: "Select Slots" },
];
export const WEEKEND_TAB_KEY = {
  INTERVIEWS_VIEW: "defaultWeekend",
  BACKLOG_VIEW: "backupBooking",
  STATUS_VIEW: "candidateStatus",
};
export const CHECK_TAB_KEY = {
  WEEKEND: "weekend",
  WEEKDAYS: "weekdays",
};
export const DRIVE_CANCEL_REASONS = {
  PANEL_NOT_AVAILABLE: "Panel Not Available",
  CANDIDATES_NOT_AVAILABLE: "Candidates Not Available",
  UNFORESEEN_CIRCUMSTANCES: "Unforeseen Circumstances",
};
export const INTERVIEW_STATUS_OPTIONS_FOR_EXPORT_BOKKING_DATA = {
  INTERVIEWER_NOT_AVAILABLE: "Interviewer Not Available",
  CORE_INTERVIEWER_NOT_AVAILABLE: "Core Interviewer Not Available",
  INTERVIEWER_RESCHEDULED: "Interviewer Rescheduled",
  CORE_INTERVIEWER_RESCHEDULED: "Core Interviewer Rescheduled",
  CANDIDATE_NOT_AVAILABLE: "Candidate Not Available",
  CANDIDATE_RESCHEDULED: "Candidate Rescheduled",
  TO_BE_SCHEDULED: "To Be Scheduled",
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  INVALID: "Invalid",
  ACTIVE: "Active"
};
export const checkEqualDates = (date1, date2) => {
  return (
    new Date(date1).getDate() === new Date(date2).getDate() &&
    new Date(date1).getMonth() === new Date(date2).getMonth() &&
    new Date(date1).getFullYear() === new Date(date2).getFullYear()
  );
};
export const bootcamp_url = `https://bootcamp.tothenew.com/`;
export const growth_url = `https://growth.tothenew.com/`;
export const referral_url = `https://referral.tothenew.com/`;
export const investment_url = `https://investment-declaration.tothenew.com/`;
export const asset_url = `https://asset-desk.tothenew.com/`;
export const reimbursement_url = `https://reimbursement.tothenew.com/`;
export const timesheet_url = `https://timesheet.tothenew.com`;
export const skillmatrix_url = `https://skillmatrix.tothenew.com/`;
export const LEGEND_OPTIONS_COLORS = [
  { value: "#eaedc9" },
  { value: "#ffbab6" },
];
export const LEGEND_OPTIONS = ["Panel member", "Suspended"];
export const SEND_AVAILABILITY_REMINDERS = "SEND_AVAILABILITY_REMINDERS";
export const ALPHA_NUMERIC_VALUES = /[^a-zA-Z0-9\s]/;

export const currentRosterTabsState =(tab)=>{
  if (tab === "all"){
    return 0;
  }
  if (tab === "active"){
    return 1;
  }
  if (tab === "suspended"){
    return 2;
  }
  else if (tab === "tags"){
    return 3;
  }
}
export const currentAvailabilityTabsState =(tab)=>{
  if (tab === "weekdays"){
    return 0;
  }
  if (tab === "weekend"){
    return 1;
  }
}
export const AvailabilityTabs=['weekdays','weekend'];
export const RosterTabs=['all','active','suspended', 'tags'];
