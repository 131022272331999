import React, { useState, useEffect } from "react";

import Icon from "../../../../components/SvgIcon/icon";
import { IOSSwitch } from "../../../../components/Switch/IoSSwitch";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import AddIcon from "../../../../assets/icons/ad-icon.svg";
import DeleteIcon from "../../../../assets/icons/delete-icon.svg";
import closeIcon from "../../../../assets/icons/close-icon.svg";

import dayjs from "dayjs";

import { dateToString } from "../../../../utils/date-utils";

import styles from "./style.module.scss";
import theme from "../../../../theme";

const AvailabilityUpdateModal = ({
  user,
  slotBookDate,
  showAvailabilityModal,
  setShowAvailabilityModal,
  slotDetailsOfTheDay,
  createOrUpdateAvailability,
}) => {
  const [isAvailable, setIsAvailable] = useState(true);
  const [slotTimes, setSlotTimes] = useState([]);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (slotInfo?.slotId) {
      setIsAvailable(slotInfo.isAvailable ? true : false);
    } else {
      setIsAvailable(true);
    }
    setReason(slotInfo.comments);
    const slotTime = formatSlotTime(slotInfo?.slotTime);
    if (slotTime?.length) {
      setSlotTimes(slotTime);
    } else {
      setSlotTimes([
        {
          startTime: new Date().setHours(10, 0, 0, 0),
          endTime: new Date().setHours(17, 0, 0, 0),
        },
      ]);
    }
  }, []);
  useEffect(() => {
    let { error, errorMessage } = validateForm();
    setError(error);
    setErrorMessage(errorMessage);
    if (!error && isAvailable) {
      setError(false);
      setErrorMessage("");
    }
  }, [slotTimes, error, errorMessage]);

  const slotInfo = slotDetailsOfTheDay[0] || {};
  const handleClose = () => setShowAvailabilityModal(false);
  const validateForm = () => {
    let error = false,
      errorMessage = "";
    if (isAvailable) {
      if (!slotTimes?.length) {
        error = error ? error : true;
        errorMessage = "Please enter a valid time slot.";
      }
      slotTimes?.map((item) => {
        let min_end = new Date(item.endTime).getMinutes();
        let min_start = new Date(item.startTime).getMinutes();
        let hour_end = new Date(item.endTime).getHours();
        let hour_start = new Date(item.startTime).getHours();
        let total_start_min = hour_start * 60 + min_start;
        let total_end_min = hour_end * 60 + min_end;
        let minutes = total_end_min - total_start_min;
        if (hour_start < hour_end) {
          if (minutes > 0 && minutes < 60) {
            error = true;
          }
        } else {
          error = true;
        }
        if (error) {
          errorMessage = "Please enter a valid time slot.";
        }
        return error;
      });
    } else {
      if (!reason) error = error ? error : true;
    }
    return { error, errorMessage };
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      slotId: slotDetailsOfTheDay[0]?.slotId,
      userPanelMappingId: user.panel_id,
      panelCompetencyId: user.panel_competency_id,
      slotTime: isAvailable
        ? slotTimes?.map((item) => ({
            startTime: dateToString(item.startTime),
            endTime: dateToString(item.endTime),
          }))
        : [],
      dateOfAvailable: new Date(slotBookDate).toISOString(),
      comments: isAvailable ? "" : reason ?? "",
    };
    createOrUpdateAvailability(payload);
    handleClose();
  };
  const formatSlotTime = (slotTime = []) => {
    return slotTime
      ?.filter((time) => time)
      ?.map((time) => {
        const startTime = new Date(time.startTime);
        const endTime = new Date(time.endTime);
        return { startTime, endTime };
      });
  };
  const onSetOrUpdateSlotTime = (startTime, endTime, index) => {
    const updatedSlotTimes = slotTimes?.map((data, i) => {
      if (index === i) {
        return {
          ...data,
          startTime: startTime ? startTime : data.startTime,
          endTime: endTime ? endTime : data.endTime,
        };
      }
      return data;
    });
    setSlotTimes(updatedSlotTimes);
  };
  const onDeleteSlot = (index) => {
    const newFields = [...slotTimes];
    newFields.splice(index, 1);
    setSlotTimes(() => newFields);
    if (!newFields?.length) setIsAvailable(false);
  };
  const onSetAvailable = (value) => {
    if (!slotTimes?.length)
      setSlotTimes([
        {
          startTime: new Date().setHours(10, 0, 0, 0),
          endTime: new Date().setHours(17, 0, 0, 0),
        },
      ]);
    setIsAvailable(value);
  };
  const closeModal = () => {
    setShowAvailabilityModal(false);
  };

  return (
    <>
      <Dialog
        open={showAvailabilityModal}
        onClose={closeModal}
        PaperProps={{
          style: {
            width: "27rem",
            maxHeight: "50rem",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle
          color={theme.palette.secondary.main}
          sx={{ padding: "30px 24px 0px" }}
        >
          Mark or Update Availability
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <DialogContent
          sx={{
            padding: "0px 14px 0px",
            maxHeight: "15rem",
            overflowX: "hidden",
          }}
        >
          <Grid
            container
            marginLeft={0}
            marginRight={0}
            marginBottom={1}
            marginTop={0}
            pr={2}
            spacing={2}
          >
            <Grid
              item
              display={"flex"}
              alignItems={"left"}
              xs={12}
              md={12}
              lg={12}
              xl={12}
            >
              <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} />}
                label=<Typography className={styles.unavailableText}>
                  Available
                </Typography>
                className={styles.switchStyle}
                disableTypography
                checked={isAvailable}
                onChange={(e) => onSetAvailable(e.target.checked)}
              />
            </Grid>
            {error && isAvailable && (
              <Typography
                sx={{ mx: 1.7, my: 0.5 }}
                className={styles.unavailableText}
              >
                {errorMessage}
              </Typography>
            )}
            {isAvailable &&
              slotTimes?.map((slot, index) => (
                <Grid
                  item
                  spacing={2}
                  alignItems="center"
                  display={"flex"}
                  marginTop={0.1}
                  marginRight={1.5}
                >
                  <Grid item xs={5} md={5} lg={5} xl={5} marginRight={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        closeOnSelect={false}
                        minutesStep={15}
                        fullWidth
                        views={["hours", "minutes"]}
                        value={
                          dayjs(slot.startTime) ||
                          dayjs(new Date().setHours(10, 0, 0, 0))
                        }
                        onChange={(newValue) => {
                          if (newValue && String(newValue) !== "Invalid Date") {
                            onSetOrUpdateSlotTime(newValue, null, index);
                          }
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "33px",
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                        }}
                        className={styles.timeInput}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5} md={5} lg={5} xl={5} marginRight={1}>
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        fullWidth
                        minutesStep={15}
                        closeOnSelect={false}
                        value={
                          dayjs(slot.endTime) ||
                          dayjs(new Date().setHours(17, 0, 0, 0))
                        }
                        onChange={(newValue) => {
                          if (newValue && String(newValue) !== "Invalid Date") {
                            onSetOrUpdateSlotTime(null, newValue, index);
                          }
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "33px",
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#000000",
                          },
                        }}
                        className={styles.timeInput}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    md={2}
                    lg={2}
                    xl={2}
                    display="flex"
                    justifyContent={"end"}
                  >
                    <IconButton
                      onClick={() =>
                        setSlotTimes([
                          ...slotTimes,
                          {
                            startTime: new Date().setHours(10, 0, 0, 0),
                            endTime: new Date().setHours(17, 0, 0, 0),
                          },
                        ])
                      }
                    >
                      <Icon icon={AddIcon} width="16.5px" height="16.77px" />
                    </IconButton>
                    <IconButton onClick={() => onDeleteSlot(index)}>
                      <Icon icon={DeleteIcon} width="16.5px" height="16.77px" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            {!isAvailable && (
              <Grid container spacing={1} marginLeft={1} marginRight={2}>
                <Grid
                  item
                  marginBottom={1}
                  marginTop={1}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Typography className={styles.weekdaysText}>
                    Reason
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    value={reason}
                    fullWidth
                    label="Not Available! Specify the reason"
                    className={styles.timeInput}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            marginLeft={2.5}
            marginRight={2.5}
            marginBottom={1}
            marginTop={1}
          >
            <Grid item xs={12} md={12} lg={12} xl={12} align="center">
              <Button
                fullWidth
                disabled={
                  (isAvailable && (error || !slotTimes?.length)) ||
                  (!isAvailable && (!reason || /^\s*$/.test(reason)))
                }
                variant={"contained"}
                onClick={onFormSubmit}
                className={
                  (
                    isAvailable
                      ? !slotTimes?.length && error
                      : !reason || /^\s*$/.test(reason)
                  )
                    ? `${styles.saveBtnDisable}`
                    : `${styles.saveBtn}`
                }
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AvailabilityUpdateModal;
