import React, { useState, useEffect } from "react";

import WarningPopup from "./WarningPopup";

import {
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
} from "@mui/material";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  INPERSON_DESCRIPTION_TEMPLATE,
  VIRTUAL_DESCRIPTION_TEMPLATE,
} from "../../../../utils/app-utils";
import {
  INTERVIEW_MODE,
  INTERVIEW_STATUS_CHECK,
  INTERVIEW_STATUS_OPTIONS_SHORTFORM_EXPANDED,
} from "../../../../constants";

import styles from "./style.module.scss";

const EventDetailsStep = (props) => {
  const {
    createEvent,
    setCreateEvent,
    calendarEventTitle,
    setCalendarEventTitle,
    eventDescription,
    setEventDescription,
    interviewMode,
    intervieweName,
    intervieweEmail,
    booking: bookingDataForUpdate,
    pairingData: pairingDataEnabled,
    handleRequiredCalendaEventTitle,
    setHandleRequiredCalendarEventTitle,
    deleteEventOnBooking,
    setDeleteEventOnBooking,
    isNaOrRsStatusPopupNoClicked,
    interviewStatus,
    disableFieldsForPairedView,
    isDisableMultipleFields,
  } = props;
  
  const [initialDescription, setInitialDescription] = useState("");

  useEffect(() => {
    if (INTERVIEW_MODE.VIRTUAL?.label === interviewMode) {
      let descriptionWithCandidateName =
        VIRTUAL_DESCRIPTION_TEMPLATE(intervieweName);
      if (descriptionWithCandidateName)
        setInitialDescription(descriptionWithCandidateName);
    }
    if (INTERVIEW_MODE.IN_PERSON?.label === interviewMode) {
      let descriptionWithCandidateName =
        INPERSON_DESCRIPTION_TEMPLATE(intervieweName);
      if (descriptionWithCandidateName)
        setInitialDescription(descriptionWithCandidateName);
    }
  }, [intervieweName, interviewMode]);
  useEffect(() => {
    if (!pairingDataEnabled?.length && !bookingDataForUpdate) {
      if (INTERVIEW_MODE.VIRTUAL?.label === interviewMode) {
        setEventDescription(initialDescription);
      }
      if (INTERVIEW_MODE.IN_PERSON?.label === interviewMode) {
        setEventDescription(initialDescription);
      }
    }
  }, [
    initialDescription,
    interviewMode,
    pairingDataEnabled?.length,
    setEventDescription,
    bookingDataForUpdate,
  ]);
  useEffect(() => {
    if (
      pairingDataEnabled &&
      pairingDataEnabled !== "undefined" &&
      Object.keys(pairingDataEnabled).length !== 0
    ) {
      setCreateEvent(pairingDataEnabled?.calendar_event_id ? true : false);
      setCalendarEventTitle(
        pairingDataEnabled?.calendar_event_title
          ? pairingDataEnabled?.calendar_event_title
          : calendarEventTitle
      );
    } else if (
      bookingDataForUpdate &&
      bookingDataForUpdate?.candidateDetailsId !== null
    ) {
      setCreateEvent(bookingDataForUpdate?.calendarEventId ? true : false);
      setCalendarEventTitle(
        bookingDataForUpdate?.calendarEventTitle === null
          ? calendarEventTitle
          : bookingDataForUpdate?.calendarEventTitle
      );
    }
  }, [pairingDataEnabled, bookingDataForUpdate]);
  useEffect(() => {
    if (pairingDataEnabled && pairingDataEnabled?.length) {
      setEventDescription(
        pairingDataEnabled?.calendar_description === null ||
          pairingDataEnabled?.interview_mode !== interviewMode
          ? initialDescription
          : pairingDataEnabled?.calendar_description
      );
    } else if (bookingDataForUpdate) {
      setEventDescription(
        bookingDataForUpdate?.calendarDescription === null ||
          bookingDataForUpdate?.interviewMode !== interviewMode
          ? initialDescription
          : bookingDataForUpdate?.calendarDescription
      );
    }
  }, [
    initialDescription,
    interviewMode,
    pairingDataEnabled,
    setEventDescription,
    bookingDataForUpdate,
  ]);

  const handleCreateEvent = (e) => {
    setCreateEvent(e.target.checked);
  };
  const handleCalendarEventTitle = (e) => {
    setCalendarEventTitle(e.target.value);
    setHandleRequiredCalendarEventTitle(!e.target.value);
  };
  const showCheckBoxForPreserveEvent = () => {
    return (
      bookingDataForUpdate &&
      bookingDataForUpdate.calendarEventId !== null && (
        <Grid item xs={12} direction={"row"}>
          <FormControlLabel
            control={
              <Checkbox
                disableFocusRipple
                disableRipple
                disableTouchRipple
                id="custom-switch"
                onClick={(e) => {
                  setDeleteEventOnBooking(!e.target.checked);
                }}
                checked={!deleteEventOnBooking}
              />
            }
            label="Would you like to preserve the Google Calendar event?"
            className={styles.eventCheckBox}
          />
        </Grid>
      )
    );
  };

  return (
    <Grid direction={"row"} container spacing={1} marginTop={2} gap={1}>
      <Grid item xs={12} direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              disableTouchRipple
              id="custom-switch"
              onClick={(e) => {
                handleCreateEvent(e);
              }}
              checked={createEvent}
              disabled={
                !intervieweEmail ||
                !intervieweName ||
                disableFieldsForPairedView ||
                isDisableMultipleFields
              }
            />
          }
          label="Create Calendar event"
          className={styles.eventCheckBox}
        />
      </Grid>
      <Grid item xs={12} className={styles.paperStyle} margin={1}>
        <Grid
          container
          gap={2}
          direction={"row"}
          marginTop={1}
          paddingY={2}
          paddingX={3}
        >
          <Grid item>
            <Typography className={styles.detailsHeading}>
              Event Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={
                !createEvent ||
                disableFieldsForPairedView ||
                isDisableMultipleFields
              }
              required={createEvent ? true : false}
              id="candidate-name"
              label="Calendar Event Title"
              value={calendarEventTitle}
              onChange={(e) => handleCalendarEventTitle(e)}
              error={handleRequiredCalendaEventTitle}
              helperText={
                handleRequiredCalendaEventTitle
                  ? "Please provide a event title"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ReactQuill
              theme="snow"
              value={eventDescription}
              readOnly={
                !createEvent ||
                disableFieldsForPairedView ||
                isDisableMultipleFields
              }
              onChange={(e) => setEventDescription(e)}
              placeholder="Write a description for calendar event and please add zoho link"
            />
          </Grid>
        </Grid>
      </Grid>
      {interviewStatus === INTERVIEW_STATUS_CHECK.CANDIDATE_NOT_AVAILABLE && (
        <>
          {showCheckBoxForPreserveEvent()}
          <WarningPopup
            WarningMessage={`${INTERVIEW_STATUS_OPTIONS_SHORTFORM_EXPANDED[interviewStatus]} ,
          deleting this booking. Click "Save Changes" to confirm`}
          />
        </>
      )}
      {isNaOrRsStatusPopupNoClicked &&
        ((bookingDataForUpdate?.isCoreInterviewer &&
          !bookingDataForUpdate?.pairedInterviewerId) ||
          (bookingDataForUpdate?.isCoreInterviewer &&
            bookingDataForUpdate?.pairedInterviewerId)) && (
          <>
            {showCheckBoxForPreserveEvent()}
            <WarningPopup
              WarningMessage={`${INTERVIEW_STATUS_OPTIONS_SHORTFORM_EXPANDED[interviewStatus]} ,
            deleting this booking. Click "Save Changes" to confirm`}
            />
          </>
        )}
    </Grid>
  );
};

export default EventDetailsStep;
