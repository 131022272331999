import { createTheme } from "@mui/material";

const defaultTheme = createTheme();
export const theme = createTheme({
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#DE1186",
    },
    secondary: {
      main: "#2E1C41",
      light: "#948B9E",
      dark: "#A199AA",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});
