import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../components/Spinner";
import WarningPopup from "./BookingModal/WarningPopup";
import DatePickerComponent from "../../../components/DatePickerComponent";

import { markTempSuspendUser } from "../../../redux/features/Availability/availabilitySlice";
import { fetchUpcomingBookings } from "../../../redux/features/Roster/rosterSlice";

import {
  Modal,
  Typography,
  Box,
  Grid,
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import format from "date-fns/format";
import { extractTime } from "../../../utils/date-utils";

import styles from "./style.module.scss";

const TemporarySuspendModal = ({
  show,
  setShow,
  competencyId,
  roundDesignationIds,
  slotsProvided,
  roundName,
  activedesignationId,
  weekFrom,
  weekTo,
  query,
  setActivePageNumber,
  selectedPanel,
  moduleName,
  memberListWithPanelData,
  panelGroupId,
  subCompetency,
  signal,
}) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const bookingData = useSelector((state) => state?.rosterReducer?.bookings);
  const loading = useSelector((state) => state?.rosterReducer?.loadBookings);
  const {
    providedSlots,
    roundsFilter,
    eligibleDesignationIds,
    interviewerDesignationIds,
    tagsFilter,
    subCompetencyFilter,
  } = useSelector((state) => state?.availabilityReducer?.availability);
 
  const [suspendedComment, setSuspendedComment] = useState("");
  const [err, setErr] = useState({ value: false, flag: "enddate" });
  const [selectedOption, setSelectedOption] = useState(null);
  const [bookings, setBookings] = useState([[]]);
  const [periodFrom, setPeriodFrom] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  useEffect(() => {
    if (parseInt(selectedPanel?.id)) {
      setSelectedOption({
        value: parseInt(selectedPanel.panel_id),
        label: `${selectedPanel.first_name} ${selectedPanel.last_name}(${selectedPanel.email})`,
      });
    }
  }, [dispatch, loginInfo.role_id, selectedPanel]);
  useEffect(() => {
    if (parseInt(selectedPanel?.id)) {
      dispatch(
        fetchUpcomingBookings({
          roleId: loginInfo.role_id,
          panelId: parseInt(selectedPanel.panel_id),
          periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        })
      );
    }
  }, [dispatch, loginInfo.role_id, selectedPanel, periodFrom]);
  useEffect(() => {
    if (selectedOption) setBookings(bookingData);
    else setBookings([]);
  }, [bookingData, selectedOption]);

  const handleClose = () => setShow(false);
  const validateForm = () => {
    let error = false;
    if (suspendedComment === "") {
      error = true;
      setErr({ value: true, flag: "comments" });
    }
    let d_period_from = periodFrom;
    let curr_date = format(
      new Date().setDate(new Date().getDate()),
      "yyyy-MM-dd"
    );
    if (d_period_from < curr_date) {
      error = true;
      setErr({ value: true, flag: "startdate" });
    }
    return error;
  };
  const optionCompetency = memberListWithPanelData?.map((item) => ({
    value: parseInt(item.panel_id),
    label: `${item.first_name} ${item.last_name}(${item.email})`,
  }));
  const onFormSubmit = async (e) => {
    const isValid = validateForm();
    if (!isValid) {
      try {
        e.preventDefault();
        const data = {
          panelId: selectedOption.value,
          periodFrom: periodFrom,
          periodTo: weekTo,
          competencyId: loginInfo.competency_id,
          roleId: loginInfo.role_id,
          comments: suspendedComment,
          panelMemberAvailabilityPayload: {
            competencyId: loginInfo.current_role_competency_id,
            roundDesignationIds: eligibleDesignationIds,
            slotsProvided: providedSlots,
            roundName: roundsFilter,
            periodFrom: weekFrom,
            periodTo: weekTo,
            roleId: loginInfo.role_id,
            query,
            activedesignationId: interviewerDesignationIds,
            panelGroupId: tagsFilter,
            subCompetency: subCompetencyFilter,
            signal: signal,
          },
          dispatch,
        };
        dispatch(markTempSuspendUser(data));
        setActivePageNumber(1);
        handleClose();
      } catch (error) {
        console.log("Error");
      }
    }
  };

  return (
    <Modal open={show} onClose={handleClose}>
      {loading ? (
        <Spinner />
      ) : (
        <Box className={`${styles.modalBox}`}>
          <Grid container spacing={0.75}>
            <Grid item xs={12} md={12} xl={12} lg={12}>
              <Typography className={styles.suspensionHeading}>
                Mark Temporary Suspended:{" "}
                {`${selectedPanel.first_name} ${selectedPanel.last_name} (${selectedPanel.employee_code})`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => handleClose(e)}
                sx={{ position: "absolute", right: 9, top: 10 }}
              >
                <img src={CloseIcon} alt="close-icon" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"column"}
            spacing={2}
            justifyContent={"center"}
          >
            <Grid item xs={12} md={12} xl={12} lg={12} marginTop={1}>
              <Typography className={styles.tempSuspTitle}>Panel</Typography>
              <Select
                fullWidth
                labelId="roleCompetency-label"
                id="roleompetency-id"
                placeholder="Role Competency"
                value={
                  parseInt(selectedPanel.panel_id)
                    ? parseInt(selectedPanel.panel_id)
                    : selectedOption?.value
                }
                disabled={true}
                className={styles.roleInputStyle}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      color: "#948B9E",
                    },
                  },
                }}
                required
              >
                <MenuItem value="" disabled>
                  Select Panel
                </MenuItem>
                {optionCompetency?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {err.value && err.flag === "comments" && (
              <Typography margin={1} paddingLeft={1}>
                *Provide Valid Comments.
              </Typography>
            )}
            {err.value && err.flag === "startdate" && (
              <Typography margin={1}>
                *Start Date should be greater than to Current Date.
              </Typography>
            )}
            <Grid item xs={12} md={12} xl={12} lg={12}>
              <Typography className={styles.tempSuspTitle}>From</Typography>

              <DatePickerComponent
                label=""
                value={periodFrom}
                handleChange={setPeriodFrom}
                minDate={format(new Date(), "yyyy-MM-dd")}
                maxDate={format(
                  new Date().setFullYear(2050, 12, 31),
                  "yyyy-MM-dd"
                )}
              />
            </Grid>

            {selectedOption && (
              <Grid item xs={12} md={12} xl={12} lg={12}>
                <TextField
                  id="Comments"
                  fullWidth
                  multiline={true}
                  rows={4}
                  placeholder="Comments"
                  label="Comments"
                  autoComplete="off"
                  variant="outlined"
                  value={suspendedComment}
                  onChange={(e) => {
                    setSuspendedComment(e.target.value);
                  }}
                  className={styles.addComment}
                  marginBottom={4}
                  required
                />
              </Grid>
            )}
          </Grid>
          {selectedOption && (
            <Grid marginTop={2}>
              <Typography className={styles.tempSuspBookingsTitle}>
                Upcoming Bookings:
              </Typography>
              {bookings && !!bookings.length && (
                <>
                  <TableContainer>
                    <Table size="small" aria-label="upcoming bookings table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Booking Date</TableCell>
                          <TableCell>Booking Time</TableCell>
                          <TableCell>Booking Assigned By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bookings?.map((booking) => (
                          <TableRow key={booking.id}>
                            <TableCell>
                              {new Date(
                                booking.bookingDate
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              {extractTime(booking.startTime)} -{" "}
                              {extractTime(booking.endTime)}
                            </TableCell>
                            <TableCell>{booking.bookingAssignedBy}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <WarningPopup
                    WarningMessage={` The selected member has already interviews scheduled, please
                have them cancelled. And if you press save 
                then upcoming bookings will also get removed including
                paired bookings.Are you sure you want to continue ?`}
                  />
                </>
              )}
              {bookings && !bookings.length && (
                <Typography className={styles.tempSuspTitle}>
                  No Upcoming Bookings
                </Typography>
              )}
              {!bookings && (
                <Typography className={styles.tempSuspTitle}>
                  Failed To Load Bookings.
                </Typography>
              )}
            </Grid>
          )}
          <Grid
            container
            justifyContent="left"
            alignItems="center"
            marginTop={2}
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Button
                fullWidth
                disabled={
                  !(
                    suspendedComment &&
                    periodFrom &&
                    !/^\s*$/.test(suspendedComment)
                  )
                }
                variant={"contained"}
                onClick={onFormSubmit}
                className={
                  !(
                    suspendedComment &&
                    periodFrom &&
                    !/^\s*$/.test(suspendedComment)
                  )
                    ? `${styles.saveBtnDisable}`
                    : `${styles.saveBtn}`
                }
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  );
};

export default TemporarySuspendModal;
