import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";
import {
  get,
  setAuthInfo,
  getAuthInfo,
  redirectToPage,
} from "../../../utils/app-utils";
import { BASE_URL, ROLE_TYPES } from "../../../constants/index";
const { isPanelMember, isAdmin, isHr, isSpoc } = ROLE_TYPES;

const initialState = {
  loading: false,
  loginInfo: {},
  competency: {},
  designation: {},
  holidaysList: [],
};

export const logIn = createAsyncThunk("auth", async (token, { dispatch }) => {
  try {
    const response = await get(`${BASE_URL}/auth/login`, null, {
      authToken: token,
    });
    setAuthInfo({
      authToken: token,
      userInfo: response,
    });
    if (isHr(response.current_role)) {
      redirectToPage("/main/dashboard");
    } else if (
      isAdmin(response.current_role) ||
      isSpoc(response.current_role)
    ) {
      redirectToPage("/main/roster");
    } else if (isPanelMember(response.current_role)) {
      redirectToPage("/main/Availability");
    } else {
      redirectToPage("/login");
    }
    return response;
  } catch (err) {
    console.log(err);
    redirectToPage("/");
    throw err;
  }
});

export const getCompetencyList = createAsyncThunk(
  "getCompetencyList",
  async ({ roleId }) => {
    try {
      const response = await get(
        `${BASE_URL}/dashboard/competency/list`,
        { queryParams: { role_id: roleId } },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getDesignationList = createAsyncThunk(
  "getDesignationList",
  async ({ roleId, competencyId, isDesignationPage = false }) => {
    try {
      const response = await get(
        `${BASE_URL}/dashboard/designation/list`,
        {
          queryParams: { role_id: roleId, competencyId },
        },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchHolidaysList = createAsyncThunk(
  "fetchHolidaysList",
  async ({ roleId }) => {
    try {
      const response = roleId
        ? await get(`${BASE_URL}/holiday/getList`, {
            queryParams: { role_id: roleId },
          })
        : [];
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
const authSlice = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    switchRole(state, action) {
      toast.custom(
        <CustomToaster
          title={TOAST_TITLE.SUCCESS}
          message={`Role Context Switched to ${
            isPanelMember(action.payload.current_role)
              ? action.payload.current_role.split("_").join(" ")
              : action.payload.current_role
          }`}
          type={TOAST_TYPE.SUCCESS}
          time={TOAST_TIME.TWO}
        />
      );
      let authInfo = getAuthInfo();
      setAuthInfo({
        authToken: authInfo?.authToken,
        userInfo: {
          ...authInfo?.userInfo,
          current_role: action.payload.current_role,
          current_role_competency_id: action.payload.current_role_competency_id,
          role_id: action.payload.role_id,
        },
      });
      state.loginInfo.current_role = action.payload.current_role;
      state.loginInfo.current_role_competency_id =
        action.payload.current_role_competency_id;
      state.loginInfo.role_id = action.payload.role_id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message="Logged In "
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        if (action.payload) {
          return { ...state, loading: false, loginInfo: action.payload };
        }
        return { ...state, loading: false };
      })
      .addCase(logIn.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error loggin-in"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(getCompetencyList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompetencyList.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            competency: action.payload?.competency,
          };
        }
        return { ...state, loading: false };
      })
      .addCase(getCompetencyList.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Something Went Wrong!"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(getDesignationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDesignationList.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            designation: action.payload?.designation,
          };
        }
        return { ...state, loading: false };
      })
      .addCase(getDesignationList.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Something Went Wrong!"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(fetchHolidaysList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHolidaysList.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.holidaysList = action.payload;
        }
      })
      .addCase(fetchHolidaysList.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Fetch Holiday List Failed"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      });
  },
});
export const { switchRole } = authSlice.actions;
export default authSlice.reducer;
