import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TagsTab from "./Tags";
import AllTable from "./AllTableComp";
import AdminTable from "./AdminTableComp";
import ActiveTableComp from "./ActiveTableComp";
import TemporarySuspendTab from "./TemporarySuspendTab";

import {
  fetchCommentsForUser,
  fetchSuspensionHistoryForUser,
} from "../../../redux/features/Roster/rosterSlice";

import { Grid } from "@mui/material";

import { ROLE_TYPES, SORT_ORDER } from "../../../constants";

const { isHr, isSpoc, isAdmin } = ROLE_TYPES;

const Detail = ({
  activeAccordion,
  setActiveAccordion,
  keyvalue,
  setKeyvalue,
  allUsersList,
  activePageNumber,
  setActivePageNumber,
  user,
  adminUsers,
  CustomTabPanel,
  suspendedList,
  searchValue,
  sortBy,
  setSortBy,
  interviewerDesignation,
  joiningDate,
  subcompetency,
  signal,
  showAddTagModal,
  setShowAddTagModal,
  panelGroupId,
  activedesignationId,
  designationIds,
  competencyId,
  setCompetencyId,
  gender,
  competencyList,
  handleTabsChange,
  roundName,
  competencyIdFilter,
  isFilterApplied,
  adminAllUserAddRoleData,
  adminCompetencyIdFilter,
  roleName,
  setRoleName,
  competencyIdState
}) => {
  let dispatch = useDispatch();
  const commentsForUniqueUser = useSelector(
    (state) => state?.rosterReducer?.userComments
  );
  const commentsLoading = useSelector(
    (state) => state?.rosterReducer?.commentsLoading
  );
  const SuspendedUsersHistoryList = useSelector(
    (state) => state?.rosterReducer?.SuspendedUsersHistory
  );
  const SuspendedUsersHistoryPageCount = useSelector(
    (state) => state?.rosterReducer?.pageCount
  );
  const SuspendedUsersHistoryLoading = useSelector(
    (state) => state?.rosterReducer?.historyLoading
  );
  const { loading, panelDataLoading, userDataLoading } = useSelector(
    (state) => state?.rosterReducer
  );
  const adminLoading = useSelector((state) => state?.adminReducer?.loading);

  const [selectedPanel, setSelectedPanel] = useState(null);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [showSuspendedHistoryModal, setShowSuspendedHistoryModal] =
    useState(false);
  const [showTempUnavailableModal, setShowTempUnavailableModal] =
    useState(false);

  const handleAddCommentModal = (item) => {
    setSelectedPanel({ ...item, key: "ACTIVE" });
    setShowAddCommentModal(true);
    dispatch(
      fetchCommentsForUser({
        roleId: user.role_id,
        userId: item?.id,
      })
    );
  };
  const handleViewSuspensionHistoryModal = (item) => {
    setSelectedPanel({ ...item, key: "ACTIVE" });
    setShowSuspendedHistoryModal(true);
    dispatch(
      fetchSuspensionHistoryForUser({
        competencyId: user.current_role_competency_id,
        roleId: user.role_id,
        userId: item?.id,
      })
    );
  };
  const handleSortType = (columnLabel) => {
    setSortBy((prevState) => {
      const sortOrder =
        prevState.sortType === columnLabel
          ? prevState.sortOrder === SORT_ORDER.ASCENDING || !prevState.sortOrder
            ? SORT_ORDER.DESCENDING
            : SORT_ORDER.ASCENDING
          : SORT_ORDER.ASCENDING;
      return {
        sortType: columnLabel,
        sortOrder: sortOrder,
      };
    });
  };
  const handleTempUnavailableModal = (item) => {
    setSelectedPanel({ ...item, key: "ACTIVE" });
    setShowTempUnavailableModal(true);
  };

  return (
    <Grid
      container
      alignItems="center"
      xs={12}
      className={"globalLayoutHeight"}
    >
      {activeAccordion === 0 && (
        <CustomTabPanel
          value={activeAccordion}
          index={0}
          maxWidth={false}
          disableGutters
          className={"globalLayoutHeight"}
        >
          <AllTable
            setKeyValue={setKeyvalue}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
            allUsersList={
              isAdmin(user.current_role)
                ? adminAllUserAddRoleData
                : allUsersList
            }
            activePageNumber={activePageNumber}
            setActivePageNumber={setActivePageNumber}
            user={user}
            selectedPanel={selectedPanel}
            setSelectedPanel={setSelectedPanel}
            setShowAddCommentModal={setShowAddCommentModal}
            showAddCommentModal={showAddCommentModal}
            commentsForUniqueUser={commentsForUniqueUser}
            commentsLoading={commentsLoading}
            subcompetency={subcompetency}
            sortBy={sortBy}
            setSortBy={setSortBy}
            signal={signal}
            handleSortType={handleSortType}
            joiningDate={joiningDate}
            designationIds={designationIds}
            competencyId={competencyId}
            setCompetencyId={setCompetencyId}
            gender={gender}
            searchValue={searchValue}
            competencyList={competencyList}
            loading={userDataLoading}
            handleTabsChange={handleTabsChange}
            isFilterApplied={isFilterApplied}
            competencyIdFilter={competencyIdFilter}
            adminLoading={adminLoading}
          />
        </CustomTabPanel>
      )}
      {activeAccordion === 1 && (
        <CustomTabPanel
          value={activeAccordion}
          index={1}
          maxWidth={false}
          disableGutters
        >
          {isHr(user.current_role) || isSpoc(user.current_role) ? (
            <ActiveTableComp
              competencyId={competencyId}
              keyvalue={keyvalue}
              setKeyValue={setKeyvalue}
              adminUsers={adminUsers}
              activePageNumber={activePageNumber}
              setActivePageNumber={setActivePageNumber}
              user={user}
              handleAddCommentModal={handleAddCommentModal}
              selectedPanel={selectedPanel}
              setSelectedPanel={setSelectedPanel}
              setCompetencyId={setCompetencyId}
              setShowAddCommentModal={setShowAddCommentModal}
              showAddCommentModal={showAddCommentModal}
              commentsForUniqueUser={commentsForUniqueUser}
              commentsLoading={commentsLoading}
              setShowSuspendedHistoryModal={setShowSuspendedHistoryModal}
              showSuspendedHistoryModal={showSuspendedHistoryModal}
              handleViewSuspensionHistoryModal={
                handleViewSuspensionHistoryModal
              }
              SuspendedUsersHistoryList={SuspendedUsersHistoryList}
              SuspendedUsersHistoryLoading={SuspendedUsersHistoryLoading}
              SuspendedUsersHistoryPageCount={SuspendedUsersHistoryPageCount}
              interviewerDesignation={interviewerDesignation}
              joiningDate={joiningDate}
              subcompetency={subcompetency}
              sortBy={sortBy}
              setSortBy={setSortBy}
              signal={signal}
              handleSortType={handleSortType}
              panelGroupId={panelGroupId}
              activedesignationId={activedesignationId}
              searchValue={searchValue}
              designationIds={designationIds}
              loading={panelDataLoading}
              showTempUnavailableModal={showTempUnavailableModal}
              setShowTempUnavailableModal={setShowTempUnavailableModal}
              handleTempUnavailableModal={handleTempUnavailableModal}
              roundName={roundName}
              handleTabsChange={handleTabsChange}
              activeAccordion={activeAccordion}
              setActiveAccordion={setActiveAccordion}
            />
          ) : (
            isAdmin(user.current_role) && (
              <AdminTable
                keyvalue={keyvalue}
                competencyId={competencyId}
                designationIds={designationIds}
                activePageNumber={activePageNumber}
                setActivePageNumber={setActivePageNumber}
                user={user}
                searchValue={searchValue}
                competencyIdFilter={competencyIdFilter}
                isFilterApplied={isFilterApplied}
                sortBy={sortBy}
                setSortBy={setSortBy}
                handleSortType={handleSortType}
                adminCompetencyIdFilter={adminCompetencyIdFilter}
                roleName={roleName}
                setRoleName={setRoleName}
                competencyIdState={competencyIdState}
              />
            )
          )}
        </CustomTabPanel>
      )}
      {activeAccordion === 2 && (
        <CustomTabPanel
          value={activeAccordion}
          index={2}
          maxWidth={false}
          disableGutters
        >
          {(isHr(user.current_role) || isSpoc(user.current_role)) && (
            <TemporarySuspendTab
              list={suspendedList?.temporarySuspendedMembersList}
              activePageNumber={activePageNumber}
              setActivePageNumber={setActivePageNumber}
              suspendedListPageCount={suspendedList?.temporarySuspendPageCount}
              searchValue={searchValue}
              setSortBy={setSortBy}
              sortBy={sortBy}
              keyvalue={keyvalue}
              roleId={user.role_id}
              competencyId={user.current_role_competency_id}
              totalCount={suspendedList?.totalSuspendedCount}
              loading={loading}
            />
          )}
        </CustomTabPanel>
      )}
      {(isHr(user.current_role) || isSpoc(user.current_role)) &&
        activeAccordion === 3 && (
          <CustomTabPanel
            value={activeAccordion}
            index={3}
            maxWidth={false}
            disableGutters
          >
            <TagsTab
              activePageNumber={activePageNumber}
              setActivePageNumber={setActivePageNumber}
              showAddTagModal={showAddTagModal}
              setShowAddTagModal={setShowAddTagModal}
            />
          </CustomTabPanel>
        )}
    </Grid>
  );
};

export default Detail;
