import React from "react";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

const StayOnHoverOverlay = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="left" classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    width: 350,
    fontSize: theme.typography.pxToRem(13),
    border: "1px solid #dadde9",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.40)",
    borderRadius: "10px !important",
    padding: "16px",
  },
}));

export default StayOnHoverOverlay;
