import React, { useState } from "react";

import Icon from "../SvgIcon/icon";
import ImageModal from "../ImageModal";

import { Grid, Avatar, Tooltip, Typography, Stack } from "@mui/material";

import StarredIcon from "../../assets/icons/starred-icon.svg";

import {
  redirectToNewersWorldProfileByUserId,
  deriveAbbreviatedName,
} from "../../utils/app-utils";

import styles from "./style.module.scss";

const PanelDetailsView = ({
  profilePicUrl,
  interviewerEmail,
  firstName = "",
  lastName = "",
  designationName = "",
  employeeCode,
  showSpocIcon = false,
  isSpoc,
  index,
  isdashboard = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex", flexWrap: "nowrap" }}>
        <Grid
          item
          marginRight={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Avatar
            alt="profile-image"
            component={"image"}
            onClick={() => {
              handleOpen();
            }}
            src={profilePicUrl}
            className={styles.avatarStyle}
          />
        </Grid>

        <Grid item>
          <Tooltip
            title={`${interviewerEmail}`}
            arrow={true}
            placement={index === 0 ? "right" : "top"}
            PopperProps={{
              disablePortal: true,
              draggable: false,
            }}
          >
            {showSpocIcon ? (
              <Stack direction="row" spacing={1}>
                <Typography
                  paragraph={true}
                  className={"tablebody"}
                  onClick={() =>
                    redirectToNewersWorldProfileByUserId(parseInt(employeeCode))
                  }
                  display="flex"
                  alignItems="center"
                  whiteSpace="nowrap"
                  marginBottom={0}
                >
                  {firstName?.length >= 18
                    ? firstName
                    : `${firstName} ${lastName}`}
                </Typography>

                {isSpoc && (
                  <Icon
                    icon={StarredIcon}
                    width="16px"
                    height="15px"
                    topPadding="6px"
                  />
                )}
              </Stack>
            ) : (
              <Typography
                paragraph={true}
                className={"tablebody"}
                onClick={() =>
                  redirectToNewersWorldProfileByUserId(parseInt(employeeCode))
                }
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
                marginBottom={0}
              >
                {!isdashboard
                  ? firstName.length >= 18
                    ? { firstName }
                    : `${firstName} ${lastName}`
                  : firstName}
              </Typography>
            )}
          </Tooltip>

          {designationName?.length ? (
            <Typography
              display="flex"
              alignItems="center"
              justifyContent="left"
              className={`${styles.tableBodyStyleDesignation} cursorPointer`}
            >
              {deriveAbbreviatedName(designationName ?? "")}
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {open && (
        <ImageModal
          imageUrl={profilePicUrl}
          open={open}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default PanelDetailsView;
