import React, { useState } from "react";
import { useDispatch } from "react-redux";

import SingleSelectComponent from "../../../components/SingleSelect/SingleSelectComponent";

import {
  configFetchDlAdminList,
  changeDriveReport,
  applyFilterStatus,
  resetAdminFilterStatus,
  changeConfigCompetencyStatus,
} from "../../../redux/features/Admin/adminSlice";

import {
  Box,
  Select,
  Typography,
  SwipeableDrawer,
  Slide,
  Grid,
  Divider,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import { ADMIN_DL_DRIVE_OPTIONS } from "../../../constants";

import styles from "./style.module.scss";
import { useSelector } from "react-redux";

const FiltersReportsConfig = ({
  showFilter,
  setShowFilter,
  setActivePageNumber,
  activePageNumber,
  loginInfo,
  optionsFilterByCompetency,
}) => {
  const dispatch = useDispatch();
  const weekType = useSelector(
    (state) => state?.adminReducer?.reportsConfig?.weekType
  );
  const competencyId = useSelector(
    (state) => state?.adminReducer?.reportsConfig?.competencyId
  );

  const handleClose = () => {
    setShowFilter(false);
  };
  const toggleDrawer = (show) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowFilter(show);
  };
  const DriveReportFilter = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="report-label">Drive Report</InputLabel>
        <Select
          value={weekType}
          onChange={(event) => dispatch(changeDriveReport(event.target.value))}
          fullWidth
          placeholder="Drive Report"
          labelId="report-label"
          label="Drive Report"
          id="report-id"
          multiline
          displayEmpty
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
        >
          <MenuItem value="ALL">All</MenuItem>
          {Object.entries(ADMIN_DL_DRIVE_OPTIONS)?.map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const CompetencyFilter = () => {
    let optionValues = [];
    if (Array.isArray(optionsFilterByCompetency)) {
      optionValues = optionsFilterByCompetency;
    } else {
      optionValues = Object.keys(optionsFilterByCompetency)?.map((key) => ({
        label: optionsFilterByCompetency[key],
        value: optionsFilterByCompetency[key],
      }));
    }
    return (
      <Autocomplete
        fullWidth
        value={competencyId ? optionValues.find(option => option.value === competencyId) : null}
        disablePortal
        onChange={(event, newValue) => {
          dispatch(changeConfigCompetencyStatus(newValue ? newValue.value : ""))
        }}
        id="combo-box-demo"
        options={optionValues}
        getOptionLabel={(option) => option?.label}
        renderInput={(params) => <TextField {...params} label={"Competency"} />}
      />
    );
  };
  const applyFilter = (e) => {
    dispatch(
      configFetchDlAdminList({
        roleId: loginInfo.role_id,
        pageNumber: activePageNumber,
        reportType: weekType,
        competencyId: competencyId===""?competencyId:parseInt(competencyId),
      })
    );
    dispatch(applyFilterStatus());
    handleClose();
  };
  const resetFilter = (e) => {
    e.stopPropagation();
    dispatch(
      configFetchDlAdminList({
        roleId: loginInfo.role_id,
        pageNumber: activePageNumber,
        reportType: "ALL",
        competencyId: "",
      })
    );
    dispatch(resetAdminFilterStatus());
    setActivePageNumber(1);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={showFilter}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Slide direction="left" in={showFilter} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: 400,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            overflowX: "hidden",
          }}
          role="presentation"
        >
          <Box mt={2} pt={6}>
            <Grid container>
              <Grid item xs={10} md={10} xl={10} lg={10}>
                <Typography variant="h6" gutterBottom pt={3} pl={3}>
                  Filters
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} xl={2} lg={2} p={2.5}>
                <IconButton onClick={(e) => handleClose(e)}>
                  <img src={CloseIcon} alt="close-icon" />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
            <Grid
              container
              m={0}
              pr={2}
              spacing={3}
              sx={{ flexGrow: 1, overflowY: "hidden" }}
            >
              <Grid item xs={11.5} xl={11.5} md={11.5} lg={11.5}>
                {DriveReportFilter()}
              </Grid>
              <Grid item xs={11.5} xl={11.5} md={11.5} lg={11.5}>
                {CompetencyFilter()}
              </Grid>
            </Grid>
          </Box>

          <Grid container m={2} spacing={1}>
            <Grid
              item
              lg={5.5}
              xl={5.5}
              xs={5.5}
              md={5.5}
              display="flex"
              alignItems="flex-end"
            >
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={(e) => resetFilter(e)}
              >
                CLEAR
              </Button>
            </Grid>

            <Grid item lg={5.5} xl={5.5} xs={5.5} md={5.5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={(e) => applyFilter(e)}
                className={styles.searchBtn}
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </SwipeableDrawer>
  );
};

export default FiltersReportsConfig;
