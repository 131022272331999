import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomDateModal from "../CustomDateModal";
import RosterFilterList from "./RosterFilterList";

import { getpanelGroupList } from "../../../../redux/features/PanelGroup/panelGroup";
import {
  fetchActiveHrSpoc,
  getUserListForAdminAddRole,
} from "../../../../redux/features/Admin/adminSlice";
import {
  getUserByCompetency,
  fetchActivePanelByCompetency,
  getDesignationByCompetencyId,
  changeCompetencyStatus,
  applyFilterStatus,
  resetRosterFilterStatus,
  getCompetencyIdForFilters,
  changeMultiCompetencyStatus,
  changeInterviewerDesignationStatus,
  changeEligibleDesignationStatus,
  changeRoleTypeStatus,
  changedateOfJoiningStatus,
  changeRoundNameStatus,
} from "../../../../redux/features/Roster/rosterSlice";

import { format } from "date-fns";

import {
  DATE_OF_JOINING_FILTER_OPTIONS,
  JOINING_DATE_VALUES,
  ROUND_FILTER_OPTIONS,
  ROSTER_FILTER_TAB_CHECK_VALUE,
  sortArrayOfObjects,
  ROLE_TYPES,
} from "../../../../constants/index";

const { isHr, isSpoc, isAdmin } = ROLE_TYPES;

const Filter = ({
  keyvalue,
  user,
  competency,
  competencyId,
  setCompetencyId,
  designationData,
  subcompetency,
  setSubcompetency,
  competencyList,
  setCompetencyList,
  interviewerDesignation,
  setInterviewerDesignation,
  joiningDate,
  setJoiningDate,
  optionsFilterByCompetency,
  setOptionsFilterByCompetency,
  joiningLabel,
  setJoiningLabel,
  setActivePageNumber,
  activePageNumber,
  selectedCompetency,
  setSelectedCompetency,
  filtericon,
  setFiltericon,
  gender,
  setGender,
  signal,
  setSignal,
  panelGroupId,
  setPanelGroupId,
  roundName,
  setRoundName,
  sortBy,
  activeDesignations,
  allDesignationData,
  eligibleDesignationIds,
  activedesignationId,
  setActiveDesignationId,
  setSearchValue,
  designationIds,
  setDesignationIds,
  searchValue,
  setKeyvalue,
  setIsFilterApplied,
  competencyIdFilter,
  setCompetencyIdFilter,
  interviewerDesignationList,
  roleName,
  setRoleName,
  adminCompetencyIdFilter,
  setAdminCompetencyIdFilter,
}) => {
  const dispatch = useDispatch();
  const panelGroupList = useSelector((state) => state?.panelGroupReducer?.list);
  const multiListdesignationData = useSelector(
    (state) => state.rosterReducer?.adminFilterAllDesignations
  );
  const {
    competencyIdState,
    multicompetencyIdsState,
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    genderState,
    roleTypesState,
    subCompetencyState,
    dateOfJoiningState,
    dateOfJoiningLabel,
    roundsNameState,
    tagsState,
  } = useSelector((state) => state.rosterReducer?.roster);

  const [options, setOptions] = useState([]);
  const [optionsForHr, setOptionsForHr] = useState([]);
  const [optionCompetency, setOptionCompetency] = useState([]);
  const [showSelectDateOfJoiningModal, setShowSelectDateOfJoiningModal] =
    useState(false);

  const isAllEligibleSelected =
    options.length > 0 && interviewerDesignation.length === options.length;
  const isAllinterviewerSelected =
    optionCompetency.length > 0 &&
    activedesignationId.length === optionCompetency.length;
  const optionsvalue = options?.map((item) => item?.value);
  const isAllSelected =
    optionsvalue.length > 0 &&
    interviewerDesignation.length === optionsvalue.length;
  const designationsData = Object.entries(options);
  let optionDesignation;
  if (designationsData.length) {
    optionDesignation = designationsData?.map((item) => {
      return {
        label: item[1].label,
        value: item[1].id,
      };
    });
  }

  useEffect(() => {
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
    ) {
      let activeDesignationsArray = [];
      for (const designation in activeDesignations) {
        activeDesignationsArray.push(
          activeDesignations[designation].name +
            ":" +
            designation +
            ":" +
            activeDesignations[designation].abbreviatedName
        );
      }
      activeDesignationsArray = activeDesignationsArray.sort();
      let sortedActiveDesignationList = activeDesignationsArray.reduce(
        (acc, item) => {
          acc[" " + item.split(":")[1]] = {
            name: item.split(":")[0],
            abbreviatedName: item.split(":")[2],
          };
          return acc;
        },
        {}
      );

      const competencyData = Object.entries(sortedActiveDesignationList);
      if (competencyData.length) {
        const optionDesignation = competencyData?.map((item) => {
          return {
            label: item[1].name,
            value: parseInt(item[0]),
          };
        });
        setOptionCompetency(optionDesignation);
      } else {
        setOptionCompetency([]);
      }
    }
  }, [activeDesignations, keyvalue]);
  useEffect(() => {
    if (
      (keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
        keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL) &&
      isHr(user?.current_role)
    ) {
      if (interviewerDesignationList?.length) {
        const optionInterviewerDesignationList =
          interviewerDesignationList?.map((item) => {
            return {
              label: item?.designation_name,
              value: item?.designation_id,
            };
          });
        setOptionsForHr(optionInterviewerDesignationList);
      } else {
        setOptionsForHr([]);
      }
    }
  }, [interviewerDesignationList, keyvalue]);
  useEffect(() => {
    if (
      !isAdmin(user.current_role) &&
      (keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
        keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP)
    ) {
      const roundDesignations = eligibleDesignationIds?.reduce((acc, item) => {
        if (item) {
          acc.push({
            label: `${allDesignationData[item.designation_id]?.name}${
              item.is_pairing ? "(P)" : ""
            }`,
            designationId: `${item.designation_id}`,
            id: `${item.designation_id}${item.is_pairing ? "-P" : ""}`,
            isPairing: item.is_pairing,
          });
        }
        return sortArrayOfObjects(acc);
      }, []);
      setOptions(roundDesignations);
    } else if (isAdmin(user.current_role)) {
      let designationsArray = [];
      if (keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL) {
        for (const designation in designationData) {
          designationsArray.push(
            designationData[designation].name +
              ":" +
              designation +
              ":" +
              designationData[designation].abbreviatedName
          );
        }
      } else {
        for (const designation in multiListdesignationData) {
          designationsArray.push(
            multiListdesignationData[designation].name +
              ":" +
              designation +
              ":" +
              multiListdesignationData[designation].abbreviatedName
          );
        }
      }
      designationsArray = designationsArray.sort();
      let sortedDesignationList = designationsArray.reduce((acc, item) => {
        acc[" " + item.split(":")[1]] = {
          name: item.split(":")[0],
          abbreviatedName: item.split(":")[2],
        };
        return acc;
      }, {});
      const desData = Object.entries(sortedDesignationList);
      if (desData.length) {
        const option = desData?.map(([id, { name }]) => {
          return {
            label: name,
            value: parseInt(id),
            id: parseInt(id),
          };
        });
        setOptions(option);
      } else {
        setOptions([]);
      }
    }else {
      let designationsArray = [];
      for (const designation in designationData) {
        designationsArray.push(
          designationData[designation].name +
            ":" +
            designation +
            ":" +
            designationData[designation].abbreviatedName
        );
      }
      designationsArray = designationsArray.sort();
      let sortedDesignationList = designationsArray.reduce((acc, item) => {
        acc[" " + item.split(":")[1]] = {
          name: item.split(":")[0],
          abbreviatedName: item.split(":")[2],
        };
        return acc;
      }, {});
      const desData = Object.entries(sortedDesignationList);
      if (desData.length) {
        const option = desData?.map(([id, { name }]) => {
          return {
            label: name,
            value: parseInt(id),
            id: parseInt(id),
          };
        });
        setOptions(option);
      } else {
        setOptions([]);
      }
    }
  }, [
    allDesignationData,
    designationData,
    keyvalue,
    eligibleDesignationIds,
    competencyId,
    user.current_role,
    activeDesignations,
    multiListdesignationData,
  ]);
  useEffect(() => {
    const data = getSortedCompetencyList(competencyList);
    const competencyDataFilter = Object.entries(data);
    if (competencyDataFilter.length) {
      const optionsFilterByCompetency = competencyDataFilter?.map((item) => {
        return {
          value: item[0].trim().toString(),
          label: item[1].toString(),
        };
      });
      setOptionsFilterByCompetency(optionsFilterByCompetency);
    } else {
      setOptionsFilterByCompetency([]);
    }
  }, [competencyList, setOptionsFilterByCompetency]);
  useEffect(() => {
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
    ) {
      const designationsItems = interviewerDesignation?.map((item) => {
        return {
          label: item.label,
          designationId: item.value && item.value.toString().split("-")[0],
          id: item.id,
          isPairing: item.is_pairing,
        };
      });
      setDesignationIds(designationsItems);
      setActivePageNumber(1);
      setRoundName(roundName);
    } else {
      const designationsIds = interviewerDesignation?.map((item) => item.id);
      setDesignationIds(designationsIds);
      setRoundName(roundName);
      setActivePageNumber(1);
    }
  }, [
    interviewerDesignation,
    setDesignationIds,
    setActivePageNumber,
    keyvalue,
    setRoundName,
    roundName,
  ]);
  useEffect(() => {
    const dataFiltered = optionsFilterByCompetency?.find(
      (item) => parseInt(item.value) === competencyId
    );
    setSelectedCompetency(dataFiltered?.value);
  }, [
    competencyId,
    user.current_role,
    user.current_role_competency_id,
    optionsFilterByCompetency,
    selectedCompetency,
    setSelectedCompetency,
  ]);
  useEffect(()=>{
      if(!(competencyIdState.toString()?.length)){
        if(isAdmin(user?.current_role)){
        dispatch(changeEligibleDesignationStatus([]));
      }
      if((isHr(user?.current_role))||(isSpoc(user?.current_role))){
        dispatch(changeCompetencyStatus(user?.current_role_competency_id))
      }
    }
  },[competencyIdState,dispatch,user?.current_role,user?.current_role_competency_id])

  const getSortedCompetencyList = (competencyList) => {
    let compListArray = [];
    for (const competency in competencyList) {
      compListArray.push([competencyList[competency]] + ":" + competency);
    }
    compListArray = compListArray.sort();

    let sortedCompetencyList = compListArray.reduce((acc, item) => {
      acc[" " + item.split(":")[1]] = item.split(":")[0];
      return acc;
    }, {});
    return sortedCompetencyList;
  };
  const handleEligibleDesignationChange = (event) => {
    setInterviewerDesignation(event);
    dispatch(changeEligibleDesignationStatus(event));
  };
  const handleEligibleDesignationChangeForActive = (event) => {
    setInterviewerDesignation(event);
    dispatch(changeEligibleDesignationStatus(event));
  };
  const handleInterviewerDesignationChange = (event) => {
    setActiveDesignationId(event);
    dispatch(changeInterviewerDesignationStatus(event));
  };
  const handleJoiningDateChange = (e) => {
    const value = e.target.value;
    setActivePageNumber(1);
    if (value === JOINING_DATE_VALUES.CUSTOM) {
      setJoiningLabel(value);
      setShowSelectDateOfJoiningModal(true);
    } else if (value === JOINING_DATE_VALUES.ALL) {
      setJoiningLabel(value);
      dispatch(
        changedateOfJoiningStatus({ date: { from: "", to: "" }, value: value })
      );
      setJoiningDate({ from: "", to: "" });
    } else {
      setJoiningLabel(value);
      const dateValue = DATE_OF_JOINING_FILTER_OPTIONS.find(
        (item) => item.label === value
      );
      dispatch(
        changedateOfJoiningStatus({
          date: {
            from: dateValue.value,
            to: format(new Date(), "yyyy-MM-dd"),
          },
          value: value,
        })
      );
      setJoiningDate({
        from: dateValue.value,
        to: format(new Date(), "yyyy-MM-dd"),
      });
    }
  };
  const handleCompetencyChange = (e) => {
    if (
      !isAdmin(user?.current_role) ||
      (isAdmin(user?.current_role) &&
        keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL)
    ) {
      dispatch(
        getDesignationByCompetencyId({
          roleId: user.role_id,
          competencyId: e.value === "" ? isSpoc(user.current_role) ? user.current_role_competency_id : e.value : parseInt(e.value),
        })
      );
    }
    dispatch(changeCompetencyStatus(e.value === "" ? isSpoc(user.current_role) ? user.current_role_competency_id : e.value : parseInt(e.value)));
    setCompetencyIdFilter(parseInt(e.value));
    setSelectedCompetency(e.value);
  };
  const handleRoundChange = (value) => {
    setActivePageNumber(1);
    setRoundName(value);
    dispatch(changeRoundNameStatus(value));
  };
  const handleRoleChange = (value) => {
    if (isAdmin(value)) {
      setAdminCompetencyIdFilter([]);
      dispatch(changeCompetencyStatus(""));
    }
    setRoleName(value);
    dispatch(changeRoleTypeStatus(value));
  };
  const handleAdminCompetencyChange = (event) => {
    setAdminCompetencyIdFilter(event);
    dispatch(changeMultiCompetencyStatus(event));
  };
  const handleClose = (e) => {
    setFiltericon(false);
  };
  const toggleDrawer = (newOpen) => () => {
    setFiltericon(newOpen);
  };
  const resetRosterFilter = (e) => {
    e.stopPropagation();
    setCompetencyId(
      isAdmin(user.current_role) ? "" : user.current_role_competency_id
    );
    setCompetencyIdFilter(
      isAdmin(user.current_role) ? "" : user.current_role_competency_id
    );
    setInterviewerDesignation([]);
    setSelectedCompetency("");
    setJoiningLabel("");
    setJoiningDate({ from: "", to: "" });
    setActivePageNumber(1);
    setSubcompetency([]);
    setPanelGroupId([]);
    setGender("");
    setRoundName(ROUND_FILTER_OPTIONS.ALL);
    setDesignationIds([]);
    setActiveDesignationId([]);
    setIsFilterApplied(false);
    setSearchValue("");
    setRoleName("");
    setAdminCompetencyIdFilter([]);
    dispatch(resetRosterFilterStatus((!isAdmin(user?.current_role) && (keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL))?true:false));
    if (!isAdmin(user?.current_role)) {
      dispatch(getCompetencyIdForFilters(user?.current_role_competency_id));
    }
    if (
      (isSpoc(user.current_role) || isHr(user.current_role)) &&
      !isAdmin(user.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL
    ) {
      dispatch(
        getUserByCompetency({
          roleId: user.role_id,
          panelCompetencyId: isAdmin(user.current_role)
            ? null
            : user.current_role_competency_id,
          competencyId: isAdmin(user.current_role)
            ? ""
            : user.current_role_competency_id,
          designationIds: [],
          dateOfJoining: { from: "", to: "" },
          subCompetency: [],
          pageNumber: 1,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          gender: "",
          query: "",
        })
      );
    } else if (
      isAdmin(user.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL
    ) {
      dispatch(
        getUserListForAdminAddRole({
          roleId: user.role_id,
          competencyId: "",
          designationIds: [],
          pageNumber: 1,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          gender: "",
          query: "",
        })
      );
    } else if (
      (isSpoc(user.current_role) || isHr(user.current_role)) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE
    ) {
      dispatch(
        fetchActivePanelByCompetency({
          competencyId: user.current_role_competency_id,
          designationIds: [],
          roleId: user.role_id,
          pageNumber: 1,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          panelGroupId:
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
              ? []
              : null,
          roundName: ROUND_FILTER_OPTIONS.ALL,
          activedesignationId: [],
          signal: signal,
          joiningDate: { from: "", to: "" },
          subCompetency: [],
        })
      );
      dispatch(
        getpanelGroupList({
          roleId: user.role_id,
          competencyId: user.current_role_competency_id,
        })
      );
    }
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE &&
      isAdmin(user.current_role)
    ) {
      dispatch(
        fetchActiveHrSpoc({
          roleId: user.role_id,
          panelCompetencyId: isAdmin(user.current_role)
            ? null
            : user.current_role_competency_id,
          competencyId: "",
          roleCompetencyIds: [],
          designationIds: [],
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          signal: signal,
          roleType: roleName,
        })
      );
    }
  };
  const applyFilter = (e) => {
    if (
      isAdmin(user.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL
    ) {
      dispatch(
        getUserListForAdminAddRole({
          roleId: user.role_id,
          competencyId: competencyIdState,
          designationIds: activeDesignationIdsState,
          pageNumber: activePageNumber,
          gender: genderState,
          query: searchValue,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
        })
      );
    } else if (
      (isSpoc(user.current_role) || isHr(user.current_role)) &&
      !isAdmin(user.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL
    ) {
      dispatch(
        getUserByCompetency({
          roleId: user.role_id,
          panelCompetencyId: isAdmin(user.current_role)
            ? null
            : user.current_role_competency_id,
          competencyId: competencyIdState,
          pageNumber: activePageNumber,
          designationIds: activeDesignationIdsState,
          dateOfJoining: dateOfJoiningState,
          subCompetency: subCompetencyState,
          gender: genderState,
          query: searchValue,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
        })
      );
    } else if (
      (isSpoc(user.current_role) || isHr(user.current_role)) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE
    ) {
      dispatch(
        fetchActivePanelByCompetency({
          competencyId: user.current_role_competency_id,
          designationIds: activeDesignationIdsState,
          roleId: user.role_id,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          panelGroupId:
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
              ? tagsState
              : null,
          roundName: roundsNameState,
          activedesignationId: interviewerDesignationIdsState,
          joiningDate: dateOfJoiningState,
          subCompetency: subCompetencyState,
          signal: signal,
        })
      );
      dispatch(
        getpanelGroupList({
          roleId: user.role_id,
          competencyId: user.current_role_competency_id,
        })
      );
    } else if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE &&
      isAdmin(user.current_role)
    ) {
      dispatch(
        fetchActiveHrSpoc({
          roleId: user.role_id,
          panelCompetencyId: isAdmin(user.current_role)
            ? null
            : user.current_role_competency_id,
          competencyId: competencyIdState,
          roleCompetencyIds: multicompetencyIdsState,
          designationIds: activeDesignationIdsState,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          signal: signal,
          roleType: roleTypesState,
        })
      );
    }
    setIsFilterApplied(true);
    dispatch(applyFilterStatus());
    handleClose();
  };

  return (
    <>
      <RosterFilterList
        competencyIdFilter={competencyIdFilter}
        setCompetencyIdFilter={setCompetencyIdFilter}
        competencyId={competencyId}
        interviewerDesignation={interviewerDesignation}
        options={options}
        optionsvalue={optionsvalue}
        optionsFilterByCompetency={optionsFilterByCompetency}
        optionCompetency={optionCompetency}
        keyvalue={keyvalue}
        setKeyvalue={setKeyvalue}
        user={user}
        roundName={roundName}
        gender={gender}
        activedesignationId={activedesignationId}
        joiningLabel={joiningLabel}
        subcompetency={subcompetency}
        isAllEligibleSelected={isAllEligibleSelected}
        isAllinterviewerSelected={isAllinterviewerSelected}
        isAllSelected={isAllSelected}
        panelGroupList={panelGroupList}
        panelGroupId={panelGroupId}
        setPanelGroupId={setPanelGroupId}
        resetRosterFilter={resetRosterFilter}
        applyFilter={applyFilter}
        handleClose={handleClose}
        handleInterviewerDesignationChange={handleInterviewerDesignationChange}
        handleEligibleDesignationChangeForActive={
          handleEligibleDesignationChangeForActive
        }
        handleEligibleDesignationChange={handleEligibleDesignationChange}
        handleRoundChange={handleRoundChange}
        handleCompetencyChange={handleCompetencyChange}
        setSubcompetency={setSubcompetency}
        setGender={setGender}
        handleJoiningDateChange={handleJoiningDateChange}
        filtericon={filtericon}
        toggleDrawer={toggleDrawer}
        optionsForHr={optionsForHr}
        roleName={roleName}
        setRoleName={setRoleName}
        handleRoleChange={handleRoleChange}
        adminCompetencyIdFilter={adminCompetencyIdFilter}
        setAdminCompetencyIdFilter={setAdminCompetencyIdFilter}
        handleAdminCompetencyChange={handleAdminCompetencyChange}
        competencyIdState={competencyIdState}
        multicompetencyIdsState={multicompetencyIdsState}
        interviewerDesignationIdsState={interviewerDesignationIdsState}
        activeDesignationIdsState={activeDesignationIdsState}
        genderState={genderState}
        roleTypesState={roleTypesState}
        subCompetencyState={subCompetencyState}
        dateOfJoiningLabel={dateOfJoiningLabel}
        roundsNameState={roundsNameState}
        tagsState={tagsState}
      />
      {showSelectDateOfJoiningModal && (
        <CustomDateModal
          show={showSelectDateOfJoiningModal}
          setShow={setShowSelectDateOfJoiningModal}
          setJoiningDate={setJoiningDate}
        />
      )}
    </>
  );
};

export default Filter;
