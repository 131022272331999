import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../components/Spinner";
import DatePickerComponent from "../../../components/DatePickerComponent/index";

import {
  getPanelistDataForExport,
  exportPanelistData,
} from "../../../redux/features/Reports/reportSlice";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

import closeIcon from "../../../assets/icons/close-icon.svg";

import dayjs from "dayjs";
import Excel from "exceljs";
import { format, addDays } from "date-fns";
import { saveAs } from "file-saver";
import "react-datepicker/dist/react-datepicker.css";

import { deriveAbbreviatedName } from "../../../utils/app-utils";
import { headersForPanelistDataExport } from "../../../constants";

import styles from "./Basic.module.scss";
import theme from "../../../theme";

const ExportPanelistDataExcelModal = ({ show, setShow, competencyList }) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const { panelistSlots } = useSelector((state) => state.reportReducer?.data);
  const {
    loading,
    exportPanelistDataExcel,
    panelistDataExcelPeriodTo,
    panelistDataExcelPeriodFrom,
  } = useSelector((state) => state.reportReducer);

  const [error, setError] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [periodTo, setPeriodTo] = useState(dayjs(new Date()));
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);
  const [periodFrom, setPeriodFrom] = useState(dayjs(new Date()));

  useEffect(() => {
    setPeriodTo(dayjs(addDays(new Date(periodFrom), 7)));
  }, [periodFrom]);

  const workSheetName = "Slots-Weekdays-Report";
  const generateCsvFileName =
    competencyList &&
    `${
      competencyList[loginInfo.current_role_competency_id]
    }_slots_report_${format(new Date(periodFrom), "yyyy-MM-dd")}_to_${format(
      new Date(periodTo),
      "yyyy-MM-dd"
    )}`;
  const handleClose = useCallback(
    (e) => {
      setShow(false);
    },
    [setShow]
  );
  const saveExcelSlots = useCallback(async () => {
    const workbook = new Excel.Workbook();
    try {
      const fileName =
        competencyList &&
        `${
          competencyList[loginInfo.current_role_competency_id]
        }_slots_report_${panelistDataExcelPeriodFrom}_to_${panelistDataExcelPeriodTo}`;
      const worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = headersForPanelistDataExport;
      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 22;
        column.alignment = { horizontal: "center" };
      });
      excelData.forEach((singleData) => {
        worksheet.addRow(singleData);
      });
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
  }, [excelData]);

  useEffect(() => {
    const dataForExcel = panelistSlots?.length
      ? panelistSlots?.map((item) => {
          let obj = {
            name: item.name,
            email: item.email,
            designation: item.designation,
            tags: item?.panel_tags?.length && item?.panel_tags?.map((tag)=>tag.tag_name).join(", "),
            slots_provided: item.slots_provided,
            bookings_total: item.bookings_total,
            scheduled: item.scheduled,
            completed: item.completed,
            slots_not_filled: item.slots_not_filled,
            to_be_scheduled: item.to_be_scheduled,
            interviewer_na: item.interviewer_na,
            interviewer_rescheduled: item.interviewer_rescheduled,
            candidate_na: item.candidate_na,
            candidate_rescheduled: item.candidate_rescheduled,
            total_slots: item.slots_total,
          };
          return obj;
        })
      : [];
    setExcelData(dataForExcel);
  }, [panelistSlots, dispatch]);
  useEffect(() => {
    if (exportPanelistDataExcel) {
      setIsExportButtonClicked(true);
      dispatch(exportPanelistData({ exportBookingDataExcel: false }));
    }
  }, [dispatch, exportPanelistDataExcel]);
  useEffect(() => {
    if (isExportButtonClicked) {
      saveExcelSlots();
      setIsExportButtonClicked(false);
      handleClose();
    }
  }, [handleClose, isExportButtonClicked, saveExcelSlots]);

  const validateForm = () => {
    let error = false;
    let d_period_from = format(new Date(periodFrom), "yyyy-MM-dd");
    let d_period_end = format(new Date(periodTo), "yyyy-MM-dd");
    let min_date = format(new Date().setFullYear(2015, 12, 31), "yyyy-MM-dd");
    if (
      !(
        d_period_from >= min_date &&
        d_period_end >= min_date &&
        d_period_from <= d_period_end
      )
    ) {
      error = true;
    }
    return error;
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    const isInvalid = validateForm();
    setError(isInvalid);
    if (!isInvalid) {
      const data = {
        from: format(new Date(periodFrom), "yyyy-MM-dd"),
        to: format(new Date(periodTo), "yyyy-MM-dd"),
        roleId: loginInfo.role_id,
      };
      dispatch(getPanelistDataForExport(data));
    }
  };

  if (loading) return <Spinner />;
  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: "28rem",
          },
        }}
      >
        <DialogTitle
          color={theme.palette.secondary.main}
          className={styles.dialogTitle}
        >
          {" "}
          {`(${
            competencyList &&
            deriveAbbreviatedName(
              competencyList[loginInfo.current_role_competency_id]
            )
          })`}{" "}
          - Export Panelist Data to Excel
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <DialogContent>
          {error && (
            <Typography sx={{ color: theme.palette.error.dark }}>
              *Select Correct Date.
            </Typography>
          )}
          <DialogContentText></DialogContentText>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <DatePickerComponent
                label="Period From"
                value={periodFrom}
                handleChange={setPeriodFrom}
              />
            </Grid>
            <Grid item sm={6}>
              <DatePickerComponent
                label="Period To"
                value={periodTo}
                handleChange={setPeriodTo}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            fullWidth
            disabled={!periodFrom || !periodTo}
            variant={"contained"}
            onClick={onFormSubmit}
            className={styles.addTagButtonStyle}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            Export Data
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportPanelistDataExcelModal;
