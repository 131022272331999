import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {IOSSwitch} from "../../../../components/Switch/IoSSwitch";
import Spinner from "../../../../components/Spinner";
import WarningPopup from "../BookingModal/WarningPopup";

import {
  fetchMarkedWeekendDrive,
  updateMarkedWeekendSlots,
  markWeekendDriveAvailability,
} from "../../../../redux/features/WeekendDrive/weekendDriveSlice";
import { fetchUpcomingBookings } from "../../../../redux/features/Roster/rosterSlice";

import {
  TextField,
  Typography,
  Grid,
  Divider,
  Button,
  IconButton,
  FormControlLabel,
  Chip,
  Dialog,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import CloseIcon from "../../../../assets/icons/close-icon.svg";
import InfoIcon from "../../../../assets/icons/info-icon.svg";

import format from "date-fns/format";
import { dateToString, extractTime } from "../../../../utils/date-utils";
import {
  INTERVIEW_STATUS_CHECK,
  checkEqualDates,
  ROLE_TYPES,
} from "../../../../constants";

import styles from "./style.module.scss";
import theme from "../../../../theme";

const { isAdmin } = ROLE_TYPES;

const WeekendAvailability = ({
  show,
  setShow,
  loginInfo,
  competencyId,
  roundDesignationId,
  activePageNumber,
  searchValue,
  slotsProvided,
  roundName,
  activedesignationId,
  weekendDriveList,
  driveDate,
  keyValue,
  panelGroupId,
  weekendDriveSelected,
  subCompetency,
  selectedRecruiters,
  setActivePageNumber,
}) => {
  const dispatch = useDispatch();
  const loggedInUserPanelDetails = useSelector(
    (state) => state?.availabilityReducer?.loggedInUserPanelDetails
  );
  const markedWeekendDriveForExistingDates = useSelector(
    (state) => state.weekendDriveReducer?.existingWeekendDriveForUser
  );
  const loading = useSelector(
    (state) => state.weekendDriveReducer?.weekendAvailabilityLoading
  );
  const bookingsCount = useSelector(
    (state) => state.rosterReducer?.bookingsCount
  );

  const [weekendDriveDate, setWeekendDriveDate] = useState(new Date());
  const [slotTimes, setSlotTimes] = useState([]);
  const [panelId, setPanelId] = useState();
  const [userPanelCompetencyId, setUserPanelCompetencyId] = useState();
  const [driveSlots, setDriveSlots] = useState([]);
  const [weekendDriveListUpdated, setWeekendDriveListUpdated] = useState([]);
  const [updatedSlotId, setUpdateSlotId] = useState(null);
  const [reason, setReason] = useState("");
  const [weekendAvailabilityToggle, setWeekendAvailabilityToggle] =
    useState(false);

  useEffect(() => {
    if (isObjectEmpty(markedWeekendDriveForExistingDates || {})) {
      setReason("");
      setWeekendAvailabilityToggle(false);
      setUpdateSlotId(null);
    }
  }, [markedWeekendDriveForExistingDates]);
  useEffect(() => {
    if (
      markedWeekendDriveForExistingDates &&
      markedWeekendDriveForExistingDates?.slot_time
    ) {
      setUpdateSlotId(markedWeekendDriveForExistingDates.id);
    }
  }, [
    markedWeekendDriveForExistingDates,
    markedWeekendDriveForExistingDates?.slot_time,
  ]);
  useEffect(() => {
    if (weekendDriveList) {
      setWeekendDriveListUpdated(
        weekendDriveList.filter(
          (item) =>
            new Date(item.date_of_drive).getTime() > new Date().getTime() &&
            INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status
        )
      );
    }
  }, [weekendDriveList]);
  useEffect(() => {
    if (driveDate) {
      setWeekendDriveDate(driveDate);
    }
  }, [driveDate]);
  useEffect(() => {
    let data = [];
    if (
      updatedSlotId !== null &&
      !isObjectEmpty(markedWeekendDriveForExistingDates || {})
    ) {
      if (
        markedWeekendDriveForExistingDates &&
        markedWeekendDriveForExistingDates.slot_time?.length === 0
      ) {
        setReason(markedWeekendDriveForExistingDates?.comments);
        setWeekendAvailabilityToggle(true);
        setSlotTimes([]);
      }
      if (
        markedWeekendDriveForExistingDates &&
        markedWeekendDriveForExistingDates.slot_time?.length > 0
      ) {
        markedWeekendDriveForExistingDates?.slot_time.forEach(
          (slotByUser, index) => {
            data.push({
              startTime: slotByUser.startTime,
              endTime: slotByUser.endTime,
              id: index,
            });
          }
        );
        setSlotTimes(data);
        setReason("");
        setWeekendAvailabilityToggle(false);
      }
    } else if (
      updatedSlotId === null &&
      isObjectEmpty(markedWeekendDriveForExistingDates || {})
    ) {
      setReason("");
      setWeekendAvailabilityToggle(false);
      setSlotTimes([]);
    }
  }, [markedWeekendDriveForExistingDates, updatedSlotId]);
  useEffect(() => {
    const getDateWiseWeekendDrive =
      new Date(weekendDriveDate).getTime() > new Date().getTime() &&
      weekendDriveListUpdated.filter((driveDetails) =>
        checkEqualDates(
          new Date(driveDetails.date_of_drive),
          new Date(weekendDriveDate)
        )
      );
    if (getDateWiseWeekendDrive.length) {
      setDriveSlots(getDateWiseWeekendDrive[0]?.slots);
    } else {
      setDriveSlots([]);
    }
  }, [weekendDriveDate, weekendDriveListUpdated]);
  useEffect(() => {
    if (userPanelCompetencyId) {
      if (
        loggedInUserPanelDetails?.panel_competency_id ===
        Number(userPanelCompetencyId)
      ) {
        setPanelId(loggedInUserPanelDetails?.panel_id);
      }
    }
    if (loggedInUserPanelDetails) {
      setUserPanelCompetencyId(loggedInUserPanelDetails?.panel_competency_id);
      setPanelId(loggedInUserPanelDetails?.panel_id);
    }
  }, [
    userPanelCompetencyId,
    setUserPanelCompetencyId,
    setPanelId,
    loggedInUserPanelDetails,
  ]);
  useEffect(() => {
    if (
      loginInfo.current_role_competency_id &&
      new Date(weekendDriveDate).getTime() > new Date().getTime()
    ) {
      !isAdmin(loginInfo.current_role) &&
        dispatch(
          fetchMarkedWeekendDrive({
            roleId: loginInfo.role_id,
            dateOfAvailable: weekendDriveDate
              ? new Date(weekendDriveDate).toISOString()
              : "",
            userPanelMappingId: panelId,
          })
        );
    }
  }, [loginInfo, panelId, weekendDriveDate]);
  useEffect(() => {
    if (
      loginInfo.current_role_competency_id &&
      new Date(weekendDriveDate).getTime() > new Date().getTime()
    ) {
      !isAdmin(loginInfo.current_role) &&
        dispatch(
          fetchUpcomingBookings({
            roleId: loginInfo.role_id,
            driveDate: driveDate,
            panelId: panelId,
          })
        );
    }
  }, [loginInfo, panelId, weekendDriveDate]);
  useEffect(() => {
    if (new Date(weekendDriveDate).getDate() > new Date().getDate()) {
      setSlotTimes([]);
      setWeekendAvailabilityToggle(false);
    }
  }, [weekendDriveDate]);

  const handleClose = () => {
    setShow(false);
    setReason("");
    setWeekendAvailabilityToggle(false);
  };
  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };
  const onSetUnAvailable = () => {
    setWeekendAvailabilityToggle(!weekendAvailabilityToggle);
    setSlotTimes([]);
  };
  const doesSlotExist = (slot) => {
    let boolean = false;
    slotTimes.length &&
      slotTimes.forEach((item) => {
        if (
          new Date(item.startTime).getTime() ===
            new Date(slot.startTime).getTime() &&
          new Date(item.endTime).getTime() === new Date(slot.endTime).getTime()
        ) {
          boolean = true;
        }
      });
    return boolean;
  };
  const validateSlotFill = (index, slot) => {
    if (slotTimes.length === 0) {
      setSlotTimes([
        {
          startTime: slot.startTime,
          endTime: slot.endTime,
          id: index,
        },
      ]);
    } else if (!doesSlotExist(slot)) {
      setSlotTimes([
        ...slotTimes,
        {
          startTime: slot.startTime,
          endTime: slot.endTime,
          id: index,
        },
      ]);
    } else if (doesSlotExist(slot)) {
      setSlotTimes(
        slotTimes.filter(
          (item) =>
            new Date(item.startTime).getTime() !==
            new Date(slot.startTime).getTime()
        )
      );
    }
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (updatedSlotId === null) {
      dispatch(
        markWeekendDriveAvailability({
          slotAvailability: {
            slotTime: slotTimes.length
              ? slotTimes.map((item) => ({
                  startTime: dateToString(item.startTime),
                  endTime: dateToString(item.endTime),
                }))
              : [],
            userPanelMappingId: panelId,
            panelCompetencyId: userPanelCompetencyId,
            dateOfAvailable: new Date(weekendDriveDate).toISOString(),
            comments: slotTimes.length === 0 ? reason : "not available",
          },
          panelMemberAvailabilityPayload: {
            competencyId: loginInfo?.current_role_competency_id,
            roundDesignationIds: roundDesignationId,
            periodFrom: new Date(
              new Date(driveDate).setHours(0, 0, 0, 0)
            ).toISOString(),
            periodTo: new Date(
              new Date(driveDate).setHours(23, 59, 59, 59)
            ).toISOString(),
            pageNumber: activePageNumber,
            roleId: loginInfo.role_id,
            query: searchValue,
            slotsProvided: slotsProvided,
            roundName: roundName,
            activedesignationId,
            keyValue,
            panelGroupId: panelGroupId,
            driveId: weekendDriveSelected.length
              ? weekendDriveSelected[0]?.id
              : null,
            subCompetency: subCompetency,
            recruiterIds: selectedRecruiters,
          },
          roleId: loginInfo.role_id,
          dispatch,
        })
      );
    } else {
      dispatch(
        updateMarkedWeekendSlots({
          slotAvailability: {
            slotTime: slotTimes.length
              ? slotTimes.map((item) => ({
                  startTime: dateToString(item.startTime),
                  endTime: dateToString(item.endTime),
                }))
              : [],
            userPanelMappingId: panelId,
            panelCompetencyId: userPanelCompetencyId,
            dateOfAvailable: new Date(weekendDriveDate).toISOString(),
            comments: slotTimes.length === 0 ? reason : "not-available",
            slotId: updatedSlotId,
          },
          panelMemberAvailabilityPayload: {
            competencyId: loginInfo?.current_role_competency_id,
            roundDesignationIds: roundDesignationId,
            periodFrom: new Date(
              new Date(driveDate).setHours(0, 0, 0, 0)
            ).toISOString(),
            periodTo: new Date(
              new Date(driveDate).setHours(23, 59, 59, 59)
            ).toISOString(),
            pageNumber: activePageNumber,
            roleId: loginInfo.role_id,
            query: searchValue,
            slotsProvided: slotsProvided,
            roundName: roundName,
            activedesignationId,
            keyValue,
            panelGroupId: panelGroupId,
            driveId: weekendDriveSelected.length
              ? weekendDriveSelected[0]?.id
              : null,
            subCompetency: subCompetency,
            recruiterIds: selectedRecruiters,
          },
          roleId: loginInfo.role_id,
          dispatch,
        })
      );
    }
    setActivePageNumber(1);
    handleClose();
  };
  const showModalWithDateCheck = () => {
    let boolean = false;
    const filterdata =
      weekendDriveList &&
      weekendDriveList.filter((item) =>
        checkEqualDates(
          new Date(item?.date_of_drive),
          new Date(weekendDriveDate)
        )
      );
    if (filterdata && filterdata.length) {
      boolean =
        new Date(weekendDriveDate).getTime() >
          new Date(filterdata[0]?.date_of_availability_deadline).getTime() &&
        new Date().getTime() <
          new Date(filterdata[0]?.date_of_availability_deadline).getTime();
    }
    return boolean;
  };

  if (loading) return <Spinner />;

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: "50vh",
          zIndex: 1300,
          borderRadius: "10px",
          boxShadow: "0px 6px 30px 0px #OD",
        },
      }}
    >
      <DialogTitle
        color={theme.palette.secondary.main}
        className={styles.weekendAvailabilityStyle}
      >
        <Typography className={styles.weekendAvailabilityHeadingStyle}>
          Weekend Drive Availability for{" "}
          {format(new Date(weekendDriveDate), "dd-MM-yyyy")}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={(e) => handleClose(e)}
        margin={1}
        sx={{
          position: "absolute",
          right: 18,
          top: 30,
          color: (theme) => theme.palette.grey[500],
        }}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <img src={CloseIcon} alt="close-icon" />
      </IconButton>
      <Divider />

      <DialogContent sx={{ padding: "0px 14px 0px", overflowX: "hidden" }}>
        <Grid
          container
          m={0}
          pr={2}
          spacing={3}
          className={styles.hideVerticalStyle}
        >
          {bookingsCount >= 1 ? (
            <Grid
              container
              m={0}
              pr={0.5}
              spacing={1}
              className={styles.hideVerticalStyle}
            >
              <Grid item display={"flex"} alignItems={"center"}>
                <WarningPopup
                  WarningMessage={`You cannot edit the avaialability as bookings have been created in weekdays .`}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              {showModalWithDateCheck() ? (
                <Grid
                  container
                  m={0}
                  pr={2}
                  spacing={3}
                  className={styles.hideVerticalStyle}
                >
                  <Grid
                    item
                    display={"flex"}
                    alignItems={"center"}
                    marginBottom={1}
                  >
                    <FormControlLabel
                      control={<IOSSwitch />}
                      label=<Typography
                        paddingLeft={1}
                        className={styles.unavailableStyle}
                      >
                        Mark Unavailable for this Drive
                      </Typography>
                      className={styles.switchStyle}
                      checked={weekendAvailabilityToggle}
                      onChange={(e) => onSetUnAvailable(e)}
                    />
                  </Grid>

                  {!weekendAvailabilityToggle &&
                    driveSlots &&
                    driveSlots.length && (
                      <Grid
                        container
                        margin={1}
                        p={1}
                        spacing={2}
                        className={styles.slotsWrapStyle}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          xl={12}
                          pr={2}
                          pl={1}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Typography className={styles.unavailableStyle}>
                            Select Slots For Drive
                          </Typography>
                          <Tooltip
                            title="Click on Slots to Fill Availability"
                            placement="top"
                            arrow
                          >
                            <Typography
                              justifyContent="right"
                              alignItems="center"
                            >
                              <img
                                src={InfoIcon}
                                alt="close-icon"
                                className={styles.unfilteredStyles}
                              />
                            </Typography>
                          </Tooltip>
                        </Grid>
                        {driveSlots?.map((slot, index) => {
                          return (
                            <Grid
                              item
                              key={index}
                              m={0}
                              pr={2}
                              pl={1}
                              spacing={1}
                              marginBottom={1}
                              display={"flex"}
                              className={styles.hideVerticalStyle}
                              xs={6}
                              md={6}
                              lg={6}
                              xl={6}
                            >
                              <Chip
                                fullWidth
                                label={`${extractTime(
                                  slot.startTime
                                )} - ${extractTime(slot.endTime)}`}
                                className={
                                  doesSlotExist(slot)
                                    ? `${styles.badgeNotSlotsStyle}`
                                    : `${styles.badgeSlotsStyle}`
                                }
                                onClick={() => {
                                  !weekendAvailabilityToggle &&
                                    validateSlotFill(index, slot);
                                }}
                                disabled={weekendAvailabilityToggle}
                                clickable={!weekendAvailabilityToggle}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}

                  {weekendAvailabilityToggle && (
                    <Grid
                      container
                      spacing={3}
                      className={styles.hideVerticalStyle}
                      paddingTop={1}
                    >
                      <Grid item xs={12} md={12} lg={12} xl={12} ml={1} mr={1}>
                        <TextField
                          fullWidth
                          placeholder="Reason for Unavailability"
                          rows={4}
                          className={styles.unavailableStyle}
                          onChange={(e) => setReason(e.target.value)}
                          value={reason}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  m={0}
                  pr={0.5}
                  spacing={1}
                  className={styles.hideVerticalStyle}
                >
                  <Grid item display={"flex"} alignItems={"center"}>
                    <WarningPopup
                      WarningMessage={`Panel Availability Deadline Date Exceeds. Contact HR for More
              Info.`}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions className={styles.btnStyle}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          disabled={
            !showModalWithDateCheck() ||
            (weekendAvailabilityToggle && !reason.length) ||
            (!weekendAvailabilityToggle && !slotTimes.length) ||
            bookingsCount >= 1
          }
          className={
            !showModalWithDateCheck() ||
            (weekendAvailabilityToggle && !reason.length) ||
            (!weekendAvailabilityToggle && !slotTimes.length) ||
            bookingsCount >= 1
              ? `${styles.saveBtnDisable}`
              : `${styles.saveBtn}`
          }
          onClick={(e) => onFormSubmit(e)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WeekendAvailability;
