import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import WeekendPanelMemberItem from "./WeekendPanelMemberItem";
import ListDriveCoordinatorsAndDesginations from "./ListDriveCoordinatorsAndDesginations";
import Pagination from "../../../../components/Pagination/index";
import NoData from "../../NoData/index";
import Spinner from "../../../../components/Spinner";

import {
  Grid,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Chip,
} from "@mui/material";

import {
  extractTime,
  extractTimeWithoutAMPM,
} from "../../../../utils/date-utils";
import {
  deriveAbbreviatedName,
  extractDate,
} from "../../../../utils/app-utils";
import {
  INTERVIEW_STATUS_CHECK,
  ROLE_TYPES,
  checkEqualDates,
} from "../../../../constants";

import styles from "./style.module.scss";

const { isPanelMember } = ROLE_TYPES;

const WeekendAvailabilityTable = ({
  open,
  competencyId,
  roundDesignationId,
  activePageNumber,
  setActivePageNumber,
  searchValue,
  slotsProvided,
  roundName,
  stateToogle,
  activedesignationId,
  weekendDriveList,
  weekendDriveDate,
  keyValue,
  weekendDriveListUpdated,
  loggedInUserPanelDetails,
  allowDriveCoordinatorsForBooking,
  panelGroupId,
  weekendDriveSelected,
  payloadForAllInWeekend,
  subCompetency,
  selectedRecruiters,
  handlePageClick,
  tagsBadge
}) => {
  const { loginInfo } = useSelector((state) => state?.authReducer);
  const loadingWeekendDates = useSelector(
    (state) => state.weekendDriveReducer?.loadingForWeekendListByDate
  );
  const {
    userListWeekend,
    loadingWeekendAvailabity,
    pageCountWeekend,
    totalCountWeekend,
  } = useSelector(
    (state) => state.availabilityReducer?.weekendAvailabilityData
  );
  const driveDetailsLoading = useSelector(
    (state) => state.availabilityReducer?.loading
  );
  const loading = useSelector((state) => state.availabilityReducer?.loading);

  const [coordinators, setCoordinators] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [slotsforGivenDate, setSlotsForGivenDate] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getSlotsByDate();
      setSlotsForGivenDate(result);
      setDataLoaded(true);
    };
    fetchData();
  }, [weekendDriveDate, weekendDriveListUpdated]);
  useEffect(() => {
    const filteredData = weekendDriveListUpdated.find(
      (list) =>
        extractDate(list.date_of_drive) === extractDate(weekendDriveDate)
    );
    setCoordinators(filteredData?.coordinators || []);
    setDesignationList(filteredData?.designations || []);
  }, [weekendDriveDate, weekendDriveListUpdated]);

  const getSlotsByDate = () => {
    let slots = [];
    weekendDriveListUpdated &&
      weekendDriveListUpdated?.length &&
      weekendDriveListUpdated.forEach((item) => {
        if (
          checkEqualDates(
            new Date(new Date(item.date_of_drive)),
            new Date(weekendDriveDate)
          ) &&
          (INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status ||
            INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED === item.drive_status ||
            INTERVIEW_STATUS_CHECK.COMPLETED === "COMPLETED")
        ) {
          slots = item.slots;
        }
      });
    return slots;
  };
  const extractRoundWiseEligibility = (rounds = []) => {
    const response = rounds.reduce(
      (acc, value) => {
        if (value.roundName === "R1") {
          acc["R1"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        if (value.roundName === "R2") {
          acc["R2"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        if (value.roundName === "MR") {
          acc["MR"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        return acc;
      },
      { R1: [], R2: [], MR: [] }
    );
    return (
      <Grid container display="flex" flexWrap="wrap">
        {!!response["R1"].length &&
          response["R1"].map((round, index) => (
            <Grid item key={index} style={{ flexBasis: "auto" }}>
              <Chip
                label={`R1-${round}`}
                className={styles.individualChipStyle}
              />
            </Grid>
          ))}
        {!!response["R2"].length &&
          response["R2"].map((round, index) => (
            <Grid item key={index} style={{ flexBasis: "auto" }}>
              <Chip
                label={`R2-${round}`}
                className={styles.individualChipStyle}
              />
            </Grid>
          ))}
        {!!response["MR"].length &&
          response["MR"].map((round, index) => (
            <Grid item key={index} style={{ flexBasis: "auto" }}>
              <Chip
                label={`MR-${round}`}
                className={styles.individualChipStyle}
              />
            </Grid>
          ))}
      </Grid>
    );
  };
  const getSlots = () => {
    if (slotsforGivenDate?.length && (!loadingWeekendAvailabity || !loading)) {
      return slotsforGivenDate?.map((item, index) => (
        <TableCell
          className={"tableheading"}
          sx={{ width: "10%" }}
          align="center"
          key={index}
        >
          {extractTimeWithoutAMPM(item.startTime)}
          {" - "}
          {extractTime(item.endTime)}
        </TableCell>
      ));
    }
    return null;
  };

  if (
    loadingWeekendAvailabity ||
    !dataLoaded ||
    loadingWeekendDates ||
    driveDetailsLoading ||
    loading
  )
    return <Spinner />;
  if (
    !isPanelMember(loginInfo?.current_role) &&
    (!weekendDriveListUpdated?.length || !userListWeekend?.length) &&
    !loadingWeekendAvailabity &&
    !loadingWeekendDates &&
    !driveDetailsLoading &&
    dataLoaded &&
    !loading
  )
    return <NoData />;
  if (
    isPanelMember(loginInfo?.current_role) &&
    (!weekendDriveListUpdated?.length || !userListWeekend?.length) &&
    !loadingWeekendAvailabity &&
    !loadingWeekendDates &&
    !driveDetailsLoading &&
    dataLoaded &&
    !loading
  )
    return <NoData data={"Not eligible for weekend drive"} disabled={true} />;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      marginTop={2}
      className={"globalLayoutHeight"}
    >
      {!!weekendDriveListUpdated?.length && (
        <Grid item xs={12} lg={12} xl={12} md={12}>
          <ListDriveCoordinatorsAndDesginations
            coordinators={coordinators}
            designationList={designationList}
          />
        </Grid>
      )}

      {!loadingWeekendAvailabity ||
      !!userListWeekend?.length ||
      !!weekendDriveListUpdated?.length ||
      dataLoaded ||
      !loadingWeekendDates ||
      !loading ? (
        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          md={12}
          className={
            open
              ? `globalLayoutHeight ${styles.tableOpenScroll}`
              : `globalLayoutHeight ${styles.tableCloseScroll}`
          }
          marginTop={5}
          marginRight={3}
        >
          <Paper className={"globalTableLayoutStyle"}>
            <TableContainer
              className={"globalLayoutHeight globalTableContainerAlign"}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.tableHead}>
                  <TableRow className={styles.tableRow}>
                    <TableCell
                      className={`tableheading ${styles.column1headerFreeze}`}
                      style={{ minWidth: "185px" }}
                    >
                      Panel Member
                    </TableCell>

                    <TableCell
                      className={`tableheading ${styles.column2headerFreeze}`}
                      style={{ whiteSpace: "nowrap", minWidth: "165px" }}
                    >
                      Eligible Rounds
                    </TableCell>

                    <TableCell
                      className={`tableheading`}
                      style={{ whiteSpace: "nowrap", minWidth: "165px" }}
                    >
                      Tags
                    </TableCell>

                    {getSlots()}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!userListWeekend &&
                    userListWeekend?.length !== 0 &&
                    userListWeekend?.map((user, index) => {
                      return (
                        <WeekendPanelMemberItem
                          user={user}
                          index={index}
                          extractRoundWiseEligibility={
                            extractRoundWiseEligibility
                          }
                          slotsforGivenDate={slotsforGivenDate}
                          weekendDriveDate={weekendDriveDate}
                          rounds={user.rounds}
                          booking={user.booking}
                          competencyId={competencyId}
                          slotsProvided={slotsProvided}
                          roundDesignationId={roundDesignationId}
                          activePageNumber={activePageNumber}
                          searchValue={searchValue}
                          roundName={roundName}
                          email={user.email}
                          stateToogle={stateToogle}
                          activedesignationId={activedesignationId}
                          keyValue={keyValue}
                          weekendDriveListUpdated={weekendDriveListUpdated}
                          allowDriveCoordinatorsForBooking={
                            allowDriveCoordinatorsForBooking
                          }
                          panelGroupId={panelGroupId}
                          weekendDriveSelected={weekendDriveSelected}
                          payloadForAllInWeekend={payloadForAllInWeekend}
                          subCompetency={subCompetency}
                          selectedRecruiters={selectedRecruiters}
                          loginInfo={loginInfo}
                          tagsBadge={tagsBadge}
                        />
                      );
                    })}
                </TableBody>
              </Table>
              {!!userListWeekend &&
                userListWeekend?.length !== 0 &&
                dataLoaded && (
                  <Pagination
                    userCount={totalCountWeekend}
                    pageCount={pageCountWeekend}
                    handlePageClick={handlePageClick}
                    activePageNumber={activePageNumber}
                  />
                )}
            </TableContainer>
          </Paper>
        </Grid>
      ) : isPanelMember(loginInfo.current_role) ? (
        !loggedInUserPanelDetails.user_details_id && (
          <NoData data={"Not eligible for weekend drive"} disabled={true} />
        )
      ) : (
        <NoData />
      )}
    </Grid>
  );
};

export default WeekendAvailabilityTable;
