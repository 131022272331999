import React from "react";

import { BarChart } from "@mui/x-charts/BarChart";

const ReportDesignationWiseDashboard = ({ designationWise, total }) => {
  return (
    <>
      {designationWise?.length ? (
        <BarChart
          height={300}
          
          slotProps={{
            legend: {
              labelStyle: {
                color: "#2E1C41",
                fontSize: "10px",
              },
              direction: "row",
              position: { vertical: "top", horizontal: "middle" },
              markGap: 8,
              padding: 29,
              borderRadius:'50%'

            },
          }}
          xAxis={[
            {
              tickLabelStyle: {
                angle: 45,
                dominantBaseline: "hanging",
                textAnchor: "start",
                fontSize: "8px",
                color: "#2E1C41",
              },
              labelStyle: {
                transform: "translateY(px)",
              },
              data: designationWise?.map((data) => data?.designationName) || [
                null,
              ],
              scaleType: "band",
            },
          ]}
          yAxis={[
            {
              tickLabelStyle: {
                fontSize: "8px",
                color: "#2E1C41",
                fontWeight: "bolder",
              },
              tickNumber:2
            }
          ]}
          series={[
            {
              data: designationWise?.map((data) => data?.currentlyInPanel) || [
                null,
              ],
              label: "Currently in Panel",
              id: "curretlyInPanelId",
              stack: "total",
              color: "#7EA6DD",
            },
            {
              data: designationWise?.map(
                (x) => x?.totalCount - x?.currentlyInPanel
              ) || [null],
              label: "Not in Panel",
              id: "NotInPanelId",
              stack: "total",
              color: "#FADA95",
            },
          ]}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ReportDesignationWiseDashboard;
