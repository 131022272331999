import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCallingCode } from 'react-phone-number-input/input';

import CreateBookingModal from "./CreateBookingModal";
import UpdateBookingModal from "./UpdateBookingModal";
import BookingTimeConflicting from "./BookingTimeConflicting";
import BookingActionItems from "./BookingActionItems";
import WarningPopup from "./WarningPopup";

import {
  fetchEligiblePaneListForPairing,
  fetchUnpairedInterviewerBookingsList,
  createBooking,
  updateBooking,
} from "../../../../redux/features/Availability/availabilitySlice";

import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "../../../../assets/icons/close-icon.svg";

import dayjs from "dayjs";
import { isEqual } from "date-fns";
import { phone } from "phone";

import {
  INTERVIEW_STATUS_CHECK,
  CREATE_PAIRING_OPTIONS,
  ALPHA_NUMERIC_VALUES
} from "../../../../constants";

import styles from "./style.module.scss";

const BookingModal = (props) => {
  const {
    showBookingModal,
    setShowBookingModal,
    rounds,
    bookingId,
    user,
    booking,
    panelMemberAvailabilityPayload,
    weekDate,
    clickedBookingInterviewerStatus,
    slotTime
  } = props;
  const steps = ["Interview Details", "Candidate Details", "Event Details"];

  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state?.authReducer?.loginInfo);
  const competencyList = useSelector((state) => state.authReducer.competency);
  const eligiblePairingList = useSelector(
    (state) => state.availabilityReducer.eligiblePairingList
  );
  const unpairedInterviewersBookingsList = useSelector(
    (state) => state.availabilityReducer.unpairedInterviewersBookingsList
  );

  const [activeStep, setActiveStep] = useState(0);
  const [bookingDate, setBookingDate] = useState(dayjs(new Date()));
  const [startTime, setStartTime] = useState(
    dayjs(new Date(new Date().setHours(12, 0, 0, 0)))
  );
  const [endTime, setEndTime] = useState(
    dayjs(new Date(new Date().setHours(13, 0, 0, 0)))
  );
  const [roundId, setRoundId] = useState(null);
  const [interviewStatus, setInterviewStatus] = useState(
    INTERVIEW_STATUS_CHECK.SCHEDULED
  );
  const [comments, setComments] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("IN");
  const [countryCodeEditable, setCountryCodeEditable] = useState(true);
  const [interviewMode, setInterviewMode] = useState("VIRTUAL");
  const [keyValue, setKeyValue] = useState("weekdays");
  const [createEvent, setCreateEvent] = useState(false);
  const [calendarEventTitle, setCalendarEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [pairingUserId, setPairingUserId] = useState(null);
  const [pairingCompetencyId, setPairingCompetencyId] = useState(null);
  const [pairingDesignationId, setPairingDesignationId] = useState(null);
  const [notavailable, setNotAvailable] = useState(false);
  const [notprovided, setNotProvided] = useState(false);
  const [slots, setSlots] = useState(false);
  const [isRoundInPairingIntial, setIsRoundInPairingIntial] = useState(false);
  const [isRoundInPairingFinal, setIsRoundInPairingFinal] = useState(false);
  const [leaderUserId, setLeaderUserId] = useState(null);
  const [leaderCompetencyId, setLeaderCompetencyId] = useState(null);
  const [leaderDesignationId, setLeaderDesignationId] = useState(null);
  const [leaderEmail, setLeaderEmail] = useState("");
  const [pairedEmail, setPairedEmail] = useState("");
  const [removePairingReason, setRemovePairingReason] = useState("");
  const [interviewerUnavailableReason, setinterviewerUnavailableReason] =
    useState("");
  const [
    pairedInterviewerUnavailableReason,
    setPairedInterviewerUnavailableReason,
  ] = useState("");
  const [isDisableMultipleFields, setIsDisableMultipleFields] = useState(false);
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] =
    useState(false);
  const [bookingIdForPairedRound, setBookingIdForPairedRound] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [fileName, setFileName] = useState("");
  const [intervieweName, setIntervieweName] = useState("");
  const [intervieweEmail, setIntervieweEmail] = useState("");
  const [cvLink, setCvLink] = useState(null);
  const [
    handleDisableCandidateMobileNumber,
    setHandleDisableCandidateMobileNumber,
  ] = useState(false);
  const [isBookingTimeConflicting, setIsBookingTimeConflicting] =
    useState(false);
  const [showInterviewStatusNaOrRsPopup, setShowInterviewStatusNaOrRsPopup] =
    useState(false);
  const [isNaOrRsStatusPopupYesClicked, setIsNaOrRsStatusPopupYesClicked] =
    useState(false);
  const [isNaOrRsStatusPopupNoClicked, setIsNaOrRsStatusPopupNoClicked] =
    useState(false);
  const [
    isNaOrRsStatusPopupCancelClicked,
    setIsNaOrRsStatusPopupCancelClicked,
  ] = useState(false);
  const [
    isNaOrRsStatusPopupContinueClicked,
    setIsNaOrRsStatusPopupContinueClicked,
  ] = useState(false);
  const [isRoundValidationError, setIsRoundValidationError] = useState(false);
  const [isPairedValidationError, setIsPairedValidationError] = useState(false);
  const [
    isRemovePairedInterviewerValidationError,
    setIsRemovePairedInterviewerValidationError,
  ] = useState(false);
  const [
    isUpdatedInterviewerValidationError,
    setIsUpdatedInterviewerValidationError,
  ] = useState(false);
  const [handleRequiredCandidateName, setHandleRequiredCandidateName] =
    useState(false);
  const [handleRequiredCandidateEmail, setHandleRequiredCandidateEmail] =
    useState(false);
  const [handleRequiredCalendaEventTitle, setHandleRequiredCalendarEventTitle] =
    useState(false);
  const [isCreatePairingChecked, setIsCreatePairingChecked] = useState("No");
  const [deleteEventOnBooking, setDeleteEventOnBooking] = useState(true);
  const [disableFieldsForPairedView, setDisableFieldsForPairedView] =
    useState(false);

  const mobileNumberRef = useRef();
  const isPairingRoundSelected =
    rounds.length &&
    rounds.some((item) => {
      if (item.id === parseInt(roundId) && item.isPairing && !bookingId) {
        return true;
      }
      return false;
    });
  const filterUnpairedInterviewersBookingsList =
    unpairedInterviewersBookingsList &&
    unpairedInterviewersBookingsList.filter((item) => item.user_id !== user.id);

  const setPairingState = useCallback(
    (value) => {
      if (isPairingRoundSelected) {
        setPairingUserId(user.id);
        setPairingCompetencyId(user.competency_id);
        setPairingDesignationId(user.designation_id);
      } else {
        if (value) {
          eligiblePairingList.forEach((item) => {
            if (item.user_id === parseInt(value)) {
              setPairingUserId(value);
              setPairingCompetencyId(item.competency_id);
              setPairingDesignationId(item.designation_id);
            }
          });
        }
      }
    },
    [
      isPairingRoundSelected,
      eligiblePairingList,
      user.competency_id,
      user.designation_id,
      user.id,
    ]
  );
  const setLeaderState = useCallback(
    (value) => {
      if (isPairingRoundSelected) {
        unpairedInterviewersBookingsList?.forEach((item) => {
          if (parseInt(item.booking_id) === parseInt(bookingIdForPairedRound)) {
            setLeaderUserId(item.user_id);
            setLeaderCompetencyId(item.competency_id);
            setLeaderDesignationId(item.user_deisgnation_id);
          }
        });
      } else {
        if (value === user.id) {
          setLeaderUserId(user.id);
          setLeaderCompetencyId(user.competency_id);
          setLeaderDesignationId(user.designation_id);
        } else {
          eligiblePairingList.forEach((item) => {
            if (item.user_id === parseInt(value)) {
              setLeaderUserId(value);
              setLeaderCompetencyId(item.competency_id);
              setLeaderDesignationId(item.designation_id);
            }
          });
        }
      }
    },
    [
      bookingIdForPairedRound,
      isPairingRoundSelected,
      eligiblePairingList,
      unpairedInterviewersBookingsList,
      user.competency_id,
      user.designation_id,
      user.id,
    ]
  );
  const getPairingMemberData = () => {
    let pairingMemberData;
    eligiblePairingList.forEach((item) => {
      if (parseInt(item.user_id) === parseInt(pairingUserId)) {
        pairingMemberData = item;
      }
    });
    return pairingMemberData;
  };
  const pairedmemberData = getPairingMemberData();

  useEffect(() => {
    if (eligiblePairingList.length) {
      eligiblePairingList.forEach((item) => {
        if (parseInt(item.user_id) === parseInt(pairingUserId)) {
          setPairedEmail(item.email);
        }
        if (parseInt(item.user_id) === parseInt(leaderUserId)) {
          setLeaderEmail(item.email);
        }
      });
    }
    if (eligiblePairingList.length && leaderUserId === null) {
      setLeaderEmail(user.email);
    }
  }, [leaderUserId, pairingUserId, eligiblePairingList, user.email]);
  useEffect(() => {
    if (eligiblePairingList?.length) {
      eligiblePairingList.forEach((item) => {
        if (parseInt(item.user_id) === parseInt(pairingUserId)) {
          setPairedEmail(item.email);
        }
        if (parseInt(item.user_id) === parseInt(leaderUserId)) {
          setLeaderEmail(item.email);
        }
      });
    }
    if (eligiblePairingList?.length && leaderUserId === null) {
      setLeaderEmail(user.email);
    }
  }, [leaderUserId, pairingUserId, eligiblePairingList, user.email]);
  useEffect(() => {
    if (roundId) {
      let settedRoundName = "";
      let designationName = "";
      rounds.forEach((round) => {
        if (round.id === parseInt(roundId)) {
          settedRoundName = round.roundName;
          designationName = round.designationName;
        }
      });
      const calendarEventTitle = `${
        intervieweName?.length ? `${intervieweName} |` : ""
      } ${
        settedRoundName === "R1" ? "Round 1" : "Round 2"
      } | ${designationName} | ${
        competencyList[loginInfo?.current_role_competency_id] ?? ""
      }`;
      if (!bookingId) {
        setCalendarEventTitle(calendarEventTitle);
      }
      if (booking && bookingId) {
        if (booking?.calendarEventTitle === null) {
          setCalendarEventTitle(calendarEventTitle);
        } else {
          setCalendarEventTitle(booking?.calendarEventTitle);
        }
      }
    }
  }, [
    booking,
    bookingId,
    createEvent,
    intervieweName,
    roundId,
    rounds,
    user.competency_name,
  ]);
  useEffect(() => {
    onSetBookingDate(dayjs(weekDate));
    if(slotTime && slotTime?.startTime){
      const startTime = dayjs(slotTime?.startTime);
      const endTime = startTime.add(1, 'hour').toISOString();
      setStartTime(startTime)
      setEndTime(endTime)
    }
  }, []);
  useEffect(() => {
    if (roundId) {
      rounds.forEach((round) => {
        if (round.id === parseInt(roundId) && !isPairingRoundSelected) {
          dispatch(
            fetchEligiblePaneListForPairing({
              competencyId: loginInfo.current_role_competency_id,
              designationId: round.designationId,
              roleId: loginInfo.role_id,
              roundName: round.roundName,
              isRoundInPairing: isRoundInPairingFinal,
              pairingDate: new Date(bookingDate).toISOString(),
              bookingUserId: user.id,
            })
          );
        }
      });
    }
  }, [
    bookingDate,
    dispatch,
    isPairingRoundSelected,
    isRoundInPairingFinal,
    loginInfo.current_role_competency_id,
    loginInfo.role_id,
    roundId,
    rounds,
    user.id,
  ]);
  useEffect(() => {
    if (
      interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
      interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
      INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED === interviewStatus
    ) {
      setShowInterviewStatusNaOrRsPopup(true);
    } else {
      setShowInterviewStatusNaOrRsPopup(false);
    }
  }, [interviewStatus]);
  useEffect(() => {
    if (roundId) {
      rounds.forEach((round) => {
        if (round.id === parseInt(roundId) && isPairingRoundSelected) {
          dispatch(
            fetchUnpairedInterviewerBookingsList({
              competencyId: loginInfo.current_role_competency_id,
              designationId: round.designationId,
              roleId: loginInfo.role_id,
              roundName: round.roundName,
              pairingDate: new Date(bookingDate).toISOString(),
              bookingUserId: user.id,
            })
          );
        }
      });
    }
  }, [
    bookingDate,
    dispatch,
    isPairingRoundSelected,
    loginInfo.current_role_competency_id,
    loginInfo.role_id,
    roundId,
    rounds,
    user.id,
  ]);
  useEffect(() => {
    setIsRoundInPairingFinal(isRoundInPairingIntial);
  }, [isRoundInPairingIntial]);
  useEffect(() => {
    //this useeffect handle slots for paired view
    if (pairingUserId !== null && pairedmemberData) {
      if (pairedmemberData?.slotdetails === null) {
        setNotProvided(true);
        setNotAvailable(false);
        setSlots(false);
      }
      if (
        pairedmemberData?.slotdetails !== null &&
        pairedmemberData?.slotdetails[0].isAvailable === false
      ) {
        setNotProvided(false);
        setNotAvailable(true);
        setSlots(false);
      }
      if (
        pairedmemberData?.slotdetails !== null &&
        pairedmemberData?.slotdetails[0].isAvailable === true
      ) {
        setSlots(true);
        setNotProvided(false);
        setNotAvailable(false);
      }
    }
  }, [pairedmemberData, pairingUserId]);
  useEffect(() => {
    if (
      interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
      interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED ||
      interviewStatus === INTERVIEW_STATUS_CHECK.INVALID ||
      interviewStatus === INTERVIEW_STATUS_CHECK.CANDIDATE_NOT_AVAILABLE ||
      interviewStatus === INTERVIEW_STATUS_CHECK.COMPLETED
    ) {
      setIsNaOrRsStatusPopupContinueClicked(false);
    }
  }, [interviewStatus]);

  const handleClose = (e) => {
    setShowBookingModal(false);
  };
  const handleNext = () => {
    let isValid = false;

    switch (activeStep) {
      case 0:
        isValid = validateStep1();
        break;
      case 1:
        isValid = validateStep2();
        break;
      default:
        break;
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    if (
      activeStep === 1 &&
      isNaOrRsStatusPopupContinueClicked &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
        interviewStatus === INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED)
    ) {
      setShowInterviewStatusNaOrRsPopup(true);
      setIsNaOrRsStatusPopupYesClicked(true);
      setIsNaOrRsStatusPopupContinueClicked(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setHandleRequiredCandidateEmail(false);
    setHandleRequiredCandidateName(false);
  };
  const handleContinue = () => {
    if (!isNaOrRsStatusPopupYesClicked) {
      setIsNaOrRsStatusPopupYesClicked(true);
    }
    if (isNaOrRsStatusPopupYesClicked) {
      setIsNaOrRsStatusPopupContinueClicked(true);
      setIsNaOrRsStatusPopupCancelClicked(false);
      setShowInterviewStatusNaOrRsPopup(false);
      setIsNaOrRsStatusPopupYesClicked(true);
      setIsNaOrRsStatusPopupNoClicked(false);
      setIsRoundInPairingFinal(true);
      handleNext();
    }
  };
  const handleCancel = () => {
    if (isNaOrRsStatusPopupYesClicked) {
      setShowInterviewStatusNaOrRsPopup(false);
      setIsNaOrRsStatusPopupYesClicked(false);
      setIsNaOrRsStatusPopupNoClicked(false);
      setIsNaOrRsStatusPopupContinueClicked(false);
      setIsNaOrRsStatusPopupCancelClicked(true);
      setInterviewStatus("SCHEDULED");
      if (booking && bookingId) {
        onSetBookingDate(dayjs(new Date(weekDate)));
        setStartTime(dayjs(new Date(booking.startTime)));
        setEndTime(dayjs(new Date(booking.endTime)));
      }
    }
    if (!isNaOrRsStatusPopupYesClicked) {
      setShowInterviewStatusNaOrRsPopup(false);
      setIsNaOrRsStatusPopupYesClicked(false);
      setIsNaOrRsStatusPopupNoClicked(true);
      setIsNaOrRsStatusPopupContinueClicked(false);
      setIsNaOrRsStatusPopupCancelClicked(false);
    }
  };
  const handleTimeConflictYes = () => {
    let createBookingOnTimeConflicting = true;
    handleBooking({ createBookingOnTimeConflicting });
  };
  const validateStep1 = () => {
    const isRoundInvalid = !roundId;
    setIsRoundValidationError(isRoundInvalid);

    const isPairedInvalid =
      isCreatePairingChecked === CREATE_PAIRING_OPTIONS.YES && !pairingUserId;
    setIsPairedValidationError(isPairedInvalid);

    const isPairingRemovedReasonInvalid =
      (isCreatePairingChecked === CREATE_PAIRING_OPTIONS.REMOVE_PAIRING &&
        !removePairingReason?.length) ||
      (booking &&
        booking?.isCoreInterviewer &&
        booking?.pairedInterviewerId &&
        booking?.pairedInterviewerId !== pairingUserId &&
        !removePairingReason?.length &&
        (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
          interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED));

    setIsRemovePairedInterviewerValidationError(isPairingRemovedReasonInvalid);

    return (
      !isRoundInvalid && !isPairedInvalid && !isPairingRemovedReasonInvalid
    );
  };
  const validateStep2 = () => {
    const emailregex = /^[a-zA-Z0-9+_.-]+@[a-z]+\.[a-z]{2,3}/;
    const isValidEmail = emailregex.test(intervieweEmail);
    if (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED) {
      if (!intervieweName?.length) {
        setHandleRequiredCandidateName(true);
      } else {
        setHandleRequiredCandidateName(false);
      }
      if (!intervieweEmail?.length) {
        setHandleRequiredCandidateEmail(true);
      } else {
        setHandleRequiredCandidateEmail(false);
      }
      if (!intervieweName?.length || !intervieweEmail?.length) {
        return false;
      }
    }
    if(ALPHA_NUMERIC_VALUES.test(intervieweName)){
      return false
    }
    if (intervieweEmail?.length && !isValidEmail) {
      setHandleRequiredCandidateEmail(true);
      return false;
    }
    if ( mobileNumberRef?.current?.value?.length  && !(phone(mobileNumberRef?.current?.value).isValid)){ 
      try {
        const countryCode = getCountryCallingCode(defaultCountry).toString();
        const filteredValue = mobileNumberRef?.current?.value.replace('+' + countryCode, '');
        if(filteredValue.length === 0){
          setMobileNumber(null);
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      setMobileNumberErrorMessage(true);
      return false;
    }
    return true;
  };
  const validateStep3 = () => {
    if (createEvent && !calendarEventTitle?.length) {
      setHandleRequiredCalendarEventTitle(true);
      return false;
    }
    return true;
  };
  const handleBooking = ({ createBookingOnTimeConflicting }) => {
    const isFieldsValid = validateStep3();
    const timeIsValid = verifyTimeCheck(new Date(startTime), new Date(endTime));
    if (!timeIsValid && !createBookingOnTimeConflicting) {
      setIsBookingTimeConflicting(true);
    }
    if (isFieldsValid && (timeIsValid || createBookingOnTimeConflicting)) {
      let settedRoundName = "";
      let designationName = "";
      let designationId = "";
      rounds.forEach((round) => {
        if (round.id === parseInt(roundId)) {
          settedRoundName = round.roundName;
          designationName = round.designationName;
          designationId = round.designationId;
        }
      });
      if (!isPairingRoundSelected && !bookingId) {
        dispatch(
          createBooking({
            interviewerUserId: user.id,
            interviewerCompetencyId: parseInt(leaderCompetencyId),
            interviewerDesignationId: parseInt(leaderDesignationId),
            isBookingInPairing: isRoundInPairingFinal,
            round: settedRoundName,
            competencyId: user.panel_competency_id,
            designationId: designationId,
            pairedInterviewerUserId:
              pairingUserId !== null ? parseInt(pairingUserId) : pairingUserId,
            pairedInterviewerCompetencyId:
              pairingCompetencyId !== null
                ? parseInt(pairingCompetencyId)
                : pairingCompetencyId,
            pairedInterviewerDesignationId:
              pairingDesignationId !== null
                ? parseInt(pairingDesignationId)
                : pairingDesignationId,
            leaderEmail,
            pairedEmail,
            startTime: new Date(startTime).toISOString(),
            endTime: new Date(endTime).toISOString(),
            roleId: loginInfo.role_id,
            comments,
            interviewStatus,
            eventDescription,
            intervieweEmail,
            createEvent,
            intervieweName,
            cvLink,
            calendarEventTitle,
            mobileNumber,
            interviewMode,
            keyValue,
            panelMemberAvailabilityPayload,
          })
        );
      }
      if (isPairingRoundSelected && !bookingId) {
        const calendarData = unpairedInterviewersBookingsList.filter(
          (item) =>
            parseInt(item.booking_id) === parseInt(bookingIdForPairedRound)
        );
        dispatch(
          updateBooking({
            panelId: user.panel_id,
            isBookingInPairing: isRoundInPairingFinal,
            round: settedRoundName,
            designationId: designationId,
            startTime: new Date(startTime).toISOString(),
            endTime: new Date(endTime).toISOString(),
            comments: comments,
            interviewStatus: interviewStatus,
            bookingId: parseInt(bookingIdForPairedRound),
            roleId: loginInfo.role_id,
            panelMemberAvailabilityPayload: panelMemberAvailabilityPayload,
            intervieweEmail: intervieweEmail,
            eventDescription: createEvent && eventDescription,
            calendarEventId: calendarData[0]?.calendar_event_id,
            createEvent: createEvent,
            intervieweName: intervieweName,
            cvLink: cvLink,
            calendarEventTitle: calendarEventTitle ?? "",
            pairedInterviewerUserId: parseInt(pairingUserId),
            pairedInterviewerCompetencyId: parseInt(pairingCompetencyId),
            pairedInterviewerDesignationId: parseInt(pairingDesignationId),
            interviewerUserId: parseInt(leaderUserId),
            interviewerCompetencyId: parseInt(leaderCompetencyId),
            interviewerDesignationId: parseInt(leaderDesignationId),
            pairedInterviewerUnavailableReason:
              pairedInterviewerUnavailableReason,
            interviewerUnavailableReason: interviewerUnavailableReason,
            leaderEmail: leaderEmail,
            pairedEmail: pairedEmail,
            mobileNumber: mobileNumber,
            interviewMode: interviewMode,
            discardEvent: createEvent && deleteEventOnBooking,
            coordinatorsEmail: [],
            keyValue: keyValue,
            isYesClicked: isNaOrRsStatusPopupContinueClicked,
          })
        );
      }
      if (bookingId) {
        dispatch(
          updateBooking({
            panelId: user.panel_id,
            isBookingInPairing: isRoundInPairingFinal,
            round: settedRoundName,
            designationId: designationId,
            startTime: new Date(startTime).toISOString(),
            endTime: new Date(endTime).toISOString(),
            comments: comments,
            interviewStatus: interviewStatus,
            bookingId: bookingId,
            roleId: loginInfo.role_id,
            panelMemberAvailabilityPayload: panelMemberAvailabilityPayload,
            intervieweEmail: intervieweEmail,
            eventDescription: createEvent && eventDescription,
            calendarEventId: booking.calendarEventId,
            createEvent: createEvent,
            intervieweName: intervieweName,
            cvLink: cvLink,
            calendarEventTitle: calendarEventTitle ?? "",
            pairedInterviewerUserId:
              pairingUserId !== null ? parseInt(pairingUserId) : pairingUserId,
            pairedInterviewerCompetencyId:
              pairingCompetencyId !== null
                ? parseInt(pairingCompetencyId)
                : pairingCompetencyId,
            pairedInterviewerDesignationId:
              pairingDesignationId !== null
                ? parseInt(pairingDesignationId)
                : pairingDesignationId,
            interviewerUserId:
              leaderUserId !== null ? parseInt(leaderUserId) : leaderUserId,
            interviewerCompetencyId:
              leaderCompetencyId !== null
                ? parseInt(leaderCompetencyId)
                : leaderCompetencyId,
            interviewerDesignationId:
              leaderDesignationId !== null
                ? parseInt(leaderDesignationId)
                : leaderDesignationId,
            pairedInterviewerUnavailableReason:
              pairedInterviewerUnavailableReason,
            interviewerUnavailableReason: interviewerUnavailableReason,
            leaderEmail: leaderEmail,
            pairedEmail: pairedEmail,
            mobileNumber: mobileNumber,
            interviewMode: interviewMode,
            discardEvent: createEvent && deleteEventOnBooking,
            keyValue: keyValue,
            isYesClicked: isNaOrRsStatusPopupContinueClicked,
          })
        );
      }
    }
  };
  const onSetBookingDate = (date) => {
    setBookingDate(dayjs(date));
    const stTime = dayjs(startTime)
      .set("year", dayjs(date.year()))
      .set("month", dayjs(date.month()))
      .set("date", dayjs(date.date()));
    const eTime = dayjs(endTime)
      .set("year", date.year())
      .set("month", date.month())
      .set("date", date.date());
    setStartTime(dayjs(stTime));
    setEndTime(dayjs(eTime));
  };
  const onSetStartTime = (time) => {
    setStartTime(time);
    const startTime = dayjs(new Date(time));
    const endTime = startTime.add(1, "hour");
    setEndTime(endTime);
  };
  const resetPairingState = (id) => {
    setPairingUserId(id);
    setPairingCompetencyId(id);
    setPairingDesignationId(id);
  };
  const resetLeaderState = (id) => {
    setLeaderUserId(id);
    setLeaderCompetencyId(id);
    setLeaderDesignationId(id);
  };
  const [changedToScheduled, setChangedToScheduled] = useState(false);
  const interviewStatusChange = (status, options) => {
    if (booking) {
      const todayDate = new Date(new Date().setHours(0, 0, 0, 0));
      const bookingDate = new Date(
        new Date(booking.startTime).setHours(0, 0, 0, 0)
      );
      if( status === INTERVIEW_STATUS_CHECK.SCHEDULED && options.TO_BE_SCHEDULED ){
        setChangedToScheduled(true)
      }else{
          setChangedToScheduled(false)
      }

      if (
        interviewStatus !== INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus !== INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED
      ) {
        return true;
      }
      if (bookingDate < todayDate) {
        return true;
      }
    }
  };
  const verifyTimeCheck = (bookingStartTime, bookingEndTime) => {
    if (user.booking) {
      for (const item of user.booking) {
        if (
          INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ===
            item.interview_status ||
          INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ===
            item.interview_status ||
          (INTERVIEW_STATUS_CHECK.SCHEDULED === item.interview_status &&
            !interviewStatusChange()) ||
          (INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED === item.interview_status &&
            !interviewStatusChange())
        ) {
          const userBookingStartTime = new Date(item.startTime);
          const userBookingEndTime = new Date(item.endTime);

          // Check if the dates are  equal
          if (
            isEqual(new Date(userBookingStartTime), new Date(bookingStartTime))
          ) {
            // Check for overlap
            if (
              userBookingStartTime.getTime() === bookingStartTime.getTime() ||
              userBookingEndTime.getTime() === bookingEndTime.getTime()
            ) {
              // Overlap detected, return false
              return false;
            }
            if (
              !(
                bookingEndTime.getTime() <= userBookingStartTime.getTime() ||
                bookingStartTime.getTime() >= userBookingEndTime.getTime()
              )
            ) {
              // Overlap detected, return false
              return false;
            }
          }
        }
      }
    }
    // No overlap detected, or user.booking is undefined, return true
    return true;
  };

  return (
    <>
      <Dialog
        open={showBookingModal}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: "90vh",
            width: "85vh",
            minHeight: "30vh",
            maxHeight: "100vh",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle sx={{ padding: "30px 24px 0px" }}>
          <Typography variant="h6" gutterBottom className={styles.headingStyle}>
            {!booking ? "Book a Slot" : "Update Booking"}- {user.first_name}{" "}
            {user.last_name}
            {booking?.isCoreInterviewer &&
              booking?.pairedInterviewerId !== null && (
                <Typography>Interviewer</Typography>
              )}
            {booking?.isCoreInterviewer === false &&
              booking?.pairedInterviewerId && (
                <Typography>Paired Interviewer</Typography>
              )}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={CloseIcon} alt="close-icon" />
        </IconButton>
        <DialogContent style={{ height: "100%", paddingBottom: "22px" }}>
          <DialogContentText>
          </DialogContentText>
          {isBookingTimeConflicting ? (
            <BookingTimeConflicting />
          ) : (
            <form style={{ height: "100%" }} noValidate>
              {!booking && (
                <CreateBookingModal
                  clickedBookingInterviewerStatus={clickedBookingInterviewerStatus}
                  showBookingModal={showBookingModal}
                  setShowBookingModal={setShowBookingModal}
                  activeStep={activeStep}
                  steps={steps}
                  user={user}
                  rounds={rounds}
                  roundId={roundId}
                  setRoundId={setRoundId}
                  interviewStatus={interviewStatus}
                  setInterviewStatus={setInterviewStatus}
                  interviewMode={interviewMode}
                  setInterviewMode={setInterviewMode}
                  comments={comments}
                  setComments={setComments}
                  defaultCountry={defaultCountry}
                  setDefaultCountry={setDefaultCountry}
                  countryCodeEditable={countryCodeEditable}
                  setCountryCodeEditable={setCountryCodeEditable}
                  createEvent={createEvent}
                  setCreateEvent={setCreateEvent}
                  calendarEventTitle={calendarEventTitle}
                  setCalendarEventTitle={setCalendarEventTitle}
                  eventDescription={eventDescription}
                  setEventDescription={setEventDescription}
                  weekDate={weekDate}
                  bookingDate={bookingDate}
                  onSetBookingDate={onSetBookingDate}
                  setBookingDate={setBookingDate}
                  startTime={startTime}
                  setStartTime={setStartTime}
                  endTime={endTime}
                  setEndTime={setEndTime}
                  onSetStartTime={onSetStartTime}
                  isPairingRoundSelected={isPairingRoundSelected}
                  isRoundInPairingIntial={isRoundInPairingIntial}
                  setIsRoundInPairingIntial={setIsRoundInPairingIntial}
                  isRoundInPairingFinal={isRoundInPairingFinal}
                  setIsRoundInPairingFinal={setIsRoundInPairingFinal}
                  eligiblePairingList={eligiblePairingList}
                  setLeaderState={setLeaderState}
                  resetPairingState={resetPairingState}
                  resetLeaderState={resetLeaderState}
                  pairingUserId={pairingUserId}
                  setPairingState={setPairingState}
                  setPairingUserId={setPairingUserId}
                  setPairingCompetencyId={setPairingCompetencyId}
                  setPairingDesignationId={setPairingDesignationId}
                  setPairedEmail={setPairedEmail}
                  notprovided={notprovided}
                  slots={slots}
                  notavailable={notavailable}
                  pairedmemberData={pairedmemberData}
                  selectedEmail={selectedEmail}
                  setSelectedEmail={setSelectedEmail}
                  fileName={fileName}
                  setFileName={setFileName}
                  intervieweName={intervieweName}
                  setIntervieweName={setIntervieweName}
                  intervieweEmail={intervieweEmail}
                  setIntervieweEmail={setIntervieweEmail}
                  mobileNumber={mobileNumber}
                  setMobileNumber={setMobileNumber}
                  cvLink={cvLink}
                  setCvLink={setCvLink}
                  handleDisableCandidateMobileNumber={
                    handleDisableCandidateMobileNumber
                  }
                  setHandleDisableCandidateMobileNumber={
                    setHandleDisableCandidateMobileNumber
                  }
                  panelMemberAvailabilityPayload={
                    panelMemberAvailabilityPayload
                  }
                  setBookingIdForPairedRound={setBookingIdForPairedRound}
                  bookingIdForPairedRound={bookingIdForPairedRound}
                  interviewStatusChange={interviewStatusChange}
                  unpairedInterviewersBookingsList={
                    unpairedInterviewersBookingsList
                  }
                  isRoundValidationError={isRoundValidationError}
                  setIsRoundValidationError={setIsRoundValidationError}
                  isPairedValidationError={isPairedValidationError}
                  setIsPairedValidationError={setIsPairedValidationError}
                  isCreatePairingChecked={isCreatePairingChecked}
                  setIsCreatePairingChecked={setIsCreatePairingChecked}
                  handleRequiredCandidateName={handleRequiredCandidateName}
                  handleRequiredCandidateEmail={handleRequiredCandidateEmail}
                  validateStep1={validateStep1}
                  setHandleRequiredCandidateName={
                    setHandleRequiredCandidateName
                  }
                  setHandleRequiredCandidateEmail={
                    setHandleRequiredCandidateEmail
                  }
                  handleRequiredCalendaEventTitle={
                    handleRequiredCalendaEventTitle
                  }
                  setHandleRequiredCalendarEventTitle={
                    setHandleRequiredCalendarEventTitle
                  }
                  mobileNumberRef={mobileNumberRef}
                  mobileNumberErrorMessage={mobileNumberErrorMessage}
                  setMobileNumberErrorMessage={setMobileNumberErrorMessage}
                  filterUnpairedInterviewersBookingsList={
                    filterUnpairedInterviewersBookingsList
                  }
                />
              )}
              {booking && (
                <UpdateBookingModal
                  changedToScheduled={changedToScheduled}
                  isUpdateModalOpen={true}
                  showInterviewStatusNaOrRsPopup={
                    showInterviewStatusNaOrRsPopup
                  }
                  isNaOrRsStatusPopupNoClicked={isNaOrRsStatusPopupNoClicked}
                  isNaOrRsStatusPopupYesClicked={isNaOrRsStatusPopupYesClicked}
                  isNaOrRsStatusPopupCancelClicked={
                    isNaOrRsStatusPopupCancelClicked
                  }
                  isNaOrRsStatusPopupContinueClicked={
                    isNaOrRsStatusPopupContinueClicked
                  }
                  booking={booking}
                  showBookingModal={showBookingModal}
                  setShowBookingModal={setShowBookingModal}
                  activeStep={activeStep}
                  steps={steps}
                  user={user}
                  rounds={rounds}
                  roundId={roundId}
                  setRoundId={setRoundId}
                  interviewStatus={interviewStatus}
                  setInterviewStatus={setInterviewStatus}
                  interviewMode={interviewMode}
                  setInterviewMode={setInterviewMode}
                  comments={comments}
                  setComments={setComments}
                  defaultCountry={defaultCountry}
                  setDefaultCountry={setDefaultCountry}
                  countryCodeEditable={countryCodeEditable}
                  setCountryCodeEditable={setCountryCodeEditable}
                  createEvent={createEvent}
                  setCreateEvent={setCreateEvent}
                  calendarEventTitle={calendarEventTitle}
                  setCalendarEventTitle={setCalendarEventTitle}
                  eventDescription={eventDescription}
                  setEventDescription={setEventDescription}
                  weekDate={weekDate}
                  bookingDate={bookingDate}
                  onSetBookingDate={onSetBookingDate}
                  setBookingDate={setBookingDate}
                  startTime={startTime}
                  setStartTime={setStartTime}
                  endTime={endTime}
                  setEndTime={setEndTime}
                  onSetStartTime={onSetStartTime}
                  isPairingRoundSelected={isPairingRoundSelected}
                  isRoundInPairingIntial={isRoundInPairingIntial}
                  setIsRoundInPairingIntial={setIsRoundInPairingIntial}
                  isRoundInPairingFinal={isRoundInPairingFinal}
                  setIsRoundInPairingFinal={setIsRoundInPairingFinal}
                  eligiblePairingList={eligiblePairingList}
                  setLeaderState={setLeaderState}
                  resetPairingState={resetPairingState}
                  resetLeaderState={resetLeaderState}
                  pairingUserId={pairingUserId}
                  leaderUserId={leaderUserId}
                  setPairingState={setPairingState}
                  setPairingUserId={setPairingUserId}
                  setPairingCompetencyId={setPairingCompetencyId}
                  setPairingDesignationId={setPairingDesignationId}
                  setPairedEmail={setPairedEmail}
                  notprovided={notprovided}
                  slots={slots}
                  notavailable={notavailable}
                  pairedmemberData={pairedmemberData}
                  selectedEmail={selectedEmail}
                  setSelectedEmail={setSelectedEmail}
                  fileName={fileName}
                  setFileName={setFileName}
                  intervieweName={intervieweName}
                  setIntervieweName={setIntervieweName}
                  intervieweEmail={intervieweEmail}
                  setIntervieweEmail={setIntervieweEmail}
                  mobileNumber={mobileNumber}
                  setMobileNumber={setMobileNumber}
                  cvLink={cvLink}
                  setCvLink={setCvLink}
                  handleDisableCandidateMobileNumber={
                    handleDisableCandidateMobileNumber
                  }
                  setHandleDisableCandidateMobileNumber={
                    setHandleDisableCandidateMobileNumber
                  }
                  panelMemberAvailabilityPayload={
                    panelMemberAvailabilityPayload
                  }
                  setBookingIdForPairedRound={setBookingIdForPairedRound}
                  bookingIdForPairedRound={bookingIdForPairedRound}
                  interviewStatusChange={interviewStatusChange}
                  unpairedInterviewersBookingsList={
                    unpairedInterviewersBookingsList
                  }
                  isRoundValidationError={isRoundValidationError}
                  setIsRoundValidationError={setIsRoundValidationError}
                  isPairedValidationError={isPairedValidationError}
                  setIsPairedValidationError={setIsPairedValidationError}
                  isCreatePairingChecked={isCreatePairingChecked}
                  setIsCreatePairingChecked={setIsCreatePairingChecked}
                  handleRequiredCandidateName={handleRequiredCandidateName}
                  handleRequiredCandidateEmail={handleRequiredCandidateEmail}
                  validateStep1={validateStep1}
                  setHandleRequiredCandidateName={
                    setHandleRequiredCandidateName
                  }
                  setHandleRequiredCandidateEmail={
                    setHandleRequiredCandidateEmail
                  }
                  handleRequiredCalendaEventTitle={
                    handleRequiredCalendaEventTitle
                  }
                  setHandleRequiredCalendarEventTitle={
                    setHandleRequiredCalendarEventTitle
                  }
                  isUpdatedInterviewerValidationError={
                    isUpdatedInterviewerValidationError
                  }
                  setIsUpdatedInterviewerValidationError={
                    setIsUpdatedInterviewerValidationError
                  }
                  isRemovePairedInterviewerValidationError={
                    isRemovePairedInterviewerValidationError
                  }
                  removePairingReason={removePairingReason}
                  setRemovePairingReason={setRemovePairingReason}
                  setIsRemovePairedInterviewerValidationError={
                    setIsRemovePairedInterviewerValidationError
                  }
                  setLeaderEmail={setLeaderEmail}
                  pairedInterviewerUnavailableReason={
                    pairedInterviewerUnavailableReason
                  }
                  setinterviewerUnavailableReason={
                    setinterviewerUnavailableReason
                  }
                  setPairedInterviewerUnavailableReason={
                    setPairedInterviewerUnavailableReason
                  }
                  deleteEventOnBooking={deleteEventOnBooking}
                  setDeleteEventOnBooking={setDeleteEventOnBooking}
                  disableFieldsForPairedView={disableFieldsForPairedView}
                  setDisableFieldsForPairedView={setDisableFieldsForPairedView}
                  mobileNumberRef={mobileNumberRef}
                  mobileNumberErrorMessage={mobileNumberErrorMessage}
                  setMobileNumberErrorMessage={setMobileNumberErrorMessage}
                  isDisableMultipleFields={isDisableMultipleFields}
                  setIsDisableMultipleFields={setIsDisableMultipleFields}
                />
              )}
            </form>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "10px 24px 20px" }}>
          <BookingActionItems
            showInterviewStatusNaOrRsPopup={showInterviewStatusNaOrRsPopup}
            handleCancel={handleCancel}
            isNaOrRsStatusPopupYesClicked={isNaOrRsStatusPopupYesClicked}
            handleContinue={handleContinue}
            isBookingTimeConflicting={isBookingTimeConflicting}
            handleClose={handleClose}
            handleTimeConflictYes={handleTimeConflictYes}
            handleBack={handleBack}
            activeStep={activeStep}
            handleBooking={handleBooking}
            handleNext={handleNext}
            isPairingRoundSelected={isPairingRoundSelected}
            unpairedInterviewersBookingsList={unpairedInterviewersBookingsList}
            steps={steps}
            bookingIdForPairedRound={bookingIdForPairedRound}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BookingModal;
