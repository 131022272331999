import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../utils/app-utils";
import { BASE_URL, ROLE_TYPES } from "../../../constants/index";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";
const initialState = {
  loading: false,
  inactiveDesignations: [],
  fetchInactiveDesignations: [],
};

export const filterSelectedCompetency = createAsyncThunk(
  "filterSelectedCompetency",
  async ({ roleId, competencyId, inactiveDesignationsArray }) => {
    try {
      const response = await post(
        `${BASE_URL}/designation/updateInactiveDesignations`,
        { queryParams: { role_id: roleId, competencyId: competencyId } },
        { inactiveDesignations: inactiveDesignationsArray },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchInactiveDesignationByCompetencyId = createAsyncThunk(
  "fetchInactiveDesignationByCompetencyId",
  async ({ competencyId, roleId, inactiveDesignationsArray }) => {
    try {
      const response = await get(
        `${BASE_URL}/designation/getInactiveDesignations`,
        { queryParams: { role_id: roleId, competencyId: competencyId } },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
const designationSlice = createSlice({
  name: "Designation",
  initialState,
  reducers: {
    resetDesignationState() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(filterSelectedCompetency.pending, (state) => {
        state.loading = true;
      })
      .addCase(filterSelectedCompetency.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={"Designations saved successfully."}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            inactiveDesignations: action.payload?.inactiveDesignations ?? [],
            loading: false,
          };
        }
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(filterSelectedCompetency.rejected, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={`Error occured while saving designation.`}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(fetchInactiveDesignationByCompetencyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchInactiveDesignationByCompetencyId.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              fetchInactiveDesignations:
                action.payload?.fetchInactiveDesignations ?? [],
              loading: false,
            };
          }
          return {
            ...state,
            fetchInactiveDesignations: [],
            loading: false,
          };
        }
      )
      .addCase(
        fetchInactiveDesignationByCompetencyId.rejected,
        (state, action) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={`Error occured while fetching inactive designation.`}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          state.loading = false;
        }
      );
  },
});

export const { resetDesignationState } = designationSlice.actions;
export default designationSlice.reducer;
