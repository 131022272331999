import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import closeIcon from "../../assets/icons/close-icon.svg";

import styles from "./style.module.scss";
import theme from "../../theme";

const DeleteConfirmationModal = ({
  show,
  setShow,
  clickConfirm,
  showWeekendDriveExistsWarningModalText = false,
}) => {
  const closeModal = () => {
    setShow(false);
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    clickConfirm();
    setShow(false);
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        PaperProps={{
          style: {
            height: !showWeekendDriveExistsWarningModalText ? "12rem" : "17rem",
            width: "22rem",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle
          color={theme.palette.secondary.main}
          sx={{ padding: "30px 24px 0px" }}
        >
          {!showWeekendDriveExistsWarningModalText ? (
            <>
              Are you sure you want to <br />
              delete this item?
            </>
          ) : (
            <>
              <>
                Are you sure you want to create a booking? <br />
              </>
              <Typography variant="body2" marginTop={1} marginBottom={1}>
                Note : The panel is part of the drive but hasn't yet provided
                their availability. We recommend confirming with them offline
                once before proceeding.
              </Typography>
            </>
          )}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <DialogContent sx={{ padding: "0px 14px 0px" }}>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 30px" }}>
          <Button
            fullWidth
            disabled={false}
            variant={"outlined"}
            onClick={closeModal}
            className={styles.addTagButtonStyle}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            Cancel
          </Button>
          <Button
            fullWidth
            disabled={false}
            variant={"contained"}
            onClick={onFormSubmit}
            className={styles.addTagButtonStyle}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteConfirmationModal;
