if (process.env.NODE_ENV === "production") {
  fetch("/config.json")
    .then((resp) => resp.json())
    .then((config) => {
      console.log("Setting app config");
      window.APP_CONFIG = config;
      renderApp();
    });
} else {
  renderApp();
}

function renderApp() {
  console.log("Running app");
  require("./bootstrap");
}
