import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put, isEmpty } from "../../../utils/app-utils";
import { BASE_URL, MODULE_NAME,ROUND_FILTER_OPTIONS,SEND_AVAILABILITY_REMINDERS, SLOT_FILTER_OPTIONS } from "../../../constants/index";
import { fetchActivePanelByCompetency } from "../Roster/rosterSlice";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";

const todayDate = new Date();
const initialState = {
  loading: false,
  isFilterApplied: false,
  availability: {
    providedSlots: SLOT_FILTER_OPTIONS.ALL,
    roundsFilter: ROUND_FILTER_OPTIONS.ALL,
    eligibleDesignationIds:[],
    interviewerDesignationIds:[],
    tagsFilter:[],
    subCompetencyFilter:[]
  },
  startDate: format(todayDate.setDate(todayDate.getDate() + 1), "yyyy-MM-dd"),
  endDate: format(todayDate.setDate(todayDate.getDate() + 14), "yyyy-MM-dd"),
  days: [
    {
      day: "Monday",
      slotTime: [
        {
          validationId: 1,
          startTime: new Date().setHours(10, 0, 0, 0),
          endTime: new Date().setHours(17, 0, 0, 0),
        },
      ],
      comments: "",
    },
    {
      day: "Tuesday",
      slotTime: [
        {
          validationId: 1,
          startTime: new Date().setHours(10, 0, 0, 0),
          endTime: new Date().setHours(17, 0, 0, 0),
        },
      ],
      comments: "",
    },
    {
      day: "Wednesday",
      slotTime: [
        {
          validationId: 1,
          startTime: new Date().setHours(10, 0, 0, 0),
          endTime: new Date().setHours(17, 0, 0, 0),
        },
      ],
      comments: "",
    },
    {
      day: "Thursday",
      slotTime: [
        {
          validationId: 1,
          startTime: new Date().setHours(10, 0, 0, 0),
          endTime: new Date().setHours(17, 0, 0, 0),
        },
      ],
      comments: "",
    },
    {
      day: "Friday",
      slotTime: [
        {
          validationId: 1,
          startTime: new Date().setHours(10, 0, 0, 0),
          endTime: new Date().setHours(17, 0, 0, 0),
        },
      ],
      comments: "",
    },
  ],
  loggedInUserPanelDetails: {},
  search: {
    loading: false,
    roundDesignationIds: [],
  },
  pageCount: 0,
  userList: [],
  totalPanelSelectedCount: 0,
  userAvailability: [],
  weekendAvailability: [],
  weekendAvailabilityData: {
    userListWeekend: [],
    pageCountWeekend: 0,
    loadingWeekendAvailabity: false,
    totalCountWeekend: 0,
  },
  reminderloading:false,
  availabilityReminderInactiveDesignations: [],
  fetchAvailabilityReminderInactiveDesignations: [],
  weekdaysAvailabilityReminderStatus: false,
  candidateDetails: [],
  loadingCandidate: false,
  pairingLoading: false,
  eligiblePairingList: [],
  pairingLoadingWithEnabled: false,
  unpairedInterviewersBookingsList: [],
  panelData: [],
  interviewerDesignationList:[]
};

export const fetchRoundDesignationId = createAsyncThunk(
  "fetchRoundDesignationId",
  async ({ roleId, competencyId, panelGroupId = [] }) => {
    try {
      const url = `${BASE_URL}/availability/competency/${competencyId}/round/designationIds`;
      const response = await get(url, {
        queryParams: { role_id: roleId,  panelGroupId:JSON.stringify(panelGroupId), },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchPanelMembersAvailability = createAsyncThunk(
  "fetchPanelMembersAvailability",
  async ({
    userId,
    competencyId,
    periodFrom,
    periodTo,
    roundDesignationIds = [],
    slotsProvided,
    roleId,
    roundName,
    pageNumber,
    query,
    activedesignationId = [],
    signal,
    panelGroupId = [],
    subCompetency = [],
  }) => {
    try {
      const subCompetencyIds = subCompetency?.map((item) => item.value);
      const response = await get(
        `${BASE_URL}/availability/panel/all`,
        {
          queryParams: {
            periodFrom,
            periodTo,
            userId,
            competencyId,
            roundDesignationIds: JSON.stringify(roundDesignationIds),
            slotsProvided,
            pageNumber,
            roundName,
            role_id: roleId,
            query,
            activedesignationId: JSON.stringify(activedesignationId),
            panelGroupId:JSON.stringify(panelGroupId),
            subCompetency:JSON.stringify(subCompetencyIds),
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const fetchPanelMembersAvailabilityWeekend = createAsyncThunk(
  "fetchPanelMembersAvailabilityWeekend",
  async ({
    userId,
    competencyId,
    periodFrom,
    periodTo,
    roundDesignationIds = [],
    slotsProvided,
    roleId,
    roundName,
    pageNumber,
    query,
    activedesignationId = [],
    signal,
    keyValue,
    panelGroupId = [],
    driveId,
    subCompetency = [],
    recruiterIds = [],
  }) => {
    try {
      const subCompetencyIds = subCompetency?.map((item) => item.value);
      const response = await get(
        `${BASE_URL}/availability/panel/all`,
        {
          queryParams: {
            periodFrom,
            periodTo,
            userId,
            competencyId,
            roundDesignationIds: JSON.stringify(roundDesignationIds),
            slotsProvided,
            pageNumber,
            roundName,
            role_id: roleId,
            query,
            activedesignationId: JSON.stringify(activedesignationId),
            keyValue,
            panelGroupId:JSON.stringify(panelGroupId),
            driveId,
            subCompetency:JSON.stringify(subCompetencyIds),
            recruiterIds: JSON.stringify(recruiterIds),
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const markAvailability = createAsyncThunk(
  "markAvailability",
  async ({
    slotAvailability,
    panelMemberAvailabilityPayload,
    roleId,
    weekend = false,
    copyUserAvailablities = false,
    dispatch,
  }) => {
    let url = `${BASE_URL}/availability/slot`;
    if (weekend) {
      url = `${BASE_URL}/availability/slot/markWeekendAvailability`;
    }
    try {
      const response = await post(
        url,
        {
          queryParams: {
            role_id: roleId,
          },
        },
        { slotAvailability, copyUserAvailablities }
      );
      dispatch(fetchPanelMembersAvailability(panelMemberAvailabilityPayload));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getLoggedInUserPanelDetails = createAsyncThunk(
  "getLoggedInUserPanelDetails",
  async ({ roleId, periodFrom, periodTo, driveId,keyValue= "weekdays" }) => {
    try {
      const url = `${BASE_URL}/availability/panel/currentUser`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          periodFrom,
          periodTo,
          keyValue,
          driveId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getWeekdaysAvailabilityReminderInactiveDesignations =
  createAsyncThunk(
    "getWeekdaysAvailabilityReminderInactiveDesignations",
    async ({ competencyId, roleId }) => {
      try {
        const url = `${BASE_URL}/availability/competency/getWeekdaysAvailabilityReminderInactiveDesignations`;
        const response = await get(url, {
          queryParams: { role_id: roleId, competencyId: competencyId },
        });
        return response;
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  );
export const updateWeekdaysAvailabilityReminderInactiveDesignations =
  createAsyncThunk(
    "updateWeekdaysAvailabilityReminderInactiveDesignations",
    async ({ competencyId, roleId, inactiveDesignationsArray }) => {
      try {
        const url = `${BASE_URL}/availability/competency/updateWeekdaysAvailabilityReminderInactiveDesignations`;
        const response = await put(
          url,
          {
            queryParams: { role_id: roleId, competencyId: competencyId },
          },
          {
            weekdaysReminderMailsInactiveDesignations:
              inactiveDesignationsArray,
          }
        );
        return response;
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  );
export const updateAvailabilityReminder = createAsyncThunk(
  "updateAvailabilityReminder",
  async ({ competencyId, roleId, isActive }) => {
    try {
      const url = `${BASE_URL}/availability/competency/updateAvailabilityReminder`;
      const response = await put(url, {
        queryParams: {
          role_id: roleId,
          competencyId: competencyId,
          isActive: isActive,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getAvailabilityReminderStatus = createAsyncThunk(
  "getAvailabilityReminderStatus",
  async ({ competencyId, roleId }) => {
    try {
      const response = await get(
        `${BASE_URL}/availability/competency/getAvailabilityReminderStatus`,
        {
          queryParams: { role_id: roleId, competencyId: competencyId },
        }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const fetchExistingCandidate = createAsyncThunk(
  "fetchExistingCandidate",
  async ({ competencyId, roleId, searchQuery, signal }) => {
    try {
      const response = await get(
        `${BASE_URL}/availability/panel/candidateList`,
        {
          queryParams: {
            competencyId,
            role_id: roleId,
            searchQuery,
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const fetchEligiblePaneListForPairing = createAsyncThunk(
  "fetchEligiblePaneListForPairing",
  async ({
    competencyId,
    designationId,
    roleId,
    roundName,
    isRoundInPairing,
    pairingDate,
    bookingUserId,
    keyValue,
    startTime,
    endTime,
    dateChanged,
  }) => {
    try {
      const response = await get(
        `${BASE_URL}/availability/panel/eligiblePairing/list`,
        {
          queryParams: {
            competencyId,
            role_id: roleId,
            designationId,
            roundName,
            isRoundInPairing: isRoundInPairing,
            pairingDate: pairingDate,
            bookingUserId: bookingUserId,
            keyValue,
            startTime,
            endTime,
            dateChanged,
          },
        }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchUnpairedInterviewerBookingsList = createAsyncThunk(
  "fetchUnpairedInterviewerBookingsList",
  async ({
    competencyId,
    designationId,
    roleId,
    roundName,
    pairingDate,
    bookingUserId,
  }) => {
    try {
      const response = await get(
        `${BASE_URL}/availability/panel/unpairedInterviewersBookings/list`,
        {
          queryParams: {
            competencyId,
            role_id: roleId,
            designationId,
            roundName,
            pairingDate: pairingDate,
            bookingUserId: bookingUserId,
          },
        }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const createBooking = createAsyncThunk(
  "createBooking",
  async (
    {
      interviewerUserId,
      interviewerCompetencyId,
      interviewerDesignationId,
      isBookingInPairing,
      pairedInterviewerUserId,
      pairedInterviewerCompetencyId,
      pairedInterviewerDesignationId,
      competencyId,
      designationId,
      startTime,
      endTime,
      comments,
      interviewStatus,
      panelMemberAvailabilityPayload,
      roleId,
      round,
      panelId,
      eventDescription,
      intervieweEmail,
      createEvent,
      cvLink,
      intervieweName,
      calendarEventTitle,
      leaderEmail,
      pairedEmail,
      mobileNumber,
      interviewMode,
      keyValue,
    },
    { dispatch }
  ) => {
    try {
      let formData = new FormData();
      let bookingDetails = {
        interviewerUserId,
        interviewerCompetencyId,
        interviewerDesignationId,
        isBookingInPairing,
        pairedInterviewerUserId,
        pairedInterviewerCompetencyId,
        pairedInterviewerDesignationId,
        competencyId,
        designationId,
        startTime,
        endTime,
        comments,
        interviewStatus,
        roleId,
        round,
        panelId,
        eventDescription,
        intervieweEmail,
        createEvent,
        intervieweName,
        calendarEventTitle,
        leaderEmail,
        pairedEmail,
        mobileNumber,
        interviewMode,
        keyValue,
      };
      if (isEmpty(cvLink)) {
        cvLink = null;
        const newData = { cvLink: cvLink };
        bookingDetails = { ...bookingDetails, ...newData };
        formData.append("bookingDetails", JSON.stringify(bookingDetails));
      } else if (cvLink?.name?.length || cvLink?.length) {
        formData.append("file", cvLink);
        formData.append("bookingDetails", JSON.stringify(bookingDetails));
      }
      const response = await post(
        `${BASE_URL}/availability/createBooking`,
        { queryParams: { role_id: roleId } },
        formData
      );
      dispatch(fetchPanelMembersAvailability(panelMemberAvailabilityPayload));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const updateBooking = createAsyncThunk(
  "updateBooking",
  async (
    {
      isBookingInPairing,
      pairedInterviewerUserId,
      pairedInterviewerCompetencyId,
      pairedInterviewerDesignationId,
      interviewerUserId,
      interviewerCompetencyId,
      interviewerDesignationId,
      pairedInterviewerUnavailableReason,
      interviewerUnavailableReason,
      leaderEmail,
      pairedEmail,
      round,
      designationId,
      startTime,
      endTime,
      interviewStatus,
      comments,
      bookingId,
      createEvent,
      roleId,
      panelMemberAvailabilityPayload,
      intervieweEmail,
      eventDescription,
      calendarEventId,
      intervieweName,
      cvLink,
      calendarEventTitle,
      mobileNumber,
      interviewMode,
      discardEvent,
      coordinatorsEmail,
      keyValue,
      dayBeforeDriveConfirmation,
      driveDayConfirmation,
      candidateInterviewStatus,
      isYesClicked,
    },
    { dispatch }
  ) => {
    try {
      let bookingDetails = {
        isBookingInPairing,
        pairedInterviewerUserId,
        pairedInterviewerCompetencyId,
        pairedInterviewerDesignationId,
        interviewerUserId,
        interviewerCompetencyId,
        interviewerDesignationId,
        pairedInterviewerUnavailableReason,
        interviewerUnavailableReason,
        leaderEmail,
        pairedEmail,
        round,
        designationId,
        startTime,
        endTime,
        interviewStatus,
        comments,
        bookingId,
        createEvent,
        roleId,
        panelMemberAvailabilityPayload,
        intervieweEmail,
        eventDescription,
        calendarEventId,
        intervieweName,
        calendarEventTitle,
        mobileNumber,
        interviewMode,
        discardEvent,
        coordinatorsEmail,
        keyValue,
        dayBeforeDriveConfirmation,
        driveDayConfirmation,
        candidateInterviewStatus,
        isYesClicked,
      };
      let formData = new FormData();
      if (isEmpty(cvLink)) {
        cvLink = null;
        const newData = { cvLink: cvLink };
        bookingDetails = { ...bookingDetails, ...newData };
        formData.append("bookingDetails", JSON.stringify(bookingDetails));
      } else if (cvLink?.name?.length || cvLink?.length) {
        formData.append("file", cvLink);
        formData.append("bookingDetails", JSON.stringify(bookingDetails));
      }
      const response = await put(
        `${BASE_URL}/availability/updateBooking`,
        {
          queryParams: {
            booking_id: bookingId,
            role_id: roleId,
          },
        },
        formData
      );
      dispatch(fetchPanelMembersAvailability(panelMemberAvailabilityPayload));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const markTempUnavailableUser = createAsyncThunk(
  "markTempUnavailableUser",
  async ({
    id,
    notes,
    periodFrom,
    periodTo,
    roleId,
    competencyId,
    panelMemberPayload,
    moduleName,
    dispatch,
  }) => {
    try {
      const response = await post(
        `${BASE_URL}/availability/panel/tempUnavailable/${id}`,
        {
          queryParams: {
            role_id: roleId,
            competency_id: competencyId,
          },
        },
        {
          notes: notes,
          periodFrom: periodFrom,
          periodTo: periodTo,
        }
      );
      if (moduleName === MODULE_NAME.ROSTER) {
        dispatch(fetchActivePanelByCompetency(panelMemberPayload));
      }
      if (moduleName === MODULE_NAME.AVAILABILITY) {
        dispatch(fetchPanelMembersAvailability(panelMemberPayload));
      }
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getMemberListWithPanelData = createAsyncThunk(
  "getMemberListWithPanelData",
  async ({ roleId, competencyId }) => {
    try {
      const url = `${BASE_URL}/availability/panel/list`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          competencyId: competencyId,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const markTempSuspendUser = createAsyncThunk(
  "markTempSuspendUser",
  async ({
    panelId,
    periodFrom,
    periodTo,
    roleId,
    competencyId,
    comments,
    panelMemberAvailabilityPayload,
    dispatch,
  }) => {
    try {
      const url = `${BASE_URL}/availability/panel/temporarySuspendFromPanel`;
      const response = await post(
        url,
        {
          queryParams: {
            role_id: roleId,
            competencyId: competencyId,
          },
        },
        {
          panelId: panelId,
          periodFrom: periodFrom,
          periodTo: periodTo,
          comments: comments,
        }
      );
      dispatch(
        getLoggedInUserPanelDetails({ roleId: roleId, periodFrom, periodTo })
      );
      dispatch(fetchPanelMembersAvailability(panelMemberAvailabilityPayload));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const createOrUpdateSlotAvailability = createAsyncThunk(
  "createOrUpdateSlotAvailability",
  async ({
    slotAvailability,
    panelMemberAvailabilityPayload,
    roleId,
    dispatch,
  }) => {
    try {
      const response = await put(
        `${BASE_URL}/availability/slot`,
        { queryParams: { role_id: roleId } },
        { slotAvailability }
      );

      dispatch(fetchPanelMembersAvailability(panelMemberAvailabilityPayload));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const sendEmailReminder = createAsyncThunk(
  "sendEmailReminder",
  async ({competencyId,roleId}) => {
    try {
      const response = await post(
        `${BASE_URL}/availability/send-email-notification`,
        { queryParams: {competencyId:competencyId,role_id: roleId ,eventType:SEND_AVAILABILITY_REMINDERS } },
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchInterviewerDesignationsIds = createAsyncThunk(
  "fetchInterviewerDesignationsIds",
  async ({ roleId, competencyId}) => {
    try {
      const url = `${BASE_URL}/availability/competency/${competencyId}/interviewerDesignationIds`;
      const response = await get(url, {
        queryParams: { role_id: roleId },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const availabilitySlice = createSlice({
  name: "Availability",
  initialState,
  reducers: {
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
    setSlotTime(state, action) {
      const { dayName, slot } = action.payload;
      const dayToUpdate = state.days?.find((day) => day.day === dayName);
      if (dayToUpdate) {
        dayToUpdate.slotTime.push(slot);
      }
    },
    updateSlotTime(state, action) {
      const { dayName, slot } = action.payload;
      const dayToUpdate = state.days?.find((day) => day.day === dayName);
      if (dayToUpdate) {
        const index = dayToUpdate.slotTime?.findIndex(
          (s) => s.validationId === slot.validationId
        );
        if (index !== -1) {
          dayToUpdate.slotTime[index] = slot;
        }
      }
    },
    deleteSlotTime(state, action) {
      const { dayName, validationId } = action.payload;
      const dayToUpdate = state.days?.find((day) => day.day === dayName);
      if (dayToUpdate) {
        dayToUpdate.slotTime = dayToUpdate.slotTime?.filter(
          (slot) => slot.validationId !== validationId
        );
      }
    },
    setNotAvailableReason(state, action) {
      const { dayName, comments } = action.payload;
      const dayToUpdate = state?.days?.find((day) => day.day === dayName);
      if (dayToUpdate) {
        dayToUpdate.comments = comments;
      }
    },
    clearAvailableReason(state, action) {
      const { dayName } = action.payload;
      const dayToUpdate = state.days?.find((day) => day.day === dayName);
      if (dayToUpdate) {
        dayToUpdate.slotTime = [];
      }
    },
    resetAvailabilityState() {
      return {
        ...initialState,
      };
    },
    resetAvailabilityDays(state, action) {
      return {
        ...state,
        days: initialState.days,
      };
    },
    applyFilterStatus(state, action) {
      return {
        ...state,
        isFilterApplied: true,
      };
    },
    resetAvailabilityFilterStatus(state,action) {
      return {
        ...state,
        isFilterApplied: false,
        availability: {
          ...state.availability,
          providedSlots: SLOT_FILTER_OPTIONS.ALL,
          roundsFilter: ROUND_FILTER_OPTIONS.ALL,
          eligibleDesignationIds:[],
          interviewerDesignationIds:[],
          tagsFilter:[],
          subCompetencyFilter:[]
        },
      };
    },
    changeSlots(state, action) {
      return {
        ...state,
        availability: {
          ...state.availability,
          providedSlots: action.payload,
        },
      };
    },
    changeRounds(state, action) {
      return {
        ...state,
        availability: {
          ...state.availability,
          roundsFilter: action.payload,
        },
      };
    },
    changeEligibleDesignationsIds(state, action) {
      return {
        ...state,
        availability: {
          ...state.availability,
          eligibleDesignationIds: action.payload,
        },
      };
    },
    changeInterviewerDesignationsIds(state, action) {
      return {
        ...state,
        availability: {
          ...state.availability,
          interviewerDesignationIds: action.payload,
        },
      };
    },
    changeTags(state, action) {
      return {
        ...state,
        availability: {
          ...state.availability,
          tagsFilter: action.payload,
        },
      };
    },
    changeSubCompetency(state, action) {
      return {
        ...state,
        availability: {
          ...state.availability,
          subCompetencyFilter: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoundDesignationId.pending, (state) => {
        state.search.loading = true;
      })
      .addCase(fetchRoundDesignationId.fulfilled, (state, action) => {
        state.search.loading = false;
        if (action.payload) {
          state.search.roundDesignationIds =
            action.payload.designationIds || [];
        }
      })
      .addCase(fetchRoundDesignationId.rejected, (state) => {
        state.search.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching round designation ids."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(fetchPanelMembersAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPanelMembersAvailability.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.pageCount = action.payload.pageCount;
          state.userList = action.payload.userList;
          state.totalPanelSelectedCount = action.payload.totalCount;
          state.weekendAvailability = action.payload.weekendAvailability ?? [];
        }
      })
      .addCase(fetchPanelMembersAvailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchPanelMembersAvailabilityWeekend.pending, (state) => {
        state.weekendAvailabilityData.loadingWeekendAvailabity = true;
      })
      .addCase(
        fetchPanelMembersAvailabilityWeekend.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              weekendAvailabilityData: {
                pageCountWeekend: action.payload.pageCount,
                userListWeekend: action.payload.userList,
                loadingWeekendAvailabity: false,
                totalCountWeekend: action.payload.totalCount,
              },
            };
          }
          return {
            ...state,
            weekendAvailabilityData: {
              ...state.weekendAvailabilityData,
              loadingWeekendAvailabity: false,
            },
          };
        }
      )
      .addCase(fetchPanelMembersAvailabilityWeekend.rejected, (state) => {
        state.weekendAvailabilityData.loadingWeekendAvailabity = false;
      })
      .addCase(getLoggedInUserPanelDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoggedInUserPanelDetails.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload.code === 400 || action.payload.code === 404) {
            toast.custom(
              <CustomToaster
                title={TOAST_TITLE.ERROR}
                message={action.payload.message}
                type={TOAST_TYPE.ERROR}
                time={TOAST_TIME.TWO}
              />
            );
            state.loading = false;
          }
          return {
            ...state,
            loggedInUserPanelDetails: action.payload,
            loading: false,
          };
        }
      })
      .addCase(getLoggedInUserPanelDetails.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error getting user panel details."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(markAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(markAvailability.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(markAvailability.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error in marking availability!"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(
        getWeekdaysAvailabilityReminderInactiveDesignations.pending,
        (state) => {
          state.reminderloading = true;
        }
      )
      .addCase(
        getWeekdaysAvailabilityReminderInactiveDesignations.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              fetchAvailabilityReminderInactiveDesignations:
                action.payload
                  ?.fetchedWeekdaysReminderMailsInactiveDesignations ?? [],
              reminderloading: false,
            };
          }
          return {
            ...state,
            fetchAvailabilityReminderInactiveDesignations: [],
            reminderloading: false,
          };
        }
      )
      .addCase(
        getWeekdaysAvailabilityReminderInactiveDesignations.rejected,
        (state) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message="Error fetching filtered Inactive designations by competency"
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          state.reminderloading = false;
        }
      )
      .addCase(
        updateWeekdaysAvailabilityReminderInactiveDesignations.pending,
        (state) => {
          state.reminderloading = true;
        }
      )
      .addCase(
        updateWeekdaysAvailabilityReminderInactiveDesignations.fulfilled,
        (state, action) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message="Successfully updated reminder mails designations."
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          if (action.payload) {
            return {
              ...state,
              availabilityReminderInactiveDesignations:
                action.payload?.weekdaysReminderMailsInactiveDesignations ?? [],
              reminderloading: false,
            };
          }
          return {
            ...state,
            reminderloading: false,
          };
        }
      )
      .addCase(
        updateWeekdaysAvailabilityReminderInactiveDesignations.rejected,
        (state) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message="Error occured while updating mails designation."
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          state.reminderloading = false;
        }
      )
      .addCase(updateAvailabilityReminder.pending, (state) => {
        state.reminderloading = true;
      })
      .addCase(updateAvailabilityReminder.fulfilled, (state, action) => {
        state.reminderloading = false;
      })
      .addCase(updateAvailabilityReminder.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error in updating Availability Reminder"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.reminderloading = false;
      })
      .addCase(getAvailabilityReminderStatus.pending, (state) => {
        state.reminderloading = true;
      })
      .addCase(getAvailabilityReminderStatus.fulfilled, (state, action) => {
        if (action.payload)
          return {
            ...state,
            weekdaysAvailabilityReminderStatus:
              action.payload.availabilityReminderStatus,
            reminderloading: false,
          };
      })
      .addCase(getAvailabilityReminderStatus.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching Availability Reminder Status"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.reminderloading = false;
      })
      .addCase(fetchExistingCandidate.pending, (state) => {
        state.loadingCandidate = true;
      })
      .addCase(fetchExistingCandidate.fulfilled, (state, action) => {
        state.loadingCandidate = false;
        state.candidateDetails = action.payload.candidateDetails;
      })
      .addCase(fetchExistingCandidate.rejected, (state) => {
        state.loadingCandidate = false;
      })
      .addCase(fetchEligiblePaneListForPairing.pending, (state) => {
        state.pairingLoading = true;
      })
      .addCase(fetchEligiblePaneListForPairing.fulfilled, (state, action) => {
        state.pairingLoading = false;
        state.eligiblePairingList = action.payload.pairingList;
      })
      .addCase(fetchEligiblePaneListForPairing.rejected, (state) => {
        state.pairingLoading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching List For Pairing."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(fetchUnpairedInterviewerBookingsList.pending, (state) => {
        state.pairingLoadingWithEnabled = true;
      })
      .addCase(
        fetchUnpairedInterviewerBookingsList.fulfilled,
        (state, action) => {
          state.pairingLoadingWithEnabled = false;
          state.unpairedInterviewersBookingsList =
            action.payload.unpairedInterviewersList;
        }
      )
      .addCase(fetchUnpairedInterviewerBookingsList.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Fetching Unpaired Panlists."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.pairingLoadingWithEnabled = false;
      })
      .addCase(createBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        if (
          action?.payload?.data?.statusCode >= 200 &&
          action?.payload?.data?.statusCode <= 299
        ) {
          if (action?.payload?.data?.statusCode === 200) {
            toast.custom(
              <CustomToaster
                title={TOAST_TITLE.SUCCESS}
                message={
                  action?.payload?.data?.message ||
                  "Slot is successfully booked"
                }
                type={TOAST_TYPE.SUCCESS}
                time={TOAST_TIME.TWO}
              />
            );
          } else {
            toast.custom(
              <CustomToaster
                title={TOAST_TITLE.WARNING}
                message={
                  action?.payload?.data?.message ||
                  "Slot is successfully booked"
                }
                type={TOAST_TYPE.WARNING}
                time={TOAST_TIME.TWO}
              />
            );
          }
        } else if (action?.payload?.data?.statusCode === 409) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={
                action?.payload?.data?.message ||
                "Booking already exist with same date and time."
              }
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.FOUR}
            />
          );
        } else {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={"Slot Booking failed!"}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        }
        state.loading = false;
      })
      .addCase(createBooking.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={"Slot Booking Creation failed!"}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(updateBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        if (
          action?.payload?.data?.statusCode >= 200 &&
          action?.payload?.data?.statusCode <= 299
        ) {
          if (action?.payload?.data?.statusCode === 200) {
            toast.custom(
              <CustomToaster
                title={TOAST_TITLE.SUCCESS}
                message={
                  action?.payload?.data?.message ||
                  "Booking is successfully updated"
                }
                type={TOAST_TYPE.SUCCESS}
                time={TOAST_TIME.TWO}
              />
            );
          } else {
            toast.custom(
              <CustomToaster
                title={TOAST_TITLE.WARNING}
                message={
                  action?.payload?.data?.message ||
                  "Booking is successfully updated"
                }
                type={TOAST_TYPE.WARNING}
                time={TOAST_TIME.TWO}
              />
            );
          }
        } else if (action?.payload?.data?.statusCode === 409) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={
                action?.payload?.data?.message ||
                "Update Failed!, Booking already exist with same date and time."
              }
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.FOUR}
            />
          );
        } else {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={"Slot Booking updation failed!"}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        }
        state.loading = false;
      })
      .addCase(updateBooking.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message={"Slot Booking updation failed!"}
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(markTempUnavailableUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(markTempUnavailableUser.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message="Marked Temporary Unavailable"
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(markTempUnavailableUser.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while adding Temporary Unavailable"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(getMemberListWithPanelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMemberListWithPanelData.fulfilled, (state, action) => {
        if (action.payload)
          return {
            ...state,
            panelData: action.payload.panelData,
            loading: false,
          };
        state.loading = false;
      })
      .addCase(getMemberListWithPanelData.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while fetching Member List."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(markTempSuspendUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(markTempSuspendUser.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message="Marked Temporary Suspend."
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(markTempSuspendUser.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while marking Temporary Suspend "
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(createOrUpdateSlotAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrUpdateSlotAvailability.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={
              action?.payload?.data?.message ||
              "Availability is successfully updated"
            }
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(createOrUpdateSlotAvailability.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error in updating availability!"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(sendEmailReminder.pending, (state) => {
        state.reminderloading = true;
      })
      .addCase(sendEmailReminder.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={
              action?.payload?.response || "Email Notification sent sucessfully"
            }
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.reminderloading = false;
      })
      .addCase(sendEmailReminder.rejected, (state) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error in sending Email!"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.reminderloading = false;
      })
      .addCase(fetchInterviewerDesignationsIds.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInterviewerDesignationsIds.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.interviewerDesignationList =
            action.payload.designationIds || [];
        }
      })
      .addCase(fetchInterviewerDesignationsIds.rejected, (state) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching interviewer designation ids."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      });
  },
});
export const {
  setStartDate,
  setEndDate,
  setSlotTime,
  updateSlotTime,
  deleteSlotTime,
  setNotAvailableReason,
  clearAvailableReason,
  resetAvailabilityState,
  resetAvailabilityDays, 
  applyFilterStatus,
  resetAvailabilityFilterStatus,
  changeSlots,
  changeRounds,
  changeEligibleDesignationsIds,
  changeInterviewerDesignationsIds,
  changeTags,
  changeSubCompetency
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
