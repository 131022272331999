import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, remove } from "../../../utils/app-utils";
import { BASE_URL } from "../../../constants/index";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";

const initialState = {
  loading: false,
  tagsData: {},
};

export const getTags = createAsyncThunk(
  "getTags",
  async ({ roleId, competencyId, pageNumber }) => {
    try {
      const url = `${BASE_URL}/panelGroup`;
      const response = await get(url, {
        queryParams: { role_id: roleId, competencyId, pageNumber },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const addTag = createAsyncThunk(
  "addTag",
  async ({ groupName, roleId, competencyId, tagDescription }) => {
    try {
      const url = `${BASE_URL}/panelGroup`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { groupName, competencyId, description: tagDescription }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deleteTag = createAsyncThunk(
  "deleteTag",
  async ({ roleId, groupId }) => {
    try {
      const url = `${BASE_URL}/panelGroup/${groupId}`;
      const response = await remove(url, { queryParams: { role_id: roleId } });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const tagsSlice = createSlice({
  name: "Tags",
  initialState,
  reducers: {
    resetTagsState() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.loading = false;
        state.tagsData = action.payload.data;
      })
      .addCase(getTags.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching tags."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(addTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTag.fulfilled, (state, action) => {
        if (action.payload.code === 409) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={action?.payload?.message}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        } else {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={action?.payload?.data?.message}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
        }
        state.loading = false;
      })
      .addCase(addTag.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while creating tag."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message || "Tag Deleted Successfully."}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteTag.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error deleting panel group."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      });
  },
});
export const { resetTagsState } = tagsSlice.actions;
export default tagsSlice.reducer;
