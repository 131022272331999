"use client";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Main from "./sections/Main";
import Login from "./components/auth/Login";
import LoginCallback from "./components/auth/LoginCallback";
import Logout from "./components/auth/Logout";
import NotFoundPage from "./sections/Main/NotFound/index"

import {
  getCompetencyList,
  getDesignationList,
  fetchHolidaysList,
} from "./redux/features/Authentication/authSlice";

import AuthenticatedRoute from "./utils/authenticated-route";
import { setNavigateCallback } from "../app/utils/app-utils";
import { ROLE_TYPES } from "./constants";

import "./app.scss";

const { isAdmin } = ROLE_TYPES;

function App() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  setNavigateCallback(navigate);

  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);

  useEffect(() => {
    if (loginInfo.role_id) {
      dispatch(getCompetencyList({ roleId: loginInfo?.role_id }));
      dispatch(
        getDesignationList({
          roleId: loginInfo?.role_id,
          competencyId: isAdmin(loginInfo.current_role)
            ? null
            : loginInfo?.current_role_competency_id,
        })
      );
      dispatch(fetchHolidaysList({ roleId: loginInfo.role_id }));
    }
  }, [loginInfo, dispatch]);
  
  return (
    <Routes>
      <Route path="/login" exact element={<Login />} />
      <Route path="/login/callback" element={<LoginCallback />} />
      <Route path="/logout" exact element={<Logout />} />
      <Route path="/*" exact element={<NotFoundPage />} />
      <Route
        path="/main/*"
        element={
          <AuthenticatedRoute>
            <Main />
          </AuthenticatedRoute>
        }
      />
      <Route path="/" index element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default App;
