import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put, remove } from "../../../utils/app-utils";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../constants/index";
import CustomToaster from "../../../components/Toaster/index";
import {
  TOAST_TYPE,
  TOAST_TIME,
  TOAST_TITLE,
  DASHBOARD_INTERVIEWS,
  KEY_MAPPING_INTERVIEW_STATUSES
} from "../../../constants/index";

const currentDate = new Date();

// Get the current date in YYYY-MM-DD format
const currentYear = currentDate.getFullYear();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
const currentDay = String(currentDate.getDate()).padStart(2, "0");
const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;

// Subtract 15 days from the current date
const previousDate = new Date(currentDate);
previousDate.setDate(previousDate.getDate() - 15);

// Get the date 15 days prior in YYYY-MM-DD format
const previousYear = previousDate.getFullYear();
const previousMonth = String(previousDate.getMonth() + 1).padStart(2, "0");
const previousDay = String(previousDate.getDate()).padStart(2, "0");
const formattedPreviousDate = `${previousYear}-${previousMonth}-${previousDay}`;

const initialState = {
  loading: false,
  tableLoading: false,
  slotCount: {},
  bookingStatus: [],
  tableData: [],
  panelCount: {},
  globalCompetency: [],
  recruiterList: [],
  tablePageCount: 0,
  tableDataCount: 0,
  weekendDriveData: {},
};

export const getSlotCountByCompetencyId = createAsyncThunk(
  "getSlotCountByCompetencyId",
  async ({ roleId, competencyId, periodFrom, periodTo }) => {
    try {
      const response = await post(
        `${BASE_URL}/analytics/slots`,
        {
          queryParams: { role_id: roleId, periodFrom, periodTo },
        },
        {
          competencyId: competencyId,
        },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getBookingStatusByCompetencyId = createAsyncThunk(
  "getBookingStatusByCompetencyId",
  async ({ roleId, competencyId, recruiterId, periodFrom, periodTo }) => {
    try {
      const response = await post(
        `${BASE_URL}/analytics/status`,
        {
          queryParams: {
            role_id: roleId,
            periodFrom,
            periodTo,
          },
        },
        {
          recruiterId: recruiterId,
          competencyId: competencyId,
        },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getPanelDetailByCompetencyId = createAsyncThunk(
  "getPanelDetailByCompetencyId",
  async ({ roleId, competencyId, periodFrom, periodTo }) => {
    try {
      const response = await post(
        `${BASE_URL}/analytics/panel-details`,
        {
          queryParams: { role_id: roleId, periodFrom, periodTo },
        },
        {
          competencyId: competencyId,
        },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getCandidatesDetailByCompetencyId = createAsyncThunk(
  "getCandidatesDetailByCompetencyId",
  async ({
    roleId,
    competencyId,
    status,
    recruiterId,
    pageNumber,
    periodFrom,
    periodTo,
    sortType,
    sortOrder,
  }) => {
    try {
      const response = await post(
        `${BASE_URL}/analytics/candidate-details`,
        {
          queryParams: {
            role_id: roleId,
            periodTo: periodTo,
            periodFrom: periodFrom,
            pageNumber: pageNumber,
            sortType,
            sortOrder,
          },
        },
        {
          recruiterId: recruiterId,
          status: status,
          competencyId: competencyId,
        },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getRecuiterListByCompetencyId = createAsyncThunk(
  "getRecuiterListByCompetencyId",
  async ({ roleId, competencyId }) => {
    try {
      const url = `${BASE_URL}/weekend-drive/coordinator/list`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          competencyId: JSON.stringify(competencyId),
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getWeekendDriveData = createAsyncThunk(
  "getWeekendDriveData",
  async ({ roleId, competencyId, periodFrom, periodTo }) => {
    try {
      const url = `${BASE_URL}/analytics/weekend-drive-details`;
      const response = await get(url, {
        queryParams: {
          role_id: roleId,
          competencyId: JSON.stringify(competencyId),
          periodTo: periodTo,
          periodFrom: periodFrom,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const hrDashboardSlice = createSlice({
  name: "hrDashboard",
  initialState,
  reducers: {
    resetDashboardState() {
      return {
        ...initialState,
      };
    },
    setGlobalCompetency: (state, action) => {
      state.globalCompetency = action.payload.competency;
    },
    resetDashboardTableData: (state, action) => {
      state.tableDataCount = 0;
      state.tablePageCount = 0;
      state.tableData = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlotCountByCompetencyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSlotCountByCompetencyId.fulfilled, (state, action) => {
        state.loading = false;
        state.slotCount = action.payload;
      })
      .addCase(getSlotCountByCompetencyId.rejected, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to get slots count data."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(getBookingStatusByCompetencyId.pending, (state, action) => {
        state.tableLoading = true;
      })
      .addCase(getBookingStatusByCompetencyId.fulfilled, (state, action) => {
        state.tableLoading = false;
        if (action.payload.statusList) {
          const convertedData = Object.entries(KEY_MAPPING_INTERVIEW_STATUSES).map(([key, status]) => ({
            "status_count": action.payload.statusList[0][key] || "0", // Default to "0" if the key doesn't exist
            "status": status
        }));
          const statusArray = DASHBOARD_INTERVIEWS.INTERVIEWS_STATUS_TO_INCLUDE;
          const toIncludePayload = convertedData?.filter((item) =>
            statusArray.includes(item.status)
          );
          state.bookingStatus = [...toIncludePayload,];
        }
      })
      .addCase(getBookingStatusByCompetencyId.rejected, (state, action) => {
        state.tableLoading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to get booking status data."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(getPanelDetailByCompetencyId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPanelDetailByCompetencyId.fulfilled, (state, action) => {
        state.loading = false;
        state.panelCount = action.payload;
      })
      .addCase(getPanelDetailByCompetencyId.rejected, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to get panelist count data"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(getCandidatesDetailByCompetencyId.pending, (state, action) => {
        state.tableLoading = true;
      })
      .addCase(getCandidatesDetailByCompetencyId.fulfilled, (state, action) => {
        state.tableLoading = false;
        if (Array.isArray(action.payload.candidateDetails)) {
          state.tableData = action.payload.candidateDetails;
          state.tableDataCount = action.payload.totalCount;
          state.tablePageCount = action.payload.pageCount;
        }
      })
      .addCase(getCandidatesDetailByCompetencyId.rejected, (state, action) => {
        state.tableLoading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to get booking data"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(getRecuiterListByCompetencyId.pending, (state, action) => {
        state.tableLoading = true;
      })
      .addCase(getRecuiterListByCompetencyId.fulfilled, (state, action) => {
        state.tableLoading = false;
        state.recruiterList = action.payload.coordinatorList;
      })
      .addCase(getRecuiterListByCompetencyId.rejected, (state, action) => {
        state.tableLoading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to fetch recruiter list."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(getWeekendDriveData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getWeekendDriveData.fulfilled, (state, action) => {
        state.loading = false;
        state.weekendDriveData = action.payload.weekendDriveDetailsData;
      })
      .addCase(getWeekendDriveData.rejected, (state) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to fetch weekend drive data"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      });
  },
});

export const { setGlobalCompetency,resetDashboardTableData, resetDashboardState } =
  hrDashboardSlice.actions;

export default hrDashboardSlice.reducer;
