import React from "react";

import { PieChart } from "@mui/x-charts/PieChart";

const Chart = ({ roundData }) => {
  const TOTAL = roundData.totalPanel;
  const pieData = [
    { value: ((parseInt(roundData?.activePanel) / TOTAL) * 100).toFixed(0) },
    { value: ((parseInt(roundData?.newPanel) / TOTAL) * 100).toFixed(0) },
    { value: ((parseInt(roundData?.suspendedPanel) / TOTAL) * 100).toFixed(0) },
  ];

  return (
    <PieChart
      height={300}
      sx={{
        marginLeft: "20%",
      }}
      series={[
        {
          data: pieData,
          innerRadius: 40,
          outerRadius: 110,
          paddingAngle: 5,
          cornerRadius: 5,
          startAngle: -180,
          endAngle: 180,
          cx: 150,
          cy: 150,
        },
      ]}
      colors={["#75E2C1", "#9B9EE4", "#2A88CC"]}
    />
  );
};

export default Chart;
