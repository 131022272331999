import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";

import { store } from "./redux/store";
import theme from "./theme/index";
import App from'./App';

import { removeAuthInfo } from "./utils/app-utils";
import './styles/globals.scss'

function RecruitmentAppTool() {
  const persistor = persistStore(store);

  if (window.location.pathname === "/logout") {
    removeAuthInfo();
    persistor.purge().then(() => {
      return persistor.flush();
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default RecruitmentAppTool;
