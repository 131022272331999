import React, { useState, useEffect } from "react";

import Spinner from "../../../components/Spinner";
import Icon from "../../../components/SvgIcon/icon";

import {
  Typography,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Stack,
  Grid
} from "@mui/material";

import SortingIcon from "../../../assets/icons/sort-icon.svg";
import AscendingIcon from "../../../assets/icons/ascending-icon.svg";
import DescendingIcon from "../../../assets/icons/descending-icon.svg";

import { SORT_TYPE_CHECK_VALUE, SORT_ORDER } from "../../../constants/index";

import styles from "./Basic.module.scss";
import theme from "../../../theme";

const TabularView = ({
  designationWise,
  roundWise,
  loading,
  setSortBy,
  sortBy,
}) => {
  const [roundData, setRoundData] = useState([]);
  const [total, setTotal] = useState({
    totalCount: 0,
    inPanel: 0,
    notInPanel: 0,
  });

  useEffect(() => {
    if (designationWise && designationWise.length) {
      const updatedCount = { totalCount: 0, inPanel: 0, notInPanel: 0 };
      designationWise?.map((data) => {
        updatedCount.totalCount = updatedCount.totalCount + data.totalCount;
        updatedCount.inPanel = updatedCount.inPanel + data.currentlyInPanel;
        updatedCount.notInPanel =
          updatedCount.notInPanel + (data.totalCount - data.currentlyInPanel);
        return data;
      });
      setTotal(updatedCount);
    }
  }, [designationWise, setTotal]);
  useEffect(() => {
    if (roundWise && roundWise.length) {
      const updatedRoundData = [];

      roundWise.forEach((round) => {
        const findIndex = updatedRoundData.findIndex((data) => {
          return (
            data.roundName === round.roundName &&
            data.designationId === round.designationId
          );
        });

        if (findIndex > -1) {
          if (round.isPairing) {
            updatedRoundData[findIndex].pairingCount = round.currentlyInRound;
          } else {
            updatedRoundData[findIndex].currentlyInRound =
              round.currentlyInRound;
          }
        } else {
          if (round.isPairing) {
            updatedRoundData.push({
              ...round,
              currentlyInRound: 0,
              pairingCount: round.currentlyInRound,
            });
          } else {
            updatedRoundData.push({ ...round });
          }
        }
      });
      setRoundData(updatedRoundData);
    }
  }, [roundWise]);

  const handleSortType = (columnLabel) => {
    setSortBy((prevState) => {
      const sortOrder =
        prevState.sortType === columnLabel
          ? prevState.sortOrder === SORT_ORDER.ASCENDING || !prevState.sortOrder
            ? SORT_ORDER.DESCENDING
            : SORT_ORDER.ASCENDING
          : SORT_ORDER.ASCENDING;
      return {
        sortType: columnLabel,
        sortOrder: sortOrder,
      };
    });
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Paper className={styles.tableStyle}>
        <TableContainer className={styles.tableHeight}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${styles.tableHeadingStyle} ${styles.cursorPointer}`}
                  onClick={() =>
                    handleSortType(SORT_TYPE_CHECK_VALUE.DESIGNATION_NAME)
                  }
                >
                  <Stack direction={"row"}>
                    Designation
                    {sortBy.sortType !==
                    SORT_TYPE_CHECK_VALUE.DESIGNATION_NAME ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    )}
                  </Stack>
                </TableCell>
                <TableCell
                  className={`${styles.tableHeadingStyle} ${styles.cursorPointer}`}
                  onClick={() =>
                    handleSortType(SORT_TYPE_CHECK_VALUE.TOTAL_COUNT)
                  }
                >
                  <Stack direction={"row"}>
                    Total Count
                    {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.TOTAL_COUNT ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    )}
                  </Stack>
                </TableCell>
                <TableCell
                  className={`${styles.tableHeadingStyle} ${styles.cursorPointer}`}
                  onClick={() =>
                    handleSortType(SORT_TYPE_CHECK_VALUE.PANEL_COUNT)
                  }
                >
                  <Stack direction={"row"}>
                    Currently in Panel
                    {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.PANEL_COUNT ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                        topPadding="6px"
                      />
                    )}
                  </Stack>
                </TableCell>
                <TableCell className={styles.tableHeadingStyle}>
                  Not in Panel yet
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {designationWise?.length
                ? designationWise?.map((data, index) => (
                    <TableRow sx={{ border: 0 }} key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={styles.tableBodyStyle}
                      >
                        {data.designationName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={styles.tableBodyStyle}
                      >
                        {data.totalCount}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={styles.tableBodyStyle}
                      >
                        {data.currentlyInPanel}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={styles.tableBodyStyle}
                      >
                        {" "}
                        {data.totalCount - data.currentlyInPanel}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
              <TableRow
                sx={{
                  border: 0,
                  fontWeight: 700,
                  position: "sticky",
                  bottom: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={`${styles.tableBodyStyle} ${styles.sumTableRowStyle} `}
                >
                  Total
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={`${styles.tableBodyStyle} ${styles.sumTableRowStyle} `}
                >
                  {total.totalCount}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={`${styles.tableBodyStyle} ${styles.sumTableRowStyle} `}
                >
                  {total.inPanel}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={`${styles.tableBodyStyle} ${styles.sumTableRowStyle} `}
                >
                  {total.notInPanel}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Typography
        variant="h5"
        display="inline-flex"
        color={theme.palette.secondary.main}
        margin={2}
      >
        Panel Pool Availability
      </Typography>
      {roundData?.length ? (
        <Paper className={styles.tableStyle}>
          <TableContainer className={styles.tableHeight}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ border: 0 }}>
                  <TableCell className={styles.tableHeadingStyle}>
                    Designation
                  </TableCell>
                  <TableCell className={styles.tableHeadingStyle}>
                    Overall Eligible Pool
                  </TableCell>
                  <TableCell className={styles.tableHeadingStyle}>
                    Takes Individual Rounds
                  </TableCell>
                  <TableCell className={styles.tableHeadingStyle}>
                    Takes Paired Interviews Only
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roundData?.map((data, index) => (
                  <TableRow sx={{ border: 0 }} key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      {`${data.designationName} - ${data.roundName}`}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      {parseInt(data.currentlyInRound) +
                        parseInt(data.pairingCount ? data.pairingCount : 0)}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      {data.currentlyInRound}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      {data.pairingCount ? data.pairingCount : 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Grid container justifyContent={"center"} marginTop={3}>
          <Typography variant="h5" color="initial">
            No Data Available.
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default TabularView;
