import React from "react";

import Icon from "../../../../../components/SvgIcon/icon";
import PanelDetailsView from "../../../../../components/PanelDetailsView/PanelDetailsView";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import SortingIcon from "../../../../../assets/icons/sort-icon.svg";
import AscendingIcon from "../../../../../assets/icons/ascending-icon.svg";
import DescendingIcon from "../../../../../assets/icons/descending-icon.svg";

import { extractTime } from "../../../../../utils/date-utils";
import {
  extractDate,
  deriveAbbreviatedName,
} from "../../../../../utils/app-utils";
import {
  SORT_TYPE_CHECK_VALUE,
  INTERVIEW_STATUS_OPTIONS,
} from "../../../../../constants";

import styles from "../style.module.scss";

const DashboardTable = ({ rows, handleSortType, sortBy, interviewStatus }) => {
  return (
    <TableContainer
      component={Paper}
      className={`${styles.shadow} ${styles.table}`}
    >
      <Table>
        <TableHead
          style={{ position: "sticky", top: 0, background: "white", zIndex: 1 }}
        >
          <TableRow>
            <TableCell
              className={`tableheading ${styles.noBorder}`}
              onClick={() => handleSortType(SORT_TYPE_CHECK_VALUE.NAME)}
            >
              Interviewer
              {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.NAME ? (
                <Icon
                  icon={SortingIcon}
                  width="6.875px"
                  height="11px"
                  leftPadding="5px"
                />
              ) : sortBy.sortOrder === "DESC" ? (
                <Icon
                  icon={DescendingIcon}
                  width="6.875px"
                  height="11px"
                  leftPadding="5px"
                />
              ) : (
                <Icon
                  icon={AscendingIcon}
                  width="6.875px"
                  height="11px"
                  leftPadding="5px"
                />
              )}
            </TableCell>
            <TableCell className={`tableheading ${styles.noBorder}`}>
              Competency{" "}
            </TableCell>
            <TableCell className={`tableheading ${styles.noBorder}`}>
              Level{" "}
            </TableCell>
            {interviewStatus?.length > 1 && (
              <TableCell className={`tableheading ${styles.noBorder}`}>
                Interview Status{" "}
              </TableCell>
            )}
            <TableCell className={`tableheading ${styles.noBorder}`}>
              Pairing Panel{" "}
            </TableCell>
            <TableCell className={`tableheading ${styles.noBorder}`}>
              Recruiter Name{" "}
            </TableCell>
            <TableCell className={`tableheading ${styles.noBorder}`}>
              Candidate Name{" "}
            </TableCell>
            <TableCell className={`tableheading ${styles.noBorder}`}>
              Candidate Email{" "}
            </TableCell>
            <TableCell
              className={`tableheading ${styles.noBorder}`}
              onClick={() => handleSortType(SORT_TYPE_CHECK_VALUE.DATE_TIME)}
            >
              Date & Time
              {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.DATE_TIME ? (
                <Icon
                  icon={SortingIcon}
                  width="6.875px"
                  height="11px"
                  leftPadding="5px"
                />
              ) : sortBy.sortOrder === "DESC" ? (
                <Icon
                  icon={DescendingIcon}
                  width="6.875px"
                  height="11px"
                  leftPadding="5px"
                />
              ) : (
                <Icon
                  icon={AscendingIcon}
                  width="6.875px"
                  height="11px"
                  leftPadding="5px"
                />
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={`tablebody cursorPointer`}>
                <PanelDetailsView
                  profilePicUrl={row.profile_pic_url}
                  interviewerEmail={row.interviewer_email}
                  firstName={row.name}
                  employeeCode={row.employee_code}
                  isdashboard={true}
                />
              </TableCell>
              <TableCell className={"tablebody"}>
                {row.hiring_competency}{" "}
              </TableCell>

              <TableCell className={"tablebody"}>
                {deriveAbbreviatedName(row.hiring_level)}
              </TableCell>

              {interviewStatus?.length > 1 && (
                <TableCell className={"tablebody"}>
                  {INTERVIEW_STATUS_OPTIONS[row?.status]}
                </TableCell>
              )}
              <TableCell className={"tablebody"}>
                {row?.panel_pairing?.length ? row?.panel_pairing : "-"}
              </TableCell>
              <TableCell className={"tablebody"}>
                {row?.recruiter_name?.length ? row?.recruiter_name : "-"}
              </TableCell>
              <TableCell className={"tablebody"}>
                {row?.candidate_name?.length ? row?.candidate_name : "-"}
              </TableCell>
              <TableCell className={"tablebody"}>
                {row?.candidate_email?.length ? row?.candidate_email : "-"}
              </TableCell>
              <TableCell className={"tablebody"}>
                {`${extractDate(row.booking_date)} ${extractTime(
                  row.start_time
                )}-${extractTime(row.end_time)}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;
