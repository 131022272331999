import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../components/Spinner";

import { addCommentCompetencyMember } from "../../../redux/features/Roster/rosterSlice";

import {
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import { extractDateAndTime } from "../../../utils/app-utils";
import {
  MODULE_NAME,
  ROLE_TYPES,
  ROSTER_FILTER_TABS,
  ROSTER_FILTER_TAB_CHECK_VALUE,
} from "../../../constants";

import styles from "./style.module.scss";
import theme from "../../../theme/index";

const { isHr, isSpoc, isAdmin } = ROLE_TYPES;

const AddCommentModal = ({
  handleAddCommentModal,
  roundName,
  slotsProvided,
  startWeekDate,
  endWeekDate,
  selectedPanel,
  setShowAddCommentModal,
  showAddCommentModal,
  commentsForUniqueUser,
  commentsLoading,
  user,
  designationIds,
  joiningDate,
  subcompetency,
  sortBy,
  activePageNumber,
  signal,
  keyvalue,
  panelGroupId,
  activedesignationId,
  searchValue,
  competencyId,
  dateOfJoining,
  gender,
}) => {
  let dispatch = useDispatch();

  const {
    competencyIdState,
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    genderState,
    subCompetencyState,
    dateOfJoiningState,
    roundsNameState,
    tagsState,
  } = useSelector((state) => state.rosterReducer?.roster);
  const {
    providedSlots,
    roundsFilter,
    eligibleDesignationIds,
    interviewerDesignationIds,
    tagsFilter,
    subCompetencyFilter,
  } = useSelector((state) => state?.availabilityReducer?.availability);

  const filterStatus = useSelector(
    (state) => state.rosterReducer?.isFilterApplied
  );

  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);

  const handleClose = (e) => {
    setShowAddCommentModal(false);
  };
  const handleApply = async (e) => {
    e.preventDefault();
    let availabilityPanelPayload,
      userByCompetencyPayload,
      activePanelCompetencyPayload;
    if (comment.trim()) {
      if (selectedPanel.key === MODULE_NAME.AVAILABILITY) {
        availabilityPanelPayload = {
          competencyId: user?.current_role_competency_id,
          roundDesignationIds: eligibleDesignationIds,
          slotsProvided: providedSlots,
          roundName: roundsFilter,
          periodFrom: new Date(startWeekDate)?.toISOString(),
          periodTo: new Date(endWeekDate)?.toISOString(),
          roleId: user?.role_id,
          pageNumber: activePageNumber,
          query: searchValue,
          activedesignationId: interviewerDesignationIds,
          panelGroupId: tagsFilter,
          subCompetency: subCompetencyFilter,
        };
      }
      if (selectedPanel.key === ROSTER_FILTER_TABS.ALL) {
        userByCompetencyPayload = {
          roleId: user.role_id,
          panelCompetencyId: isAdmin(user?.current_role)
            ? null
            : user?.current_role_competency_id,
          competencyId: (filterStatus && (competencyIdState.toString()?.length) )
            ? competencyIdState
            : isAdmin(user.current_role)
            ? ""
            : user.current_role_competency_id,
          designationIds: activeDesignationIdsState,
          pageNumber: activePageNumber,
          sortType: sortBy?.sortType,
          sortOrder: sortBy?.sortOrder,
          query: searchValue,
          dateOfJoining: dateOfJoiningState,
          gender: genderState,
          subCompetency: subCompetencyState,
        };
      }
      if (selectedPanel.key === ROSTER_FILTER_TABS.ACTIVE) {
        activePanelCompetencyPayload = {
          competencyId: user?.current_role_competency_id,
          designationIds:activeDesignationIdsState,
          roleId: user?.role_id,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          signal: signal,
          joiningDate:dateOfJoiningState,
          subCompetency: subCompetencyState,
          panelGroupId:
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
              ? tagsState
              : null,
          roundName: roundsNameState,
          activedesignationId:interviewerDesignationIdsState,
          query: searchValue,
        };
      }
      dispatch(
        addCommentCompetencyMember({
          userId: selectedPanel.id,
          comment,
          key: selectedPanel.key,
          roleId: user.role_id,
          userByCompetencyPayload,
          activePanelCompetencyPayload,
          availabilityPanelPayload,
          dispatch,
        })
      );
      setComment("");
      setError(false);
    }
  };

  const addComments = () => {
    if (isSpoc(user?.current_role) || isHr(user?.current_role)) {
      return (
        <Grid item xs={12} xl={12} md={12} lg={12}>
          <TextField
            id="Add-Comment"
            multiline={true}
            rows={4}
            placeholder="Add Comment"
            label="Add Comment"
            autoComplete="off"
            variant="outlined"
            value={comment}
            onChange={(e) => {
              if (e.target.value === "" || /^\s*$/.test(e.target.value)) {
                setError(true);
              } else {
                setError(false);
              }
              setComment(e.target.value);
            }}
            fullWidth
            className={styles.addComment}
            marginBottom={4}
            required
          />
        </Grid>
      );
    }
  };
  const noComments = () => {
    if (isHr(user?.current_role) || isSpoc(user?.current_role)) {
      return (
        <Grid item xs={12} xl={12} md={12} lg={12}>
          <Typography className={styles.no_comment}>
            No comments available.
          </Typography>
        </Grid>
      );
    }
  };
  const submitButton = () => {
    if (isSpoc(user?.current_role) || isHr(user?.current_role)) {
      return (
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          fullWidth
        >
          <Grid item xl={12} lg={12} xs={12} md={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              disabled={/^\s*$/.test(comment)}
              onClick={(e) => handleApply(e)}
              className={
                /^\s*$/.test(comment)
                  ? `${styles.saveBtnDisable}`
                  : `${styles.saveBtn}`
              }
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  if (commentsLoading) return <Spinner />;

  return (
    <>
      <>
        <Dialog
          open={showAddCommentModal}
          onClose={handleClose}
          scroll="body"
          PaperProps={{
            style: {
              width: "30rem",
              borderRadius: "10px",
              boxShadow: "0px 6px 30px 0px #OD",
            },
          }}
        >
          <DialogTitle
            color={theme.palette.secondary.main}
            sx={{ padding: "30px 24px 0px" }}
          >
            Notes:{" "}
            {`${selectedPanel.first_name} ${selectedPanel.last_name} (${selectedPanel.employee_code})`}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 18,
              top: 30,
              color: (theme) => theme.palette.grey[500],
            }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <img src={CloseIcon} alt="close-icon" />
          </IconButton>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container direction={"column"}>
              <Grid
                container
                className={styles.modalBody}
                spacing={1.5}
                paddingRight={1}
              >
                {!commentsForUniqueUser.user_comments && noComments()}
                {commentsForUniqueUser?.user_comments &&
                  commentsForUniqueUser?.user_comments?.map((userComment) => (
                    <Grid item xs={12} xl={12} md={12} lg={12}>
                      <Card
                        variant="outlined"
                        alignItems="center"
                        className={styles.cardWrap}
                      >
                        <CardContent
                          sx={{
                            p: 0,
                            "&:last-child": {
                              padding: 0,
                            },
                          }}
                        >
                          <Grid container gap={2}>
                            <Grid item xs={2}>
                              <img
                                className={styles.imgCircle}
                                src={userComment.commenterProfilePic}
                                alt={userComment.commenterName}
                              />
                            </Grid>
                            <Grid item xs={9} marginTop={1}>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className={styles.fontSize}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    xl={6}
                                    md={6}
                                    lg={6}
                                    className={`${styles.userComment} ${styles.fontSize}`}
                                  >
                                    {userComment?.commenterName}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    xl={6}
                                    md={6}
                                    lg={6}
                                    textAlign="right"
                                    className={`${styles.commentCreated} ${styles.fontSize}`}
                                  >
                                    {extractDateAndTime(userComment?.createdAt)}
                                  </Grid>
                                </Grid>
                              </Typography>
                              <Typography
                                variant="body2"
                                className={`${styles.comment} ${styles.fontSize}`}
                              >
                                {userComment?.comment}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: "0px 24px 30px" }}>
            <Grid container>
              {addComments()}
              {error && (
                <Grid item xs={12} xl={12} md={12} lg={12}>
                  <Typography color={"red"}>Please add comments*</Typography>
                </Grid>
              )}
              {submitButton()}
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default AddCommentModal;
