import React from "react";

import { Grid, Divider } from "@mui/material";

import { ROSTER_FILTER_TAB_CHECK_VALUE } from "../../../../constants";

const ShowFilterForAdmin = ({
  keyvalue,
  competencyFilter,
  competencyRoleAdminFilter,
  eligibleDesignationFilter,
  genderFilter,
  roleTypeFilter,
}) => {
  return (
    <>
      {keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL && (
        <>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {competencyFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {eligibleDesignationFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {genderFilter()}
          </Grid>
        </>
      )}
      {keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE && (
        <>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {competencyFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            <Divider variant="fullWidth" />
          </Grid>{" "}
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {roleTypeFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {competencyRoleAdminFilter()}
          </Grid>
        </>
      )}
    </>
  );
};

export default ShowFilterForAdmin;
