import React from "react";

import { Grid, Card, Typography, Box } from "@mui/material";

import styles from "../style.module.scss";

function PanelCard({ value, type, title }) {
  return (
    <Box marginTop={2}>
      <Card variant="outlined" className={`${styles.border} ${styles.shadow}`}>
        <Grid container spacing={2} padding={1}>
          <Grid item>
            <div
              className={styles.panelCardIconBackground}
              style={{ backgroundColor: type }}
            >
              <Typography variant="h6" color="white">
                {value}
              </Typography>
            </div>
          </Grid>
          <Grid item display={"flex"} alignItems={"center"}>
            <Typography color="#2E1C41">{title}</Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default PanelCard;
