import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AutoCompleteComponent from "../../../components/AutoComplete/index";

import { saveAddToPanel } from "../../../redux/features/Roster/rosterSlice";

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import closeIcon from "../../../assets/icons/close-icon.svg";

import { ROSTER_FILTER_TAB_CHECK_VALUE } from "../../../constants";

import styles from "./style.module.scss";
import theme from "../../../theme/index";

const AddRounds = ({
  showAddRounds,
  setShowAddRounds,
  setCompetencyId,
  loginInfo,
  competencyId,
  selectedPanel,
  showAddToPanelModal,
  setShowAddToPanelModal,
  activePageNumber,
  setActivePageNumber,
  activeAccordion,
  handleTabsChange,
  getRoundDetail,
  designationData,
  activedesignationId,
  subCompetency,
  designationIds,
  panelGroupId,
  searchValue,
  sortBy,
  signal,
  dateOfJoining,
  keyvalue
}) => {
  const dispatch = useDispatch();
  const activeDesignations = useSelector(
    (state) => state.rosterReducer?.activeDesignation
  );
  const {
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    subCompetencyState,
    dateOfJoiningState,
    roundsNameState,
    tagsState,
  } = useSelector((state) => state.rosterReducer?.roster);

  const [error, setError] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedR1, setSelectedR1] = useState([]);
  const [preselectedR1, setPreSelectedR1] = useState([]);
  const [selectedR2, setSelectedR2] = useState([]);
  const [preselectedR2, setPreSelectedR2] = useState([]);
  const [selectedMR, setSelectedMR] = useState([]);
  const [preselectedMR, setPreSelectedMR] = useState([]);
  const [toDisableR1, setToDisableR1] = useState([]);
  const [toDisableR2, setToDisableR2] = useState([]);
  const [toDisableMR, setToDisableMR] = useState([]);

  useEffect(() => {
    const desData = Object.entries(designationData);
    const roundDetailsR1 = getRoundDetail(selectedPanel.rounds, "R1");
    const roundDetailsR2 = getRoundDetail(selectedPanel.rounds, "R2");
    const roundDetailsMR = getRoundDetail(selectedPanel.rounds, "MR");
    if (roundDetailsR1.length && desData.length) {
      const selectedR1 = roundDetailsR1.map((item) => {
        return {
          label: item.isPairing
            ? `${item.designationName}(P)`
            : `${item.designationName}`,
          value: item.isPairing
            ? `${item.designationId}-P`
            : `${item.designationId}`,
          id: item.id,
        };
      });
      setToDisableR1(selectedR1)
      setSelectedR1(selectedR1);
      setPreSelectedR1(selectedR1);
    }
    if (roundDetailsR2.length && desData.length) {
      const selectedR2 = roundDetailsR2.map((item) => {
        return {
          label: item.isPairing
            ? `${item.designationName}(P)`
            : `${item.designationName}`,
          value: item.isPairing
            ? `${item.designationId}-P`
            : `${item.designationId}`,
          id: item.id,
        };
      });
      setToDisableR2(selectedR2)
      setSelectedR2(selectedR2);
      setPreSelectedR2(selectedR2);
    }
    if (roundDetailsMR.length && desData.length) {
      const selectedMR = roundDetailsMR.map((item) => {
        return {
          label: item.isPairing
            ? `${item.designationName}(P)`
            : `${item.designationName}`,
          value: item.isPairing
            ? `${item.designationId}-P`
            : `${item.designationId}`,
          id: item.id,
        };
      });
      setToDisableMR(selectedMR);
      setSelectedMR(selectedMR);
      setPreSelectedMR(selectedMR);
    }
  }, [designationData, selectedPanel.rounds]);
  useEffect(() => {
    const desData = Object.entries(activeDesignations);
    if (desData.length) {
      const newOptions = desData.reduce((acc, [id, { name }]) => {
        let counter = 0;
        while (counter < 2) {
          if (counter === 1) {
            acc.push({
              id: `${id}-P`,
              value: `${id}-P`,
              label: `${name}(Pairing)`,
            });
          } else {
            acc.push({
              id,
              value: `${id}`,
              label: name,
            });
          }
          counter++;
        }
        return acc;
      }, []);
      setOptions(newOptions);
    }
  }, [activeDesignations]);

  const extractDesignationIdAndPairingMode = (item) => {
    const { value } = item;
    const designationId = parseInt(value.split("-")[0]);
    const isPairing = value.split("-")[1] === "P" ? true : false;
    return { designationId, isPairing };
  };
  const handleClose = () => setShowAddRounds(false);
  const handleRound1Change = (event) => {
    setSelectedR1(event);
  };
  const handleRound2Change = (event) => {
    setSelectedR2(event);
  };
  const handleManagerialRoundChange = (event) => {
    setSelectedMR(event);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (
      competencyId &&
      selectedPanel.id &&
      (selectedR1.length > 0 || selectedR2.length > 0 || selectedMR.length > 0)
    ) {
      const roundR1 = selectedR1?.map((item) => {
        const { designationId, isPairing } =
          extractDesignationIdAndPairingMode(item);
        return {
          roundName: "R1",
          designationId,
          isPairing,
        };
      });

      const roundR2 = selectedR2?.map((item) => {
        const { designationId, isPairing } =
          extractDesignationIdAndPairingMode(item);
        return {
          roundName: "R2",
          designationId,
          isPairing,
        };
      });
      const roundMR = selectedMR?.map((item) => {
        const { designationId, isPairing } =
          extractDesignationIdAndPairingMode(item);
        return {
          roundName: "MR",
          designationId,
          isPairing,
        };
      });
      dispatch(
        saveAddToPanel({
          userId: selectedPanel.id,
          competencyId: selectedPanel.panel_competency_id,
          roundName: roundsNameState,
          panelGroupId:
          keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
          keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
            ? tagsState
            : null,
          activedesignationId:interviewerDesignationIdsState,
          dateOfJoining:dateOfJoiningState,
          subCompetency: subCompetencyState,
          designationIds:activeDesignationIdsState,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          panelCompetencyId: loginInfo.current_role_competency_id,
          roundData: [...roundR1, ...roundR2, ...roundMR],
          roleId: loginInfo.role_id,
          pageNumber: activePageNumber,
          signal: signal,
          dispatch,
        })
      );
      setError(false);
      handleClose();
    } else {
      setError(true);
    }
  };

  return (
    <>
      <>
        <Dialog
          open={showAddRounds}
          onClose={handleClose}
          PaperProps={{
            style: {
              height: "25rem",
              width: "35rem",
              minHeight: "30vh",
              maxHeight: "100vh",
              zIndex: 1300,
              borderRadius: "10px",
              boxShadow: "0px 6px 30px 0px #OD",
            },
          }}
        >
          <DialogTitle
            color={theme.palette.secondary.main}
            sx={{ padding: "30px 24px 0px" }}
            className={styles.headingModal}
          >
            Add/Edit Rounds
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 18,
              top: 30,
              color: (theme) => theme.palette.grey[500],
            }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <img src={closeIcon} alt="close-icon" />
          </IconButton>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <AutoCompleteComponent
                  options={options}
                  placeholder={"Round"}
                  label={"Select Round 1"}
                  onChange={(e) => handleRound1Change(e)}
                  selectedValue={selectedR1}
                  disabled={false}
                  chipDisabled={true}
                  toDisable={toDisableR1}
                />
              </Grid>

              <Grid item>
                <AutoCompleteComponent
                  options={options}
                  placeholder={"Round"}
                  label={"Select Round 2"}
                  onChange={(e) => handleRound2Change(e)}
                  selectedValue={selectedR2}
                  disabled={false}
                  chipDisabled={true}
                  toDisable={toDisableR2}
                />
              </Grid>

              <Grid item>
                <AutoCompleteComponent
                  options={options}
                  placeholder={"Round"}
                  label={"Select Managerial Round"}
                  onChange={(e) => handleManagerialRoundChange(e)}
                  selectedValue={selectedMR}
                  disabled={false}
                  chipDisabled={true}
                  toDisable={toDisableMR}
                />
              </Grid>
            </Grid>
            {error && (
              <p className="form-error">
                Please fill out all Mandatory(*) fields
              </p>
            )}
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px 20px" }}>
            <Button
              fullWidth
              disabled={
                !competencyId ||
                !selectedPanel.id ||
                !(selectedR1.length || selectedR2.length || selectedMR.length)
              }
              variant={"contained"}
              onClick={onFormSubmit}
              className={
                !(selectedR1.length || selectedR2.length || selectedMR.length)
                  ? `${styles.saveBtnDisable}`
                  : `${styles.saveBtn}`
              }
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};
export default AddRounds;
