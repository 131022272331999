import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteRole,
  deleteManagerRole,
} from "../../../redux/features/Admin/adminSlice";

import Icon from "../../../components/SvgIcon/icon.js";
import NoDataFound from "../NoData/index";
import Spinner from "../../../components/Spinner";
import Pagination from "../../../components/Pagination/index";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal.js";
import PanelDetailsView from "../../../components/PanelDetailsView/PanelDetailsView.jsx";

import {
  Grid,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import SortingIcon from "../../../assets/icons/sort-icon.svg";
import AscendingIcon from "../../../assets/icons/ascending-icon.svg";
import DescendingIcon from "../../../assets/icons/descending-icon.svg";

import {
  ADMIN_ROLE_TYPES,
  ROLES,
  ROLE_TYPES,
  SORT_TYPE_CHECK_VALUE,
} from "../../../constants/index";

import styles from "./style.module.scss";

const { isAdmin } = ROLE_TYPES;

const AdminTable = ({
  keyvalue,
  competencyId,
  designationIds,
  activePageNumber,
  setActivePageNumber,
  user,
  searchValue,
  competencyIdFilter,
  isFilterApplied,
  handleSortType,
  sortBy,
  setSortBy,
  adminCompetencyIdFilter,
  roleName,
  setRoleName,
  competencyIdState,
}) => {
  const dispatch = useDispatch();
  const userCount = useSelector((state) => state?.adminReducer?.totalCount);
  const adminActiveList = useSelector((state) => state?.adminReducer?.userList);
  const { pageCount, loading } = useSelector((state) => state?.adminReducer);

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [showDeleteManagerConfirmModal, setShowDeleteManagerConfirmModal] =
    useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const handleDeleteRole = (id) => {
    if (deleteItemId) {
      dispatch(
        deleteRole({
          userRoleId: deleteItemId,
          roleId: user.role_id,
          role: user.current_role,
          pageNumber: 1,
          competencyId: competencyIdState,
          roleCompetencyIds: isFilterApplied
            ? adminCompetencyIdFilter
            : isAdmin(user.current_role)
            ? ""
            : user.current_role_competency_id,
          designationIds,
          query: searchValue,
          roleType: roleName,
          dispatch,
        })
      );
      setActivePageNumber(1);
    }
  };
  const handleDeleteManagerRole = (id) => {
    if (deleteUserId) {
      dispatch(
        deleteManagerRole({
          userId: deleteUserId,
          roleId: user.role_id,
          role: user.current_role,
          pageNumber: 1,
          competencyId: competencyIdState,
          roleCompetencyIds: isFilterApplied
            ? adminCompetencyIdFilter
            : isAdmin(user.current_role)
            ? []
            : user.current_role_competency_id,
          designationIds,
          query: searchValue,
          roleType: roleName,
          dispatch,
        })
      );
      setActivePageNumber(1);
    }
  };
  const handleClose = () => {
    setShowDeleteConfirmModal(false);
    setDeleteItemId(null);
  };
  const closeModal = () => {
    setShowDeleteManagerConfirmModal(false);
    setDeleteUserId(null);
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={"globalLayoutHeight"}
      >
        {adminActiveList?.length ? (
          <Paper className={"globalTableLayoutStyle"}>
            <TableContainer
              className={`globalLayoutHeight globalTableContainerAlign`}
              marginRight={2}
            >
              <Table aria-label="all-table" stickyHeader>
                <TableHead className={styles.tableHead}>
                  <TableRow className={styles.tableRow}>
                    <TableCell
                      className={"tableheading"}
                      onClick={() => handleSortType(SORT_TYPE_CHECK_VALUE.NAME)}
                      width={"25%"}
                    >
                      Panel Member
                      {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.NAME ? (
                        <Icon
                          icon={SortingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      ) : sortBy.sortOrder === "DESC" ? (
                        <Icon
                          icon={DescendingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      ) : (
                        <Icon
                          icon={AscendingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      )}
                    </TableCell>
                    <TableCell
                      className={"tableheading"}
                      align="left"
                      onClick={() =>
                        handleSortType(SORT_TYPE_CHECK_VALUE.COMPETENCY)
                      }
                    >
                      Competency
                      {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.COMPETENCY ? (
                        <Icon
                          icon={SortingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      ) : sortBy.sortOrder === "DESC" ? (
                        <Icon
                          icon={DescendingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      ) : (
                        <Icon
                          icon={AscendingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      )}
                    </TableCell>
                    <TableCell
                      className={"tableheading"}
                      paddingRight={5}
                      align={"left"}
                    >
                      Active Roles
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminActiveList?.map((user) => (
                    <TableRow key={user.id} sx={{ border: 0 }}>
                      <TableCell
                        className={`tablebody cursorPointer`}
                        width={"20%"}
                      >
                        <PanelDetailsView
                          profilePicUrl={user.profile_pic_url}
                          interviewerEmail={user.email}
                          firstName={user.first_name}
                          lastName={user.last_name}
                          designationName={user.designation_name}
                          employeeCode={user.employee_code}
                        />
                      </TableCell>

                      <TableCell
                        className="tablebody"
                        align="left"
                        width={"20%"}
                      >
                        {user.competency_name}
                      </TableCell>

                      <TableCell
                        align="left"
                        className="tablebody"
                        width={"60%"}
                      >
                        <Grid
                          container
                          className={styles.tagsWrap}
                          rowSpacing={0.5}
                          columnSpacing={0.5}
                          justifyContent={"left"}
                          alignItems={"center"}
                        >
                          {user.ismanager === true && (
                            <Chip
                              label={`HR-Manager`}
                              className={`${styles.adminActiveManagerRolesStyle} `}
                              key={user.id}
                              onDelete={() => {
                                setShowDeleteManagerConfirmModal(true);
                                setDeleteUserId(user.id);
                              }}
                            />
                          )}
                          {user.roles.length &&
                            user.roles?.map((role, index) =>
                              role.type === ROLES.ADMIN ? (
                                <Chip
                                  label={role.type}
                                  className={`${styles.activeRolesStyle} `}
                                  key={index}
                                  onDelete={() => {
                                    setShowDeleteConfirmModal(true);
                                    setDeleteItemId(role.id);
                                  }}
                                />
                              ) : (
                                <Chip
                                  label={`${role.type} - ${role.competencyName}`}
                                  className={`${styles.adminActiveRolesStyle} `}
                                  key={index}
                                  onDelete={() => {
                                    setShowDeleteConfirmModal(true);
                                    setDeleteItemId(role.id);
                                  }}
                                />
                              )
                            )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                userCount={userCount}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                activePageNumber={activePageNumber}
              />
            </TableContainer>
          </Paper>
        ) : (
          <NoDataFound disabled={true} />
        )}
      </Grid>
      {showDeleteConfirmModal && (
        <DeleteConfirmationModal
          show={showDeleteConfirmModal}
          setShow={handleClose}
          clickConfirm={handleDeleteRole}
        />
      )}
      {showDeleteManagerConfirmModal && (
        <DeleteConfirmationModal
          show={showDeleteManagerConfirmModal}
          setShow={closeModal}
          clickConfirm={handleDeleteManagerRole}
        />
      )}
    </>
  );
};

export default AdminTable;
