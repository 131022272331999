import buildUrl from "build-url";
import {
  AUTH_INFO,
  INTERVIEW_STATUS_OPTIONS,
  PERSIST_ROOT,
  INTERVIEW_STATUS_CHECK,
} from "../constants";
import format from "date-fns/format";

let authInfo = "";
function loadAuthInfo(refresh) {
  if (authInfo && !refresh) {
    return authInfo;
  }
  try {
    authInfo = JSON.parse(localStorage.getItem(AUTH_INFO));
    return authInfo;
  } catch (err) {
    console.log("Unauthorized. No token found");
  }
}
loadAuthInfo();

function saveAuthInfo(authInfo) {
  localStorage.setItem(AUTH_INFO, JSON.stringify(authInfo));
  loadAuthInfo(true);
}

function resetAuthInfo() {
  localStorage.removeItem(AUTH_INFO);
  localStorage.removeItem(PERSIST_ROOT);
}

function doFetch(url, method, body, configuredHeaders = {}, signal) {
  const options = {
    method,
    mode: "cors",
  };
  if (body) {
    if (body instanceof FormData) {
      options.body = body;
    } else {
      options.body = JSON.stringify(body);
      options.headers = {
        Accept: "application/json",
        "Content-type": "application/json",
      };
    }
  }

  options.headers = options.headers || {};
  options.headers.authToken =
    authInfo && authInfo.authToken ? authInfo.authToken : ``;
  options.headers = {
    ...options.headers,
    ...configuredHeaders,
  };
  options.headers.role_id = authInfo?.userInfo?.role_id
    ? authInfo.userInfo.role_id
    : ``;
  if (signal) {
    return fetch(url, { signal: signal, ...options }).then(async (resp) => {
      if (resp.status >= 400) {
        const body = await resp.json();
        console.log("Error inside fetch", body);
        if (
          body?.error?.key === "INVALID_TOKEN" ||
          body.key === "UNAUTHORIZED"
        ) {
          window.history.go("/login");
          resetAuthInfo();
        }
        throw body;
      }
      return resp.json().then((response) => {
        if (response.error) {
          throw new Error(response.error);
        }
        return response;
      });
    });
  } else {
    return fetch(url, options).then(async (resp) => {
      if (resp.status >= 400) {
        const body = await resp.json();
        console.log("Error inside fetch", body);
        if (
          body?.error?.key === "INVALID_TOKEN" ||
          body.key === "UNAUTHORIZED"
        ) {
          window.history.go("/login");
          resetAuthInfo();
        }
        throw body;
      }
      return resp.json().then((response) => {
        if (response.error) {
          throw new Error(response.error);
        }
        return response;
      });
    });
  }
}

export const getAuthInfo = loadAuthInfo;
export const setAuthInfo = saveAuthInfo;
export const removeAuthInfo = resetAuthInfo;

export const get = (baseUrl, queryParams, configuredHeaders, signal) => {
  const url = buildUrl(baseUrl, queryParams);
  return doFetch(url, "GET", null, configuredHeaders, signal);
};

export const post = (baseUrl, queryParams, body, configuredHeaders) => {
  const url = buildUrl(baseUrl, queryParams);
  return doFetch(url, "POST", body, configuredHeaders);
};

export const put = (baseUrl, queryParams, body) => {
  const url = buildUrl(baseUrl, queryParams);
  return doFetch(url, "PUT", body);
};

export const remove = (baseUrl, queryParams, body) => {
  const url = buildUrl(baseUrl, queryParams);
  return doFetch(url, "DELETE", body);
};

let navigateCallback;

export const setNavigateCallback = (callback) => {
  navigateCallback = callback;
};

export const redirectToPage = (path) => {
  if (navigateCallback) {
    navigateCallback(path);
  } else {
    console.error("Navigate callback not set");
  }
};
export const deriveAbbreviatedName = (name) => {
  const splittedName = name?.trim().split(" ");
  if (splittedName?.length < 2) return name;
  return splittedName?.map((item) => String(item).trim()[0]).join("");
};

export const extractDate = (date) => {
  return new Date(date).toLocaleString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const extractDateAndTime = (date) => {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = new Date(date);
  const formattedDate1 = formattedDate.toLocaleString("en-US", options);
  const [, monthPart, dayPart, yearPart, timePart] = formattedDate1.match(
    /(\w+) (\d+), (\d+), (.+)/
  );
  return ` ${monthPart}-${dayPart}-${yearPart} ${timePart}`;
};

export const extractDateMonth = (date) => {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = new Date(date);
  const formattedDate1 = formattedDate.toLocaleString("en-US", options);
  const [, monthPart, dayPart, yearPart] = formattedDate1.match(
    /(\w+) (\d+), (\d+), (.+)/
  );
  return ` ${monthPart} ${dayPart}, ${yearPart}`;
};

export const redirectToNewersWorldProfileByUserId = (userId) => {
  const linkUrl = `https://newersworld.tothenew.com/#/unifiedEmployeeView/${userId}`;
  const newTab = window.open(linkUrl, "_blank");
  if (newTab) {
    newTab.focus();
  }
};

export const redirectUrl = (linkUrl) => {
  const newTab = window.open(linkUrl, "_blank");
  if (newTab) {
    newTab.focus();
  }
};

export const isTodayHoliday = (todayDate, holidaysList) => {
  const result = Array.isArray(holidaysList)
    ? holidaysList.filter(
        (item) =>
          new Date(item.date).getDate() === new Date(todayDate).getDate() &&
          new Date(item.date).getMonth() === new Date(todayDate).getMonth() &&
          new Date(item.date).getFullYear() ===
            new Date(todayDate).getFullYear()
      )
    : [];
  return !!result.length;
};

export const isWeekendDriveExists = (todayDate, weekendDriveList) => {
  const result = Array.isArray(weekendDriveList)
    ? weekendDriveList.filter(
        (item) =>
          new Date(item.date_of_drive).getDate() ===
            new Date(todayDate).getDate() &&
          new Date(item.date_of_drive).getMonth() ===
            new Date(todayDate).getMonth() &&
          new Date(item.date_of_drive).getFullYear() ===
            new Date(todayDate).getFullYear()
      )
    : [];
  return result;
};

export const isCancelledWeekendDrive = (todayDate, weekendDriveList) => {
  const result = Array.isArray(weekendDriveList)
    ? weekendDriveList.filter(
        (item) =>
          new Date(item.date_of_drive).getDate() ===
            new Date(todayDate).getDate() &&
          new Date(item.date_of_drive).getMonth() ===
            new Date(todayDate).getMonth() &&
          new Date(item.date_of_drive).getFullYear() ===
            new Date(todayDate).getFullYear() &&
          item.drive_status === INTERVIEW_STATUS_CHECK.CANCELLED
      )
    : [];
  return !!result.length;
};

export const checkEqualDates = (date1, date2) => {
  return (
    new Date(date1).getDate() === new Date(date2).getDate() &&
    new Date(date1).getMonth() === new Date(date2).getMonth() &&
    new Date(date1).getFullYear() === new Date(date2).getFullYear()
  );
};

const convertStringtoPascalCase = (str) =>
  (str.match(/[a-zA-Z0-9]+/g) || [])
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join(" ");

export const VIRTUAL_DESCRIPTION_TEMPLATE = (interieweeName) => {
  return `<p>Dear ${convertStringtoPascalCase(
    interieweeName
  )},</p><p>Greetings from TO THE NEW!</p><p>Your interview is confirmed with TO THE NEW. This would be a video call so request you to take care of the following things:</p><p>- Use a laptop or desktop computer and ensure that your webcam is functional.</p><p>- Test your internet connection and sound atleast a couple minutes prior to the interview.</p><p>Please share a copy of your updated Resume and a valid Photo ID document (PAN Card / Passport) with us before the interview.</p><p>Wish you good luck for the interview!</p><p>Regards,</p><p>HR Team</p>`;
};

export const INPERSON_DESCRIPTION_TEMPLATE = (interieweeName) => {
  return `<p>Dear ${convertStringtoPascalCase(
    interieweeName
  )},</p><p>Greetings from TO THE NEW!</p><p>We would like to invite you for a face-to-face interview to our office in Noida. Given below is the address for your reference:</p><p>TO THE NEW Private Limited</p><p>2nd Floor, Golden Tower Infratech Pvt. Ltd., SEZ</p><p>Plot No. 8, NSL Techzone, Sector - 144, Noida (UP)</p><p>Google Maps:<a href="https://www.google.com/maps/place/TO+THE+NEW/@28.4956921,77.4346721,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce670fa863f67:0x302ea32b4f957d29!8m2!3d28.4956921!4d77.4346721!16s%2Fg%2F11c3k54n7m?hl=en-IN" rel="noopener noreferrer" target="_blank"> TO THE NEW - Google Map</a></p><p>Also, please share a copy of your updated Resume and a valid Photo ID document (PAN Card / Passport) before the interview.</p><p>Please acknowledge to confirm your presence and try reaching atleast 10 minutes prior to the scheduled time.</p><p>Wish you good luck for the interview!</p><p>Regards,</p><p>HR Team</p>`;
};

export const isEmpty = (cvLink) => {
  if (
    typeof cvLink === "undefined" ||
    cvLink === null ||
    (!cvLink && !cvLink?.name?.length)
  )
    return true;
  else return false;
};

export const showBadge = (x, y) => {
  if (x === "Interviewer Not Available") {
    return "none";
  }
  if (x === "Interviewer Rescheduled") {
    return "none";
  }
  if (x === "Candidate Not Available") {
    return "none";
  }
  if (x === "Candidate Rescheduled") {
    return "none";
  }
  if (x === "Completed" && y.isInterviewerDeleted) {
    return "none";
  }
  if (x === "Completed" && !y.isInterviewerDeleted) {
    return "";
  }
  if (x === "Invalid") {
    return "none";
  }
  if (x === "Scheduled" && y.isInterviewerDeleted) {
    return "none";
  }
  if (x === "Scheduled" && !y.isInterviewerDeleted) {
    return "";
  }
  if (x === "To Be Scheduled" && !y.isInterviewerDeleted) {
    return "";
  }
  if (x === "To Be Scheduled" && y.isInterviewerDeleted) {
    return "none";
  }
};

export const colorSet = (x, y) => {
  if (
    y.isInterviewerDeleted &&
    INTERVIEW_STATUS_OPTIONS[y.interviewer_status] === "Invalid"
  ) {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }
  if (x === "Interviewer Not Available") {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }
  if (x === "Interviewer Rescheduled") {
    return {
      color: "#A56D00 !important",
      backgroundColor: "#FADA95!important",
    };
  }
  if (x === "Candidate Not Available") {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }
  if (x === "Candidate Rescheduled") {
    return {
      color: "#A56D00 !important",
      backgroundColor: "#FADA95!important",
    };
  }
  if (x === "Completed" && y.isInterviewerDeleted) {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }
  if (x === "Completed" && !y.isInterviewerDeleted) {
    return {
      color: "#FFFFFF !important",
      backgroundColor: "#05B14E !important",
    };
  }
  if (x === "Invalid") {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }

  if (x === "Scheduled" && y.isInterviewerDeleted) {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }
  if (x === "To Be Scheduled" && y.isInterviewerDeleted) {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }
  if (x === "Scheduled" && !y.isInterviewerDeleted) {
    return {
      color: "#000000 !important",
      backgroundColor: "#FFAF00!important",
    };
  }
  if (x === "To Be Scheduled" && !y.isInterviewerDeleted) {
    return {
      color: "#000000 !important",
      backgroundColor: "#FFAF00!important",
    };
  }
};

export const weekdaysColorSet = (x, y) => {
  if (
    y.isInterviewerDeleted &&
    INTERVIEW_STATUS_OPTIONS[y.interviewer_status] === "Invalid"
  ) {
    return {
      color: "#CF2E2E !important",
      backgroundColor: "#FFB5B5 !important",
    };
  }
  if (x === "Interviewer Not Available") {
    return { color: "#CF2E2E", backgroundColor: "#FFB5B5 " };
  }
  if (x === "Interviewer Rescheduled") {
    return { color: "#A56D00", backgroundColor: "#FADA95 " };
  }
  if (x === "Candidate Not Available") {
    return { color: "#CF2E2E", backgroundColor: "#FFB5B5 " };
  }
  if (x === "Candidate Rescheduled") {
    return { color: "#A56D00", backgroundColor: "#FADA95" };
  }
  if (x === "Completed" && y.isInterviewerDeleted) {
    return { color: "#CF2E2E", backgroundColor: "#FFB5B5" };
  }
  if (x === "Completed" && !y.isInterviewerDeleted) {
    return { color: "#FFFFFF", backgroundColor: "#05B14E" };
  }
  if (x === "Invalid") {
    return { color: "#CF2E2E", backgroundColor: "#FFB5B5" };
  }
  if (x === "Scheduled" && y.isInterviewerDeleted) {
    return { color: "#CF2E2E", backgroundColor: "#FFB5B5" };
  }
  if (x === "To Be Scheduled" && y.isInterviewerDeleted) {
    return { color: "#CF2E2E", backgroundColor: "#FFB5B5" };
  }
  if (x === "Scheduled" && !y.isInterviewerDeleted) {
    return { color: "#000000", backgroundColor: "#FFAF00" };
  }
  if (x === "To Be Scheduled" && !y.isInterviewerDeleted) {
    return { color: "#000000", backgroundColor: "#FFAF00" };
  }
};

export const getDataFromComments = (comments) => {
  let result = "";
  comments &&
    comments.forEach((item, index) => {
      if (comments?.length - 1 === index) result += item;
      else result = result + item + ",";
    });
  return result;
};

export const generateDatesList = (startDate, endDate) => {
  const datesList = [];
  const currentDate = new Date(startDate);
  while (currentDate <= new Date(endDate)) {
    datesList.push({
      value: currentDate.toISOString(),
      label: format(currentDate, "yyyy-MM-dd"),
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return datesList;
};
