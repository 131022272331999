import React from "react";

import { Pagination, PaginationItem, Typography, Stack } from "@mui/material";

import styles from "./style.module.scss";

const index = ({ pageCount, handlePageClick, activePageNumber, userCount }) => {
  return (
    <Stack
      spacing={0.4}
      direction="row"
      justifyContent="space-between"
      paddingRight={2}
      className={styles.paginationlayout}
    >
      <Typography
        display="flex"
        alignItems={"center"}
        paddingBottom={2.5}
        paddingTop={2.9}
        paddingLeft={3}
        className={"tablebody"}
      >
        Total {userCount} records
      </Typography>
      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          shape="rounded"
          onChange={(e, value) => handlePageClick(e, value)}
          page={activePageNumber}
          color="primary"
          sx={{
            ".MuiPagination-ul li:first-child ": {
              color: "#7477AC",
              borderRadius: "50%",
              borderWidth: "1.4px",
              backgroundColor: "#F5F6F8",
              width: "30.27px",
              height: "32.21px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            ".MuiPagination-ul li:first-child button": { borderRadius: "50%" },
            ".MuiPagination-ul li:last-child button": { borderRadius: "50%" },
            ".MuiPagination-ul li:last-child": {
              color: "#7477AC",
              borderRadius: "50%",
              borderWidth: "1.4px",
              backgroundColor: "#F5F6F8",
              width: "30.27px",
              height: "32.21px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            display: "flex",
            alignItems: "center",
            paddingTop: "21px",
            paddingBottom: "20px",
          }}
          renderItem={(item) => (
            <PaginationItem
              sx={{
                color: "#2E1C41",
                width: "40px",
              }}
              {...item}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default index;
