import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  saveDistributionList,
  editDistributionListById,
} from "../../../redux/features/Admin/adminSlice";

import {
  TextField,
  Box,
  Typography,
  Dialog,
  Grid,
  Button,
  IconButton,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import styles from "./style.module.scss";

const CreateEditDL = ({
  show,
  setShow,
  loginInfo,
  configId,
  setConfigId,
  distributionListDescription,
  setDistributionListDescription,
  distributionListEmail,
  setDistributionListEmail,
  distributionListName,
  setDistributionListName,
  setActiveAccordion,
  editDlEmailId,
  setEditDlEmailId,
}) => {
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const [nameerror, setNameError] = useState(false);
  const [emailerror, setEmailError] = useState(false);
  const [descerror, setDescError] = useState(false);

  const handleClose = () => {
    setShow(false);
    setEditDlEmailId(null);
    setDistributionListName("");
    setDistributionListEmail("");
    setDistributionListDescription("");
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email === "" || emailPattern.test(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setDistributionListEmail(email);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      if (configId === null && editDlEmailId == null) {
        dispatch(
          saveDistributionList({
            dlName: distributionListName,
            dlEmail: distributionListEmail,
            dlDescription: distributionListDescription,
            roleId: loginInfo.role_id,
            pageNumber: 1,
            dispatch,
          })
        );
        setActiveAccordion("0");
        setValidated(false);
        setDistributionListName("");
        setDistributionListEmail("");
        setDistributionListDescription("");
      } else if (configId !== null && editDlEmailId !== null) {
        dispatch(
          editDistributionListById({
            dlName: distributionListName,
            dlEmail: distributionListEmail,
            dlDescription: distributionListDescription,
            roleId: loginInfo.role_id,
            pageNumber: 1,
            dlId: configId,
            dispatch,
          })
        );
        setActiveAccordion("0");
        setValidated(false);
        setDistributionListName("");
        setDistributionListEmail("");
        setDistributionListDescription("");
        setConfigId(null);
        setEditDlEmailId(null);
      }
      handleClose();
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ maxWidth: 600, margin: "auto" }}
      PaperProps={{
        style: {
          borderRadius: "10px",
          boxShadow: "0px 6px 30px 0px #OD",
        },
      }}
    >
      <Box p={4}>
        <Grid container alignItems="center" marginBottom={2}>
          <Grid item xs={10}>
            <Typography variant="h6" color={"#2e1c41"} gutterBottom>
              {configId !== null && editDlEmailId !== null
                ? "Update DL"
                : "Create DL"}
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="DL Name"
              multiline
              rows={1}
              required
              label="DL Name"
              placeholder="DL Name"
              autoComplete="off"
              variant="outlined"
              value={distributionListName}
              onChange={(e) => {
                if (e.target.value === "" || /^\s*$/.test(e.target.value))
                  setNameError(true);
                else setNameError(false);

                setDistributionListName(e.target.value);
              }}
              fullWidth
              error={nameerror}
              helperText={nameerror ? "Please enter a valid name" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="DL Email"
              multiline
              rows={1}
              type="email"
              label="DL Email"
              placeholder="DL Email"
              autoComplete="off"
              variant="outlined"
              value={distributionListEmail}
              onChange={handleEmailChange}
              fullWidth
              error={emailerror}
              helperText={
                emailerror ? "Please enter a valid email address" : ""
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="DL Description"
              multiline
              rows={4}
              label="DL Description"
              placeholder="DL Description"
              autoComplete="off"
              variant="outlined"
              value={distributionListDescription}
              onChange={(e) => {
                if (e.target.value === "" || /^\s*$/.test(e.target.value))
                  setDescError(true);
                else setDescError(false);

                setDistributionListDescription(e.target.value);
              }}
              fullWidth
              error={descerror}
              helperText={descerror ? "Please enter a description" : ""}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={
                !(
                  distributionListName &&
                  distributionListEmail &&
                  distributionListDescription &&
                  !emailerror &&
                  !nameerror &&
                  !descerror
                )
              }
              className={
                !(
                  distributionListName &&
                  distributionListEmail &&
                  distributionListDescription &&
                  !emailerror &&
                  !nameerror &&
                  !descerror
                )
                  ? `${styles.saveBtnDisable}`
                  : `${styles.saveBtn}`
              }
              onClick={onFormSubmit}
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              {configId !== null && editDlEmailId !== null ? "Update" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CreateEditDL;
