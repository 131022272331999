import React, { useState, useEffect, useCallback } from "react";

import InterviewDetailsStep from "./InterviewDetailsStep";
import CandidateDetailsStep from "./CandidateDetailsStep";
import EventDetailsStep from "./EventDetailsStep";
import CustomizedStepper from "./CustomizedStepper";

import dayjs from "dayjs";

import { INTERVIEW_STATUS_CHECK } from "../../../../constants";

const CreateBookingModal = (props) => {
  const {
    user,
    rounds,
    roundId,
    setRoundId,
    interviewStatus,
    setInterviewStatus,
    interviewMode,
    setInterviewMode,
    comments,
    setComments,
    defaultCountry,
    setDefaultCountry,
    countryCodeEditable,
    setCountryCodeEditable,
    createEvent,
    setCreateEvent,
    calendarEventTitle,
    setCalendarEventTitle,
    eventDescription,
    setEventDescription,
    intervieweName,
    setIntervieweName,
    bookingId,
    weekDate,
    bookingDate,
    setBookingDate,
    onSetBookingDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    onSetStartTime,
    isPairingRoundSelected,
    isRoundInPairingIntial,
    setIsRoundInPairingIntial,
    isRoundInPairingFinal,
    setIsRoundInPairingFinal,
    eligiblePairingList,
    pairingUserId,
    setPairingState,
    setPairingUserId,
    setPairingCompetencyId,
    setPairingDesignationId,
    setPairedEmail,
    slots,
    notavailable,
    notprovided,
    pairedmemberData,
    selectedEmail,
    setSelectedEmail,
    fileName,
    setFileName,
    intervieweEmail,
    setIntervieweEmail,
    setLeaderState,
    mobileNumber,
    setMobileNumber,
    handleDisableCandidateMobileNumber,
    setHandleDisableCandidateMobileNumber,
    cvLink,
    setCvLink,
    activeStep,
    steps,
    resetPairingState,
    resetLeaderState,
    setBookingIdForPairedRound,
    bookingIdForPairedRound,
    interviewStatusChange,
    unpairedInterviewersBookingsList,
    isRoundValidationError,
    setIsRoundValidationError,
    isPairedValidationError,
    setIsPairedValidationError,
    isCreatePairingChecked,
    setIsCreatePairingChecked,
    handleRequiredCandidateName,
    handleRequiredCandidateEmail,
    setHandleRequiredCandidateName,
    setHandleRequiredCandidateEmail,
    validateStep1,
    handleRequiredCalendaEventTitle,
    setHandleRequiredCalendarEventTitle,
    mobileNumberRef,
    mobileNumberErrorMessage,
    setMobileNumberErrorMessage,
    filterUnpairedInterviewersBookingsList,
    clickedBookingInterviewerStatus
  } = props;

  const [pairingData, setPairingData] = useState([]);

  const setStatesWhenPairingSelected = useCallback(
    (e) => {
      setBookingIdForPairedRound(e.target.value);
    },
    [setBookingIdForPairedRound]
  );
  
  useEffect(() => {
    if (
      isRoundInPairingIntial &&
      pairingUserId &&
      typeof bookingId === "undefined"
    ) {
      setIsRoundInPairingFinal(true);
    }
  }, [
    bookingId,
    isRoundInPairingIntial,
    pairingUserId,
    setIsRoundInPairingFinal,
  ]);
  useEffect(() => {
    if (isCreatePairingChecked === "No") {
      setPairingUserId(null);
      setPairingCompetencyId(null);
      setPairingDesignationId(null);
      setPairedEmail("");
    }
  }, [isCreatePairingChecked]);
  useEffect(() => {
    if (
      !isPairingRoundSelected &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED)
    ) {
      setLeaderState(user.id);
    }
  }, [isPairingRoundSelected, interviewStatus, setLeaderState, user.id]);
  useEffect(() => {
    if (
      isPairingRoundSelected &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED)
    ) {
      setLeaderState(bookingIdForPairedRound);
      setPairingState();
    }
  }, [
    bookingIdForPairedRound,
    isPairingRoundSelected,
    interviewStatus,
    setLeaderState,
    setPairingState,
  ]);
  useEffect(() => {
    unpairedInterviewersBookingsList &&
      unpairedInterviewersBookingsList.map((item) => {
        if (parseInt(item.booking_id) === parseInt(bookingIdForPairedRound)) {
          setInterviewStatus(item.booking_status);
          onSetStartTime(dayjs(new Date(item.start_time)));
          setEndTime(dayjs(new Date(item.end_time)));
          setPairingData(item);
          setComments(item.booking_notes);
        }
        return item;
      });
  }, [
    setStatesWhenPairingSelected,
    bookingIdForPairedRound,
    onSetStartTime,
    unpairedInterviewersBookingsList,
    setEndTime,
    setInterviewStatus,
  ]);

  const eligiblePanelistForPairing = eligiblePairingList?.filter(
    (item) => item.user_id !== user.id
  );

  return (
    <>
      <CustomizedStepper activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <InterviewDetailsStep
          clickedBookingInterviewerStatus={clickedBookingInterviewerStatus}
          roundId={roundId}
          setRoundId={setRoundId}
          rounds={rounds}
          interviewStatus={interviewStatus}
          setInterviewStatus={setInterviewStatus}
          interviewMode={interviewMode}
          setInterviewMode={setInterviewMode}
          comments={comments}
          setComments={setComments}
          isPairingRoundSelected={isPairingRoundSelected}
          weekDate={weekDate}
          bookingDate={bookingDate}
          onSetBookingDate={onSetBookingDate}
          setBookingDate={setBookingDate}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          onSetStartTime={onSetStartTime}
          isCreatePairingChecked={isCreatePairingChecked}
          setIsCreatePairingChecked={setIsCreatePairingChecked}
          isRoundInPairingIntial={isRoundInPairingIntial}
          setIsRoundInPairingIntial={setIsRoundInPairingIntial}
          isRoundInPairingFinal={isRoundInPairingFinal}
          setIsRoundInPairingFinal={setIsRoundInPairingFinal}
          eligiblePanelistForPairing={eligiblePanelistForPairing}
          pairingUserId={pairingUserId}
          setPairingState={setPairingState}
          slots={slots}
          notavailable={notavailable}
          notprovided={notprovided}
          pairedmemberData={pairedmemberData}
          resetPairingState={resetPairingState}
          resetLeaderState={resetLeaderState}
          bookingIdForPairedRound={bookingIdForPairedRound}
          setBookingIdForPairedRound={setBookingIdForPairedRound}
          interviewStatusChange={interviewStatusChange}
          filterUnpairedInterviewersBookingsList={
            filterUnpairedInterviewersBookingsList
          }
          setStatesWhenPairingSelected={setStatesWhenPairingSelected}
          pairingData={pairingData}
          isRoundValidationError={isRoundValidationError}
          setIsRoundValidationError={setIsRoundValidationError}
          isPairedValidationError={isPairedValidationError}
          setIsPairedValidationError={setIsPairedValidationError}
          validateStep1={validateStep1}
        />
      )}
      {activeStep === 1 && (
        <CandidateDetailsStep
          interviewStatus={interviewStatus}
          defaultCountry={defaultCountry}
          setDefaultCountry={setDefaultCountry}
          countryCodeEditable={countryCodeEditable}
          setCountryCodeEditable={setCountryCodeEditable}
          mobileNumberRef={mobileNumberRef}
          mobileNumberErrorMessage={mobileNumberErrorMessage}
          setMobileNumberErrorMessage={setMobileNumberErrorMessage}
          intervieweName={intervieweName}
          setIntervieweName={setIntervieweName}
          isPairingRoundSelected={isPairingRoundSelected}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          fileName={fileName}
          setFileName={setFileName}
          intervieweEmail={intervieweEmail}
          setIntervieweEmail={setIntervieweEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          cvLink={cvLink}
          setCvLink={setCvLink}
          handleDisableCandidateMobileNumber={
            handleDisableCandidateMobileNumber
          }
          setHandleDisableCandidateMobileNumber={
            setHandleDisableCandidateMobileNumber
          }
          pairingData={pairingData}
          handleRequiredCandidateName={handleRequiredCandidateName}
          handleRequiredCandidateEmail={handleRequiredCandidateEmail}
          setHandleRequiredCandidateName={setHandleRequiredCandidateName}
          setHandleRequiredCandidateEmail={setHandleRequiredCandidateEmail}
        />
      )}
      {activeStep === 2 && (
        <EventDetailsStep
          createEvent={createEvent}
          setCreateEvent={setCreateEvent}
          calendarEventTitle={calendarEventTitle}
          setCalendarEventTitle={setCalendarEventTitle}
          eventDescription={eventDescription}
          setEventDescription={setEventDescription}
          interviewMode={interviewMode}
          setInterviewMode={setInterviewMode}
          intervieweName={intervieweName}
          pairingData={pairingData}
          intervieweEmail={intervieweEmail}
          handleRequiredCalendaEventTitle={handleRequiredCalendaEventTitle}
          setHandleRequiredCalendarEventTitle={
            setHandleRequiredCalendarEventTitle
          }
        />
      )}
    </>
  );
};

export default CreateBookingModal;
