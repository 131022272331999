import React, { useState } from "react";
import {
  Grid,
  Chip,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { extractDate, deriveAbbreviatedName } from "../../../utils/app-utils";
import {
  ROLE_TYPES,
  SORT_TYPE_CHECK_VALUE,
  SORT_ORDER,
} from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteTemporarySuspendedMember,
  fetchSuspendedListByCompetency,
} from "../../../redux/features/Roster/rosterSlice";
import Pagination from "../../../components/Pagination/index";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal.js";
import PanelDetailsView from "../../../components/PanelDetailsView/PanelDetailsView.jsx";
import styles from "./style.module.scss";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import Icon from "../../../components/SvgIcon/icon";
import SortingIcon from "../../../assets/icons/sort-icon.svg";
import AscendingIcon from "../../../assets/icons/ascending-icon.svg";
import DescendingIcon from "../../../assets/icons/descending-icon.svg";
import Spinner from "../../../components/Spinner/index.js";
import NoData from "../NoData/index.js";
const { isSpoc } = ROLE_TYPES;

const TemporarySuspendTab = ({
  list,
  activePageNumber,
  setActivePageNumber,
  suspendedListPageCount,
  searchValue,
  setSortBy,
  sortBy,
  keyvalue,
  roleId,
  competencyId,
  totalCount,
  loading,
}) => {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [suspendPanelId, setSuspendPanelId] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer?.loginInfo);
  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const onRemoveTemporarySuspend = async () => {
    if (suspendPanelId) {
      setActivePageNumber(1);
      await dispatch(
        deleteTemporarySuspendedMember({
          panelId: suspendPanelId,
          roleId: roleId,
        })
      );
      await dispatch(
        fetchSuspendedListByCompetency({
          competencyId,
          roleId,
          pageNumber: 1,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
        })
      );
      setSuspendPanelId(null);
    }
  };
  const handleClose = () => {
    setShowDeleteConfirmModal(false);
    setSuspendPanelId(null);
  };
  const getRoundDetail = (data, serial) =>
    data && data.length && data.filter((item) => item.roundName === serial);
  const showRoundDetails = (roundType, user) => {
    const roundDetails = getRoundDetail(user.rounds, roundType);
    if (roundDetails.length) {
      return roundDetails?.map((item, index) => (
        <Grid item>
          {
            <Chip
              label={
                item.isPairing
                  ? `${deriveAbbreviatedName(item.designationName)}(P)`
                  : `${deriveAbbreviatedName(item.designationName)}`
              }
              className={
                item.isPairing
                  ? styles.pairingChipStyle
                  : styles.individualChipStyle
              }
              key={index}
            />
          }
        </Grid>
      ));
    }
  };
  const actionItems = (panelId) => {
    if (isSpoc(user.current_role))
      return (
        <TableCell className="tablebody">
          <Tooltip title="Remove From Temporary Suspend" placement="top" arrow>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Icon
                  icon={DeleteIcon}
                  width="16.5px"
                  height="16.77px"
                  click={() => {
                    setShowDeleteConfirmModal(true);
                    setSuspendPanelId(panelId);
                  }}
                />
              </Grid>
            </Grid>
          </Tooltip>
        </TableCell>
      );
  };
  const handleSortType = (columnLabel) => {
    setSortBy((prevState) => {
      const sortOrder =
        prevState.sortType === columnLabel
          ? prevState.sortOrder === SORT_ORDER.ASCENDING || !prevState.sortOrder
            ? SORT_ORDER.DESCENDING
            : SORT_ORDER.ASCENDING
          : SORT_ORDER.ASCENDING;
      return {
        sortType: columnLabel,
        sortOrder: sortOrder,
      };
    });
  };
  const [open, setOpen] = React.useState(null);

  const handleTooltipClose = () => {
    setOpen(null);
  };

  const handleTooltipOpen = (index) => {
    setOpen(index);
  };
  if (loading) return <Spinner />;
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={"globalLayoutHeight"}
    >
      {list?.length ? (
        <Paper className={"globalTableLayoutStyle"}>
          <TableContainer
            className={`globalLayoutHeight globalTableContainerAlign`}
          >
            <Table aria-label="suspended table" stickyHeader>
              <TableHead sx={{ lineHeight: "21px" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    className={"tableheading"}
                    onClick={() => handleSortType(SORT_TYPE_CHECK_VALUE.NAME)}
                    width={"20%"}
                  >
                    Panel Member
                    {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.NAME ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={"tableheading"}
                    width={"15%"}
                    onClick={() =>
                      handleSortType(SORT_TYPE_CHECK_VALUE.DATE_OF_JOINING)
                    }
                    style={{ whiteSpace: "nowrap"}}
                  >
                    Date of Joining
                    {sortBy.sortType !==
                    SORT_TYPE_CHECK_VALUE.DATE_OF_JOINING ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    )}
                  </TableCell>
                  <TableCell align="left" className={"tableheading"}>
                    Round 1
                  </TableCell>
                  <TableCell align="left" className={"tableheading"}>
                    Round 2
                  </TableCell>
                  <TableCell
                    align="left"
                    className={"tableheading"}
                    width={"13%"}
                    style={{ whiteSpace: "nowrap"}}
                  >
                    Suspended on
                  </TableCell>
                  <TableCell align="left" className={"tableheading"}>
                    Reason
                  </TableCell>
                  <TableCell
                    align="left"
                    className={"tableheading"}
                    width={"15%"}
                    style={{ whiteSpace: "nowrap"}}
                  >
                    Suspended by
                  </TableCell>
                  {isSpoc(user.current_role) ? (
                    <TableCell align="center" className={"tableheading"}>
                      Action
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map((user, index) => (
                  <TableRow key={user.id} sx={{ border: 0 }}>
                    <TableCell className={`tablebody cursorPointer`}>
                      <PanelDetailsView
                        profilePicUrl={user.profile_pic_url}
                        interviewerEmail={user.email}
                        firstName={user.first_name}
                        lastName={user.last_name}
                        designationName={user.designation_name}
                        employeeCode={user.employee_code}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={"tablebody"}
                      width={"10%"}
                    >
                      {extractDate(user?.date_of_joining)}
                    </TableCell>
                    <TableCell align="left" className={"tablebody"}>
                      <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                        {showRoundDetails("R1", user)}
                      </Grid>
                    </TableCell>
                    <TableCell align="left" className={"tablebody"}>
                      <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                        {showRoundDetails("R2", user)}
                      </Grid>
                    </TableCell>
                    <TableCell align="left" className={"tablebody"}>
                      {extractDate(user?.suspended_from)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`tablebody ${styles.suspendReason}`}
                      width={"10%"}
                    >
                      {(user?.suspended_reason &&
                        user.suspended_reason.slice(0, 20)) ||
                        "No Comments"}
                      <Typography
                        variant="body1"
                        className={styles.bookingOverlayItemSubHeading}
                      >
                        {user?.suspended_reason.length > 20 && (
                          <>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={open === index}
                              title={user?.suspended_reason}
                              arrow
                            >
                              <Button
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                sx={{ fontSize: "12px" }}
                                onClick={() => {
                                  handleTooltipOpen(index);
                                }}
                              >
                                ...View More
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={"tablebody"}>
                      {user?.suspended_by}
                    </TableCell>
                    {actionItems(user?.panel_id)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              pageCount={suspendedListPageCount}
              handlePageClick={handlePageClick}
              activePageNumber={activePageNumber}
              userCount={totalCount}
            />
          </TableContainer>
        </Paper>
      ) : (
        <NoData/>
      )}
      {showDeleteConfirmModal && (
        <DeleteConfirmationModal
          show={showDeleteConfirmModal}
          setShow={handleClose}
          clickConfirm={onRemoveTemporarySuspend}
        />
      )}
    </Grid>
  );
};

export default TemporarySuspendTab;
