import React from "react";

import { useDispatch } from "react-redux";

import { changeFilterStatus } from "../../../../redux/features/WeekendDrive/weekendDriveSlice";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { INTERVIEW_STATUS_WEEKEND_DRIVE_FILTER } from "../../../../constants/index";

export default function FilterView({
  driveFilterStatus,
  finalFilterStatus,
  onChangeFilterStatus,
}) {
  let dispatch = useDispatch();

  const handleFilterStatus = (event) => {
    onChangeFilterStatus(event.target.value);
    dispatch(changeFilterStatus(event.target.value));
  };

  return (
    <FormControl
      fullWidth
      style={{ width: "100%", height: "40px", backgroundColor: "#FFFFFF" }}
    >
      <InputLabel id="round-label">Drive Status</InputLabel>
      <Select
        style={{
          width: "100%",
          height: "40px",
          textAlign: "left",
          backgroundColor: "#FFFFFF",
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
              maxWidth: 200,
            },
          },
        }}
        labelId="drive-status-label"
        value={driveFilterStatus}
        onChange={(event) => handleFilterStatus(event)}
        label="Drive Status"
        id="drive-status-label"
        placeholder="Drive Status"
      >
        {Object.entries(INTERVIEW_STATUS_WEEKEND_DRIVE_FILTER)?.map(
          ([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
