import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { BpIcon, BpCheckedIcon } from "./MuiCheckIcon";

import { createPanelGroupMapping } from "../../../redux/features/PanelGroup/panelGroup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Checkbox,
  TextField,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  InputAdornment,
  Typography,
  Grid,
} from "@mui/material";

import closeIcon from "../../../assets/icons/close-icon.svg";
import SearchIcon from "../../../assets/icons/tag-search-icon.svg";

import { ROSTER_FILTER_TAB_CHECK_VALUE } from "../../../constants";

import styles from "./style.module.scss";
import theme from "../../../theme/index";

const AddTagModal = ({
  show,
  setShow,
  selectedGroup,
  setSelectedGroup,
  competencyId,
  selectedPanel,
  activedesignationId,
  subCompetency,
  designationIds,
  panelGroupId,
  searchValue,
  sortBy,
  signal,
  dateOfJoining,
  setCompetencyId,
  loginInfo,
  activePageNumber,
  setActivePageNumber,
  activeAccordion,
  handleTabsChange,
  keyvalue,
}) => {
  const dispatch = useDispatch();
  const panelGroupList = useSelector((state) => state?.panelGroupReducer?.list);
  const activeDesignations = useSelector(
    (state) => state.rosterReducer?.activeDesignation
  );
  const {
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    subCompetencyState,
    dateOfJoiningState,
    roundsNameState,
    tagsState,
  } = useSelector((state) => state.rosterReducer?.roster);

  const [optionsGroup, setOptionsGroup] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (selectedPanel.panelgroup && selectedPanel?.panelgroup?.length) {
      const selectedGroup = selectedPanel.panelgroup?.map((item) => {
        return {
          label: item.groupName,
          value: item.tagId,
          id: item.id,
        };
      });
      setSelectedGroup(selectedGroup);
    } else {
      setSelectedGroup(null);
    }
  }, [selectedPanel, selectedPanel.panelgroup]);
  useEffect(() => {
    if (activeDesignations) {
      const desData = Object.entries(activeDesignations);
      if (desData.length) {
        const optionGroup = extractGroupMultiSelectOptions(
          panelGroupList,
          selectedPanel.panelgroup
        );
        setOptionsGroup(optionGroup);
      }
    }
  }, [activeDesignations, selectedPanel.panelgroup, panelGroupList]);

  const closeModal = () => setShow(false);
  const handleClose = () => setShow(false);
  const extractGroupMultiSelectOptions = (
    panelGroupList,
    groupDetails = []
  ) => {
    return panelGroupList?.reduce((acc, { id, group_name }) => {
      const isSelected = groupDetails?.find(
        (detail) => String(detail.groupName) === String(group_name)
      );
      let result = {
        id,
        value: id,
        label: group_name,
      };
      if (isSelected) {
        result.disabled = true;
      }
      acc.push(result);
      return acc;
    }, []);
  };
  const handleToggle = (value) => {
    let newSelected = [];
    if (selectedGroup === null || !selectedGroup) {
      newSelected.push(value);
    } else {
      newSelected = [...selectedGroup];

      const selectedIndex = newSelected.findIndex(
        (item) => item.value === value.value
      );

      if (selectedIndex === -1) {
        newSelected.push(value);
      } else {
        newSelected.splice(selectedIndex, 1);
      }
    }

    setSelectedGroup(newSelected);
  };
  const filteredOptions = optionsGroup?.filter((option) =>
    option.label.toLowerCase().includes(searchInput.toLowerCase())
  );
  const updateGroupDetails = (item) => {
    const groupIds = selectedGroup?.map(({ value }) => value);
    dispatch(
      createPanelGroupMapping({
        groupIds,
        userPanelMappingId: selectedPanel.panel_id,
        panelCompetencyId: loginInfo.current_role_competency_id,
        roleId: loginInfo.role_id,
        pageNumber: activePageNumber,
        query: searchValue,
        roundName: roundsNameState,
        panelGroupId:
          keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
          keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
            ? tagsState
            : null,
        sortType: sortBy.sortType,
        sortOrder: sortBy.sortOrder,
        activedesignationId: interviewerDesignationIdsState,
        dateOfJoining: dateOfJoiningState,
        subCompetency: subCompetencyState,
        designationIds: activeDesignationIdsState,
        signal: signal,
        dispatch,
      })
    );
    handleClose();
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        PaperProps={{
          style: {
            maxHeight: "20rem",
            width: "21rem",
            maxHeight: "50vh",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle
          color={theme.palette.secondary.main}
          sx={{ padding: "30px 24px 0px" }}
        >
          Add Tags
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <Grid sx={{ width: "97%", paddingLeft: 0.6 }}>
          <DialogContent sx={{ padding: "17px 24px 0px" }}>
            {optionsGroup.length ? (
              <>
                <TextField
                  variant="outlined"
                  label=""
                  fullWidth
                  placeholder="Search"
                  value={searchInput}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "40px",
                      fontWeight: "400",
                      fontSize: "15px",
                      border: "1px solid #D3CFD6",
                      color: "#2E1C41",
                      "&::placeholder": {
                        color: "#948B9E",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={SearchIcon}
                          alt="close-icon"
                          style={{ height: "20px", marginRight: "5px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <Grid
                  container
                  direction="column"
                  marginTop={1}
                  marginBottom={1}
                  maxHeight={200}
                  overflow={"auto"}
                >
                  <List sx={{ maxHeight: 400, overflow: "auto" }}>
                    {filteredOptions?.map((option) => (
                      <ListItem
                        key={option}
                        disablePadding
                        disabled={selectedPanel.panelgroup?.some(
                          (item) => item.tagId === option.value
                        )}
                      >
                        <Checkbox
                          checked={
                            selectedGroup &&
                            selectedGroup?.some(
                              (item) => item?.value === option.value
                            )
                          }
                          onChange={() => handleToggle(option)}
                          disabled={selectedPanel.panelgroup?.some(
                            (item) => item.tagId === option.value
                          )}
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon />}
                        />
                        <ListItemText
                          primary={
                            <Typography
                              fontSize={15}
                              fontWeight={400}
                              color={"#2E1C41"}
                              fontFamily={"poppins"}
                            >
                              {option.label}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </>
            ) : (
              <Grid marginBottom={2}>
                <Typography fontSize={15} fontFamily={"poppins"}>
                  {" "}
                  No Tags Available
                </Typography>
              </Grid>
            )}
          </DialogContent>
          <DialogActions sx={{ padding: "0px 24px", marginBottom: 2 }}>
            <Button
              fullWidth
              disabled={optionsGroup.length == 0 ? true : false}
              variant={"contained"}
              onClick={() => updateGroupDetails(selectedPanel)}
              className={styles.saveTagBtn}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              <Typography
                fontSize={14}
                fontWeight={"bold"}
                fontFamily={"poppins"}
              >
                Save
              </Typography>
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};

export default AddTagModal;
