import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";

import AvailabilityHeader from "./AvailabilityHeader";
import PanelMemberList from "./PanelMemberList";
import WeekdaysPanelMemberView from "./WeekdaysPanelMemberView/index.js";
import Weekend from "./Weekend/index.js";

import {
  fetchPanelMembersAvailability,
  getLoggedInUserPanelDetails,
  fetchRoundDesignationId,
  getMemberListWithPanelData,
  fetchInterviewerDesignationsIds,
} from "../../../redux/features/Availability/availabilitySlice.js";
import { fetchWeekendDriveByDate } from "../../../redux/features/WeekendDrive/weekendDriveSlice.js";
import { fetchActiveDesignationByCompetencyId } from "../../../redux/features/Roster/rosterSlice.js";

import { Grid, Chip } from "@mui/material";

import { startOfWeek, endOfWeek, lastDayOfWeek } from "date-fns";
import {
  SLOT_FILTER_OPTIONS,
  ROUND_FILTER_OPTIONS,
  CHECK_TAB_KEY,
  sortArrayOfObjects,
  ROLE_TYPES,
  INTERVIEW_STATUS_CHECK,
  currentAvailabilityTabsState,
  AvailabilityTabs,
} from "../../../constants/index";

import styles from "./style.module.scss";

const { isSpoc, isPanelMember, isAdmin, isHr } = ROLE_TYPES;

const Availability = ({
  searchValue,
  setSearchValue,
  setPlaceholderName,
  activePageNumber,
  setActivePageNumber,
  signal,
  setShowSearch,
  open,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const designationList = useSelector(
    (state) => state?.authReducer?.designation
  );
  const roundDesignationIds = useSelector(
    (state) => state?.availabilityReducer?.search?.roundDesignationIds
  );
  const loggedInUserPanelDetails = useSelector(
    (state) => state?.availabilityReducer?.loggedInUserPanelDetails
  );
  const memberListWithPanelData = useSelector(
    (state) => state?.availabilityReducer?.panelData
  );
  const weekendDriveList = useSelector(
    (state) => state.weekendDriveReducer?.weekendDriveDetails
  );
  const weekendDrivesByDate = useSelector(
    (state) => state.weekendDriveReducer?.weekendDriveLists
  );
  const { userList, loading, pageCount, totalPanelSelectedCount } = useSelector(
    (state) => state?.availabilityReducer
  );
  const weekendDriveLoading = useSelector(
    (state) => state?.weekendDriveReducer?.loadingForWeekendListByDate
  );
  const activeDesignationLoading = useSelector(
    (state) => state?.rosterReducer?.loading
  );
  const {
    providedSlots,
    roundsFilter,
    eligibleDesignationIds,
    interviewerDesignationIds,
    tagsFilter,
    subCompetencyFilter,
  } = useSelector((state) => state?.availabilityReducer?.availability);

  const [competencyId, setCompetencyId] = useState(0);
  const [slotsProvided, setSlotsProvided] = useState(SLOT_FILTER_OPTIONS.ALL);
  const [roundName, setRoundName] = useState(ROUND_FILTER_OPTIONS.ALL);
  const [designationIds, setDesignationIds] = useState([]);
  const [activedesignationId, setActiveDesignationId] = useState([]);
  const [stateToogle, setStateToogle] = useState({ compact: false });
  const [subCompetency, setSubCompetency] = useState([]);
  const [weekDate, setWeekDate] = useState(new Date());
  const [panelGroupId, setPanelGroupId] = useState([]);
  const [optionsRecruiterEmail, setOptionsRecruiterEmail] = useState([]);
  const [weekendDriveListUpdated, setWeekendDriveListUpdated] = useState([]);
  const [startWeekDate, setStartWeekDate] = useState(
    startOfWeek(weekDate, { weekStartsOn: 0 })
  );
  const [endWeekDate, setEndWeekDate] = useState(
    endOfWeek(weekDate, { weekStartsOn: 0 })
  );
  const [monthDate, setMonthDate] = useState(new Date());
  const [startMonthDate, setStartMonthDate] = useState(
    new Date(monthDate.getFullYear(), monthDate.getMonth(), 1)
  );
  const [endMonthDate, setEndMonthDate] = useState(
    new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0)
  );
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  const [weekenddesignationIds, setWeekendDesignationIds] = useState([]);
  const [isMarkAvailabilityModalOpen, setIsMarkAvailabilityModalOpen] =
    useState(false);
  const [filterIcon, setFilterIcon] = useState(false);
  const [weekendDriveSelected, setWeekendDriveSelected] = useState([]);
  const [weekendDriveDate, setWeekendDriveDate] = useState(null);
  const [eligibleDesignationList, setEligibleDesignationList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({ tab: "weekdays" });
  const [activeAccordion, setActiveAccordion] = useState(
    searchParams.get("tab") === CHECK_TAB_KEY.WEEKDAYS ? 0 : 1
  );
  const [keyValue, setKeyValue] = useState(searchParams.get("tab"));

  useEffect(() => {
    const tab = new URLSearchParams(location.search).get("tab");
    if (!tab || !AvailabilityTabs.includes(tab)) {
      setSearchParams({ tab: "weekdays" });
    }
  }, [location]);
  useEffect(() => {
    setKeyValue(searchParams.get("tab"));
    setActiveAccordion(currentAvailabilityTabsState(searchParams.get("tab")));
  }, [searchParams]);
  useEffect(() => {
    if (
      loginInfo.role_id &&
      keyValue === CHECK_TAB_KEY.WEEKDAYS &&
      !isPanelMember(loginInfo.current_role)
    ) {
      dispatch(
        getLoggedInUserPanelDetails({
          roleId: loginInfo.role_id,
          periodFrom: new Date(startWeekDate).toISOString(),
          periodTo: new Date(endWeekDate).toISOString(),
          keyValue,
        })
      );
    }
  }, [dispatch, endWeekDate, loginInfo, startWeekDate]);
  useEffect(() => {
    if (
      loginInfo.current_role_competency_id &&
      (isSpoc(loginInfo.current_role) || isHr(loginInfo.current_role))
    ) {
      !isAdmin(loginInfo.current_role) &&
        dispatch(
          fetchActiveDesignationByCompetencyId({
            roleId: loginInfo.role_id,
            competencyId: loginInfo.current_role_competency_id,
          })
        );
    }
  }, [dispatch, loginInfo]);
  useEffect(() => {
    if (
      loginInfo.role_id &&
      keyValue === CHECK_TAB_KEY.WEEKDAYS &&
      isPanelMember(loginInfo.current_role)
    ) {
      dispatch(
        getLoggedInUserPanelDetails({
          roleId: loginInfo.role_id,
          periodFrom: new Date(startMonthDate).toISOString(),
          periodTo: new Date(endMonthDate).toISOString(),
          keyValue,
        })
      );
    }
  }, [dispatch, endMonthDate, loginInfo, startMonthDate, keyValue]);
  useEffect(() => {
    if (loginInfo.role_id && !isPanelMember(loginInfo.current_role)) {
      dispatch(
        fetchWeekendDriveByDate({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
          periodFrom: new Date(startWeekDate).toISOString(),
          periodTo: new Date(endWeekDate).toISOString(),
        })
      );
    }
  }, [startWeekDate, endWeekDate, loginInfo]);
  useEffect(() => {
    if (!isPanelMember(loginInfo?.current_role)) {
      dispatch(
        fetchRoundDesignationId({
          competencyId: loginInfo.current_role_competency_id,
          roleId: loginInfo.role_id,
          panelGroupId,
        })
      );
    }
  }, [loginInfo.current_role_competency_id]);
  useEffect(() => {
      if (!isPanelMember(loginInfo?.current_role)) { 
      dispatch(
        fetchRoundDesignationId({
          competencyId: loginInfo?.current_role_competency_id,
          roleId: loginInfo?.role_id,
          panelGroupId,
        })
      );
    }
  }, [dispatch, loginInfo?.current_role, loginInfo?.current_role_competency_id, loginInfo?.role_id, panelGroupId]);
  useEffect(() => {
    setEligibleDesignationList(multiSelectOptions(roundDesignationIds));
  }, [roundDesignationIds]);
  useEffect(() => {
    isSpoc(loginInfo.current_role) &&
      dispatch(
        getMemberListWithPanelData({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
        })
      );
  }, [dispatch, loginInfo]);
  useEffect(() => {
    if (isPanelMember(loginInfo.current_role)) {
      setStateToogle({ ...stateToogle, compact: !stateToogle.compact });
    }
  }, [loginInfo.current_role]);
  useEffect(() => {
    if (
      !isPanelMember(loginInfo.current_role) &&
      keyValue === CHECK_TAB_KEY.WEEKDAYS
    ) {
      dispatch(
        fetchPanelMembersAvailability({
          competencyId: loginInfo.current_role_competency_id,
          roundDesignationIds: eligibleDesignationIds,
          periodFrom: new Date(startWeekDate).toISOString(),
          periodTo: new Date(endWeekDate).toISOString(),
          pageNumber: activePageNumber,
          roleId: loginInfo.role_id,
          query: searchValue,
          slotsProvided: providedSlots,
          roundName: roundsFilter,
          activedesignationId: interviewerDesignationIds,
          panelGroupId: tagsFilter,
          subCompetency: subCompetencyFilter,
          signal: signal,
        })
      );
    }
  }, [
    loginInfo,
    activePageNumber,
    searchValue,
    startWeekDate,
    endWeekDate,
    keyValue,
  ]);
  useEffect(() => {
    const getUpdatedListWithScheduled = weekendDriveList?.filter(
      (item) => INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status
    );
    if (getUpdatedListWithScheduled && getUpdatedListWithScheduled.length) {
      const getDriveDatesFiltered = getUpdatedListWithScheduled?.map(
        (driveData) => ({
          driveDate: driveData.date_of_drive,
          driveId: driveData.id,
        })
      );
      const weekEnd = lastDayOfWeek(new Date(new Date().setHours(0, 0, 0, 0)));
      const weekStart = startOfWeek(new Date(new Date().setHours(0, 0, 0, 0)));
      const getDriveBeforeTodayDate = getDriveDatesFiltered.filter(
        (item) => new Date(item.driveDate).getTime() < new Date().getTime()
      );
      const getDriveAfterWeekendLastDate = getDriveDatesFiltered.filter(
        (item) =>
          new Date(item.driveDate).getTime() > new Date(weekEnd).getTime()
      );

      const getDriveInSameWeek = getDriveDatesFiltered.filter(
        (item) =>
          new Date(item.driveDate).getTime() > new Date(weekStart).getTime() &&
          new Date(item.driveDate).getTime() <= new Date(weekEnd).getTime()
      );

      const storedWeekendDriveDate = localStorage.getItem("weekendDriveDate");
      const dateExists = getUpdatedListWithScheduled
        ?.map((drive) => drive.date_of_drive)
        .includes(storedWeekendDriveDate);
      if (dateExists) {
        setWeekendDriveDate(new Date(storedWeekendDriveDate).toISOString());
      } else {
        if (CHECK_TAB_KEY.WEEKEND === keyValue) {
          if (getDriveInSameWeek && getDriveInSameWeek.length) {
            setWeekendDriveDate(
              new Date(getDriveInSameWeek[0]?.driveDate).toISOString()
            );
          } else if (
            getDriveAfterWeekendLastDate &&
            getDriveAfterWeekendLastDate.length
          ) {
            setWeekendDriveDate(
              new Date(
                getDriveAfterWeekendLastDate[
                  getDriveAfterWeekendLastDate.length - 1
                ]?.driveDate
              ).toISOString()
            );
          } else if (
            getDriveBeforeTodayDate &&
            getDriveBeforeTodayDate.length
          ) {
            setWeekendDriveDate(
              new Date(getDriveBeforeTodayDate[0]?.driveDate).toISOString()
            );
          }
        }
      }
    }
  }, [keyValue, weekendDriveList]);
  useEffect(() => {
    const getUpdatedListWithScheduled = weekendDriveList?.filter(
      (item) => INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status
    );
    const storedWeekendDriveDate = localStorage.getItem("weekendDriveDate");
    const dateExists = getUpdatedListWithScheduled
      ?.map((drive) => drive.date_of_drive)
      .includes(storedWeekendDriveDate);
    if (dateExists) {
      setWeekendDriveDate(new Date(storedWeekendDriveDate).toISOString());
    } else {
      setWeekendDriveDate(new Date().toISOString());
    }
  }, [keyValue]);
  useEffect(() => {
    if (
      weekendDriveList &&
      weekendDriveList.length &&
      CHECK_TAB_KEY.WEEKEND === keyValue
    ) {
      setWeekendDriveListUpdated(
        weekendDriveList?.filter(
          (item) =>
            item.date_of_drive &&
            INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status
        )
      );
    }
  }, [keyValue, weekendDriveList]);
  useEffect(() => {
    if (isHr(loginInfo?.current_role)) {
      dispatch(
        fetchInterviewerDesignationsIds({
          roleId: loginInfo?.role_id,
          competencyId: loginInfo?.current_role_competency_id,
        })
      );
    }
  }, [loginInfo, dispatch]);

  const panelMemberAvailabilityPayload = {
    competencyId: loginInfo.current_role_competency_id,
    roundDesignationIds: eligibleDesignationIds,
    periodFrom: new Date(startWeekDate).toISOString(),
    periodTo: new Date(endWeekDate).toISOString(),
    pageNumber: activePageNumber,
    roleId: loginInfo.role_id,
    query: searchValue,
    slotsProvided: providedSlots,
    panelGroupId: tagsFilter,
    roundName: roundsFilter,
    activedesignationId:interviewerDesignationIds,
    subCompetency: subCompetencyFilter,
  };
  const isPast = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date < currentDate;
  };
  const isDateOrPast = (date) => {
    const currentDate = new Date();
    return date <= currentDate;
  };
  const multiSelectOptions = (roundDesignationIds = []) => {
    return roundDesignationIds.reduce((acc, item) => {
      if (item) {
        acc.push({
          label: `${designationList[item.designation_id]?.name}${
            item.is_pairing ? "(P)" : ""
          }`,
          value: `${item.designation_id}${item.is_pairing ? "-P" : ""}`,
          isPairing: item.is_pairing,
          designationId: item.designation_id,
        });
      }

      return sortArrayOfObjects(acc);
    }, []);
  };
  const getDriveDates = weekendDriveListUpdated?.map((driveData) => ({
    driveDate: driveData.date_of_drive,
    driveId: driveData.id,
    availabilityDate: driveData.date_of_availability_deadline,
  }));

  const tagsBadge = (tags = []) => {
    return (
      <Grid container display="flex" flexWrap="wrap">
        {!!tags.length &&
          tags?.map((tag, index) => (
            <Grid item key={index} style={{ flexBasis: "auto" }}>
              <Chip
                label={tag.tag_name}
                className={styles.individualChipStyle}
              />
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      className={"globalLayoutHeight"}
    >
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <AvailabilityHeader
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          keyValue={keyValue}
          weekendDriveDate={weekendDriveDate}
          setWeekendDriveDate={setWeekendDriveDate}
          startWeekDate={startWeekDate}
          endWeekDate={endWeekDate}
          weekDate={weekDate}
          setWeekDate={setWeekDate}
          setStartWeekDate={setStartWeekDate}
          setEndWeekDate={setEndWeekDate}
          loginInfo={loginInfo}
          competencyId={competencyId}
          setCompetencyId={setCompetencyId}
          startDateOfWeek={startWeekDate}
          endDateOfWeek={endWeekDate}
          isMarkAvailabilityModalOpen={isMarkAvailabilityModalOpen}
          setIsMarkAvailabilityModalOpen={setIsMarkAvailabilityModalOpen}
          roundDesignationId={designationIds}
          activePageNumber={activePageNumber}
          setActivePageNumber={setActivePageNumber}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          slotsProvided={slotsProvided}
          setSlotsProvided={setSlotsProvided}
          roundName={roundName}
          setRoundName={setRoundName}
          activedesignationId={activedesignationId}
          setActiveDesignationId={setActiveDesignationId}
          panelGroupId={panelGroupId}
          setPanelGroupId={setPanelGroupId}
          subCompetency={subCompetency}
          setSubCompetency={setSubCompetency}
          filterIcon={filterIcon}
          setFilterIcon={setFilterIcon}
          designationIds={designationIds}
          setDesignationIds={setDesignationIds}
          eligibleDesignationList={eligibleDesignationList}
          loggedInUserPanelDetails={loggedInUserPanelDetails}
          signal={signal}
          stateToogle={stateToogle}
          setStateToogle={setStateToogle}
          setShowSearch={setShowSearch}
          setPlaceholderName={setPlaceholderName}
          setKeyValue={setKeyValue}
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          weekendDriveListUpdated={weekendDriveListUpdated}
          setWeekendDriveListUpdated={setWeekendDriveListUpdated}
          getDriveDates={getDriveDates}
          weekendDriveSelected={weekendDriveSelected}
          setWeekendDriveSelected={setWeekendDriveSelected}
          selectedRecruiters={selectedRecruiters}
          setSelectedRecruiters={setSelectedRecruiters}
          weekenddesignationIds={weekenddesignationIds}
          setWeekendDesignationIds={setWeekendDesignationIds}
          optionsRecruiterEmail={optionsRecruiterEmail}
          setOptionsRecruiterEmail={setOptionsRecruiterEmail}
          weekendDriveList={weekendDriveList}
          startMonthDate={startMonthDate}
          endMonthDate={endMonthDate}
          monthDate={monthDate}
          setMonthDate={setMonthDate}
          setStartMonthDate={setStartMonthDate}
          setEndMonthDate={setEndMonthDate}
          providedSlots={providedSlots}
          roundsFilter={roundsFilter}
          eligibleDesignationIds={eligibleDesignationIds}
          interviewerDesignationIds={interviewerDesignationIds}
          tagsFilter={tagsFilter}
          subCompetencies={subCompetencyFilter}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        className={"globalLayoutHeight"}
      >
        {activeAccordion === 0 &&
          keyValue === CHECK_TAB_KEY.WEEKDAYS &&
          !isPanelMember(loginInfo.current_role) && (
            <PanelMemberList
              open={open}
              startDateOfWeek={startWeekDate}
              endDateOfWeek={endWeekDate}
              activePageNumber={activePageNumber}
              setActivePageNumber={setActivePageNumber}
              competencyId={competencyId}
              roundDesignationId={designationIds}
              slotsProvided={slotsProvided}
              roundName={roundName}
              activedesignationId={activedesignationId}
              searchValue={searchValue}
              panelGroupId={panelGroupId}
              subCompetency={subCompetency}
              weekFrom={new Date(startWeekDate).toISOString()}
              weekTo={new Date(endWeekDate).toISOString()}
              memberListWithPanelData={memberListWithPanelData}
              signal={signal}
              panelMemberAvailabilityPayload={panelMemberAvailabilityPayload}
              stateToogle={stateToogle}
              setStateToogle={setStateToogle}
              weekendDrivesByDate={weekendDrivesByDate}
              loading={loading}
              userList={userList}
              pageCount={pageCount}
              totalPanelSelectedCount={totalPanelSelectedCount}
              weekendDriveLoading={weekendDriveLoading}
              activeDesignationLoading={activeDesignationLoading}
              isPast={isPast}
              isDateOrPast={isDateOrPast}
              tagsBadge={tagsBadge}
            />
          )}
        {activeAccordion === 1 && keyValue === CHECK_TAB_KEY.WEEKEND && (
          <Weekend
            open={open}
            loginInfo={loginInfo}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
            keyValue={keyValue}
            setKeyValue={setKeyValue}
            weekendDriveDate={weekendDriveDate}
            setWeekendDriveDate={setWeekendDriveDate}
            weekendDriveListUpdated={weekendDriveListUpdated}
            setWeekendDriveListUpdated={setWeekendDriveListUpdated}
            weekendDriveSelected={weekendDriveSelected}
            setWeekendDriveSelected={setWeekendDriveSelected}
            selectedRecruiters={selectedRecruiters}
            setSelectedRecruiters={setSelectedRecruiters}
            weekenddesignationIds={weekenddesignationIds}
            setWeekendDesignationIds={setWeekendDesignationIds}
            optionsRecruiterEmail={optionsRecruiterEmail}
            setOptionsRecruiterEmail={setOptionsRecruiterEmail}
            weekendDriveList={weekendDriveList}
            activePageNumber={activePageNumber}
            setActivePageNumber={setActivePageNumber}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isPast={isPast}
            date={new Date(weekendDriveDate)}
            isDateOrPast={isDateOrPast}
            eligibleDesignationIds={eligibleDesignationIds}
            providedSlots={providedSlots}
            roundsFilter={roundsFilter}
            tagsFilter={tagsFilter}
            subCompetencyFilter={subCompetencyFilter}
            interviewerDesignationIds={interviewerDesignationIds}
            tagsBadge={tagsBadge}
          />
        )}

        {isPanelMember(loginInfo.current_role) &&
          activeAccordion === 0 &&
          keyValue === CHECK_TAB_KEY.WEEKDAYS && (
            <WeekdaysPanelMemberView
              startMonthDate={startMonthDate}
              endMonthDate={endMonthDate}
              monthDate={monthDate}
              setMonthDate={setMonthDate}
              setStartMonthDate={setStartMonthDate}
              setEndMonthDate={setEndMonthDate}
              loginInfo={loginInfo}
              activePageNumber={activePageNumber}
              signal={signal}
              activeAccordion={activeAccordion}
              setActiveAccordion={setActiveAccordion}
              keyValue={keyValue}
              setKeyValue={setKeyValue}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default Availability;
