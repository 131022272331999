import React from "react";

import { Grid, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import calendarIcon from "../../assets/icons/calendar.svg";

export const CalendarIcon = () => {
  return <img src={calendarIcon} alt="calendar-icon"></img>;
};

const DatePickerComponent = ({
  value = null,
  label = null,
  handleChange = null,
  filterDate = null,
  disableDay = null,
  disablePast = null,
  disabled = null,
  maxDate = null,
  minDate = null,
  disabledKeyboardNavigation = null,
  isDateDisabled,
  fullWidth,
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container>
          <DatePicker
            format="DD/M/YYYY"
            slots={{
              openPickerIcon: CalendarIcon,
            }}
            label={label}
            value={dayjs(value)}
            disabledKeyboardNavigation={disabledKeyboardNavigation}
            onChange={(newValue) => {
              handleChange(dayjs(newValue));
            }}
            minDate={dayjs(minDate)}
            disableDay={disableDay}
            disablePast={disablePast}
            disabled={disabled}
            filterDate={filterDate}
            sx={{ width: "100%" }}
            color="primary"
            slotProps={{
              textField: {
                readOnly: true,
                error: false,
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "50vw" }}
                fullWidth={fullWidth}
              />
            )}
            maxDate={dayjs(maxDate)}
            shouldDisableDate={isDateDisabled}
          />
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default DatePickerComponent;
