import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal.js";

import {
  deleteDocument,
  updateDocumentsOrder,
  passingDocumentDetail,
} from "../../../redux/features/Document/documentSlice";

import { List, Link, ListItem, Typography, Grid, Tooltip } from "@mui/material";

import EditIcon from "../../../assets/icons/edit-select-icon.svg";
import DeleteIcon from "../../../assets/icons/bin-icon.svg";

import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";

import { ROLE_TYPES } from "../../../constants";
import styles from "./style.module.scss";

const { isHr, isPanelMember } = ROLE_TYPES;

const EachDocumentSection = ({
  id,
  designationId,
  data,
  designationData,
  display,
  setDisplay,
  editDocument,
  setEditDocument,
}) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state?.authReducer?.loginInfo);

  const [currentId, setCurrentId] = useState(0);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const onDeleteDocument = () => {
    if (deleteItemId) {
      dispatch(
        deleteDocument({
          documentId: deleteItemId,
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
        })
      );
    }
  };
  const onEditDocument = (id) => {
    const documentDataById = data.filter((item) => item.id === id);

    if (display === true && currentId !== id) {
      setDisplay(true);
      setEditDocument(true);
      setCurrentId(id);
    } else {
      setDisplay(!display);
      setEditDocument(!editDocument);
      setCurrentId(id);
    }
    dispatch(
      passingDocumentDetail({
        label: documentDataById[0]?.label,
        link: documentDataById?.[0]?.link,
        id: documentDataById?.[0]?.id,
        competency_id: documentDataById?.[0]?.competency_id,
        designation_id: documentDataById?.[0]?.designation_id,
      })
    );
  };
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const [sourceLink] = data.filter(
      (i, index) => index === parseInt(result.source.index)
    );
    const [targetLink] = data.filter(
      (i, index) => index === parseInt(result.destination.index)
    );

    const direction =
      result.destination.index > result.source.index ? "down" : "up";
    dispatch(
      updateDocumentsOrder({
        toBeUpdateData: { targetLink, sourceLink, direction },
        roleId: loginInfo.role_id,
        competencyId: loginInfo.current_role_competency_id,
      })
    );
  };
  const handleClose = () => {
    setShowDeleteConfirmModal(false);
    setDeleteItemId(null);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={id.toString()}>
          {(provided, snaphot) => (
            <Grid item>
              <Grid item>
                <Typography className={styles.sectionHeading}>
                  {!!Object.entries(designationData).length
                    ? !!designationId
                      ? designationData[designationId]?.name
                      : "Others"
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                className={`${styles.fontFamily}  section-content`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Grid item className={styles.sectionStyle}>
                  <List>
                    {data?.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}
                          isDragDisabled={
                            isHr(loginInfo.current_role) ||
                            isPanelMember(loginInfo.current_role)
                          }
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.eachLink}
                            >
                              {!isHr(loginInfo.current_role) &&
                              !isPanelMember(loginInfo.current_role) ? (
                                <Grid container display={"flex"}>
                                  <Grid item xs={10}>
                                    <Link
                                      className="cursorPointer"
                                      href={item.link}
                                      rel="noreferrer"
                                      target="_blank"
                                      underline="none"
                                    >
                                      {item.label}
                                    </Link>
                                  </Grid>
                                  <Grid item xs={1} className="cursorPointer">
                                  <Tooltip title={'Edit Link'}
                                        arrow={true}
                                        placement="top"
                                        >
                                    <img
                                      src={EditIcon}
                                      alt="s"
                                      onClick={() => onEditDocument(item.id)}
                                    ></img>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item xs={1} className="cursorPointer">
                                      <Tooltip title={'Delete Link'}
                                        arrow={true}
                                        placement="top"
                                        >
                                    <img
                                      alt="d"
                                      src={DeleteIcon}
                                      onClick={() => {
                                        setShowDeleteConfirmModal(true);
                                        setDeleteItemId(item.id);
                                      }}
                                    ></img>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Link
                                  className="cursorPointer"
                                  href={item.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  underline="none"
                                >
                                  <Grid item>{item.label}</Grid>
                                </Link>
                              )}
                              {provided.placeholder}
                            </ListItem>
                          )}
                        </Draggable>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      {showDeleteConfirmModal && (
        <DeleteConfirmationModal
          show={showDeleteConfirmModal}
          setShow={handleClose}
          clickConfirm={onDeleteDocument}
        />
      )}
    </>
  );
};

export default EachDocumentSection;
