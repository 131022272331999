import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreateWeekendDrive from "./CreateWeekendDrive";
import Spinner from "../../../components/Spinner";
import NoData from "../NoData/index";
import Pagination from "../../../components/Pagination/index";
import Icon from "../../../components/SvgIcon/icon";

import { reportWeekendDriveAvailableSlots } from "../../../redux/features/WeekendDrive/weekendDriveSlice";

import {
  Grid,
  Chip,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

import DisableEditIcon from "../../../assets/icons/edit-icon.svg";
import EditIcon from "../../../assets/icons/edit-select-icon.svg";
import SortingIcon from "../../../assets/icons/sort-icon.svg";
import AscendingIcon from "../../../assets/icons/ascending-icon.svg";
import DescendingIcon from "../../../assets/icons/descending-icon.svg";
import DownloadIcon from "../../../assets/icons/download-icon.svg";

import Excel from "exceljs";
import { saveAs } from "file-saver";

import format from "date-fns/format";

import { deriveAbbreviatedName } from "../../../utils/app-utils";
import { extractTime } from "../../../utils/date-utils";
import { getDataFromComments } from "../../../utils/app-utils";
import { isSlotsAvailable } from "../../../utils/eligible-slots-utils";
import {
  ROLE_TYPES,
  SORT_TYPE_CHECK_VALUE,
  INTERVIEW_STATUS_CHECK,
  CANDIDATE_INTERVIEW_STATUS,
  DAY_MINUS_ONE_CONFIRMATION_STATUS,
  DRIVE_DAY_CONFIRMATION_STATUS,
  headersForAvailableSlotsDetailsDataExportWeekend,
  INTERVIEW_STATUS_WEEKEND_DRIVE_FILTER
} from "../../../constants";

import styles from "./style.module.scss";

const { isHr } = ROLE_TYPES;

const WeekendDriveTableDetails = ({
  loginInfo,
  pageCount,
  weekendDriveList,
  activePageNumber,
  setActivePageNumber,
  competencyList,
  filterStatus,
  setEditWeekendDrive,
  setDataEditWeekend,
  dataEditWeekend,
  setHandlePanelDeadlineDateTrigger,
  handlePanelDeadlineDateTrigger,
  setSelectedWeekendDriveId,
  editWeekendDrive,
  selectedWeekendDriveId,
  weekendDriveDate,
  setWeekendDriveDate,
  activedesignationId,
  setActiveDesignationId,
  bookings,
  coordinatorId,
  reason,
  setReason,
  setDateRange,
  activeAccordion,
  dateRange,
  setSlotTimes,
  slotTimes,
  getDesignationData,
  setOptionCompetency,
  optionCompetency,
  optionCompetencyDriveCoordiators,
  setOptionCompetencyDriveCoordinators,
  setFilterStatus,
  panelMemberWeekendAvailabilityFillDate,
  setPanelMemberWeekendAvailabilityFillDate,
  coordinatorList,
  setSelectedDates,
  selectedDates,
  setCoordinatorId,
  activeDesignations,
  interviewStatus,
  setInterviewStatus,
  handleSortType,
  sortBy,
  totalDriveCount,
  loading,
}) => {
  const dispatch = useDispatch();
  const reportData = useSelector(
    (state) => state.weekendDriveReducer?.weekendDriveReport?.bookingData
  );
  const reportDataAvailableSlots = useSelector(
    (state) =>
      state.weekendDriveReducer?.weekendDriveReportForAvailableSlots
        ?.weekendPanelistAvailabilitySlots
  );
  const reportDataSlots = useSelector(
    (state) => state.weekendDriveReducer?.weekendDriveReportForSlots
  );

  const [dataCsv, setDataCsv] = useState([]);
  const [dataCsvSlots, setDataCsvSlots] = useState([]);
  const [showeditWeekendDrive, setShowEditWeekendDrive] = useState(false);
  const [dataCsvAvailableSlots, setDataCsvAvailableSlots] = useState([]);
  const [dateForAvailableSlotReport, setDateForAvailableSlotReport] = useState(
    new Date()
  );
  const [
    isExportButtonAvailableSlotsClicked,
    setIsExportButtonAvailableSlotsClicked,
  ] = useState(false);
  const [headersForAvailableSlotsCsv, setHeadersForAvailableSlotsCsv] =
    useState([]);

  const id = loginInfo.id;
  const workSheetNameForAvailableSlots = "Booking-Weekend-Report";
  const generateCsvFileNameAvailableSlots =
    competencyList &&
    `${
      competencyList[loginInfo.current_role_competency_id]
    }_Weekend_Drive_Available_Slots_${format(
      new Date(dateForAvailableSlotReport),
      "dd-MM-yyyy"
    )}`;

  const data = useCallback(() => {
    const dataForAvaialableSlotsCsv =
      reportDataAvailableSlots && reportDataAvailableSlots.length
        ? reportDataAvailableSlots.map((item) => {
            let obj = {
              panel_email_id: item.email,
              hiring_level: item.hiring_level
                .map((item) => {
                  const round = item.round;
                  const designation = item.designationName;
                  const pairingString = item.Pairing ? "(P)" : "";
                  return `${round}-${designation}${pairingString}`;
                })
                .join(", "),
              availability: item.is_available ? "Available" : "Not Available",
            };
            for (let i = 3; i < headersForAvailableSlotsCsv.length; i++) {
              obj = {
                ...obj,
                [headersForAvailableSlotsCsv[i].key]: isSlotsAvailable(
                  item.slots,
                  headersForAvailableSlotsCsv[i]
                ),
              };
            }
            obj = {
              ...obj,
              available_slots: item.slots?.length,
            };
            return obj;
          })
        : [];
    return dataForAvaialableSlotsCsv;
  }, [headersForAvailableSlotsCsv, reportDataAvailableSlots]);
  const saveExcelForAvailableSlots = useCallback(async () => {
    const workbook = new Excel.Workbook();
    try {
      const fileName = generateCsvFileNameAvailableSlots;
      const worksheet = workbook.addWorksheet(workSheetNameForAvailableSlots);
      worksheet.columns = headersForAvailableSlotsCsv;
      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column) => {
        column.width = column.header?.length + 20;
        column.alignment = { horizontal: "center" };
      });
      const finalData = data();
      finalData.forEach((singleData) => {
        worksheet.addRow(singleData);
      });
      worksheet.views = [
        { state: "frozen", xSplit: 2, ySplit: 0, activeCell: "C1" },
      ];
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      workbook.removeWorksheet(workSheetNameForAvailableSlots);
    }
  }, [data, generateCsvFileNameAvailableSlots, headersForAvailableSlotsCsv]);

  useEffect(() => {
    const dataForCsv =
      reportData && reportData?.length
        ? reportData?.map((item) => {
            let obj = {
              drive_date:
                item.drive_date &&
                format(new Date(item.drive_date), "dd-MMM-yyyy"),
              competency: item.competency,
              panel_email_id: item.panel_email_id,
              paired_email: item.paired_email,
              booked_slot: `${extractTime(item.start_time)} - ${extractTime(
                item.end_time
              )}`,
              recruiter_email: item.recruiter_email,
              hiring_designation: item.hiring_designation,
              candidate_name: item.candidate_name,
              candidate_mobile_number: item.candidate_mobile_number,
              candidate_resume_link: item.candidate_resume_link,
              candidate_email: item.candidate_email,
              round: item.round,
              calendar_event_link: item.calendar_event_link,
              notes: item.notes,
              candidate_interview_status:
                item.candidate_interview_status !== null
                  ? `${
                      CANDIDATE_INTERVIEW_STATUS[
                        item.candidate_interview_status
                      ]
                    }`
                  : "",
              day_minus_one_confirmation:
                item.day_before_drive_confirmation !== null
                  ? `${
                      DAY_MINUS_ONE_CONFIRMATION_STATUS[
                        item.day_before_drive_confirmation
                      ]
                    }`
                  : "",
              drive_day_confirmation:
                item.drive_day_confirmation !== null
                  ? `${
                      DRIVE_DAY_CONFIRMATION_STATUS[item.drive_day_confirmation]
                    }`
                  : "",
            };
            return obj;
          })
        : [];
    setDataCsv(dataForCsv);
  }, [reportData, dispatch]);
  useEffect(() => {
    const dataForAvaialableSlotsCsv =
      reportDataAvailableSlots && reportDataAvailableSlots?.length
        ? reportDataAvailableSlots.flatMap((item) => {
            return item.slots?.map((slot) => ({
              drive_date:
                item.drive_date &&
                format(new Date(item.drive_date), "dd-MMM-yyyy"),
              competency: item.competency,
              panel_email_id: item.email,
              paired_email: item.paired_email,
              booked_slot: `${extractTime(slot.startTime)} - ${extractTime(
                slot.endTime
              )}`,
              recruiter_email: item.recruiter_email,
              hiring_designation: item.hiring_designation,
              candidate_name: item.candidate_name,
            }));
          })
        : [];
    setDataCsvAvailableSlots(dataForAvaialableSlotsCsv);
  }, [reportDataAvailableSlots, dispatch]);
  useEffect(() => {
    if (isExportButtonAvailableSlotsClicked) {
      saveExcelForAvailableSlots();
      setIsExportButtonAvailableSlotsClicked(false);
    }
  }, [isExportButtonAvailableSlotsClicked, saveExcelForAvailableSlots]);
  useEffect(() => {
    const slotsDataCsv =
      reportDataSlots && reportDataSlots?.weekendPanelistSlots?.length
        ? reportDataSlots.weekendPanelistSlots?.map((item) => {
            const result = getDataFromComments(item.user_comments);
            let obj = {
              name: item.name,
              email: item.email,
              designation: item.designation,
              slots_provided: item.slots_provided ?? 0,
              bookings_total: item.bookings_total,
              scheduled: item.scheduled,
              completed: item.completed,
              interviewer_na: item.interviewer_na,
              interviewer_rescheduled: item.interviewer_rescheduled,
              candidate_na: item.candidate_na,
              candidate_rescheduled: item.candidate_rescheduled,
            };
            return obj;
          })
        : [];
    setDataCsvSlots(slotsDataCsv);
  }, [reportDataSlots, dispatch]);

  const checkPermission = (item) => {
    const isCreator = item.created_by_id === id;
    const isCoordinator = item.drive_coordinator_ids?.find(
      (element) => element === id
    );
    if (!isCoordinator && !isCreator) {
      return false;
    } else return true;
  };
  const editButtonFunction = (drive) => {
    if (INTERVIEW_STATUS_CHECK.SCHEDULED === drive.drive_status) {
      const dataforEditWeekendDetail = weekendDriveList?.filter(
        (item) => item.id === drive.id
      );
      const selecteddate =
        drive?.availability_reminder_dates !== null
          ? drive?.availability_reminder_dates
          : [];
      setSelectedWeekendDriveId(dataforEditWeekendDetail[0]?.id);
      setCoordinatorId(dataforEditWeekendDetail[0]?.drive_coordinator_ids);
      setActiveDesignationId(dataforEditWeekendDetail[0]?.designation_ids);
      setSelectedDates(selecteddate);
      setHandlePanelDeadlineDateTrigger(false);
      setEditWeekendDrive(drive?.id);
      setShowEditWeekendDrive(true);
      setSlotTimes(drive?.slots);
    }
  };
  const availableSlotsButtonClick = async (id) => {
    const dataforReportWeekendDetails = weekendDriveList.filter(
      (item) => item.id === id
    );
    if (dataforReportWeekendDetails) {
      const driveSlots = dataforReportWeekendDetails[0]?.slots.reduce(
        (acc, item, index) => {
          if (item) {
            acc.push({
              header: `(Slot${index + 1}) ${extractTime(
                item.startTime
              )} - ${extractTime(item.endTime)}`,
              key:
                new Date(item.startTime).getTime() +
                new Date(item.endTime).getTime(),
              startTime: item.startTime,
              endTime: item.endTime,
            });
          }
          return acc;
        },
        []
      );
      let numberOfAvailableSlots = [
        { header: "# of Slots", key: "available_slots" },
      ];
      setHeadersForAvailableSlotsCsv([
        ...headersForAvailableSlotsDetailsDataExportWeekend,
        ...driveSlots,
        ...numberOfAvailableSlots,
      ]);
      setDateForAvailableSlotReport(
        new Date(dataforReportWeekendDetails[0]?.date_of_drive)
      );
      await dispatch(
        reportWeekendDriveAvailableSlots({
          roleId: loginInfo.role_id,
          driveId: dataforReportWeekendDetails[0]?.id,
        })
      );
    }
    dataCsvAvailableSlots && setIsExportButtonAvailableSlotsClicked(true);
  };
  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };

  const getItems = (drive) => {
    if (
      INTERVIEW_STATUS_CHECK.SCHEDULED === filterStatus &&
      isHr(loginInfo?.current_role)
    ) {
      return (
        <TableCell align="center" className={"tablebody"}>
          <Grid container justifyContent={"center"} alignItems="center">
            {checkPermission(drive) ? (
              <Grid
                item
                xl={12}
                md={12}
                lg={12}
                xs={12}
                className={styles.tooltipAlign}
              >
                <Tooltip title="Edit Weekend Drive" placement="top" arrow>
                  <Typography>
                    <Icon
                      icon={EditIcon}
                      width="20.71px"
                      height="16px"
                      leftPadding="16.2px"
                      rightPadding="16.2px"
                      click={() => editButtonFunction(drive)}
                    />
                  </Typography>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xl={12} md={12} lg={12} xs={12}>
                <Icon
                  icon={DisableEditIcon}
                  width="20.71px"
                  height="16px"
                  leftPadding="16.2px"
                  rightPadding="16.2px"
                  cursor="default"
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
      );
    }
  };
  const showDesignations = (designations) => {
    if (designations?.length) {
      return designations?.map((item, index) => (
        <Tooltip
          title={<span>{item.designationName}</span>}
          arrow
          placement="top"
          enterDelay={250}
        >
          {
            <Chip
              label={
                item.isPairing
                  ? `${deriveAbbreviatedName(item.designationName)}(P)`
                  : `${deriveAbbreviatedName(item.designationName)}`
              }
              className={
                item.isPairing
                  ? `${styles.pairingChipStyle} `
                  : `${styles.individualChipStyle} ${styles.pointer}`
              }
              key={index}
            />
          }
        </Tooltip>
      ));
    }
  };

  if (loading) return <Spinner />;
  if (!loading && !weekendDriveList?.length) return <NoData />;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={"globalLayoutHeight"}
    >
      <Paper className={"globalTableLayoutStyle"}>
        <TableContainer
          className={"globalLayoutHeight globalTableContainerAlign"}
          sx={{ paddingRight: isHr(loginInfo.current_role) ? 5 : 2 }}
        >
          <Table
            aria-label="all-table"
            stickyHeader
            sx={{ paddingRight: "5px" }}
          >
            <TableHead>
              <TableRow className={styles.tableRow}>
                <TableCell
                  className={"tableheading"}
                  onClick={() =>
                    handleSortType(SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE)
                  }
                  align="left"
                >
                  Date of Drive
                  {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE ? (
                    <Icon
                      icon={SortingIcon}
                      width="6.875px"
                      height="11px"
                      leftPadding="5px"
                    />
                  ) : sortBy.sortOrder === "DESC" ? (
                    <Icon
                      icon={DescendingIcon}
                      width="6.875px"
                      height="11px"
                      leftPadding="5px"
                    />
                  ) : (
                    <Icon
                      icon={AscendingIcon}
                      width="6.875px"
                      height="11px"
                      leftPadding="5px"
                    />
                  )}
                </TableCell>
                <TableCell className={"tableheading"} align="left">
                  Panel Availability Deadline
                </TableCell>
                <TableCell className={"tableheading"} align="left">
                  Coordinator(s)
                </TableCell>
                <TableCell className={"tableheading"}>For Positions</TableCell>
                <TableCell className={"tableheading"}>Status</TableCell>
                {INTERVIEW_STATUS_CHECK.CANCELLED === filterStatus && (
                  <TableCell
                    className={"tableheading"}
                    align="center"
                    scope="col"
                  >
                    Drive Cancellation Reason
                  </TableCell>
                )}
                {INTERVIEW_STATUS_CHECK.CANCELLED !== filterStatus && (
                  <TableCell className={"tableheading"} align="center">
                    Report{" "}
                  </TableCell>
                )}

                {INTERVIEW_STATUS_CHECK.SCHEDULED === filterStatus &&
                  isHr(loginInfo?.current_role) && (
                    <TableCell
                      className={"tableheading"}
                      paddingRight={5}
                      marginRight={5}
                      align="center"
                    >
                      Action
                    </TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {weekendDriveList?.map((drive) => (
                <TableRow key={loginInfo.id} sx={{ border: 0 }}>
                  <TableCell component="th" scope="row" className={"tablebody"}>
                    {format(new Date(drive.date_of_drive), "do MMMM, yyyy")}
                  </TableCell>

                  <TableCell align="left" className={"tablebody"}>
                    {" "}
                    {format(
                      new Date(drive.date_of_availability_deadline),
                      "do MMMM, yyyy"
                    )}
                  </TableCell>
                  <TableCell align="left" className={"tablebody"}>
                    {drive.coordinators?.map((data) => (
                      <Typography className={"tablebody"}>{data}</Typography>
                    ))}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`${styles.tags} tablebody`}
                  >
                    <Grid
                      container
                      className={"tablebody"}
                      rowSpacing={0.5}
                      columnSpacing={0.5}
                    >
                      {showDesignations(drive?.designations)}{" "}
                    </Grid>
                  </TableCell>
                  <TableCell align="left" className={"tablebody"}>
                      <Typography className={"tablebody"}>{INTERVIEW_STATUS_WEEKEND_DRIVE_FILTER[drive?.drive_status]}</Typography>
                  </TableCell>

                  {INTERVIEW_STATUS_CHECK.CANCELLED === filterStatus && (
                    <TableCell align="center">
                      <Typography className={"tablebody"}>
                        {drive.comments}
                      </Typography>
                    </TableCell>
                  )}
                  {INTERVIEW_STATUS_CHECK.CANCELLED !== filterStatus && (
                    <TableCell align="center" className={"tablebody"}>
                      <Tooltip
                        title={<span>Download Available Sheet</span>}
                        arrow
                        placement="top"
                        enterDelay={250}
                      >
                        <Typography>
                          {" "}
                          <Icon
                            icon={DownloadIcon}
                            width="16.5px"
                            height="16.77px"
                            click={() => {
                              availableSlotsButtonClick(drive.id);
                            }}
                          />
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}

                  {getItems(drive)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            activePageNumber={activePageNumber}
            userCount={totalDriveCount}
          />
        </TableContainer>
      </Paper>
      {showeditWeekendDrive && editWeekendDrive !== null && (
        <CreateWeekendDrive
          show={showeditWeekendDrive}
          setShow={setShowEditWeekendDrive}
          editWeekendDrive={editWeekendDrive}
          setEditWeekendDrive={setEditWeekendDrive}
          selectedWeekendDriveId={selectedWeekendDriveId}
          setSelectedWeekendDriveId={setSelectedWeekendDriveId}
          weekendDriveDate={weekendDriveDate}
          weekendDriveList={weekendDriveList}
          loginInfo={loginInfo}
          competencyList={competencyList}
          activedesignationId={activedesignationId}
          setActiveDesignationId={setActiveDesignationId}
          bookings={bookings}
          coordinatorId={coordinatorId}
          setCoordinatorId={setCoordinatorId}
          setWeekendDriveDate={setWeekendDriveDate}
          activeDesignations={activeDesignations}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          coordinatorList={coordinatorList}
          panelMemberWeekendAvailabilityFillDate={
            panelMemberWeekendAvailabilityFillDate
          }
          setPanelMemberWeekendAvailabilityFillDate={
            setPanelMemberWeekendAvailabilityFillDate
          }
          setFilterStatus={setFilterStatus}
          setDataEditWeekend={setDataEditWeekend}
          dataEditWeekend={dataEditWeekend}
          filterStatus={filterStatus}
          optionCompetency={optionCompetency}
          setOptionCompetency={setOptionCompetency}
          optionCompetencyDriveCoordiators={optionCompetencyDriveCoordiators}
          setOptionCompetencyDriveCoordinators={
            setOptionCompetencyDriveCoordinators
          }
          getDesignationData={getDesignationData}
          slotTimes={slotTimes}
          setSlotTimes={setSlotTimes}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setReason={setReason}
          activeAccordion={activeAccordion}
          activePageNumber={activePageNumber}
          reason={reason}
          interviewStatus={interviewStatus}
          setInterviewStatus={setInterviewStatus}
        />
      )}
    </Grid>
  );
};

export default WeekendDriveTableDetails;
