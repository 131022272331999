import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import Spinner from "../../../components/Spinner";
import Icon from "../../../components/SvgIcon/icon";

import { Tooltip, Box } from "@mui/material";
import ExportIcon from "../../../assets/icons/export-icon.svg";

import Excel from "exceljs";
import { format } from "date-fns";
import { saveAs } from "file-saver";

import { get } from "../../../utils/app-utils";
import { isEligibleForRound } from "../../../utils/eligible-round-utils";
import { staticHeadersForCsvExport, BASE_URL } from "../../../constants/index";

import styles from "./style.module.scss";

const ExportActiveData = ({
  competencyList,
  competencyId,
  sortBy,
  roundName,
  designationIds,
  searchValue,
  activeDesignations = [],
  dateOfJoining,
  panelGroupId,
  subCompetency,
}) => {
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const designationData = useSelector(
    (state) => state.authReducer?.designation
  );

  const [loading, setLoading] = useState(false);
  const [allPanelData, setallPaneldata] = useState([]);
  const [headersForCsv, setHeadersForCsv] = useState([]);
  const [eligibleDesignationIds, setEligibleDesignationIds] = useState([]);
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);

  const workSheetName = "Slots-Eligibility-Report";
  const generateCsvFileName =
    competencyList &&
    `${
      competencyList[loginInfo?.current_role_competency_id]
    }_eligibility_report_${format(new Date(), "dd-MM-yyyy")}`;

  const dataForCsv = useCallback(() => {
    const dataUpdated =
      allPanelData && allPanelData.length
        ? allPanelData?.map((item) => {
            let obj = {
              emp_id: item.employee_code,
              name: `${item.first_name} ${item.last_name}`,
              email: item.email,
              mobileNumber: item.mobile_number,
              designation: designationData[item.designation_id].name,
              reporting_manager: item.reporting_manager,
              mentor: item.mentor,
              notes: item.comments,
            };

            for (let i = 8; i < headersForCsv.length; i++) {
              obj = {
                ...obj,
                [headersForCsv[i].key]: isEligibleForRound(
                  item.rounds,
                  headersForCsv[i]
                ),
              };
            }
            return obj;
          })
        : [];
    return dataUpdated;
  }, [allPanelData, designationData, headersForCsv]);
  const saveExcelData = useCallback(async () => {
    const workbook = new Excel.Workbook();
    try {
      const fileName = generateCsvFileName;
      const worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = headersForCsv;
      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 22;
        column.alignment = { horizontal: "center" };
      });
      const dataFinalWithAllHeaders = dataForCsv();
      dataFinalWithAllHeaders.forEach((singleData) => {
        worksheet.addRow(singleData);
      });
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
  }, [dataForCsv, generateCsvFileName, headersForCsv]);

  useEffect(() => {
    if (isExportButtonClicked) {
      saveExcelData();
      setIsExportButtonClicked(false);
    }
  }, [isExportButtonClicked, saveExcelData]);
  useEffect(() => {
    const roundDesignations = eligibleDesignationIds.reduce((acc, item) => {
      if (item) {
        acc.push({
          header: `${designationData[item.designation_id]?.name}-R1`,
          key: `${designationData[item.designation_id]?.name
            .toLowerCase()
            .replaceAll(" ", "_")}-R1`,
          roundName: "R1",
          designationId: item.designation_id,
        });
        acc.push({
          header: `${designationData[item.designation_id]?.name}-R2`,
          key: `${designationData[item.designation_id]?.name
            .toLowerCase()
            .replaceAll(" ", "_")}-R2`,
          roundName: "R2",
          designationId: item.designation_id,
        });
        acc.push({
          header: `${designationData[item.designation_id]?.name}-MR`,
          key: `${designationData[item.designation_id]?.name
            .toLowerCase()
            .replaceAll(" ", "_")}-MR`,
          roundName: "MR",
          designationId: item.designation_id,
        });
      }
      return acc;
    }, []);
    setHeadersForCsv([...staticHeadersForCsvExport, ...roundDesignations]);
  }, [competencyId, designationData, eligibleDesignationIds, loginInfo]);

  const fetchPanelMembersForCsv = async () => {
    setLoading(true);
    const subCompetencyIds = subCompetency?.map((item) => item.value);
    const panelCount = await get(`${BASE_URL}/dashboard/panel/all/totalCount`, {
      queryParams: {
        competencyId,
        role_id: loginInfo?.role_id,
        designationIds: JSON.stringify(designationIds),
        searchQuery: searchValue,
        dateOfJoining: JSON.stringify(dateOfJoining),
        panelGroupId: JSON.stringify(panelGroupId),
        roundDesignationIds: JSON.stringify(designationIds),
        activedesignationId: JSON.stringify(activeDesignations),
        subCompetency: JSON.stringify(subCompetencyIds),
        roundName: roundName,
      },
    });
    const response = await get(`${BASE_URL}/dashboard/panel/all`, {
      queryParams: {
        designationIds: JSON.stringify(designationIds),
        query: searchValue,
        dateOfJoining: JSON.stringify(dateOfJoining),
        panelGroupId: JSON.stringify(panelGroupId),
        competencyId,
        roundDesignationIds: JSON.stringify(designationIds),
        sortType: sortBy.sortType,
        sortOrder: sortBy.sortOrder,
        role_id: loginInfo.role_id,
        limit: panelCount.totalCount,
        activedesignationId: JSON.stringify(activeDesignations),
        subCompetency: JSON.stringify(subCompetencyIds),
        roundName: roundName,
      },
    });
    const designationResponse = await get(
      `${BASE_URL}/availability/competency/${competencyId}/round/designationIds`,
      {
        queryParams: {
          role_id: loginInfo.role_id,
          panelGroupId: JSON.stringify(panelGroupId),
        },
      }
    );
    if (response && designationResponse) {
      setallPaneldata(response?.data?.activePanelMembersList);
      const arrayUniqueByDesignationId = [
        ...new Map(
          designationResponse.designationIds?.map((item) => [
            item["designation_id"],
            item,
          ])
        ).values(),
      ];
      setEligibleDesignationIds(arrayUniqueByDesignationId);
      setIsExportButtonClicked(true);
    }
    setLoading(false);
  };

  if (loading) return <Spinner />;
  return (
    <>
      <Tooltip
        title={"Download Active Panel Excel"}
        arrow={true}
        placement="top"
        className={styles.tooltipAlign}
      >
        <Box>
          <Icon
            icon={ExportIcon}
            click={() => fetchPanelMembersForCsv()}
            width={"15.5px"}
            height={"16px"}
            rotation={"rotate(180deg)"}
          />
        </Box>
      </Tooltip>
    </>
  );
};

export default ExportActiveData;
