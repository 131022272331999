import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "../../../../components/Spinner";

import {
  getWeekdaysAvailabilityReminderInactiveDesignations,
  updateWeekdaysAvailabilityReminderInactiveDesignations,
  updateAvailabilityReminder,
  getAvailabilityReminderStatus,
  sendEmailReminder,
} from "../../../../redux/features/Availability/availabilitySlice";

import {
  Modal,
  Typography,
  Button,
  Box,
  Grid,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ROLE_TYPES } from "../../../../constants";

import styles from "./style.module.scss";

const { isHr, isSpoc } = ROLE_TYPES;

const AvailabilityReminderListModal = ({
  show,
  setShow,
  designationData,
  loginInfo,
  IOSSwitch,
  weekdaysAvailabilityReminderToggle,
  updateWeekdaysAvailabilityReminderStatus,
  setWeekdaysAvailabilityReminderToogle,
  showSendReminderBtn,
  setShowSendReminderBtn,
  setIsChanged,
  isChanged,
}) => {
  let dispatch = useDispatch();
  const inactiveDesignations = useSelector(
    (state) =>
      state?.availabilityReducer?.fetchAvailabilityReminderInactiveDesignations
  );
  const loading = useSelector(
    (state) => state?.availabilityReducer?.reminderloading
  );

  const [designations, SetDesignations] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    let desg = [];
    designationData &&
      Object.keys(designationData).forEach((val) => {
        let obj = {
          isChecked: !inactiveDesignations?.includes(parseInt(val)),
          value: val,
        };
        desg = [...desg, obj];
      });
    SetDesignations(desg);
  }, [inactiveDesignations]);
  useEffect(() => {
    const allChecked = designations.every(
      (designation) => designation.isChecked
    );
    setSelectAllChecked(allChecked);
  }, [designations]);
  useEffect(() => {
    try {
      const data = {
        competencyId: loginInfo.current_role_competency_id,
        roleId: loginInfo.role_id,
      };

      dispatch(getWeekdaysAvailabilityReminderInactiveDesignations(data));
    } catch (error) {
      console.log("Error");
    }
  }, [loginInfo.current_role_competency_id, loginInfo.role_id]);
  useEffect(() => {
    if (
      loginInfo.role_id &&
      (isHr(loginInfo.current_role) || isSpoc(loginInfo.current_role))
    ) {
      dispatch(
        getAvailabilityReminderStatus({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
        })
      );
    }
  }, [loginInfo, dispatch]);

  const handleChange = (e, index) => {
    const { value, checked } = e.target;
    designations[index].isChecked = checked;
    let tempUser = designations?.map((designation) =>
      designation[index]?.value === value
        ? { ...designation, isChecked: !checked }
        : designation
    );
    SetDesignations(tempUser);
    setIsChanged(true);
  };
  const handleClose = () => {
    setShow(false);
    setIsChanged(false);
  };
  const onSaveButtonClick = async (e) => {
    try {
      e.preventDefault();
      let inactiveDesignationsArray = [];
      Object.values(designations).forEach((item) => {
        if (item.isChecked === false) {
          inactiveDesignationsArray.push(parseInt(item.value));
        }
      });
      const data = {
        competencyId: loginInfo.current_role_competency_id,
        roleId: loginInfo.role_id,
        inactiveDesignationsArray,
      };
      dispatch(
        updateAvailabilityReminder({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
          isActive: weekdaysAvailabilityReminderToggle,
        })
      );
      if (weekdaysAvailabilityReminderToggle) {
        dispatch(updateWeekdaysAvailabilityReminderInactiveDesignations(data));
      }
      handleClose();
      setIsChanged(false);
    } catch (error) {
      console.log("Error");
    }
  };
  const onConfirmBtnClick = async (e) => {
    onSaveButtonClick(e);
    dispatch(
      sendEmailReminder({
        competencyId: loginInfo.current_role_competency_id,
        roleId: loginInfo?.role_id,
      })
    );
    handleClose();
  };
  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    setSelectAllChecked(checked);
    SetDesignations(
      designations.map((designation) => ({
        ...designation,
        isChecked: true,
      }))
    );
    setIsChanged(true);
  };
  const handleDeSelectAllChange = (e) => {
    const { checked } = e.target;
    setSelectAllChecked(checked);
    SetDesignations(
      designations.map((designation) => ({
        ...designation,
        isChecked: false,
      }))
    );
    setIsChanged(true);
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box className={`${styles.modalBox} ${styles.paddingmodalBox}`}>
        <Grid container spacing={0.75} marginLeft={0.5}>
          <Grid item xs={12} md={12} xl={12} lg={12}>
            <Typography className={styles.reminderListHeading}>
              Edit Reminder Email List{" "}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => handleClose(e)}
              sx={{ position: "absolute", right: 8, top: 13 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          marginLeft={1.5}
          justifyContent={"space-between"}
        >
          {!loading ? (
            <>
              <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} />}
                label="Availability Reminder"
                className={styles.switchStyle}
                disableTypography
                disabled={disabled}
                checked={weekdaysAvailabilityReminderToggle}
                onChange={(e) => {
                  handleSelectAllChange({ target: { checked: false } });
                  updateWeekdaysAvailabilityReminderStatus(e);
                }}
              />
            </>
          ) : (
            <Spinner />
          )}
        </Grid>

        <Grid display={"flex"} justifyContent={"space-between"} margin={1}>
          <Grid item alignItems={"center"} lg={5.5} xl={5.5} xs={5.5} md={5.5}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              className={styles.selectBtn}
              disabled={!weekdaysAvailabilityReminderToggle}
              onClick={(e) => handleSelectAllChange(e)}
            >
              Select All
            </Button>
          </Grid>
          <Grid item alignItems={"center"} lg={5.5} xl={5.5} xs={5.5} md={5.5}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={(e) => handleDeSelectAllChange(e)}
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disabled={!weekdaysAvailabilityReminderToggle}
              className={styles.selectBtn}
            >
              De-Select All
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" alignItems="center">
          <Grid
            container
            spacing={1}
            mb={5}
            style={{ height: 400, maxHeight: 400, overflowY: "auto" }}
          >
            {!loading ? (
              designationData ? (
                Object.entries(designationData)
                  .filter(
                    ([id]) =>
                      !disabled ||
                      (disabled &&
                        designations.find((d) => d.value === id)?.isChecked)
                  )
                  ?.map(([id, { name }], index) => (
                    <Grid
                      key={parseInt(id)}
                      item
                      xl={6}
                      md={6}
                      sx={{ display: "flex" }}
                    >
                      <Checkbox
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                        className={styles.checkbox}
                        checked={
                          weekdaysAvailabilityReminderToggle
                            ? designations[index]?.isChecked || false
                            : false
                        }
                        name={name}
                        disabled={
                          !weekdaysAvailabilityReminderToggle || disabled
                        }
                      />
                      <Typography
                        marginTop={1.5}
                        className={styles.designationList}
                      >
                        {name}
                      </Typography>
                    </Grid>
                  ))
              ) : null
            ) : (
              <Spinner />
            )}
          </Grid>
        </Grid>
        <Grid item display={"flex"} justifyContent={"space-between"}>
          <Grid item lg={5.5} xl={5.5} xs={5.5} md={5.5}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={(e) => onSaveButtonClick(e)}
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              className={styles.saveBtn}
            >
              SAVE
            </Button>
          </Grid>
          <Grid item lg={5.5} xl={5.5} xs={5.5} md={5.5}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={(e) => onConfirmBtnClick(e)}
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disabled={!weekdaysAvailabilityReminderToggle}
              className={styles.saveBtn}
            >
              SAVE & SEND
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AvailabilityReminderListModal;
