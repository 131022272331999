import React from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";

import { deriveAbbreviatedName } from "../../utils/app-utils";

export default function AutoCompleteComponent({
  width,
  options,
  onChange,
  label,
  placeholder,
  selectedValue,
  disabled,
  limitTags,
  chipDisabled = false,
  competencyAbbrivation = false,
  toDisable,
}) {
  const competencyList = useSelector((state) => state.authReducer?.competency);
  let filteredOptions = [];
  if (label !== "Eligibility Designation") {
    filteredOptions = options?.filter(
      (option) =>
        !selectedValue?.some((selected) => selected?.value === option?.value)
    );
  } else {
    
      filteredOptions = options?.filter(
        (option) =>
          !selectedValue?.some((selected) => selected?.designationId === option?.designationId && selected?.isPairing === option?.isPairing )
      );
  
  }

  return (
    <Stack spacing={3} width={width ? width : "100%"}>
      <Autocomplete
        disabled={disabled}
        getOptionLabel={
          competencyAbbrivation
            ? (option) => deriveAbbreviatedName(competencyList[option?.value])
            : (option) => option?.label
        }
        limitTags={limitTags || 2}
        render
        value={selectedValue}
        onChange={(event, newValue, reason) => {
          onChange(newValue, reason);
        }}
        multiple
        id="tags-outlined"
        options={filteredOptions}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            label={label}
            placeholder={placeholder || ""}
          />
        )}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              overflowY: "auto",
              fontSize: "15px",
              fontFamily: "poppins",
            }}
          >
            {children}
          </Paper>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              key={index}
              label={
                competencyAbbrivation
                  ? deriveAbbreviatedName(competencyList[option.value])
                  : option.label
              }
              {...getTagProps({ index })}
              disabled={
                chipDisabled
                  ? toDisable.some((value) => value.value == option.value)
                  : false
              }
            />
          ))
        }
      />
    </Stack>
  );
}
