import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import NoData from "../NoData/index";
import Spinner from "../../../components/Spinner";
import EachDocumentSection from "./EachDocumentSection";

import { fetchDocument } from "../../../redux/features/Document/documentSlice";

import { Grid } from "@mui/material";

const DocumentsList = ({
  display,
  setDisplay,
  editDocument,
  setEditDocument,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.documentReducer?.loading);
  const list = useSelector((state) => state.documentReducer?.list);
  const { loginInfo, designation } = useSelector((state) => state?.authReducer);

  useEffect(() => {
    if (loginInfo.role_id) {
      dispatch(
        fetchDocument({
          competencyId: loginInfo.current_role_competency_id,
          roleId: loginInfo.role_id,
        })
      );
    }
  }, [
    dispatch,
    loginInfo.current_role_competency_id,
    loginInfo.role_id,
    editDocument,
  ]);

  if (loading) return <Spinner />;

  return (
    <>
      <Grid container rowSpacing={4} columnSpacing={6} paddingBottom={2}>
        {list?.length &&
          list?.map(({ id, designationId, data }, index) => {
            return (
              <Grid item lg={6} xs={6} xl={4} md={6} sm={6}>
                <EachDocumentSection
                  key={index}
                  id={id}
                  designationId={designationId}
                  data={data}
                  designationData={designation}
                  display={display}
                  setDisplay={setDisplay}
                  editDocument={editDocument}
                  setEditDocument={setEditDocument}
                />
              </Grid>
            );
          })}
      </Grid>
      {!list?.length && !loading && <NoData />}
    </>
  );
};

export default DocumentsList;
