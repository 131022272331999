import React, { useState } from "react";
import { useSelector } from "react-redux";

import AvailableSlotView from "./AvailableSlotView";
import BookingModal from "./BookingModal/index";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal.js/DeleteConfirmationModal";

import { Tooltip, Chip, Grid } from "@mui/material";

import { validateDate } from "../../../utils/date-utils";
import { ROLE_TYPES } from "../../../constants";

import styles from "./style.module.scss";

const { isHr, isPanelMember, isSpoc } = ROLE_TYPES;

const SlotView = ({
  slotDetailsOfTheDay,
  rounds,
  saveBookingForAvailableSlot,
  weekDate,
  isTodayHoliday,
  email,
  booking,
  user,
  panelMemberAvailabilityPayload,
  defaultCountry,
  setDefaultCountry,
  countryCodeEditable,
  setCountryCodeEditable,
  isWeekendDriveExist,
  isCancelledDrive,
  daysDate,
  isDateOrPast,
  date,
  isPast,
  onShowAvailabilityUpdateModal,
  isSaturday,
}) => {
  const { loginInfo } = useSelector((state) => state?.authReducer);

  const [showBookingModal, setShowBookingModal] = useState(false);
  const [
    showWeekendDriveExistsWarningModal,
    setShowWeekendDriveExistsWarningModal,
  ] = useState(false);
  const [slotAvailablilityId, setSlotAvailablilityId] = useState(null);

  const onShowModal = (slotId) => {
    if (isHr(loginInfo.current_role)) {
      setShowBookingModal(true);
    }
    setSlotAvailablilityId(slotId);
  };
  const weekendDrivesList = isWeekendDriveExist?.map((weekend) => {
    return weekend.designations;
  });
  const weekendDriveDesignations = weekendDrivesList[0]
    ?.map((week) => {
      return week?.designationName;
    })
    .join(",");
  let formattedDate2Array = [];
  let formattedDate1 = "";
  let availableSlotsformattedDates = [];
  let availableDriveDate = "";
  if (user?.slots_not_available) {
    formattedDate1 = daysDate.toISOString();
    formattedDate2Array = user?.slots_not_available?.map((date) => {
      return new Date(date).toISOString();
    });
  }
  if (user?.slots_available) {
    availableDriveDate = daysDate.toISOString();
    availableSlotsformattedDates = user?.slots_available?.map((date) => {
      return new Date(date).toISOString();
    });
  }
  const isLoggedInUser = () => {
    return (
      (isPanelMember(loginInfo.current_role) ||
        isSpoc(loginInfo.current_role)) &&
      parseInt(user.id) === parseInt(loginInfo.id)
    );
  };

  return (
    <>
      {!isTodayHoliday ? (
        !isWeekendDriveExist?.length ? (
          slotDetailsOfTheDay.length ? (
            slotDetailsOfTheDay?.map((slot) => {
              return slot.isAvailable ? (
                <React.Fragment key={slot.slotId}>
                  {slot?.slotTime.map((time) => {
                    return (
                      <AvailableSlotView
                        key={`${time.startTime}-${time.endTime}`}
                        time={time}
                        slot={slot}
                        rounds={rounds}
                        saveBookingForAvailableSlot={
                          saveBookingForAvailableSlot
                        }
                        weekDate={weekDate}
                        user={user}
                        email={email}
                        panelMemberAvailabilityPayload={
                          panelMemberAvailabilityPayload
                        }
                        defaultCountry={defaultCountry}
                        setDefaultCountry={setDefaultCountry}
                        countryCodeEditable={countryCodeEditable}
                        setCountryCodeEditable={setCountryCodeEditable}
                        onShowAvailabilityUpdateModal={
                          onShowAvailabilityUpdateModal
                        }
                        date={date}
                        isDateOrPast={isDateOrPast}
                        isPast={isPast}
                        isSaturday={isSaturday}
                      />
                    );
                  })}
                </React.Fragment>
              ) : (
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Tooltip
                    key={`${slot.slotId}-${slot.dateOfAvailable}`}
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    title={slot.comments ? slot.comments : "Not given"}
                    arrow={true}
                    PopperProps={{
                      disablePortal: true,
                      draggable: false,
                    }}
                  >
                    <Chip
                      label={"Not Available"}
                      pill
                      bg=""
                      key={slot.slotId}
                      style={{
                        outline: "none",
                        boxShadow:
                          (isHr(loginInfo.current_role) && isPast(date)) ||
                          (!isHr(loginInfo.current_role) &&
                            isDateOrPast(date)) ||
                          (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                          (!isHr(loginInfo.current_role) && !isLoggedInUser())
                            ? "none"
                            : "auto",
                      }}
                      disableFocusRipple={
                        (isHr(loginInfo.current_role) && isPast(date)) ||
                        (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                        (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                        (!isHr(loginInfo.current_role) && !isLoggedInUser())
                          ? true
                          : false
                      }
                      disableRipple={
                        (isHr(loginInfo.current_role) && isPast(date)) ||
                        (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                        (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                        (!isHr(loginInfo.current_role) && !isLoggedInUser())
                          ? true
                          : false
                      }
                      disableTouchRipple={
                        (isHr(loginInfo.current_role) && isPast(date)) ||
                        (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                        (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                        (!isHr(loginInfo.current_role) && !isLoggedInUser())
                          ? true
                          : false
                      }
                      className={
                        isHr(loginInfo.current_role) && validateDate(weekDate)
                          ? `${styles.notAvailableStyle} cursorPointer`
                          : `${styles.notAvailableStyle}`
                      }
                      onClick={() => {
                        onShowAvailabilityUpdateModal();
                        onShowModal(slot.slotId);
                      }}
                    />
                  </Tooltip>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Chip
                label={"Not Provided"}
                pill
                bg=" "
                className={
                  isHr(loginInfo.current_role) && validateDate(weekDate)
                    ? `${styles.notProvidedSlotStyle} cursorPointer`
                    : `${styles.notProvidedSlotStyle}`
                }
                style={{
                  pointerEvents:
                    (isHr(loginInfo.current_role) && isPast(date)) ||
                    (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                    (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                    (!isHr(loginInfo.current_role) && !isLoggedInUser())
                      ? "none"
                      : "auto",
                  outline: "none",
                }}
                onClick={() => {
                  !isDateOrPast(date) && onShowAvailabilityUpdateModal();
                  onShowModal();
                }}
              />
            </Grid>
          )
        ) : isCancelledDrive ? (
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Chip
              label={"Not Provided"}
              pill
              bg=" "
              className={
                (
                  isHr(loginInfo.current_role)
                    ? validateDate(weekDate)
                    : isDateOrPast(date)
                )
                  ? `${styles.notProvidedSlotStyle} cursorPointer`
                  : `${styles.notProvidedSlotStyle}`
              }
              style={{
                pointerEvents:
                  (isHr(loginInfo.current_role) && isPast(date)) ||
                  (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                  (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                  (!isHr(loginInfo.current_role) && !isLoggedInUser())
                    ? "none"
                    : "auto",
                outline: "none",
              }}
              onClick={() => {
                onShowAvailabilityUpdateModal();
                onShowModal();
              }}
            />
          </Grid>
        ) : user.rounds.some(
            (round) => weekendDriveDesignations.includes(round.designationName)
          ) ? (
          <>
            {formattedDate2Array?.includes(formattedDate1) ? (
              <Chip
                label={"Not Available"}
                pill
                bg=" "
                className={
                (  isHr(loginInfo.current_role)
                    ? validateDate(weekDate)
                     : isDateOrPast(date))
                    ? `${styles.notAvailableStyle} cursorPointer`
                    : `${styles.notAvailableStyle}`
                }
                style={{
                  pointerEvents:
                    (isHr(loginInfo.current_role) && isPast(date)) ||
                    (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                    (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                    (!isHr(loginInfo.current_role) && !isLoggedInUser())
                      ? "none"
                      : "auto",
                  outline: "none",
                }}
                onClick={() => {
                  !isDateOrPast(date) && onShowAvailabilityUpdateModal();
                  onShowModal();
                }}
              />
            ) : availableSlotsformattedDates?.includes(availableDriveDate) ? (
              <Tooltip
                title={weekendDriveDesignations}
                arrow={true}
                placement="top"
              >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Chip
                    label={"Weekend Drive"}
                    pill
                    bg="danger"
                    className={styles.weekendDriveBadgeStyle}
                  />
                </Grid>
              </Tooltip>
            ) : (
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Chip
                  label={"Not Provided"}
                  pill
                  bg=" "
                  className={
                    isHr(loginInfo.current_role) && validateDate(weekDate)
                      ? `${styles.notProvidedSlotStyle} cursorPointer`
                      : `${styles.notProvidedSlotStyle}`
                  }
                  style={{
                    pointerEvents:
                      (isHr(loginInfo.current_role) && isPast(date)) ||
                      (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                      (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                      (!isHr(loginInfo.current_role) && !isLoggedInUser())
                        ? "none"
                        : "auto",
                    outline: "none",
                  }}
                  onClick={() => {
                    !isDateOrPast(date) && onShowAvailabilityUpdateModal();
                    isHr(loginInfo.current_role) &&
                      setShowWeekendDriveExistsWarningModal(true);
                  }}
                />
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Chip
              label={"Not Provided"}
              pill
              bg=" "
              className={
                isHr(loginInfo.current_role) && validateDate(weekDate)
                  ? `${styles.notProvidedSlotStyle} cursorPointer`
                  : `${styles.notProvidedSlotStyle}`
              }
              style={{
                pointerEvents:
                  (isHr(loginInfo.current_role) && isPast(date)) ||
                  (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
                  (!isHr(loginInfo.current_role) && isSaturday(date)) ||
                  (!isHr(loginInfo.current_role) && !isLoggedInUser())
                    ? "none"
                    : "auto",
                outline: "none",
              }}
              onClick={() => {
                !isDateOrPast(date) && onShowAvailabilityUpdateModal();
                onShowModal();
              }}
            />
          </Grid>
        )
      ) : (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Chip
            label={"Holiday"}
            pill
            bg="danger"
            className={styles.holidayBadgeStyle}
          />
        </Grid>
      )}
      {validateDate(weekDate) &&
        isHr(loginInfo.current_role) &&
        showBookingModal && (
          <BookingModal
            showBookingModal={showBookingModal}
            setShowBookingModal={setShowBookingModal}
            rounds={rounds}
            booking={null}
            user={user}
            weekDate={weekDate}
            panelMemberAvailabilityPayload={panelMemberAvailabilityPayload}
          />
        )}
      {showWeekendDriveExistsWarningModal && (
        <DeleteConfirmationModal
          setShow={setShowWeekendDriveExistsWarningModal}
          show={showWeekendDriveExistsWarningModal}
          clickConfirm={onShowModal}
          showWeekendDriveExistsWarningModalText={true}
        />
      )}
    </>
  );
};

export default SlotView;
