import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SelectDesignations from "./SelectDesignations";

import { getDesignationList } from "../../../redux/features/Authentication/authSlice";

const Designations = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer?.loginInfo);
  const designations = useSelector((state) => state.authReducer?.designation);

  useEffect(() => {
    if (user.role_id) {
      dispatch(
        getDesignationList({
          roleId: user.role_id,
          competencyId: user.current_role_competency_id,
          isDesignationPage: true,
        })
      );
    }
  }, [user, dispatch]);

  let designationsArray = [];
  for (const designation in designations) {
    designationsArray.push(
      designations[designation].name +
        ":" +
        designation +
        ":" +
        designations[designation].abbreviatedName
    );
  }
  designationsArray = designationsArray.sort();
  let designationData = designationsArray.reduce((acc, item) => {
    acc[" " + item.split(":")[1]] = {
      name: item.split(":")[0],
      abbreviatedName: item.split(":")[2],
    };
    return acc;
  }, {});

  return (
    <div>
      <SelectDesignations designationData={designationData} />
    </div>
  );
};

export default Designations;
