import React from "react";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";

import Spinner from "../Spinner";

import { Container, Typography, Card, Button } from "@mui/material";
import { Google } from "@mui/icons-material";

import logo from "../../assets/images/ttn-logo.jpeg";

import config from "./../../../config";
import { getAuthInfo } from "../../utils/app-utils";
import { ROLE_TYPES } from "../../constants";

import styles from "./style.module.scss";

const { isHr, isSpoc, isAdmin, isPanelMember } = ROLE_TYPES;

const Login = () => {
  const authToken = getAuthInfo();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const loading = useSelector((state) => state.authReducer?.loading);

  if (authToken && authToken.userInfo) {
    if (isHr(loginInfo?.current_role)) {
      return <Navigate to="/main/dashboard" />;
    } else if (
      isAdmin(loginInfo?.current_role) ||
      isSpoc(loginInfo?.current_role)
    ) {
      return <Navigate to="/main/roster" />;
    } else if (isPanelMember(loginInfo?.current_role)) {
      return <Navigate to="/main/Availability" />;
    } else {
      return <Navigate to="/login" />;
    }
  }

  if (loading) return <Spinner />;

  return (
    <Container maxWidth={false} className={styles.loginContainer}>
      <Card variant="outlined" className={styles.loginCard}>
        <img src={logo} alt="ttn-logo" className={styles.img} />
        <Typography variant="h5" className={styles.loginHeading}>
          Welcome to iScheduler
        </Typography>
        <Button
          variant="contained"
          size="large"
          href={`${config.baseUrl}/auth?state=new-portal`}
          startIcon={<Google />}
          className={styles.loginButton}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          Login With Google
        </Button>
      </Card>
    </Container>
  );
};

export default Login;
