import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../constants/index";
import { get, post } from "../../../utils/app-utils";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";
const initialState = {
  loading: false,
  report: {
    designationWise: [],
    roundWise: [],
  },
  data: {
    panelistSlots: [],
    bookingData: [],
  },
  exportBookingDataExcel: false,
  exportPanelistDataExcel: false,
  bookingDataPeriodFrom: "",
  bookingDataPeriodTo: "",
  panelistDataExcelPeriodFrom: "",
  panelistDataExcelPeriodTo: "",
};

export const getBookingDataForExport = createAsyncThunk(
  "bookingDataForExport",
  async ({ roleId, from, to, competencyId, fetchSelfBookings = false }) => {
    try {
      const url = `${BASE_URL}/dashboard/competency/${competencyId}/report/booking-details`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { from, to, fetchSelfBookings }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getPanelistDataForExport = createAsyncThunk(
  "panelistDataForExport",
  async ({ roleId, from, to }) => {
    try {
      const url = `${BASE_URL}/dashboard/panelist-slots/report`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { from, to }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getPanelReportByCompetency = createAsyncThunk(
  "panelReportByCompetency",
  async ({ competencyId, roleId, sortType, sortOrder }) => {
    try {
      const url = `${BASE_URL}/dashboard/competency/${competencyId}/report`;
      const response = await get(url, {
        queryParams: { role_id: roleId, sortType, sortOrder },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const reportSlice = createSlice({
  name: "Reports",
  initialState,
  reducers: {
    exportBookingData(state, action) {
      state.exportBookingDataExcel = action.payload.exportBookingDataExcel;
    },
    exportPanelistData(state, action) {
      state.exportPanelistDataExcel = action.payload.exportBookingDataExcel;
    },
    resetReportState() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingDataForExport.pending, (state) => {
        state.loading = true;
        state.exportBookingDataExcel = false;
      })
      .addCase(getBookingDataForExport.fulfilled, (state, action) => {
        state.bookingDataPeriodFrom = action.meta.arg.from ?? "";
        state.bookingDataPeriodTo = action.meta.arg.to ?? "";
        if (action.payload) {
          state.data.bookingData = action.payload?.data?.bookingData ?? [];
          state.loading = false;
          state.exportBookingDataExcel = true;
        }
      })
      .addCase(getBookingDataForExport.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while fetching booking data"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
        state.exportBookingDataExcel = false;
      })
      .addCase(getPanelistDataForExport.pending, (state) => {
        state.loading = true;
        state.exportPanelistDataExcel = false;
      })
      .addCase(getPanelistDataForExport.fulfilled, (state, action) => {
        state.panelistDataExcelPeriodFrom = action.meta.arg.from ?? "";
        state.panelistDataExcelPeriodTo = action.meta.arg.to ?? "";
        if (action.payload) {
          state.data.panelistSlots = action.payload?.data?.panelistSlots ?? [];
          state.loading = false;
          state.exportPanelistDataExcel = true;
        }
      })
      .addCase(getPanelistDataForExport.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while fetching panelist data"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
        state.exportPanelistDataExcel = false;
      })
      .addCase(getPanelReportByCompetency.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPanelReportByCompetency.fulfilled, (state, action) => {
        if (action.payload) {
          state.report.designationWise =
            action.payload?.report?.designationWise ?? [];
          state.report.roundWise = action.payload?.report?.roundWise ?? [];
          state.loading = false;
        }
      })
      .addCase(getPanelReportByCompetency.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while fetching panel report"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      });
  },
});
export const { exportBookingData, exportPanelistData, resetReportState } =
  reportSlice.actions;
export default reportSlice.reducer;
