import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SelectSlots from "./SelectSlots";
import DriveDetails from "./DriveDetails";
import Spinner from "../../../../components/Spinner";
import Icon from "../../../../components/SvgIcon/icon";
import { ColorlibConnector } from "../../../../components/Stepper";
import DeleteDesignationConfirmModal from "../DeleteDesignationConfirmModal";

import {
  editWeekendDriveForSlot,
  saveWeekendDriveForSlot,
  fetchWeekendDriveBookingByCompetency,
} from "../../../../redux/features/WeekendDrive/weekendDriveSlice";

import {
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Stepper,
  Step,
  StepButton,
  IconButton,
  Modal,
} from "@mui/material";

import AddIcon from "../../../../assets/icons/add-icon.svg";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

import {
  isSaturday,
  nextSaturday,
  parse,
  differenceInMilliseconds,
  compareAsc,
  format,
} from "date-fns";

import { dateToString, extractTime } from "../../../../utils/date-utils";
import {
  stepsWeekendDrive,
  INTERVIEW_STATUS_CHECK,
} from "../../../../constants";

import styles from "./style.module.scss";

const CreateWeekendDrive = ({
  show,
  setShow,
  editWeekendDrive,
  setEditWeekendDrive,
  selectedWeekendDriveId,
  setSelectedWeekendDriveId,
  weekendDriveDate,
  weekendDriveList,
  loginInfo,
  competencyList,
  activedesignationId,
  bookings,
  coordinatorId,
  setCoordinatorId,
  setWeekendDriveDate,
  activeDesignations,
  setActiveDesignationId,
  setSelectedDates,
  coordinatorList,
  panelMemberWeekendAvailabilityFillDate,
  setPanelMemberWeekendAvailabilityFillDate,
  setFilterStatus,
  setDataEditWeekend,
  dataEditWeekend,
  filterStatus,
  selectedDates,
  optionCompetency,
  setOptionCompetency,
  optionCompetencyDriveCoordiators,
  setOptionCompetencyDriveCoordinators,
  getDesignationData,
  slotTimes,
  setSlotTimes,
  dateRange,
  setDateRange,
  setReason,
  activeAccordion,
  activePageNumber,
  reason,
  interviewStatus,
  setInterviewStatus,
  updatedBookings,
  setActiveAccordion,
  checkWeekendBookings,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.rosterReducer?.loading);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([false, false]);
  const [open, setOpen] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [error, setError] = useState(false);
  const [deletedDesignations, setDeletedDesignations] = useState([]);
  const [isSendMail, setIsSendMail] = useState(true);
  const [handlePanelDeadlineDateTrigger, setHandlePanelDeadlineDateTrigger] =
    useState(false);
  const [designationSelectedOnUpdate, setDesignationSelectedOnUpdate] =
    useState([]);
  const [
    showDeleteDesignationConfirmModal,
    setShowDeleteDesignationConfirmModal,
  ] = useState(false);

  useEffect(() => {
    let difference = [];
    const result =
      activedesignationId && activedesignationId?.map((item) => item.value);
    let designationIdSelected =
      dataEditWeekend && dataEditWeekend[0]?.designation_ids;
    setDesignationSelectedOnUpdate(designationIdSelected);
    difference = designationSelectedOnUpdate?.filter(
      (x) => !result.includes(x)
    );
    setDeletedDesignations(difference);
  }, [activedesignationId, dataEditWeekend, designationSelectedOnUpdate]);
  useEffect(() => {
    if (selectedWeekendDriveId !== null) {
      const updatedData = weekendDriveList.filter(
        (item) => item.id === selectedWeekendDriveId
      );
      setDataEditWeekend(updatedData);
    }
  }, [selectedWeekendDriveId, weekendDriveList]);
  useEffect(() => {
    const data = getDesignationData();
    if (data?.length) {
      const optionDesignation = data?.map((item) => {
        return {
          label: item[1].name,
          value: parseInt(item[0]),
        };
      });
      setOptionCompetency(optionDesignation);
    } else {
      setOptionCompetency([]);
    }
  }, [getDesignationData]);
  useEffect(() => {
    const coordinatorListUpdated = Object.entries(coordinatorList);
    if (coordinatorListUpdated?.length) {
      const optionCoordinators = coordinatorListUpdated?.map((item) => {
        return {
          label:
            item[1].first_name +
            " " +
            item[1].last_name +
            "( " +
            item[1].email +
            " )",
          value: parseInt(item[1].id),
        };
      });
      setOptionCompetencyDriveCoordinators(optionCoordinators);
    } else {
      setOptionCompetencyDriveCoordinators([]);
    }
  }, [coordinatorList]);
  useEffect(() => {
    setSlotTimes([
      {
        startTime: new Date().setHours(10, 0, 0, 0),
        endTime: new Date().setHours(11, 15, 0, 0),
      },
      {
        startTime: new Date().setHours(11, 15, 0, 0),
        endTime: new Date().setHours(12, 30, 0, 0),
      },
      {
        startTime: new Date().setHours(12, 30, 0, 0),
        endTime: new Date().setHours(13, 45, 0, 0),
      },
      {
        startTime: new Date().setHours(14, 30, 0, 0),
        endTime: new Date().setHours(15, 45, 0, 0),
      },
      {
        startTime: new Date().setHours(15, 45, 0, 0),
        endTime: new Date().setHours(17, 0, 0, 0),
      },
      {
        startTime: new Date().setHours(17, 0, 0, 0),
        endTime: new Date().setHours(18, 0, 0, 0),
      },
      {
        startTime: new Date().setHours(18, 0, 0, 0),
        endTime: new Date().setHours(19, 0, 0, 0),
      },
    ]);
  }, []);

  const handleClose = () => {
    setShow(false);
    setEditWeekendDrive(null);
  };
  const handleChangeDate = (date, event) => {
    const selectedDate = new Date(date);
    if (isSaturday(selectedDate)) {
      setWeekendDriveDate(selectedDate);
      setPanelMemberWeekendAvailabilityFillDate(
        new Date(selectedDate).setFullYear(
          new Date(selectedDate).getFullYear(),
          new Date(selectedDate).getMonth(),
          new Date(selectedDate).getDate() - 4
        )
      );
    } else {
      let value = nextSaturday(new Date());
      setWeekendDriveDate(value);
      setPanelMemberWeekendAvailabilityFillDate(
        new Date(value).setFullYear(
          new Date(value).getFullYear(),
          new Date(value).getMonth(),
          new Date(value).getDate() - 4
        )
      );
    }
    if (editWeekendDrive === null) {
      checkWeekendBookings(date);
    }
    setSelectedDates([]);
  };
  const handleChangeDateAvailability = (date) => {
    setPanelMemberWeekendAvailabilityFillDate(date);
    setSelectedDates([]);
    setHandlePanelDeadlineDateTrigger(!handlePanelDeadlineDateTrigger);
  };
  const handleNext = () => {
    const newCompleted = [...completed];
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setError(false);
    setErrorMessage("")
    setActiveStep(0);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    e.stopPropagation();
    setSelectedCardIndex(null);

    const startTimes = slotTimes?.map((time) => extractTime(time.startTime))
    const hasDuplicates = startTimes.some((element, index) => startTimes.indexOf(element) !== index);
    if (hasDuplicates) {
      setError(true);
      setErrorMessage("Time slot already exists")
      return
    }

    if (editWeekendDrive === null) {
      dispatch(
        saveWeekendDriveForSlot({
          filterdriveStatus: filterStatus,
          pageNumber: activePageNumber,
          isPaginated: true,
          driveDate: new Date(weekendDriveDate).toISOString(),
          designationIds: activedesignationId,
          coordinatorIds: coordinatorId,
          driveStatus: interviewStatus,
          slotTime: slotTimes?.length
            ? slotTimes?.map((item) => ({
                startTime: dateToString(item.startTime),
                endTime: dateToString(item.endTime),
              }))
            : [],
          competencyId: loginInfo.current_role_competency_id,
          roleId: loginInfo.role_id,
          availabilityDeadlineDate: new Date(
            panelMemberWeekendAvailabilityFillDate
          ).toISOString(),
          availabilityReminderDates: selectedDates,
          dispatch,
        })
      );
      setEditWeekendDrive(null);
      handleClose();
    } else if (
      editWeekendDrive !== null &&
      ((reason && reason?.length > 1) ||
        interviewStatus === "COMPLETED" ||
        interviewStatus === "SCHEDULED")
    ) {
      dispatch(
        editWeekendDriveForSlot({
          filterdriveStatus: filterStatus,
          pageNumber: activePageNumber,
          isPaginated: true,
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
          slotTime: slotTimes?.length
            ? slotTimes?.map((item) => ({
                startTime: dateToString(item.startTime),
                endTime: dateToString(item.endTime),
              }))
            : [],
          driveStatus: interviewStatus,
          designationIds: activedesignationId,
          coordinatorIds: coordinatorId,
          updateId: selectedWeekendDriveId,
          reason: reason,
          isSendMail: isSendMail,
          driveDate: new Date(weekendDriveDate).toISOString(),
          weekendDriveId: selectedWeekendDriveId,
          availabilityDeadlineDate: new Date(
            panelMemberWeekendAvailabilityFillDate
          ).toISOString(),
          availabilityReminderDates: selectedDates,
          dispatch,
        })
      );
      handleClose();
      setEditWeekendDrive(null);
    } else {
      setError(true);
    }
  };
  const toggleTimePanel = (index) => {
    if (INTERVIEW_STATUS_CHECK.SCHEDULED === interviewStatus) {
      setSelectedCardIndex(index);
    }
  };
  const confirmTimeSlot = (index) => {
    if (INTERVIEW_STATUS_CHECK.SCHEDULED === interviewStatus) {
      setSelectedCardIndex(null);
    }
  };
  const compareTime = (time1, time2) => {
    const parsedTime1 = parse(time1, "hh:mm aa", new Date());
    const parsedTime2 = parse(time2, "hh:mm aa", new Date());
    const comparisonResult = compareAsc(parsedTime1, parsedTime2);
    if (comparisonResult === -1) {
      return -1;
    } else if (comparisonResult === 1) {
      return 1;
    } else {
      return 0;
    }
  };
  const getTimeGap = (time1, time2) => {
    const parsedTime1 = parse(time1, "hh:mm aa", new Date());
    const parsedTime2 = parse(time2, "hh:mm aa", new Date());
    const durationInMilliseconds = differenceInMilliseconds(
      parsedTime2,
      parsedTime1
    );
    const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
    return durationInMinutes;
  };
  const validateSlotTime = (startTime, endTime, newValue, timeFlag) => {
    const getStartTime = extractTime(startTime);
    const getEndTime = extractTime(endTime);
    const getNewValueTime = extractTime(newValue);
    if (
      timeFlag === "endTime" &&
      compareTime(getNewValueTime, getStartTime) === -1
    ) {
      setErrorMessage("End time can not be smaller than start time");
      setOpen(true);
      return false;
    } else if (
      timeFlag === "endTime" &&
      getTimeGap(getStartTime, getNewValueTime) < 60
    ) {
      setErrorMessage("Set 1 hour gap from start date");
      setOpen(true);
      return false;
    } else if (
      timeFlag === "startTime" &&
      compareTime(getNewValueTime, getEndTime) === 1
    ) {
      setErrorMessage("Start time can not be greater than end time");
      setOpen(true);
      return false;
    } else if (
      timeFlag === "startTime" &&
      getTimeGap(getNewValueTime, getEndTime) < 60
    ) {
      setErrorMessage("Set 1 hour gap from end date");
      setOpen(true);
      return false;
    }
    return true;
  };
  const compareDate = (date) => {
    const currentDate = new Date();
    const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");
    const formattedDriveDate = format(date, "yyyy-MM-dd");
    if (formattedCurrentDate < formattedDriveDate) {
      return 1;
    } else if (formattedCurrentDate > formattedDriveDate) {
      return -1;
    } else {
      return 0;
    }
  };
  const addSlot = () => {
    let formatTime;
    if (slotTimes?.length === 0) formatTime = "10:00";
    else
      formatTime = new Date(
        slotTimes[slotTimes?.length - 1]?.endTime
      ).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
    const hourCount = parseInt(formatTime.split(":")[0]);
    const minuteCount = parseInt(formatTime.split(":")[1]);
    setSlotTimes([
      ...slotTimes,
      {
        startTime: new Date().setHours(hourCount, minuteCount, 0, 0),
        endTime: new Date().setHours(hourCount + 1, minuteCount, 0, 0),
      },
    ]);
    setSelectedCardIndex(slotTimes?.length);
  };
  const onDeleteSlot = (index) => {
    if (INTERVIEW_STATUS_CHECK.SCHEDULED === interviewStatus) {
      setSelectedCardIndex(null);
      const newFields = [...slotTimes];
      newFields.splice(index, 1);
      setSlotTimes(() => newFields);
      if (!newFields?.length);
    }
  };
  const onSetOrUpdateSlotTime = (startTime, endTime, index) => {
    if (INTERVIEW_STATUS_CHECK.SCHEDULED === interviewStatus) {
      const updatedSlotTimes = slotTimes?.map((data, i) => {
        if (index === i) {
          return {
            ...data,
            startTime: startTime ? startTime : data.startTime,
            endTime: endTime ? endTime : data.endTime,
          };
        }
        return data;
      });
      setSlotTimes(updatedSlotTimes);
    }
  };
  const openDeleteBookingModal = (value) => {
    if (
      ((deletedDesignations &&
        deletedDesignations?.length &&
        INTERVIEW_STATUS_CHECK.SCHEDULED === interviewStatus) ||
        (INTERVIEW_STATUS_CHECK.CANCELLED === interviewStatus &&
          reason !== null &&
          reason?.length)) &&
      editWeekendDrive !== null
    ) {
      setShowDeleteDesignationConfirmModal(true);
      dispatch(
        fetchWeekendDriveBookingByCompetency({
          competencyId: loginInfo.current_role_competency_id,
          driveStatus: value,
          periodTo: new Date(
            new Date(dataEditWeekend[0].date_of_drive).setHours(23, 59, 59, 59)
          ).toISOString(),
          periodFrom: new Date(
            new Date(dataEditWeekend[0].date_of_drive).setHours(0, 0, 0, 0)
          ).toISOString(),
          roleId: loginInfo.role_id,
          designationIds:
            INTERVIEW_STATUS_CHECK.SCHEDULED === value
              ? JSON.stringify(deletedDesignations)
              : [],
        })
      );
    } else if (editWeekendDrive === null && updatedBookings?.length > 0) {
      setShowDeleteDesignationConfirmModal(true);
    } else {
      setShowDeleteDesignationConfirmModal(false);
    }
  };

  if (loading) return <Spinner />;

  return (
    <Modal open={show} onClose={handleClose}>
      <Box className={`${styles.modalBox}`}>
        <Grid container>
          <Grid item xs={10} md={10} xl={10} lg={10}>
            <Typography variant="h6" gutterBottom pt={3} pl={3}>
              {selectedWeekendDriveId !== null && editWeekendDrive !== null
                ? "Update Weekend Drive"
                : "Create Weekend Drive"}
            </Typography>
          </Grid>
          <Grid item xs={2} md={2} xl={2} lg={2} p={2.5}>
            <IconButton
              onClick={(e) => handleClose(e)}
              sx={{
                position: "absolute",
                right: 18,
                top: 36,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />
        <Grid
          container
          m={0}
          pr={2}
          spacing={3}
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            maxHeight: "30.5rem",
            height: "32rem",
          }}
        >
          {interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED && (
            <Grid item xs={11} md={11} lg={11} xl={11}>
              <Stepper
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {stepsWeekendDrive?.map((step, index) => (
                  <Step key={step.key} completed={completed[step.key]}>
                    <StepButton color="inherit">{step.value}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          )}
          {(interviewStatus === INTERVIEW_STATUS_CHECK.CANCELLED ||
            interviewStatus === INTERVIEW_STATUS_CHECK.COMPLETED) &&
            selectedWeekendDriveId !== null &&
            editWeekendDrive !== null && (
              <Grid item xs={11} md={11} lg={11} xl={11}>
                <Stepper activeStep={activeStep}>
                  <Step key={stepsWeekendDrive[0].key}>
                    <StepButton color="inherit">
                      {stepsWeekendDrive[0].value}
                    </StepButton>
                  </Step>
                </Stepper>
              </Grid>
            )}
          <Grid item xs={11.5} md={11.5} lg={11.5} xl={11.5}>
            {activeStep === 0 && (
              <DriveDetails
                bookings={bookings}
                updatedBookings={updatedBookings}
                onNext={handleNext}
                weekendDriveDate={weekendDriveDate}
                setWeekendDriveDate={setWeekendDriveDate}
                handleChangeDate={handleChangeDate}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
                panelMemberWeekendAvailabilityFillDate={
                  panelMemberWeekendAvailabilityFillDate
                }
                setPanelMemberWeekendAvailabilityFillDate={
                  setPanelMemberWeekendAvailabilityFillDate
                }
                compareDate={compareDate}
                interviewStatus={interviewStatus}
                setInterviewStatus={setInterviewStatus}
                handleChangeDateAvailability={handleChangeDateAvailability}
                activedesignationId={activedesignationId}
                setActiveDesignationId={setActiveDesignationId}
                optionCompetency={optionCompetency}
                coordinatorId={coordinatorId}
                setCoordinatorId={setCoordinatorId}
                optionCompetencyDriveCoordiators={
                  optionCompetencyDriveCoordiators
                }
                selectedDates={selectedDates}
                dateRange={dateRange}
                setDateRange={setDateRange}
                dataEditWeekend={dataEditWeekend}
                handlePanelDeadlineDateTrigger={handlePanelDeadlineDateTrigger}
                setSelectedDates={setSelectedDates}
                setSlotTimes={setSlotTimes}
                reason={reason}
                setReason={setReason}
                setOptionCompetency={setOptionCompetency}
                coordinatorList={coordinatorList}
                setOptionCompetencyDriveCoordinators={
                  setOptionCompetencyDriveCoordinators
                }
                competencyList={competencyList}
                getDesignationData={getDesignationData}
                activeAccordion={activeAccordion}
                editWeekendDrive={editWeekendDrive}
                setEditWeekendDrive={setEditWeekendDrive}
                activePageNumber={activePageNumber}
                loginInfo={loginInfo}
                slotTimes={slotTimes}
                selectedWeekendDriveId={selectedWeekendDriveId}
                setActiveAccordion={setActiveAccordion}
                deletedDesignations={deletedDesignations}
                weekendDriveList={weekendDriveList}
                setSelectedWeekendDriveId={setSelectedWeekendDriveId}
                openDeleteBookingModal={openDeleteBookingModal}
                showDeleteDesignationConfirmModal={
                  showDeleteDesignationConfirmModal
                }
                isSendMail={isSendMail}
                setIsSendMail={setIsSendMail}
              />
            )}
            {activeStep === 1 &&
              interviewStatus !== INTERVIEW_STATUS_CHECK.CANCELLED && (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    padding={1}
                    justifyContent="space-between"
                    className={styles.slotsContainer}
                  >
                    <Grid item display="flex" justifyContent="space-between">
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <Typography className={styles.slotsHeading}>
                          Slots For Drive
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        lg={6}
                        xl={6}
                        justifyContent={"end"}
                        display={"flex"}
                        onClick={() => addSlot()}
                      >
                        <Icon
                          icon={AddIcon}
                          justifyContent="end"
                          width="16px"
                          height="14px"
                          topPadding="2px"
                          leftPadding="14px"
                          rightPadding="16.2px"
                        />
                        <Typography className={styles.addSlotHeading}>
                          ADD SLOT
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} marginTop={1}>
                      {slotTimes &&
                        slotTimes?.map((slot, index) => (
                          <Grid
                            item
                            key={index}
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Box
                              marginBottom={1}
                              position="relative"
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <SelectSlots
                                interviewStatus={interviewStatus}
                                editWeekendDrive={editWeekendDrive}
                                index={index}
                                selectedCardIndex={selectedCardIndex}
                                startTime={slot.startTime}
                                endTime={slot.endTime}
                                onSetOrUpdateSlotTime={onSetOrUpdateSlotTime}
                                onDeleteSlot={onDeleteSlot}
                                toggleTimePanel={toggleTimePanel}
                                validateSlotTime={validateSlotTime}
                                confirmTimeSlot={confirmTimeSlot}
                                showDeleteDesignationConfirmModal={
                                  showDeleteDesignationConfirmModal
                                }
                                bookings={bookings}
                                openDeleteBookingModal={openDeleteBookingModal}
                              />
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    spacing={1}
                    marginTop={1}
                  >
                    {showDeleteDesignationConfirmModal &&
                      editWeekendDrive === null && (
                        <DeleteDesignationConfirmModal
                          bookings={bookings}
                          interviewStatus={interviewStatus}
                          editWeekendDrive={editWeekendDrive}
                        />
                      )}
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid
          container
          marginLeft={3}
          marginTop={1}
        >
          {
            error ? (<Typography color={'#5F2120'} fontFamily={'poppins'} fontSize={'15px'}>{errorMessage}</Typography>) : (<></>)
          }
        </Grid>

        <Grid
          container
          marginLeft={0}
          pr={5}
          spacing={3}
          marginBottom={2}
          marginTop={1}
        >
          <Grid item lg={6} xl={6} xs={6} md={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={(e) => handleBack(e)}
              disabled={activeStep === 0}
              className={
                activeStep === 0
                  ? `${styles.backBtnDisable}`
                  : `${styles.backBtn}`
              }
              disableFocusRipple
              disableRipple
              disableHoverRipple
              disableTouchRipple
              disableElevation
            >
              Back
            </Button>
          </Grid>
          <Grid item lg={6} xl={6} xs={6} md={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
              disabled={
                !activedesignationId?.length ||
                !coordinatorId?.length ||
                !slotTimes.length ||
                (interviewStatus === INTERVIEW_STATUS_CHECK.CANCELLED &&
                  !reason)
              }
              className={
                !activedesignationId?.length ||
                !coordinatorId?.length ||
                !slotTimes.length ||
                (interviewStatus === INTERVIEW_STATUS_CHECK.CANCELLED &&
                  !reason)
                  ? `${styles.saveBtnDisable}`
                  : `${styles.saveBtn}`
              }
              onClick={(e) =>
                activeStep === 1 ||
                (activeStep === 0 &&
                  (interviewStatus === INTERVIEW_STATUS_CHECK.CANCELLED ||
                    interviewStatus === INTERVIEW_STATUS_CHECK.COMPLETED))
                  ? handleSubmit(e)
                  : handleNext()
              }
            >
              {activeStep === 1 ||
              (activeStep === 0 &&
                (interviewStatus === INTERVIEW_STATUS_CHECK.CANCELLED ||
                  interviewStatus === INTERVIEW_STATUS_CHECK.COMPLETED))
                ? selectedWeekendDriveId !== null && editWeekendDrive !== null
                  ? "Update "
                  : "Save "
                : "Next"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CreateWeekendDrive;
