import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchCommentsForUser,
  fetchUpcomingBookings,
} from "../../../redux/features/Roster/rosterSlice";

import NoData from "../../Main/NoData/index";
import AddCommentModal from "./AddCommentModal";
import AddToPanelModal from "./AddToPanelModal";
import Icon from "../../../components/SvgIcon/icon";
import AssignUserRoleModal from "./AssignUserRoleModal";
import Spinner from "../../../components/Spinner/index";
import Pagination from "../../../components/Pagination/index";
import DeleteBookingConfirmModal from "./DeleteBookingConfirmModal";
import PanelDetailsView from "../../../components/PanelDetailsView/PanelDetailsView";

import {
  Grid,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CommentsActive from "../../../assets/icons/active-comments.svg";
import CommentsInactive from "../../../assets/icons/inactive-comments.svg";
import AddUserIcon from "../../../assets/icons/add-user-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import SortingIcon from "../../../assets/icons/sort-icon.svg";
import AscendingIcon from "../../../assets/icons/ascending-icon.svg";
import DescendingIcon from "../../../assets/icons/descending-icon.svg";

import { extractDate } from "../../../utils/app-utils";
import { ROLE_TYPES, SORT_TYPE_CHECK_VALUE } from "../../../constants";

import styles from "./style.module.scss";
import themes from "../../../theme/index";

const { isSpoc, isAdmin, isHr } = ROLE_TYPES;

const TableComp = ({
  keyvalue,
  setKeyvalue,
  activeAccordion,
  setActiveAccordion,
  allUsersList,
  activePageNumber,
  setActivePageNumber,
  user,
  selectedPanel,
  setSelectedPanel,
  setShowAddCommentModal,
  showAddCommentModal,
  commentsForUniqueUser,
  commentsLoading,
  handleSortType,
  subcompetency,
  sortBy,
  setSortBy,
  signal,
  joiningDate,
  designationIds,
  competencyId,
  setCompetencyId,
  gender,
  searchValue,
  competencyList,
  loading,
  handleTabsChange,
  competencyIdFilter,
  isFilterApplied,
  interviewerDesignation,
  adminLoading,
}) => {
  const pageCount = allUsersList?.pageCount;
  const allUserCount = allUsersList?.userCount;

  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.rosterReducer?.bookings);
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);

  const [roleType, setRoleType] = useState("");
  const [removeFromPanelId, setRemoveFromPanelId] = useState(0);
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [selectedAdminPanel, setSelectedAdminPanel] = useState(null);
  const [showAddToPanelModal, setShowAddToPanelModal] = useState(false);
  const [showDeleteBookingConfirmModal, setShowDeleteBookingConfirmModal] =
    useState(false);

  useEffect(() => {
    return () => {
      setSelectedAdminPanel(null);
      setRoleType("");
    };
  }, []);

  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const handleAddCommentModal = (item) => {
    setSelectedPanel({ ...item, key: "ALL" });
    setCompetencyId(item.competency_id);
    setShowAddCommentModal(true);
    dispatch(
      fetchCommentsForUser({
        roleId: user.role_id,
        userId: item?.id,
      })
    );
  };
  const handleAddToPanel = (data) => {
    setSelectedPanel(data);
    setShowAddToPanelModal(true);
  };
  const handleCreateUserRole = (selectedUserData) => {
    setSelectedAdminPanel(selectedUserData);
    setShowCreateRoleModal(true);
  };
  const onRemoveUserFromPanel = (id) => {
    if (id) {
      setRemoveFromPanelId(id);
      setShowDeleteBookingConfirmModal(true);
      dispatch(
        fetchUpcomingBookings({
          roleId: user.role_id,
          panelId: id,
        })
      );
    }
  };
  const setBackground = (item) => {
    if (
      item.is_part_of_panel &&
      !item.is_suspended &&
      !isAdmin(loginInfo.current_role)
    )
      return styles["part-of-panel"];
    else if (
      item.is_part_of_panel &&
      item.is_suspended &&
      !isAdmin(loginInfo.current_role)
    )
      return styles["suspended"];
    else return "";
  };

  const getItems = (item) => {
    if (isSpoc(user.current_role) || isHr(user.current_role)) {
      return (
        <TableCell sx={{ paddingLeft: 3 }} className={"tablebody"}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {isSpoc(user.current_role) && (
              <>
                {item?.is_part_of_panel && (
                  <Grid
                    item
                    xl={3}
                    xs={3}
                    md={3}
                    lg={3}
                    className={styles.tooltipAlign}
                  >
                    {" "}
                    <Tooltip title="Remove from Panel" placement="top" arrow>
                      <Typography justifyContent="center" alignItems="center">
                        <Icon
                          icon={DeleteIcon}
                          width="16.5px"
                          height="16.77px"
                          click={() => onRemoveUserFromPanel(item?.panel_id)}
                        />
                      </Typography>
                    </Tooltip>
                  </Grid>
                )}
                {!item?.is_part_of_panel && (
                  <Grid
                    item
                    xl={3}
                    xs={3}
                    md={3}
                    lg={3}
                    className={styles.tooltipAlign}
                  >
                    <Tooltip title="Add to Panel" placement="top" arrow>
                      {" "}
                      <AddIcon
                        cursor="pointer"
                        right
                        sx={{
                          color: themes.palette.primary.main,
                        }}
                        onClick={() => handleAddToPanel(item)}
                      />
                    </Tooltip>
                  </Grid>
                )}
              </>
            )}
            <Grid
              item
              xl={3}
              xs={3}
              md={3}
              lg={3}
              className={styles.tooltipAlign}
            >
              <Tooltip title="Notes, Click to see" placement="top" arrow>
                <Typography justifyContent="right" alignItems="center">
                  <Icon
                    icon={
                      item?.is_comments_available
                        ? CommentsActive
                        : CommentsInactive
                    }
                    width="20.71px"
                    height="16px"
                    click={() => handleAddCommentModal(item)}
                  />
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      );
    }
    if (isAdmin(user.current_role)) {
      return (
        <TableCell align={"center"}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className="cursorPointer"
          >
            <Grid
              item
              xl={4}
              xs={12}
              md={12}
              lg={12}
              display={"flex"}
              justifyContent={"center"}
              component={"span"}
              onClick={() => handleCreateUserRole(item)}
            >
              <Tooltip title={"Assign User Role"} arrow={true} placement="top">
                <img src={AddUserIcon} alt="add-user-role" />
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      );
    }
  };

  if (!isAdmin(user?.current_role) ? loading : adminLoading) return <Spinner />;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={"globalLayoutHeight"}
    >
      {allUsersList?.usersList?.length ? (
        <Paper className={"globalTableLayoutStyle"}>
          <TableContainer
            className={` globalLayoutHeight globalTableContainerAlign`}
          >
            <Table aria-label="all-table" stickyHeader>
              <TableHead className={styles.tableHead}>
                <TableRow className={styles.tableRow}>
                  <TableCell
                    className={"tableheading"}
                    onClick={() => handleSortType(SORT_TYPE_CHECK_VALUE.NAME)}
                    width={"25%"}
                  >
                    Panel Member
                    {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.NAME ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    )}
                  </TableCell>

                  {isAdmin(user.current_role) && (
                    <TableCell
                      className={"tableheading"}
                      align="left"
                      onClick={() =>
                        handleSortType(SORT_TYPE_CHECK_VALUE.COMPETENCY)
                      }
                    >
                      Competency
                      {sortBy.sortType !== SORT_TYPE_CHECK_VALUE.COMPETENCY ? (
                        <Icon
                          icon={SortingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      ) : sortBy.sortOrder === "DESC" ? (
                        <Icon
                          icon={DescendingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      ) : (
                        <Icon
                          icon={AscendingIcon}
                          width="6.875px"
                          height="11px"
                          leftPadding="5px"
                        />
                      )}
                    </TableCell>
                  )}

                  <TableCell
                    className={"tableheading"}
                    align="left"
                    onClick={() =>
                      handleSortType(SORT_TYPE_CHECK_VALUE.DATE_OF_JOINING)
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Date of Joining{" "}
                    {sortBy.sortType !==
                    SORT_TYPE_CHECK_VALUE.DATE_OF_JOINING ? (
                      <Icon
                        icon={SortingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : sortBy.sortOrder === "DESC" ? (
                      <Icon
                        icon={DescendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    ) : (
                      <Icon
                        icon={AscendingIcon}
                        width="6.875px"
                        height="11px"
                        leftPadding="5px"
                      />
                    )}
                  </TableCell>

                  <TableCell className={"tableheading"} align="left">
                    Gender
                  </TableCell>

                  {isSpoc(user.current_role) ||
                  isAdmin(user.current_role) ||
                  isHr(user.current_role) ? (
                    <TableCell
                      className={"tableheading"}
                      align={"center"}
                      paddingRight={5}
                    >
                      Action
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsersList?.usersList?.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{ border: 0 }}
                    className={setBackground(user)}
                  >
                    <TableCell
                      className={`${styles.tableBodyStyle} cursorPointer tablebody`}
                    >
                      <PanelDetailsView
                        profilePicUrl={user.profile_pic_url}
                        interviewerEmail={user.email}
                        firstName={user.first_name}
                        lastName={user.last_name}
                        designationName={user.designation_name}
                        employeeCode={user.employee_code}
                      />
                    </TableCell>

                    {isAdmin(loginInfo?.current_role) && (
                      <TableCell align="left">
                        <Typography className={"tablebody"}>
                          {user.competency_name}
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell align="left">
                      <Typography className={"tablebody"}>
                        {extractDate(user?.date_of_joining)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={"tablebody"}>
                        {user.gender}
                      </Typography>
                    </TableCell>

                    {getItems(user)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageClick}
              activePageNumber={activePageNumber}
              userCount={allUserCount}
            />
          </TableContainer>
          {showAddToPanelModal && (
            <AddToPanelModal
              showAddToPanelModal={showAddToPanelModal}
              competencyId={competencyId}
              selectedPanel={selectedPanel}
              setShowAddToPanelModal={setShowAddToPanelModal}
              setCompetencyId={setCompetencyId}
              loginInfo={user}
              activePageNumber={activePageNumber}
              setActivePageNumber={setActivePageNumber}
              setKeyvalue={setKeyvalue}
              activeAccordion={activeAccordion}
              setActiveAccordion={setActiveAccordion}
              handleTabsChange={handleTabsChange}
            />
          )}
          {showAddCommentModal && (
            <AddCommentModal
              showAddCommentModal={showAddCommentModal}
              setShowAddCommentModal={setShowAddCommentModal}
              selectedPanel={selectedPanel}
              commentsForUniqueUser={commentsForUniqueUser}
              commentsLoading={commentsLoading}
              user={user}
              designationIds={designationIds}
              joiningDate={joiningDate}
              subcompetency={subcompetency}
              sortBy={sortBy}
              setSortBy={setSortBy}
              activePageNumber={activePageNumber}
              signal={signal}
              keyvalue={keyvalue}
              competencyId={competencyId}
              dateOfJoining={joiningDate}
              gender={gender}
              searchValue={searchValue}
            />
          )}
        </Paper>
      ) : (
        <NoData
          disabled={
            isSpoc(user?.current_role) || isAdmin(user?.current_role)
              ? true
              : false
          }
        />
      )}
      {isAdmin(user.current_role) && showCreateRoleModal && (
        <AssignUserRoleModal
          show={showCreateRoleModal}
          setShow={setShowCreateRoleModal}
          user={selectedAdminPanel}
          roleType={roleType}
          setRoleType={setRoleType}
          competencyList={competencyList}
          searchValue={searchValue}
          competencyIdFilter={competencyIdFilter}
          isFilterApplied={isFilterApplied}
          designationIds={interviewerDesignation}
        />
      )}
      {showDeleteBookingConfirmModal && isSpoc(user.current_role) && (
        <DeleteBookingConfirmModal
          showDeleteBookingConfirmModal={showDeleteBookingConfirmModal}
          setShowDeleteBookingConfirmModal={setShowDeleteBookingConfirmModal}
          removeFromPanelId={removeFromPanelId}
          activePageNumber={activePageNumber}
          searchValue={searchValue}
          designationIds={designationIds}
          sortBy={sortBy}
          dateOfJoining={joiningDate}
          gender={gender}
          subCompetency={subcompetency}
          bookings={bookings}
          isFilterApplied={isFilterApplied}
          competencyIdFilter={competencyIdFilter}
        />
      )}
    </Grid>
  );
};

export default TableComp;
