import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import WeekendDriveTableDetails from "./WeekendDriveTableDetails";
import CreateWeekendDrive from "./CreateWeekendDrive";
import FilterList from "./Filter/index";
import Icon from "../../../components/SvgIcon/icon";

import {
  fetchWeekendDriveDetailsByCompetency,
  fetchWeekendDriveBookingByCompetency,
  fetchWeekendDriveCoordinatorListByCompetency,
  changeFilterStatus,
  resetDriveFilterStatus,
} from "../../../redux/features/WeekendDrive/weekendDriveSlice";
import { fetchActiveDesignationByCompetencyId } from "../../../redux/features/Roster/rosterSlice";

import { Grid, Typography, Button, Box, Tooltip } from "@mui/material";

import FilteredIcon from "../../../assets/icons/filtered-icon.svg";
import UnFilteredIcon from "../../../assets/icons/unfiltered.svg";

import { nextSaturday, format } from "date-fns";

import { generateDatesList } from "../../../utils/app-utils";
import { ROLE_TYPES, INTERVIEW_STATUS_CHECK } from "../../../constants";
import { SORT_TYPE_CHECK_VALUE, SORT_ORDER } from "../../../constants";

import styles from "./style.module.scss";
import theme from "../../../theme/index";

const { isHr, isSpoc, isAdmin } = ROLE_TYPES;

const WeekendDrive = ({}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.weekendDriveReducer?.loading);
  const competency = useSelector((state) => state.authReducer?.competency);
  const weekendDriveList = useSelector(
    (state) => state.weekendDriveReducer?.weekendDriveDetails
  );
  const pageCount = useSelector(
    (state) => state.weekendDriveReducer?.pageCount
  );
  const totalDriveCount = useSelector(
    (state) => state.weekendDriveReducer?.totalDriveCount
  );
  const activeDesignations = useSelector(
    (state) => state?.rosterReducer?.activeDesignation
  );
  const coordinatorList = useSelector(
    (state) => state.weekendDriveReducer?.coordinatorList
  );
  const bookings = useSelector(
    (state) => state.weekendDriveReducer?.weekendBookings?.bookings
  );
  const driveFilterStatus = useSelector(
    (state) => state.weekendDriveReducer?.weekendDrive?.filterStatus
  );
  const positionFilter = useSelector(
    (state) => state.weekendDriveReducer?.weekendDrive?.activedesignationId
  );
  const periodFromState = useSelector(
    (state) => state.weekendDriveReducer?.weekendDrive?.periodFrom
  );
  const periodToState = useSelector(
    (state) => state.weekendDriveReducer?.weekendDrive?.periodTo
  );
  const isFilterStatus = useSelector(
    (state) => state.weekendDriveReducer?.isFilterApplied
  );
  const updatedBookings = bookings?.filter(
    (item) =>
      INTERVIEW_STATUS_CHECK.SCHEDULED === item.bookingStatus ||
      INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED === item.bookingStatus
  );

  const [filterIcon, setFilterIcon] = useState(false);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [optionCompetency, setOptionCompetency] = useState([]);
  const [competencyList, setCompetencyList] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState("0");
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const [editWeekendDrive, setEditWeekendDrive] = useState(null);
  const [selectedWeekendDriveId, setSelectedWeekendDriveId] = useState(null);
  const [dataEditWeekend, setDataEditWeekend] = useState([]);
  const [reason, setReason] = useState("");
  const [createWeekendDrive, setShowCreateWeekendDrive] = useState(false);
  const [activedesignationId, setActiveDesignationId] = useState([]);
  const [filterActiveDesignations, setFilterActiveDesignations] = useState([]);
  const [weekendDriveDesignations, setWeekendDriveDesignations] = useState([]);
  const [slotTimes, setSlotTimes] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [coordinatorId, setCoordinatorId] = useState([]);
  const [handlePanelDeadlineDateTrigger, setHandlePanelDeadlineDateTrigger] =
    useState(false);
  const [weekendDriveDate, setWeekendDriveDate] = useState(
    nextSaturday(new Date())
  );
  const [
    optionCompetencyDriveCoordiators,
    setOptionCompetencyDriveCoordinators,
  ] = useState([]);
  const [filterStatus, setFilterStatus] = useState(
    INTERVIEW_STATUS_CHECK.SCHEDULED
  );
  const [finalFilterStatus, setFinalFilterStatus] = useState(
    INTERVIEW_STATUS_CHECK.SCHEDULED
  );
  const [interviewStatus, setInterviewStatus] = useState(
    INTERVIEW_STATUS_CHECK.SCHEDULED
  );
  const [sortBy, setSortBy] = useState({
    sortType: SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE,
    sortOrder: SORT_ORDER.DESCENDING,
  });
  const [periodFrom, setPeriodFrom] = useState(
    format(new Date().setMonth(new Date().getMonth() - 6), "yyyy-MM-dd")
  );
  const [periodTo, setPeriodTo] = useState(
    format(new Date().setMonth(new Date().getMonth() + 6), "yyyy-MM-dd")
  );
  const [
    panelMemberWeekendAvailabilityFillDate,
    setPanelMemberWeekendAvailabilityFillDate,
  ] = useState(
    new Date(weekendDriveDate).setFullYear(
      new Date(weekendDriveDate).getFullYear(),
      new Date(weekendDriveDate).getMonth(),
      new Date(weekendDriveDate).getDate() - 4
    )
  );

  const getDesignationData = useCallback(() => {
    let activeDesignationsArray = [];
    for (const designation in activeDesignations) {
      activeDesignationsArray.push(
        activeDesignations[designation].name +
          ":" +
          designation +
          ":" +
          activeDesignations[designation].abbreviatedName
      );
    }
    activeDesignationsArray = activeDesignationsArray.sort();
    let sortedActiveDesignationList = activeDesignationsArray.reduce(
      (acc, item) => {
        acc[" " + item.split(":")[1]] = {
          name: item.split(":")[0],
          abbreviatedName: item.split(":")[2],
        };
        return acc;
      },
      {}
    );
    return Object.entries(sortedActiveDesignationList);
  }, [activeDesignations]);

  useEffect(() => {
    if (filterIcon) {
      const data = getDesignationData();
      if (data?.length) {
        const optionDesignation = data?.map((item) => {
          return {
            label: item[1].name,
            value: parseInt(item[0]),
            disabled: false,
          };
        });
        setWeekendDriveDesignations(optionDesignation);
      }
    }
  }, [filterIcon]);
  useEffect(() => {
    if (editWeekendDrive === null) {
      const list = generateDatesList(
        new Date().setHours(0, 0, 0, 0),
        new Date(panelMemberWeekendAvailabilityFillDate)
      );
      setDateRange(list);
    }
    if (
      editWeekendDrive !== null &&
      dataEditWeekend &&
      dataEditWeekend?.length
    ) {
      const array_designation = dataEditWeekend[0]?.designation_ids;
      const array_coordinator = dataEditWeekend[0]?.drive_coordinator_ids;
      const data = getDesignationData();
      const updatedDesignationData = data?.reduce((acc, item) => {
        if (array_designation?.includes(parseInt(item[0]))) {
          acc.push(parseInt(item[0]));
        }
        return acc;
      }, []);
      if (data?.length) {
        const optionDesignation = data?.map((item) => {
          return {
            label: item[1].name,
            value: parseInt(item[0]),
            disabled: false,
          };
        });
        setOptionCompetency(optionDesignation);
      } else {
        setOptionCompetency([]);
      }
      let updatedArray = [];
      optionCompetency &&
        optionCompetency?.map((Optionitem) => {
          updatedDesignationData &&
            updatedDesignationData.forEach((selectedItem) => {
              if (Optionitem.value === selectedItem.value) {
                updatedArray.push({
                  label: Optionitem.label,
                  value: parseInt(Optionitem.value),
                  disabled: true,
                });
              }
            });
          updatedArray.push({
            label: Optionitem.label,
            value: parseInt(Optionitem.value),
            disabled: false,
          });
        });

      let uniqueValuesWithDesignationDisabled = updatedArray.filter(
        (a, i) => updatedArray?.findIndex((s) => a.value === s.value) === i
      );
      const coordinatorListUpdated = Object.entries(coordinatorList);
      if (coordinatorListUpdated?.length) {
        const optionCoordinators = coordinatorListUpdated?.map((item) => {
          return {
            label:
              item[1].first_name +
              " " +
              item[1].last_name +
              "( " +
              item[1].email +
              " )",
            value: parseInt(item[1].id),
          };
        });
        setOptionCompetencyDriveCoordinators(optionCoordinators);
      } else {
        setOptionCompetencyDriveCoordinators([]);
      }
      const updatedCordinatorData = coordinatorListUpdated.reduce(
        (acc, item) => {
          if (array_coordinator?.includes(parseInt(item[1].id))) {
            acc.push(parseInt(item[1].id));
          }
          return acc;
        },
        []
      );
      let list = [];
      const dates = dataEditWeekend[0]?.availability_reminder_dates;
      if (dates !== null && Array.isArray(dates) && dates?.length !== 0) {
        const labelValueArray = dates?.map((dateString) => {
          const formattedLabel = format(new Date(dateString), "yyyy-MM-dd");
          return {
            value: dateString,
            label: formattedLabel,
          };
        });
        const array_remainder_dates =
          dataEditWeekend[0]?.availability_reminder_dates;
        const dateObjects = array_remainder_dates?.map(
          (isoString) => new Date(isoString)
        );
        const minDate = new Date(Math.min(...dateObjects));
        if (new Date(dataEditWeekend[0].date_of_drive) >= new Date()) {
          // now three cases are posible
          if (
            new Date(dataEditWeekend[0].date_of_availability_deadline) >=
            new Date()
          ) {
            const list1 = generateDatesList(
              new Date().setHours(0, 0, 0, 0),
              new Date(dataEditWeekend[0].date_of_availability_deadline)
            );

            list = list1.concat(labelValueArray);

            const uniqueMap = new Map();

            for (const obj of list) {
              uniqueMap.set(obj.value, obj);
            }
            list = Array.from(uniqueMap.values());
            setDateRange(list);
          } else {
            list = labelValueArray;
            setDateRange(list);
          }
        } else {
          list = generateDatesList(
            new Date(minDate),
            new Date(dataEditWeekend[0].date_of_availability_deadline)
          );
          setDateRange(list);
        }
        const datesArray =
          list &&
          list.length &&
          list.reduce((acc, item) => {
            if (array_remainder_dates?.includes(item.value)) {
              acc.push(item);
            }
            return acc;
          }, []);
        setSelectedDates(datesArray);
      } else {
        list = generateDatesList(
          new Date().setHours(0, 0, 0, 0),
          new Date(dataEditWeekend[0].date_of_availability_deadline)
        );
        setDateRange(list);
        setSelectedDates([]);
      }
      let updatedArrayCoordinator = [];
      optionCompetencyDriveCoordiators &&
        optionCompetencyDriveCoordiators.forEach((optiondata) => {
          updatedCordinatorData &&
            updatedCordinatorData.forEach((updatedDataCordinator) => {
              if (optiondata.value === updatedDataCordinator.value) {
                updatedArrayCoordinator.push({
                  label: optiondata.label,
                  value: parseInt(optiondata.value),
                  disabled: true,
                });
              }
            });
          updatedArrayCoordinator.push({
            label: optiondata.label,
            value: parseInt(optiondata.value),
            disabled: false,
          });
        });
      let uniqueValuesWithCoordinatorDisabled = updatedArrayCoordinator.filter(
        (a, i) =>
          updatedArrayCoordinator?.findIndex((s) => a.value === s.value) === i
      );
      const coordinatorsForWeekendDrive =
        optionCompetencyDriveCoordiators.filter((item) =>
          updatedCordinatorData.includes(item.value)
        );

      const designationsForWeekendDrive = Object.entries(activeDesignations)
        .filter(([key]) => updatedDesignationData.includes(parseInt(key)))
        .map(([key, value]) => ({ value: parseInt(key), label: value.name }));

      setInterviewStatus(dataEditWeekend[0]?.drive_status);
      setSlotTimes(dataEditWeekend[0]?.slots);
      setWeekendDriveDate(new Date(dataEditWeekend[0]?.date_of_drive));
      setActiveDesignationId(designationsForWeekendDrive);
      setCoordinatorId(coordinatorsForWeekendDrive);
      setReason(dataEditWeekend[0]?.comments);
      setPanelMemberWeekendAvailabilityFillDate(
        new Date(dataEditWeekend[0]?.date_of_availability_deadline)
      );
    }
  }, [dataEditWeekend, getDesignationData, coordinatorList, editWeekendDrive]);
  useEffect(() => {
    if (editWeekendDrive === null) {
      const list = generateDatesList(
        new Date().setHours(0, 0, 0, 0),
        new Date(panelMemberWeekendAvailabilityFillDate)
      );
      setDateRange(list);
    }
    if (
      editWeekendDrive !== null &&
      !isNaN(new Date(dataEditWeekend[0]?.date_of_availability_deadline)) &&
      !isNaN(new Date(panelMemberWeekendAvailabilityFillDate)) &&
      handlePanelDeadlineDateTrigger
    ) {
      if (
        new Date(
          new Date(dataEditWeekend[0]?.date_of_availability_deadline)
        ).getTime() !==
        new Date(panelMemberWeekendAvailabilityFillDate).getTime()
      ) {
        const result = generateDatesList(
          new Date().setHours(0, 0, 0, 0),
          new Date(panelMemberWeekendAvailabilityFillDate)
        );
        const array_remainder_dates =
          dataEditWeekend[0]?.availability_reminder_dates;
        const labelValueArray = array_remainder_dates?.map((dateString) => {
          const formattedLabel = format(new Date(dateString), "yyyy-MM-dd");
          return {
            value: dateString,
            label: formattedLabel,
          };
        });
        let combinedArray = [];
        if (labelValueArray?.length) {
          combinedArray = result.concat(labelValueArray);
          const uniqueMap = new Map();
          for (const obj of combinedArray) {
            uniqueMap.set(obj.value, obj);
          }
          combinedArray = Array.from(uniqueMap.values());
        } else {
          combinedArray = [...result];
        }
        setDateRange(combinedArray);
        if (result.length) {
          const datesArray =
            combinedArray &&
            combinedArray.length &&
            combinedArray.reduce((acc, item) => {
              if (array_remainder_dates?.includes(item.value)) {
                acc.push({
                  label: item.label,
                  value: item.value,
                });
              }
              return acc;
            }, []);

          if (editWeekendDrive !== null) {
            setSelectedDates(datesArray);
          }
        }
      }
    }
  }, [
    panelMemberWeekendAvailabilityFillDate,
    editWeekendDrive,
    dataEditWeekend,
    handlePanelDeadlineDateTrigger,
  ]);
  useEffect(() => {
    if (competency) {
      setCompetencyList(competency);
    }
  }, [competency]);
  useEffect(() => {
    if (
      loginInfo.current_role_competency_id &&
      (isSpoc(loginInfo.current_role) || isHr(loginInfo.current_role))
    ) {
      !isAdmin(loginInfo.current_role) &&
        dispatch(
          fetchWeekendDriveDetailsByCompetency({
            roleId: loginInfo.role_id,
            competencyId: loginInfo.current_role_competency_id,
            driveStatus: driveFilterStatus,
            pageNumber: activePageNumber,
            isPaginated: true,
            designationIds: positionFilter,
            periodFrom,
            periodTo,
            sortType: sortBy.sortType,
            sortOrder: sortBy.sortOrder,
          })
        );
    }
  }, [activePageNumber, dispatch, loginInfo, sortBy]);
  useEffect(() => {
    if (createWeekendDrive) {
      setInterviewStatus(INTERVIEW_STATUS_CHECK.SCHEDULED);
      let date = new Date(weekendDriveDate).setFullYear(
        new Date(weekendDriveDate).getFullYear(),
        new Date(weekendDriveDate).getMonth(),
        new Date(weekendDriveDate).getDate() - 4
      );
      date = new Date(date);
      let currentDate = new Date();
      date > currentDate
        ? setPanelMemberWeekendAvailabilityFillDate(date)
        : setPanelMemberWeekendAvailabilityFillDate(
            new Date(weekendDriveDate).setFullYear(
              new Date(weekendDriveDate).getFullYear(),
              new Date(weekendDriveDate).getMonth(),
              new Date(weekendDriveDate).getDate() - 1
            )
          );
    }
  }, [createWeekendDrive, weekendDriveDate]);
  useEffect(() => {
    if (selectedWeekendDriveId !== null) {
      const updatedData = weekendDriveList.filter(
        (item) => item.id === selectedWeekendDriveId
      );
      setDataEditWeekend(updatedData);
    }
  }, [selectedWeekendDriveId, weekendDriveList]);
  useEffect(() => {
    if (loginInfo.current_role_competency_id && isHr(loginInfo.current_role)) {
      !isAdmin(loginInfo.current_role) &&
        dispatch(
          fetchActiveDesignationByCompetencyId({
            roleId: loginInfo.role_id,
            competencyId: loginInfo.current_role_competency_id,
          })
        );
      !isAdmin(loginInfo.current_role) &&
        dispatch(
          fetchWeekendDriveCoordinatorListByCompetency({
            roleId: loginInfo.role_id,
            competencyId: loginInfo.current_role_competency_id,
          })
        );
    }
  }, [loginInfo, loginInfo.current_role_competency_id, loginInfo.current_role]);

  const checkWeekendBookings = (selectedDate) => {
    dispatch(
      fetchWeekendDriveBookingByCompetency({
        competencyId: loginInfo.current_role_competency_id,
        driveStatus: interviewStatus,
        periodTo: new Date(
          new Date(selectedDate).setHours(23, 59, 59, 59)
        ).toISOString(),
        periodFrom: new Date(
          new Date(selectedDate).setHours(0, 0, 0, 0)
        ).toISOString(),
        roleId: loginInfo.role_id,
      })
    );
  };
  const handleSortType = (columnLabel) => {
    setSortBy((prevState) => {
      const sortOrder =
        prevState.sortType === columnLabel
          ? prevState.sortOrder === SORT_ORDER.ASCENDING || !prevState.sortOrder
            ? SORT_ORDER.DESCENDING
            : SORT_ORDER.ASCENDING
          : SORT_ORDER.ASCENDING;
      return {
        sortType: columnLabel,
        sortOrder: sortOrder,
      };
    });
  };
  const onChangeFilterStatus = (e) => {
    setFinalFilterStatus(e);
    dispatch(changeFilterStatus(e));
  };
  const onCreateButtonClick = (e) => {
    let nextSaturdayDate = nextSaturday(new Date());
    setShowCreateWeekendDrive(true);
    setEditWeekendDrive(null);
    setCoordinatorId([]);
    setActiveDesignationId([]);
    setSelectedDates([]);
    setWeekendDriveDate(nextSaturdayDate);
    setPanelMemberWeekendAvailabilityFillDate(
      new Date(nextSaturdayDate).setFullYear(
        new Date(nextSaturdayDate).getFullYear(),
        new Date(nextSaturdayDate).getMonth(),
        new Date(nextSaturdayDate).getDate() - 4
      )
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      className={"globalLayoutHeight"}
      gap={2}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4} md={4} lg={4} xl={4}>
          <Typography
            variant="h5"
            display="inline-flex"
            color={theme.palette.secondary.main}
            className={"heading"}
          >
            Weekend Drive
          </Typography>
        </Grid>
        <Grid
          container
          xs={8}
          md={8}
          lg={8}
          xl={8}
          spacing={1}
          display={"flex"}
          justifyContent={"end"}
          alignItems="center"
        >
          {isHr(loginInfo?.current_role) && (
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <Button
                className={`${styles.createDriveButtonStyle}`}
                color="primary"
                variant="contained"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disableElevation
                onClick={(e) => onCreateButtonClick(e)}
              >
                Create Weekend Drive
              </Button>
            </Grid>
          )}
          <Grid
            item
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <Grid item xs={12} md={12} lg={12} xl={12} display={"flex"}>
              <Tooltip title="Filter" placement="top" arrow>
                <Box
                  className={
                    isFilterStatus
                      ? styles.filteredStyles
                      : styles.unfilteredStyles
                  }
                  onClick={() => {
                    setFilterIcon(true);
                  }}
                >
                  <Icon
                    icon={isFilterStatus ? FilteredIcon : UnFilteredIcon}
                    width={"15px"}
                    height={"16.5px"}
                    color={isFilterStatus ? "#FFFFFF" : null}
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        className={"globalLayoutHeight"}
      >
        <WeekendDriveTableDetails
          loginInfo={loginInfo}
          weekendDriveList={weekendDriveList}
          pageCount={pageCount}
          activePageNumber={activePageNumber}
          setActivePageNumber={setActivePageNumber}
          competencyList={competencyList}
          filterStatus={filterStatus}
          editWeekendDrive={editWeekendDrive}
          setEditWeekendDrive={setEditWeekendDrive}
          setDataEditWeekend={setDataEditWeekend}
          dataEditWeekend={dataEditWeekend}
          setHandlePanelDeadlineDateTrigger={setHandlePanelDeadlineDateTrigger}
          handlePanelDeadlineDateTrigger={handlePanelDeadlineDateTrigger}
          setSelectedWeekendDriveId={setSelectedWeekendDriveId}
          selectedWeekendDriveId={selectedWeekendDriveId}
          weekendDriveDate={weekendDriveDate}
          setWeekendDriveDate={setWeekendDriveDate}
          activedesignationId={activedesignationId}
          setActiveDesignationId={setActiveDesignationId}
          bookings={bookings}
          coordinatorId={coordinatorId}
          reason={reason}
          setReason={setReason}
          setDateRange={setDateRange}
          activeAccordion={activeAccordion}
          dateRange={dateRange}
          setSlotTimes={setSlotTimes}
          slotTimes={slotTimes}
          getDesignationData={getDesignationData}
          setOptionCompetency={setOptionCompetency}
          optionCompetency={optionCompetency}
          optionCompetencyDriveCoordiators={optionCompetencyDriveCoordiators}
          setOptionCompetencyDriveCoordinators={
            setOptionCompetencyDriveCoordinators
          }
          setFilterStatus={setFilterStatus}
          panelMemberWeekendAvailabilityFillDate={
            panelMemberWeekendAvailabilityFillDate
          }
          setPanelMemberWeekendAvailabilityFillDate={
            setPanelMemberWeekendAvailabilityFillDate
          }
          coordinatorList={coordinatorList}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          setCoordinatorId={setCoordinatorId}
          activeDesignations={activeDesignations}
          setInterviewStatus={setInterviewStatus}
          interviewStatus={interviewStatus}
          sortBy={sortBy}
          handleSortType={handleSortType}
          totalDriveCount={totalDriveCount}
          loading={loading}
        />
      </Grid>
      {createWeekendDrive && editWeekendDrive === null && (
        <CreateWeekendDrive
          show={createWeekendDrive}
          setShow={setShowCreateWeekendDrive}
          editWeekendDrive={editWeekendDrive}
          setEditWeekendDrive={setEditWeekendDrive}
          selectedWeekendDriveId={selectedWeekendDriveId}
          setSelectedWeekendDriveId={setSelectedWeekendDriveId}
          weekendDriveDate={weekendDriveDate}
          weekendDriveList={weekendDriveList}
          loginInfo={loginInfo}
          competencyList={competencyList}
          activedesignationId={activedesignationId}
          setActiveDesignationId={setActiveDesignationId}
          bookings={bookings}
          coordinatorId={coordinatorId}
          setCoordinatorId={setCoordinatorId}
          setWeekendDriveDate={setWeekendDriveDate}
          activeDesignations={activeDesignations}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          coordinatorList={coordinatorList}
          panelMemberWeekendAvailabilityFillDate={
            panelMemberWeekendAvailabilityFillDate
          }
          setPanelMemberWeekendAvailabilityFillDate={
            setPanelMemberWeekendAvailabilityFillDate
          }
          setFilterStatus={setFilterStatus}
          setDataEditWeekend={setDataEditWeekend}
          dataEditWeekend={dataEditWeekend}
          filterStatus={filterStatus}
          optionCompetency={optionCompetency}
          setOptionCompetency={setOptionCompetency}
          optionCompetencyDriveCoordiators={optionCompetencyDriveCoordiators}
          setOptionCompetencyDriveCoordinators={
            setOptionCompetencyDriveCoordinators
          }
          getDesignationData={getDesignationData}
          slotTimes={slotTimes}
          setSlotTimes={setSlotTimes}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setReason={setReason}
          activeAccordion={activeAccordion}
          activePageNumber={activePageNumber}
          reason={reason}
          interviewStatus={interviewStatus}
          setInterviewStatus={setInterviewStatus}
          updatedBookings={updatedBookings}
          setActiveAccordion={setActiveAccordion}
          checkWeekendBookings={checkWeekendBookings}
        />
      )}
      {filterIcon && (
        <FilterList
          loginInfo={loginInfo}
          filterStatus={filterStatus}
          activePageNumber={activePageNumber}
          isFilterApplied={isFilterApplied}
          setIsFilterApplied={setIsFilterApplied}
          filterIcon={filterIcon}
          setFilterIcon={setFilterIcon}
          setActivePageNumber={setActivePageNumber}
          onChangeFilterStatus={onChangeFilterStatus}
          setActiveAccordion={setActiveAccordion}
          weekendDriveDate={weekendDriveDate}
          setInterviewStatus={setInterviewStatus}
          setWeekendDriveDate={setWeekendDriveDate}
          setActiveDesignationId={setFilterActiveDesignations}
          setCoordinatorId={setCoordinatorId}
          setReason={setReason}
          setPanelMemberWeekendAvailabilityFillDate={
            setPanelMemberWeekendAvailabilityFillDate
          }
          finalFilterStatus={finalFilterStatus}
          setFinalFilterStatus={setFinalFilterStatus}
          setFilterStatus={setFilterStatus}
          activedesignationId={filterActiveDesignations}
          activeDesignations={weekendDriveDesignations}
          periodFrom={periodFrom}
          setPeriodFrom={setPeriodFrom}
          periodTo={periodTo}
          setPeriodTo={setPeriodTo}
          sortBy={sortBy}
          positionFilter={positionFilter}
          driveFilterStatus={driveFilterStatus}
          periodFromState={periodFromState}
          periodToState={periodToState}
        />
      )}
    </Grid>
  );
};

export default WeekendDrive;
