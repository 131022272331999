import React from "react";
import { useSelector } from "react-redux";

import SlotCard from "./components/SlotCard";
import DashboardTable from "./components/DashboardTable";
import Chart from "./components/Chart";
import PanelCard from "./components/PanelCard";
import Pagination from "../../../../components/Pagination/index";
import Icon from "../../../../components/SvgIcon/icon";
import FilterCompetency from "./components/FilterCompetency";
import RecruiterList from "./components/RecruiterList";
import StayOnHoverOverlay from "../../../../components/StayOnHoverOverlay";

import {
  Grid,
  Typography,
  Box,
  Paper,
  TableContainer,
  Tooltip,
  TextField,
  Chip,
  Divider,
} from "@mui/material";

import unfiltered from "../../../../assets/icons/unfiltered.svg";
import filtered from "../../../../assets/icons/filtered-icon.svg";

import { DASHBOARD_CARD_ICONS_COLOR } from "../../../../constants/index";

import styles from "./style.module.scss";

const DashboardView = (props) => {
  const {
    loginInfo,
    isFilterApplied,
    setShowFilteredCompetency,
    slotCardData,
    isManager,
    setShowFilteredRecruiter,
    selectedRecruiterOptions,
    cards,
    interviewStatus,
    handleSortType,
    sortBy,
    handlePageClick,
    activePageNumber,
    weekendDriveDetails,
    showFilteredCompetency,
    handleClose,
    onSubmit,
    filterByCompetencyOptions,
    handleSelect,
    selectedCompetencies,
    periodFrom,
    periodTo,
    setPeriodFrom,
    setPeriodTo,
    handleClear,
    showFilteredRecruiter,
    handleRecruiterSubmit,
    recruiterOptionsList,
    tempRecruiters,
    handleRecruiterValueAndActivePage,
  } = props;

  const {
    tableLoading,
    panelCount,
    tableData,
    tablePageCount,
    tableDataCount,
  } = useSelector((state) => state.hrDashboardReducer);

  return (
    <>
      <Grid container alignItems="center" className={styles.fontFamily}>
        <Grid sm={12} md={12} lg={12} container justifyContent="space-between">
          <Grid item xs={4} alignSelf={"center"}>
            <Typography className={"heading"}>
              Welcome, {loginInfo?.name}
            </Typography>
          </Grid>

          <Grid
            item
            xs={1.5}
            md={1.5}
            lg={1.5}
            xl={1.5}
            paddingTop={0.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <Tooltip title="Filter" placement="top" arrow>
              <Box
                className={
                  isFilterApplied
                    ? styles.filteredStyles
                    : styles.unfilteredStyles
                }
                onClick={() => setShowFilteredCompetency(true)}
              >
                <Icon
                  icon={isFilterApplied ? filtered : unfiltered}
                  width={"15.5px"}
                  height={"16px"}
                  color={isFilterApplied ? "#FFFFFF" : null}
                />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" spacing={2}>
          {slotCardData?.map((card, index) => (
            <Grid item xs={12} md={6} lg={4}>
              <SlotCard
                key={index}
                icon={card.icon}
                type={card.type}
                numberOfSlots={card.numberOfSlots}
                slotTitle={card.slotTitle}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container justifyContent="space-between">
          <Box marginTop={2}>
            {isManager ? (
              <Typography variant="h6" color="#2E1C41">
                Interviews
              </Typography>
            ) : (
              <Typography variant="h6" color="#2E1C41">
                My Interviews
              </Typography>
            )}
          </Box>

          {isManager && (
            <Box
              marginTop={2}
              sx={{ display: "flex" }}
              maxWidth={250}
              justifyContent="flex-end"
            >
              <Box onClick={() => setShowFilteredRecruiter(true)}>
                <StayOnHoverOverlay
                  title={
                    <>
                      <Typography variant="sub1" fontSize={14}>
                        Selected Recruiters
                      </Typography>
                      <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
                      <Grid container rowGap={1}>
                        {selectedRecruiterOptions.map((item, index) => {
                          return (
                            <Grid xs={6} item>
                              <Chip
                                label={`${item.label}`}
                                className={styles.chipClass}
                              ></Chip>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  }
                >
                  <TextField
                    id="outlined-basic"
                    label="Filter by Recruiter"
                    variant="outlined"
                    value={`${selectedRecruiterOptions[0].label} ${
                      selectedRecruiterOptions.length - 1
                        ? `+${selectedRecruiterOptions.length - 1}`
                        : ""
                    }`}
                    contentEditable={false}
                    spellCheck={false}
                  />
                </StayOnHoverOverlay>
              </Box>
            </Box>
          )}
        </Grid>

        <Grid
          container
          gap={1}
          marginBottom={3}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          {cards}
        </Grid>
        {tableLoading ? (
          <h5>Loading...</h5>
        ) : (
          tableData?.length > 0 && (
            <Grid item xs={12}>
              <Box marginTop={0}>
                <DashboardTable
                  rows={tableData}
                  interviewStatus={interviewStatus}
                  handleSortType={handleSortType}
                  sortBy={sortBy}
                />
              </Box>
            </Grid>
          )
        )}

        <TableContainer
          component={Paper}
          className={`${styles.shadow} ${styles.pagination}`}
        >
          <Pagination
            userCount={tableDataCount}
            pageCount={tablePageCount}
            handlePageClick={handlePageClick}
            activePageNumber={activePageNumber}
          />
        </TableContainer>

        <Grid container justifyContent="space-between" marginTop={3} gap={1}>
          <Grid xs={7.5}>
            <Grid>
              <Typography variant="h6" color="#2E1C41">
                Panel
              </Typography>
            </Grid>
            {
              parseInt(panelCount?.totalPanel) ? (
                <>
                  <Grid
                    marginTop={2}
                    sx={{ backgroundColor: "white" }}
                    padding={2}
                    className={`${styles.border} ${styles.shadow}`}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      color="#2E1C41"
                    >
                      <Grid item>
                        <Typography variant="h5">{panelCount?.totalPanel}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6">Total panel</Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      justifyContent="space-around"
                      container
                      marginTop={2}
                      alignItems="center"
                    >
                      <Grid item sm={7} xl={5}>
                        <Chart roundData={panelCount} />
                      </Grid>

                      <Grid item xs={5} paddingRight={2.2}>
                        <PanelCard
                          value={panelCount?.activePanel}
                          title="Active Panel"
                          type={DASHBOARD_CARD_ICONS_COLOR.UNUTILISED_SLOT}
                        />
                        <PanelCard
                          value={panelCount?.newPanel}
                          title="New Panel"
                          type={DASHBOARD_CARD_ICONS_COLOR.AVAILABLE_SLOT}
                        />
                        <PanelCard
                          value={panelCount?.suspendedPanel}
                          title="Suspended Panel"
                          type={DASHBOARD_CARD_ICONS_COLOR.SUSPENDED_PANEL}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) :(
                  <>
                    <Grid
                      marginBottom={2}
                      marginTop={2}
                      sx={{ backgroundColor: "white", height: '350px', textAlign: 'center' }}
                      padding={4}
                      className={`${styles.border} ${styles.shadow}`}
                    >
                      <Typography variant="h5" fontFamily={'poppins'}>
                      No Data Available
                      </Typography>
                    </Grid>
                  </>
              )
            }

          </Grid>

          <Grid xs={4}>
            <Grid container justifyContent="space-between">
              <Typography variant="h6" color="#2E1C41">
                Weekend Drive
              </Typography>
            </Grid>
            <Grid>
              {weekendDriveDetails?.map((card, index) => (
                <Grid item xs={12}>
                  <SlotCard
                    key={index}
                    icon={card.icon}
                    type={card.type}
                    numberOfSlots={card.count}
                    slotTitle={card.slotTitle}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {showFilteredCompetency && (
          <FilterCompetency
            show={showFilteredCompetency}
            setShow={handleClose}
            clickConfirm={onSubmit}
            filterByCompetencyOptions={filterByCompetencyOptions}
            handleSelect={handleSelect}
            selectedCompetencies={selectedCompetencies}
            periodFrom={periodFrom}
            periodTo={periodTo}
            setPeriodFrom={setPeriodFrom}
            setPeriodTo={setPeriodTo}
            handleClear={handleClear}
          />
        )}

        {showFilteredRecruiter && (
          <RecruiterList
            show={showFilteredRecruiter}
            setShow={handleClose}
            clickConfirm={handleRecruiterSubmit}
            recruiterOptionsList={recruiterOptionsList}
            selectedRecruiterOptions={tempRecruiters}
            handleRecruiterValueAndActivePage={
              handleRecruiterValueAndActivePage
            }
          />
        )}
      </Grid>
    </>
  );
};

export default DashboardView;
