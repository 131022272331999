import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import WeekendAvailabilityTable from "./WeekendAvailabilityTable";
import NoData from "../../NoData/index";
import Spinner from "../../../../components/Spinner";

import {
  fetchPanelMembersAvailabilityWeekend,
  getLoggedInUserPanelDetails,
} from "../../../../redux/features/Availability/availabilitySlice";
import {
  fetchWeekendDriveFilterOptions,
  fetchWeekendDriveDetailsByCompetency,
} from "../../../../redux/features/WeekendDrive/weekendDriveSlice";

import { Grid } from "@mui/material";

import { lastDayOfWeek, startOfWeek, format } from "date-fns";
import { extractDate } from "../../../../utils/app-utils";
import {
  ROLE_TYPES,
  SLOT_FILTER_OPTIONS,
  ROUND_FILTER_OPTIONS,
  INTERVIEW_STATUS_CHECK,
  checkEqualDates,
  CHECK_TAB_KEY,
  SORT_ORDER,
  SORT_TYPE_CHECK_VALUE,
} from "../../../../constants";

const { isPanelMember, isAdmin } = ROLE_TYPES;

const Weekend = ({
  open,
  loginInfo,
  activeAccordion,
  setActiveAccordion,
  keyValue,
  setKeyValue,
  setWeekendDriveDate,
  weekendDriveDate,
  weekendDriveListUpdated,
  setWeekendDriveListUpdated,
  weekendDriveSelected,
  weekenddesignationIds,
  setWeekendDesignationIds,
  setWeekendDriveSelected,
  selectedRecruiters,
  setSelectedRecruiters,
  optionsRecruiterEmail,
  setOptionsRecruiterEmail,
  weekendDriveList,
  activePageNumber,
  setActivePageNumber,
  searchValue,
  setSearchValue,
  eligibleDesignationIds,
  providedSlots,
  roundsFilter,
  tagsFilter,
  subCompetencyFilter,
  interviewerDesignationIds,
  tagsBadge
}) => {
  const dispatch = useDispatch();
  const controlAllApi = useRef(true);
  const controlCurrentUserApi = useRef(true);
  const controlDriveDesignationApi = useRef(true);
  const loggedInUserPanelDetails = useSelector(
    (state) => state?.availabilityReducer?.loggedInUserPanelDetails
  );
  const loadingForWeekendListByDate = useSelector(
    (state) => state?.weekendDriveReducer?.loadingForWeekendListByDate
  );
  const loading = useSelector((state) => state?.weekendDriveReducer?.loading);
  const dataFilters = useSelector(
    (state) => state.weekendDriveReducer?.filtersArray
  );
  const recruiterEmails = dataFilters?.recruiterEmails;
  const {
    userListWeekend,
    loadingWeekendAvailabity,
    pageCountWeekend,
    totalCountWeekend,
  } = useSelector(
    (state) => state.availabilityReducer?.weekendAvailabilityData
  );

  const [competencyId, setCompetencyId] = useState(0);
  const [slotsProvided, setSlotsProvided] = useState(SLOT_FILTER_OPTIONS.ALL);
  const [roundName, setRoundName] = useState(ROUND_FILTER_OPTIONS.ALL);
  const [activedesignationId, setActiveDesignationId] = useState([]);
  const [stateToogle, setStateToogle] = useState({ compact: false });
  const [signal, setSignal] = useState(null);
  const [prevWeekendDriveDate, setPrevWeekendDriveDate] = useState(null);
  const [
    allowDriveCoordinatorsForBooking,
    setAllowDriveCoordinatorsForBooking,
  ] = useState(false);
  const [panelGroupId, setPanelGroupId] = useState([]);
  const [subCompetency, setSubCompetency] = useState([]);

  useEffect(() => {
    if (loginInfo.current_role_competency_id) {
      !isAdmin(loginInfo.current_role) &&
        dispatch(
          fetchWeekendDriveDetailsByCompetency({
            roleId: loginInfo.role_id,
            competencyId: loginInfo.current_role_competency_id,
            driveStatus: INTERVIEW_STATUS_CHECK.SCHEDULED,
            isPaginated: false,
            designationIds: [],
            periodFrom: format(
              new Date().setMonth(new Date().getMonth() - 6),
              "yyyy-MM-dd"
            ),
            periodTo: format(
              new Date().setMonth(new Date().getMonth() + 6),
              "yyyy-MM-dd"
            ),
            sortType: SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE,
            sortOrder: SORT_ORDER.DESCENDING,
            isMultiStatus: true,
          })
        );
    }
  }, [loginInfo]);
  useEffect(() => {
    if (loginInfo.role_id && weekendDriveSelected?.length) {
      dispatch(
        getLoggedInUserPanelDetails({
          roleId: loginInfo?.role_id,
          periodFrom: new Date(
            new Date(weekendDriveDate).setHours(0, 0, 0, 0)
          ).toISOString(),
          periodTo: new Date(
            new Date(weekendDriveDate).setHours(23, 59, 59, 59)
          ).toISOString(),
          keyValue,
          driveId: weekendDriveSelected && weekendDriveSelected[0]?.id,
        })
      );
    }
  }, [dispatch, weekendDriveDate, loginInfo, weekendDriveSelected]);
  useEffect(() => {
    if (
      weekendDriveList &&
      weekendDriveList.length &&
      CHECK_TAB_KEY.WEEKEND === keyValue
    ) {
      setWeekendDriveListUpdated(
        weekendDriveList.filter(
          (item) =>
            item.date_of_drive &&
            INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status
        )
      );
    }
  }, [keyValue, weekendDriveList]);
  useEffect(() => {
    if (weekendDriveDate !== null) {
      const data =
        weekendDriveList &&
        weekendDriveList?.filter((item) =>
          checkEqualDates(
            new Date(item.date_of_drive),
            new Date(weekendDriveDate)
          )
        );
      if (data && data.length) {
        setWeekendDriveSelected(data);
      }
    }
  }, [weekendDriveDate, weekendDriveList]);
  useEffect(() => {
    const getUpdatedListWithScheduled = weekendDriveList?.filter(
      (item) => INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status
    );
    if (getUpdatedListWithScheduled && getUpdatedListWithScheduled.length) {
      const getDriveDatesFiltered = getUpdatedListWithScheduled.map(
        (driveData) => ({
          driveDate: driveData.date_of_drive,
          driveId: driveData.id,
        })
      );
      const weekEnd = lastDayOfWeek(new Date(new Date().setHours(0, 0, 0, 0)));
      const weekStart = startOfWeek(new Date(new Date().setHours(0, 0, 0, 0)));
      const getDriveBeforeTodayDate = getDriveDatesFiltered.filter(
        (item) => new Date(item.driveDate).getTime() < new Date().getTime()
      );
      const getDriveAfterWeekendLastDate = getDriveDatesFiltered.filter(
        (item) =>
          new Date(item.driveDate).getTime() > new Date(weekEnd).getTime()
      );

      const getDriveInSameWeek = getDriveDatesFiltered.filter(
        (item) =>
          new Date(item.driveDate).getTime() > new Date(weekStart).getTime() &&
          new Date(item.driveDate).getTime() <= new Date(weekEnd).getTime()
      );

      const storedWeekendDriveDate = localStorage.getItem("weekendDriveDate");
      const dateExists = getUpdatedListWithScheduled
        .map((drive) => drive.date_of_drive)
        .includes(storedWeekendDriveDate);
      if (dateExists) {
        setWeekendDriveDate(new Date(storedWeekendDriveDate).toISOString());
      } else {
        if (CHECK_TAB_KEY.WEEKEND === keyValue) {
          if (getDriveInSameWeek && getDriveInSameWeek.length) {
            setWeekendDriveDate(
              new Date(getDriveInSameWeek[0]?.driveDate).toISOString()
            );
          } else if (
            getDriveAfterWeekendLastDate &&
            getDriveAfterWeekendLastDate.length
          ) {
            setWeekendDriveDate(
              new Date(
                getDriveAfterWeekendLastDate[
                  getDriveAfterWeekendLastDate.length - 1
                ]?.driveDate
              ).toISOString()
            );
          } else if (
            getDriveBeforeTodayDate &&
            getDriveBeforeTodayDate.length
          ) {
            setWeekendDriveDate(
              new Date(getDriveBeforeTodayDate[0]?.driveDate).toISOString()
            );
          }
        }
      }
    }
  }, [keyValue, weekendDriveList]);
  useEffect(() => {
    if (
      weekendDriveList &&
      weekendDriveList.length &&
      CHECK_TAB_KEY.WEEKEND === keyValue
    ) {
      setWeekendDriveListUpdated(
        weekendDriveList.filter(
          (item) =>
            item.date_of_drive &&
            INTERVIEW_STATUS_CHECK.SCHEDULED === item.drive_status
        )
      );
    }
  }, [keyValue, weekendDriveList]);
  useEffect(() => {
    if (loginInfo?.current_role_competency_id) {
      if (
        CHECK_TAB_KEY.WEEKEND === keyValue &&
        weekendDriveDate !== null &&
        weekendDriveSelected.length
      ) {
        dispatch(
          fetchPanelMembersAvailabilityWeekend({
            competencyId: loginInfo?.current_role_competency_id,
            roundDesignationIds: eligibleDesignationIds,
            slotsProvided: providedSlots,
            roundName: roundsFilter,
            periodFrom: new Date(
              new Date(weekendDriveDate).setHours(0, 0, 0, 0)
            ).toISOString(),
            periodTo: new Date(
              new Date(weekendDriveDate).setHours(23, 59, 59, 59)
            ).toISOString(),
            roleId: loginInfo.role_id,
            pageNumber: activePageNumber,
            query: searchValue,
            activedesignationId: interviewerDesignationIds,
            signal: signal,
            keyValue: keyValue,
            panelGroupId: tagsFilter,
            driveId: weekendDriveSelected && weekendDriveSelected[0]?.id,
            subCompetency: subCompetencyFilter,
            recruiterIds: selectedRecruiters,
          })
        );
      }
    }
  }, [
    activePageNumber,
    dispatch,
    keyValue,
    loginInfo,
    searchValue,
    signal,
    weekendDriveDate,
    weekendDriveSelected,
  ]);
  useEffect(() => {
    if (loginInfo.role_id) {
      setCompetencyId(loginInfo.current_role_competency_id);
    }
  }, [setCompetencyId, loginInfo]);
  useEffect(() => {
    const verifyDate = compareDateBetweenRange(
      prevWeekendDriveDate,
      weekendDriveDate
    );
    if (verifyDate !== 0) {
      controlAllApi.current = true;
      controlCurrentUserApi.current = true;
      controlDriveDesignationApi.current = true;
      setPrevWeekendDriveDate(weekendDriveDate);
    }
  }, [weekendDriveDate]);
  useEffect(() => {
    if (!isPanelMember(loginInfo.current_role)) {
      if (weekendDriveSelected && weekendDriveSelected[0]?.id) {
        dispatch(
          fetchWeekendDriveFilterOptions({
            driveId: weekendDriveSelected && weekendDriveSelected[0]?.id,
            roleId: loginInfo.role_id,
          })
        );
      }
    }
  }, [
    dispatch,
    loginInfo.current_role,
    loginInfo.role_id,
    weekendDriveSelected,
  ]);
  useEffect(() => {
    let list = recruiterEmails;
    if (recruiterEmails !== null && recruiterEmails?.length) {
      if (list?.length) {
        const formattedRecuriterOptions = list?.reduce((acc, item) => {
          acc.push({ label: item.recruiterEmail, value: item.recruiterId });
          return acc;
        }, []);
        setOptionsRecruiterEmail(formattedRecuriterOptions);
      }
    }
  }, [loginInfo.id, recruiterEmails, dataFilters]);

  let payloadForAllInWeekend = {
    competencyId: loginInfo?.current_role_competency_id,
    roundDesignationIds: weekenddesignationIds,
    slotsProvided: slotsProvided,
    roundName: roundName,
    periodFrom: new Date(
      new Date(weekendDriveDate).setHours(0, 0, 0, 0)
    ).toISOString(),
    periodTo: new Date(
      new Date(weekendDriveDate).setHours(23, 59, 59, 59)
    ).toISOString(),
    roleId: loginInfo.role_id,
    pageNumber: activePageNumber,
    query: searchValue,
    activedesignationId: activedesignationId,
    signal: signal,
    keyValue: keyValue,
    panelGroupId: panelGroupId,
    driveId: weekendDriveSelected && weekendDriveSelected[0]?.id,
    recruiterIds: selectedRecruiters?.map((item) => item.value),
  };
  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const compareDateBetweenRange = (date1, date2) => {
    const formattedDate1 = extractDate(date1);
    const formattedDate2 = extractDate(date2);
    if (formattedDate1 < formattedDate2) {
      return 1;
    } else if (formattedDate1 > formattedDate2) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    <>
      {(loading || loadingForWeekendListByDate || loadingWeekendAvailabity)?<Spinner/>:
        weekendDriveListUpdated?.length &&
      parseInt(totalCountWeekend) !== 0 &&
      userListWeekend?.length &&
      (!loadingForWeekendListByDate ||
        !loading ||
        !loadingWeekendAvailabity) ? (
        <Grid
          container
          className={"globalLayoutHeight"}
          justifyContent="center"
          alignItems="center"
        >
          <WeekendAvailabilityTable
            open={open}
            competencyId={competencyId}
            roundDesignationId={weekenddesignationIds}
            activePageNumber={activePageNumber}
            setActivePageNumber={setActivePageNumber}
            searchValue={searchValue}
            slotsProvided={slotsProvided}
            roundName={roundName}
            stateToogle={stateToogle}
            activedesignationId={activedesignationId}
            weekendDriveList={weekendDriveList}
            weekendDriveDate={weekendDriveDate}
            keyValue={keyValue}
            weekendDriveListUpdated={weekendDriveListUpdated}
            loggedInUserPanelDetails={loggedInUserPanelDetails}
            allowDriveCoordinatorsForBooking={allowDriveCoordinatorsForBooking}
            panelGroupId={panelGroupId}
            weekendDriveSelected={weekendDriveSelected}
            payloadForAllInWeekend={payloadForAllInWeekend}
            subCompetency={subCompetency}
            selectedRecruiters={selectedRecruiters}
            handlePageClick={handlePageClick}
            tagsBadge={tagsBadge}
          />
        </Grid>
      ) : (!weekendDriveListUpdated?.length ||
          (parseInt(totalCountWeekend) === 0 && !userListWeekend?.length)) &&
        (!loadingForWeekendListByDate ||
          !loading ||
          !loadingWeekendAvailabity) ? (
        <NoData />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Weekend;
