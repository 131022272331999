import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "../../../components/Spinner";

import {
  removeFromPanel,
  deleteRoundMappingPanel,
} from "../../../redux/features/Roster/rosterSlice";

import {
  Modal,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import { extractTime } from "../../../utils/date-utils";

import { ROSTER_FILTER_TAB_CHECK_VALUE, ROLE_TYPES } from "../../../constants";

import styles from "./style.module.scss";

const { isAdmin } = ROLE_TYPES;

const returnHeading = (bookings, deleteRoundId, removeFromPanelId) => {
  if (bookings && !bookings.length) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={12} xs={12} xl={12} md={12}>
          <Typography className={styles.suspensionHeading}>
            Are you sure you want to delete this item?
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Typography className={styles.suspensionHeading}>
        {deleteRoundId && <>Upcoming Bookings For Round</>}
        {removeFromPanelId && <>Upcoming Bookings For Panelist</>}{" "}
      </Typography>
    );
  }
};
const returnText = (bookings, deleteRoundId) => {
  if (!bookings) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography>Failed To Load Bookings.</Typography>
      </Grid>
    );
  }
  if (bookings && bookings.length) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TableContainer
            sx={{
              borderRadius: "10px",
              border: "1px solid #EFF0F5",
            }}
            className={styles.modalBody}
          >
            <Table aria-label="all-table">
              <TableHead className={styles.tableHead}>
                <TableRow className={styles.tableRow}>
                  <TableCell
                    className={`${styles.tableHeadingStyle} `}
                    align="left"
                  >
                    Booking Date
                  </TableCell>
                  <TableCell
                    className={`${styles.tableHeadingStyle} `}
                    align="left"
                  >
                    Booking Time
                  </TableCell>
                  <TableCell
                    className={`${styles.tableHeadingStyle} `}
                    align="left"
                  >
                    Booking Assigned By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings?.map((booking) => (
                  <TableRow key={booking.id} sx={{ border: 0 }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.nameCellAll}
                    >
                      <Typography className={styles.tableBodyStyle}>
                        {new Date(booking.bookingDate).toLocaleDateString()}
                      </Typography>
                    </TableCell>

                    <TableCell className={styles.emailCellAll} align="left">
                      <Typography className={styles.tableBodyStyle}>
                        {extractTime(booking.startTime)}
                        {"-"}
                        {extractTime(booking.endTime)}{" "}
                      </Typography>
                    </TableCell>

                    <TableCell className={styles.emailCellAll} align="left">
                      <Typography className={styles.tableBodyStyle}>
                        {booking.bookingAssignedBy}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <Alert severity="warning" sx={{ marginTop: "1rem" }}>
            <AlertTitle sx={{ fontWeight: "bold" }}>Warning !!!</AlertTitle>
            <Typography>
              The selected {deleteRoundId ? "round" : "panel"} has already
              interviews scheduled, please have them cancelled. And if you press{" "}
              <strong>remove</strong>, then upcoming bookings will also get
              removed including paired bookings.
            </Typography>
            <hr />
            <Typography>
              <b>Are you sure you want to continue?</b>
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    );
  }
};

const DeleteBookingConfirmModal = ({
  loadBookings,
  showDeleteBookingConfirmModal,
  setShowDeleteBookingConfirmModal,
  deleteRoundId,
  activePageNumber,
  searchValue,
  panelGroupId,
  roundName,
  sortBy,
  designationIds,
  removeFromPanelId,
  activedesignationId,
  dateOfJoining,
  gender,
  subCompetency,
  bookings,
  signal,
  isFilterApplied,
  competencyIdFilter,
  keyvalue,
}) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const {
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    subCompetencyState,
    dateOfJoiningState,
    roundsNameState,
    tagsState,
    genderState,
    competencyIdState,
  } = useSelector((state) => state.rosterReducer?.roster);
  const filterStatus = useSelector(
    (state) => state.rosterReducer?.isFilterApplied
  );

  const handleClose = () => setShowDeleteBookingConfirmModal(false);
  const handleDeleteRound = () => {
    if (deleteRoundId) {
      dispatch(
        deleteRoundMappingPanel({
          roundId: deleteRoundId,
          panelCompetencyId: loginInfo.current_role_competency_id,
          roleId: loginInfo.role_id,
          pageNumber: activePageNumber,
          query: searchValue,
          roundName: roundsNameState,
          panelGroupId:
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
              ? tagsState
              : null,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          activedesignationId: interviewerDesignationIdsState,
          dateOfJoining: dateOfJoiningState,
          subCompetency: subCompetencyState,
          designationIds: activeDesignationIdsState,
          signal,
          dispatch,
        })
      );
    }
    if (removeFromPanelId) {
      dispatch(
        removeFromPanel({
          panelId: removeFromPanelId,
          roleId: loginInfo.role_id,
          userByCompetencyPayload: {
            roleId: loginInfo.role_id,
            panelCompetencyId: loginInfo.current_role_competency_id,
            competencyId:
              filterStatus && competencyIdState.toString()?.length
                ? competencyIdState
                : isAdmin(loginInfo.current_role)
                ? ""
                : loginInfo.current_role_competency_id,
            designationIds: activeDesignationIdsState,
            pageNumber: activePageNumber,
            sortType: sortBy.sortType,
            sortOrder: sortBy.sortOrder,
            query: searchValue,
            dateOfJoining: dateOfJoiningState,
            gender: genderState,
            subCompetency: subCompetencyState,
          },
          dispatch,
        })
      );
    }
    handleClose();
  };

  if (loadBookings) return <Spinner />;

  return (
    <Modal open={showDeleteBookingConfirmModal} onClose={handleClose}>
      <Box className={`${styles.modalBox} ${styles.paddingmodalBox}`}>
        <Grid container spacing={0.75}>
          <Grid item xs={12} md={12} xl={12} lg={12}>
            {returnHeading(bookings, deleteRoundId, removeFromPanelId)}
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => handleClose(e)}
              sx={{ position: "absolute", right: 8, top: 26 }}
            >
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          </Grid>
        </Grid>
        {returnText(bookings, deleteRoundId)}

        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={1}
          fullWidth
          paddingRight={1}
        >
          <Grid xs={5} margin={1}>
            <Button
              fullWidth
              variant={"outlined"}
              size="large"
              disabled={!bookings}
              className={styles.cancelBtn}
              onClick={(e) => handleClose(e)}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              <Typography variant="subtitle2">Cancel</Typography>
            </Button>
          </Grid>
          <Grid xs={5} margin={1}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={!bookings}
              className={
                !bookings ? `${styles.saveBtnDisable}` : `${styles.saveBtn}`
              }
              onClick={() => handleDeleteRound()}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              {deleteRoundId && <>Remove Round</>}
              {removeFromPanelId && (
                <>
                  <Typography color={"white"} variant="subtitle2">
                    Yes
                  </Typography>
                </>
              )}{" "}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteBookingConfirmModal;
