import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchExistingCandidate } from "../../../../redux/features/Availability/availabilitySlice";

import { Grid, Autocomplete, TextField, Button, Typography } from "@mui/material";

import UploadIcon from "../../../../assets/icons/upload-icon.svg";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

import { phone } from "phone";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { styled } from "@mui/material/styles";
import styles from "./style.module.scss";
import { INTERVIEW_STATUS_CHECK, ALPHA_NUMERIC_VALUES } from "../../../../constants";

let controller;

const CandidateDetailsStep = (props) => {
  const {
    interviewStatus,
    mobileNumberRef,
    mobileNumberErrorMessage,
    isDisableMultipleFields,
    setMobileNumberErrorMessage,
    defaultCountry,
    setDefaultCountry,
    countryCodeEditable,
    intervieweName,
    setIntervieweName,
    selectedEmail,
    setSelectedEmail,
    fileName,
    setFileName,
    intervieweEmail,
    setIntervieweEmail,
    mobileNumber,
    setMobileNumber,
    handleDisableCandidateMobileNumber,
    setHandleDisableCandidateMobileNumber,
    cvLink,
    setCvLink,
    booking: bookingDataForUpdate,
    pairingData: pairingDataEnabled,
    handleRequiredCandidateName,
    handleRequiredCandidateEmail,
    setHandleRequiredCandidateName,
    setHandleRequiredCandidateEmail,
    disableFieldsForPairedView,
    isUpdateModalOpen,
    changedToScheduled
  } = props;
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const loginInfo = useSelector((state) => state?.authReducer?.loginInfo);
  const candidateEmails = useSelector(
    (state) => state?.availabilityReducer?.candidateDetails
  );
  const [optionsCandidate, setOptionsCandidate] = useState([]);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showInputComponent, setShowInputComponent] = useState(false);
  const [signal, setSignal] = useState(null);
  const [alphaNumericCheck, setAlphaNumericCheck] = useState(false);

  useEffect(() => {
    if (cvLink === null && showInputComponent) {
      fileInputRef.current.click();
    }
  }, [showInputComponent, cvLink]);
  useEffect(() => {
    setShowEditIcon(false);
    if (
      pairingDataEnabled?.candidate_resume_link ||
      bookingDataForUpdate?.candidateResumeLink
    ) {
      setShowEditIcon(true);
    }
  }, [
    pairingDataEnabled?.candidate_resume_link,
    bookingDataForUpdate?.candidateResumeLink,
  ]);
  useEffect(() => {
    if (selectedEmail !== null && selectedEmail.length) {
      dispatch(
        fetchExistingCandidate({
          searchQuery: selectedEmail,
          roleId: loginInfo?.role_id,
          competencyId: loginInfo?.current_role_competency_id,
          signal: signal,
        })
      );
    }
  }, [
    dispatch,
    loginInfo?.current_role_competency_id,
    loginInfo?.role_id,
    selectedEmail,
    signal,
  ]);
  useEffect(() => {
    if (
      pairingDataEnabled &&
      pairingDataEnabled !== "undefined" &&
      Object.keys(pairingDataEnabled).length !== 0
    ) {
      setIntervieweName(pairingDataEnabled?.candidate_name ?? "");
      setIntervieweEmail(pairingDataEnabled?.candidate_email ?? "");
      setCvLink(
        pairingDataEnabled?.candidate_resume_link?.length
          ? pairingDataEnabled?.candidate_resume_link
          : null
      );
      setMobileNumber(pairingDataEnabled?.mobile_number ?? "");
    } else if (
      bookingDataForUpdate &&
      bookingDataForUpdate?.candidateDetailsId !== null
    ) {
      setIntervieweName(bookingDataForUpdate?.candidateName ?? "");
      setIntervieweEmail(bookingDataForUpdate?.candidateEmail ?? "");
      setCvLink(
        bookingDataForUpdate?.candidateResumeLink?.length
          ? bookingDataForUpdate?.candidateResumeLink
          : null
      );
      setMobileNumber(bookingDataForUpdate?.candidateMobileNumber ?? "");
    }
  }, [pairingDataEnabled, bookingDataForUpdate]);
  useEffect(() => {
    if (candidateEmails.length === 1 && selectedEmail.length > 0) {
      setIntervieweName(candidateEmails[0]?.name ?? "");
      setIntervieweEmail(candidateEmails[0]?.email ?? "");
      setMobileNumber(candidateEmails[0]?.mobile_number ?? '');
      setCvLink(
        candidateEmails[0]?.resume_link?.length
          ? candidateEmails[0]?.resume_link
          : null
      );
      setHandleRequiredCandidateName(!candidateEmails[0]?.name?.length);
      validateEmail(candidateEmails[0]?.email);
      if (candidateEmails[0]?.resume_link?.length) {
        setShowEditIcon(true);
      }
      if (!candidateEmails[0]?.resume_link?.length) {
        setShowEditIcon(false);
      }
    }
  }, [
    candidateEmails,
    selectedEmail,
    setCvLink,
    setHandleDisableCandidateMobileNumber,
    setIntervieweEmail,
    setIntervieweName,
    setMobileNumber,
  ]);
  useEffect(() => {
    if (candidateEmails?.length) {
      const formattedOptions = candidateEmails?.reduce((acc, item) => {
        acc.push({ label: item.email });
        return acc;
      }, []);
      setOptionsCandidate(formattedOptions);
    }
  }, [candidateEmails]);

  const handleChange = (e) => {
    setSelectedEmail(e.target.value);
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    setSignal(controller.signal);
  };
  const handleFileChange = (event) => {
    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const file = event.target.files[0];
    if (allowedTypes.includes(file.type)) {
      setFileName(file.name);
      setCvLink(file);
    } else {
      setFileName("");
      setCvLink(null);
    }
  };
  const handleChangeOption = (event, newValue = [], reason, details) => {
    if (reason === "selectOption" && newValue !== null) {
      setSelectedEmail(newValue.label);
    } else if (reason === "clear") {
      setSelectedEmail("");
      setIntervieweName("");
      setIntervieweEmail("");
      setMobileNumber("");
      setCvLink(null);
      setShowEditIcon(false);
    }
  };
  const validateEmail = (value) => {
    const emailregex = /^[a-zA-Z0-9+_.-]+@[a-z]+\.[a-z]{2,3}/;
    const isValidEmail = emailregex.test(value);
    setHandleRequiredCandidateEmail(!value?.length || !isValidEmail);
  };
  const handleNameChange = (e) => {
    setIntervieweName(e.target.value);
    const updatedName = e.target.value;
    setHandleRequiredCandidateName(!updatedName?.length);
    setAlphaNumericCheck(ALPHA_NUMERIC_VALUES.test(updatedName))
  };
  const handleEmailChange = (e) => {
    setIntervieweEmail(e.target.value);
    validateEmail(e.target.value);
  };
  const handleEditClick = () => {
    setShowEditIcon(false);
    setFileName("");
    setCvLink(null);
  };
  const handleCloseClick = () => {
    setFileName("");
    setCvLink(null);
    if (
      pairingDataEnabled &&
      pairingDataEnabled !== "undefined" &&
      Object.keys(pairingDataEnabled).length !== 0
    ) {
      setCvLink(
        pairingDataEnabled?.candidate_resume_link?.length
          ? pairingDataEnabled?.candidate_resume_link
          : null
      );
    } else if (
      bookingDataForUpdate &&
      bookingDataForUpdate?.candidateDetailsId !== null
    ) {
      setCvLink(
        bookingDataForUpdate?.candidateResumeLink?.length
          ? bookingDataForUpdate?.candidateResumeLink
          : null
      );
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Grid container direction={"row"} rowSpacing={1} marginTop={4} gap={2}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth={true}
          disablePortal
          id="combo-box-demo"
          options={optionsCandidate}
          onChange={handleChangeOption}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search By Candidate Name or Email"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          )}
          disabled={disableFieldsForPairedView || isDisableMultipleFields}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          helperText={
            handleRequiredCandidateName || alphaNumericCheck ? "Please provide a valid name" : ""
          }
          id="candidate-name"
          label={
            interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED
              ? "Name*"
              : "Name"
          }
          value={intervieweName}
          onChange={handleNameChange}
          error={handleRequiredCandidateName || alphaNumericCheck}
          disabled={disableFieldsForPairedView || isDisableMultipleFields}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={
            interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED
              ? "Email*"
              : "Email"
          }
          variant="outlined"
          fullWidth
          value={intervieweEmail}
          onChange={handleEmailChange}
          error={handleRequiredCandidateEmail}
          helperText={
            handleRequiredCandidateEmail
              ? "Please enter a valid email address"
              : ""
          }
          disabled={
            disableFieldsForPairedView ||
            isDisableMultipleFields ||
           isUpdateModalOpen ? ((changedToScheduled ? false : (interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED ? false : true)) ? true : false) : false
          }
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneInput
          placeholder="Mobile Number"
          numberInputProps={{
            className: "form-control w-100 border-start border-0",
            ref: mobileNumberRef,
            style: isDisableMultipleFields
              ? { background: "#e9ecef", height: "3rem" }
              : mobileNumberErrorMessage
              ? {
                  fontFamily: "poppins",
                  fontSize: "15px",
                  background: "white",
                  border: "10px solid red",
                  height: "3rem",
                  borderRadius: "4px",
                  border: "none",
                  margin: "0px",
                }
              : {
                  fontFamily: "poppins",
                  fontSize: "15px",
                  background: "white",
                  border: "0px solid #C4C4C4",
                  height: "3rem",
                  borderRadius: "4px",
                },
          }}
          className={styles.phone_input_focused}
          focusInputOnCountrySelection={false}
          defaultCountry={defaultCountry}
          value={mobileNumber}
          style={
            isDisableMultipleFields
              ? { background: "#e9ecef" }
              : mobileNumberErrorMessage
              ? {
                  background: "none",
                  border: "1px solid red",
                  borderRadius: "4px",
                  paddingLeft: "10px",
                }
              : {
                  background: "white",
                  border: "1px solid #C4C4C4",
                  paddingLeft: "10px",
                  borderRadius: "4px",
                }
          }
          onChange={(e) => {
            if (mobileNumberRef?.current?.value?.length === 0) {
              setMobileNumberErrorMessage(false);
            } else if (phone(e).isValid) {
              setMobileNumberErrorMessage(false);
            }
            else
            setMobileNumberErrorMessage(true);
            setMobileNumber(e);
          }}
          limitMaxLength={true}
          countryCallingCodeEditable={countryCodeEditable}
          onCountryChange={(e) => {
            setDefaultCountry(e);
          }}
          international={true}
          disabled={
            isDisableMultipleFields ||
            handleDisableCandidateMobileNumber ||
            disableFieldsForPairedView
          }
        />
        {mobileNumberErrorMessage && (
          <Typography
            color={"red"}
            style={{ fontSize: "0.875rem" }}
            paddingTop={0.5}
          >
            Please provide a valid Mobile Number
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className={styles.uploadButtonStyle}
        >
          {cvLink !== null && showEditIcon ? (
            <Grid item xs={10}>
              <Button href={cvLink} target="_blank" rel="noreferrer">
                View Resume
              </Button>
            </Grid>
          ) : (
            <Grid item xs={10}>
              <Typography fontFamily={'poppins'} fontSize={'15px'} color={'#2E1C41'}>{!fileName?.length ? <>Upload file</> : <>{fileName}</>}</Typography>
            </Grid>
          )}
          <Grid item xs={2} direction={"column"}>
            <Grid container justifyContent={"flex-end"} gap={2}>
              <Grid item>
                <img
                  src={UploadIcon}
                  alt={"upload-icon"}
                  onClick={() => {
                    handleEditClick();
                    setShowInputComponent(true);
                  }}
                  className={
                    !disableFieldsForPairedView && !isDisableMultipleFields
                      ? "cursorPointer"
                      : "cursorNotAllowed"
                  }
                />
              </Grid>
              {!!fileName?.length && (
                <Grid item>
                  <img
                    className={styles.closeIconStyle}
                    src={CloseIcon}
                    alt={"close-icon"}
                    onClick={handleCloseClick}
                  />
                </Grid>
              )}
            </Grid>
            <VisuallyHiddenInput
              type="file"
              accept=".pdf, .docx"
              onChange={(event) => handleFileChange(event)}
              ref={fileInputRef}
              disabled={disableFieldsForPairedView || isDisableMultipleFields}
            ></VisuallyHiddenInput>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CandidateDetailsStep;
