import React from "react";

const NotFoundPage = () => {
  return (
    <div className="page-not-found">
      <div>
        <h1 className="title">Uh Oh!</h1>
        <p className="message">The page you were looking for doesn’t exist.</p>
        <p className="action">
          You might have typed in the wrong address or the page has moved. In
          the meantime, try again
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
