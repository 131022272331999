import React from "react";
import { Navigate } from "react-router-dom";

import { getAuthInfo } from "./app-utils";

const AuthenticatedRoute = ({ children }) => {
  let hasToken = false;
  try {
    const authInfo = getAuthInfo();
    if (authInfo && authInfo.authToken) {
      hasToken = true;
    }
  } catch (err) {
    console.log(`AUTH Error`, err);
  }

  if (hasToken) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default AuthenticatedRoute;
