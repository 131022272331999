import React from "react";

import { Typography, Grid } from "@mui/material";

import ToastWarningIcon from "../../../../assets/icons/toast-warning.svg";
import ToastWarningForBookingOverlay from "../../../../assets/icons/toast-warning-status-icon.svg";

import styles from "./style.module.scss";

const WarningPopup = ({ WarningMessage, showBookingNa = false }) => {
  return (
    <>
      <Grid
        container
        gap={2}
        padding={1}
        marginTop={2}
        marginBottom={2}
        direction={"row"}
        wrap={"nowrap"}
        className={
          showBookingNa
            ? styles.warningPopupContainerForNaStatus
            : styles.warningPopupContainer
        }
      >
        <Grid item xs={1}>
          <img
            src={
              showBookingNa ? ToastWarningForBookingOverlay : ToastWarningIcon
            }
            alt="toast-warning-icon"
          />
        </Grid>
        <Grid item xs={11} marginLeft={1}>
          <Typography className={styles.warningPopupTitle}>Warning!</Typography>
          <Typography className={styles.warningPopupSubTitle}>
            {WarningMessage}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default WarningPopup;
