import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put, remove } from "../../../utils/app-utils";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import {
  TOAST_TYPE,
  TOAST_TIME,
  TOAST_TITLE,
  ROUND_FILTER_OPTIONS,
} from "../../../constants/index";
import { BASE_URL } from "../../../constants/index";
import { fetchPanelMembersAvailability } from "../Availability/availabilitySlice";

const initialState = {
  loading: false,
  isFilterApplied: false,
  roster: {
    competencyIdState: "",
    multicompetencyIdsState: [],
    interviewerDesignationIdsState: [],
    eligibleDesignationIdsState: [],
    genderState: "",
    roleTypesState: "",
    subCompetencyState: [],
    dateOfJoiningState: { from: "", to: "" },
    dateOfJoiningLabel:'',
    roundsNameState: ROUND_FILTER_OPTIONS.ALL,
    tagsState: [],
  },
  designationloading: false,
  loggedInUserDesignation: {},
  adminFilterAllDesignations: {},
  userData: {},
  userDataLoading: false,
  suspendedList: {},
  panelData: {},
  panelDataLoading: false,
  userComments: [],
  commentsLoading: false,
  pageCount: 0,
  SuspendedUsersHistory: [],
  historyLoading: false,
  activeDesignation: {},
  bookingsCount: 0,
  bookings: [],
  loadBookings: false,
};
export const getDesignationByCompetencyId = createAsyncThunk(
  "getDesignationByCompetencyId",
  async ({ roleId, competencyId }) => {
    try {
      const response = await get(
        `${BASE_URL}/dashboard/designation/list`,
        {
          queryParams: { role_id: roleId, competencyId },
        },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getAdminDesignationListByCompetencyId = createAsyncThunk(
  "getAdminDesignationListByCompetencyId",
  async ({ roleId, competencyIds = [] }) => {
    try {
      const competencyIdsArray = competencyIds?.map((item) => item.value);
      const response = await get(
        `${BASE_URL}/dashboard/designation/multi-list`,
        {
          queryParams: { role_id: roleId, competencyIds: competencyIdsArray },
        },
        false
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const fetchActivePanelByCompetency = createAsyncThunk(
  "fetchActivePanelByCompetency",
  async ({
    competencyId,
    interviewerDesignation = [],
    roleId,
    pageNumber,
    sortType,
    sortOrder,
    activedesignationId,
    signal,
    joiningDate,
    subCompetency = [],
    designationIds,
    roundName,
    panelGroupId = [],
    query,
  }) => {
    try {
      const subCompetencyIds = subCompetency?.map((item) => item.value);
      const response = await get(
        `${BASE_URL}/dashboard/panel/all`,
        {
          queryParams: {
            competencyId,
            roundDesignationIds: JSON.stringify(designationIds),
            pageNumber,
            sortType,
            sortOrder,
            role_id: roleId,
            roundName,
            query,
            panelGroupId: JSON.stringify(panelGroupId),
            activedesignationId: JSON.stringify(activedesignationId),
            dateOfJoining: JSON.stringify(joiningDate),
            subCompetency: JSON.stringify(subCompetencyIds),
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const getUserByCompetency = createAsyncThunk(
  "getUserByCompetency",
  async ({
    competencyId,
    designationIds,
    roleId,
    pageNumber,
    panelCompetencyId,
    sortType,
    sortOrder,
    signal,
    dateOfJoining,
    subCompetency = [],
    gender,
    query,
  }) => {
    try {
      const designationIdsArray = designationIds?.map((item) => item.value);
      const subCompetencyIds = subCompetency?.map((item) => item.value);
      const response = await get(
        `${BASE_URL}/dashboard/user`,

        {
          queryParams: {
            competencyId,
            panelCompetencyId,
            designationIds: designationIdsArray,
            pageNumber,
            sortType,
            sortOrder,
            role_id: roleId,
            query,
            dateOfJoining: JSON.stringify(dateOfJoining),
            subCompetency: JSON.stringify(subCompetencyIds),
            gender,
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchSuspendedListByCompetency = createAsyncThunk(
  "fetchSuspendedListByCompetency",
  async ({
    competencyId,
    roleId,
    pageNumber,
    sortType,
    sortOrder,
    query,
    signal,
  }) => {
    try {
      const response = await get(
        `${BASE_URL}/dashboard/user/temporarySuspended/list`,

        {
          queryParams: {
            competencyId,
            pageNumber,
            sortType,
            sortOrder,
            role_id: roleId,
            query,
          },
        },
        {},
        signal
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deleteTemporarySuspendedMember = createAsyncThunk(
  "deleteTemporarySuspendedMember",
  async ({ roleId, panelId }) => {
    try {
      const response = await post(
        `${BASE_URL}/dashboard/panel/removeTemporarySuspendFromPanel`,
        { queryParams: { role_id: roleId, panelId } }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchCommentsForUser = createAsyncThunk(
  "fetchCommentsForUser",
  async ({ roleId, userId }) => {
    try {
      const response = await get(
        `${BASE_URL}/dashboard/user/getComments`,
        {
          queryParams: {
            role_id: roleId,
            userId,
          },
        },
        {}
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchSuspensionHistoryForUser = createAsyncThunk(
  "fetchSuspensionHistoryForUser",
  async ({ roleId, competencyId, userId }) => {
    try {
      const response = await get(
        `${BASE_URL}/dashboard/user/getSuspendedHistory`,
        {
          queryParams: {
            role_id: roleId,
            competencyId,
            userId,
          },
        },
        {}
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const addCommentCompetencyMember = createAsyncThunk(
  "addCommentCompetencyMember",
  async ({
    roleId,
    key,
    userId,
    comment,
    activePanelCompetencyPayload,
    userByCompetencyPayload,
    availabilityPanelPayload,
    dispatch,
  }) => {
    try {
      const response = await put(
        `${BASE_URL}/dashboard/user/addComment`,
        {
          queryParams: {
            role_id: roleId,
            userId,
          },
        },
        { comment: comment }
      );
      if (key === "ALL") {
        dispatch(getUserByCompetency(userByCompetencyPayload));
      }
      if (key === "ACTIVE") {
        dispatch(fetchActivePanelByCompetency(activePanelCompetencyPayload));
      }
      if (key === "AVAILABILTY") {
        dispatch(fetchPanelMembersAvailability(availabilityPanelPayload));
      }
      dispatch(
        fetchCommentsForUser({
          roleId,
          userId,
        })
      );

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchActiveDesignationByCompetencyId = createAsyncThunk(
  "fetchActiveDesignationByCompetencyId",
  async ({ roleId, competencyId }) => {
    try {
      const response = await get(
        `${BASE_URL}/dashboard/activeDesignation/list`,
        {
          queryParams: {
            role_id: roleId,
            competencyId,
          },
        },
        {}
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const saveAddToPanel = createAsyncThunk(
  "saveAddToPanel",
  async ({
    userId,
    competencyId,
    roundData,
    panelCompetencyId,
    roleId,
    pageNumber,
    roundName,
    query,
    panelGroupId,
    sortType,
    sortOrder,
    activedesignationId,
    dateOfJoining,
    subCompetency,
    designationIds,
    handleTabsChange,
    dispatch,
    signal,
  }) => {
    let url;
    if (competencyId) {
      url = `${BASE_URL}/dashboard/user/${userId}/competency/${competencyId}/add-round-mapping`;
    } else {
      url = `${BASE_URL}/dashboard/user/${userId}/competency/${panelCompetencyId}/add-panel-round-mapping`;
    }
    try {
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { rounds: roundData }
      );
      if (competencyId) {
        dispatch(
          fetchActivePanelByCompetency({
            competencyId: panelCompetencyId,
            roleId,
            pageNumber,
            query,
            roundName,
            panelGroupId,
            designationIds,
            sortType,
            sortOrder,
            activedesignationId,
            joiningDate: dateOfJoining,
            subCompetency,
            signal: signal,
          })
        );
      }
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const fetchUpcomingBookings = createAsyncThunk(
  "fetchUpcomingBookings",
  async ({ roundId, panelId, roleId, driveDate, periodFrom }) => {
    try {
      const queryParams = {
        role_id: roleId,
        roundId,
        panelId,
        driveDate,
      };
      if (periodFrom) {
        queryParams.periodFrom = periodFrom;
      }
      const response = await get(
        `${BASE_URL}/dashboard/user/upcoming-bookings`,
        {
          queryParams,
        }
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const removeFromPanel = createAsyncThunk(
  "removeFromPanel",
  async ({ panelId, roleId, userByCompetencyPayload, dispatch }) => {
    try {
      const response = await remove(
        `${BASE_URL}/dashboard/user/panel/${panelId}`,
        { queryParams: { role_id: roleId } }
      );
      dispatch(getUserByCompetency(userByCompetencyPayload));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deleteRoundMappingPanel = createAsyncThunk(
  "deleteRoundMappingPanel",
  async ({
    roundId,
    panelCompetencyId,
    pageNumber,
    roleId,
    query,
    panelGroupId,
    designationIds,
    roundName,
    sortType,
    sortOrder,
    deleteBookings,
    activedesignationId,
    dateOfJoining,
    subCompetency,
    dispatch,
    signal,
  }) => {
    try {
      const response = await remove(
        `${BASE_URL}/dashboard/user/round/${roundId}`,
        { queryParams: { role_id: roleId } }
      );
      dispatch(
        fetchActivePanelByCompetency({
          competencyId: panelCompetencyId,
          roleId,
          pageNumber,
          query,
          roundName,
          panelGroupId,
          designationIds,
          sortType,
          sortOrder,
          activedesignationId,
          joiningDate: dateOfJoining,
          subCompetency,
          signal: signal,
        })
      );

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deletePanelGroupMapping = createAsyncThunk(
  "deletePanelGroupMapping",
  async ({
    panelGroupId,
    panelCompetencyId,
    pageNumber,
    roleId,
    query,
    designationIds,
    groupId,
    roundName,
    sortType,
    sortOrder,
    activedesignationId,
    dateOfJoining,
    subCompetency,
    dispatch,
    signal,
  }) => {
    try {
      const response = await remove(
        `${BASE_URL}/panelGroup/panelGroupMapping/${panelGroupId}`,
        { queryParams: { role_id: roleId } }
      );
      dispatch(
        fetchActivePanelByCompetency({
          competencyId: panelCompetencyId,
          roleId,
          pageNumber,
          query,
          roundName,
          panelGroupId: groupId,
          designationIds,
          sortType,
          sortOrder,
          activedesignationId,
          joiningDate: dateOfJoining,
          subCompetency,
          signal: signal,
        })
      );

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const rosterSlice = createSlice({
  name: "Roster",
  initialState,
  reducers: {
    resetRosterState() {
      return {
        ...initialState,
      };
    },
    applyFilterStatus(state, action) {
      return {
        ...state,
        isFilterApplied: true,
      };
    },
    resetRosterFilterStatus(state,action) {
      return {
        ...state,
        isFilterApplied: action.payload,
        roster: {
          ...state.roster,
          competencyIdState: "",
          multicompetencyIdsState: [],
          interviewerDesignationIdsState: [],
          activeDesignationIdsState: [],
          genderState: "",
          roleTypesState: "",
          subCompetencyState: [],
          dateOfJoiningState: { from: "", to: "" },
          dateOfJoiningLabel:"",
          roundsNameState: ROUND_FILTER_OPTIONS.ALL,
          tagsState: [],
        },
      };
    },
    changeCompetencyStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          competencyIdState: action.payload,
        },
      };
    },
    changeMultiCompetencyStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          multicompetencyIdsState: action.payload,
        },
      };
    },
    changeInterviewerDesignationStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          interviewerDesignationIdsState: action.payload,
        },
      };
    },
    changeEligibleDesignationStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          activeDesignationIdsState: action.payload,
        },
      };
    },
    changeGenderStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          genderState: action.payload,
        },
      };
    },
    changeRoleTypeStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          roleTypesState: action.payload,
        },
      };
    },
    changeSubcompetencyStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          subCompetencyState: action.payload,
        },
      };
    },
    changedateOfJoiningStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          dateOfJoiningState: action.payload.date,
          dateOfJoiningLabel:action.payload.value,
        },
      };
    },
    changeRoundNameStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          roundsNameState: action.payload,
        },
      };
    },
    changeTagStatus(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          tagsState: action.payload,
        },
      };
    },
    getCompetencyIdForFilters(state, action) {
      return {
        ...state,
        roster: {
          ...state.roster,
          competencyIdState: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDesignationByCompetencyId.pending, (state) => {
        state.designationloading = true;
      })
      .addCase(getDesignationByCompetencyId.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            designationloading: false,
            loggedInUserDesignation: action.payload?.designation,
          };
        }
        return { ...state, designationloading: false };
      })
      .addCase(getDesignationByCompetencyId.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while fetching designations"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.designationloading = false;
      })
      .addCase(getAdminDesignationListByCompetencyId.pending, (state) => {
        state.designationloading = true;
      })
      .addCase(
        getAdminDesignationListByCompetencyId.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              designationloading: false,
              adminFilterAllDesignations: action.payload?.designation,
            };
          }
          return { ...state, designationloading: false };
        }
      )
      .addCase(
        getAdminDesignationListByCompetencyId.rejected,
        (state, action) => {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message="Error while fetching designations"
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
          state.designationloading = false;
        }
      )
      .addCase(getUserByCompetency.pending, (state) => {
        state.userDataLoading = true;
      })
      .addCase(getUserByCompetency.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            userData: action.payload.data,
            userDataLoading: false,
          };
        }
        return {
          ...state,
          userDataLoading: false,
        };
      })
      .addCase(getUserByCompetency.rejected, (state) => {
        state.userDataLoading = false;
      })
      .addCase(fetchSuspendedListByCompetency.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSuspendedListByCompetency.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            suspendedList: action.payload.data,
            loading: false,
          };
        }
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(fetchSuspendedListByCompetency.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTemporarySuspendedMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTemporarySuspendedMember.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteTemporarySuspendedMember.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error deleting temporary suspended member"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(fetchActivePanelByCompetency.pending, (state) => {
        state.panelDataLoading = true;
      })
      .addCase(fetchActivePanelByCompetency.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            panelData: action.payload.data,
            panelDataLoading: false,
          };
        }
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(fetchActivePanelByCompetency.rejected, (state, action) => {
        state.panelDataLoading = false;
      })
      .addCase(fetchCommentsForUser.pending, (state, action) => {
        state.commentsLoading = true;
      })
      .addCase(fetchCommentsForUser.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            userComments: action.payload.comments,
            commentsLoading: false,
          };
        }
        return {
          ...state,
          commentsLoading: false,
        };
      })
      .addCase(fetchCommentsForUser.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while fetching comments for user."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.commentsLoading = false;
      })
      .addCase(fetchSuspensionHistoryForUser.pending, (state, action) => {
        state.historyLoading = true;
      })
      .addCase(fetchSuspensionHistoryForUser.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            pageCount: action.payload.data.pageCount,
            SuspendedUsersHistory: action.payload.data.SuspendedUsersHistory,
            historyLoading: false,
          };
        }
        return {
          ...state,
          historyLoading: false,
        };
      })
      .addCase(fetchSuspensionHistoryForUser.rejected, (state, action) => {
        state.historyLoading = false;
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while fetching suspension history of user."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
      })
      .addCase(addCommentCompetencyMember.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while adding comment."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.commentsLoading = false;
      })
      .addCase(addCommentCompetencyMember.pending, (state) => {
        state.commentsLoading = true;
      })
      .addCase(addCommentCompetencyMember.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message="Comment added successfully."
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        return {
          ...state,
          commentsLoading: false,
        };
      })
      .addCase(fetchActiveDesignationByCompetencyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchActiveDesignationByCompetencyId.fulfilled,
        (state, action) => {
          if (action.payload) {
            return {
              ...state,
              loading: false,
              activeDesignation: action.payload?.activeDesignation,
            };
          }
          return { ...state, loading: false };
        }
      )
      .addCase(
        fetchActiveDesignationByCompetencyId.rejected,
        (state, action) => {
          state.loading = false;
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message="Error while fetching active designations."
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        }
      )
      .addCase(saveAddToPanel.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveAddToPanel.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={action?.payload?.data?.message}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            loading: false,
          };
        }
        return { ...state, loading: false };
      })
      .addCase(saveAddToPanel.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error while adding panel member round."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(fetchUpcomingBookings.pending, (state) => {
        state.loadBookings = true;
        state.bookingsCount = null;
        state.bookings = [];
      })
      .addCase(fetchUpcomingBookings.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loadBookings: false,
            bookingsCount: action.payload.data?.bookingsCount,
            bookings: action.payload.data?.bookings,
          };
        }
        return { ...state, loadBookings: false };
      })
      .addCase(fetchUpcomingBookings.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching upcoming bookings"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loadBookings = false;
      })
      .addCase(removeFromPanel.pending, (state) => {
        state.loadBookings = true;
        state.bookingsCount = 0;
        state.bookings = [];
      })
      .addCase(removeFromPanel.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={action?.payload?.data?.message}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return {
            ...state,
            loadBookings: false,
            bookingsCount: action.payload.data?.bookingsCount,
            bookings: action.payload.data?.bookings,
          };
        }
        return { ...state, loadBookings: false };
      })
      .addCase(removeFromPanel.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Failed removing user from panel"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loadBookings = false;
      })
      .addCase(deleteRoundMappingPanel.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoundMappingPanel.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={action?.payload?.data?.message}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return { ...state, loading: false };
        }
        return { ...state, loading: false };
      })
      .addCase(deleteRoundMappingPanel.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Failed removing round from panel"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deletePanelGroupMapping.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePanelGroupMapping.fulfilled, (state, action) => {
        if (action.payload) {
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={action?.payload?.data?.message}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
          return { ...state, loading: false };
        }
        return { ...state, loading: false };
      })
      .addCase(deletePanelGroupMapping.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Failed removing tags from panel"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      });
  },
});
export const {
  resetRosterState,
  changeCompetencyStatus,
  applyFilterStatus,
  resetRosterFilterStatus,
  changeMultiCompetencyStatus,
  changeInterviewerDesignationStatus,
  changeEligibleDesignationStatus,
  changeGenderStatus,
  changeRoleTypeStatus,
  changeSubcompetencyStatus,
  changedateOfJoiningStatus,
  changeRoundNameStatus,
  changeTagStatus,
  getCompetencyIdForFilters,
} = rosterSlice.actions;
export default rosterSlice.reducer;
