import * as React from "react";
import { TextField, Autocomplete } from "@mui/material";

export default function SingleSelectComponent({
  disabled,
  label,
  value,
  onChange,
  options,
  disabledOptions
}) {
  const [selectedValue,setSelectedValue] = React.useState(null);
  let optionValues = [];
  if (Array.isArray(options)) {
    optionValues = options;
  } else {
    optionValues = Object.keys(options)?.map((key) => ({
      label: options[key],
      value: options[key],
    }));
  }
  
  React.useEffect(()=>{
    if(optionValues?.length>0){
      setSelectedValue(optionValues?.find((option) => option.value == value))
    }
  },[optionValues])

  const isOptionDisabled = (option) => {
    return disabledOptions && disabledOptions.includes(option.value);
  };

  return (
    <Autocomplete
      fullWidth
      value={selectedValue}
      disablePortal
      onChange={(event, newValue) => {
        onChange(newValue || { value: "" });
      }}
      disabled={disabled}
      id="combo-box-demo"
      options={optionValues}
      getOptionLabel={(option) => option.label}
      getOptionDisabled={(option) => isOptionDisabled(option)}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
