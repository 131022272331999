import React from "react";

import Spinner from "../../../components/Spinner";

import {
  Modal,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import { extractDateMonth } from "../../../utils/app-utils";

import styles from "./style.module.scss";

const SuspensionHistoryModal = ({
  showSuspendedHistoryModal,
  setShowSuspendedHistoryModal,
  selectedPanel,
  SuspendedUsersHistoryList,
  SuspendedUsersHistoryLoading,
  SuspendedUsersHistoryPageCount,
}) => {
  const handleClose = () => {
    setShowSuspendedHistoryModal(false);
  };

  if (SuspendedUsersHistoryLoading) return <Spinner />;

  return (
    <Modal open={showSuspendedHistoryModal} onClose={handleClose}>
      <Box className={`${styles.modalBox} ${styles.paddingmodalBox}`}>
        <Grid container spacing={0.75}>
          <Grid item xs={12} md={12} xl={12} lg={12}>
            <Typography className={styles.suspensionHeading}>
              View Suspension History:{" "}
              {`${selectedPanel.first_name} ${selectedPanel.last_name} (${selectedPanel.employee_code})`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => handleClose(e)}
              sx={{ position: "absolute", right: 8, top: 20 }}
            >
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          {!SuspendedUsersHistoryList?.length ? (
            <Grid item xs={12} marginTop={2}>
              <Typography className={styles.no_comment}>
                No Suspension History available.
              </Typography>
            </Grid>
          ) : (
            <TableContainer
              sx={{
                margin: "12px 0",
                borderRadius: "10px",
                paddingLeft: "27px",
                paddingRight: "27px",
                border: "1px solid #EFF0F5",
              }}
              className={styles.modalBody}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Suspended From</TableCell>
                    <TableCell align="left">Suspended To</TableCell>
                    <TableCell align="left">Suspended By</TableCell>
                    <TableCell align="left">Resumed By</TableCell>
                    <TableCell align="left">Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {SuspendedUsersHistoryList?.map((user) => (
                    <TableRow
                      key={user.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{user.name}</TableCell>
                      <TableCell align="left">
                        {extractDateMonth(user.suspended_from)}
                      </TableCell>
                      <TableCell align="left">
                        {extractDateMonth(user.suspended_to)}
                      </TableCell>
                      <TableCell align="left">{user.suspended_by}</TableCell>
                      <TableCell align="left">{user.resumed_by}</TableCell>
                      <TableCell align="left">{user.comments}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default SuspensionHistoryModal;
