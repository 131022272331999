import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AutoCompleteComponent from "../../../components/AutoComplete";

import { saveAddToPanel } from "../../../redux/features/Roster/rosterSlice";

import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import closeIcon from "../../../assets/icons/close-icon.svg";

import styles from "./style.module.scss";
import theme from "../../../theme/index";

const AddToPanelModal = ({
  competencyId,
  selectedPanel,
  showAddToPanelModal,
  setShowAddToPanelModal,
  setKeyValue,
  loginInfo,
  activePageNumber,
  setActivePageNumber,
  setActiveAccordion,
  activeAccordion,
  handleTabsChange,
}) => {
  const dispatch = useDispatch();
  const activeDesignations = useSelector(
    (state) => state.rosterReducer?.activeDesignation
  );

  const [selectedR1, setSelectedR1] = useState([]);
  const [selectedR2, setSelectedR2] = useState([]);
  const [selectedMR, setSelectedMR] = useState([]);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const desData = Object.entries(activeDesignations);
    if (desData.length) {
      const newOptions = desData.reduce((acc, [id, { name }]) => {
        let counter = 0;
        while (counter < 2) {
          if (counter === 1) {
            acc.push({
              id: `${id}-P`,
              value: `${id}-P`,
              label: `${name}(Pairing)`,
            });
          } else {
            acc.push({
              id,
              value: `${id}`,
              label: name,
            });
          }
          counter++;
        }
        return acc;
      }, []);
      setOptions(newOptions);
    }
  }, [activeDesignations]);

  const handleClose = () => setShowAddToPanelModal(false);
  const extractDesignationIdAndPairingMode = (item) => {
    const { value } = item;
    const designationId = parseInt(value.split("-")[0]);
    const isPairing = value.split("-")[1] === "P" ? true : false;
    return { designationId, isPairing };
  };
  const handleRound1Change = (event) => {
    setSelectedR1(event);
  };
  const handleRound2Change = (event) => {
    setSelectedR2(event);
  };
  const handleManagerialRoundChange = (event) => {
    setSelectedMR(event);
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (
      competencyId &&
      selectedPanel.id &&
      (selectedR1.length > 0 || selectedR2.length > 0 || selectedMR.length > 0)
    ) {
      const roundR1 = selectedR1?.map((item) => {
        const { designationId, isPairing } =
          extractDesignationIdAndPairingMode(item);
        return {
          roundName: "R1",
          designationId,
          isPairing,
        };
      });

      const roundR2 = selectedR2?.map((item) => {
        const { designationId, isPairing } =
          extractDesignationIdAndPairingMode(item);
        return {
          roundName: "R2",
          designationId,
          isPairing,
        };
      });
      const roundMR = selectedMR?.map((item) => {
        const { designationId, isPairing } =
          extractDesignationIdAndPairingMode(item);
        return {
          roundName: "MR",
          designationId,
          isPairing,
        };
      });
      await dispatch(
        saveAddToPanel({
          userId: selectedPanel.id,
          panelCompetencyId: loginInfo.current_role_competency_id,
          roundData: [...roundR1, ...roundR2, ...roundMR],
          roleId: loginInfo.role_id,
          pageNumber: activePageNumber,
        })
      );
      setError(false);
      handleTabsChange(e, 1);
      handleClose();
    } else {
      setError(true);
    }
  };

  return (
    <>
      <>
        <Dialog
          open={showAddToPanelModal}
          onClose={handleClose}
          PaperProps={{
            style: {
              height: "32rem",
              width: "35rem",
              minHeight: "30vh",
              maxHeight: "100vh",
              zIndex: 1300,
              borderRadius: "10px",
              boxShadow: "0px 6px 30px 0px #OD",
            },
          }}
        >
          <DialogTitle
            color={theme.palette.secondary.main}
            sx={{ padding: "30px 24px 0px" }}
          >
            Add To Panel
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 18,
              top: 30,
              color: (theme) => theme.palette.grey[500],
            }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <img src={closeIcon} alt="close-icon" />
          </IconButton>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <TextField
                  label="Competency"
                  variant="outlined"
                  fullWidth
                  defaultValue={selectedPanel.competency_name}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <TextField
                  label="Panel Member"
                  variant="outlined"
                  fullWidth
                  defaultValue={`${selectedPanel.first_name} ${selectedPanel.last_name}`}
                  margin="normal"
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12} xl={12}>
                <AutoCompleteComponent
                  options={options}
                  placeholder={"Rounds"}
                  label={"Select Round 1"}
                  onChange={handleRound1Change}
                  selectedValue={selectedR1}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12} xl={12}>
                <AutoCompleteComponent
                  options={options}
                  placeholder={"Rounds"}
                  label={"Select Round 2"}
                  onChange={handleRound2Change}
                  selectedValue={selectedR2}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12} xl={12}>
                <AutoCompleteComponent
                  options={options}
                  placeholder={"Rounds"}
                  label={"Select Managerial Round"}
                  onChange={handleManagerialRoundChange}
                  selectedValue={selectedMR}
                />
              </Grid>
            </Grid>
            {error && (
              <p className="form-error">
                Please fill out all Mandatory(*) fields
              </p>
            )}
          </DialogContent>
          <DialogActions sx={{ padding: "0px 24px 30px" }}>
            <Button
              fullWidth
              disabled={
                !competencyId ||
                !selectedPanel.id ||
                !(selectedR1.length || selectedR2.length || selectedMR.length)
              }
              variant={"contained"}
              onClick={onFormSubmit}
              className={
                !(selectedR1.length || selectedR2.length || selectedMR.length)
                  ? `${styles.saveBtnDisable}`
                  : `${styles.saveBtn}`
              }
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};
export default AddToPanelModal;
