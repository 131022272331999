import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../components/SvgIcon/icon";

import { resetAvailabilityFilterStatus } from "../../../redux/features/Availability/availabilitySlice";

import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Tab,
  Button,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import arrowForward from "../../../assets/icons/arrow-forward-icon.svg";
import arrowBackward from "../../../assets/icons/arrow-backward-icon.svg";
import editIcon from "../../../assets/icons/edit-email.svg";
import expandedIcon from "../../../assets/icons/expand-icon.svg";
import collapsedIcon from "../../../assets/icons/collapsed.svg";
import FilteredIcon from "../../../assets/icons/filtered-icon.svg";
import UnFilteredIcon from "../../../assets/icons/unfiltered.svg";

import { addDays, format, isBefore } from "date-fns";

import { MONTH_NAME, ROLE_TYPES } from "../../../constants/index";

import theme from "../../../theme";
import styles from "./style.module.scss";

const { isHr, isSpoc, isAdmin, isPanelMember } = ROLE_TYPES;

const AvailabilityHeaderTabs = (props) => {
  const {
    onAvailabilityButtonClick,
    setWeekendDriveDate,
    getDriveDates,
    handleTabsChange,
    weekendDriveDate,
    setActivePageNumber,
    activeAccordion,
    switchPreviousWeek,
    setWeekendDriveSelected,
    startWeekDate,
    loginInfo,
    endWeekDate,
    switchNextWeek,
    setCurrentMonth,
    setCurrentWeek,
    currentMonthButtonDisable,
    currentWeekButtonDisable,
    loggedInUserPanelDetails,
    handleChange,
    setShow,
    isFilterApplied,
    setFilterIcon,
    filterIcon,
    stateToogle,
    setStateToogle,
  } = props;
  const dispatch= useDispatch();
  const loadingWeekendDates = useSelector(
    (state) => state.weekendDriveReducer?.loadingForWeekendListByDate
  );
  const loadingWeekend = useSelector(
    (state) =>
      state.availabilityReducer?.weekendAvailabilityData
        ?.loadingWeekendAvailabity
  );
  const isFilterAppliedStatus = useSelector(
    (state) => state?.availabilityReducer?.isFilterApplied
  );

  const today = new Date();
  const setAndSaveWeekendDriveDate = (date) => {
    setWeekendDriveDate(new Date(date).toISOString());
    localStorage.setItem(
      "weekendDriveDate",
      new Date(date).toISOString()
    );
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const DatePicker = () => {
    if (getDriveDates?.length && !loadingWeekendDates && !loadingWeekend) {
      return (
        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <FormControl
            fullWidth
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <InputLabel id="drive-date-label">Select Drive Date</InputLabel>
            <Select
              style={{
                width: "100%",
                height: "40px",
                textAlign: "left",
                backgroundColor: "#FFFFFF",
              }}
              value={weekendDriveDate}
              onChange={(e) => {
                setAndSaveWeekendDriveDate(e.target.value);
                setActivePageNumber(1);
                setWeekendDriveSelected([]);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    maxWidth: 200,
                  },
                },
              }}
              className="weekend-select-date-dropdown"
              labelId="drive-date-label"
              label="Select Drive Date"
              placeholder="Select Drive Date"
              id="drive-date-id"
            >
              {getDriveDates?.map((data) => (
                <MenuItem
                  key={data.driveId}
                  value={new Date(data.driveDate).toISOString()}
                  sx={{ fontSize: "12px" }}
                >
                  {format(new Date(data.driveDate), "dd-MM-yyyy")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    }
    return <></>;
  };

  return (
    <>
      <Grid item xs={1.9} md={1.9} lg={1.9} xl={1.9}>
        <Typography
          variant="h5"
          display="inline-flex"
          color={theme.palette.secondary.main}
          className={"heading"}
        >
          Availability
        </Typography>
      </Grid>
      <Grid container xs={2.7} md={2.7} lg={2.7} xl={2.7}>
        <Grid item xs={5.8} md={5.8} lg={5.8} xl={5.8}>
          <Tabs
            value={activeAccordion}
            onChange={handleTabsChange}
            aria-label="tab"
            className={styles.tabshr}
            sx={{
              minHeight: "30px",
              height: "30px",
              "& button": {
                fontWeight: 400,
                borderRadius: "19.5px",
                height: "30px",
                minHeight: "30px",
                width: "105.25px",
              },
              "& button.Mui-selected": {
                backgroundColor: "#FFFFFF",
                color: theme.palette.secondary.main,
                width: "115px",
                fontWeight: 500,
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#D97D54",
                height: 0,
                top: 0,
              },
            }}
          >
            <Tab label="Weekdays" {...a11yProps(0)} className={styles.Tab} />
            <Tab label="Weekend" {...a11yProps(1)} className={styles.Tab} />
          </Tabs>
        </Grid>
      </Grid>
      {activeAccordion === 0 && !isPanelMember(loginInfo.current_role) && (
        <Grid
          container
          xs={3.4}
          md={3.4}
          lg={3.4}
          xl={3.4}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            xl={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Tooltip title={"Previous Week"} arrow={true} placement="top">
              <IconButton
                aria-label="arrow-backward"
                onClick={() => switchPreviousWeek()}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <img src={arrowBackward} alt="arrow-backward-icon" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography className={styles.weeklyViewDate}>
              {new Date(addDays(new Date(startWeekDate), 1)).getDate()}&nbsp;
              {
                MONTH_NAME[
                  new Date(addDays(new Date(startWeekDate), 1))?.getMonth() + 1
                ]
              }
              &nbsp;-&nbsp;
              {new Date(addDays(new Date(endWeekDate), 1)).getDate()}&nbsp;
              {
                MONTH_NAME[
                  new Date(addDays(new Date(endWeekDate), 1))?.getMonth() + 1
                ]
              }
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            xl={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Tooltip title={"Next Week"} arrow={true} placement="top">
              <IconButton
                aria-label="arrrow-forward"
                onClick={() => switchNextWeek()}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <img src={arrowForward} alt="arrow-forward-icon" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {activeAccordion === 0 && (
        <Grid
          container
          xs={isPanelMember(loginInfo.current_role) ? 7.3 : 4}
          md={isPanelMember(loginInfo.current_role) ? 7.3 : 4}
          lg={isPanelMember(loginInfo.current_role) ? 7.3 : 4}
          xl={isPanelMember(loginInfo.current_role) ? 7.3 : 4}
          display={"flex"}
          justifyContent={"end"}
          alignItems="center"
        >
          <Grid
            item
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              marginRight={1}
            >
              <Button
                onClick={() =>
                  isPanelMember(loginInfo.current_role)
                    ? setCurrentMonth()
                    : setCurrentWeek()
                }
                disabled={
                  isPanelMember(loginInfo.current_role)
                    ? currentMonthButtonDisable()
                    : currentWeekButtonDisable()
                }
                className={
                  (
                    isPanelMember(loginInfo.current_role)
                      ? currentMonthButtonDisable()
                      : currentWeekButtonDisable()
                  )
                    ? `${styles.currentWeekBtnDisable} ${styles.buttonHeight}`
                    : `${styles.currentWeekBtn}  ${styles.buttonHeight}`
                }
              >
                {isPanelMember(loginInfo.current_role)
                  ? "THIS MONTH"
                  : "THIS WEEK"}
              </Button>
            </Grid>
          </Grid>
          {(isPanelMember(loginInfo.current_role) ||
            isSpoc(loginInfo.current_role)) && (
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  variant="contained"
                  className={` ${styles.buttonHeight} ${styles.markAvailabilityButtonStyle}`}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    handleChange();
                  }}
                  disabled={
                    loggedInUserPanelDetails?.is_user_temporary_suspended ||
                    !loggedInUserPanelDetails?.user_details_id 
                  }
                >
                  Mark your Availability
                </Button>
              </Grid>
            </Grid>
          )}
          {isHr(loginInfo.current_role) && (
            <Grid
              item
              xs={1}
              md={1}
              lg={1}
              xl={1}
              paddingTop={0.2}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Tooltip
                title={"Manage Email Reminder"}
                arrow={true}
                placement="top"
              >
                <Box marginTop={0.8}>
                  <Icon
                    icon={editIcon}
                    width={"19.5px"}
                    height={"16px"}
                    click={() => setShow(true)}
                    variant={"outlined"}
                    color={theme.palette.primary.main}
                  />
                </Box>
              </Tooltip>
            </Grid>
          )}
          {!isPanelMember(loginInfo.current_role) && (
            <Grid
              item
              xs={0.9}
              md={0.9}
              lg={1}
              xl={0.9}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <IconButton
                aria-label="edit-reminder-list-button"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={(e) =>
                  setStateToogle({
                    ...stateToogle,
                    compact: !stateToogle.compact,
                  })
                }
              >
                {stateToogle.compact ? (
                  <Tooltip title={"Compact View"} arrow={true} placement="top">
                    <img src={collapsedIcon} alt="collpased-icon" />
                  </Tooltip>
                ) : (
                  <Tooltip title={"Expanded View"} arrow={true} placement="top">
                    <img src={expandedIcon} alt="expanded-icon" />
                  </Tooltip>
                )}
              </IconButton>
            </Grid>
          )}
          {!isPanelMember(loginInfo.current_role) && (
            <Grid
              item
              xs={0.8}
              md={0.8}
              lg={0.8}
              xl={0.8}
              paddingTop={0.5}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <Tooltip title="Filter" placement="top" arrow>
                <Box
                  className={
                    isFilterAppliedStatus
                      ? styles.filteredStyles
                      : styles.unfilteredStyles
                  }
                  onClick={
                    isAdmin(loginInfo.current_role)
                      ? null
                      : () => {
                          setFilterIcon(!filterIcon);
                          if(!isFilterAppliedStatus){
                            dispatch(resetAvailabilityFilterStatus())
                          }
                        }
                  }
                >
                  <Icon
                    icon={isFilterAppliedStatus ? FilteredIcon : UnFilteredIcon}
                    width={"15.5px"}
                    height={"16px"}
                    color={isFilterAppliedStatus ? "#FFFFFF" : null}
                  />
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
      {activeAccordion === 1 && (
        <Grid
          container
          xs={7.4}
          md={7.4}
          lg={7.4}
          xl={7.4}
          display={"flex"}
          spacing={1}
          justifyContent={"end"}
          alignItems="center"
        >
          {(isSpoc(loginInfo?.current_role) ||
            isPanelMember(loginInfo.current_role)) && (
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              xs={4}
              md={4}
              lg={4}
              xl={4}
            >
              <Button
                variant="contained"
                className={` ${styles.buttonHeight} ${styles.markAvailabilityButtonStyle}`}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={(e) => onAvailabilityButtonClick(e)}
                disabled={
                  loggedInUserPanelDetails?.is_user_temporary_suspended ||
                  !loggedInUserPanelDetails?.user_details_id ||
                  (loggedInUserPanelDetails?.user_details_id &&
                    isBefore(new Date(weekendDriveDate), today))
                }
              >
                Weekend Availability
              </Button>
            </Grid>
          )}

          {(isHr(loginInfo.current_role) ||
            isSpoc(loginInfo.current_role) ||
            isPanelMember(loginInfo.current_role)) &&
            DatePicker()}
          {!isPanelMember(loginInfo.current_role) && (
            <Grid
              item
              xs={isFilterApplied ? 0.7 : 0.5}
              md={isFilterApplied ? 0.7 : 0.5}
              lg={isFilterApplied ? 0.7 : 0.5}
              xl={isFilterApplied ? 0.7 : 0.5}
              paddingTop={0.5}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
            >
              {" "}
              <Tooltip title="Filter" placement="top" arrow>
                <Box
                  className={
                    isFilterAppliedStatus
                      ? styles.filteredStyles
                      : styles.unfilteredStyles
                  }
                  onClick={
                    isAdmin(loginInfo.current_role)
                      ? null
                      : () => {
                          setFilterIcon(!filterIcon);
                          if(!isFilterAppliedStatus){
                            dispatch(resetAvailabilityFilterStatus())
                          }
                        }
                  }
                >
                  <Icon
                    icon={isFilterAppliedStatus ? FilteredIcon : UnFilteredIcon}
                    width={"15.5px"}
                    height={"16px"}
                    color={isFilterAppliedStatus ? "#FFFFFF" : null}
                  />
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default AvailabilityHeaderTabs;
