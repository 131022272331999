import React from "react";

import ReportRoundWiseDashboard from "./ReportDashboard/ReportRoundWiseDashboard";
import ReportDesignationWiseDashboard from "./ReportDashboard/ReportDesignationWiseDashboard";

import { Typography, Grid } from "@mui/material";

import userGroupIcon from "../../../assets/icons/user-group.svg";

import styles from "./Basic.module.scss";

const GraphicalView = ({ roundData, designationWise, total }) => {
  return (
    <Grid
      container
      spacing={2}
      flexWrap={"nowrap"}
      margin={2}
      marginTop={5}
      justifyContent={"space-around"}
    >
      <Grid id item xs={7} className={styles.chartStyle} marginRight={2}>
        <Typography
          variant="h6"
          display={"inline-flex"}
          className={styles.chartLabelStyle}
        >
          Panel Pool Availability
        </Typography>
        {Object.keys(roundData)?.length ? (
          <Grid
            container
            justifyContent={"center"}
            flexWrap={"nowrap"}
            marginTop={2}
            xs={10}
          >
            <ReportRoundWiseDashboard roundData={roundData} />
            <Grid
              container
              flexDirection={"column"}
              justifyContent={"flex-start"}
              gap={3}
              className={styles.graphDetailContainer}
            >
              <Grid container flexDirection={"row"}>
                <Grid item gap={2} className={styles.outerBoxStyle}>
                  <Grid
                    container
                    justifyContent={"center"}
                    className={`${styles.innerBoxStyle} ${styles.individualBoxBg}`}
                  >
                    <img src={userGroupIcon} alt="group-icon" color="#FFFF" />
                  </Grid>
                  <Grid>
                    <Typography className={styles.boxTextStyle}>
                      Individual Interviewers Pool
                    </Typography>
                    <Typography className={styles.countTextStyle}>
                      {roundData.currentlyInRound}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid item gap={2} className={styles.outerBoxStyle}>
                  <Grid
                    container
                    className={`${styles.innerBoxStyle} ${styles.pairedBoxBg}`}
                  >
                    <img src={userGroupIcon} alt="group-icon" color="#FFFF" />
                  </Grid>
                  <Grid>
                    <Typography className={styles.boxTextStyle}>
                      Paired Interviewers Pool
                    </Typography>
                    <Typography className={styles.countTextStyle}>
                      {roundData.pairingCount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent={"center"} marginTop={3}>
            <Typography variant="h5" color="initial">
              No Data Available.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={6} className={styles.chartStyle} marginLeft={2}>
        <Typography
          variant="h6"
          display={"inline-flex"}
          className={styles.chartLabelStyle}
        >
          Designation Wise Availability
        </Typography>
        <ReportDesignationWiseDashboard
          designationWise={designationWise}
          total={total}
        />
      </Grid>
    </Grid>
  );
};

export default GraphicalView;
