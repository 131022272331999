import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import NoData from "../NoData/index";
import CreateEditDL from "./CreateEditDL";
import DisplayDistributionList from "./DisplayDistributionList";

import {
  fetchAllDistributionList,
  deleteDistributionListById,
} from "../../../redux/features/Admin/adminSlice";

import { Box, Grid, Typography, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { ROLE_TYPES } from "../../../constants";

import styles from "./style.module.scss";

const { isAdmin } = ROLE_TYPES;

const ManageDL = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.authReducer);
  const loading = useSelector((state) => state.adminReducer?.loading);
  const dlData = useSelector(
    (state) => state?.adminReducer?.distributionListData
  );

  const [configId, setConfigId] = useState(null);
  const [editDlEmailId, setEditDlEmailId] = useState(null);
  const [checkWeekType, setCheckWeekType] = useState("ALL");
  const [activeAccordion, setActiveAccordion] = useState("0");
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [distributionListName, setDistributionListName] = useState("");
  const [distributionListEmail, setDistributionListEmail] = useState("");
  const [distributionListDescription, setDistributionListDescription] =
    useState("");

  useEffect(() => {
    dispatch(
      fetchAllDistributionList({
        roleId: loginInfo.role_id,
        pageNumber: activePageNumber,
      })
    );
  }, [dispatch, loginInfo.role_id, activePageNumber]);

  const onDeleteDl = (item) => {
    dispatch(
      deleteDistributionListById({
        roleId: loginInfo.role_id,
        dlId: item.id,
        pageNumber: 1,
        reportType: "ALL",
        dispatch,
      })
    );
    setActivePageNumber("1");
    setCheckWeekType("ALL");
  };

  return (
    isAdmin(loginInfo.current_role) && (
      <Box className={"globalLayoutHeight"}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={1}
        >
          <Grid item>
            <Typography className={"heading"}>Manage DL</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Create DL" arrow={true} placement="top">
              <Box
                className={styles.addTagStyle}
                component={"button"}
                onClick={() => setShowCreateModal(true)}
              >
                <AddIcon />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item className={"globalLayoutHeight"}>
          {dlData?.dlEmailList?.length ? (
            <DisplayDistributionList
              dlEmailList={dlData?.dlEmailList}
              setActivePageNumber={setActivePageNumber}
              activePageNumber={activePageNumber}
              pageCount={dlData?.pageCount}
              userCount={dlData?.totalList}
              checkWeekType={checkWeekType}
              setCheckWeekType={setCheckWeekType}
              distributionListName={distributionListName}
              distributionListEmail={distributionListEmail}
              distributionListDescription={distributionListDescription}
              setDistributionListDescription={setDistributionListDescription}
              setDistributionListEmail={setDistributionListEmail}
              setDistributionListName={setDistributionListName}
              configId={configId}
              setConfigId={setConfigId}
              loginInfo={loginInfo}
              onDeleteDl={onDeleteDl}
              setActiveAccordion={setActiveAccordion}
              setEditDlEmailId={setEditDlEmailId}
              editDlEmailId={editDlEmailId}
              loading={loading}
            />
          ) : (
            <NoData />
          )}
        </Grid>

        {showCreateModal && (
          <CreateEditDL
            show={showCreateModal}
            setShow={setShowCreateModal}
            loginInfo={loginInfo}
            configId={configId}
            setConfigId={setConfigId}
            distributionListDescription={distributionListDescription}
            setDistributionListDescription={setDistributionListDescription}
            distributionListEmail={distributionListEmail}
            setDistributionListEmail={setDistributionListEmail}
            distributionListName={distributionListName}
            setDistributionListName={setDistributionListName}
            setActiveAccordion={setActiveAccordion}
            setEditDlEmailId={setEditDlEmailId}
            editDlEmailId={editDlEmailId}
          />
        )}
      </Box>
    )
  );
};

export default ManageDL;
