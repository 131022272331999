import React from "react";

import Icon from "@mui/material/Icon";

import styles from "./style.module.scss";

const IconWrap = ({
  icon,
  height,
  width,
  click,
  variant,
  border,
  rotation,
  rightPadding,
  leftPadding,
  topPadding,
  bottomPadding,
  color,
  cursor
}) => {
  return (
    <Icon
      onClick={click}
      component="img"
      src={icon}
      className={styles.icon}
      variant={variant}
      sx={{
        height: height,
        width: width,
        borderWidth: border,
        transform: rotation,
        paddingLeft: leftPadding,
        paddingRight: rightPadding,
        paddingTop: topPadding,
        paddingBottom: bottomPadding,
        color: color,
        cursor: cursor
      }}
    />
  );
};

export default IconWrap;
