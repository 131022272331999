import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "../../../components/Spinner/index";

import {
  filterSelectedCompetency,
  fetchInactiveDesignationByCompetencyId,
} from "../../../redux/features/Designation/designationSlice";

import {
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { getAuthInfo } from "../../../utils/app-utils";

import styles from "./style.module.scss";

const SelectDesignations = ({ designationData }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.designationReducer);
  const competencyList = useSelector((state) => state.authReducer?.competency);
  const { current_role_competency_id, role_id } = useSelector(
    (state) => state.authReducer?.loginInfo
  );
  const inactive = useSelector(
    (state) => state?.designationReducer?.fetchInactiveDesignations
  );

  const [selectAll, setSelectAll] = useState(false);
  const [designations, SetDesignations] = useState([]);

  const handleChange = useCallback(
    (e, index) => {
      const { value, checked } = e.target;
      designations[index].isChecked = checked;
      let tempUser = designations.map((designation) =>
        designation[index]?.value === value
          ? { ...designation, isChecked: !checked }
          : designation
      );
      SetDesignations(tempUser);
    },
    [designations]
  );

  useEffect(() => {
    try {
      const data = {
        competencyId: current_role_competency_id,
        roleId: role_id,
      };
      dispatch(fetchInactiveDesignationByCompetencyId(data));
    } catch (error) {
      console.log("Error");
    }
  }, [current_role_competency_id, dispatch, role_id]);
  useEffect(() => {
    let desg = [];
    designationData &&
      Object.keys(designationData).forEach((val) => {
        let obj = {
          isChecked: !inactive.includes(parseInt(val)),
          value: val,
        };
        desg = [...desg, obj];
      });
    SetDesignations(desg);
  }, [designationData, inactive]);
  useEffect(() => {
    if (
      Boolean(
        designations?.length && designations.every((item) => item.isChecked)
      )
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [designations, handleChange]);

  const authInfo = getAuthInfo();
  const current_competency =
    competencyList[authInfo?.userInfo?.current_role_competency_id];
  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    let tempUser = designations.map(
      (designation) => (designation = { ...designation, isChecked: checked })
    );
    SetDesignations(tempUser);
  };
  const onButtonClick = async (e) => {
    try {
      e.preventDefault();
      let inactiveDesignationsArray = [];
      Object.values(designations).forEach((item) => {
        if (item.isChecked === false) {
          inactiveDesignationsArray.push(parseInt(item.value));
        }
      });
      const data = {
        competencyId: current_role_competency_id,
        roleId: role_id,
        inactiveDesignationsArray,
      };
      dispatch(filterSelectedCompetency(data));
    } catch (error) {
      console.log("Error");
    }
  };

  if (loading) return <Spinner />;

  return (
    <Grid
      container
      justifyContent="left"
      alignItems="center"
      paddingRight={2}
      paddingLeft={0.5}
    >
      <Grid item lg={12} xl={12} xs={12} md={12}>
        <Typography className={"heading"}>Designations</Typography>
      </Grid>
      <Grid
        container
        spacing={1}
        mt={1}
        mb={5}
        className={styles.subheadingWrap}
      >
        <Grid item>
          <Typography className={"subheading"}>
            {current_competency} Designations
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{
              fontWeight: 700,
              fontSize: "11px",
              color: "#FFFFFF",
              borderRadius: "5px",
            }}
            onClick={(e) => onButtonClick(e)}
            className={styles.saveBtn}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            SAVE
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        mt={1}
        mb={5}
        className={styles.designationListWrap}
      >
        {" "}
        <Grid item xs={12}>
          <FormControlLabel
            className={`${styles.selectAllWrap} ${styles.designationList}`}
            control={
              <Checkbox
                size="small"
                checked={selectAll}
                onChange={(e) => handleSelectAll(e)}
              />
            }
            label="Select All"
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
        {designationData
          ? Object.entries(designationData)?.map(([id, { name }], index) => (
              <Grid
                key={parseInt(id)}
                item
                xl={3}
                md={6}
                xs={12}
                lg={3}
                sx={{ display: "flex" }}
              >
                <Checkbox
                  onChange={(e) => handleChange(e, index)}
                  size="small"
                  checked={designations[index]?.isChecked || false}
                  name={name}
                />
                <Typography marginTop={1.25} className={styles.designationList}>
                  {name}
                </Typography>
              </Grid>
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export default SelectDesignations;
