import React from "react";

import { Button, Grid } from "@mui/material";

import styles from "./style.module.scss";

const BookingActionItems = (props) => {
  const {
    showInterviewStatusNaOrRsPopup,
    handleCancel,
    isNaOrRsStatusPopupYesClicked,
    handleContinue,
    isBookingTimeConflicting,
    handleClose,
    handleTimeConflictYes,
    handleBack,
    activeStep,
    handleBooking,
    handleNext,
    isPairingRoundSelected,
    unpairedInterviewersBookingsList,
    steps,
    bookingIdForPairedRound
  } = props;
  return (
    <Grid container direction={"row"} spacing={1}>
      {showInterviewStatusNaOrRsPopup && (
        <>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={handleCancel}
              variant="outlined"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              className={styles.backBtnStyle}
            >
              {isNaOrRsStatusPopupYesClicked ? "Cancel Changes" : "No"}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={handleContinue}
              variant="contained"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              className={styles.backBtnStyle}
            >
              {isNaOrRsStatusPopupYesClicked ? "Continue" : "Yes"}
            </Button>
          </Grid>
        </>
      )}
      {isBookingTimeConflicting ? (
        <>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={handleClose}
              variant="outlined"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              className={styles.backBtnStyle}
            >
              Discard Booking
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={handleTimeConflictYes}
              variant="contained"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              className={styles.backBtnStyle}
            >
              Yes
            </Button>
          </Grid>
        </>
      ) : (
        <>
          {!showInterviewStatusNaOrRsPopup && (
            <>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  className={styles.backBtnStyle}
                >
                  Back
                </Button>
              </Grid>
              {activeStep === steps?.length - 1 ? (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() =>
                      handleBooking({
                        createBookingOnTimeConflicting: false,
                      })
                    }
                    variant="contained"
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    className={styles.nextBtnStyle}
                  >
                    Save Changes
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={handleNext}
                    variant="contained"
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    className={styles.nextBtnStyle}
                    disabled={
                      (isPairingRoundSelected &&
                        !unpairedInterviewersBookingsList?.length) ||
                      (isPairingRoundSelected &&
                        unpairedInterviewersBookingsList?.length &&
                        !bookingIdForPairedRound)
                    }
                  >
                    Next
                  </Button>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default BookingActionItems;
