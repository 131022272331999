import React from "react";

import FilterComponents from "./FilterComponents";
import AutoCompleteComponent from "../../../../components/AutoComplete/index";
import SingleSelectComponent from "../../../../components/SingleSelect/SingleSelectComponent";

import {
  changeGenderStatus,
  changeSubcompetencyStatus,
  changeTagStatus,
} from "../../../../redux/features/Roster/rosterSlice";

import {
  MenuItem,
  Select,
  Box,
  SwipeableDrawer,
  Slide,
  Autocomplete,
  TextField,
  Paper,
  FormControl,
  InputLabel,
} from "@mui/material";

import {
  DATE_OF_JOINING_FILTER_OPTIONS,
  SUBCOMPETENCY_FILTERS_JS,
  ROUND_FILTER_OPTIONS,
  JS_COMPETENCY_ID,
  ROLE_TYPES,
  ADMIN_ROLE_TYPES,
  ROSTER_FILTER_TAB_CHECK_VALUE,
} from "../../../../constants/index";
import { useDispatch } from "react-redux";

const { isHr, isAdmin } = ROLE_TYPES;

const RosterFilterList = ({
  competencyIdFilter,
  setCompetencyIdFilter,
  competencyId,
  interviewerDesignation,
  options,
  optionsFilterByCompetency,
  optionCompetency,
  keyvalue,
  setKeyvalue,
  user,
  roundName,
  gender,
  activedesignationId,
  joiningLabel,
  subcompetency,
  panelGroupList,
  panelGroupId,
  setPanelGroupId,
  resetRosterFilter,
  applyFilter,
  handleClose,
  handleInterviewerDesignationChange,
  handleEligibleDesignationChangeForActive,
  handleEligibleDesignationChange,
  handleRoundChange,
  setGender,
  handleCompetencyChange,
  setSubcompetency,
  handleJoiningDateChange,
  filtericon,
  toggleDrawer,
  optionsForHr,
  roleName,
  setRoleName,
  handleRoleChange,
  adminCompetencyIdFilter,
  setAdminCompetencyIdFilter,
  handleAdminCompetencyChange,
  competencyIdState,
  multicompetencyIdsState,
  interviewerDesignationIdsState,
  activeDesignationIdsState,
  genderState,
  roleTypesState,
  subCompetencyState,
  dateOfJoiningLabel,
  roundsNameState,
  tagsState,
}) => {
  let dispatch = useDispatch();

  const eligibleDesignationFilterForActive = () => {
    return (
      <>
        <AutoCompleteComponent
          placeholder={"Designation"}
          label={"Eligibility Designation"}
          options={options}
          onChange={handleEligibleDesignationChangeForActive}
          selectedValue={activeDesignationIdsState}
        />
      </>
    );
  };
  const eligibleDesignationFilter = () => {
    const isAdminActiveCompetencyIdArray =
      isAdmin(user?.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE;
    return (
      <>
        <AutoCompleteComponent
          options={isHr(user?.current_role) ? optionsForHr : options}
          onChange={handleEligibleDesignationChange}
          disabled={
            (!isAdminActiveCompetencyIdArray && !competencyIdState) ||
            (isAdminActiveCompetencyIdArray &&
              multicompetencyIdsState?.length === 0) ||
            (isAdmin(user?.current_role) &&
              keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL &&
              (!competencyIdState.toString()?.length))
          }
          placeholder={"Designation"}
          label={"Interviewer Designation"}
          selectedValue={activeDesignationIdsState}
        />
      </>
    );
  };
  const interviewerDesignationFilter = () => {
    return (
      <>
        <AutoCompleteComponent
          placeholder={"Designation"}
          label={"Interviewer Designation"}
          options={isHr(user?.current_role) ? optionsForHr : optionCompetency}
          onChange={handleInterviewerDesignationChange}
          selectedValue={interviewerDesignationIdsState}
        />
      </>
    );
  };
  const competencyFilter = () => {
    let optionValues = optionsFilterByCompetency;
    const isAdminRole = isAdmin(user?.current_role);
    const filteredOptions = isAdminRole
      ? optionValues.filter((option) => option.value !== "1")
      : optionValues;
    const selectedValue =
      filteredOptions?.find((option) => option.value == competencyIdState) ||
      null;
    return (
      <Autocomplete
        fullWidth
        value={selectedValue}
        disablePortal
        onChange={(event, newValue) => {
          handleCompetencyChange(newValue || { value: "" });
        }}
        disabled={isHr(user?.current_role) || isAdmin(roleName)}
        id="combo-box-demo"
        options={filteredOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Competency" />}
      />
    );
  };
  const subCompetencyFilter = () => {
    if (user.current_role_competency_id === JS_COMPETENCY_ID) {
      return (
        <AutoCompleteComponent
          placeholder={"Competency"}
          label={"Sub Competency"}
          options={SUBCOMPETENCY_FILTERS_JS}
          onChange={(e) => {
            setSubcompetency(e);
            dispatch(changeSubcompetencyStatus(e));
          }}
          selectedValue={subCompetencyState}
        />
      );
    }
  };
  const dateOfJoiningFilter = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="date-of-joining-label">Date Of Joining</InputLabel>
        <Select
          fullWidth
          displayEmpty
          labelId="date-of-joining-label"
          id="date-of-joining-label"
          label={"Date Of Joining"}
          value={dateOfJoiningLabel}
          defaultValue={""}
          onChange={(e) => handleJoiningDateChange(e)}
        >
          {DATE_OF_JOINING_FILTER_OPTIONS?.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const genderFilter = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="gender-label">Gender</InputLabel>
        <Select
          labelId="gender-label"
          label="Gender"
          id="gender-id"
          placeholder="Gender"
          value={genderState}
          onChange={(event) => {
            setGender(event.target.value);
            dispatch(changeGenderStatus(event.target.value));
          }}
          displayEmpty
          fullWidth
          inputProps={{ "aria-label": "Gender" }}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </Select>
      </FormControl>
    );
  };
  const tagFilter = () => {
    function selectedPanelGroupIds() {
      const result = panelGroupList?.filter((item) =>
        tagsState?.includes(item.id)
      );
      return result;
    }

    return (
      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option?.group_name}
        limitTags={2}
        render
        value={selectedPanelGroupIds()}
        onChange={(event, newValue) => {
          const idArray = newValue.map((id) => id.id);
          setPanelGroupId(idArray);
          dispatch(changeTagStatus(idArray));
        }}
        multiple
        id="tags-outlined"
        options={panelGroupList}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField fullWidth {...params} label={"Tags"} placeholder={"Tag"} />
        )}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              maxHeight: 150,
              overflowY: "auto",
              fontSize: "15px",
              fontFamily: "poppins",
            }}
          >
            {children}
          </Paper>
        )}
      />
    );
  };
  const roundFilter = () => {
    return (
      <SingleSelectComponent
        options={ROUND_FILTER_OPTIONS}
        onChange={(event) => handleRoundChange(event.value)}
        label={"Select Round"}
        value={roundsNameState}
      />
    );
  };
  const roleTypeFilter = () => {
    const disableOptions = adminCompetencyIdFilter?.length > 0;
    return (
      <SingleSelectComponent
        options={ADMIN_ROLE_TYPES}
        onChange={(event) => handleRoleChange(event.value)}
        label={"Select Role"}
        value={roleTypesState}
        disabledOptions={disableOptions ? [ADMIN_ROLE_TYPES.ADMIN] : []}
      />
    );
  };

  const competencyRoleAdminFilter = () => {
    let optionValues = optionsFilterByCompetency;
    const isAdminRole = isAdmin(user?.current_role);
    const filteredOptions = isAdminRole
      ? optionValues?.filter((option) => option.value !== "1")
      : optionValues;
    return (
      <>
        <AutoCompleteComponent
          options={filteredOptions}
          onChange={handleAdminCompetencyChange}
          placeholder={"Competency"}
          label={"Active Role Competency"}
          disabled={isAdmin(roleName)}
          selectedValue={multicompetencyIdsState}
        />
      </>
    );
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={filtericon}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Slide direction="left" in={filtericon} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: 400,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            overflowX: "hidden",
          }}
          role="presentation"
        >
          <FilterComponents
            keyvalue={keyvalue}
            setKeyvalue={setKeyvalue}
            competencyFilter={competencyFilter}
            dateOfJoiningFilter={dateOfJoiningFilter}
            genderFilter={genderFilter}
            subCompetencyFilter={subCompetencyFilter}
            interviewerDesignationFilter={interviewerDesignationFilter}
            eligibleDesignationFilter={eligibleDesignationFilter}
            eligibleDesignationFilterForActive={
              eligibleDesignationFilterForActive
            }
            roundFilter={roundFilter}
            tagFilter={tagFilter}
            resetRosterFilter={resetRosterFilter}
            applyFilter={applyFilter}
            handleClose={handleClose}
            user={user}
            roleTypeFilter={roleTypeFilter}
            competencyRoleAdminFilter={competencyRoleAdminFilter}
          />
        </Box>
      </Slide>
    </SwipeableDrawer>
  );
};

export default RosterFilterList;
