import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "./../../../../components/Spinner";
import Icon from "./../../../../components/SvgIcon/icon";
import Pagination from "../../../../components/Pagination";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal.js";

import { deleteTag, getTags } from "../../../../redux/features/Tags/tagsSlice";

import {
  Typography,
  Grid,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Badge,
  Tooltip,
} from "@mui/material";

import DeleteIcon from "../../../../assets/icons/bin-icon.svg";

import { ROLE_TYPES } from "../../../../constants";

import styles from "./style.module.scss";
import theme from "./../../../../theme/index";

const { isSpoc } = ROLE_TYPES;

const TagList = ({
  activePageNumber,
  setActivePageNumber,
  tagsList,
  tagPageCount,
  totalTagsCount,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.tagReducer);
  const loginInfo = useSelector((state) => state?.authReducer?.loginInfo);

  const [deleteTagId, setDeleteTagId] = useState(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const handleClose = () => {
    setShowDeleteConfirmModal(false);
    setDeleteTagId(null);
  };
  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const onDeletePanelGroup = async () => {
    if (deleteTagId) {
      setActivePageNumber(1);
      await dispatch(
        deleteTag({
          groupId: deleteTagId,
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
        })
      );
      await dispatch(
        getTags({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
          pageNumber: 1,
        })
      );
      setDeleteTagId(null);
    }
  };

  const actionItems = (id) => {
    if (isSpoc(loginInfo.current_role))
      return (
        <TableCell>
          <Tooltip title="Remove Tag" placement="top" arrow>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Icon
                  icon={DeleteIcon}
                  width="16.5px"
                  height="16.77px"
                  click={() => {
                    setShowDeleteConfirmModal(true);
                    setDeleteTagId(id);
                  }}
                />
              </Grid>
            </Grid>
          </Tooltip>
        </TableCell>
      );
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Paper className={"globalTableLayoutStyle"}>
        <TableContainer
          className={`globalLayoutHeight globalTableContainerAlign`}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeadingStyle}>Tag</TableCell>
                <TableCell className={styles.tableHeadingStyle}>
                  Created by
                </TableCell>
                <TableCell className={styles.tableHeadingStyle}>
                  Description
                </TableCell>
                {isSpoc(loginInfo.current_role) ? (
                  <TableCell
                    align="center"
                    className={styles.tableHeadingStyle}
                  >
                    Action
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tagsList?.length ? (
                tagsList?.map((data, index) => (
                  <TableRow key={index} sx={{ border: 0 }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      <Badge className={styles.tagBadgeStyle}>
                        {data.group_name}
                      </Badge>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      <Typography
                        fontFamily={"poppins"}
                        className={"tablebody"}
                      >
                        {data?.created_by}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      {data.description}
                    </TableCell>
                    {actionItems(data.id)}
                  </TableRow>
                ))
              ) : (
                <Typography color={theme.palette.secondary.main}>
                  No Tag Is Available Yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <Pagination
            userCount={totalTagsCount}
            pageCount={tagPageCount}
            handlePageClick={handlePageClick}
            activePageNumber={activePageNumber}
          />
        </TableContainer>
      </Paper>
      {showDeleteConfirmModal && (
        <DeleteConfirmationModal
          show={showDeleteConfirmModal}
          setShow={handleClose}
          clickConfirm={onDeletePanelGroup}
        />
      )}
    </>
  );
};

export default TagList;
