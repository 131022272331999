import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../components/Spinner";
import DatePickerComponent from "../../../components/DatePickerComponent/index";

import {
  getBookingDataForExport,
  exportBookingData,
} from "../../../redux/features/Reports/reportSlice";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

import closeIcon from "../../../assets/icons/close-icon.svg";

import Excel from "exceljs";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { format, addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import { extractTime } from "../../../utils/date-utils";
import { deriveAbbreviatedName } from "../../../utils/app-utils";
import {
  headersForBookingDetailsDataExport,
  INTERVIEW_STATUS_OPTIONS_FOR_EXPORT_BOKKING_DATA,
} from "../../../constants";

import styles from "./Basic.module.scss";
import theme from "../../../theme";

const ExportBookingDataExcelModal = ({ show, setShow, competencyList }) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const {
    loading,
    exportBookingDataExcel,
    bookingDataPeriodFrom,
    bookingDataPeriodTo,
  } = useSelector((state) => state.reportReducer);
  const { bookingData } = useSelector((state) => state.reportReducer?.data);

  const [periodFrom, setPeriodFrom] = useState(dayjs(new Date()));
  const [periodTo, setPeriodTo] = useState(dayjs(new Date()));
  const [wantSelfBookings, setWantSelfBookings] = useState(false);
  const [error, setError] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);

  const workSheetName = "Booking-Weekdays-Report";
  const handleClose = useCallback(
    (e) => {
      setShow(false);
    },
    [setShow]
  );
  const saveExcel = useCallback(async () => {
    const workbook = new Excel.Workbook();
    try {
      const fileName =
        competencyList &&
        `${
          competencyList[loginInfo.current_role_competency_id]
        }_booking_slots_report_${bookingDataPeriodFrom}_to_${bookingDataPeriodTo}`;
      const worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = headersForBookingDetailsDataExport;
      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 22;
        column.alignment = { horizontal: "center" };
      });
      excelData.forEach((singleData) => {
        worksheet.addRow(singleData);
      });
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
  }, [excelData]);

  useEffect(() => {
    setPeriodTo(dayjs(addDays(new Date(periodFrom), 7)));
  }, [periodFrom]);
  useEffect(() => {
    const dataForExcel = bookingData?.length
      ? bookingData?.map((item) => {
          let obj = {
            competency: item.competency,
            emp_name: item.emp_name,
            email: item.email,
            round: item.round,
            tags: item?.panel_tags?.length && item?.panel_tags?.map((tag)=>tag.tag_name).join(", "),
            booking_designation: item.booking_designation,
            candidate_email: item.candidate_email,
            pairing_with: item.pairing_with,
            booking_date:
              item.booking_date &&
              format(new Date(item.booking_date), "dd-MMM-yyyy"),
            booking_time: `${extractTime(item.start_time)} - ${extractTime(
              item.end_time
            )}`,
            booking_assigned_by: item.booking_assigned_by,
            booking_status:
              INTERVIEW_STATUS_OPTIONS_FOR_EXPORT_BOKKING_DATA[
                item.booking_status
              ],
            interviewer_status:
              INTERVIEW_STATUS_OPTIONS_FOR_EXPORT_BOKKING_DATA[
                item.interviewer_status
              ],
          };
          return obj;
        })
      : [];
    setExcelData(dataForExcel);
  }, [bookingData, dispatch]);
  useEffect(() => {
    if (exportBookingDataExcel) {
      setIsExportButtonClicked(true);
      dispatch(exportBookingData({ exportBookingDataExcel: false }));
    }
  }, [dispatch, exportBookingDataExcel]);
  useEffect(() => {
    if (isExportButtonClicked) {
      saveExcel();
      setIsExportButtonClicked(false);
      handleClose();
    }
  }, [handleClose, isExportButtonClicked, saveExcel]);

  const validateForm = () => {
    let error = false;
    let d_period_from = format(new Date(periodFrom), "yyyy-MM-dd");
    let d_period_end = format(new Date(periodTo), "yyyy-MM-dd");
    let min_date = format(new Date().setFullYear(2015, 12, 31), "yyyy-MM-dd");
    if (
      !(
        d_period_from >= min_date &&
        d_period_end >= min_date &&
        d_period_from <= d_period_end
      )
    ) {
      error = true;
    }
    return error;
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    const isInvalid = validateForm();
    setError(isInvalid);
    if (!isInvalid) {
      const data = {
        from: format(new Date(periodFrom), "yyyy-MM-dd"),
        to: format(new Date(periodTo), "yyyy-MM-dd"),
        roleId: loginInfo.role_id,
        fetchSelfBookings: wantSelfBookings,
        competencyId: loginInfo.current_role_competency_id,
      };
      dispatch(getBookingDataForExport(data));
    }
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "28rem",
            minHeight: "20vh",
            maxHeight: "100vh",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle
          color={theme.palette.secondary.main}
          className={styles.dialogTitle}
        >
          {" "}
          {`(${
            competencyList &&
            deriveAbbreviatedName(
              competencyList[loginInfo.current_role_competency_id]
            )
          })`}{" "}
          - Export Booking Data to Excel
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <DialogContent>
          {error && (
            <Typography sx={{ color: theme.palette.error.dark }}>
              *Select Correct Date.
            </Typography>
          )}
          <DialogContentText></DialogContentText>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <DatePickerComponent
                label="Period From"
                value={periodFrom}
                handleChange={setPeriodFrom}
              />
            </Grid>
            <Grid item sm={6}>
              <DatePickerComponent
                label="Period To"
                value={periodTo}
                handleChange={setPeriodTo}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={wantSelfBookings}
                    onClick={() => {
                      setWantSelfBookings(!wantSelfBookings);
                    }}
                  />
                }
                label="Export self bookings only?"
                sx={{ fontWeight: 400, color: theme.palette.secondary.main }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            fullWidth
            disabled={!periodFrom || !periodTo}
            variant={"contained"}
            onClick={onFormSubmit}
            className={styles.addTagButtonStyle}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            Export Data
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportBookingDataExcelModal;
