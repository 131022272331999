import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePickerComponent from "../../../components/DatePickerComponent";

import { markTempUnavailableUser } from "../../../redux/features/Availability/availabilitySlice";

import {
  Modal,
  Typography,
  Box,
  Grid,
  IconButton,
  Button,
  TextField,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import format from "date-fns/format";

import { MODULE_NAME,ROSTER_FILTER_TAB_CHECK_VALUE } from "../../../constants";

import styles from "./style.module.scss";

const TempUnavailableModal = ({
  show,
  setShow,
  selectedPanel,
  moduleName = MODULE_NAME.ROSTER,
  competencyId,
  roundDesignationIds,
  slotsProvided,
  roundName,
  activedesignationId,
  weekFrom,
  weekTo,
  query,
  setActivePageNumber,
  sortBy,
  panelGroupId,
  dateOfJoining,
  subCompetency,
  keyvalue
}) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state?.authReducer?.loginInfo);
  const {
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    subCompetencyState,
    dateOfJoiningState,
    roundsNameState,
    tagsState,
  } = useSelector((state) => state.rosterReducer?.roster);
  const {
    providedSlots,
    roundsFilter,
    eligibleDesignationIds,
    interviewerDesignationIds,
    tagsFilter,
    subCompetencyFilter,
  } = useSelector((state) => state?.availabilityReducer?.availability);
  
  const [commentNotes, setCommentNotes] = useState("");
  const [err, setErr] = useState({ value: false, flag: "enddate" });
  const [periodFrom, setPeriodFrom] = useState(
    format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd")
  );
  const [periodTo, setPeriodTo] = useState(
    format(new Date().setDate(new Date().getDate() + 30), "yyyy-MM-dd")
  );

  const handleClose = (e) => {
    setShow(false);
  };
  function monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }
  const validateForm = () => {
    let error = false;
    let d_period_from = periodFrom;
    let d_period_end = periodTo;
    let curr_date = format(
      new Date().setDate(new Date().getDate() + 1),
      "yyyy-MM-dd"
    );
    if (d_period_from < curr_date) {
      error = true;
      setErr({ value: true, flag: "startdate" });
    }
    let months = monthDiff(new Date(periodFrom), new Date(periodTo));
    if (d_period_end < d_period_from || !(months <= 6)) {
      error = true;
      setErr({ value: true, flag: "enddate" });
    }
    return error;
  };
  const onFormSubmit = async (e) => {
    const isValid = validateForm();
    if (!isValid) {
      try {
        e.preventDefault();
        let panelMemberPayload;
        if (moduleName === MODULE_NAME.ROSTER) {
          panelMemberPayload = {
            competencyId: loginInfo.current_role_competency_id,
            designationIds:activeDesignationIdsState,
            sortType: sortBy.sortType,
            sortOrder: sortBy.sortOrder,
            roundName: roundsNameState,
            query,
            panelGroupId:
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
              ? tagsState
              : null,
            activedesignationId:interviewerDesignationIdsState,
            roleId: loginInfo.role_id,
            joiningDate: dateOfJoiningState,
            subCompetency: subCompetencyState,
          };
        }
        if (moduleName === MODULE_NAME.AVAILABILITY) {
          panelMemberPayload = {
            competencyId: loginInfo.current_role_competency_id,
            roundDesignationIds: eligibleDesignationIds,
            slotsProvided: providedSlots,
            roundName: roundsFilter,  
            periodFrom: weekFrom,
            periodTo: weekTo,
            roleId: loginInfo.role_id,
            query,
            activedesignationId: interviewerDesignationIds,
            panelGroupId: tagsFilter,
            subCompetency: subCompetencyFilter,
          };
        }
        const data = {
          id: selectedPanel.panel_id,
          notes: commentNotes,
          periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
          periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
          competencyId: selectedPanel.panel_competency_id,
          roleId: loginInfo.role_id,
          moduleName,
          panelMemberPayload,
          dispatch,
        };
        dispatch(markTempUnavailableUser(data));
        handleClose();
        setActivePageNumber(1);
      } catch (error) {
        console.log("Error");
      }
    }
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        className={`${styles.modalBox}`}
        sx={{ padding: "15px 25px", width: 500 }}
      >
        <Grid container spacing={0.75}>
          <Grid item xs={12} md={12} xl={12} lg={12}>
            <Typography className={styles.suspensionHeading}>
              Mark Temporary Unavailable:{" "}
              {`${selectedPanel.first_name} ${selectedPanel.last_name} (${selectedPanel.employee_code})`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => handleClose(e)}
              sx={{ position: "absolute", right: 9, top: 14 }}
            >
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"column"}
          spacing={2}
          justifyContent={"center"}
        >
          {err.value && err.flag === "enddate" && (
            <Typography margin={1} paddingLeft={1}>
              *End Date should be greater than or equal to Start Date.
            </Typography>
          )}
          {err.value && err.flag === "startdate" && (
            <Typography margin={1}>
              *Start Date should be greater than or equal to Current Date.
            </Typography>
          )}

          <Grid container marginTop={2} justifyContent={"flex-end"} spacing={1}>
            <Grid item xs={5.6}>
              <DatePickerComponent
                label=" From"
                value={periodFrom}
                disablePast={true}
                handleChange={(newValue) => {
                  const adjustedPeriodTo = newValue
                    .add(30, "day")
                    .format("YYYY-MM-DD");
                  setPeriodTo(adjustedPeriodTo);
                  setPeriodFrom(newValue.format("YYYY-MM-DD"));
                }}
                minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerComponent
                label="To"
                value={periodTo}
                handleChange={setPeriodTo}
                disablePast={true}
                minDate={periodFrom}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} xl={12} lg={12}>
            <TextField
              id="Reason"
              fullWidth
              multiline={true}
              rows={4}
              label="Reason"
              placeholder="Reason"
              autoComplete="off"
              variant="outlined"
              value={commentNotes}
              onChange={(e) => {
                setCommentNotes(e.target.value);
              }}
              className={styles.addComment}
              marginBottom={4}
              required
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="left" alignItems="center" marginTop={2}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Button
              fullWidth
              disabled={/^\s*$/.test(commentNotes) || !periodFrom || !periodTo}
              variant={"contained"}
              onClick={onFormSubmit}
              className={
                /^\s*$/.test(commentNotes) || !periodFrom || !periodTo
                  ? `${styles.saveBtnDisable}`
                  : `${styles.saveBtn}`
              }
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TempUnavailableModal;
