import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import AvailabilityUpdateModal from "../MarkAvailability/AvailabilityUpdateModal";
import Spinner from "../../../../components/Spinner";
import BookingDetailsModal from "./BookingDetailsModal";

import {
  fetchPanelMembersAvailability,
  createOrUpdateSlotAvailability,
} from "../../../../redux/features/Availability/availabilitySlice";
import { fetchWeekendDriveByDate } from "../../../../redux/features/WeekendDrive/weekendDriveSlice";

import {
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableRow,
  Tooltip,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
} from "@mui/material";

import arrowForward from "../../../../assets/icons/arrow-forward-icon.svg";
import arrowBackward from "../../../../assets/icons/arrow-backward-icon.svg";
import NotAvailable from "../../../../assets/icons/not-available.svg";
import Available from "../../../../assets/icons/available.svg";
import Holiday from "../../../../assets/icons/holiday.svg";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { isEqual } from "date-fns";

import { extractTime, extractTimeWithoutAMPM } from "../../../../utils/date-utils";
import { deriveAbbreviatedName } from "../../../../utils/app-utils";
import { isTodayHoliday, weekdaysColorSet } from "../../../../utils/app-utils";
import {
  INTERVIEW_STATUS_OPTIONS,
  INTERVIEW_STATUS_OPTIONS_SHORTFORM,
  CHECK_TAB_KEY,
  ROUND_FILTER_OPTIONS,
  SLOT_FILTER_OPTIONS,
  FULL_MONTH_NAME,
  INTERVIEW_STATUS_CHECK,
} from "../../../../constants";

import styles from "./style.module.scss";

const localizer = momentLocalizer(moment);

const WeekdaysPanelMemberView = ({
  startMonthDate,
  endMonthDate,
  monthDate,
  setMonthDate,
  setStartMonthDate,
  setEndMonthDate,
  activePageNumber,
  loginInfo,
  signal,
  activeAccordion,
  setActiveAccordion,
  keyValue,
  setKeyValue,
}) => {
  let dispatch = useDispatch();
  const { holidaysList } = useSelector((state) => state?.authReducer);
  const { weekendDriveLists } = useSelector(
    (state) => state?.weekendDriveReducer
  );
  const { userList, loading, loggedInUserPanelDetails } = useSelector(
    (state) => state?.availabilityReducer
  );
  const slotDetails = userList?.[0]?.slotdetails;
  const bookings = userList?.[0]?.booking;

  const [filteredWeekendDriveLists, setFilteredWeekendDriveLists] =
    useState(null);
  const [slotsInfo, setSlotsInfo] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [date, setDate] = useState(new Date());
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [filteredSlotDetails, setFilteredSlotDetails] = useState([]);

  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

  useEffect(() => {
    if (keyValue === CHECK_TAB_KEY.WEEKDAYS && activeAccordion === 0) {
      dispatch(
        fetchPanelMembersAvailability({
          competencyId: loginInfo.current_role_competency_id,
          roundDesignationIds: [],
          periodFrom: new Date(startMonthDate).toISOString(),
          periodTo: new Date(endMonthDate).toISOString(),
          pageNumber: activePageNumber,
          roleId: loginInfo.role_id,
          query: "",
          slotsProvided: SLOT_FILTER_OPTIONS.ALL,
          roundName: ROUND_FILTER_OPTIONS.ALL,
          activedesignationId: [],
          panelGroupId: [],
          subCompetency: [],
          signal: signal,
        })
      );
    }
  }, [loginInfo, startMonthDate, endMonthDate, keyValue, activeAccordion]);
  useEffect(() => {
    if (loginInfo.role_id && keyValue === CHECK_TAB_KEY.WEEKEND ) {
      dispatch(
        fetchWeekendDriveByDate({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
          periodFrom: new Date(startMonthDate).toISOString(),
          periodTo: new Date(endMonthDate).toISOString(),
        })
      );
    }
  }, [startMonthDate, endMonthDate, loginInfo, keyValue]);
  useEffect(() => {
    const filteredWeekendDriveData = weekendDriveLists?.filter(
      (list) =>
        list.drive_status === INTERVIEW_STATUS_CHECK.COMPLETED ||
        list.drive_status === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        list.drive_status === INTERVIEW_STATUS_CHECK.CANCELLED
    );
    setFilteredWeekendDriveLists(filteredWeekendDriveData);
  }, [weekendDriveLists]);

  const createOrUpdateAvailability = (slotAvailability) => {
    const panelMemberAvailabilityPayload = {
      periodFrom: new Date(startMonthDate).toISOString(),
      periodTo: new Date(endMonthDate).toISOString(),
      competencyId: loginInfo.current_role_competency_id,
      roundDesignationIds: [],
      pageNumber: activePageNumber,
      roleId: loginInfo.role_id,
      query: "",
      slotsProvided: SLOT_FILTER_OPTIONS.ALL,
      roundName: ROUND_FILTER_OPTIONS.ALL,
      activedesignationId: [],
      panelGroupId: [],
      subCompetency: [],
      signal: signal,
    };
    dispatch(
      createOrUpdateSlotAvailability({
        slotAvailability,
        panelMemberAvailabilityPayload,
        roleId: loginInfo.role_id,
        dispatch,
      })
    );
  };
  const extractRoundWiseEligibility = (rounds = []) => {
    const response = rounds.reduce(
      (acc, value) => {
        if (value.roundName === "R1") {
          acc["R1"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        if (value.roundName === "R2") {
          acc["R2"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        if (value.roundName === "MR") {
          acc["MR"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        return acc;
      },
      { R1: [], R2: [], MR: [] }
    );
    return (
      <Grid
        container
        item
        xs={12}
        lg={12}
        md={12}
        xl={12}
        display="flex"
        flexWrap="wrap"
      >
        {!!response["R1"].length && (
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Chip
              label={`R1- 
                ${response["R1"].join(", ")}`}
              className={styles.individualChipStyle}
            />
          </Grid>
        )}
        {!!response["R2"].length && (
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Chip
              label={`R2- 
                ${response["R2"].join(", ")}`}
              className={styles.individualChipStyle}
            />
          </Grid>
        )}
        {!!response["MR"].length && (
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Chip
              label={`MR- 
                ${response["MR"].join(", ")}`}
              className={styles.individualChipStyle}
            />
          </Grid>
        )}
      </Grid>
    );
  };
  const convertSlotsToEvents = (slots) => {
    return slots
      ?.flatMap((slot) => {
        const isSaturday = new Date(slot.slotTime[0]?.startTime).getDay() === 6;
        const isBookingDate = bookings?.some((book) => {
          return (
            new Date(slot.dateOfAvailable).toDateString() ===
            new Date(book.startTime).toDateString()
          );
        });
        if (!isBookingDate) {
          if (slot?.isAvailable) {
            if (!isSaturday) {
              return slot?.slotTime?.map((time) => {
                let startTime = new Date(time?.startTime);
                let endTime = new Date(time?.endTime);
                return {
                  title: `${extractTimeWithoutAMPM(new Date(startTime))} - ${extractTime(
                    new Date(endTime)
                  )}`,
                  start: new Date(startTime),
                  end: new Date(endTime),
                  date: new Date(new Date(slot?.dateOfAvailable)),
                  isAvailable: slot?.isAvailable,
                  color: "#fff",
                  borderRadius: "5px",
                  badgeBgColor: "#05B14E",
                  bookingsList: null,
                  comments: "",
                  cursor: "default",
                };
              });
            }
          } else if (!slot?.isAvailable) {
            if (new Date(slot?.dateOfAvailable).getDay() !== 6) {
              return {
                title: `Not Available`,
                date: new Date(slot?.dateOfAvailable),
                start: new Date(slot?.dateOfAvailable),
                end: new Date(slot?.dateOfAvailable),
                isAvailable: slot?.isAvailable,
                bookingsList: null,
                comments: slot?.comments,
                color: "#003636",
                borderRadius: "5px",
                badgeBgColor: "#ffbab6",
                cursor: "pointer",
              };
            }
          } else {
            return {
              cellbackgroundColor: "#FFF",
              bookingsList: null,
              comments: "",
            };
          }
        }
      })
      .filter(Boolean);
  };
  const bookingsToEvents = (bookings) => {
    return bookings
      ?.flatMap((booking) => {
        const startTime = new Date(booking.startTime);
        const endTime = new Date(booking.endTime);
        if (booking) {
          const title =
            (INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
              INTERVIEW_STATUS_OPTIONS.SCHEDULED ||
              INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
                INTERVIEW_STATUS_OPTIONS.TO_BE_SCHEDULED) &&
            !booking.isInterviewerDeleted
              ? `${extractTimeWithoutAMPM(startTime)} - ${extractTime(endTime)}`
              : booking.isInterviewerDeleted
              ? INTERVIEW_STATUS_OPTIONS_SHORTFORM[booking.interviewer_status]
              : INTERVIEW_STATUS_OPTIONS_SHORTFORM[booking.interview_status];
          const { color, backgroundColor } = weekdaysColorSet(
            INTERVIEW_STATUS_OPTIONS[booking.interview_status],
            booking
          );
          return {
            title: title,
            start: new Date(startTime),
            end: new Date(endTime),
            date: new Date(startTime),
            color: color,
            borderRadius: "5px",
            badgeBgColor: backgroundColor,
            bookingsList: booking || null,
            comments: "",
            cursor: "pointer",
            onclick: () => {
              setShowAvailabilityModal(true);
            },
          };
        }
      })
      .filter(Boolean);
  };
  const dayPropGetter = (date) => {
    if (date >= startMonthDate && date <= endMonthDate) {
      const dayOfWeek = date.getDay();
      const currentDate = new Date();
      const isPastDate = date < currentDate;
      const isHoliday = isTodayHoliday(date, holidaysList);
      if (isHoliday) {
        return {
          style: {
            backgroundColor: "#BCECF6",
            cursor: "default",
          },
        };
      } else {
        if (dayOfWeek === 0) {
          return {
            style: {
              backgroundColor: "#EFF0F5",
              border: "1px solid #EFF0F5",
              cursor: "default",
            },
          };
        }
        if (loggedInUserPanelDetails?.is_user_temporary_suspended) {
          return {
            style: {
              cursor: "default",
            },
          };
        }
        let matchingWeekend, isBookingExists;
        if (dayOfWeek === 6) {
          matchingWeekend = filteredWeekendDriveLists?.find((weekend) => {
            return isEqual(new Date(date), new Date(weekend.date_of_drive));
          });
          isBookingExists = bookings?.find((booking) => {
            return isEqual(
              new Date(new Date(booking?.startTime).setHours(0, 0, 0, 0)),
              new Date(date)
            );
          });
          if (!isBookingExists) {
            return {
              style: {
                backgroundColor: "#EFF0F5",
                border: "1px solid #EFF0F5",
                cursor: "default",
              },
            };
          }
          return {
            style: {
              backgroundColor: "#fff",
              border: "1px solid #fff",
              cursor: "default",
            },
          };
        }
        const matchingEvent = events?.find((event) => {
          if (!matchingWeekend) {
            if (event?.isAvailable) {
              return (
                moment(date).isSame(event?.start, "day") &&
                moment(date).isSame(event?.end, "day")
              );
            } else {
              return moment(date).isSame(event?.date, "day");
            }
          } else {
            return {
              style: {
                backgroundColor: "#EFF0F5",
                border: "1px solid #EFF0F5",
                cursor: "default",
              },
            };
          }
        });

        if (matchingEvent) {
          if (matchingEvent.bookingsList !== null) {
            return {
              style: {
                cursor: "default",
              },
            };
          }
          return {
            style: {
              backgroundColor: matchingEvent.cellbackgroundColor,
              cursor: isPastDate ? "default" : "pointer",
            },
            comments: matchingEvent.comments,
          };
        }
        return {
          style: {
            backgroundColor: "fff",
            cursor: isPastDate ? "default" : "pointer",
          },
        };
      }
    }
  };
  const handleSlot = (slotInfo) => {
    const today = new Date();
    const isHoliday = isTodayHoliday(new Date(slotInfo.slots[0]), holidaysList);
    const isDatepassed =
      new Date(slotInfo.slots[0]).toISOString() > today.toISOString();
    const isDatePresent =
      new Date(slotInfo.slots[0]).toISOString() >=
        startMonthDate.toISOString() &&
      new Date(slotInfo.slots[0]).toISOString() <= endMonthDate.toISOString();
    let isWeekend =
      new Date(slotInfo.slots[0]).getDay() === 0 ||
      new Date(slotInfo.slots[0]).getDay() === 6;

    if (
      events.length !== 0 &&
      !isHoliday &&
      !loggedInUserPanelDetails?.is_user_temporary_suspended &&
      isDatePresent
    ) {
      events?.map((event) => {
        const isBookingDate = bookings?.some((book) => {
          return (
            new Date(slotInfo.slots[0]).toDateString() ===
            new Date(book.startTime).toDateString()
          );
        });
        if (!isWeekend && isDatepassed && !isBookingDate) {
            setShowAvailabilityModal(true);
            setSlotsInfo(slotInfo.slots[0]);
            const filteredData = slotDetails?.filter((slot) => {
              return (
                new Date(slot?.dateOfAvailable).toISOString() ===
                new Date(slotInfo.slots[0]).toISOString()
              );
            });
            setFilteredSlotDetails(filteredData ?? []);
        }
      });
    } else if (events.length === 0) {
      if (
        !isWeekend &&
        isDatepassed &&
        !isHoliday &&
        !loggedInUserPanelDetails?.is_user_temporary_suspended &&
        isDatePresent
      ) {
        setShowAvailabilityModal(true);
        setSlotsInfo(slotInfo.slots[0]);
      }
    }
  };
  const handleEvent = (event) => {
    if (event.bookingsList) {
      setShowBookingDetails(true);
      setBookingInfo(event?.bookingsList);
    }
  };
  const slotsEvents = slotDetails !== null && convertSlotsToEvents(slotDetails);
  const bookingEvents = bookings !== null && bookingsToEvents(bookings);
  const events = [...(slotsEvents || []), ...(bookingEvents || [])];

  const CustomToolbar = (toolbar) => {
    const switchPreviousMonth = () => {
      const date = new Date(monthDate);
      date.setDate(1);
      date.setDate(0);
      const startMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const endMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setMonthDate(date);
      setStartMonthDate(startMonth);
      setEndMonthDate(endMonth);
      onNavigate(startMonth);
    };

    const switchNextMonth = () => {
      const date = new Date(monthDate);
      date.setDate(1);
      date.setMonth(date.getMonth() + 1);
      const startMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const endMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setMonthDate(date);
      setStartMonthDate(startMonth);
      setEndMonthDate(endMonth);
      onNavigate(startMonth);
    };

    return (
      <Box className="custom-toolbar" marginBottom={2}>
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={1}
        >
          <Grid
            item
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Tooltip title={"Previous Month"} arrow={true} placement="top">
              <IconButton
                aria-label="arrow-backward"
                onClick={switchPreviousMonth}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <KeyboardArrowLeftOutlinedIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography className={styles.weeklyViewDate}>
              {FULL_MONTH_NAME[startMonthDate?.getMonth() + 1]}{" "}
              {startMonthDate?.getFullYear()}
            </Typography>
          </Grid>
          <Grid
            item
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Tooltip title={"Next Month"} arrow={true} placement="top">
              <IconButton
                aria-label="arrrow-forward"
                onClick={switchNextMonth}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <KeyboardArrowRightOutlinedIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    );
  };

  if (loading) return <Spinner />;

  return (
    <Box className={styles.panelViewContainer}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TableContainer className={styles.tableHeight}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={styles.tableHead}>
                <TableRow className={styles.tableRow}>
                  <TableCell className={styles.tableHeadingStyle}>
                    Name
                  </TableCell>
                  <TableCell className={styles.tableHeadingStyle}>
                    Designation
                  </TableCell>
                  <TableCell className={styles.tableHeadingStyle}>
                    Eligible Rounds
                  </TableCell>
                  {loggedInUserPanelDetails?.is_user_temporary_suspended && (
                    <TableCell className={styles.tableHeadingStyle}>
                      Notes
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {userList?.map((user) => (
                  <TableRow key={user.id} sx={{ border: 0, height: 40 }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={styles.tableBodyStyle}
                    >
                      {user.first_name} {user.last_name}
                    </TableCell>

                    <TableCell className={styles.tableBodyStyle} align="left">
                      {deriveAbbreviatedName(user.designation_name)}
                    </TableCell>

                    <TableCell align="left" className={styles.tableBodyStyle}>
                      {extractRoundWiseEligibility(user.rounds)}
                    </TableCell>
                    {loggedInUserPanelDetails?.is_user_temporary_suspended && (
                      <TableCell align="left" className={styles.tableBodyStyle}>
                        You cannot fill the availability. Please contact the
                        SPOC for further info.
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          {" "}
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          marginTop={2}
          sx={{ backgroundColor: "#fff" }}
        >
          <Calendar
            localizer={localizer}
            events={events}
            onNavigate={onNavigate}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
            onSelectSlot={(slotInfo) => {
              handleSlot(slotInfo);
            }}
            onSelectEvent={(event) => {
              handleEvent(event);
            }}
            date={monthDate}
            views={["month"]}
            selectable
            popup={true}
            tooltipAccessor={(event) => {
              if (event?.comments?.length !== 0) {
                return event?.comments;
              }
              return null;
            }}
            eventPropGetter={(event, start, end, isSelected) => ({
              style: {
                backgroundColor: event.badgeBgColor,
                color: event.color,
                border: event.border,
                borderRadius: event.borderRadius || "0",
                cursor: event.cursor,
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                marginBottom: "0.2rem",
              },
            })}
            eventStyleGetter={(event) => ({
              style: {
                backgroundColor: event.bgColor,
                borderColor: event.borderColor,
              },
            })}
            components={{
              toolbar: CustomToolbar,
            }}
            dayPropGetter={dayPropGetter}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          marginTop={2}
          display={"flex"}
          sx={{ gap: "1.5rem" }}
        >
          <Grid item display={"flex"}>
            <img src={Available} alt="close-icon" />
            <Typography className={styles.colorsText}>
              Slots available
            </Typography>
          </Grid>
          <Grid item display={"flex"}>
            <img src={NotAvailable} alt="close-icon" />
            <Typography className={styles.colorsText}>
              Slots not available
            </Typography>
          </Grid>
          <Grid item display={"flex"}>
            <img src={Holiday} alt="close-icon" />
            <Typography className={styles.colorsText}>Holidays</Typography>
          </Grid>
        </Grid>
      </Grid>
      {showAvailabilityModal && (
        <AvailabilityUpdateModal
          user={userList[0]}
          slotBookDate={slotsInfo}
          showAvailabilityModal={showAvailabilityModal}
          setShowAvailabilityModal={setShowAvailabilityModal}
          slotDetailsOfTheDay={filteredSlotDetails}
          createOrUpdateAvailability={createOrUpdateAvailability}
        />
      )}
      {showBookingDetails && (
        <BookingDetailsModal
          setShowBookingDetails={setShowBookingDetails}
          showBookingDetails={showBookingDetails}
          bookingInfo={bookingInfo}
          user={userList?.[0]}
        />
      )}
    </Box>
  );
};

export default WeekdaysPanelMemberView;
