import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardView from "./DashboardView";
import Spinner from "../../../../components/Spinner";
import InterviewCard from "./components/InterviewCard";

import {
  getSlotCountByCompetencyId,
  getBookingStatusByCompetencyId,
  getPanelDetailByCompetencyId,
  getCandidatesDetailByCompetencyId,
  getRecuiterListByCompetencyId,
  getWeekendDriveData,
  resetDashboardTableData,
} from "../../../../redux/features/HrDashboard/hrDashboardSlice";
import { getIsManagerForCurrentUser } from "../../../../redux/features/Admin/adminSlice";

import { Grid } from "@mui/material";

import bookedSlots from "../../../../assets/icons/booked-slots-icon.svg";
import availableSlots from "../../../../assets/icons/available-slots-icon.svg";
import drivesCreated from "../../../../assets/icons/drives-created.svg";
import drivesConducted from "../../../../assets/icons/drives-conducted.svg";
import upcomingDrives from "../../../../assets/icons/upcoming-drives.svg";

import dayjs from "dayjs";
import { subDays, format } from "date-fns";

import { getAuthInfo } from "../../../../utils/app-utils";
import {
  DASHBOARD_CARD_ICONS_COLOR,
  DASHBOARD_CARD_TITLE,
  DASHBOARD_WEEKEND_DRIVE_CARD_TITLE,
  SORT_TYPE_CHECK_VALUE,
  SORT_ORDER,
  ROLE_TYPES,
  INTERVIEW_CARD_ORDER,
} from "../../../../constants/index";

import styles from "./style.module.scss";

const { isHr } = ROLE_TYPES;

function HrDashboard() {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const competencyList = useSelector((state) => state.authReducer?.competency);
  const isManager = useSelector((state) => state?.adminReducer?.managerInfo);
  const { loading, slotCount, bookingStatus, recruiterList, weekendDriveData } =
    useSelector((state) => state.hrDashboardReducer);

  const [slotCardData, setSlotCardData] = useState([]);
  const [clear, setClear] = useState(false);
  const [periodTo, setPeriodTo] = useState(format(new Date().setDate(new Date().getDate() + 14), "yyyy-MM-dd"));
  const [weekendDriveDetails, setWeekendDriveDetails] = useState([]);
  const [periodFrom, setPeriodFrom] = useState(dayjs(subDays(new Date(), 15)));
  const [interviewCardWithBorder, setInterviewCardWithBorder] = useState(0);
  const [interviewStatus, setInterviewStatus] = useState(["SCHEDULED"]);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [showFilteredCompetency, setShowFilteredCompetency] = useState(false);
  const [showFilteredRecruiter, setShowFilteredRecruiter] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(true);
  const [selectedCompetency, setSelectedCompetency] = useState([
    parseInt(loginInfo?.current_role_competency_id),
  ]);
  const [sortBy, setSortBy] = useState({
    sortType: SORT_TYPE_CHECK_VALUE.DATE_TIME,
    sortOrder: SORT_ORDER.DESCENDING,
  });
  const [selectedRecruiter, setSelectedRecruiter] = useState([loginInfo?.id]);
  const [selectedRecruiterOptions, setSelectedRecruiterOptions] = useState([
    { label: loginInfo?.name, value: loginInfo?.id },
  ]);
  const [tempRecruiters, setTempRecruiters] = useState(
    selectedRecruiterOptions
  );

  useEffect(() => {
    if (isHr(loginInfo.current_role)) {
      dispatch(
        getIsManagerForCurrentUser({
          roleId: loginInfo?.role_id,
          userId: selectedRecruiter,
        })
      );
    }
  }, [loginInfo.current_role, loginInfo.current_role_competency_id]);
  useEffect(() => {
    dispatch(
      getBookingStatusByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: isFilterApplied ? selectedCompetency : [parseInt(loginInfo?.current_role_competency_id)],
        recruiterId: selectedRecruiter,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
      })
    );
    dispatch(
      getCandidatesDetailByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: isFilterApplied ? selectedCompetency : [parseInt(loginInfo?.current_role_competency_id)],
        status: interviewStatus,
        recruiterId: selectedRecruiter,
        pageNumber: activePageNumber,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
        sortType: sortBy.sortType,
        sortOrder: sortBy.sortOrder,
      })
    );
  }, [interviewStatus, activePageNumber, sortBy.sortOrder, sortBy.sortType]);

  useEffect(() => {
    setSelectedCompetency([parseInt(loginInfo?.current_role_competency_id)]);
    handleClear()
    setSelectedRecruiter([loginInfo?.id]);
    fetchData([parseInt(loginInfo?.current_role_competency_id)]);
  }, [loginInfo.current_role_competency_id]);
  useEffect(() => {
    fillSlotData(slotCount);
  }, [slotCount, loginInfo, dispatch]);
  useEffect(() => {
    weekendDrive(weekendDriveData);
  }, [weekendDriveData]);

  let authInfo = getAuthInfo();
  const hrCompetencyArray = authInfo?.userInfo?.role?.filter(
    (item) => item.user_role === "HR"
  );
  let filterByCompetencyOptions = [];
  hrCompetencyArray?.map((user) => {
    filterByCompetencyOptions.push({
      label: competencyList[user.role_competency_id],
      value: user.role_competency_id,
    });
  });
  function fillSlotData(slotData) {
    const slotsData = [
      {
        icon: availableSlots,
        type: DASHBOARD_CARD_ICONS_COLOR.AVAILABLE_SLOT,
        numberOfSlots: parseInt(slotData?.availabileSlots),
        slotTitle: DASHBOARD_CARD_TITLE.AVAILABLE_SLOT,
      },
      {
        icon: bookedSlots,
        type: DASHBOARD_CARD_ICONS_COLOR.BOOKED_SLOT,
        numberOfSlots: parseInt(slotData?.bookedSlots),
        slotTitle: DASHBOARD_CARD_TITLE.BOOKED_SLOT,
      },
      {
        icon: availableSlots,
        type: DASHBOARD_CARD_ICONS_COLOR.UNUTILISED_SLOT,
        numberOfSlots: parseInt(slotData?.unutilisedSlots),
        slotTitle: DASHBOARD_CARD_TITLE.UNUTILISED_SLOT,
      },
    ];

    setSlotCardData(slotsData);
  }
  const weekendDrive = (data) => {
    const slotsData = [
      {
        icon: drivesCreated,
        type: DASHBOARD_CARD_ICONS_COLOR.WEEKEND_DRIVE_DATA,
        count: parseInt(data?.total_drives_created_count),
        slotTitle: DASHBOARD_WEEKEND_DRIVE_CARD_TITLE.DRIVES_CREATED,
      },
      {
        icon: drivesConducted,
        type: DASHBOARD_CARD_ICONS_COLOR.WEEKEND_DRIVE_DATA,
        count: parseInt(data?.drives_conducted_count),
        slotTitle: DASHBOARD_WEEKEND_DRIVE_CARD_TITLE.DRIVES_CONDUCTED,
      },
      {
        icon: upcomingDrives,
        type: DASHBOARD_CARD_ICONS_COLOR.WEEKEND_DRIVE_DATA,
        count: parseInt(data?.upcoming_drives_count),
        slotTitle: DASHBOARD_WEEKEND_DRIVE_CARD_TITLE.UPCOMING_DRIVES,
      },
    ];

    setWeekendDriveDetails(slotsData);
  };
  const handleRecruiterValueAndActivePage = (value, reason) => {
    if (value.length > 0) {
      const recruiterIds = value?.map((item) => item.value);
      setTempRecruiters(value);
    } else {
      if (reason === "clear" && value.length === 0) {
        setTempRecruiters([{ label: loginInfo?.name, value: loginInfo?.id }]);
      }
    }
  };
  function handleInterviewCardClick(index, text, number) {
    setInterviewCardWithBorder(index);
    if (Number(number) === 0) {
      dispatch(resetDashboardTableData());
    } else {
      setActivePageNumber(1);
      const text2 = text
        .replace(/NA/g, "NOT AVAILABLE")
        .replace(/RS/g, "RESCHEDULED")
        .replace(/ /g, "_")
        .toUpperCase();
      setInterviewStatus([text2]);
    }
  }
  const sortedInterviewCardData =
    bookingStatus && Array.isArray(bookingStatus)
      ? Array.from(bookingStatus).sort((a, b) => {
        const statusA = a.status;
        const statusB = b.status;
        return (
          INTERVIEW_CARD_ORDER.indexOf(statusA) -
          INTERVIEW_CARD_ORDER.indexOf(statusB)
        );
      })
      : [];
  const cards = sortedInterviewCardData?.map((data, index) => {
    let text = data.status.replace(/_/g, " ").toLowerCase();
    text = text.replace(/not available/g, "NA").replace(/rescheduled/g, "RS");
    const capitalizedText = text.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    return (
      <Grid
        item
        key={index}
        className={styles.hoverDiv}
        flexDirection={"row"}
        flexWrap={"wrap"}
      >
        <Grid item>
          <InterviewCard
            text={capitalizedText}
            number={data.status_count}
            index={index}
            handleClick={handleInterviewCardClick}
            interviewCardWithBorder={interviewCardWithBorder}
          />
        </Grid>
      </Grid>
    );
  });
  function handlePageClick(e, value) {
    setActivePageNumber(value);
  }
  function fetchData(selectedCompetencyId) {
    dispatch(
      getRecuiterListByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetencyId ? selectedCompetencyId : selectedCompetency,
      })
    );
    dispatch(
      getSlotCountByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetencyId ? selectedCompetencyId : selectedCompetency,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
      })
    );
    dispatch(
      getBookingStatusByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetencyId ? selectedCompetencyId : selectedCompetency,
        recruiterId: selectedRecruiter,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
      })
    );
    dispatch(
      getCandidatesDetailByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetencyId ? selectedCompetencyId : selectedCompetency,
        status: interviewStatus,
        recruiterId: selectedRecruiter,
        pageNumber: 1,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
        sortType: sortBy.sortType,
        sortOrder: sortBy.sortOrder,
      })
    );
    dispatch(
      getPanelDetailByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetencyId ? selectedCompetencyId : selectedCompetency,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
      })
    );
    dispatch(
      getWeekendDriveData({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetencyId ? selectedCompetencyId : selectedCompetency,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
      })
    );
  }
  function handleSelect(competencySelectedOptions, reason) {
    const competencyIds = competencySelectedOptions?.map((item) => item.value);
    if (competencySelectedOptions.length == 0 && reason == "removeOption") {
      return;
    } else {
      if (competencySelectedOptions.length == 0 && reason == "clear") {
        setSelectedCompetency([
          parseInt(loginInfo?.current_role_competency_id),
        ]);
      } else {
        setSelectedCompetency(competencyIds);
      }
    }
  }
  const handleSortType = (columnLabel) => {
    setSortBy((prevState) => {
      const sortOrder =
        prevState.sortType === columnLabel
          ? prevState.sortOrder === SORT_ORDER.ASCENDING || !prevState.sortOrder
            ? SORT_ORDER.DESCENDING
            : SORT_ORDER.ASCENDING
          : SORT_ORDER.ASCENDING;
      return {
        sortType: columnLabel,
        sortOrder: sortOrder,
      };
    });
  };
  const selectedCompetencies = selectedCompetency?.map((id) => ({
    label: competencyList[id],
    value: id,
  }));
  const recruiterOptionsList = recruiterList?.map((props) => ({
    label: `${props?.first_name} ${props?.last_name}`,
    value: props?.id,
  }));
  const handleClose = () => {
    setShowFilteredCompetency(false);
    setShowFilteredRecruiter(false);
    setTempRecruiters(selectedRecruiterOptions);
  };
  const onSubmit = () => {
    if (selectedCompetency && selectedCompetency.length > 0) {
      setInterviewStatus(["SCHEDULED"]);
      setInterviewCardWithBorder(0);
      setActivePageNumber(1);
      fetchData();
      setShowFilteredCompetency(false);
      setIsFilterApplied(true);
    }
  };
  const handleRecruiterSubmit = () => {
    const tempRecruitersIds = tempRecruiters?.map((item) => item.value);
    setSelectedRecruiterOptions(tempRecruiters);
    setSelectedRecruiter(tempRecruitersIds);
    setActivePageNumber(1);
    setInterviewCardWithBorder(0);
    setInterviewStatus(["SCHEDULED"]);
    setShowFilteredRecruiter(false);
  };
  const handleClear = () => {
    setPeriodFrom(dayjs(subDays(new Date(), 15)));
    setPeriodTo(format(new Date().setDate(new Date().getDate() + 14), "yyyy-MM-dd"));
    setSelectedCompetency([parseInt(loginInfo?.current_role_competency_id)]);
    setInterviewStatus(["SCHEDULED"]);
    setInterviewCardWithBorder(0);
    setActivePageNumber(1);
    fetchData();
    setClear(!clear);
    setIsFilterApplied(true);
  };

  useEffect(()=>{
    dispatch(
      getWeekendDriveData({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetency,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
      })
    );

    dispatch(
      getSlotCountByCompetencyId({
        roleId: loginInfo?.role_id,
        competencyId: selectedCompetency,
        periodFrom: format(new Date(periodFrom), "yyyy-MM-dd"),
        periodTo: format(new Date(periodTo), "yyyy-MM-dd"),
      })
    );

  },[clear])

  if (loading) return <Spinner />;

  return (
    <>    
    <DashboardView
      loginInfo={loginInfo}
      isFilterApplied={isFilterApplied}
      setShowFilteredCompetency={setShowFilteredCompetency}
      slotCardData={slotCardData}
      isManager={isManager}
      setShowFilteredRecruiter={setShowFilteredRecruiter}
      selectedRecruiterOptions={selectedRecruiterOptions}
      cards={cards}
      interviewStatus={interviewStatus}
      handleSortType={handleSortType}
      sortBy={sortBy}
      handlePageClick={handlePageClick}
      activePageNumber={activePageNumber}
      weekendDriveDetails={weekendDriveDetails}
      showFilteredCompetency={showFilteredCompetency}
      handleClose={handleClose}
      onSubmit={onSubmit}
      filterByCompetencyOptions={filterByCompetencyOptions}
      handleSelect={handleSelect}
      selectedCompetencies={selectedCompetencies}
      periodFrom={periodFrom}
      periodTo={periodTo}
      setPeriodFrom={setPeriodFrom}
      setPeriodTo={setPeriodTo}
      handleClear={handleClear}
      showFilteredRecruiter={showFilteredRecruiter}
      handleRecruiterSubmit={handleRecruiterSubmit}
      recruiterOptionsList={recruiterOptionsList}
      tempRecruiters={tempRecruiters}
      handleRecruiterValueAndActivePage={handleRecruiterValueAndActivePage}
    />
    </>
  );
}

export default HrDashboard;