const eligibleSlotsCondition = (slot, headerForCsv) => {
  return (new Date(slot.startTime).getTime() +
    new Date(slot.endTime).getTime()) ===
    headerForCsv.key
    ? "Yes"
    : "No";
};

export const isSlotsAvailable = (slotsArr, headerForCsv) => {
  for (var i = 0; i < slotsArr.length; i++) {
    if (eligibleSlotsCondition(slotsArr[i], headerForCsv) === "Yes")
      return eligibleSlotsCondition(slotsArr[i], headerForCsv);
  }
  return "No";
};
