import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";

import Filter from "./FilterRoster/index";
import Detail from "./Detail";
import TabsList from "./TabsList";
import Spinner from "../../../components/Spinner";

import {
  fetchSuspendedListByCompetency,
  fetchActivePanelByCompetency,
  getDesignationByCompetencyId,
  getUserByCompetency,
  fetchActiveDesignationByCompetencyId,
  resetRosterFilterStatus,
  getCompetencyIdForFilters,
} from "../../../redux/features/Roster/rosterSlice";
import { getDesignationList } from "../../../redux/features/Authentication/authSlice";
import {
  fetchInterviewerDesignationsIds,
  fetchRoundDesignationId,
} from "../../../redux/features/Availability/availabilitySlice";
import {
  fetchActiveHrSpoc,
  getUserListForAdminAddRole,
} from "../../../redux/features/Admin/adminSlice";
import { getpanelGroupList } from "../../../redux/features/PanelGroup/panelGroup";

import { Container, Grid, Box, Typography } from "@mui/material";

import PropTypes from "prop-types";

import {
  ROSTER_FILTER_TAB_CHECK_VALUE,
  ROLE_TYPES,
  ROUND_FILTER_OPTIONS,
  SORT_ORDER,
  SORT_TYPE_CHECK_VALUE,
  currentRosterTabsState,
  RosterTabs,
} from "../../../constants/index";

import styles from "./style.module.scss";

const { isHr, isSpoc, isAdmin } = ROLE_TYPES;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="globalLayoutHeight"
    >
      {value === index && (
        <Box className={`${styles.paddingTop} globalLayoutHeight`}>
          <Typography className="globalLayoutHeight">{children}</Typography>
        </Box>
      )}
    </Container>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Roster = ({
  searchValue,
  setSearchValue,
  setShowSearch,
  setPlaceholderName,
  activePageNumber,
  setActivePageNumber,
  signal,
}) => {
  let dispatch = useDispatch();
  const location = useLocation();

  const competency = useSelector((state) => state.authReducer?.competency);
  const user = useSelector((state) => state.authReducer?.loginInfo);
  const designationData = useSelector(
    (state) => state.rosterReducer?.loggedInUserDesignation
  );
  const allDesignationData = useSelector(
    (state) => state.authReducer?.designation
  );
  const allUsersList = useSelector((state) => state.rosterReducer?.userData);
  const adminAllUserAddRoleData = useSelector(
    (state) => state.adminReducer?.adminAllUserAddRoleData
  );
  const suspendedList = useSelector(
    (state) => state.rosterReducer?.suspendedList
  );
  const loading = useSelector((state) => state.rosterReducer?.loading);
  const adminUsers = useSelector((state) => state.rosterReducer.panelData);
  const activeDesignations = useSelector(
    (state) => state?.rosterReducer?.activeDesignation
  );
  const eligibleDesignationIds = useSelector(
    (state) => state?.availabilityReducer?.search?.roundDesignationIds
  );
  const interviewerDesignationList = useSelector(
    (state) => state?.availabilityReducer?.interviewerDesignationList
  );
  const filterStatus = useSelector(
    (state) => state.rosterReducer?.isFilterApplied
  );
  const {
    competencyIdState,
    multicompetencyIdsState,
    interviewerDesignationIdsState,
    activeDesignationIdsState,
    genderState,
    roleTypesState,
    subCompetencyState,
    dateOfJoiningState,
    roundsNameState,
    tagsState,
  } = useSelector((state) => state.rosterReducer?.roster);

  const [searchParams, setSearchParams] = useSearchParams({ tabs: "all" });
  const [competencyId, setCompetencyId] = useState("");
  const [competencyIdFilter, setCompetencyIdFilter] = useState("");
  const [adminCompetencyIdFilter, setAdminCompetencyIdFilter] = useState([]);
  const [competencyList, setCompetencyList] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(
    currentRosterTabsState(searchParams.get("tabs"))
  );
  const [filtericon, setFiltericon] = useState(false);
  const [interviewerDesignation, setInterviewerDesignation] = useState([]);
  const [subcompetency, setSubcompetency] = useState([]);
  const [joiningDate, setJoiningDate] = useState({ from: "", to: "" });
  const [keyvalue, setKeyvalue] = useState(searchParams.get("tabs"));
  const [optionsFilterByCompetency, setOptionsFilterByCompetency] = useState(
    []
  );
  const [selectedCompetency, setSelectedCompetency] = useState("");
  const [sortBy, setSortBy] = useState({
    sortType: SORT_TYPE_CHECK_VALUE.NAME,
    sortOrder: SORT_ORDER.ASCENDING,
  });
  const [activedesignationId, setActiveDesignationId] = useState([]);
  const [gender, setGender] = useState("");
  const [panelGroupId, setPanelGroupId] = useState([]);
  const [roundName, setRoundName] = useState(ROUND_FILTER_OPTIONS.ALL);
  const [roleName, setRoleName] = useState("");
  const [designationIds, setDesignationIds] = useState([]);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [joiningLabel, setJoiningLabel] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  useEffect(() => {
    const tab = new URLSearchParams(location.search).get("tabs");
    if (!tab || !RosterTabs.includes(tab)) {
      setSearchParams({ tabs: "all" });
    }
  }, [location]);
  useEffect(() => {
    setKeyvalue(searchParams.get("tabs"));
    setActiveAccordion(currentRosterTabsState(searchParams.get("tabs")));
  }, [searchParams]);
  useEffect(() => {
    if (user.competency_id && !isAdmin(user.current_role)) {
      setCompetencyId(user.current_role_competency_id);
      setCompetencyIdFilter(user.current_role_competency_id);
    }
    return () => {
      setKeyvalue("all");
      setActiveAccordion(0);
      setCompetencyId("");
      setCompetencyIdFilter("");
      setInterviewerDesignation([]);
      setDesignationIds([]);
      setActivePageNumber(1);
      setJoiningDate({ from: "", to: "" });
      setJoiningLabel("");
      setGender("");
      setPanelGroupId([]);
      setRoundName(ROUND_FILTER_OPTIONS.ALL);
      setSubcompetency([]);
      setSelectedCompetency("");
      setIsFilterApplied(false);
    };
  }, [user, user.current_role, user.current_role_competency_id]);
  useEffect(() => {
    if (
      isAdmin(user?.current_role) &&
      (keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
        keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL) &&
      competencyId === user.current_role_competency_id
    ) {
      setCompetencyId("");
      setCompetencyIdFilter("");
      setSelectedCompetency("");
      setIsFilterApplied(false);
    }
  }, [user, competencyId, setCompetencyId, user.current_role_competency_id]);
  useEffect(() => {
    if (
      isAdmin(user.current_role) &&
      competencyId !== user.current_role_competency_id &&
      competencyId !== ""
    ) {
      dispatch(
        getDesignationList({
          roleId: user.role_id,
          competencyId,
        })
      );
    }
  }, [dispatch, user, competencyId]);
  useEffect(() => {
    if (
      user.role_id &&
      competencyId &&
      (keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL ||
        (!isAdmin(user?.current_role) &&
          keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE))
    ) {
      dispatch(
        getDesignationList({
          roleId: user.role_id,
          competencyId: isAdmin(user.current_role)
            ? competencyId
            : keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE
            ? ""
            : competencyId ?? user.current_role_competency_id,
        })
      );
      if (
        keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
        keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL
      ) {
        dispatch(
          getDesignationByCompetencyId({
            roleId: user.role_id,
            competencyId: user.current_role_competency_id,
          })
        );
      }
    }
  }, [user, dispatch, keyvalue, user.current_role_competency_id, competencyId]);
  useEffect(() => {
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL ||
      isAdmin(user.current_role)
    ) {
      setCompetencyList(competency);
      setCompetencyId(user.current_role_competency_id);
      setActiveDesignationId([]);
    }
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE &&
      !isAdmin(user.current_role)
    ) {
      setCompetencyId(user.current_role_competency_id);
      setCompetencyIdFilter(user.current_role_competency_id);
      const eligibleCom = user?.role?.reduce((acc, value) => {
        acc[value.role_competency_id] = `${
          competency[value.role_competency_id]
        }`;
        return acc;
      }, {});
      setCompetencyList(eligibleCom);
    }
  }, [
    keyvalue,
    setCompetencyList,
    competency,
    user.current_role_competency_id,
    user?.role,
    competencyId,
  ]);
  useEffect(() => {
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE &&
      isAdmin(user.current_role)
    ) {
      dispatch(
        fetchActiveHrSpoc({
          roleId: user.role_id,
          panelCompetencyId: isAdmin(user.current_role)
            ? null
            : user.current_role_competency_id,
          roleCompetencyIds: filterStatus ? multicompetencyIdsState : [],
          competencyId: filterStatus ? competencyIdState : "",
          designationIds: activeDesignationIdsState,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          signal: signal,
          roleType: roleTypesState,
        })
      );
    }
  }, [
    keyvalue,
    dispatch,
    user,
    activePageNumber,
    searchValue,
    sortBy,
    signal,
    competencyId,
  ]);
  useEffect(() => {
    if (
      !isAdmin(user?.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE
    ) {
      if (user.role_id) {
        dispatch(
          getpanelGroupList({
            roleId: user.role_id,
            competencyId: user.current_role_competency_id,
          })
        );
      }
    }
  }, [dispatch, user, user.current_role_competency_id, keyvalue]);
  useEffect(() => {
    if (
      user.current_role_competency_id &&
      (isSpoc(user.current_role) || isHr(user.current_role))
    ) {
      !isAdmin(user.current_role) &&
        dispatch(
          fetchActiveDesignationByCompetencyId({
            roleId: user.role_id,
            competencyId: user.current_role_competency_id,
          })
        );
    }
  }, [
    dispatch,
    user.current_role_competency_id,
    user.current_role,
    competencyId,
  ]);
  useEffect(() => {
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL &&
      !isAdmin(user.current_role) &&
      (competencyId || user.current_role_competency_id)
    ) {
      dispatch(
        getUserByCompetency({
          roleId: user.role_id,
          panelCompetencyId: isAdmin(user.current_role)
            ? null
            : user.current_role_competency_id,
          competencyId: (filterStatus && (competencyIdState.toString()?.length) )
            ? competencyIdState
            : isAdmin(user.current_role)
            ? ""
            : user.current_role_competency_id,
          designationIds: activeDesignationIdsState,
          dateOfJoining: dateOfJoiningState,
          subCompetency: subCompetencyState,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          gender: genderState,
          signal,
          query: searchValue,
        })
      );
    }
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL &&
      isAdmin(user.current_role)
    ) {
      dispatch(
        getUserListForAdminAddRole({
          roleId: user.role_id,
          competencyId: filterStatus ? competencyIdState : "",
          designationIds: activeDesignationIdsState,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          gender: genderState,
          signal,
          query: searchValue,
        })
      );
    }
  }, [
    keyvalue,
    dispatch,
    activePageNumber,
    signal,
    isAdmin,
    user.current_role,
    user.role_id,
    user.current_role_competency_id,
    sortBy.sortOrder,
    sortBy.sortType,
    searchValue,
    competencyId,
  ]);
  useEffect(() => {
    if (
      !isAdmin(user.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.SUSPENDED
    ) {
      dispatch(
        fetchSuspendedListByCompetency({
          competencyId: user.current_role_competency_id,
          roleId: user.role_id,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          signal: signal,
        })
      );
    }
  }, [
    activePageNumber,
    dispatch,
    isAdmin,
    keyvalue,
    searchValue,
    signal,
    sortBy.sortOrder,
    sortBy.sortType,
    user.current_role,
    user.current_role_competency_id,
    user.role_id,
  ]);
  useEffect(() => {
    if (
      !isAdmin(user.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE
    ) {
      dispatch(
        fetchActivePanelByCompetency({
          competencyId: user.current_role_competency_id,
          designationIds: activeDesignationIdsState,
          roleId: user.role_id,
          pageNumber: activePageNumber,
          sortType: sortBy.sortType,
          sortOrder: sortBy.sortOrder,
          query: searchValue,
          panelGroupId:
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE ||
            keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.GROUP
              ? tagsState
              : null,
          roundName: roundsNameState,
          activedesignationId: interviewerDesignationIdsState,
          joiningDate: dateOfJoiningState,
          subCompetency: subCompetencyState,
          signal: signal,
        })
      );
    }
  }, [
    keyvalue,
    dispatch,
    user.current_role_competency_id,
    activePageNumber,
    user.role_id,
    user.current_role,
    signal,
    isAdmin,
    sortBy.sortType,
    sortBy.sortOrder,
    searchValue,
    competencyId,
  ]);
  useEffect(() => {
    if (
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE &&
      !isAdmin(user?.current_role)
    ) {
      dispatch(
        fetchRoundDesignationId({
          competencyId: user.current_role_competency_id,
          roleId: user.role_id,
          panelGroupId,
          roundName,
        })
      );
    }
  }, [user, keyvalue, dispatch, panelGroupId, roundName]);
  useEffect(() => {
    if (
      (keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL ||
        keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE) &&
      isHr(user?.current_role)
    ) {
      dispatch(
        fetchInterviewerDesignationsIds({
          roleId: user?.role_id,
          competencyId: user?.current_role_competency_id,
        })
      );
    }
  }, [user, dispatch, keyvalue]);

  const handleChange = (event, newValue) => {
    setActiveAccordion(newValue);
    setFiltericon(false);
    setActivePageNumber(1);
    setSortBy({
      sortType: SORT_TYPE_CHECK_VALUE.NAME,
      sortOrder: SORT_ORDER.ASCENDING,
    });
    setCompetencyId(user.current_role_competency_id);
    setCompetencyIdFilter(user.current_role_competency_id);
    setDesignationIds([]);
    setPanelGroupId([]);
    setJoiningDate({ from: "", to: "" });
    setGender("");
    setJoiningLabel("");
    setInterviewerDesignation([]);
    setRoundName(ROUND_FILTER_OPTIONS.ALL);
    setSubcompetency([]);
    setIsFilterApplied(false);
    setSearchValue("");
    setRoleName("");
    setAdminCompetencyIdFilter([]);
    if (newValue === 0) {
      setKeyvalue("all");
      setShowSearch(true);
      setPlaceholderName("Search by name or email");
      setSearchParams({ tabs: "all" });
    } else if (newValue === 1) {
      setKeyvalue("active");
      setShowSearch(true);
      setPlaceholderName("Search by name or email");
      setSearchParams({ tabs: "active" });
    } else if (newValue === 2) {
      setKeyvalue("suspended");
      setShowSearch(true);
      setPlaceholderName("Search by name or email");
      setSearchParams({ tabs: "suspended" });
    } else if (newValue === 3) {
      setKeyvalue("tags");
      setShowSearch(false);
      setPlaceholderName("Search by name or email");
      setSearchParams({ tabs: "tags" });
    }
    dispatch(
      resetRosterFilterStatus(
        !isAdmin(user?.current_role) && newValue === 0 ? true : false
      )
    );
    if (!isAdmin(user?.current_role)) {
      dispatch(getCompetencyIdForFilters(user?.current_role_competency_id));
    }
  };

  const tabTitle = () => {
    if (keyvalue === "tags") return "Tags";
    else return "Panel Pool";
  };

  if (loading) return <Spinner />;

  return (
    <Grid container alignItems="center" className={"globalLayoutHeight"}>
      <TabsList
        tabTitle={tabTitle}
        user={user}
        activeAccordion={activeAccordion}
        handleChange={handleChange}
        keyvalue={keyvalue}
        showAddTagModal={showAddTagModal}
        setShowAddTagModal={setShowAddTagModal}
        filtericon={filtericon}
        setFiltericon={setFiltericon}
        isFilterApplied={isFilterApplied}
        competencyList={competencyList}
        competencyId={competencyId}
        sortBy={sortBy}
        roundName={roundName}
        designationIds={designationIds}
        searchValue={searchValue}
        activeDesignations={activedesignationId}
        joiningDate={joiningDate}
        panelGroupId={panelGroupId}
        subcompetency={subcompetency}
        searchParams={searchParams}
      />

      {filtericon && (
        <Filter
          keyvalue={keyvalue}
          user={user}
          competency={competency}
          competencyId={competencyId}
          setCompetencyId={setCompetencyId}
          designationData={designationData}
          subcompetency={subcompetency}
          setSubcompetency={setSubcompetency}
          competencyList={competencyList}
          setCompetencyList={setCompetencyList}
          interviewerDesignation={interviewerDesignation}
          setInterviewerDesignation={setInterviewerDesignation}
          joiningDate={joiningDate}
          setJoiningDate={setJoiningDate}
          optionsFilterByCompetency={optionsFilterByCompetency}
          setOptionsFilterByCompetency={setOptionsFilterByCompetency}
          joiningLabel={joiningLabel}
          setJoiningLabel={setJoiningLabel}
          activePageNumber={activePageNumber}
          setActivePageNumber={setActivePageNumber}
          selectedCompetency={selectedCompetency}
          setSelectedCompetency={setSelectedCompetency}
          filtericon={filtericon}
          setFiltericon={setFiltericon}
          gender={gender}
          setGender={setGender}
          signal={signal}
          panelGroupId={panelGroupId}
          setPanelGroupId={setPanelGroupId}
          roundName={roundName}
          setRoundName={setRoundName}
          sortBy={sortBy}
          activeDesignations={activeDesignations}
          allDesignationData={allDesignationData}
          eligibleDesignationIds={eligibleDesignationIds}
          activedesignationId={activedesignationId}
          setActiveDesignationId={setActiveDesignationId}
          designationIds={designationIds}
          setDesignationIds={setDesignationIds}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setKeyvalue={setKeyvalue}
          setIsFilterApplied={setIsFilterApplied}
          competencyIdFilter={competencyIdFilter}
          setCompetencyIdFilter={setCompetencyIdFilter}
          interviewerDesignationList={interviewerDesignationList}
          roleName={roleName}
          setRoleName={setRoleName}
          adminCompetencyIdFilter={adminCompetencyIdFilter}
          setAdminCompetencyIdFilter={setAdminCompetencyIdFilter}
        />
      )}
      <Detail
        activeAccordion={activeAccordion}
        setActiveAccordion={setActiveAccordion}
        keyvalue={keyvalue}
        setKeyvalue={setKeyvalue}
        allUsersList={allUsersList}
        competencyList={competencyList}
        activePageNumber={activePageNumber}
        setActivePageNumber={setActivePageNumber}
        user={user}
        adminUsers={adminUsers}
        CustomTabPanel={CustomTabPanel}
        suspendedList={suspendedList}
        searchValue={searchValue}
        setSortBy={setSortBy}
        sortBy={sortBy}
        interviewerDesignation={interviewerDesignation}
        joiningDate={joiningDate}
        subcompetency={subcompetency}
        signal={signal}
        designationIds={designationIds}
        setDesignationIds={setDesignationIds}
        showAddTagModal={showAddTagModal}
        setShowAddTagModal={setShowAddTagModal}
        panelGroupId={panelGroupId}
        activedesignationId={activedesignationId}
        competencyId={competencyId}
        setCompetencyId={setCompetencyId}
        competencyIdFilter={competencyIdFilter}
        isFilterApplied={isFilterApplied}
        gender={gender}
        handleTabsChange={handleChange}
        roundName={roundName}
        adminAllUserAddRoleData={adminAllUserAddRoleData}
        adminCompetencyIdFilter={adminCompetencyIdFilter}
        roleName={roleName}
        setRoleName={setRoleName}
        competencyIdState={competencyIdState}
      />
    </Grid>
  );
};

export default Roster;
