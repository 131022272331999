import React, { useState } from "react";

import CreateEditDL from "./CreateEditDL";
import Icon from "../../../components/SvgIcon/icon";
import Spinner from "../../../components/Spinner/index.js";
import Pagination from "../../../components/Pagination/index.js";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal.js";

import {
  Typography,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  Grid,
} from "@mui/material";

import EditIcon from "../../../assets/icons/edit-selected-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";

import styles from "./style.module.scss";

const DisplayDistributionList = ({
  dlEmailList,
  setActivePageNumber,
  activePageNumber,
  pageCount,
  checkWeekType,
  setCheckWeekType,
  distributionListName,
  distributionListEmail,
  distributionListDescription,
  setDistributionListDescription,
  setDistributionListEmail,
  setDistributionListName,
  configId,
  setConfigId,
  loginInfo,
  onDeleteDl,
  setActiveAccordion,
  setEditDlEmailId,
  editDlEmailId,
  loading,
  userCount,
}) => {
  const [selectedDl, setSelectedDl] = useState(null);
  const [editDlModal, setEditDlModal] = useState(false);
  const [showdeleteDlModal, setShowDeleteDlModal] = useState(false);

  const handleEditDl = (item) => {
    setEditDlModal(true);
    setEditDlEmailId(item.id);
    setDistributionListDescription(item.dl_description);
    setDistributionListEmail(item.dl_email);
    setDistributionListName(item.dl_name);
    setConfigId(item.id);
  };
  const handleDeleteDl = (user) => {
    setSelectedDl(user);
    setShowDeleteDlModal(true);
  };
  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };

  const getItems = (user) => {
    return (
      <TableCell className={"tablebody"}>
        <Grid container justifyContent={"center"} alignItems="center">
          <Grid
            item
            xl={2}
            md={6}
            lg={3}
            xs={12}
            className={styles.tooltipAlign}
          >
            <Tooltip title="Edit DL" placement="top" arrow>
              <Typography>
                <Icon
                  icon={EditIcon}
                  width="20.71px"
                  height="16px"
                  leftPadding="16.2px"
                  rightPadding="16.2px"
                  click={() => handleEditDl(user)}
                />
              </Typography>
            </Tooltip>
          </Grid>
          <Grid
            item
            xl={2}
            md={6}
            lg={3}
            xs={12}
            className={styles.tooltipAlign}
          >
            <Tooltip title="Delete DL" placement="top" arrow>
              <Typography>
                <Icon
                  icon={DeleteIcon}
                  width="20.71px"
                  height="16px"
                  leftPadding="16.2px"
                  rightPadding="16.2px"
                  click={() => handleDeleteDl(user)}
                />
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  if (loading) return <Spinner />;

  return (
    <Paper className={"globalTableLayoutStyle"}>
      <TableContainer
        className={"globalLayoutHeight globalTableContainerAlign"}
      >
        <Table aria-label="all-table" stickyHeader>
          <TableHead className={styles.tableHead}>
            <TableRow className={styles.tableRow}>
              <TableCell className={"tableheading"}>DL Name</TableCell>
              <TableCell className={"tableheading"} align="left">
                DL Email
              </TableCell>
              <TableCell className={"tableheading"} align="left">
                DL Description
              </TableCell>

              <TableCell
                className={"tableheading"}
                align={"center"}
                paddingRight={5}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dlEmailList &&
              dlEmailList?.map((user) => (
                <TableRow key={user.id} sx={{ border: 0 }}>
                  <TableCell component="th" scope="row" className={"tablebody"}>
                    {user.dl_name}
                  </TableCell>

                  <TableCell className={"tablebody"} align="left">
                    {user.dl_email}
                  </TableCell>
                  <TableCell className={"tablebody"} align="left">
                    {user.dl_description}
                  </TableCell>
                  {getItems(user)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination
          pageCount={pageCount}
          userCount={parseInt(userCount)}
          handlePageClick={handlePageClick}
          activePageNumber={activePageNumber}
        />
      </TableContainer>
      {editDlModal && (
        <CreateEditDL
          show={editDlModal}
          setShow={setEditDlModal}
          loginInfo={loginInfo}
          configId={configId}
          setConfigId={setConfigId}
          distributionListDescription={distributionListDescription}
          setDistributionListDescription={setDistributionListDescription}
          distributionListEmail={distributionListEmail}
          setDistributionListEmail={setDistributionListEmail}
          distributionListName={distributionListName}
          setDistributionListName={setDistributionListName}
          setActiveAccordion={setActiveAccordion}
          editDlEmailId={editDlEmailId}
          setEditDlEmailId={setEditDlEmailId}
        />
      )}

      {showdeleteDlModal && (
        <DeleteConfirmationModal
          show={showdeleteDlModal}
          setShow={setShowDeleteDlModal}
          clickConfirm={() => onDeleteDl(selectedDl)}
        />
      )}
    </Paper>
  );
};
export default DisplayDistributionList;
