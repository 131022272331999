import React, { useState } from "react";

import DeleteDesignationConfirmModal from "../DeleteDesignationConfirmModal";
import DatePickerComponent from "../../../../components/DatePickerComponent";
import AutoCompleteComponent from "../../../../components/AutoComplete/AutoCompleteComponent";
import SingleSelectComponent from "../../../../components/SingleSelect/index";
import CircularProgress from '@mui/material/CircularProgress';

import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
  InputLabel,
} from "@mui/material";

import { getDay } from "date-fns";

import {
  INTERVIEW_STATUS_WEEKEND_DRIVE,
  INTERVIEW_STATUS_CHECK,
  DRIVE_CANCEL_REASONS,
} from "../../../../constants";

import styles from "./style.module.scss";

const DriveDetails = ({
  isCreateWeekendBooking,
  bookings,
  handleChangeDate,
  compareDate,
  interviewStatus,
  setInterviewStatus,
  weekendDriveDate,
  setWeekendDriveDate,
  filterStatus,
  setFilterStatus,
  panelMemberWeekendAvailabilityFillDate,
  setPanelMemberWeekendAvailabilityFillDate,
  handleChangeDateAvailability,
  activedesignationId,
  setActiveDesignationId,
  optionCompetency,
  coordinatorId,
  setCoordinatorId,
  optionCompetencyDriveCoordiators,
  dateRange,
  setDateRange,
  dataEditWeekend,
  handlePanelDeadlineDateTrigger,
  selectedDates,
  setSelectedDates,
  setSlotTimes,
  reason,
  setReason,
  setOptionCompetency,
  coordinatorList,
  setOptionCompetencyDriveCoordinators,
  competencyList,
  getDesignationData,
  activeAccordion,
  editWeekendDrive,
  updatedBookings,
  setEditWeekendDrive,
  activePageNumber,
  loginInfo,
  slotTimes,
  selectedWeekendDriveId,
  setActiveAccordion,
  deletedDesignations,
  weekendDriveList,
  setSelectedWeekendDriveId,
  showDeleteDesignationConfirmModal,
  openDeleteBookingModal,
  isSendMail,
  setIsSendMail,
}) => {
  const [loading, setLoading] = useState(true);
  const isDisabledDate = (date) => {
    const day = getDay(date);
    return day == 6;
  };
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 6;
  };
  const isWeekdaysDisabled = (date) => {
    const dayOfWeek = date.day();
    return dayOfWeek === 0 || (dayOfWeek >= 1 && dayOfWeek <= 5);
  };
  const handleInterviewStatus = (event) => {
    let value = event.target.value;
    setInterviewStatus(value);
    if (value === INTERVIEW_STATUS_CHECK.CANCELLED) {
      openDeleteBookingModal(value);
    }
  };
  const handleEligibilityDesignationChange = (event) => {
    setActiveDesignationId(event);
  };
  const handleDriveCoordinatorsChange = (event) => {
    setCoordinatorId(event);
  };
  const handleChangeReminderDates = (event) => {
    setSelectedDates(event);
  };

  const DriveDate = () => {
    return (
      <Grid item xs={12} lg={12}>
        <DatePickerComponent
          label="Drive Date"
          value={weekendDriveDate}
          handleChange={(e, date) => {
            handleChangeDate(e, date);
          }}
          minDate={new Date()}
          disabledKeyboardNavigation={true}
          disableDay={isWeekday}
          disablePast={true}
          disabled={!(editWeekendDrive == null)}
          filterDate={isDisabledDate}
          dateFormat="dd/MM/yyyy"
          maxDate={null}
          isDateDisabled={isWeekdaysDisabled}
        />
      </Grid>
    );
  };
  const Positions = () => {
    return (
      <Grid item xs={12} lg={12}>
        <AutoCompleteComponent
          placeholder={"Designation"}
          label={"Select Eligibility Designation*"}
          disabled={
            (compareDate(weekendDriveDate) === -1 && true) ||
            INTERVIEW_STATUS_CHECK.COMPLETED === interviewStatus
          }
          options={optionCompetency}
          onChange={handleEligibilityDesignationChange}
          selectedValue={activedesignationId}
          toDisable={((compareDate(weekendDriveDate) === -1 && true) ||
            INTERVIEW_STATUS_CHECK.COMPLETED === interviewStatus)  && optionCompetency}
          chipDisabled={(compareDate(weekendDriveDate) === -1 && true) ||
            INTERVIEW_STATUS_CHECK.COMPLETED === interviewStatus}
        />
      </Grid>
    );
  };
  const DriveCoordinators = () => {
    return (
      <Grid item xs={12} lg={12}>
        <AutoCompleteComponent
          placeholder={"Coordinator"}
          label={"Select Drive Coordinator(s)*"}
          disabled={
            (compareDate(weekendDriveDate) === -1 && true) ||
            INTERVIEW_STATUS_CHECK.COMPLETED === interviewStatus
          }
          options={optionCompetencyDriveCoordiators}
          onChange={handleDriveCoordinatorsChange}
          selectedValue={coordinatorId}
          toDisable={((compareDate(weekendDriveDate) === -1 && true) ||
            INTERVIEW_STATUS_CHECK.COMPLETED === interviewStatus)  && optionCompetencyDriveCoordiators}
          chipDisabled={(compareDate(weekendDriveDate) === -1 && true) ||
            INTERVIEW_STATUS_CHECK.COMPLETED === interviewStatus}
        />
      </Grid>
    );
  };
  const InterviewStatus = () => {
    return (
      <Grid item xs={12} lg={12}>
        <FormControl fullWidth>
          <InputLabel>Drive Status</InputLabel>
          <Select
            label="Drive Status"
            labelId="status-label"
            value={interviewStatus}
            disabled={
              interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED &&
              editWeekendDrive == null
            }
            onChange={(event) => handleInterviewStatus(event)}
          >
            {Object.entries(INTERVIEW_STATUS_WEEKEND_DRIVE)?.map(
              ([key, value]) => (
                <MenuItem
                  key={key}
                  value={key}
                  disabled={
                    key === "COMPLETED" && compareDate(weekendDriveDate) === 1
                      ? true
                      : false
                  }
                >
                  <Typography className={styles.fontSize}>{value}</Typography>
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
    );
  };
  const PanelAvailability = () => {
    return (
      <Grid item xs={12} lg={12}>
        <DatePickerComponent
          label="Panel Availability to be filled by"
          value={panelMemberWeekendAvailabilityFillDate}
          handleChange={(e, date) => {
            handleChangeDateAvailability(e, date);
          }}
          minDate={new Date()}
          disabledKeyboardNavigation={true}
          disablePast={true}
          disableDay={isWeekday}
          maxDate={new Date(weekendDriveDate).setFullYear(
            new Date(weekendDriveDate).getFullYear(),
            new Date(weekendDriveDate).getMonth(),
            new Date(weekendDriveDate).getDate() - 1
          )}
          dateFormat="dd/MM/yyyy"
          shouldDisableDate={isDisabledDate}
          disabled={
            (compareDate(weekendDriveDate) === -1 && true) ||
            INTERVIEW_STATUS_CHECK.COMPLETED === interviewStatus
          }
        />
      </Grid>
    );
  };
  const AvailabilityReminderDates = () => {
    if (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED) {
      return (
        <Grid item xs={12} lg={12}>
          <AutoCompleteComponent
            placeholder={"Date(s)"}
            label={"Select Availability Reminder Dates"}
            disabled={compareDate(weekendDriveDate) === -1 && true}
            chipDisabled={compareDate(weekendDriveDate) === -1 && true}
            toDisable={dateRange}
            options={dateRange}
            onChange={handleChangeReminderDates}
            selectedValue={selectedDates}
          />
        </Grid>
      );
    }
  };
  const AddReason = () => {
    if (interviewStatus === INTERVIEW_STATUS_CHECK.CANCELLED) {
      return (
        <Grid item xs={12} lg={12}>
          <SingleSelectComponent
            options={DRIVE_CANCEL_REASONS}
            onChange={(e) => setReason(e.value)}
            label={"Reason*"}
            value={reason}
          />
        </Grid>
      );
    }
  };
  const SendCancelMail = () => {
    if (interviewStatus === INTERVIEW_STATUS_CHECK.CANCELLED) {
      return (
        <Grid
          item
          xl={12}
          md={12}
          sx={{ display: "flex", justifyContent: "left" }}
        >
          <Checkbox
            onChange={(e) => setIsSendMail(e.target.checked)}
            checked={isSendMail}
            className={styles.checkboxAlign}
          />
          <Typography marginTop={1.5} className={styles.cancelTextStyle}>
            Do you want to send Cancel Mail?
          </Typography>
        </Grid>
      );
    }
  };

  setTimeout(() => {
    setLoading(false)
  }, 100);
  
  if(loading && editWeekendDrive)
  return(<><CircularProgress /> </>)

  return (
    <Grid container spacing={2}>
      {DriveDate()}
      {Positions()}
      {DriveCoordinators()}
      {InterviewStatus()}
      {PanelAvailability()}
      {AvailabilityReminderDates()}
      {AddReason()}
      {SendCancelMail()}
      {showDeleteDesignationConfirmModal && editWeekendDrive !== null && (
        <DeleteDesignationConfirmModal
          bookings={bookings}
          interviewStatus={interviewStatus}
          editWeekendDrive={editWeekendDrive}
        />
      )}
    </Grid>
  );
};

export default DriveDetails;
