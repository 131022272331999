import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Nodata from "../NoData/index";
import CreateConfigDl from "./CreateConfigDL";
import Icon from "../../../components/SvgIcon/icon";
import ReportsConfigTable from "./ReportsConfigTable";
import FiltersReportsConfig from "./FiltersReportsConfig";
import AutoCompleteComponent from "../../../components/AutoComplete/index";

import {
  configFetchAllDlListForAdmin,
  configFetchAllUserListForAdmin,
  configFetchDlAdminList,
  resetAdminFilterStatus,
} from "../../../redux/features/Admin/adminSlice";

import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import FilteredIcon from "../../../assets/icons/filtered-icon.svg";
import UnFilteredIcon from "../../../assets/icons/unfiltered.svg";

import {
  ADMIN_DL_DRIVE_OPTIONS,
  ADMIN_DL_DRIVE_VALUES,
  ROLE_TYPES,
} from "../../../constants/index";

import styles from "./style.module.scss";

const { isAdmin } = ROLE_TYPES;

const ReportsConfig = () => {
  let dispatch = useDispatch();
  const loading = useSelector((state) => state.adminReducer?.loading);
  const competency = useSelector((state) => state.authReducer?.competency);
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const existingdlEmails = useSelector(
    (state) => state?.adminReducer?.allExistingDls
  );
  const usersList = useSelector(
    (state) => state?.adminReducer?.allUserEmails?.usersList
  );
  const dlDataForConfig = useSelector(
    (state) => state.adminReducer?.dlDataForConfig
  );
  const filterStatus = useSelector(
    (state) => state.adminReducer?.isFilterApplied
  );
  const weekType = useSelector(
    (state) => state.adminReducer?.reportsConfig?.weekType
  );
  const configCompetencyId = useSelector(
    (state) => state.adminReducer?.reportsConfig?.competencyId
  );

  const [competencyId, setCompetencyId] = useState("");
  const [showCreateConfigModal, setShowCreateConfigModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [competencyList, setCompetencyList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [selectedDlEmailId, setSelectedDlEmailId] = useState([]);
  const [optionsEmail, setOptionsEmail] = useState([]);
  const [editDlEmailId, setEditDlEmailId] = useState(null);
  const [errorUserEmail, setErrUserEmail] = useState(false);
  const [selectedUserEmailId, setSelectedUserEmailId] = useState([]);
  const [optionsUserEmail, setOptionsUserEmail] = useState([]);
  const [reportType, setReportType] = useState(
    ADMIN_DL_DRIVE_VALUES.WEEKDAYS_BOOKINGS_REPORT
  );
  const [optionsFilterByCompetency, setOptionsFilterByCompentecy] = useState(
    []
  );

  useEffect(() => {
    dispatch(
      configFetchAllDlListForAdmin({
        roleId: loginInfo.role_id,
      })
    );
  }, [dispatch, loginInfo.role_id]);
  useEffect(() => {
    dispatch(
      configFetchDlAdminList({
        roleId: loginInfo.role_id,
        pageNumber: activePageNumber,
        reportType: weekType,
        competencyId: configCompetencyId,
      })
    );
  }, [activePageNumber, dispatch, loginInfo.role_id]);
  useEffect(() => {
    const optionsEmail = existingdlEmails?.reduce((accumulator, item) => {
      accumulator.push({
        value: item.id,
        label: item.email,
      });
      return accumulator;
    }, []);
    if (optionsEmail) {
      setOptionsEmail(optionsEmail);
    } else {
      setOptionsEmail([]);
    }
  }, [existingdlEmails]);
  useEffect(() => {
    const optionsUserEmail = usersList?.reduce((accumulator, item) => {
      accumulator.push({
        value: item.id,
        label: item.email,
      });
      return accumulator;
    }, []);
    if (optionsUserEmail) {
      setOptionsUserEmail(optionsUserEmail);
    } else {
      setOptionsUserEmail([]);
    }
  }, [usersList]);
  useEffect(() => {
    setCompetencyList(competency);
  }, [competency]);
  useEffect(() => {
    getSortedCompetencyList(competencyList);
  }, [competencyList]);
  useEffect(() => {
    const data = getSortedCompetencyList(competencyList);
    const competencyDataFilter = Object.entries(data);
    if (competencyDataFilter.length) {
      const optionsFilterByCompetency = competencyDataFilter?.map((item) => {
        return {
          value: item[0].trim().toString(),
          label: item[1].toString(),
        };
      });
      setOptionsFilterByCompentecy(optionsFilterByCompetency);
    } else {
      setOptionsFilterByCompentecy([]);
    }
  }, [competencyList]);
  useEffect(() => {
    const dataFiltered = optionsFilterByCompetency.find(
      (item) => parseInt(item.value) === competencyId
    );
    setSelectedOption(dataFiltered?.value);
  }, [
    competencyId,
    loginInfo.current_role,
    loginInfo.current_role_competency_id,
    optionsFilterByCompetency,
    selectedOption,
  ]);

  const onSelectCompetency = (item) => {
    setCompetencyId(parseInt(item.target.value));
    setSelectedOption(item.target.value);
    setSelectedUserEmailId([]);
    if (parseInt(item.target.value) !== 1) {
      dispatch(
        configFetchAllUserListForAdmin({
          roleId: loginInfo.role_id,
          competencyId: parseInt(item.target.value),
        })
      );
    }
  };
  const onSelectDrive = (value) => {
    setReportType(value);
  };
  const onSelectEmails = (e) => {
    setActivePageNumber(1);
    setSelectedDlEmailId(e);
  };
  const onSelectUserEmails = (e) => {
    setActivePageNumber(1);
    setSelectedUserEmailId(e);
  };
  const getSortedCompetencyList = (competencyList) => {
    let compListArray = [];
    for (const competency in competencyList) {
      compListArray.push([competencyList[competency]] + ":" + competency);
    }
    compListArray = compListArray.sort();

    let sortedCompetencyList = compListArray.reduce((acc, item) => {
      acc[" " + item.split(":")[1]] = item.split(":")[0];
      return acc;
    }, {});
    return sortedCompetencyList;
  };

  const DriveReportFilter = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="report-type-label">Report Type*</InputLabel>
        <Select
          value={reportType || ADMIN_DL_DRIVE_VALUES.WEEKDAYS_BOOKINGS_REPORT}
          onChange={(event) => onSelectDrive(event.target.value)}
          disabled={editDlEmailId !== null}
          fullWidth
          labelId="report-type-label"
          id="report-type-id"
          placeholder="Report Type"
          label="Report Type"
          multiline
          displayEmpty
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          required
        >
          {Object.entries(ADMIN_DL_DRIVE_OPTIONS)?.map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const CompetencyFilter = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="competency-label">Competency*</InputLabel>
        <Select
          labelId="competency-label"
          label="Competency"
          id="competency-id"
          placeholder="Competency"
          value={competencyId}
          multiline
          fullWidth
          displayEmpty
          disabled={editDlEmailId !== null}
          onChange={(e) => onSelectCompetency(e)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          required
        >
          {Object.entries(optionsFilterByCompetency)?.map(([key, value]) => (
            <MenuItem key={key} value={value.value}>
              {value.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const UserEmail = () => {
    return (
      <AutoCompleteComponent
        options={optionsUserEmail}
        placeholder={"Email"}
        disabled={competencyId === ""}
        label={"Select User Email*"}
        onChange={onSelectUserEmails}
        selectedValue={selectedUserEmailId}
      />
    );
  };
  const DlEmail = () => {
    return (
      <AutoCompleteComponent
        options={optionsEmail}
        placeholder={"Email"}
        label={"Select DL Email"}
        onChange={onSelectEmails}
        selectedValue={selectedDlEmailId}
      />
    );
  };

  return (
    isAdmin(loginInfo.current_role) && (
      <Box className={"globalLayoutHeight"}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={1}
        >
          <Grid item xs={4} md={4} lg={4} xl={4}>
            <Typography className={"heading"}>Reports Configuration</Typography>
          </Grid>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            spacing={2}
            justifyContent={"end"}
            xs={8}
            md={8}
            lg={8}
            xl={8}
          >
            <Grid
              item
              paddingTop={0.5}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Tooltip title="Filter" placement="top" arrow>
                <Box
                  className={
                    filterStatus
                      ? styles.filteredStyles
                      : styles.unfilteredStyles
                  }
                  onClick={() => {
                    setShowFilter(true);
                    if (!filterStatus) {
                      dispatch(resetAdminFilterStatus());
                    }
                  }}
                >
                  <Icon
                    icon={filterStatus ? FilteredIcon : UnFilteredIcon}
                    width={"15.5px"}
                    height={"16px"}
                    color={filterStatus ? "#FFFFFF" : null}
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title="Create Config Report"
                arrow={true}
                placement="top"
              >
                <Box
                  className={styles.addTagStyle}
                  component={"button"}
                  onClick={() => setShowCreateConfigModal(true)}
                >
                  <AddIcon />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={"globalLayoutHeight"}>
          {dlDataForConfig?.dlEmailList?.length ? (
            <ReportsConfigTable
              showCreateConfigModal={showCreateConfigModal}
              setShowCreateConfigModal={setShowCreateConfigModal}
              pageCount={dlDataForConfig?.pageCount}
              dlEmailList={dlDataForConfig?.dlEmailList}
              userCount={dlDataForConfig?.totalList}
              editDlEmailId={editDlEmailId}
              checkWeekType={weekType}
              setActivePageNumber={setActivePageNumber}
              activePageNumber={activePageNumber}
              setEditDlEmailId={setEditDlEmailId}
              loginInfo={loginInfo}
              selectedDlEmailId={selectedDlEmailId}
              setSelectedDlEmailId={setSelectedDlEmailId}
              reportType={reportType}
              setReportType={setReportType}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              competencyId={competencyId}
              setCompetencyId={setCompetencyId}
              selectedUserEmailId={selectedUserEmailId}
              setSelectedUserEmailId={setSelectedUserEmailId}
              optionsFilterByCompetency={optionsFilterByCompetency}
              competencyIdFilter={configCompetencyId}
              setErrUserEmail={setErrUserEmail}
              DlEmail={DlEmail}
              DriveReportFilter={DriveReportFilter}
              CompetencyFilter={CompetencyFilter}
              UserEmail={UserEmail}
              loading={loading}
            />
          ) : (
            !loading && <Nodata />
          )}
        </Grid>
        {showCreateConfigModal && editDlEmailId === null && (
          <CreateConfigDl
            show={showCreateConfigModal}
            setShow={setShowCreateConfigModal}
            editDlEmailId={editDlEmailId}
            checkWeekType={weekType}
            setActivePageNumber={setActivePageNumber}
            activePageNumber={activePageNumber}
            pageCount={dlDataForConfig?.pageCount}
            dlEmailList={dlDataForConfig?.dlEmailList}
            setEditDlEmailId={setEditDlEmailId}
            loginInfo={loginInfo}
            setSelectedDlEmailId={setSelectedDlEmailId}
            setReportType={setReportType}
            setSelectedOption={setSelectedOption}
            setCompetencyId={setCompetencyId}
            selectedDlEmailId={selectedDlEmailId}
            setSelectedUserEmailId={setSelectedUserEmailId}
            reportType={reportType}
            selectedUserEmailId={selectedUserEmailId}
            optionsFilterByCompetency={optionsFilterByCompetency}
            competencyIdFilter={configCompetencyId}
            DlEmail={DlEmail}
            DriveReportFilter={DriveReportFilter}
            CompetencyFilter={CompetencyFilter}
            UserEmail={UserEmail}
            competencyId={competencyId}
            setErrUserEmail={setErrUserEmail}
          />
        )}
        {showFilter && (
          <FiltersReportsConfig
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setActivePageNumber={setActivePageNumber}
            activePageNumber={activePageNumber}
            loginInfo={loginInfo}
            optionsFilterByCompetency={optionsFilterByCompetency}
          />
        )}
      </Box>
    )
  );
};

export default ReportsConfig;
