import React, { useState } from "react";
import { useSelector } from "react-redux";

import BookingModal from "./BookingModal/index";

import { Chip, Grid } from "@mui/material";

import {
  extractTime,
  validateDate,
  extractTimeWithoutAMPM,
} from "../../../utils/date-utils";
import { ROLE_TYPES } from "../../../constants";

import styles from "./style.module.scss";

const { isHr } = ROLE_TYPES;

const AvailableSlotView = ({
  time,
  slot,
  rounds,
  weekDate,
  user,
  email,
  panelMemberAvailabilityPayload,
  onShowAvailabilityUpdateModal,
  date,
  isDateOrPast,
  isPast,
  isSaturday,
}) => {
  const { loginInfo } = useSelector((state) => state?.authReducer);

  const [showBookingModal, setShowBookingModal] = useState(false);
  const [slotTimeForModal, setSlotTimeForModal] = useState({});

  const onShowModal = (startTime, endTime) => {
    setSlotTimeForModal({ startTime, endTime });
    setShowBookingModal(true);
  };
  return (
    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        {validateDate(weekDate) &&
          isHr(loginInfo.current_role) &&
          showBookingModal && (
            <BookingModal
              weekDate={weekDate}
              panelMemberAvailabilityPayload={panelMemberAvailabilityPayload}
              showBookingModal={showBookingModal}
              setShowBookingModal={setShowBookingModal}
              slotTime={slotTimeForModal}
              rounds={rounds}
              user={user}
            />
          )}
        <Chip
          label={`${extractTimeWithoutAMPM(time.startTime)} - ${extractTime(
            time.endTime
          )}`}
          style={{
            pointerEvents:
              (isHr(loginInfo.current_role) && isPast(date)) ||
              (!isHr(loginInfo.current_role) && isDateOrPast(date)) ||
              (!isHr(loginInfo.current_role) && isSaturday(date))
                ? "none"
                : "auto",
            outline: "none",
          }}
          className={
            isHr(loginInfo.current_role) && validateDate(weekDate)
              ? `${styles.availableSlotStyle} cursorPointer`
              : `${styles.availableSlotStyle}`
          }
          key={time.startTime}
          onClick={() => {
            onShowAvailabilityUpdateModal();
            onShowModal(time.startTime, time.endTime);
          }}
        ></Chip>
      </Grid>
    </Grid>
  );
};

export default AvailableSlotView;
