import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SlotAndBookingView from "./SlotAndBookingView";
import Pagination from "../../../components/Pagination/index";
import TemporarySuspendModal from "./TemporarySuspendModal";
import TempUnavailableModal from "../Roster/TempUnavailableModal";
import Icon from "../../../components/SvgIcon/icon";
import Spinner from "../../../components/Spinner/index";
import PanelDetailsView from "../../../components/PanelDetailsView/PanelDetailsView";
import AddCommentModal from "../Roster/AddCommentModal";
import NoData from "../NoData/index";

import { fetchCommentsForUser } from "../../../redux/features/Roster/rosterSlice";

import {
  Typography,
  Grid,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  Chip,
} from "@mui/material";

import CommentsActive from "../../../assets/icons/active-comments.svg";
import CommentsInactive from "../../../assets/icons/inactive-comments.svg";
import Suspended from "../../../assets/icons/TempSuspended.svg";
import TempUnavailable from "../../../assets/icons/unavailable.svg";

import {
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
} from "date-fns";

import {
  deriveAbbreviatedName,
  isTodayHoliday,
  isWeekendDriveExists,
  isCancelledWeekendDrive,
} from "../../../utils/app-utils";
import {
  MONTH_NAME,
  WEEK_DAYS,
  INTERVIEW_STATUS_OPTIONS,
  ROLE_TYPES,
  MODULE_NAME,
} from "../../../constants";

import styles from "./style.module.scss";

const { isHr, isSpoc } = ROLE_TYPES;

const PanelMemberList = ({
  open,
  startDateOfWeek,
  endDateOfWeek,
  activePageNumber,
  setActivePageNumber,
  panelMemberAvailabilityPayload,
  competencyId,
  roundDesignationId,
  slotsProvided,
  roundName,
  activedesignationId,
  searchValue,
  panelGroupId,
  subCompetency,
  weekFrom,
  weekTo,
  memberListWithPanelData,
  signal,
  stateToogle,
  setStateToogle,
  weekendDrivesByDate,
  userList,
  loading,
  pageCount,
  totalPanelSelectedCount,
  weekendDriveLoading,
  activeDesignationLoading,
  isPast,
  isDateOrPast,
  tagsBadge
}) => {
  const dispatch = useDispatch();
  const { loginInfo, holidaysList } = useSelector(
    (state) => state?.authReducer
  );
  const commentsForUniqueUser = useSelector(
    (state) => state?.rosterReducer?.userComments
  );
  const commentsLoading = useSelector(
    (state) => state?.rosterReducer?.commentsLoading
  );
  const roundsLoading = useSelector(
    (state) => state?.availabilityReducer?.search?.loading
  );

  const [showTempSuspendedModal, setShowTempSuspendedModal] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [showTempUnavailableModal, setShowTempUnavailableModal] =
    useState(false);
  const [selectedPanel, setSelectedPanel] = useState(null);

  const weekDaysDates = [
    new Date(nextMonday(startDateOfWeek)),
    new Date(nextTuesday(startDateOfWeek)),
    new Date(nextWednesday(startDateOfWeek)),
    new Date(nextThursday(startDateOfWeek)),
    new Date(nextFriday(startDateOfWeek)),
    new Date(nextSaturday(startDateOfWeek)),
  ];
  const handleTempSuspendModal = (item) => {
    setSelectedPanel({ ...item });
    setShowTempSuspendedModal(true);
  };
  const handleAddCommentModal = (user) => {
    setSelectedPanel({ ...user, key: MODULE_NAME.AVAILABILITY });
    setShowAddCommentModal(true);
    dispatch(
      fetchCommentsForUser({
        roleId: loginInfo.role_id,
        userId: user?.id,
      })
    );
  };
  const handleTempUnavailableModal = (item) => {
    setSelectedPanel({ ...item, key: "ALL" });
    setShowTempUnavailableModal(true);
  };
  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const extractRoundWiseEligibility = (rounds = []) => {
    const response = rounds.reduce(
      (acc, value) => {
        if (value.roundName === "R1") {
          acc["R1"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        if (value.roundName === "R2") {
          acc["R2"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        if (value.roundName === "MR") {
          acc["MR"].push(
            `${deriveAbbreviatedName(value.designationName ?? "")}${
              value.isPairing ? "(P)" : ""
            }`
          );
        }
        return acc;
      },
      { R1: [], R2: [], MR: [] }
    );
    return (
      <Grid container display="flex" flexWrap="wrap">
        {!!response["R1"].length &&
          response["R1"].map((round, index) => (
            <Grid item key={index} style={{ flexBasis: "auto" }}>
              <Chip
                label={`R1-${round}`}
                className={styles.individualChipStyle}
              />
            </Grid>
          ))}
        {!!response["R2"].length &&
          response["R2"].map((round, index) => (
            <Grid item key={index} style={{ flexBasis: "auto" }}>
              <Chip
                label={`R2-${round}`}
                className={styles.individualChipStyle}
              />
            </Grid>
          ))}
        {!!response["MR"].length &&
          response["MR"].map((round, index) => (
            <Grid item key={index} style={{ flexBasis: "auto" }}>
              <Chip
                label={`MR-${round}`}
                className={styles.individualChipStyle}
              />
            </Grid>
          ))}
      </Grid>
    );
  };
  const isAvailableDate = (date) => {
    const todayDate = new Date(new Date().setHours(0, 0, 0, 0));
    return new Date(date) >= todayDate;
  };
  const getItems = (user) => {
    if (isHr(loginInfo?.current_role) || isSpoc(loginInfo?.current_role)) {
      return (
        <TableCell sx={{ padding: "10px" }}>
          <Grid container justifyContent={"center"} alignItems="center">
            <Grid
              item
              xl={isHr(loginInfo.current_role) ? 12 : 4}
              md={12}
              lg={12}
              xs={12}
              className={styles.tooltipAlign}
            >
              <Tooltip title="Notes, Click to see" placement="top" arrow>
                <Typography>
                  <Icon
                    icon={
                      user?.is_comments_available
                        ? CommentsActive
                        : CommentsInactive
                    }
                    width="20.71px"
                    height="16px"
                    leftPadding="16.2px"
                    rightPadding="16.2px"
                    click={() => handleAddCommentModal(user)}
                  />
                </Typography>
              </Tooltip>
            </Grid>
            {isSpoc(loginInfo?.current_role) && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={4}
                className={styles.tooltipAlign}
              >
                <Tooltip
                  title="Mark Temporary Unavailable"
                  placement="top"
                  arrow
                >
                  <Typography justifyContent="right" alignItems="center">
                    <Icon
                      icon={TempUnavailable}
                      width="20.71px"
                      height="16px"
                      leftPadding="16.2px"
                      rightPadding="16.2px"
                      click={() => handleTempUnavailableModal(user)}
                    />
                  </Typography>
                </Tooltip>
              </Grid>
            )}
            {isSpoc(loginInfo?.current_role) && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={4}
                className={styles.tooltipAlign}
              >
                <Tooltip title="Mark Temporary Suspended" placement="top" arrow>
                  <Typography justifyContent="right" alignItems="center">
                    <Icon
                      icon={Suspended}
                      width="20.71px"
                      height="16px"
                      leftPadding="16.2px"
                      rightPadding="16.2px"
                      click={() => handleTempSuspendModal(user)}
                    />
                  </Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </TableCell>
      );
    }
  };

  return (
    <>
      {loading ||
      weekendDriveLoading ||
      activeDesignationLoading ||
      roundsLoading ? (
        <Spinner />
      ) : totalPanelSelectedCount !== "0" &&
        userList?.length &&
        (!loading ||
          !weekendDriveLoading ||
          !activeDesignationLoading ||
          !roundsLoading) ? (
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          className={`globalLayoutHeight`}
          sx={{ overflow: "hidden" }}
        >
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            md={12}
            marginRight={2}
            marginTop={2}
            className={
              open
                ? `globalLayoutHeight ${styles.tableOpenScroll}`
                : `globalLayoutHeight ${styles.tableCloseScroll}`
            }
          >
            <Paper className={"globalTableLayoutStyle"}>
              <TableContainer
                className={"globalLayoutHeight globalTableContainerAlign"}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={styles.tableHead}>
                    <TableRow className={styles.tableRow}>
                      <TableCell
                        style={{ minWidth: "185px" }}
                        className={`tableheading ${styles.column1headerFreeze}`}
                      >
                        Panel Member
                      </TableCell>
                      <TableCell
                        className={`tableheading ${styles.column2headerFreeze}`}
                        style={{ whiteSpace: "nowrap", minWidth: "165px" }}
                      >
                        Eligible Rounds
                      </TableCell>
                      <TableCell
                        className={`tableheading`}
                        style={{ whiteSpace: "nowrap", minWidth: "165px" }}
                      >
                        Tags
                      </TableCell>
                      {Object.values(WEEK_DAYS)?.map(({ day }, index) => (
                        <TableCell
                          className={"tableheading"}
                          align="center"
                          key={index}
                        >
                          {day}
                          <Typography
                            style={{
                              marginBottom: 0,
                              fontSize: "12px",
                            }}
                          >
                            {weekDaysDates[index].getDate()}{" "}
                            {MONTH_NAME[weekDaysDates[index].getMonth() + 1]}
                          </Typography>
                        </TableCell>
                      ))}
                      {(isHr(loginInfo?.current_role) ||
                        isSpoc(loginInfo?.current_role)) && (
                        <TableCell
                          className={"tableheading"}
                          align="center"
                          width="10%"
                        >
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!userList?.length &&
                      userList?.map((user, index) => (
                        <TableRow key={user.email} sx={{ border: 0 }}>
                          <TableCell
                            className={`tablebody cursorPointer ${styles.column1bodyFreeze}`}
                            style={{ verticalAlign: "top" }}
                          >
                            <PanelDetailsView
                              profilePicUrl={user.profile_pic_url}
                              interviewerEmail={user.email}
                              firstName={user.first_name}
                              lastName={user.last_name}
                              designationName={user.designation_name}
                              employeeCode={user.employee_code}
                              index={index}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            className={`tablebody ${styles.column2bodyFreeze}`}
                          >
                            {extractRoundWiseEligibility(user.rounds)}
                          </TableCell>

                          <TableCell align="left" className={`tablebody`}>
                            {user?.panel_tags?.length &&
                              tagsBadge(user?.panel_tags)}
                          </TableCell>
                          {weekDaysDates?.map((dayDate, index) => {
                            const dateofBooking = new Date(dayDate).getDate();
                            const isHoliday = isTodayHoliday(
                              dayDate,
                              holidaysList
                            );
                            const isCancelledDrive = isCancelledWeekendDrive(
                              dayDate,
                              weekendDrivesByDate
                            );
                            const isWeekendDriveExist = isWeekendDriveExists(
                              dayDate,
                              weekendDrivesByDate
                            );
                            const isLoggedInUserIsBookingCreator = () => {
                              let result = false;
                              if (user.booking) {
                                user.booking.forEach((item) => {
                                  const date_start = new Date(
                                    item.startTime
                                  ).getDate();
                                  if (
                                    (parseInt(loginInfo.id) ===
                                      parseInt(item.assignedByUserId)) ===
                                      true &&
                                    date_start === dateofBooking &&
                                    (INTERVIEW_STATUS_OPTIONS[
                                      item.interview_status
                                    ] === "Scheduled" ||
                                      INTERVIEW_STATUS_OPTIONS[
                                        item.interview_status
                                      ] === "To Be Scheduled") &&
                                    !item.isInterviewerDeleted
                                  ) {
                                    result = true;
                                  }
                                });
                                return result;
                              }
                              return false;
                            };
                            const bookingByHr =
                              isLoggedInUserIsBookingCreator();
                            return (
                              <TableCell
                                key={index}
                                sx={{ padding: "10px", width: "10%" }}
                                className={
                                  !isHoliday
                                    ? isAvailableDate(dayDate)
                                      ? ``
                                      : `no-slot ${styles.tableBodyStyle}`
                                    : `holiday`
                                }
                                style={{
                                  backgroundColor:
                                    bookingByHr === true
                                      ? "#E3FAE3"
                                      : "#FFFFFF",
                                }}
                                align="left"
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <SlotAndBookingView
                                    daysDate={weekDaysDates[index]}
                                    key={dayDate}
                                    user={user}
                                    startDateOfWeek={startDateOfWeek}
                                    endDateOfWeek={endDateOfWeek}
                                    date={dayDate}
                                    slotDetails={user.slotdetails || []}
                                    rounds={user.rounds}
                                    booking={user.booking}
                                    isTodayHoliday={isHoliday}
                                    isCancelledDrive={isCancelledDrive}
                                    email={user.email}
                                    panelMemberAvailabilityPayload={
                                      panelMemberAvailabilityPayload
                                    }
                                    stateToogle={stateToogle}
                                    setStateToogle={setStateToogle}
                                    competencyId={competencyId}
                                    roundDesignationId={roundDesignationId}
                                    activePageNumber={activePageNumber}
                                    searchValue={searchValue}
                                    slotsProvided={slotsProvided}
                                    panelGroupId={panelGroupId}
                                    roundName={roundName}
                                    activedesignationId={activedesignationId}
                                    subCompetency={subCompetency}
                                    isWeekendDriveExist={isWeekendDriveExist}
                                    isPast={isPast}
                                    isDateOrPast={isDateOrPast}
                                  />
                                </Grid>
                              </TableCell>
                            );
                          })}
                          {getItems(user)}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Pagination
                  userCount={totalPanelSelectedCount}
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  activePageNumber={activePageNumber}
                />
              </TableContainer>

              {showAddCommentModal && (
                <AddCommentModal
                  showAddCommentModal={showAddCommentModal}
                  setShowAddCommentModal={setShowAddCommentModal}
                  selectedPanel={selectedPanel}
                  designationIds={roundDesignationId}
                  slotsProvided={slotsProvided}
                  roundName={roundName}
                  activedesignationId={activedesignationId}
                  searchValue={searchValue}
                  startWeekDate={startDateOfWeek}
                  endWeekDate={endDateOfWeek}
                  activePageNumber={activePageNumber}
                  panelGroupId={panelGroupId}
                  commentsForUniqueUser={commentsForUniqueUser}
                  commentsLoading={commentsLoading}
                  subCompetency={subCompetency}
                  user={loginInfo}
                />
              )}
              {showTempSuspendedModal && (
                <TemporarySuspendModal
                  competencyId={competencyId}
                  roundDesignationIds={roundDesignationId}
                  slotsProvided={slotsProvided}
                  roundName={roundName}
                  activedesignationId={activedesignationId}
                  query={searchValue}
                  setActivePageNumber={setActivePageNumber}
                  selectedPanel={selectedPanel}
                  moduleName={MODULE_NAME.AVAILABILITY}
                  weekFrom={weekFrom}
                  weekTo={weekTo}
                  show={showTempSuspendedModal}
                  setShow={setShowTempSuspendedModal}
                  memberListWithPanelData={userList}
                  panelGroupId={panelGroupId}
                  subCompetency={subCompetency}
                />
              )}
              {showTempUnavailableModal && (
                <TempUnavailableModal
                  show={showTempUnavailableModal}
                  setShow={setShowTempUnavailableModal}
                  selectedPanel={selectedPanel}
                  moduleName={MODULE_NAME.AVAILABILITY}
                  competencyId={competencyId}
                  roundDesignationIds={roundDesignationId}
                  slotsProvided={slotsProvided}
                  roundName={roundName}
                  activedesignationId={activedesignationId}
                  weekFrom={new Date(startDateOfWeek).toISOString()}
                  weekTo={new Date(endDateOfWeek).toISOString()}
                  query={searchValue}
                  setActivePageNumber={setActivePageNumber}
                  panelGroupId={panelGroupId}
                  subCompetency={subCompetency}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : totalPanelSelectedCount === "0" &&
        !userList?.length &&
        (!loading ||
          !weekendDriveLoading ||
          !activeDesignationLoading ||
          !roundsLoading) ? (
        <NoData />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default PanelMemberList;
