import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  configSaveDistributionList,
  configEditDistributionListById,
} from "../../../redux/features/Admin/adminSlice";

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import { ADMIN_DL_DRIVE_VALUES } from "../../../constants";

import styles from "./style.module.scss";

const CreateConfigDL = ({
  show,
  setShow,
  editDlEmailId,
  checkWeekType,
  setEditDlEmailId,
  loginInfo,
  setSelectedDlEmailId,
  setReportType,
  setSelectedOption,
  setCompetencyId,
  selectedDlEmailId,
  setSelectedUserEmailId,
  competencyIdFilter,
  DlEmail,
  DriveReportFilter,
  CompetencyFilter,
  UserEmail,
  competencyId,
  reportType,
  selectedUserEmailId,
  setErrUserEmail,
}) => {
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const [errorEmail, setErrEmail] = useState(false);
  const [errCompetency, setErrCompetency] = useState(false);

  const handleClose = () => {
    setShow(false);
    setEditDlEmailId(null);
    setValidated(false);
    setSelectedDlEmailId([]);
    setSelectedUserEmailId([]);
    setReportType(ADMIN_DL_DRIVE_VALUES.WEEKDAYS_BOOKINGS_REPORT);
    setCompetencyId("");
    setSelectedOption("");
    setErrCompetency(false);
    setErrEmail(false);
    setErrUserEmail(false);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    const form = e.currentTarget;
    const valuesArray = selectedDlEmailId;
    const emailUserArray = selectedUserEmailId;
    if (
      form.checkValidity() === true &&
      competencyId !== "" &&
      selectedUserEmailId.length
    ) {
      if (editDlEmailId === null) {
        dispatch(
          configSaveDistributionList({
            roleId: loginInfo.role_id,
            pageNumber: 1,
            ttnMailingListIds: valuesArray,
            reportType: reportType,
            competencyId: competencyId,
            checkWeekType: checkWeekType,
            competencyIdFilter: competencyIdFilter,
            userDetailsIds: emailUserArray,
            dispatch,
          })
        );
        setValidated(false);
        setSelectedDlEmailId([]);
        setSelectedUserEmailId([]);
        setReportType(ADMIN_DL_DRIVE_VALUES.WEEKDAYS_BOOKINGS_REPORT);
        setCompetencyId("");
        setSelectedOption("");
        setErrCompetency(false);
        setErrEmail(false);
        setErrUserEmail(false);
        handleClose();
      } else if (editDlEmailId !== null) {
        dispatch(
          configEditDistributionListById({
            roleId: loginInfo.role_id,
            pageNumber: 1,
            ttnMailingListIds: valuesArray,
            reportType: reportType,
            competencyId: competencyId,
            checkWeekType: checkWeekType,
            configId: editDlEmailId,
            competencyIdFilter: competencyIdFilter,
            userDetailsIds: emailUserArray,
            dispatch,
          })
        );
        setValidated(false);
        setSelectedDlEmailId([]);
        setSelectedUserEmailId([]);
        setReportType(ADMIN_DL_DRIVE_VALUES.WEEKDAYS_BOOKINGS_REPORT);
        setCompetencyId("");
        setSelectedOption("");
        setEditDlEmailId(null);
        setErrCompetency(false);
        setErrEmail(false);
        setErrUserEmail(false);
        handleClose();
      }
    } else {
      setErrCompetency(true);
      setErrEmail(true);
      setErrUserEmail(true);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "10px",
          boxShadow: "0px 6px 30px 0px #OD",
        },
      }}
    >
      <DialogTitle>
        <Grid container justifyContent={"space-between"}>
          <Typography variant="h6" gutterBottom pt={1} color={"#2e1c41"}>
            {editDlEmailId === null
              ? "Create Config Report"
              : "Edit Config Report"}
          </Typography>
          <IconButton onClick={handleClose}>
            <img src={CloseIcon} alt="close-icon" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ flexGrow: 1, overflowY: "hidden" }}>
          <Grid item xs={12} marginTop={1}>
            {DriveReportFilter()}
          </Grid>
          <Grid item xs={12}>
            {CompetencyFilter()}
          </Grid>
          <Grid item xs={12}>
            {DlEmail()}
          </Grid>
          <Grid item xs={12}>
            {UserEmail()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: "0px 24px 16px" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={!(reportType && selectedUserEmailId.length && competencyId)}
          onClick={(e) => onFormSubmit(e)}
          className={
            !(reportType && selectedUserEmailId.length && competencyId)
              ? `${styles.saveBtnDisable}`
              : `${styles.saveBtn}`
          }
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          {editDlEmailId === null ? "Save" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateConfigDL;
