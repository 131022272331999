import { isThisWeek } from "date-fns";

export const extractTime = (date) => {
  return new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const extractTimeWithoutAMPM = (date) => {
  const extractedTime = new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
  let [hours, minutes] = extractedTime.split(':');
  hours = parseInt(hours, 10);

  if (hours >= 13 && hours <= 23) {
    hours = hours % 12;
  }
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes}`;

  return formattedTime;
};

export const dateToString =(date)=>{
  return new Date(date).toLocaleTimeString('en-US', { hour12: false });
}

export const getNextSeventhDay = (inputDate) => {
  const date = inputDate ? new Date(inputDate) : new Date();
  date.setDate(date.getDate() + 7);
  return date;
};

export const validateDate = (date) => {
  const todayDate = new Date(new Date().setHours(0,0,0,0));
  return new Date(date) >= todayDate;
}

export const validateCurrentWeekAndDate = (date) => {
  const todayDate = new Date(new Date().setHours(0,0,0,0));
  return (isThisWeek((date), { weekStartsOn: 1 }) || new Date(date) >= todayDate);
}
