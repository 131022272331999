import React from "react";

import BookingOverlayTemplate from "../BookingOverlayTemplate";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import closeIcon from "../../../../assets/icons/close-icon.svg";

import theme from "../../../../theme";

const BookingDetailsModal = ({
  showBookingDetails,
  setShowBookingDetails,
  bookingInfo,
  user,
}) => {
  const handleClose = () => {
    setShowBookingDetails(false);
  };

  return (
    <>
      <Dialog
        open={showBookingDetails}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "28rem",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle
          color={theme.palette.secondary.main}
          sx={{ padding: "30px 24px 0px" }}
        >
          Booking Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <DialogContent>
          <BookingOverlayTemplate
            bookingSlot={{
              interview_status: bookingInfo.interview_status,
              interviewMode: bookingInfo.interviewMode,
              calendarEventId: bookingInfo.calendarEventId,
              bookingForRound: bookingInfo.bookingForRound,
              roundDesignationName: bookingInfo.roundDesignationName,
              pairedInterviewerId: bookingInfo.pairedInterviewerId,
              pairedInterviewerEmail: bookingInfo.pairedInterviewerEmail,
              startTime: bookingInfo.startTime,
              endTime: bookingInfo.endTime,
              candidateName: bookingInfo.candidateName,
              candidateMobileNumber: bookingInfo.candidateMobileNumber,
              candidateEmail: bookingInfo.candidateEmail,
              bookingAssignedBy: bookingInfo.bookingAssignedBy,
              slotDetailsOfTheDay: user.slotdetails,
              notes: bookingInfo.notes,
              isInterviewerDeleted: bookingInfo.isInterviewerDeleted,
              interviewer_status: bookingInfo.interviewer_status,
            }}
            user={user}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 30px" }}></DialogActions>
      </Dialog>
    </>
  );
};

export default BookingDetailsModal;
