import React, { useEffect } from "react";

import PanelistSlotsAndBookings from "./PanelistSlotsAndBookings";

import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Radio,
  FormControl,
  Divider,
  Typography,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { extractTime } from "../../../../utils/date-utils";
import {
  INTERVIEW_STATUS_CHECK,
  INTERVIEW_STATUS_OPTIONS_FOR_PAIRING_REMOVE,
  CREATE_PAIRING_OPTIONS,
} from "../../../../constants";

const PairingView = (props) => {
  const {
    isUpdateModalOpen,
    booking,
    isPairingRoundSelected,
    roundId,
    isRoundInPairingIntial,
    eligiblePanelistForPairing,
    isPairedValidationError,
    pairingUserId,
    setPairingState,
    isCreatePairingChecked,
    setIsCreatePairingChecked,
    setIsRoundInPairingIntial,
    setIsPairedValidationError,
    slots,
    notavailable,
    notprovided,
    pairedmemberData,
    filterUnpairedInterviewersBookingsList,
    bookingIdForPairedRound,
    setStatesWhenPairingSelected,
    resetPairingState,
    setPairedEmail,
    isRemovePairedInterviewerValidationError,
    setIsRemovePairedInterviewerValidationError,
    removePairingReason,
    setRemovePairingReason,
    interviewStatus,
    isDisableMultipleFields,
  } = props;

  useEffect(() => {
    if (isUpdateModalOpen && booking && booking?.isCoreInterviewer) {
      if (isCreatePairingChecked === CREATE_PAIRING_OPTIONS.YES) {
        setPairedEmail(pairedmemberData?.email ?? "");
        setPairingState(pairedmemberData?.user_id);
      }
      if (isCreatePairingChecked === CREATE_PAIRING_OPTIONS.NO) {
        setPairedEmail(booking?.pairedInterviewerEmail ?? "");
        setPairingState(booking?.pairedInterviewerId);
      }
      if (isCreatePairingChecked === CREATE_PAIRING_OPTIONS.REMOVE_PAIRING) {
        setPairedEmail("");
        resetPairingState(null);
      }
    }
  }, [isCreatePairingChecked, isUpdateModalOpen, pairedmemberData]);
  useEffect(() => {
    if (
      isCreatePairingChecked === CREATE_PAIRING_OPTIONS.REMOVE_PAIRING &&
      isRoundInPairingIntial === false &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED)
    ) {
      resetPairingState(null);
    }
  }, [
    booking,
    interviewStatus,
    isRoundInPairingIntial,
    resetPairingState,
    isCreatePairingChecked,
  ]);

  const options = eligiblePanelistForPairing?.map((option) => ({
    label: `${option.email} ${option.round_inpairing ? "(Pairing)" : ""}`,
    value: option.user_id,
    slotdetails:option.slotdetails
  }));
  const statusForList = (status) => {
    if (status === INTERVIEW_STATUS_CHECK.SCHEDULED) {
      return "Scheduled";
    }
    if (status === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED) {
      return "To Be Scheduled";
    }
  };
  const handleCreatePairing = (e) => {
    setIsCreatePairingChecked(e.target.value);
    if (e.target.value === CREATE_PAIRING_OPTIONS.YES)
      setIsRoundInPairingIntial(true);
    if (e.target.value === CREATE_PAIRING_OPTIONS.NO && !isUpdateModalOpen)
      setIsRoundInPairingIntial(false);
    if (e.target.value === CREATE_PAIRING_OPTIONS.NO && isUpdateModalOpen)
      setIsRoundInPairingIntial(true);
    if (e.target.value === CREATE_PAIRING_OPTIONS.REMOVE_PAIRING)
      setIsRoundInPairingIntial(false);
  };
  const handlePairingUserIdChange = (event, newValue) => {
    const newPairingUserId = newValue?.value;
    setPairingState(newValue?.value);

    // Validate and update state for pairingUserId
    const isPairedInvalid =
      isCreatePairingChecked === "Yes" && !newPairingUserId;
    setIsPairedValidationError(isPairedInvalid);
  };

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
  }));
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#DE1186",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#DE1186",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "#EEB0D3",
    },
  });

  function CustomizedRadios() {
    return (
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid item alignSelf={"center"}>
          <FormLabel id="demo-customized-radios">
            {" "}
            {!!booking?.pairedInterviewerId
              ? "Switch Paired Interviewer?"
              : "Create Pairing?"}
          </FormLabel>
        </Grid>
        <Grid item>
          <RadioGroup
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            row
            value={isCreatePairingChecked}
            onChange={(e) => {
              handleCreatePairing(e);
              isUpdateModalOpen && setRemovePairingReason("");
            }}
          >
            <FormControlLabel
              value={CREATE_PAIRING_OPTIONS.YES}
              control={<BpRadio />}
              label={CREATE_PAIRING_OPTIONS.YES}
            />
            <FormControlLabel
              value={CREATE_PAIRING_OPTIONS.NO}
              sx={{ margin: 0 }}
              control={<BpRadio />}
              label={CREATE_PAIRING_OPTIONS.NO}
            />
            {booking?.pairedInterviewerId && booking?.isCoreInterviewer && (
              <FormControlLabel
                value={CREATE_PAIRING_OPTIONS.REMOVE_PAIRING}
                sx={{ margin: 0 }}
                control={<BpRadio />}
                label={CREATE_PAIRING_OPTIONS.REMOVE_PAIRING}
              />
            )}
          </RadioGroup>
        </Grid>
      </FormControl>
    );
  }
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
        disabled={isDisableMultipleFields}
      />
    );
  }

  return (
    <>
      {((isUpdateModalOpen && booking?.isCoreInterviewer) ||
        !isUpdateModalOpen) &&
        !isDisableMultipleFields && (
          <>
            {!isPairingRoundSelected ? (
              roundId && (
                <Grid item xs={12}>
                  <Grid direction={"row"}>
                    <Grid item marginBottom={2}>
                      <Divider />
                    </Grid>
                    <Grid item>{CustomizedRadios()}</Grid>
                    {isRoundInPairingIntial && (
                      <Grid item marginY={2}>
                        {isRoundInPairingIntial &&
                          isCreatePairingChecked ===
                            CREATE_PAIRING_OPTIONS.YES && (
                            <>
                              {eligiblePanelistForPairing?.length ? (
                                <FormControl
                                  fullWidth
                                  error={isPairedValidationError}
                                >
                                  <Autocomplete
                                    id="paired-interviewer"
                                    disableClearable
                                    disablePortal
                                    fullWidth
                                    options={options}
                                    value={eligiblePanelistForPairing?.reduce(
                                      (acc, user) =>
                                        user.user_id === pairingUserId
                                          ? {
                                              label: `${user.email} ${
                                                user.round_inpairing
                                                  ? "(Pairing)"
                                                  : ""
                                              }`,
                                              value: user.user_id,
                                            }
                                          : acc,
                                      null
                                    )}
                                    onChange={handlePairingUserIdChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Paired Interviewer"
                                        placeholder="Paired Interviewer"
                                        disabled={!isRoundInPairingIntial}
                                        required
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        style={{
                                          ...props.style,
                                          backgroundColor: option
                                            .slotdetails?.[0]?.isAvailable
                                            ? "#E0F7CC"
                                            : "default",
                                        }}
                                      >
                                        {option.label}
                                      </li>
                                    )}
                                    style={{
                                      display: isRoundInPairingIntial
                                        ? "block"
                                        : "none",
                                    }}
                                    ListboxProps={{
                                      style: {
                                        maxHeight: 150,
                                        overflow: "auto",
                                      },
                                    }}
                                  />
                                  {isPairedValidationError && (
                                    <FormHelperText>
                                      Paired Interviewer is Required
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              ) : (
                                <Grid container>
                                  <Grid item xs={6}>
                                    <Typography variant="body1">
                                      Paired Interviewer :
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} textAlign={"end"}>
                                    <Typography variant="body1" color="red">
                                      No Data Found
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                        {pairingUserId &&
                          isCreatePairingChecked ===
                            CREATE_PAIRING_OPTIONS.NO && (
                            <>
                              <FormControl
                                fullWidth
                                error={isPairedValidationError}
                              >
                                <InputLabel id="paired-interviewer">
                                  Paired Interviewer
                                </InputLabel>
                                <Select
                                  labelId="paired-interviewer"
                                  label={"Paired Interviewer"}
                                  id="paired-interviewer"
                                  placeholder="Paired Interviewer"
                                  value={pairingUserId}
                                  disabled={true}
                                >
                                  {eligiblePanelistForPairing &&
                                    eligiblePanelistForPairing.map((item) => (
                                      <MenuItem
                                        key={item.user_id}
                                        value={item.user_id}
                                      >
                                        {item.email}{" "}
                                        {item.round_inpairing === true
                                          ? "(Pairing)"
                                          : " "}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </>
                          )}

                        {isRoundInPairingIntial && pairingUserId && (
                          <Grid container direction={"row"} marginY={2} gap={1}>
                            <PanelistSlotsAndBookings
                              slots={slots}
                              notavailable={notavailable}
                              notprovided={notprovided}
                              pairedmemberData={pairedmemberData}
                            />
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {(isCreatePairingChecked ===
                      CREATE_PAIRING_OPTIONS.REMOVE_PAIRING ||
                      (isUpdateModalOpen &&
                        booking?.isCoreInterviewer &&
                        booking?.pairedInterviewerId &&
                        booking?.pairedInterviewerId !== pairingUserId &&
                        pairingUserId !== null)) && (
                      <Grid item marginY={2}>
                        <FormControl
                          fullWidth
                          error={isRemovePairedInterviewerValidationError}
                        >
                          <InputLabel id="removing-paired-interviewer-reason">
                            Reason For Removing Paired Interviewer*
                          </InputLabel>
                          <Select
                            labelId="removing-paired-interviewer-reason"
                            label={"Reason For Removing Paired Interviewer"}
                            id="removing-paired-interviewer-reason"
                            placeholder="Reason For Removing Paired Interviewer"
                            value={removePairingReason}
                            onChange={(e) => {
                              setRemovePairingReason(e.target.value);
                              setIsRemovePairedInterviewerValidationError(
                                !e.target.value
                              );
                            }}
                            required
                          >
                            {Object.values(
                              INTERVIEW_STATUS_OPTIONS_FOR_PAIRING_REMOVE
                            ).map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {isRemovePairedInterviewerValidationError && (
                            <FormHelperText>Reason is Required</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item marginTop={2}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              )
            ) : (
              <>
                <Grid item xs={12}>
                  <Grid direction={"row"}>
                    <Grid item marginBottom={2}>
                      <Divider />
                    </Grid>
                    <Grid item marginY={2}>
                      {filterUnpairedInterviewersBookingsList?.length ? (
                        <FormControl fullWidth>
                          <InputLabel id="existing-bookings">
                            Existing Bookings*
                          </InputLabel>
                          <Select
                            labelId="existing-bookings"
                            label={"Existing Bookings"}
                            id="existing-bookings"
                            placeholder="Existing Bookings"
                            value={bookingIdForPairedRound}
                            required
                            onChange={(event) => {
                              setStatesWhenPairingSelected(event);
                            }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          >
                            {filterUnpairedInterviewersBookingsList &&
                              filterUnpairedInterviewersBookingsList.map(
                                (item) => (
                                  <MenuItem
                                    key={item.booking_id}
                                    value={item.booking_id}
                                  >
                                    {item.email}
                                    {" ("}
                                    {extractTime(item.start_time)}
                                    {"-"}
                                    {extractTime(item.end_time)}
                                    {")  ("}{" "}
                                    {statusForList(item.booking_status)}
                                    {" )"}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      ) : (
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              Existing Bookings :
                            </Typography>
                          </Grid>
                          <Grid item xs={6} textAlign={"end"}>
                            <Typography variant="body1" color="red">
                              No Data Found
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item marginTop={2}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
    </>
  );
};

export default PairingView;
