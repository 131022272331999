import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { IOSSwitch } from "../../../../components/Switch/IoSSwitch.js";

import {
  setSlotTime,
  updateSlotTime,
  deleteSlotTime,
  setNotAvailableReason,
  clearAvailableReason,
} from "../../../../redux/features/Availability/availabilitySlice";

import {
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AddIcon from "@mui/icons-material/Add";

import Icon from "../../../../components/SvgIcon/icon.js";
import DeleteIcon from "../../../../assets/icons/bin-icon.svg";

import dayjs from "dayjs";

import themes from "../../../../theme/index.js";
import styles from "./style.module.scss";

const DaySlotTimeView = ({ key, dayName, state, index }) => {
  const dispatch = useDispatch();

  const [fields, setFields] = useState([{}]);
  const [isAvailable, setIsAvailable] = useState(true);

  const onSetSlotTime = ({ startTime, endTime, validationId }) => {
    let slotExist = null;
    state.days?.forEach((data) => {
      if (data.day === dayName) {
        data.slotTime?.forEach((slot) => {
          if (slot.validationId === validationId) {
            slotExist = slot;
          }
        });
      }
    });
    if (slotExist) {
      dispatch(
        updateSlotTime({
          slot: {
            validationId,
            startTime: startTime ? startTime : slotExist.startTime,
            endTime: endTime ? endTime : slotExist.endTime,
          },
          dayName,
        })
      );
    } else {
      dispatch(
        setSlotTime({
          slot: { validationId, startTime, endTime },
          dayName,
        })
      );
    }
  };
  const onSetAvailable = (value) => {
    if (!fields.length) setFields([{}]);
    setIsAvailable(value);
    if (!value) {
      dispatch(clearAvailableReason({ slot: [], dayName }));
    } else {
      dispatch(
        setSlotTime({
          slot: {
            validationId: 1,
            startTime: new Date().setHours(10, 0, 0, 0),
            endTime: new Date().setHours(17, 0, 0, 0),
          },
          dayName,
        })
      );
    }
  };
  const onRemoveSlot = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(() => newFields);
    dispatch(deleteSlotTime({ validationId: index + 1, dayName }));
    if (!newFields.length) setIsAvailable(false);
  };

  return (
    <Grid container padding={1} className={styles.daySlotWrap}>
      <Grid container spacing={0.5} paddingRight={3} marginTop={0.1}>
        <Grid item lg={8}>
          <Typography className={styles.weekdaysText}>{dayName}</Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          lg={4}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid item lg={3}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={isAvailable}
                  onChange={(e) => onSetAvailable(e.target.checked)}
                />
              }
              labelPlacement="end"
            />
          </Grid>
          <Grid item lg={3}>
            <Typography className={styles.availableText}> Available</Typography>
          </Grid>
        </Grid>
      </Grid>
      {isAvailable &&
        fields?.map((f, index) => {
          let daySlot = [];
          state.days?.forEach((item) => {
            if (item.day === dayName) {
              daySlot = item?.slotTime[index];
            }
          });
          return (
            <Grid item key={index}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                paddingRight={2}
                marginTop={0.1}
              >
                <Grid item md={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      closeOnSelect={false}
                      minutesStep={15}
                      views={["hours", "minutes"]}
                      value={
                        daySlot && daySlot[index]
                          ? dayjs(daySlot[index]?.startTime)
                          : dayjs(new Date().setHours(10, 0, 0, 0))
                      }
                      onChange={(newValue) => {
                        onSetSlotTime({
                          startTime: new Date(newValue)?.getTime(),
                          endTime: null,
                          validationId: index + 1,
                        });
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "33px",
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#000000",
                        },
                      }}
                      className={styles.timeInput}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      minutesStep={15}
                      closeOnSelect={false}
                      value={
                        daySlot && daySlot[index]
                          ? dayjs(daySlot[index]?.endTime)
                          : dayjs(new Date().setHours(17, 0, 0, 0))
                      }
                      onChange={(newValue) => {
                        onSetSlotTime({
                          startTime: null,
                          endTime: new Date(newValue)?.getTime(),
                          validationId: index + 1,
                        });
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "33px",
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#000000",
                        },
                      }}
                      className={styles.timeInput}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={1}>
                  <IconButton onClick={() => onRemoveSlot(index)}>
                    <Icon icon={DeleteIcon} width="16.5px" height="16.77px" />
                  </IconButton>
                </Grid>
                <Grid item md={1}>
                  <IconButton onClick={() => setFields([...fields, {}])}>
                    <AddIcon
                      cursor="pointer"
                      right
                      sx={{
                        color: themes.palette.primary.main,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      {!isAvailable && (
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            marginBottom={1}
            marginTop={1}
          >
            <Typography className={styles.weekdaysText}>Reason</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <TextField
              size="small"
              placeholder="Not Available! Specify the reason"
              label="Not Available! Specify the reason"
              className={styles.timeInput}
              onChange={(e) =>
                dispatch(
                  setNotAvailableReason({ comments: e.target.value, dayName })
                )
              }
              required
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DaySlotTimeView;
