import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TagList from "./TagList";
import AddNewTag from "./AddNewTag";
import NoData from "../../NoData/index";
import Spinner from "./../../../../components/Spinner";

import { getTags } from "../../../../redux/features/Tags/tagsSlice";

import { ROLE_TYPES } from "../../../../constants";

const { isSpoc } = ROLE_TYPES;

const Index = ({
  activePageNumber,
  setActivePageNumber,
  showAddTagModal,
  setShowAddTagModal,
}) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const { loading, tagsData } = useSelector((state) => state?.tagReducer);
  const tagsList = tagsData?.list;
  const tagPageCount = tagsData?.tagPageCount;
  const totalTagsCount = tagsData?.totalTagsCount;

  useEffect(() => {
    dispatch(
      getTags({
        roleId: loginInfo.role_id,
        competencyId: loginInfo.current_role_competency_id,
        pageNumber: activePageNumber,
      })
    );
  }, [
    activePageNumber,
    dispatch,
    loginInfo.current_role_competency_id,
    loginInfo.role_id,
  ]);

  if (loading) return <Spinner />;

  return (
    <>
      <AddNewTag
        activePageNumber={activePageNumber}
        setActivePageNumber={setActivePageNumber}
        showAddTagModal={showAddTagModal}
        setShowAddTagModal={setShowAddTagModal}
      />
      {tagsList?.length ? (
        <TagList
          tagsList={tagsList}
          tagPageCount={tagPageCount}
          totalTagsCount={totalTagsCount}
          activePageNumber={activePageNumber}
          setActivePageNumber={setActivePageNumber}
        />
      ) : (
        !loading && (
          <NoData />
        )
      )}
    </>
  );
};

export default Index;
