import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import EventDetailsStep from "./EventDetailsStep";
import CustomizedStepper from "./CustomizedStepper";
import InterviewDetailsStep from "./InterviewDetailsStep";
import CandidateDetailsStep from "./CandidateDetailsStep";
import InterviewStatusNaOrRsScreenPopup from "./InterviewStatusNaOrRsScreenPopup";

import { fetchWeekendDriveDetailsByCompetency } from "../../../../redux/features/WeekendDrive/weekendDriveSlice";

import dayjs from "dayjs";
import format from "date-fns/format";

import {
  CREATE_PAIRING_OPTIONS,
  INTERVIEW_STATUS_CHECK,
  SORT_ORDER,
  SORT_TYPE_CHECK_VALUE,
} from "../../../../constants";

const UpdateBookingModal = (props) => {
  const {
    isUpdateModalOpen,
    showInterviewStatusNaOrRsPopup,
    isNaOrRsStatusPopupYesClicked,
    isNaOrRsStatusPopupNoClicked,
    isNaOrRsStatusPopupCancelClicked,
    isNaOrRsStatusPopupContinueClicked,
    booking,
    user,
    rounds,
    roundId,
    setRoundId,
    interviewStatus,
    setInterviewStatus,
    interviewMode,
    setInterviewMode,
    comments,
    setComments,
    defaultCountry,
    setDefaultCountry,
    countryCodeEditable,
    setCountryCodeEditable,
    createEvent,
    setCreateEvent,
    calendarEventTitle,
    setCalendarEventTitle,
    eventDescription,
    setEventDescription,
    intervieweName,
    setIntervieweName,
    bookingId,
    weekDate,
    bookingDate,
    setBookingDate,
    onSetBookingDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    onSetStartTime,
    isPairingRoundSelected,
    isRoundInPairingIntial,
    setIsRoundInPairingIntial,
    isRoundInPairingFinal,
    setIsRoundInPairingFinal,
    eligiblePairingList,
    pairingUserId,
    leaderUserId,
    setPairingState,
    setPairingUserId,
    setPairingCompetencyId,
    setPairingDesignationId,
    setPairedEmail,
    slots,
    notavailable,
    notprovided,
    pairedmemberData,
    selectedEmail,
    setSelectedEmail,
    fileName,
    setFileName,
    intervieweEmail,
    setIntervieweEmail,
    setLeaderState,
    mobileNumber,
    setMobileNumber,
    mobileNumberRef,
    mobileNumberErrorMessage,
    setMobileNumberErrorMessage,
    handleDisableCandidateMobileNumber,
    setHandleDisableCandidateMobileNumber,
    cvLink,
    setCvLink,
    activeStep,
    steps,
    resetPairingState,
    resetLeaderState,
    setBookingIdForPairedRound,
    bookingIdForPairedRound,
    interviewStatusChange,
    unpairedInterviewersBookingsList,
    isRoundValidationError,
    setIsRoundValidationError,
    isPairedValidationError,
    setIsPairedValidationError,
    isCreatePairingChecked,
    setIsCreatePairingChecked,
    handleRequiredCandidateName,
    handleRequiredCandidateEmail,
    setHandleRequiredCandidateName,
    setHandleRequiredCandidateEmail,
    validateStep1,
    handleRequiredCalendaEventTitle,
    setHandleRequiredCalendarEventTitle,
    isRemovePairedInterviewerValidationError,
    removePairingReason,
    setRemovePairingReason,
    setIsRemovePairedInterviewerValidationError,
    setLeaderEmail,
    setinterviewerUnavailableReason,
    setPairedInterviewerUnavailableReason,
    pairedInterviewerUnavailableReason,
    deleteEventOnBooking,
    setDeleteEventOnBooking,
    disableFieldsForPairedView,
    setDisableFieldsForPairedView,
    isDisableMultipleFields,
    setIsDisableMultipleFields,
    changedToScheduled
  } = props;
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);

  const [updatedInterviewerId, setUpdatedInterviewerId] = useState(null);

  const updateWithNoBooking = useCallback(() => {
    if (
      booking &&
      !booking.pairedInterviewerId &&
      booking.isCoreInterviewer &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
        INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED === interviewStatus)
    )
      return true;
    else return false;
  }, [booking, interviewStatus]);
  const updateWithLeaderView = useCallback(() => {
    if (
      booking &&
      booking.isCoreInterviewer &&
      booking.pairedInterviewerId &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
        INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED === interviewStatus)
    ) {
      return true;
    } else {
      return false;
    }
  }, [booking, interviewStatus]);
  const updatePairedViewBooking = useCallback(() => {
    if (
      booking &&
      booking.pairedInterviewerId &&
      !booking.isCoreInterviewer &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
        INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED === interviewStatus)
    )
      return true;
    else return false;
  }, [booking, interviewStatus]);

  const eligiblePanelistForPairing = eligiblePairingList?.filter(
    (item) => item.user_id !== user.id
  );
  const filterUnpairedInterviewersBookingsList =
    unpairedInterviewersBookingsList &&
    unpairedInterviewersBookingsList.filter((item) => item.user_id !== user.id);

  useEffect(() => {
    if (booking) {
      setStartTime(dayjs(new Date(booking.startTime)));
      setEndTime(dayjs(new Date(booking.endTime)));
      rounds.forEach((round) => {
        if (
          round.roundName === booking.bookingForRound &&
          round.designationId === booking.roundDesignationId
        ) {
          setRoundId(parseInt(round.id));
        }
      });
      setComments(booking.notes);
      setInterviewStatus(booking.interview_status);
    }
  }, [booking, rounds]);
  useEffect(() => {
    setIsDisableMultipleFields(false);
    if (booking) {
      if (
        !isNaOrRsStatusPopupContinueClicked &&
        (interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
          interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
          interviewStatus === INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED)
      ) {
        setIsDisableMultipleFields(true);
      }
      if (
        interviewStatus === INTERVIEW_STATUS_CHECK.INVALID ||
        interviewStatus === INTERVIEW_STATUS_CHECK.COMPLETED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.CANDIDATE_NOT_AVAILABLE
      ) {
        setIsDisableMultipleFields(true);
      }
    }
  }, [
    booking,
    interviewStatus,
    isNaOrRsStatusPopupContinueClicked,
    setIsDisableMultipleFields,
  ]);
  useEffect(() => {
    if (
      booking &&
      booking.isCoreInterviewer === true &&
      booking.pairedInterviewerId !== null &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED)
    ) {
      setPairingState(parseInt(booking.pairedInterviewerId));
      setIsRoundInPairingFinal(true);
      setIsRoundInPairingIntial(true);
    }
  }, []);
  useEffect(() => {
    if (
      booking &&
      isUpdateModalOpen &&
      booking.pairedInterviewerId === null &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED)
    ) {
      setLeaderState(user.id);
    }
  }, [booking, interviewStatus, isUpdateModalOpen, setLeaderState, user.id]);
  useEffect(() => {
    if (
      booking &&
      booking.isCoreInterviewer === true &&
      booking.pairedInterviewerId !== null &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED) &&
      isRoundInPairingFinal
    ) {
      setPairingState(parseInt(booking.pairedInterviewerId));
    }
    if (
      booking &&
      booking.isCoreInterviewer === false &&
      booking.pairedInterviewerId &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED)
    ) {
      setLeaderState(parseInt(booking.pairedInterviewerId));
      setIsRoundInPairingFinal(true);
      setDisableFieldsForPairedView(true);
    }
  }, [
    booking,
    interviewStatus,
    isRoundInPairingFinal,
    setDisableFieldsForPairedView,
    setIsRoundInPairingFinal,
    setLeaderState,
    setPairingState,
  ]);
  useEffect(() => {
    if (booking && booking.pairedInterviewerId && !booking.isCoreInterviewer) {
      setPairingState(parseInt(user.id));
    }
  }, [booking, setPairingState, user.id]);
  useEffect(() => {
    if (
      booking &&
      booking.isCoreInterviewer === true &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED) &&
      booking.pairedInterviewerId
    ) {
      setLeaderState(user.id);
    }
  }, [booking, interviewStatus, setLeaderState, user.id]);
  useEffect(() => {
    if (
      updateWithNoBooking() &&
      isNaOrRsStatusPopupNoClicked &&
      !isNaOrRsStatusPopupYesClicked &&
      !isNaOrRsStatusPopupContinueClicked
    ) {
      setIsRoundInPairingFinal(false);
      resetPairingState(null);
      resetLeaderState(null);
      setLeaderEmail("");
    }
    if (
      updateWithNoBooking() &&
      isNaOrRsStatusPopupYesClicked &&
      updatedInterviewerId &&
      isNaOrRsStatusPopupContinueClicked
    ) {
      setIsRoundInPairingFinal(false);
      setLeaderState(updatedInterviewerId);
      resetPairingState(null);
    }
  }, [
    isNaOrRsStatusPopupContinueClicked,
    isNaOrRsStatusPopupNoClicked,
    isNaOrRsStatusPopupYesClicked,
    resetLeaderState,
    resetPairingState,
    setIsRoundInPairingFinal,
    setLeaderEmail,
    setLeaderState,
    updateWithNoBooking,
    updatedInterviewerId,
  ]);
  useEffect(() => {
    if (
      updateWithLeaderView() &&
      isNaOrRsStatusPopupYesClicked &&
      !isNaOrRsStatusPopupNoClicked &&
      isNaOrRsStatusPopupContinueClicked &&
      updatedInterviewerId
    ) {
      setLeaderState(updatedInterviewerId);
      if (parseInt(pairingUserId) !== parseInt(user.id)) {
        setPairingState(parseInt(pairingUserId));
      }
      if (parseInt(pairingUserId) === parseInt(user.id)) {
        setPairingState(parseInt(booking.pairedInterviewerId));
      }
    }
    if (
      updateWithLeaderView() &&
      !isNaOrRsStatusPopupYesClicked &&
      isNaOrRsStatusPopupNoClicked &&
      !isNaOrRsStatusPopupContinueClicked
    ) {
      setLeaderEmail("");
      setPairedEmail("");
    }
  }, [
    booking.pairedInterviewerId,
    isNaOrRsStatusPopupContinueClicked,
    isNaOrRsStatusPopupNoClicked,
    isNaOrRsStatusPopupYesClicked,
    pairingUserId,
    setLeaderEmail,
    setLeaderState,
    setPairedEmail,
    setPairingState,
    updateWithLeaderView,
    updatedInterviewerId,
    user.id,
  ]);
  useEffect(() => {
    if (
      updatePairedViewBooking() &&
      isNaOrRsStatusPopupNoClicked &&
      !isNaOrRsStatusPopupYesClicked &&
      !isNaOrRsStatusPopupContinueClicked
    ) {
      setIsRoundInPairingFinal(false);
      resetPairingState(null);
      setPairedEmail("");
    }
    if (
      updatePairedViewBooking() &&
      !isNaOrRsStatusPopupNoClicked &&
      isNaOrRsStatusPopupYesClicked &&
      isNaOrRsStatusPopupContinueClicked
    ) {
      setIsRoundInPairingFinal(true);
      setPairingState(updatedInterviewerId);
    }
  }, [
    isNaOrRsStatusPopupContinueClicked,
    isNaOrRsStatusPopupNoClicked,
    isNaOrRsStatusPopupYesClicked,
    resetPairingState,
    setIsRoundInPairingFinal,
    setPairedEmail,
    setPairingState,
    updatePairedViewBooking,
    updatedInterviewerId,
  ]);
  useEffect(() => {
    if (
      (isCreatePairingChecked === CREATE_PAIRING_OPTIONS.REMOVE_PAIRING &&
        removePairingReason) ||
      (isUpdateModalOpen &&
        booking?.isCoreInterviewer &&
        booking?.pairedInterviewerId &&
        booking?.pairedInterviewerId !== pairingUserId &&
        pairingUserId !== null)
    ) {
      setPairedInterviewerUnavailableReason(removePairingReason);
    }
  }, [
    booking?.isCoreInterviewer,
    booking?.pairedInterviewerId,
    isCreatePairingChecked,
    isUpdateModalOpen,
    pairingUserId,
    removePairingReason,
    setPairedInterviewerUnavailableReason,
  ]);
  useEffect(() => {
    if (
      booking &&
      booking.pairedInterviewerId !== null &&
      booking.leader &&
      parseInt(pairingUserId) !== booking.pairedInterviewerId &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED)
    ) {
      setPairedInterviewerUnavailableReason(pairedInterviewerUnavailableReason);
    }
  }, [
    booking,
    interviewStatus,
    pairedInterviewerUnavailableReason,
    pairingUserId,
    setPairedInterviewerUnavailableReason,
  ]);
  useEffect(() => {
    //new for setting pairedInterviewerunavailable reason when leader view Interviewer na both leader and interviwer is chnaged
    if (
      booking &&
      booking.isCoreInterviewer &&
      booking.pairedInterviewerId &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
        INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED === interviewStatus)
    ) {
      if (parseInt(pairingUserId) !== parseInt(user.id))
        setPairedInterviewerUnavailableReason(interviewStatus);
    }
  }, [
    booking,
    interviewStatus,
    pairingUserId,
    pairedInterviewerUnavailableReason,
    user.id,
  ]);
  useEffect(() => {
    if (
      interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
      interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
      interviewStatus === INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED
    ) {
      setinterviewerUnavailableReason(interviewStatus);
      setPairedInterviewerUnavailableReason("");
    }
  }, [interviewStatus]);
  useEffect(() => {
    if (
      booking &&
      booking.isCoreInterviewer === false &&
      booking.pairedInterviewerId &&
      (interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
        interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
        INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED === interviewStatus) &&
      updatedInterviewerId
    ) {
      setPairedInterviewerUnavailableReason(interviewStatus);
    }
  }, [booking, interviewStatus, updatedInterviewerId]);
  useEffect(() => {
    if (
      booking &&
      !booking.isCoreInterviewer &&
      booking.pairedInterviewerId &&
      interviewStatus === INTERVIEW_STATUS_CHECK.INVALID
    ) {
      resetPairingState(null);
      setPairedEmail("");
      setPairedInterviewerUnavailableReason(interviewStatus);
    }
  }, [interviewStatus]);
  useEffect(() => {
    if (
      interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ||
      interviewStatus === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
      interviewStatus === INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED
    ) {
      dispatch(
        fetchWeekendDriveDetailsByCompetency({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
          driveStatus: INTERVIEW_STATUS_CHECK.SCHEDULED,
          isPaginated: false,
          designationIds: [],
          periodFrom: format(
            new Date().setMonth(new Date().getMonth() - 6),
            "yyyy-MM-dd"
          ),
          periodTo: format(
            new Date().setMonth(new Date().getMonth() + 6),
            "yyyy-MM-dd"
          ),
          sortType: SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE,
          sortOrder: SORT_ORDER.DESCENDING,
        })
      );
    }
  }, [
    dispatch,
    interviewStatus,
    loginInfo.current_role_competency_id,
    loginInfo.role_id,
  ]);

  return (
    <>
      {showInterviewStatusNaOrRsPopup ? (
        <>
          <InterviewStatusNaOrRsScreenPopup
            user={user}
            interviewStatus={interviewStatus}
            eligiblePairingList={eligiblePairingList}
            showInterviewStatusNaOrRsPopup={showInterviewStatusNaOrRsPopup}
            isNaOrRsStatusPopupYesClicked={isNaOrRsStatusPopupYesClicked}
            bookingDate={bookingDate}
            onSetBookingDate={onSetBookingDate}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            onSetStartTime={onSetStartTime}
            updatedInterviewerId={updatedInterviewerId}
            setUpdatedInterviewerId={setUpdatedInterviewerId}
            bookingData={booking}
            pairingUserId={pairingUserId}
            leaderUserId={leaderUserId}
            setPairingState={setPairingState}
          />
        </>
      ) : (
        <>
          <CustomizedStepper activeStep={activeStep} steps={steps} />
          {activeStep === 0 && (
            <InterviewDetailsStep
              isUpdateModalOpen={isUpdateModalOpen}
              booking={booking}
              roundId={roundId}
              setRoundId={setRoundId}
              rounds={rounds}
              interviewStatus={interviewStatus}
              setInterviewStatus={setInterviewStatus}
              interviewMode={interviewMode}
              setInterviewMode={setInterviewMode}
              comments={comments}
              setComments={setComments}
              isPairingRoundSelected={isPairingRoundSelected}
              weekDate={weekDate}
              bookingDate={bookingDate}
              onSetBookingDate={onSetBookingDate}
              setBookingDate={setBookingDate}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              onSetStartTime={onSetStartTime}
              isCreatePairingChecked={isCreatePairingChecked}
              setIsCreatePairingChecked={setIsCreatePairingChecked}
              isRoundInPairingIntial={isRoundInPairingIntial}
              setIsRoundInPairingIntial={setIsRoundInPairingIntial}
              isRoundInPairingFinal={isRoundInPairingFinal}
              setIsRoundInPairingFinal={setIsRoundInPairingFinal}
              eligiblePanelistForPairing={eligiblePanelistForPairing}
              pairingUserId={pairingUserId}
              setPairingState={setPairingState}
              slots={slots}
              notavailable={notavailable}
              notprovided={notprovided}
              pairedmemberData={pairedmemberData}
              resetPairingState={resetPairingState}
              resetLeaderState={resetLeaderState}
              bookingIdForPairedRound={bookingIdForPairedRound}
              setBookingIdForPairedRound={setBookingIdForPairedRound}
              interviewStatusChange={interviewStatusChange}
              filterUnpairedInterviewersBookingsList={
                filterUnpairedInterviewersBookingsList
              }
              isRoundValidationError={isRoundValidationError}
              setIsRoundValidationError={setIsRoundValidationError}
              isPairedValidationError={isPairedValidationError}
              setIsPairedValidationError={setIsPairedValidationError}
              validateStep1={validateStep1}
              setPairedEmail={setPairedEmail}
              isRemovePairedInterviewerValidationError={
                isRemovePairedInterviewerValidationError
              }
              setIsRemovePairedInterviewerValidationError={
                setIsRemovePairedInterviewerValidationError
              }
              removePairingReason={removePairingReason}
              setRemovePairingReason={setRemovePairingReason}
              isNaOrRsStatusPopupNoClicked={isNaOrRsStatusPopupNoClicked}
              disableFieldsForPairedView={disableFieldsForPairedView}
              isDisableMultipleFields={isDisableMultipleFields}
            />
          )}
          {activeStep === 1 && (
            <CandidateDetailsStep
              changedToScheduled={changedToScheduled}
              booking={booking}
              interviewStatus={interviewStatus}
              defaultCountry={defaultCountry}
              setDefaultCountry={setDefaultCountry}
              countryCodeEditable={countryCodeEditable}
              setCountryCodeEditable={setCountryCodeEditable}
              mobileNumberRef={mobileNumberRef}
              mobileNumberErrorMessage={mobileNumberErrorMessage}
              setMobileNumberErrorMessage={setMobileNumberErrorMessage}
              intervieweName={intervieweName}
              setIntervieweName={setIntervieweName}
              isPairingRoundSelected={isPairingRoundSelected}
              selectedEmail={selectedEmail}
              setSelectedEmail={setSelectedEmail}
              fileName={fileName}
              setFileName={setFileName}
              intervieweEmail={intervieweEmail}
              setIntervieweEmail={setIntervieweEmail}
              mobileNumber={mobileNumber}
              setMobileNumber={setMobileNumber}
              cvLink={cvLink}
              setCvLink={setCvLink}
              handleDisableCandidateMobileNumber={
                handleDisableCandidateMobileNumber
              }
              setHandleDisableCandidateMobileNumber={
                setHandleDisableCandidateMobileNumber
              }
              handleRequiredCandidateName={handleRequiredCandidateName}
              handleRequiredCandidateEmail={handleRequiredCandidateEmail}
              setHandleRequiredCandidateName={setHandleRequiredCandidateName}
              setHandleRequiredCandidateEmail={setHandleRequiredCandidateEmail}
              disableFieldsForPairedView={disableFieldsForPairedView}
              isDisableMultipleFields={isDisableMultipleFields}
              isUpdateModalOpen={isUpdateModalOpen}
            />
          )}
          {activeStep === 2 && (
            <EventDetailsStep
              booking={booking}
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              calendarEventTitle={calendarEventTitle}
              setCalendarEventTitle={setCalendarEventTitle}
              eventDescription={eventDescription}
              setEventDescription={setEventDescription}
              interviewMode={interviewMode}
              setInterviewMode={setInterviewMode}
              intervieweName={intervieweName}
              intervieweEmail={intervieweEmail}
              handleRequiredCalendaEventTitle={handleRequiredCalendaEventTitle}
              setHandleRequiredCalendarEventTitle={
                setHandleRequiredCalendarEventTitle
              }
              deleteEventOnBooking={deleteEventOnBooking}
              setDeleteEventOnBooking={setDeleteEventOnBooking}
              isNaOrRsStatusPopupNoClicked={isNaOrRsStatusPopupNoClicked}
              interviewStatus={interviewStatus}
              disableFieldsForPairedView={disableFieldsForPairedView}
              isDisableMultipleFields={isDisableMultipleFields}
            />
          )}
        </>
      )}
    </>
  );
};

export default UpdateBookingModal;
