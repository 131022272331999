import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../utils/app-utils";
import { BASE_URL } from "../../../constants/index";
import { fetchActivePanelByCompetency } from "../Roster/rosterSlice";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";

const initialState = {
  loading: false,
  list: [],
  panelLoading: false,
};
export const getpanelGroupList = createAsyncThunk(
  "getpanelgroupList",
  async ({ roleId, competencyId }) => {
    try {
      const url = `${BASE_URL}/panelGroup/getAllTag`;
      const response = await get(url, {
        queryParams: { role_id: roleId, competencyId },
      });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const createPanelGroupMapping = createAsyncThunk(
  "createPanelGroupMapping",
  async ({
    panelCompetencyId,
    roleId,
    pageNumber,
    query,
    userPanelMappingId,
    groupIds,
    panelGroupId,
    designationIds,
    roundName,
    sortType,
    sortOrder,
    activedesignationId,
    dateOfJoining,
    subCompetency,
    signal,
    dispatch,
  }) => {
    try {
      const url = `${BASE_URL}/panelGroup/panelGroupMapping`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { userPanelMappingId, groupIds }
      );
      dispatch(
        fetchActivePanelByCompetency({
          competencyId: panelCompetencyId,
          roleId,
          pageNumber,
          query,
          roundName,
          panelGroupId,
          designationIds,
          sortType,
          sortOrder,
          activedesignationId,
          joiningDate: dateOfJoining,
          subCompetency,
          signal: signal,
        })
      );
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
const panelGroupSlice = createSlice({
  name: "Panel Group",
  initialState,
  reducers: {
    resetPanelGroupState() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getpanelGroupList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getpanelGroupList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload?.allTagByCompetency;
      })
      .addCase(getpanelGroupList.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to get tags list."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(createPanelGroupMapping.pending, (state) => {
        state.panelLoading = true;
      })
      .addCase(createPanelGroupMapping.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message || "Panel added to tag."}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.panelLoading = false;
      })
      .addCase(createPanelGroupMapping.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Unable to add panel to tag."
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.panelLoading = false;
      });
  },
});

export const { resetPanelGroupState } = panelGroupSlice.actions;
export default panelGroupSlice.reducer;
