import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SlotView from "./SlotView";
import BookingOverlay from "./BookingOverlay.js";
import AvailabilityUpdateModal from "./MarkAvailability/AvailabilityUpdateModal";

import { createOrUpdateSlotAvailability } from "../../../redux/features/Availability/availabilitySlice.js";

import { Grid } from "@mui/material";

import { checkEqualDates } from "../../../utils/app-utils";
import { ROLE_TYPES } from "./../../../constants";

const { isPanelMember, isSpoc } = ROLE_TYPES;

const SlotAndBookingView = (props) => {
  const {
    slotDetails = [],
    date,
    booking,
    rounds,
    user,
    email,
    isTodayHoliday,
    isCancelledDrive,
    panelMemberAvailabilityPayload,
    stateToogle,
    setStateToogle,
    key,
    startDateOfWeek,
    endDateOfWeek,
    competencyId,
    roundDesignationId,
    activePageNumber,
    searchValue,
    slotsProvided,
    panelGroupId,
    roundName,
    activedesignationId,
    subCompetency,
    isWeekendDriveExist,
    daysDate,
    isPast,
    isDateOrPast,
  } = props;
  const dispatch = useDispatch();
  const { designation: designationList, loginInfo } = useSelector(
    (state) => state?.authReducer
  );

  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const [isBookingModalClicked, setIsBookingModalClicked] = useState(false);

  const findSlotByDate = (slotDetails = [], date) => {
    return (
      slotDetails?.filter((data) => {
        const dateOfAvailable = new Date(data.dateOfAvailable);
        return checkEqualDates(dateOfAvailable, new Date(date));
      }) || []
    );
  };
  const createOrUpdateAvailability = (slotAvailability) => {
    const panelMemberAvailabilityPayload = {
      periodFrom: new Date(startDateOfWeek).toISOString(),
      periodTo: new Date(endDateOfWeek).toISOString(),
      competencyId: loginInfo.current_role_competency_id,
      roundDesignationIds: roundDesignationId,
      roleId: loginInfo.role_id,
      pageNumber: activePageNumber,
      query: searchValue,
      slotsProvided: slotsProvided,
      panelGroupId: panelGroupId,
      roundName: roundName,
      activedesignationId,
      subCompetency: subCompetency,
    };
    dispatch(
      createOrUpdateSlotAvailability({
        slotAvailability,
        panelMemberAvailabilityPayload,
        roleId: loginInfo.role_id,
        dispatch,
      })
    );
  };
  const findBookingByDate = (booking = [], date) => {
    return (
      booking?.filter((data) => {
        const weekDate = new Date(date);
        const bookingDate = new Date(data.startTime);
        return checkEqualDates(weekDate, bookingDate);
      }) || []
    );
  };
  const findIsSaturday = (date) => {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 6;
  };
  const doesCurrentUserAvailabilityIsForLoggedInUser = () => {
    const todayDate = new Date(new Date().setHours(0, 0, 0, 0));
    return (
      (isPanelMember(loginInfo.current_role) ||
        isSpoc(loginInfo.current_role)) &&
      parseInt(user.id) === parseInt(loginInfo.id) &&
      new Date(date) > todayDate
    );
  };
  const slotDetailsOfTheDay = findSlotByDate(slotDetails, date);
  const bookingOfTheDay = findBookingByDate(booking, date);
  const isSaturday = findIsSaturday(date);
  const onShowAvailabilityUpdateModal = () => {
    if (isTodayHoliday || isCancelledDrive || isSaturday) {
      setShowAvailabilityModal(false);
    } else if (doesCurrentUserAvailabilityIsForLoggedInUser()) {
      setShowAvailabilityModal(true);
    } else {
      setShowAvailabilityModal(false);
    }
  };

  return (
    <>
      {Boolean(bookingOfTheDay?.length) &&
        bookingOfTheDay?.map((item, index) => {
          return (
            <BookingOverlay
              slotDetailsOfTheDay={slotDetailsOfTheDay}
              booking={item}
              designationList={designationList}
              loginInfo={loginInfo}
              weekDate={date}
              rounds={rounds}
              user={user}
              isBookingModalClicked={isBookingModalClicked}
              setIsBookingModalClicked={setIsBookingModalClicked}
              panelMemberAvailabilityPayload={panelMemberAvailabilityPayload}
              indexMap={index}
              length={bookingOfTheDay.length}
              email={email}
              key={index}
              bookingOfTheDay={bookingOfTheDay}
              stateToogle={stateToogle}
              setStateToogle={setStateToogle}
            />
          );
        })}
      <>
        {showAvailabilityModal && (
          <AvailabilityUpdateModal
            user={user}
            slotBookDate={date}
            showAvailabilityModal={showAvailabilityModal}
            setShowAvailabilityModal={setShowAvailabilityModal}
            slotDetailsOfTheDay={slotDetailsOfTheDay}
            createOrUpdateAvailability={createOrUpdateAvailability}
          />
        )}
        {!bookingOfTheDay.length ? (
          <Grid
            className={
              !isTodayHoliday && doesCurrentUserAvailabilityIsForLoggedInUser()
                ? "cursorPointer"
                : ""
            }
          >
            <SlotView
              daysDate={daysDate}
              rounds={rounds}
              slotDetailsOfTheDay={slotDetailsOfTheDay}
              weekDate={date}
              isTodayHoliday={isTodayHoliday}
              isCancelledDrive={isCancelledDrive}
              email={email}
              booking={booking}
              user={user}
              panelMemberAvailabilityPayload={panelMemberAvailabilityPayload}
              isWeekendDriveExist={isWeekendDriveExist}
              isDateOrPast={isDateOrPast}
              isPast={isPast}
              date={date}
              onShowAvailabilityUpdateModal={onShowAvailabilityUpdateModal}
              isSaturday={findIsSaturday}
            />
          </Grid>
        ) : (
          <></>
        )}
      </>
    </>
  );
};

export default SlotAndBookingView;
