import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { switchRole } from "../../redux/features/Authentication/authSlice";
import { resetReportState } from "../../redux/features/Reports/reportSlice";
import { resetRosterState } from "../../redux/features/Roster/rosterSlice";
import { resetDocumentState } from "../../redux/features/Document/documentSlice";
import { resetTagsState } from "../../redux/features/Tags/tagsSlice";
import { resetDashboardState } from "../../redux/features/HrDashboard/hrDashboardSlice";
import { resetAdminState } from "../../redux/features/Admin/adminSlice";
import { resetAvailabilityState } from "../../redux/features/Availability/availabilitySlice";
import { resetDesignationState } from "../../redux/features/Designation/designationSlice";
import { resetPanelGroupState } from "../../redux/features/PanelGroup/panelGroup";
import { resetWeekendDriveState } from "../../redux/features/WeekendDrive/weekendDriveSlice";

import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Avatar,
  Stack,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuList,
  Divider,
  Typography,
  MenuItem,
  Menu,
  Icon,
  InputBase,
  Paper,
  Popover,
} from "@mui/material";
import {
  KeyboardArrowDownOutlined,
  PowerSettingsNewOutlined,
  SettingsOutlined,
} from "@mui/icons-material";

import searchIcon from "../../assets/icons/search-icon.svg";
import AppDrawerIcon from "../../assets/icons/app-drawer-icon.svg";
import AppDrawerHoverIcon from "../../assets/icons/app-drawer-hover-icon.svg";
import BootCampIcon from "../../assets/icons/bootcamp-icon.svg";
import BootcampHoverIcon from "../../assets/icons/bootcamp-hover-icon.svg";
import GrowthIcon from "../../assets/icons/growth-icon.svg";
import GrowthHoverIcon from "../../assets/icons/growth-hover-icon.svg";
import ReferralIcon from "../../assets/icons/referral-icon.svg";
import ReferralHoverIcon from "../../assets/icons/referral-hover-icon.svg";
import InvestmentIcon from "../../assets/icons/investment-declaration-icon.svg";
import InvestmentHoverIcon from "../../assets/icons/investment-hover-icon.svg";
import AssetsIcon from "../../assets/icons/asset-desk-icon.svg";
import AssetsHoverIcon from "../../assets/icons/asset-hover-icon.svg";
import ReimbursementIcon from "../../assets/icons/reimbursement-icon.svg";
import ReimbursementHoverIcon from "../../assets/icons/reimbursement-hover-icon.svg";
import TimesheetIcon from "../../assets/icons/timesheet-icon.svg";
import TimesheetHoverIcon from "../../assets/icons/timesheet-hover-icon.svg";
import SkillMatrixIcon from "../../assets/icons/skill-matrix-icon.svg";
import skillMatrixHoverIcon from "../../assets/icons/skill-matrix-hover-icon.svg";
import editIcon from "../../assets/icons/edit-icon.svg";
import logo from "../../assets/icons/ttn-logo.svg";

import {
  deriveAbbreviatedName,
  redirectToPage,
  redirectUrl,
} from "../../utils/app-utils";
import {
  bootcamp_url,
  growth_url,
  skillmatrix_url,
  referral_url,
  timesheet_url,
  reimbursement_url,
  asset_url,
  ROLE_TYPES,
  investment_url,
  REPORT_BUG_GOOGLE_FORM_URL,
} from "../../constants";

import styles from "./style.module.scss";
import theme from "../../theme";

const { isPanelMember, isAdmin, isHr, isSpoc } = ROLE_TYPES;
let controller;

const Navbar = ({ props, open, handleDrawerOpen }) => {
  const {
    onSearchChange,
    searchValue,
    showSearch,
    placeholderName,
    setActivePageNumberForSearch,
    setSignal,
  } = props;

  const dispatch = useDispatch();
  const {
    name,
    current_role_competency_id,
    profile_pic_url,
    role = [],
    current_role,
    role_id,
  } = useSelector((state) => state.authReducer?.loginInfo);
  const competencyList = useSelector((state) => state.authReducer.competency);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDrawerIcon, setShowDrawerIcon] = React.useState(null);
  const [isBootcampHovered, setIsBootcampHovered] = useState(false);
  const [isDrawerHovered, setIsDrawerHovered] = useState(false);
  const [isGrowthHovered, setIsGrowthHovered] = useState(false);
  const [isInvestmentHovered, setIsInvestmentHovered] = useState(false);
  const [isReferralHovered, setIsReferralHovered] = useState(false);
  const [isAssetHovered, setIsAssetHovered] = useState(false);
  const [isReimbursementHovered, setIsReimbursementHovered] = useState(false);
  const [isTimesheetHovered, setIsTimesheetHovered] = useState(false);
  const [isSkillHovered, setIskillHovered] = useState(false);

  useEffect(() => {
    onSearchChange("");
  }, [current_role_competency_id, current_role]);

  const menuId = "primary-search-account-menu";
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDrawer = () => {
    setShowDrawerIcon(null);
  };
  const resetAppStates = () => {
    dispatch(resetRosterState());
    dispatch(resetTagsState());
    dispatch(resetReportState());
    dispatch(resetDocumentState());
    dispatch(resetDashboardState());
    dispatch(resetAdminState());
    dispatch(resetWeekendDriveState());
    dispatch(resetAvailabilityState());
    dispatch(resetDesignationState());
    dispatch(resetPanelGroupState());
  };
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    onSearchChange(searchTerm);
    setActivePageNumberForSearch(1);
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    setSignal(controller.signal);
  };
  const handleOptionClick = (value) => {
    const userRole = role.find((data) => data.role_id === Number(value));
    dispatch(
      switchRole({
        current_role: userRole.user_role,
        current_role_competency_id: userRole.role_competency_id ?? null,
        role_id: userRole.role_id,
      })
    );

    resetAppStates();

    if (isHr(userRole.user_role)) {
      redirectToPage("/main/dashboard");
    } else if (isAdmin(userRole.user_role) || isSpoc(userRole.user_role)) {
      redirectToPage("/main/roster");
    } else if (isPanelMember(userRole.user_role)) {
      redirectToPage("/main/Availability");
    } else {
      redirectToPage("/login");
    }
    handleClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={styles.Menu}
    >
      <MenuList className={styles.MenuList}>
        <Grid direction={"row"} spacing={0} container>
          <Grid item marginLeft={1}>
            <SettingsOutlined fontSize="small" className={styles.MenuIcon} />
          </Grid>
          <Grid item>
            <Typography
              className={styles.MenuListText}
              marginLeft={".5rem"}
              variant="body2"
            >
              Switch Role
            </Typography>
          </Grid>
        </Grid>
        <ListItemText
          sx={{
            maxHeight: "10rem",
            overflowY: "scroll",
          }}
          secondary={
            role.length &&
            role?.map((user) => (
              <MenuItem
                key={user.role_id}
                onClick={() => handleOptionClick(user.role_id)}
                sx={{ marginLeft: "1rem" }}
                disabled={user.role_id === role_id}
              >
                <Typography
                  noWrap
                  variant="body2"
                  sx={{ fontSize: "12px", color: "#2E1C41" }}
                  className={styles.MuiTypographyRoot}
                >
                  {isAdmin(user.user_role)
                    ? `${user.user_role}`
                    : `${deriveAbbreviatedName(
                        competencyList[user.role_competency_id]
                      )} - ${
                        isPanelMember(user.user_role)
                          ? user.user_role.split("_")[0]
                          : user.user_role
                      }`}
                </Typography>
              </MenuItem>
            ))
          }
        ></ListItemText>
        <Divider variant="middle" sx={{ borderColor: "##ECE9F0" }} />
        <Link
          to={REPORT_BUG_GOOGLE_FORM_URL}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <MenuItem>
            <ListItemIcon>
              <img src={editIcon} alt="edit-icon" className={styles.MenuIcon} />
            </ListItemIcon>
            <ListItemText className={styles.MenuListText} disableTypography>
              Feedback
            </ListItemText>
          </MenuItem>
        </Link>
        <Link to="/logout" style={{ textDecoration: "none" }}>
          <MenuItem>
            <Stack direction={"row"}>
              <ListItemIcon>
                <PowerSettingsNewOutlined
                  fontSize="small"
                  className={styles.MenuIcon}
                />
              </ListItemIcon>
              <ListItemText disableTypography className={styles.MenuListText}>
                Logout
              </ListItemText>
            </Stack>
          </MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
  const renderAppDrawerIcons = (
    <Popover
      open={Boolean(showDrawerIcon)}
      anchorEl={showDrawerIcon}
      onClose={handleCloseDrawer}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ left: "-7.8%" }}
      classes={{ paper: styles.DrawerMenuList }}
    >
      <Box className={`${styles.BoxMenuList}`}>
        <Grid container>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIsBootcampHovered(true)}
              onMouseOut={() => setIsBootcampHovered(false)}
              onClick={() => redirectUrl(bootcamp_url)}
            >
              <img
                src={isBootcampHovered ? BootcampHoverIcon : BootCampIcon}
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>Bootcamp</Typography>
          </Grid>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIsGrowthHovered(true)}
              onMouseOut={() => setIsGrowthHovered(false)}
              onClick={() => redirectUrl(growth_url)}
            >
              <img
                src={isGrowthHovered ? GrowthHoverIcon : GrowthIcon}
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>Growth</Typography>
          </Grid>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIsReferralHovered(true)}
              onMouseOut={() => setIsReferralHovered(false)}
              onClick={() => redirectUrl(referral_url)}
            >
              <img
                src={isReferralHovered ? ReferralHoverIcon : ReferralIcon}
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>Referral</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIsInvestmentHovered(true)}
              onMouseOut={() => setIsInvestmentHovered(false)}
              onClick={() => redirectUrl(investment_url)}
            >
              <img
                src={isInvestmentHovered ? InvestmentHoverIcon : InvestmentIcon}
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>
              Investment Declaration
            </Typography>
          </Grid>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIsAssetHovered(true)}
              onMouseOut={() => setIsAssetHovered(false)}
              onClick={() => redirectUrl(asset_url)}
            >
              <img
                src={isAssetHovered ? AssetsHoverIcon : AssetsIcon}
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>Asset Desk</Typography>
          </Grid>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIsReimbursementHovered(true)}
              onMouseOut={() => setIsReimbursementHovered(false)}
              onClick={() => redirectUrl(reimbursement_url)}
            >
              <img
                src={
                  isReimbursementHovered
                    ? ReimbursementHoverIcon
                    : ReimbursementIcon
                }
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>
              Reimbursement Claims
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIsTimesheetHovered(true)}
              onMouseOut={() => setIsTimesheetHovered(false)}
              onClick={() => redirectUrl(timesheet_url)}
            >
              <img
                src={isTimesheetHovered ? TimesheetHoverIcon : TimesheetIcon}
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>Timesheet</Typography>
          </Grid>
          <Grid direction={"row"} spacing={0} item xs={4} md={4} lg={4} xl={4}>
            <IconButton
              size="large"
              className={styles.drawericons}
              onMouseOver={() => setIskillHovered(true)}
              onMouseOut={() => setIskillHovered(false)}
              onClick={() => redirectUrl(skillmatrix_url)}
            >
              <img
                src={isSkillHovered ? skillMatrixHoverIcon : SkillMatrixIcon}
                alt="arrow-backward-icon"
              />
            </IconButton>
            <Typography className={styles.drawerText}>Skill Matrix</Typography>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );

  return (
    <>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#fff",
          borderBottom: "1px solid #ECE9F0",
          height: "66px",
        }}
        open={open}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid container xs={2.7} alignItems="center">
              <Grid xs={12} item className="ttn-logo">
                <img src={logo} alt="ttn-logo" />
              </Grid>
            </Grid>
            <Grid xs={5.3}>
              {showSearch && (
                <Paper component="form" className={styles.searchBarForm}>
                  <Icon
                    disabled
                    className={styles.searchIconStyle}
                    aria-label="search"
                  >
                    <img src={searchIcon} alt="search" />
                  </Icon>
                  <InputBase
                    className={styles.searchBarStyle}
                    placeholder={placeholderName}
                    inputProps={{ "aria-label": "search" }}
                    value={searchValue}
                    onChange={(event) => handleSearchChange(event)}
                  />
                </Paper>
              )}
            </Grid>
            <Grid
              xs={4}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box sx={{ display: { md: "flex" } }}>
                <Grid direction={"row"} paddingY={1}>
                  <Tooltip title="Application Drawer" placement="top" arrow>
                    <IconButton
                      size="small"
                      disableTouchRipple
                      className={styles.appdrawer}
                      onMouseOver={() => setIsDrawerHovered(true)}
                      onMouseOut={() => setIsDrawerHovered(false)}
                      onClick={() => setShowDrawerIcon(true)}
                      style={{
                        transform: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <img
                        src={
                          isDrawerHovered ? AppDrawerHoverIcon : AppDrawerIcon
                        }
                        alt="app-drawer"
                      />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    disableRipple
                    disableTouchRipple
                    sx={{ paddingRight: "0px", paddingLeft: "0px" }}
                  >
                    <ListItem
                      alignItems="flex-start"
                      sx={{ paddingLeft: "0px" }}
                    >
                      <Avatar
                        alt="profile-image"
                        src={profile_pic_url}
                        className={styles.avatarStyle}
                      />
                      <Grid direction={"column"}>
                        <Typography
                          alignSelf={"flex-end"}
                          color={theme.palette.secondary.main}
                          fontWeight={700}
                        >
                          {name}
                        </Typography>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            component="span"
                            variant="body2"
                            color={theme.palette.secondary.main}
                          >
                            {isAdmin(current_role)
                              ? competencyList[current_role_competency_id]
                              : `${deriveAbbreviatedName(
                                  competencyList[current_role_competency_id]
                                )} - ${
                                  isPanelMember(current_role)
                                    ? current_role.split("_")[0]
                                    : current_role
                                }`}
                          </Typography>
                          <KeyboardArrowDownOutlined />
                        </Stack>
                      </Grid>
                    </ListItem>
                  </IconButton>
                  {renderMenu}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderAppDrawerIcons}
    </>
  );
};

export default Navbar;
