const eligibleRoundCondition = (round, headerForCsv) => {
    return round.roundName === headerForCsv.roundName &&
      round.designationId === headerForCsv.designationId
      ? round.isPairing === true
        ? "P"
        : "Y"
      : "N";
  };
  
export const isEligibleForRound = (roundsArr, headerForCsv) => {
    for (var i = 0; i < roundsArr.length; i++) {
      if (
        eligibleRoundCondition(roundsArr[i], headerForCsv) === "P" ||
        eligibleRoundCondition(roundsArr[i], headerForCsv) === "Y"
      )
        return eligibleRoundCondition(roundsArr[i], headerForCsv);
    }
    return "N";
};
  