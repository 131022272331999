import React from "react";

import ShowFilterForAdmin from "./ShowFilterForAdmin";
import ShowFilterForHr from "./ShowFilterForHr";
import ShowFilterForSpoc from "./ShowFilterForSpoc";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Divider,
  Button,
} from "@mui/material";

import CloseIcon from "../../../../assets/icons/close-icon.svg";

import { ROLE_TYPES } from "../../../../constants/index";

import styles from "../style.module.scss";

const { isHr, isSpoc, isAdmin } = ROLE_TYPES;

const FilterComponents = ({
  keyvalue,
  competencyFilter,
  eligibleDesignationFilter,
  dateOfJoiningFilter,
  subCompetencyFilter,
  genderFilter,
  roundFilter,
  interviewerDesignationFilter,
  tagFilter,
  setKeyvalue,
  eligibleDesignationFilterForActive,
  resetRosterFilter,
  applyFilter,
  handleClose,
  user,
  roleTypeFilter,
  competencyRoleAdminFilter,
}) => {
  return (
    <>
      <Box mt={2} pt={6}>
        <Grid container>
          <Grid item xs={10} md={10} xl={10} lg={10}>
            <Typography
              variant="h6"
              gutterBottom
              pt={3}
              pl={3}
              className={styles.filtersHeading}
            >
              Filters
            </Typography>
          </Grid>
          <Grid item xs={2} md={2} xl={2} lg={2} p={2.5}>
            <IconButton onClick={(e) => handleClose(e)}>
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />
        <Grid
          container
          mt={2}
          ml={0.3}
          pr={2}
          spacing={3}
          sx={{ flexGrow: 1, overflowY: "hidden" }}
        >
          {isSpoc(user.current_role) && (
            <ShowFilterForSpoc
              keyvalue={keyvalue}
              setKeyvalue={setKeyvalue}
              competencyFilter={competencyFilter}
              dateOfJoiningFilter={dateOfJoiningFilter}
              genderFilter={genderFilter}
              subCompetencyFilter={subCompetencyFilter}
              interviewerDesignationFilter={interviewerDesignationFilter}
              eligibleDesignationFilter={eligibleDesignationFilter}
              eligibleDesignationFilterForActive={
                eligibleDesignationFilterForActive
              }
              roundFilter={roundFilter}
              tagFilter={tagFilter}
              loginInfo={user}
            />
          )}
          {isHr(user.current_role) && (
            <ShowFilterForHr
              keyvalue={keyvalue}
              setKeyvalue={setKeyvalue}
              competencyFilter={competencyFilter}
              dateOfJoiningFilter={dateOfJoiningFilter}
              genderFilter={genderFilter}
              subCompetencyFilter={subCompetencyFilter}
              interviewerDesignationFilter={interviewerDesignationFilter}
              eligibleDesignationFilter={eligibleDesignationFilter}
              eligibleDesignationFilterForActive={
                eligibleDesignationFilterForActive
              }
              roundFilter={roundFilter}
              tagFilter={tagFilter}
              loginInfo={user}
            />
          )}
          {isAdmin(user.current_role) && (
            <ShowFilterForAdmin
              keyvalue={keyvalue}
              competencyFilter={competencyFilter}
              competencyRoleAdminFilter={competencyRoleAdminFilter}
              genderFilter={genderFilter}
              eligibleDesignationFilter={eligibleDesignationFilter}
              roleTypeFilter={roleTypeFilter}
            />
          )}
        </Grid>
      </Box>

      <Grid container m={2} spacing={1}>
        <Grid
          item
          lg={5.5}
          xl={5.5}
          xs={5.5}
          md={5.5}
          display="flex"
          alignItems="flex-end"
        >
          <Button
            variant="outlined"
            fullWidth
            size="large"
            onClick={(e) => resetRosterFilter(e)}
          >
            CLEAR
          </Button>
        </Grid>

        <Grid item lg={5.5} xl={5.5} xs={5.5} md={5.5}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={(e) => applyFilter(e)}
            className={styles.searchBtn}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            SEARCH
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterComponents;
