import React, { useState } from "react";

import DocumentsList from "./DocumentsList";
import AddDocumentForm from "./AddDocumentForm";

import { Box } from "@mui/material";

const Document = () => {
  const [display, setDisplay] = useState(false);
  const [editDocument, setEditDocument] = useState(false);

  return (
    <Box className="globalLayoutHeight">
      <AddDocumentForm
        setDisplay={setDisplay}
        display={display}
        editDocument={editDocument}
        setEditDocument={setEditDocument}
      />
      <DocumentsList
        setDisplay={setDisplay}
        display={display}
        editDocument={editDocument}
        setEditDocument={setEditDocument}
      />
    </Box>
  );
};

export default Document;
