import React, { useState } from "react";

import { Chip, Grid, Tooltip } from "@mui/material";

import { validateDate } from "../../../../utils/date-utils";
import { checkEqualDates, ROLE_TYPES } from "../../../../constants";

import styles from "./style.module.scss";

const { isHr } = ROLE_TYPES;

const WeekendSlotView = ({
  item,
  user,
  email,
  slotDetails = [],
  booking,
  weekendDriveDate,
  rounds,
  competencyId,
  slotsProvided,
  roundDesignationId,
  activePageNumber,
  searchValue,
  roundName,
  stateToogle,
  activedesignationId,
  keyValue,
  slotsforGivenDate,
  weekendDriveListUpdated,
  weekendDriveList,
  allowDriveCoordinatorsForBooking,
  panelGroupId,
  weekendDriveSelected,
  subCompetency,
  selectedRecruiters,
  loginInfo,
}) => {
  const findMatchingSlotByTimeOnSameDay = (
    slotDetails = [],
    weekendDriveDate
  ) => {
    return (
      slotDetails?.filter((data) => {
        const dateOfAvailable = new Date(data.dateOfAvailable);
        return checkEqualDates(dateOfAvailable, new Date(weekendDriveDate));
      }) || []
    );
  };
  const slotDetailsOfTheDay = findMatchingSlotByTimeOnSameDay(
    slotDetails,
    weekendDriveDate
  );
  const checkSlotsValidation = () => {
    let boolean = false;
    getSlots.length &&
      getSlots.forEach((slots) => {
        if (
          new Date(slots.startTime).getTime() ===
            new Date(item.startTime).getTime() &&
          new Date(slots.endTime).getTime() === new Date(item.endTime).getTime()
        ) {
          boolean = true;
        }
      });
    return boolean;
  };
  const getSlots =
    slotDetailsOfTheDay.length && slotDetailsOfTheDay[0].slotTime;

  return (
    <>
      {slotDetailsOfTheDay?.length ? (
        checkSlotsValidation() ? (
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Chip
              label={`Available`}
              style={{
                pointerEvents: "none",
                outline: "none",
              }}
              key={`${slotDetailsOfTheDay[0].dateOfAvailable}`}
              className={
                isHr(loginInfo.current_role) && validateDate(weekendDriveDate)
                  ? `${styles.availableSlotStyle} `
                  : `${styles.availableSlotStyle}`
              }
            ></Chip>
          </Grid>
        ) : (
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Tooltip
              key={`${slotDetailsOfTheDay[0].dateOfAvailable}`}
              placement="top"
              delay={{ show: 250, hide: 400 }}
              arrow={true}
              title={
                slotDetailsOfTheDay[0]?.slotTime.length === 0 &&
                slotDetailsOfTheDay[0]?.comments !== ""
                  ? slotDetailsOfTheDay[0]?.comments
                  : ""
              }
            >
              <Chip
                label={"Not Available"}
                pill
                bg=""
                style={{
                  outline: "none",
                  boxShadow: "none",
                  cursor: slotDetailsOfTheDay[0]?.isAvailable
                    ? "default"
                    : "pointer",
                }}
                disableFocusRipple={true}
                disableRipple={true}
                disableTouchRipple={true}
                className={
                  isHr(loginInfo.current_role) && validateDate(weekendDriveDate)
                    ? `${styles.notAvailableStyle} `
                    : `${styles.notAvailableStyle}`
                }
              />
            </Tooltip>
          </Grid>
        )
      ) : (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Chip
            label={"Not Provided"}
            pill
            bg=" "
            style={{
              pointerEvents: "none",
              outline: "none",
            }}
            className={
              isHr(loginInfo.current_role) && validateDate(weekendDriveDate)
                ? `${styles.notProvidedSlotStyle}`
                : `${styles.notProvidedSlotStyle}`
            }
          />
        </Grid>
      )}
    </>
  );
};

export default WeekendSlotView;
