import React from "react";

import WarningPopup from "../Availability/BookingModal/WarningPopup";

import {
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Grid,
} from "@mui/material";

import { extractTime } from "../../../utils/date-utils";
import { INTERVIEW_STATUS_CHECK } from "../../../constants";

import styles from "./style.module.scss";

const DeleteDesignationConfirmModal = ({
  bookings,
  interviewStatus,
  editWeekendDrive,
}) => {
  const updatedBookings = bookings?.filter(
    (item) =>
      INTERVIEW_STATUS_CHECK.SCHEDULED === item.bookingStatus ||
      INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED === item.bookingStatus
  );
  if (
    !updatedBookings?.length &&
    INTERVIEW_STATUS_CHECK.CANCELLED !== interviewStatus
  ) {
    return (
      <Grid item xs={12} marginTop={2}>
        <Typography className={styles.no_comment}>
          No upcoming bookings.
        </Typography>
        <Typography className={styles.no_comment}>
          Press Update to save the changes.
        </Typography>
      </Grid>
    );
  }
  if (
    !updatedBookings?.length &&
    INTERVIEW_STATUS_CHECK.CANCELLED === interviewStatus
  ) {
    return (
      <Grid item xs={12} marginTop={2}>
        <Typography className={styles.no_comment}>
          No upcoming bookings.
        </Typography>
        <Typography className={styles.no_comment}>
          Press Update to cancel the drive.
        </Typography>
      </Grid>
    );
  }
  if (
    updatedBookings &&
    updatedBookings?.length &&
    (INTERVIEW_STATUS_CHECK.CANCELLED === interviewStatus ||
      (INTERVIEW_STATUS_CHECK.SCHEDULED === interviewStatus &&
        editWeekendDrive === null))
  ) {
    return (
      <Grid item xs={12} marginTop={2}>
        <WarningPopup
          WarningMessage={`The selected member has already interviews scheduled, please have
            them cancelled. And if you press "UPDATE"
            then upcoming bookings will also get removed including paired
            bookings.
            Are you sure you want to continue ?`}
        />

        <TableContainer>
          <Table size="small" aria-label="upcoming bookings table">
            <TableHead>
              <TableRow>
                <TableCell>Booking Date</TableCell>
                <TableCell>Booking Time</TableCell>
                <TableCell>Booking Assigned By</TableCell>
              </TableRow>
            </TableHead>
            {updatedBookings?.map((booking) => (
              <TableBody>
                <TableRow key={booking.id}>
                  <TableCell>
                    {new Date(booking.bookingDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {extractTime(booking.startTime)} -{" "}
                    {extractTime(booking.endTime)}
                  </TableCell>
                  <TableCell>{booking.bookingAssignedBy}</TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Grid>
    );
  }
};

export default DeleteDesignationConfirmModal;
