import React from "react";

import { ColorlibConnector } from "../../../../components/Stepper";

import { Stack, Stepper, Step, StepLabel } from "@mui/material";

import styles from "./style.module.scss";

const CustomizedStepper = ({ activeStep, steps }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label} className={styles.stepStyle}>
            <StepLabel className={styles.stepLabelStyle}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CustomizedStepper;
