import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import PanelistSlotsAndBookings from "./PanelistSlotsAndBookings";
import DatePickerComponent from "../../../../components/DatePickerComponent/index";

import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import SwitchInterviewerIcon from "../../../../assets/icons/switch-interviewer-icon.svg";

import dayjs from "dayjs";

import {
  INTERVIEW_STATUS_OPTIONS_SHORTFORM_EXPANDED,
  INTERVIEW_STATUS_CHECK,
} from "../../../../constants";

import styles from "./style.module.scss";

const NaOrRsStatusScreenPopup = (props) => {
  const {
    user,
    interviewStatus,
    isNaOrRsStatusPopupYesClicked,
    bookingDate,
    onSetBookingDate,
    startTime,
    onSetStartTime,
    endTime,
    setEndTime,
    bookingData,
    updatedInterviewerId,
    setUpdatedInterviewerId,
    showInterviewStatusNaOrRsPopup,
    eligiblePairingList,
    pairingUserId,
    leaderUserId,
    isUpdatedInterviewerValidationError,
    setIsUpdatedInterviewerValidationError,
    setPairingState,
  } = props;

  const weekendDriveList = useSelector(
    (state) => state.weekendDriveReducer?.weekendDriveDetails
  );

  const [interviewersList, setInterviewersList] = useState([]);
  const [slotsUpdated, setSlotsUpdated] = useState(false);
  const [notavailableUpdated, setNotAvailableUpdated] = useState(false);
  const [notprovidedUpdated, setNotProvidedUpdated] = useState(false);
  const [slotsPairing, setSlotsPairing] = useState(false);
  const [notavailablePairing, setNotAvailablePairing] = useState(false);
  const [notprovidedPairing, setNotProvidedPairing] = useState(false);

  useEffect(() => {
    if (
      bookingData?.isCoreInterviewer &&
      !bookingData?.pairedInterviewerId &&
      showInterviewStatusNaOrRsPopup
    ) {
      const list = eligiblePairingList.filter((item) => !item.round_inpairing);
      setInterviewersList(list);
    }
    // case 2 : when pairing is present with leader view and Interviewer NA is selected

    if (
      bookingData &&
      bookingData?.isCoreInterviewer &&
      bookingData?.pairedInterviewerId &&
      showInterviewStatusNaOrRsPopup
    ) {
      const list = eligiblePairingList.filter((item) => !item.round_inpairing);
      const filterList = list.filter(
        (item) => item.user_id !== parseInt(pairingUserId)
      );
      setInterviewersList(filterList);
    }
    // case 3 : when pairing is present with paired view and Interviewer NA is selected
    if (
      !bookingData?.isCoreInterviewer &&
      bookingData?.pairedInterviewerId &&
      showInterviewStatusNaOrRsPopup
    ) {
      const filterList = eligiblePairingList.filter(
        (item) => item.user_id !== parseInt(leaderUserId)
      );
      setInterviewersList(filterList);
    }
  }, [
    bookingData,
    eligiblePairingList,
    leaderUserId,
    pairingUserId,
    showInterviewStatusNaOrRsPopup,
  ]);
  useEffect(() => {
    if (
      bookingData?.isCoreInterviewer &&
      !bookingData?.pairedInterviewerId &&
      showInterviewStatusNaOrRsPopup
    ) {
      setUpdatedInterviewerId(leaderUserId);
    }
    // case 2 : when pairing is present with leader view and Interviewer NA is selected
    if (
      bookingData &&
      bookingData?.isCoreInterviewer &&
      bookingData?.pairedInterviewerId &&
      showInterviewStatusNaOrRsPopup
    ) {
      setUpdatedInterviewerId(leaderUserId);
    }
    // case 3 : when pairing is present with paired view and Interviewer NA is selected
    if (
      !bookingData?.isCoreInterviewer &&
      bookingData?.pairedInterviewerId &&
      showInterviewStatusNaOrRsPopup
    ) {
      setUpdatedInterviewerId(pairingUserId);
    }
  }, []);
  useEffect(() => {
    if (parseInt(updatedInterviewerId) !== 0) {
      const updatedInterviewerData = eligiblePairingList.find(
        (item) => parseInt(item.user_id) === parseInt(updatedInterviewerId)
      );
      if (updatedInterviewerData?.slotdetails === null) {
        setNotProvidedUpdated(true);
        setNotAvailableUpdated(false);
        setSlotsUpdated(false);
      }
      if (
        updatedInterviewerData?.slotdetails !== null &&
        updatedInterviewerData?.slotdetails[0].isAvailable === false
      ) {
        setNotProvidedUpdated(false);
        setNotAvailableUpdated(true);
        setSlotsUpdated(false);
      }
      if (
        updatedInterviewerData?.slotdetails !== null &&
        updatedInterviewerData?.slotdetails[0].isAvailable === true
      ) {
        setSlotsUpdated(true);
        setNotProvidedUpdated(false);
        setNotAvailableUpdated(false);
      }
    }
  }, [eligiblePairingList, updatedInterviewerId]);
  useEffect(() => {
    const updatedPairedmemberData = eligiblePairingList.find(
      (item) => parseInt(item.user_id) === parseInt(pairingUserId)
    );
    if (
      updatedPairedmemberData &&
      updatedPairedmemberData?.slotdetails === null
    ) {
      setNotProvidedPairing(true);
      setNotAvailablePairing(false);
      setSlotsPairing(false);
    }
    if (
      updatedPairedmemberData &&
      updatedPairedmemberData?.slotdetails !== null &&
      updatedPairedmemberData?.slotdetails[0].isAvailable === false
    ) {
      setNotProvidedPairing(false);
      setNotAvailablePairing(true);
      setSlotsPairing(false);
    }
    if (
      updatedPairedmemberData &&
      updatedPairedmemberData?.slotdetails !== null &&
      updatedPairedmemberData?.slotdetails[0].isAvailable === true
    ) {
      setSlotsPairing(true);
      setNotProvidedPairing(false);
      setNotAvailablePairing(false);
    }
  }, [eligiblePairingList, pairingUserId]);

  const futureWeekendDrives = weekendDriveList
    .map((obj) => dayjs(obj["date_of_drive"]))
    .filter(
      (date) =>
        date.isAfter(dayjs(new Date())) || date.isSame(dayjs(new Date()), "day")
    );
  const isDateDisabled = (date) => {
    return futureWeekendDrives?.some((disabledDate) =>
      disabledDate.isSame(date, "day")
    );
  };
  const handlePairingUserIdChange = (event, newValue) => {
    setPairingState(newValue?.value);
  };
  const handleUpdateInterviewerIdChange = (event, newValue) => {
    setUpdatedInterviewerId(newValue?.value);
  };

  const selectUpdatedPairedInterviewer = () => {
    const listForPaired = eligiblePairingList
      ? eligiblePairingList.filter(
          (item) =>
            item.user_id !== parseInt(updatedInterviewerId) &&
            item.user_id !== leaderUserId
        )
      : [];
    return (
      <>
        <Grid item xs={12}>
          <Grid direction={"row"}>
            <Grid item marginBottom={2}>
              {
                <Grid item marginY={2}>
                  {listForPaired?.length ? (
                    <FormControl
                      fullWidth
                      error={isUpdatedInterviewerValidationError}
                    >
                      <Autocomplete
                        id="updated-paired-interviewer"
                        disableClearable
                        disablePortal
                        fullWidth
                        options={listForPaired?.map((option) => ({
                          label: `${option.email} ${
                            option.round_inpairing ? "(Pairing)" : ""
                          }`,
                          value: option.user_id,
                          slotdetails:option.slotdetails
                        }))}
                        value={listForPaired?.reduce(
                          (acc, user) =>
                            user.user_id === pairingUserId
                              ? {
                                  label: `${user.email} ${
                                    user.round_inpairing ? "(Pairing)" : ""
                                  }`,
                                  value: user.user_id,
                                }
                              : acc,
                          null
                        )}
                        onChange={handlePairingUserIdChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Paired Interviewer"
                            placeholder="Select Paired Interviewer"
                          />
                        )}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{
                              ...props.style,
                              backgroundColor: option
                                .slotdetails?.[0]?.isAvailable
                                ? "#E0F7CC"
                                : "default",
                            }}
                          >
                            {option.label}
                          </li>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: 150,
                            overflow: "auto",
                          },
                        }}
                      />
                      {isUpdatedInterviewerValidationError && (
                        <FormHelperText>Interviewer is Required</FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          Paired Interviewer :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign={"end"}>
                        <Typography variant="body1" color="red">
                          No Data Found
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {pairingUserId && (
                    <Grid container direction={"row"} marginY={2} gap={1}>
                      <PanelistSlotsAndBookings
                        slots={slotsPairing}
                        notavailable={notavailablePairing}
                        notprovided={notprovidedPairing}
                        pairedmemberData={eligiblePairingList.find(
                          (item) => item.user_id === parseInt(pairingUserId)
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      {!isNaOrRsStatusPopupYesClicked ? (
        <Grid className={styles.interviewStatusNaOrRsPopup}>
          <Grid
            item
            xs={12}
            className={styles.interviewStatusNaOrRsPopupSubContainer}
          >
            <img src={SwitchInterviewerIcon} alt="switch-interviewer-icon" />
            <Typography
              variant="h5"
              color="initial"
              className={styles.popupScreenHeading}
            >
              {INTERVIEW_STATUS_OPTIONS_SHORTFORM_EXPANDED[interviewStatus]}
            </Typography>
            {(interviewStatus ===
              INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE ||
              interviewStatus ===
                INTERVIEW_STATUS_CHECK.CANDIDATE_RESCHEDULED) && (
              <Typography
                variant="h5"
                color="initial"
                className={styles.popupScreenSubHeading}
              >
                Do you want to switch interviewer?
              </Typography>
            )}
            {interviewStatus ===
              INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED && (
              <Typography
                variant="h5"
                color="initial"
                className={styles.popupScreenSubHeading}
              >
                Do you want to switch Interview Date & Time?
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          {" "}
          <Grid direction={"row"} container spacing={1} marginTop={2} gap={2}>
            <Grid item xs={12}>
              <DatePickerComponent
                label="Booking Date"
                value={dayjs(bookingDate)}
                handleChange={onSetBookingDate}
                disablePast={true}
                futureWeekendDrives={futureWeekendDrives}
                isDateDisabled={isDateDisabled}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      closeOnSelect={false}
                      label={"Start Time"}
                      value={dayjs(startTime)}
                      onChange={(newValue) => {
                        onSetStartTime(newValue);
                      }}
                      fullWidth
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      closeOnSelect={false}
                      label={"End Time"}
                      value={dayjs(endTime)}
                      onChange={(newValue) => {
                        setEndTime(newValue);
                      }}
                      fullWidth
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid direction={"row"}>
                <Grid item marginBottom={2}>
                  <Grid item marginY={2}>
                    {interviewStatus ===
                    INTERVIEW_STATUS_CHECK.INTERVIEWER_RESCHEDULED ? (
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="body1">Interviewer :</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={"start"}>
                          <Typography variant="body1">{user.email}</Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        {interviewersList?.length ? (
                          <FormControl
                            fullWidth
                            error={isUpdatedInterviewerValidationError}
                          >
                            <Autocomplete
                              id="updated-interviewer"
                              disableClearable
                              disablePortal
                              fullWidth
                              options={interviewersList?.map((option) => ({
                                label: `${option.email} ${
                                  option.round_inpairing ? "(Pairing)" : ""
                                }`,
                                value: option.user_id,
                                slotdetails:option.slotdetails
                              }))}
                              value={interviewersList?.reduce(
                                (acc, user) =>
                                  user.user_id ===
                                  parseInt(updatedInterviewerId)
                                    ? {
                                        label: `${user.email} ${
                                          user.round_inpairing
                                            ? "(Pairing)"
                                            : ""
                                        }`,
                                        value: user.user_id,
                                      }
                                    : acc,
                                null
                              )}
                              onChange={handleUpdateInterviewerIdChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Interviewer"
                                  placeholder="Select Interviewer"
                                />
                              )}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    ...props.style,
                                    backgroundColor: option
                                      .slotdetails?.[0]?.isAvailable
                                      ? "#E0F7CC"
                                      : "default",
                                  }}
                                >
                                  {option.label}
                                </li>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: 150,
                                  overflow: "auto",
                                },
                              }}
                            />

                            {isUpdatedInterviewerValidationError && (
                              <FormHelperText>
                                Interviewer is Required
                              </FormHelperText>
                            )}
                          </FormControl>
                        ) : (
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                Interviewer :
                              </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign={"end"}>
                              <Typography variant="body1" color="red">
                                No Data Found
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                    {updatedInterviewerId && (
                      <Grid container direction={"row"} marginY={2} gap={1}>
                        <PanelistSlotsAndBookings
                          slots={slotsUpdated}
                          notavailable={notavailableUpdated}
                          notprovided={notprovidedUpdated}
                          pairedmemberData={eligiblePairingList.find(
                            (item) =>
                              parseInt(item.user_id) ===
                              parseInt(updatedInterviewerId)
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {updatedInterviewerId &&
              parseInt(updatedInterviewerId) !== user.id &&
              bookingData &&
              bookingData.isCoreInterviewer &&
              bookingData.pairedInterviewerId &&
              selectUpdatedPairedInterviewer()}
          </Grid>
        </>
      )}
    </>
  );
};

export default NaOrRsStatusScreenPopup;
