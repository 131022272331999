import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { getMenuItems } from "../../constants";

import styles from "./style.module.scss";

const Sidebar = ({ props, open, handleDrawerClose, handleDrawerOpen }) => {
  const { setShowSearch, onSearchChange } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);

  useEffect(() => {
    const currentMenuItemDetail = getMenuItems(loginInfo.current_role).find(
      (item) => `/main/${item.path}` === location.pathname
    );
    setShowSearch(currentMenuItemDetail?.showSearch);
  }, [location.pathname, loginInfo.current_role, setShowSearch]);

  const matchPath = ({ selectedPath, location }) => {
    return location.pathname === `/main/${selectedPath}`;
  };
  const handleMenuItem = (item) => {
    navigate(item.path);
    setShowSearch(item.showSearch);
    onSearchChange("");
  };

  const ListItemIconComponent = (item, open) => {
    return (
      <ListItemIcon
        className={
          matchPath({ selectedPath: item.path, location })
            ? styles.listItemIconStyle
            : ""
        }
        sx={{
          minWidth: 0,
          mr: open ? 3 : "auto",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        {matchPath({ selectedPath: item.path, location })
          ? item.selectedIcon
          : item.icon}
        {!open && (
          <Typography
            variant="caption"
            align="center"
            sx={{ fontSize: "9px", marginTop: "2px" }}
          >
            {item.key === "DOCUMENT_REPOSITORY"
              ? "Documents"
              : item.key === "WEEKEND_DRIVE"
              ? "Drive"
              : item.key === "REPORTS_CONFIGURATIONS"
              ? "Reports Config"
              : item.label}
          </Typography>
        )}
      </ListItemIcon>
    );
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      className={open ? styles.customDrawer : ""}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: open ? "15%" : "5%",
          overflowX: open ? "visible" : "hidden",
        },
      }}
    >
      <Toolbar />
      <Box>
        <List autoWidth={false}>
          <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon />
              </IconButton>
            )}
          </ListItem>
          {getMenuItems(loginInfo.current_role)?.map((item) => (
            <ListItem
              key={item.key}
              onClick={() => {
                handleMenuItem(item);
              }}
              sx={{ padding: ".6vw" }}
            >
              <ListItemButton
                disableRipple={true}
                selected={matchPath({ selectedPath: item.path, location })}
                className={
                  matchPath({ selectedPath: item.path, location })
                    ? styles.listItemStyle
                    : ""
                }
                sx={{
                  justifyContent: open ? "initial" : "center",
                }}
              >
                {ListItemIconComponent(item, open)}
                <ListItemText
                  className={styles.ListItemStyle}
                  sx={{ opacity: open ? 1 : 0 }}
                >
                  {item.label}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Outlet />
    </Drawer>
  );
};

export default Sidebar;
