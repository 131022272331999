import React from "react";

import AutoCompleteComponent from "../../../../../components/AutoComplete";
import DatePickerComponent from "../../../../../components/DatePickerComponent/index";

import {
  SwipeableDrawer,
  Slide,
  Box,
  IconButton,
  Button,
  Grid,
  Typography,
  Divider,
} from "@mui/material";

import closeIcon from "../../../../../assets/icons/close-icon.svg";

import styles from "../style.module.scss";

const FilterCompetency = ({
  show,
  setShow,
  clickConfirm,
  selectedCompetencies,
  handleSelect,
  filterByCompetencyOptions,
  periodFrom,
  periodTo,
  setPeriodFrom,
  setPeriodTo,
  handleClear,
}) => {
  const closeModal = () => {
    setShow(false);
  };

  return (
    <SwipeableDrawer anchor="right" open={show} onBackdropClick={closeModal}>
      <Slide direction="left" in={show} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: 400,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            overflowX: "hidden",
          }}
          role="presentation"
        >
          <Box mt={2} pt={6}>
            <Typography variant="h6" gutterBottom pt={3} pl={3}>
              Filters
            </Typography>

            <IconButton onClick={closeModal} sx={{ ml: 44, mt: -10 }}>
              <img src={closeIcon} />
            </IconButton>

            <Divider />

            <Grid
              container
              m={0}
              pr={2}
              spacing={3}
              sx={{ overflowY: "hidden" }}
            >
              <Grid item xs={12} pr={3}>
                <DatePickerComponent
                  label={"From"}
                  value={periodFrom}
                  handleChange={setPeriodFrom}
                  maxDate={periodTo}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} pr={3}>
                <DatePickerComponent
                  label={"To"}
                  value={periodTo}
                  handleChange={setPeriodTo}
                  minDate={periodFrom}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} pr={3}>
                <AutoCompleteComponent
                  options={filterByCompetencyOptions}
                  label={"Filter by Competency"}
                  onChange={handleSelect}
                  selectedValue={selectedCompetencies}
                  limitTags={1}
                  placeholder={"Competency"}
                  competencyAbbrivation={true}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid container m={2} spacing={1}>
            <Grid item sm={5.5} display="flex" alignItems="flex-end">
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={handleClear}
                className={styles.cancelBtn}
              >
                CLEAR
              </Button>
            </Grid>

            <Grid item sm={5.5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => clickConfirm()}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className={styles.searchBtn}
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </SwipeableDrawer>
  );
};

export default FilterCompetency;
