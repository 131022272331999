import React from "react";

import { Grid } from "@mui/material";

import {
  ROSTER_FILTER_TAB_CHECK_VALUE,
  JS_COMPETENCY_ID,
} from "../../../../constants";

const ShowFilterForSpoc = ({
  keyvalue,
  competencyFilter,
  eligibleDesignationFilter,
  dateOfJoiningFilter,
  subCompetencyFilter,
  genderFilter,
  roundFilter,
  interviewerDesignationFilter,
  tagFilter,
  loginInfo,
  setKeyvalue,
  eligibleDesignationFilterForActive,
}) => {
  return (
    <>
      {keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL && (
        <>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {competencyFilter()}
          </Grid>
          {loginInfo.current_role_competency_id === JS_COMPETENCY_ID && (
            <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
              {subCompetencyFilter()}
            </Grid>
          )}
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {eligibleDesignationFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {dateOfJoiningFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {genderFilter()}
          </Grid>
        </>
      )}
      {keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE && (
        <>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {roundFilter()}
          </Grid>

          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {eligibleDesignationFilterForActive()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {dateOfJoiningFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {interviewerDesignationFilter()}
          </Grid>
          <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
            {tagFilter()}
          </Grid>
          {loginInfo.current_role_competency_id === JS_COMPETENCY_ID && (
            <Grid item xl={12} xs={12} md={12} lg={12} pr={3}>
              {subCompetencyFilter()}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ShowFilterForSpoc;
