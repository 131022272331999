import React from "react";

import { Grid, Chip, Typography } from "@mui/material";

import { deriveAbbreviatedName } from "../../../../utils/app-utils";

import styles from "./style.module.scss";

const ListDriveCoordinatorsAndDesginations = ({
  coordinators,
  designationList,
}) => {
  return (
    <Grid
      item
      xs={12}
      lg={12}
      xl={12}
      md={12}
      display={"flex"}
      justifyContent={"space-between"}
      marginBottom={2}
    >
      <Grid display={"flex"}>
        <Typography className={styles.coordinatorsHeading}>
          Coordinators
        </Typography>
        <Typography>
          {coordinators?.map((coordinator, index) => (
            <Chip
              key={index}
              label={coordinator}
              style={{ background: "#F1F1F1", fontSize: "14px" }}
              variant="outlined"
            />
          ))}
        </Typography>
      </Grid>

      <Grid display={"flex"}>
        <Typography className={styles.coordinatorsHeading}>
          Hiring Level
        </Typography>
        <Typography>
          {designationList?.map((designation, index) => (
            <Chip
              key={index}
              label={deriveAbbreviatedName(designation.designationName)}
              style={{ background: "#F1F1F1", fontSize: "14px" }}
              variant="outlined"
            />
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ListDriveCoordinatorsAndDesginations;
