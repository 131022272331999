import React from "react";

import AutoCompleteComponent from "../../../../../components/AutoComplete";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";

import closeIcon from "../../../../../assets/icons/close-icon.svg";

import styles from "../style.module.scss";

const RecruiterList = ({
  show,
  setShow,
  clickConfirm,
  recruiterOptionsList,
  handleRecruiterValueAndActivePage,
  selectedRecruiterOptions,
}) => {
  const closeModal = () => {
    setShow(false);
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    clickConfirm();
  };
  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        PaperProps={{
          style: {
            width: "28rem",
            maxHeight: "50vh",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle sx={{ padding: "30px 24px 0px" }}>
          Filter by recruiter
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            <Grid>
              <AutoCompleteComponent
                options={recruiterOptionsList}
                label={"Filter by Recruiter"}
                onChange={handleRecruiterValueAndActivePage}
                selectedValue={selectedRecruiterOptions}
                limitTags={1}
                placeholder={"Recruiter"}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ padding: "0px 17px 17px 17px" }}
          onClick={onFormSubmit}
        >
          <Button
            fullWidth
            disabled={false}
            variant={"contained"}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
            className={styles.searchBtn}
            sx={{ borderRadius: "8px" }}
          >
            <Typography variant={"h6"} fontSize={14}>
              SEARCH
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecruiterList;
