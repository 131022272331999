import { useEffect } from "react";

import { removeAuthInfo } from "./../../utils/app-utils";

const Logout = () => {
  useEffect(() => {
    removeAuthInfo();
    window.location.replace("/login");
  }, []);
};

export default Logout;
