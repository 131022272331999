import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IOSSwitch } from "../../../../components/Switch/IoSSwitch";
import DaySlotTimeView from "../../Availability/MarkAvailability/DaySlotTimeView";
import DatePickerComponent from "../../../../components/DatePickerComponent/index";

import {
  setStartDate,
  setEndDate,
  markAvailability,
  markTempUnavailableUser,
  resetAvailabilityDays,
} from "../../../../redux/features/Availability/availabilitySlice";

import {
  Checkbox,
  Box,
  Grid,
  Typography,
  SwipeableDrawer,
  Button,
  Slide,
  FormControlLabel,
  TextField,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import dayjs from "dayjs";
import { format, startOfWeek, endOfWeek } from "date-fns";

import { getNextSeventhDay } from "../../../../utils/date-utils";
import {
  MODULE_NAME,
  WEEK_DAYS,
  ROLE_TYPES,
  ROUND_FILTER_OPTIONS,
  SLOT_FILTER_OPTIONS,
} from "../../../../constants/index";

import styles from "./style.module.scss";

const { isPanelMember } = ROLE_TYPES;

const MarkAvailability = ({
  loginInfo,
  startDateOfWeek,
  endDateOfWeek,
  isMarkAvailabilityModalOpen,
  setIsMarkAvailabilityModalOpen,
  roundDesignationId,
  activePageNumber,
  searchValue,
  slotsProvided,
  roundName,
  activedesignationId,
  panelGroupId,
  subCompetency,
  loggedInUserPanelDetails,
  moduleName = MODULE_NAME.AVAILABILITY,
  startMonthDate,
  endMonthDate,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.availabilityReducer?.loading);
  let state = useSelector((state) => state?.availabilityReducer);
  let { days, endDate, startDate } = state;
  state = { days, endDate, startDate };

  const [err, setErr] = useState({ value: false, flag: "minOneHour" });
  const [unavailableErr, setUnavailableErr] = useState({
    value: false,
    flag: "",
  });
  const [userPanelCompetencyId, setUserPanelCompetencyId] = useState();
  const [copyUserAvailablities, setCopyUserAvailablities] = useState(false);
  const [panelId, setPanelId] = useState(null);
  const [showCopyUserAvailablityCheckbox, setShowCopyUserAvailablityCheckbox] =
    useState(false);
  const [unavailable, setUnAvailable] = useState(false);
  const [commentNotes, setCommentNotes] = useState("");

  useEffect(() => {
    dispatch(
      setStartDate(
        format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd")
      )
    );
    dispatch(
      setEndDate(
        format(new Date().setDate(new Date().getDate() + 14), "yyyy-MM-dd")
      )
    );
    dispatch(resetAvailabilityDays());
  }, []);
  useEffect(() => {
    if (loggedInUserPanelDetails) {
      setUserPanelCompetencyId(loggedInUserPanelDetails.panel_competency_id);
      setPanelId(loggedInUserPanelDetails.panel_id);
    }
  }, [
    userPanelCompetencyId,
    setUserPanelCompetencyId,
    setPanelId,
    loggedInUserPanelDetails,
  ]);
  useEffect(() => {
    if (loggedInUserPanelDetails?.slotdetails?.length) {
      setShowCopyUserAvailablityCheckbox(true);
    }
  }, [loggedInUserPanelDetails]);

  const toggleDrawer = (newOpen) => () => {
    setIsMarkAvailabilityModalOpen(newOpen);
  };
  const handleClose = () => {
    setIsMarkAvailabilityModalOpen(false);
  };
  function monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }
  const validateForm = (days) => {
    let error = false;
    if (!userPanelCompetencyId) error = error ? error : true;
    if (copyUserAvailablities) return error;
    let d_period_from = state.startDate;
    let d_period_end = state.endDate;
    let curr_date = format(
      new Date().setDate(new Date().getDate()),
      "yyyy-MM-dd"
    );
    if (d_period_from <= curr_date) {
      setErr({ value: true, flag: "startdate" });
      error = true;
    }
    if (d_period_end <= curr_date) {
      setErr({ value: true, flag: "enddate" });
      error = true;
    }

    let months = monthDiff(new Date(d_period_from), new Date(d_period_end));
    if (d_period_end < d_period_from || !(months <= 6)) {
      error = true;
    }
    days?.forEach(({ slotTime, comments }) => {
      if (slotTime.length) {
        if (!slotTime.length) error = error ? error : true;
        slotTime?.map((item) => {
          let startTime =
            item.startTime === null
              ? new Date().setHours(10, 0, 0, 0)
              : item.startTime;
          let endTime =
            item.endTime === null
              ? new Date().setHours(17, 0, 0, 0)
              : item.endTime;
          let min_end = new Date(endTime).getMinutes();
          let min_start = new Date(startTime).getMinutes();
          let hour_end = new Date(endTime).getHours();
          let hour_start = new Date(startTime).getHours();
          let total_start_min = hour_start * 60 + min_start;
          let total_end_min = hour_end * 60 + min_end;
          let minutes = total_end_min - total_start_min;

          if (hour_start < hour_end) {
            if (minutes > 0 && minutes < 60) {
              error = true;
              setErr({ value: true, flag: "minOneHour" });
            }
          } else {
            error = true;
            setErr({ value: true, flag: "minOneHour" });
          }
          return error;
        });
      } else {
        if (!comments.trim()) {
          error = error ? error : true;
          setErr({ value: true, flag: "comments" });
        }
      }
    });
    return error;
  };
  const validateUnavailableForm = (state) => {
    let error = false;
    if (!userPanelCompetencyId) error = error ? error : true;
    let d_period_from = state.startDate;
    let d_period_end = state.endDate;
    let curr_date = format(
      new Date().setDate(new Date().getDate()),
      "yyyy-MM-dd"
    );
    if (d_period_from <= curr_date) {
      setUnavailableErr({ value: true, flag: "startdate" });
      error = true;
    }
    if (d_period_end <= curr_date) {
      setUnavailableErr({ value: true, flag: "enddate" });
      error = true;
    }

    let months = monthDiff(new Date(d_period_from), new Date(d_period_end));
    if (d_period_end < d_period_from || !(months <= 6)) {
      error = true;
    }
    return error;
  };
  const saveAvailability = (e) => {
    e.preventDefault();
    if (!unavailable) {
      if (!validateForm(state?.days)) {
        let newstate = {
          ...state,
          days: state.days?.map((day) => {
            return {
              ...day,
              slotTime: day.slotTime?.map((daySlot) => {
                let { validationId, startTime, endTime } = daySlot;
                startTime =
                  startTime === null
                    ? new Date().setHours(10, 0, 0, 0)
                    : startTime;
                endTime =
                  endTime === null ? new Date().setHours(17, 0, 0, 0) : endTime;
                return {
                  validationId: validationId,
                  startTime: format(startTime, "HH:mm"),
                  endTime: format(endTime, "HH:mm"),
                };
              }),
            };
          }),
        };
        dispatch(
          markAvailability({
            slotAvailability: {
              ...newstate,
              userPanelMappingId: panelId,
              panelCompetencyId: userPanelCompetencyId,
            },
            panelMemberAvailabilityPayload: {
              competencyId: loginInfo.current_role_competency_id,
              roundDesignationIds: !isPanelMember(loginInfo?.current_role)
                ? roundDesignationId
                : [],
              periodFrom: !isPanelMember(loginInfo?.current_role)
                ? new Date(startDateOfWeek).toISOString()
                : new Date(startMonthDate).toISOString(),
              periodTo: !isPanelMember(loginInfo?.current_role)
                ? new Date(endDateOfWeek).toISOString()
                : new Date(endMonthDate).toISOString(),
              pageNumber: activePageNumber,
              roleId: loginInfo.role_id,
              query: !isPanelMember(loginInfo?.current_role) ? searchValue : "",
              slotsProvided: !isPanelMember(loginInfo?.current_role)
                ? slotsProvided
                : SLOT_FILTER_OPTIONS.ALL,
              roundName: !isPanelMember(loginInfo?.current_role)
                ? roundName
                : ROUND_FILTER_OPTIONS.ALL,
              activedesignationId: !isPanelMember(loginInfo?.current_role)
                ? activedesignationId
                : [],
              panelGroupId: !isPanelMember(loginInfo?.current_role)
                ? panelGroupId
                : [],
              subCompetency: !isPanelMember(loginInfo?.current_role)
                ? subCompetency
                : [],
            },
            roleId: loginInfo.role_id,
            copyUserAvailablities,
            dispatch,
          })
        );

        handleClose();
      }
    } else {
      if (!validateUnavailableForm(state)) {
        let panelMemberPayload = {
          competencyId: loginInfo.current_role_competency_id,
          roundDesignationIds: !isPanelMember(loginInfo?.current_role)
            ? roundDesignationId
            : [],
          periodFrom: !isPanelMember(loginInfo?.current_role)
            ? new Date(startDateOfWeek).toISOString()
            : new Date(startMonthDate).toISOString(),
          periodTo: !isPanelMember(loginInfo?.current_role)
            ? new Date(endDateOfWeek).toISOString()
            : new Date(endMonthDate).toISOString(),
          pageNumber: activePageNumber,
          roleId: loginInfo.role_id,
          query: !isPanelMember(loginInfo?.current_role) ? searchValue : "",
          slotsProvided: !isPanelMember(loginInfo?.current_role)
            ? slotsProvided
            : SLOT_FILTER_OPTIONS.ALL,
          roundName: !isPanelMember(loginInfo?.current_role)
            ? roundName
            : ROUND_FILTER_OPTIONS.ALL,
          activedesignationId: !isPanelMember(loginInfo?.current_role)
            ? activedesignationId
            : [],
          panelGroupId: !isPanelMember(loginInfo?.current_role)
            ? panelGroupId
            : [],
          subCompetency: !isPanelMember(loginInfo?.current_role)
            ? subCompetency
            : [],
        };
        const data = {
          id: loggedInUserPanelDetails.panel_id,
          notes: commentNotes,
          periodFrom: format(new Date(state.startDate), "yyyy-MM-dd"),
          periodTo: format(new Date(state.endDate), "yyyy-MM-dd"),
          competencyId: loggedInUserPanelDetails.panel_competency_id,
          roleId: loginInfo.role_id,
          moduleName: MODULE_NAME.AVAILABILITY,
          panelMemberPayload,
          dispatch,
        };
        dispatch(markTempUnavailableUser(data));
        setCommentNotes("");
        handleClose();
      }
    }
  };

  const datePickerAvailability = () => {
    return (
      <>
        <Grid item xs={12} md={12} lg={5.5} xl={5.5}>
          <Typography className={styles.dateLabel}>
            {!unavailable && copyUserAvailablities && `Next Week`} From{" "}
          </Typography>
          <DatePickerComponent
            label=""
            disabled={copyUserAvailablities}
            value={
              copyUserAvailablities
                ? format(
                    startOfWeek(getNextSeventhDay(), {
                      weekStartsOn: 1,
                    }),
                    "yyyy-MM-dd"
                  )
                : state.startDate
            }
            handleChange={(newValue) => {
              const formattedStartDate = dayjs(newValue).format("YYYY-MM-DD");
              const formattedEndDate = dayjs(newValue.add(13, "day")).format(
                "YYYY-MM-DD"
              );
              dispatch(setStartDate(formattedStartDate));
              dispatch(setEndDate(formattedEndDate));
            }}
            disablePast={true}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={5.5} xl={5.5}>
          <Typography className={styles.dateLabel}>
            {!unavailable && copyUserAvailablities && `Next Week`} To
          </Typography>
          <DatePickerComponent
            label=""
            min={format(new Date(), "yyyy-MM-dd")}
            max={format(new Date().setFullYear(2050, 12, 31), "yyyy-MM-dd")}
            disabled={copyUserAvailablities}
            value={
              copyUserAvailablities
                ? format(
                    endOfWeek(getNextSeventhDay(), {
                      weekStartsOn: 1,
                    }),
                    "yyyy-MM-dd"
                  )
                : state.endDate
            }
            handleChange={(newValue) => {
              const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
              dispatch(setEndDate(formattedDate));
            }}
            disablePast={true}
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isMarkAvailabilityModalOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Slide
          direction="left"
          in={isMarkAvailabilityModalOpen}
          mountOnEnter
          unmountOnExit
        >
          <Box role="presentation" className={styles.markAvailabilityContainer}>
            <Box mt={2} pt={6} className={styles.daysWrap} >
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                pt={2}
              >
                <Typography className={styles.heading} pl={2} pt={0.5}>
                  Mark Availability
                </Typography>
                <IconButton onClick={(e) => handleClose(e)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              {!copyUserAvailablities && (
                <Grid
                  container
                  m={0}
                  pr={2}
                  pl={3}
                  className={styles.hideVerticalStyle}
                >
                  <Grid item display={"flex"} alignItems={"center"}>
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 0.5 }} />}
                      label=<Typography className={styles.unavailableText}>
                        Mark Unavailable
                      </Typography>
                      className={styles.switchStyle}
                      disableTypography
                      checked={unavailable}
                      onChange={(e) => setUnAvailable(!unavailable)}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                marginLeft={2}
                marginTop={1}
                spacing={1}
                className={styles.hideVerticalStyle}
              >
                <Grid container>
                  <Grid item xs={12}>
                    {!loading && (
                      <>
                        {!unavailable && (
                          <Grid item xs={11} md={11} lg={11} xl={11}>
                            {err.value && err.flag === "minOneHour" && (
                              <Alert
                                severity="error"
                                className={styles.alertstyle}
                              >
                                Please enter times in Start Time and End Time
                                respectively with a minimum 1-hour Gap.
                              </Alert>
                            )}
                            {err.value && err.flag === "startdate" && (
                              <Alert
                                severity="error"
                                className={styles.alertstyle}
                              >
                                Start Date should be greater than the Current
                                Date.
                              </Alert>
                            )}
                            {err.value && err.flag === "enddate" && (
                              <Alert
                                severity="error"
                                className={styles.alertstyle}
                              >
                                End Date should be greater than or equal to
                                Start Date.
                              </Alert>
                            )}
                            {err.value && err.flag === "comments" && (
                              <Alert
                                severity="error"
                                className={styles.alertstyle}
                              >
                                Please fill in the reason.
                              </Alert>
                            )}
                          </Grid>
                        )}
                        {unavailable && (
                          <Grid item xs={11} md={11} lg={11} xl={11}>
                            {unavailableErr.value &&
                              unavailableErr.flag === "startdate" && (
                                <Alert
                                  severity="error"
                                  className={styles.alertstyle}
                                >
                                  Start Date should be greater than the Current
                                  Date.
                                </Alert>
                              )}
                            {unavailableErr.value &&
                              unavailableErr.flag === "enddate" && (
                                <Alert
                                  severity="error"
                                  className={styles.alertstyle}
                                >
                                  End Date should be greater than or equal to
                                  Start Date.
                                </Alert>
                              )}
                          </Grid>
                        )}

                        {showCopyUserAvailablityCheckbox && !unavailable && (
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={copyUserAvailablities}
                                  onChange={(e) => {
                                    setCopyUserAvailablities(e.target.checked);
                                    setErr({ value: false });
                                  }}
                                />
                              }
                              label=<Typography className={styles.weekdaysText}>
                                Do You Want To Copy Current Week Availabilities
                                Into Next Week ?
                              </Typography>
                            />
                          </Grid>
                        )}

                        <Grid container spacing={2} marginBottom={2}>
                          {datePickerAvailability()}
                        </Grid>
                        {/* DaySlotTimeView components */}
                        {!copyUserAvailablities && !unavailable ? (
                          <Grid container>
                            {Object.values(WEEK_DAYS)?.map(
                              (weekDay, index) =>
                                weekDay.day !== "Saturday" && (
                                  <Grid item lg={11}>
                                    <DaySlotTimeView
                                      key={weekDay.day}
                                      dayName={weekDay.day}
                                      state={state}
                                      index={index}
                                    />
                                  </Grid>
                                )
                            )}
                          </Grid>
                        ) : (
                          <></>
                        )}
                        {/* Warning Alert */}
                        {copyUserAvailablities && !unavailable && (
                          <Grid
                            item
                            xs={11}
                            md={11}
                            lg={11}
                            xl={11}
                            style={{ marginTop: "2rem" }}
                          >
                            <Alert severity="warning">
                              <AlertTitle style={{ fontWeight: "bold" }}>
                                Warning !!!
                              </AlertTitle>
                              <Typography>
                                This operation will override the existing slots
                                provided for the next week. There will be no
                                impact on the slots already booked.
                              </Typography>
                              <hr />
                              <strong>
                                Are you sure you want to continue ?
                              </strong>
                            </Alert>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {unavailable && (
                <Grid
                  container
                  spacing={1}
                  className={styles.hideVerticalStyle}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    marginLeft={2}
                    marginRight={2}
                  >
                    <TextField
                      fullWidth
                      placeholder="Reason for Unavailability"
                      label="Reason for Unavailability"
                      className={styles.timeInput}
                      multiline
                      rows={4}
                      onChange={(e) => setCommentNotes(e.target.value)}
                      value={commentNotes}
                      style={{ width: "100%" }}
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box mr={2} marginTop={2}>
              <Grid container marginLeft={1} spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  marginRight={1.5}
                  marginBottom={2}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    className={
                      unavailable &&
                      (!commentNotes || /^\s*$/.test(commentNotes))
                        ? `${styles.saveBtnDisable}`
                        : `${styles.saveBtn}`
                    }
                    disabled={
                      unavailable &&
                      (!commentNotes || /^\s*$/.test(commentNotes))
                    }
                    onClick={(e) => saveAvailability(e)}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    disableElevation
                  >
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Slide>
      </SwipeableDrawer>
    </>
  );
};

export default MarkAvailability;
