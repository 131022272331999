import React from "react";
import { useDispatch } from "react-redux";

import AutoCompleteComponent from "../../../../components/AutoComplete";
import DriveStatusFilter from "./DriveStatusFilter";
import DatePickerComponent from "../../../../components/DatePickerComponent";

import {
  applyFilterStatus,
  changePositionFilter,
  fetchWeekendDriveDetailsByCompetency,
  fromDateFilter,
  resetDriveFilterStatus,
  toDateFilter,
} from "../../../../redux/features/WeekendDrive/weekendDriveSlice";

import {
  Box,
  Typography,
  SwipeableDrawer,
  Slide,
  Grid,
  Divider,
  Button,
  IconButton,
} from "@mui/material";

import CloseIcon from "../../../../assets/icons/close-icon.svg";

import { format } from "date-fns";

import {
  INTERVIEW_STATUS_CHECK,
  SORT_TYPE_CHECK_VALUE,
  SORT_ORDER,
} from "../../../../constants";

import styles from "../style.module.scss";

const FilterList = ({
  loginInfo,
  activePageNumber,
  setIsFilterApplied,
  filterIcon,
  setFilterIcon,
  onChangeFilterStatus,
  setActiveDesignationId,
  finalFilterStatus,
  activeDesignations,
  setFinalFilterStatus,
  setFilterStatus,
  activedesignationId,
  periodFrom,
  setPeriodFrom,
  periodTo,
  setPeriodTo,
  sortBy,
  positionFilter,
  driveFilterStatus,
  periodFromState,
  periodToState,
}) => {
  const dispatch = useDispatch();

  const toggleDrawer = (newOpen) => () => {
    setFilterIcon(newOpen);
  };
  const handleEligibilityDesignationChange = (event) => {
    setActiveDesignationId(event);
    dispatch(changePositionFilter(event));
  };
  const handlePeriodFrom = (event) => {
    setPeriodFrom(event);
    dispatch(fromDateFilter(event));
  };
  const handlePeriodTo = (event) => {
    setPeriodTo(event);
    dispatch(toDateFilter(event));
  };
  const handleClose = (e) => {
    setFilterIcon(false);
  };
  const resetWeekendDriveFilter = () => {
    let startdate = format(
      new Date().setMonth(new Date().getMonth() - 6),
      "yyyy-MM-dd"
    );
    let enddate = format(
      new Date().setMonth(new Date().getMonth() + 6),
      "yyyy-MM-dd"
    );

    dispatch(
      fetchWeekendDriveDetailsByCompetency({
        roleId: loginInfo.role_id,
        competencyId: loginInfo.current_role_competency_id,
        driveStatus: INTERVIEW_STATUS_CHECK.SCHEDULED,
        pageNumber: activePageNumber,
        isPaginated: true,
        designationIds: [],
        periodFrom: startdate,
        periodTo: enddate,
        sortType: SORT_TYPE_CHECK_VALUE.DATE_OF_DRIVE,
        sortOrder: SORT_ORDER.DESCENDING,
      })
    );
    dispatch(resetDriveFilterStatus());
    setFilterStatus(INTERVIEW_STATUS_CHECK.SCHEDULED);
    setFinalFilterStatus(INTERVIEW_STATUS_CHECK.SCHEDULED);
    setIsFilterApplied(false);
    setActiveDesignationId([]);
    setPeriodFrom(startdate);
    setPeriodTo(enddate);
  };
  const applyFilter = () => {
    dispatch(
      fetchWeekendDriveDetailsByCompetency({
        roleId: loginInfo.role_id,
        competencyId: loginInfo.current_role_competency_id,
        driveStatus: finalFilterStatus,
        pageNumber: activePageNumber,
        isPaginated: true,
        designationIds: activedesignationId,
        periodFrom: periodFrom,
        periodTo: periodTo,
        sortType: sortBy.sortType,
        sortOrder: sortBy.sortOrder,
      })
    );
    dispatch(applyFilterStatus());
    setFilterStatus(finalFilterStatus);
    setIsFilterApplied(true);
    handleClose();
  };

  const Positions = () => {
    return (
      <Grid item xs={12} lg={12}>
        <AutoCompleteComponent
          placeholder={"Positions"}
          label={"Select Positions"}
          options={activeDesignations}
          onChange={handleEligibilityDesignationChange}
          selectedValue={positionFilter}
        />
      </Grid>
    );
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={filterIcon}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Slide direction="left" in={filterIcon} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: 400,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            overflowX: "hidden",
          }}
          role="presentation"
        >
          <Box mt={2} pt={6}>
            <Grid container>
              <Grid item xs={10} md={10} xl={10} lg={10}>
                <Typography variant="h6" gutterBottom pt={3} pl={3}>
                  Filters
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} xl={2} lg={2} p={2.5}>
                <IconButton onClick={(e) => handleClose(e)}>
                  <img src={CloseIcon} alt="close-icon" />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
            <Grid
              container
              m={0}
              pr={2}
              spacing={3}
              sx={{ flexGrow: 1, overflowY: "hidden" }}
            >
              <Grid item xs={12} md={12} xl={12} lg={12} pr={3}>
                <DriveStatusFilter
                  driveFilterStatus={driveFilterStatus}
                  finalFilterStatus={finalFilterStatus}
                  onChangeFilterStatus={onChangeFilterStatus}
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12} lg={12} pr={3}>
                {Positions()}
              </Grid>
              <Grid item xs={12} md={12} xl={12} lg={12} pr={3}>
                <DatePickerComponent
                  label="Period From"
                  value={periodFromState}
                  handleChange={handlePeriodFrom}
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12} lg={12} pr={3}>
                <DatePickerComponent
                  label="Period To"
                  value={periodToState}
                  handleChange={handlePeriodTo}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid container m={2} spacing={1}>
            <Grid
              item
              lg={5.5}
              xl={5.5}
              xs={5.5}
              md={5.5}
              display="flex"
              alignItems="flex-end"
            >
              <Button
                variant="outlined"
                fullWidth
                size="large"
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={(e) => resetWeekendDriveFilter(e)}
              >
                CLEAR
              </Button>
            </Grid>

            <Grid item lg={5.5} xl={5.5} xs={5.5} md={5.5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={(e) => applyFilter(e)}
                className={styles.searchBtn}
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </SwipeableDrawer>
  );
};

export default FilterList;
