import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "./../../../../components/Spinner";
import { addTag, getTags } from "../../../../redux/features/Tags/tagsSlice";
import { ALPHA_NUMERIC_VALUES } from "../../../../constants/index"

import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import closeIcon from "../../../../assets/icons/close-icon.svg";

import styles from "./style.module.scss";
import theme from "../../../../theme";

const AddNewTag = ({
  setActivePageNumber,
  showAddTagModal,
  setShowAddTagModal,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.tagReducer);
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);

  const [error, setError] = useState(false);
  const [groupName, setGroupName] = useState();
  const [tagDescription, setTagDescription] = useState();

  const handleClose = () => {
    setShowAddTagModal(false);
    setGroupName("");
    setTagDescription("");
    setError(false);
  };
  const handleInputChange = (e) => {
    setGroupName(e.target.value);
    if (e.target.value?.length) setError(false);
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    setError(!groupName);
    setActivePageNumber(1);
    if (groupName) {
      const doesnotHaveCharacters = ALPHA_NUMERIC_VALUES.test(groupName);
      if(!doesnotHaveCharacters){
        await dispatch(
          addTag({
            roleId: loginInfo.role_id,
            groupName,
            competencyId: loginInfo.current_role_competency_id,
            tagDescription,
          })
        );
        await dispatch(
          getTags({
            roleId: loginInfo.role_id,
            competencyId: loginInfo.current_role_competency_id,
            pageNumber: 1,
          })
        );
        setGroupName("");
        setTagDescription("");
        setShowAddTagModal(false);
        setError(false);
      }else{
        setError(true)
      }
      }
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Dialog
        open={showAddTagModal}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: "22rem",
            width: "30rem",
            minHeight: "30vh",
            maxHeight: "100vh",
            zIndex: 1300,
            borderRadius: "10px",
            boxShadow: "0px 6px 30px 0px #OD",
          },
        }}
      >
        <DialogTitle
          color={theme.palette.secondary.main}
          sx={{ padding: "30px 24px 0px" }}
        >
          Add New Tag
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 18,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid container direction={"column"}>
            <Grid item>
              <TextField
                error={error}
                label="Enter Tag Name"
                variant="outlined"
                fullWidth
                value={groupName}
                onChange={(e) => handleInputChange(e)}
                margin="normal"
                required
                helperText={error ? "Tag name can only contain alphanumeric characters" : ""}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Enter Tag Description"
                variant="outlined"
                fullWidth
                value={tagDescription}
                onChange={(e) => setTagDescription(e.target.value)}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 30px" }}>
          <Button
            fullWidth
            disabled={!groupName || /^\s*$/.test(groupName)}
            variant={"contained"}
            onClick={onFormSubmit}
            className={styles.addTagButtonStyle}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            Add Tag
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewTag;
