import React, { useState, useEffect } from "react";

import PairingView from "./PairingView";
import WarningPopup from "./WarningPopup";
import DatePickerComponent from "../../../../components/DatePickerComponent";

import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  INTERVIEW_STATUS_OPTIONS_CREATE_BOOKING_MODAL,
  INTERVIEW_STATUS_OPTIONS_UPDATE_BOOKING_PAIRED_VIEW_MODAL,
  INTERVIEW_MODE,
  INTERVIEW_STATUS_OPTIONS_UPDATE_BOOKING_MODAL,
  CREATE_PAIRING_OPTIONS,
  INTERVIEW_STATUS_CHECK,
} from "../../../../constants/index";

const InterviewDetailsStep = (props) => {
  const {
    isUpdateModalOpen,
    roundId,
    setRoundId,
    rounds,
    interviewStatus,
    setInterviewStatus,
    interviewMode,
    setInterviewMode,
    comments,
    setComments,
    isPairingRoundSelected,
    bookingDate,
    onSetBookingDate,
    startTime,
    endTime,
    setEndTime,
    onSetStartTime,
    isCreatePairingChecked,
    setIsCreatePairingChecked,
    isRoundInPairingIntial,
    setIsRoundInPairingIntial,
    eligiblePanelistForPairing,
    pairingUserId,
    setPairingState,
    slots,
    notavailable,
    notprovided,
    pairedmemberData,
    resetPairingState,
    resetLeaderState,
    bookingIdForPairedRound,
    setBookingIdForPairedRound,
    interviewStatusChange,
    filterUnpairedInterviewersBookingsList,
    setStatesWhenPairingSelected,
    booking: bookingDataForUpdate,
    pairingData: pairingDataEnabled,
    setIsRoundValidationError,
    isRoundValidationError,
    isPairedValidationError,
    setIsPairedValidationError,
    setPairedEmail,
    isRemovePairedInterviewerValidationError,
    setIsRemovePairedInterviewerValidationError,
    removePairingReason,
    setRemovePairingReason,
    isNaOrRsStatusPopupNoClicked,
    disableFieldsForPairedView,
    isDisableMultipleFields,
    clickedBookingInterviewerStatus
  } = props;

  const [interviewOptions, setInterviewOptions] = useState(
    INTERVIEW_STATUS_OPTIONS_CREATE_BOOKING_MODAL
  );

  useEffect(() => {
    if (pairingDataEnabled && Object.keys(pairingDataEnabled)?.length) {
      setInterviewMode(
        pairingDataEnabled?.interview_mode === null
          ? interviewMode
          : pairingDataEnabled?.interview_mode
      );
    } else if (bookingDataForUpdate) {
      setInterviewMode(
        bookingDataForUpdate?.interviewMode === null
          ? interviewMode
          : bookingDataForUpdate?.interviewMode
      );
    }
  }, [pairingDataEnabled, setInterviewMode, bookingDataForUpdate]);
  useEffect(() => {
    if (
      isUpdateModalOpen &&
      bookingDataForUpdate?.interview_status ===
        INTERVIEW_STATUS_CHECK.SCHEDULED
    ) {
      setInterviewOptions(INTERVIEW_STATUS_OPTIONS_UPDATE_BOOKING_MODAL);
    }
    if (
      isUpdateModalOpen &&
      bookingDataForUpdate?.interview_status ===
        INTERVIEW_STATUS_CHECK.TO_BE_SCHEDULED
    ) {
      setInterviewOptions(INTERVIEW_STATUS_OPTIONS_CREATE_BOOKING_MODAL);
    }
    if (
      isUpdateModalOpen &&
      bookingDataForUpdate?.pairedInterviewerId &&
      !bookingDataForUpdate?.isCoreInterviewer &&
      bookingDataForUpdate?.interview_status ===
        INTERVIEW_STATUS_CHECK.SCHEDULED
    ) {
      setInterviewOptions(
        INTERVIEW_STATUS_OPTIONS_UPDATE_BOOKING_PAIRED_VIEW_MODAL
      );
    }
  }, [
    bookingDataForUpdate?.interview_status,
    bookingDataForUpdate?.isCoreInterviewer,
    bookingDataForUpdate?.pairedInterviewerId,
    interviewStatus,
    isUpdateModalOpen,
  ]);

  const handleRoundIdChange = (event) => {
    setRoundId(event.target.value);
    setBookingIdForPairedRound(null);
    resetLeaderState(null);
    resetPairingState(null);
    setIsRoundValidationError(!event.target.value);
  };

  return (
    <>
      <Grid direction={"row"} container spacing={1} marginTop={2} gap={2}>
        <Grid item xs={12}>
          <DatePickerComponent
            label="Booking Date"
            value={bookingDate}
            handleChange={onSetBookingDate}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth={true}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    closeOnSelect={false}
                    label={"Start Time"}
                    value={startTime}
                    onChange={(newValue) => {
                      onSetStartTime(newValue);
                    }}
                    disabled={
                      isPairingRoundSelected ||
                      disableFieldsForPairedView ||
                      isDisableMultipleFields
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth={true}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    closeOnSelect={false}
                    label={"End Time"}
                    value={endTime}
                    onChange={(newValue) => {
                      setEndTime(newValue);
                    }}
                    disabled={
                      isPairingRoundSelected ||
                      disableFieldsForPairedView ||
                      isDisableMultipleFields
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={isRoundValidationError}>
            <InputLabel id="round-id">Round Designation*</InputLabel>
            <Select
              labelId="round-id"
              id="round-id"
              required
              label="Round Designation"
              placeholder="Round Designation"
              value={roundId ? parseInt(roundId) : ""}
              onChange={handleRoundIdChange}
              disabled={isUpdateModalOpen || isDisableMultipleFields}
            >
              {rounds?.length &&
                rounds?.map((round) => (
                  <MenuItem key={round.id} value={parseInt(round.id)}>
                    {round.roundName}
                    {"-"}
                    {round.designationName} {round.isPairing ? "(P)" : ""}
                  </MenuItem>
                ))}
            </Select>
            {isRoundValidationError && (
              <FormHelperText>Round is Required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="interview-status">Interview Status</InputLabel>
            <Select
              labelId="interview-status"
              id="interview-status"
              label={"interview Status"}
              placeholder="Interview Status"
              value={interviewStatus}
              onChange={(event) => {
                setInterviewStatus(event.target.value);
                interviewStatusChange(event.target.value, interviewOptions);
              }}
              disabled={isPairingRoundSelected}
            >
              {Object.entries(interviewOptions).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <PairingView
          isUpdateModalOpen={isUpdateModalOpen}
          booking={bookingDataForUpdate}
          setPairedEmail={setPairedEmail}
          isPairingRoundSelected={isPairingRoundSelected}
          roundId={roundId}
          isRoundInPairingIntial={isRoundInPairingIntial}
          eligiblePanelistForPairing={eligiblePanelistForPairing}
          isPairedValidationError={isPairedValidationError}
          pairingUserId={pairingUserId}
          setPairingState={setPairingState}
          isCreatePairingChecked={isCreatePairingChecked}
          setIsCreatePairingChecked={setIsCreatePairingChecked}
          setIsRoundInPairingIntial={setIsRoundInPairingIntial}
          setIsPairedValidationError={setIsPairedValidationError}
          slots={slots}
          notavailable={notavailable}
          notprovided={notprovided}
          pairedmemberData={pairedmemberData}
          filterUnpairedInterviewersBookingsList={
            filterUnpairedInterviewersBookingsList
          }
          bookingIdForPairedRound={bookingIdForPairedRound}
          setStatesWhenPairingSelected={setStatesWhenPairingSelected}
          resetPairingState={resetPairingState}
          isRemovePairedInterviewerValidationError={
            isRemovePairedInterviewerValidationError
          }
          setIsRemovePairedInterviewerValidationError={
            setIsRemovePairedInterviewerValidationError
          }
          removePairingReason={removePairingReason}
          setRemovePairingReason={setRemovePairingReason}
          interviewStatus={interviewStatus}
          isDisableMultipleFields={isDisableMultipleFields}
        />
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="interview-mode">Interview Mode</InputLabel>
            <Select
              labelId="interview-mode"
              label={"Interview Mode"}
              id="interview-mode"
              placeholder="Interview Mode"
              value={interviewMode}
              onChange={(event) => setInterviewMode(event.target.value)}
              disabled={
                isPairingRoundSelected ||
                disableFieldsForPairedView ||
                isDisableMultipleFields
              }
            >
              {Object.entries(INTERVIEW_MODE).map(([key, item]) => (
                <MenuItem key={key} value={key}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="comment"
            label="Comments"
            multiline
            rows={2}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            disabled={isPairingRoundSelected}
          />
        </Grid>
      </Grid>
      {isCreatePairingChecked === CREATE_PAIRING_OPTIONS.REMOVE_PAIRING &&
        Boolean(removePairingReason?.length) &&
        !isRoundInPairingIntial && (
          <WarningPopup
            WarningMessage={`This action Will remove paired interviewer from booking. Click
              "Next" to continue.`}
          />
        )}
      {!bookingDataForUpdate?.isCoreInterviewer &&
        bookingDataForUpdate?.pairedInterviewerId &&
        isNaOrRsStatusPopupNoClicked && (
          <WarningPopup
            WarningMessage={`This action Will remove paired interviewer from booking. Click
              "Next" to continue.`}
          />
        )}
      {bookingDataForUpdate?.isCoreInterviewer &&
        interviewStatus === INTERVIEW_STATUS_CHECK.INVALID && (
          <WarningPopup
            WarningMessage={`This action Will remove booking. Click
              "Next" to continue.`}
          />
        )}
      {!bookingDataForUpdate?.isCoreInterviewer &&
        bookingDataForUpdate?.pairedInterviewerId &&
        interviewStatus === INTERVIEW_STATUS_CHECK.INVALID && (
          <WarningPopup
            WarningMessage={`This action Will remove paired interviewer from booking. Click
              "Next" to continue.`}
          />
        )}
      {
        clickedBookingInterviewerStatus ?
          (
            <>
              <WarningPopup  WarningMessage={'Interviewer Not Available'} showBookingNa={true}></WarningPopup>
            </>
          ) : (null)
      }
    </>
  );
};

export default InterviewDetailsStep;
