import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SingleSelectComponent from "../../../components/SingleSelect/SingleSelectComponent";

import {
  createDocument,
  editDocumentByDocumentId,
} from "../../../redux/features/Document/documentSlice";
import { fetchActiveDesignationByCompetencyId } from "../../../redux/features/Roster/rosterSlice";

import {
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import closeIcon from "../../../assets/icons/close-icon.svg";

import styles from "./style.module.scss";
import theme from "../../../theme";

import { ROLE_TYPES, ALPHA_NUMERIC_VALUES } from "../../../constants";

const { isHr, isSpoc, isPanelMember } = ROLE_TYPES;

const AddDocumentForm = ({
  display,
  setDisplay,
  editDocument,
  setEditDocument,
}) => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const designationData = useSelector(
    (state) => state.rosterReducer?.activeDesignation
  );
  const updateDocumentInfo = useSelector(
    (state) => state.documentReducer?.currentDocumentInfo
  );

  const [documentInfo, setDocumentInfo] = useState({
    label: "",
    designationId: "",
    link: "",
  });

  const [error, setError] = useState(false);
  const [labelError, setLabelError] = useState(false); 

  useEffect(() => {
    return () => {
      setDocumentInfo({});
    };
  }, [setDocumentInfo]);
  useEffect(() => {
    if (updateDocumentInfo) {
      setDocumentInfo({
        label: updateDocumentInfo?.label,
        designationId: updateDocumentInfo?.designation_id ? updateDocumentInfo?.designation_id : "0",
        link: updateDocumentInfo?.link,
      });
    }
  }, [updateDocumentInfo, updateDocumentInfo?.label, updateDocumentInfo?.link]);
  useEffect(() => {
    if (
      loginInfo.current_role_competency_id &&
      (isSpoc(loginInfo.current_role) || isHr(loginInfo.current_role))
    ) {
      dispatch(
        fetchActiveDesignationByCompetencyId({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
        })
      );
    }
  }, [loginInfo, dispatch]);

  const handleClose = () => {
    setDisplay(false);
    setEditDocument(false);
    setLabelError(false);
  };
  const onSetOrUpdateDocumentInfo = (label, designationId, link) => {
    let documentData = {
      ...documentInfo,
      label:
        label || (!label?.length && label !== null)
          ? label
          : documentInfo.label,
      link: link || (!link?.length && link !== null) ? link : documentInfo.link,
      designationId:
        designationId !== "Select"
          ? !!parseInt(designationId) ||
            designationId === "0" ||
            (!designationId?.length && designationId !== null)
            ? parseInt(designationId)
            : documentInfo.designationId
          : "",
    };
    setDocumentInfo(documentData);
  };
  const validateFormData = () => {
    let error = false;
    if (!documentInfo) error = error ? error : true;

    const document = Object.values(documentInfo);
    if (!document.length) error = error ? error : true;
    document.forEach((value) => {
      if (value === undefined) error = error ? error : true;
    });
    return error;
  };
  const handleAddButtonClick = () => {
    setDisplay(!display);
    setEditDocument(false);
    setDocumentInfo({ label: "", designationId: "", link: "" });
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const formError = validateFormData();
    setError(formError);
    const doesnotHaveCharacters = ALPHA_NUMERIC_VALUES.test(documentInfo?.label);
    if(!doesnotHaveCharacters){
      if (!formError && !editDocument) {
        dispatch(
          createDocument({
            competencyId: loginInfo.current_role_competency_id,
            roleId: loginInfo.role_id,
            documentInfo,
          })
        );
        setDocumentInfo({
          label: "",
          designationId: "",
          link: "",
        });
        setError(false);
        setDisplay(false);
      } else if (!formError && editDocument) {
        dispatch(
          editDocumentByDocumentId({
            documentId: updateDocumentInfo?.id,
            roleId: loginInfo.role_id,
            label: documentInfo?.label,
            link: documentInfo?.link,
            competencyId: loginInfo.current_role_competency_id,
          })
        );
        setError(false);
        setDisplay(false);
        setEditDocument(false);
        setDocumentInfo({
          label: "",
          designationId: "",
          link: "",
        });
      }
      setLabelError(false);
    }else{
      setLabelError(true);
    }
  };
  let designationsArray = [];
  for (const designation in designationData) {
    designationsArray.push(
      designationData[designation].name +
        ":" +
        designation +
        ":" +
        designationData[designation].abbreviatedName
    );
  }
  designationsArray = designationsArray.sort();
  let sortedDesignationList = designationsArray.reduce((acc, item) => {
    const label = item.split(":")[0];
    const value = item.split(":")[1];
    acc.push({
      label: label,
      value: value,
    });
    return acc;
  }, []);
  sortedDesignationList.push({ label: "Others", value: "0" });
  const dialogTitle = editDocument ? "Update Link" : "Add New Link";
  const saveButtonTitle = editDocument ? "Update" : "Save";
  const buttonVisibility =
    !documentInfo.label ||
    !documentInfo.link ||
    /^\s*$/.test(documentInfo.label) ||
    /^\s*$/.test(documentInfo.link);
  const validUrlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  const isValidUrl = validUrlRegex.test(documentInfo.link);

  return (
    <>
      <Grid item xs container alignItems="center" mb={2}>
        <Grid item md={3} lg={4}>
          <Typography
            variant="h5"
            display="inline-flex"
            color={theme.palette.secondary.main}
            fontSize={20}
            className={"heading"}
          >
            Document Repository
          </Typography>
        </Grid>
        {!isPanelMember(loginInfo.current_role) && (
          <Grid
            item
            md={9}
            lg={8}
            display={"flex"}
            justifyContent="flex-end"
            spacing={2}
          >
            {!isHr(loginInfo.current_role) ? (
              <Tooltip title="Add New Link" arrow={true} placement="top">
                <Box
                  className={styles.addTagStyle}
                  component={"button"}
                  onClick={() => handleAddButtonClick()}
                >
                  <AddIcon />
                </Box>
              </Tooltip>
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Grid>
      <>
        {!isPanelMember(loginInfo.current_role) && display && (
          <Dialog
            open={setDisplay}
            onClose={handleClose}
            PaperProps={{
              style: {
                height: "25rem",
                width: "30rem",
                minHeight: "30vh",
                maxHeight: "100vh",
                zIndex: 1300,
                borderRadius: "10px",
                boxShadow: "0px 6px 30px 0px #OD",
              },
            }}
          >
            <DialogTitle
              color={theme.palette.secondary.main}
              sx={{ padding: "30px 24px 0px" }}
            >
              {dialogTitle}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 18,
                top: 30,
                color: (theme) => theme.palette.grey[500],
              }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              <img src={closeIcon} alt="close-icon" />
            </IconButton>
            <DialogContent>
              <DialogContentText></DialogContentText>
              <Grid container direction={"column"} gap={3}>
                <Grid item>
                  <TextField
                    error={labelError || error}
                    label="Enter Label"
                    variant="outlined"
                    fullWidth
                    value={documentInfo?.label}
                    onChange={(e) =>
                      onSetOrUpdateDocumentInfo(e.target.value, null, null)
                    }
                    InputLabelProps={{
                      style: {
                        color: "#948B9E",
                      },
                    }}
                    helperText={labelError ? 'Label can only contain alphanumeric characters' : ''}
                    required
                  />
                </Grid>
                <Grid item>
                  <SingleSelectComponent
                    options={sortedDesignationList}
                    onChange={(e) => onSetOrUpdateDocumentInfo(null, e.value, null)
                    }
                    label={"Select Designation*"}
                    value={documentInfo?.designationId}
                    disabled={editDocument}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    error={error}
                    label="Enter Document Link"
                    variant="outlined"
                    fullWidth
                    value={documentInfo?.link}
                    helperText={
                      isValidUrl || (documentInfo.link ?? "").trim() === ""
                        ? ""
                        : "Provide a valid url"
                    }
                    onChange={(e) =>
                      onSetOrUpdateDocumentInfo(null, null, e.target.value)
                    }
                    InputLabelProps={{
                      style: {
                        color: "#948B9E",
                      },
                    }}
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: "0px 24px 30px" }}>
              <Button
                fullWidth
                disabled={buttonVisibility || !isValidUrl}
                variant={"contained"}
                onClick={onFormSubmit}
                className={styles.addTagButtonStyle}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disableElevation
              >
                {saveButtonTitle}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    </>
  );
};

export default AddDocumentForm;
