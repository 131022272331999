import React from "react";

import { Grid, Typography } from "@mui/material";

import conflictIcon from "../../../../assets/icons/conflict-icon.svg";

import styles from "./style.module.scss";

const BookingTimeConflicting = () => {
  return (
    <Grid className={styles.timeConflictingContainer}>
      <Grid xs={12} item className={styles.timeConflictingStyle}>
        <img src={conflictIcon} alt="conflict-icon" />
        <Typography
          variant="h5"
          color="initial"
          className={styles.popupScreenHeading}
        >
          Booking time conflicting
        </Typography>
        <Typography className={styles.popupScreenSubHeading}>
          Are you sure you want to book for the same time ?
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BookingTimeConflicting;
