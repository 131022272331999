import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logIn } from "../../redux/features/Authentication/authSlice";

import { redirectToPage } from "../../utils/app-utils";

const LoginCallback = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(logIn(token));
    } else {
      redirectToPage("/login");
    }
  }, [dispatch, token]);

  return <></>;
};

export default LoginCallback;
