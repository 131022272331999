import React, { useState } from "react";

import BookingModal from "./BookingModal/index";
import BookingOverlayTemplate from "./BookingOverlayTemplate";
import StayOnHoverOverlay from "../../../components/StayOnHoverOverlay/index";

import { Grid, Chip, Tooltip, Box } from "@mui/material";

import AddIcon from "../../../assets/icons/ad-icon.svg";

import {
  extractTime,
  validateDate,
  validateCurrentWeekAndDate,
  extractTimeWithoutAMPM,
} from "../../../utils/date-utils";
import { colorSet, showBadge } from "../../../utils/app-utils";
import {
  ROLE_TYPES,
  INTERVIEW_STATUS_OPTIONS,
  INTERVIEW_STATUS_OPTIONS_SHORTFORM,
  INTERVIEW_STATUS_CHECK
} from "../../../constants";

import styles from "./style.module.scss";
import WarningPopup from "./BookingModal/WarningPopup";

const { isHr } = ROLE_TYPES;

const BookingOverlay = (props) => {
  const {
    booking,
    slotDetailsOfTheDay,
    loginInfo,
    weekDate,
    saveBookingForAvailableSlot,
    rounds,
    user,
    isBookingModalClicked,
    setIsBookingModalClicked,
    panelMemberAvailabilityPayload,
    indexMap,
    length,
    email,
    stateToogle,
    bookingOfTheDay,
  } = props;

  const [showBookingModal, setShowBookingModal] = useState(false);
  const [addBooking, setaddBooking] = useState(true);
  const [ clickedBookingInterviewerStatus , setClickedBookingInterviewerStatus] = useState(false);
  const [ slotTimeForModal , setSlotTimeForModal ] = useState({});

  const badgeStyle = {
    backgroundColor: `${
      colorSet(INTERVIEW_STATUS_OPTIONS[booking.interview_status], booking)
        ?.backgroundColor
    }`,
    color: `${
      colorSet(INTERVIEW_STATUS_OPTIONS[booking.interview_status], booking)
        ?.color
    }`,
    display:
      !stateToogle.compact &&
      `${showBadge(
        INTERVIEW_STATUS_OPTIONS[booking.interview_status],
        booking
      )}`,
  };
  const isLoggedInUserIsBookingCreator = () => {
    return parseInt(loginInfo.id) === parseInt(booking.assignedByUserId);
  };
  const onShowModal = (e, isNewBooking = false) => {
    if (isHr(loginInfo.current_role)) {
      if (isNewBooking && validateDate(weekDate)) {
        setShowBookingModal(true);
      } else {
        if (
          isLoggedInUserIsBookingCreator() &&
          !booking.isInterviewerDeleted &&
          (INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
            INTERVIEW_STATUS_OPTIONS.SCHEDULED ||
            INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
              INTERVIEW_STATUS_OPTIONS.TO_BE_SCHEDULED)
        ) {
          setShowBookingModal(true);
          setIsBookingModalClicked(true);
        }
      }
    }
  };
  const doesScheduledBookingExists = (bookingOfTheDay) => {
    const statusToBeChecked = ["SCHEDULED", "TO_BE_SCHEDULED"];
    const scheduledBookings = bookingOfTheDay.filter(
      (item) =>
        statusToBeChecked.indexOf(item.interview_status) > -1 &&
        !item.isInterviewerDeleted
    );
    return !!scheduledBookings?.length || false;
  };
  const onHideModal = () => {
    if (isHr(loginInfo.current_role)) {
      setShowBookingModal(false);
      setIsBookingModalClicked(false);
    }
  };
  const handleClickBadge = (e, status) => {
    if(status == true){
      setClickedBookingInterviewerStatus(true);
    }else{
      setClickedBookingInterviewerStatus(false);
    }
    setaddBooking(false);
    onShowModal(e, true);
  };
  const doesActiveBookingExists = () => {
    const statusToBeChecked = ["SCHEDULED", "TO_BE_SCHEDULED", "COMPLETED"];
    const activeBookings = bookingOfTheDay.filter(
      (item) =>
        statusToBeChecked.indexOf(item.interview_status) > -1 &&
        !item.isInterviewerDeleted
    );

    return !!activeBookings?.length || false;
  };

  const containsStatus = bookingOfTheDay.some(booking =>
    booking.interviewer_status === INTERVIEW_STATUS_CHECK.INTERVIEWER_NOT_AVAILABLE
  );

  return (
    <>
      {!isBookingModalClicked && (
        <>
          <StayOnHoverOverlay
            title={
              <BookingOverlayTemplate
                bookingSlot={{
                  interview_status: booking.interview_status,
                  interviewMode: booking.interviewMode,
                  calendarEventId: booking.calendarEventId,
                  bookingForRound: booking.bookingForRound,
                  roundDesignationName: booking.roundDesignationName,
                  pairedInterviewerId: booking.pairedInterviewerId,
                  pairedInterviewerEmail: booking.pairedInterviewerEmail,
                  startTime: booking.startTime,
                  endTime: booking.endTime,
                  candidateName: booking.candidateName,
                  candidateMobileNumber: booking.candidateMobileNumber,
                  candidateEmail: booking.candidateEmail,
                  bookingAssignedBy: booking.bookingAssignedBy,
                  slotDetailsOfTheDay: slotDetailsOfTheDay,
                  notes: booking.notes,
                  isInterviewerDeleted: booking.isInterviewerDeleted,
                  interviewer_status: booking.interviewer_status,
                }}
                user={user}
              />
            }
          >
            <Grid
              direction={"column"}
              spacing={1}
              gap={0.3}
              margin={0.5}
              sx={{
                display:
                  !stateToogle.compact &&
                  `${showBadge(
                    INTERVIEW_STATUS_OPTIONS[booking.interview_status],
                    booking
                  )}`,
              }}
            >
              <Grid item xs={12}>
                <Chip
                  className={
                    isHr(loginInfo.current_role) &&
                    validateCurrentWeekAndDate(weekDate)
                      ? `cursorPointer ${styles.bookingsBadgeStyle}`
                      : styles.bookingsBadgeStyle
                  }
                  sx={badgeStyle}
                  onClick={(e) => {
                    setaddBooking(true);
                    onShowModal(e);
                  }}
                  label={
                    (INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
                      INTERVIEW_STATUS_OPTIONS.SCHEDULED ||
                      INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
                        INTERVIEW_STATUS_OPTIONS.TO_BE_SCHEDULED) &&
                    !booking.isInterviewerDeleted
                      ? `${extractTimeWithoutAMPM(
                          booking.startTime
                        )} - ${extractTime(booking.endTime)}`
                      : booking.isInterviewerDeleted
                      ? `${
                          INTERVIEW_STATUS_OPTIONS_SHORTFORM[
                            booking.interviewer_status
                          ]
                        }`
                      : `${
                          INTERVIEW_STATUS_OPTIONS_SHORTFORM[
                            booking.interview_status
                          ]
                        }`
                  }
                ></Chip>
              </Grid>
            </Grid>
          </StayOnHoverOverlay>
          <>

            {isHr(loginInfo?.current_role) ? (
              <>
              {
                containsStatus ? (
                  <>
                                <StayOnHoverOverlay
                title={
                  <WarningPopup
                    WarningMessage={'Interviewer Not Available'}
                    showBookingNa={true}
                  />
                }
              >
                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !slotDetailsOfTheDay?.length && (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid item xs={12}>
                        <Chip
                          className={
                            isHr(loginInfo.current_role) && validateDate(weekDate)
                              ? `${styles.notProvidedSlotStyle} cursorPointer`
                              : `${styles.notProvidedSlotStyle}`
                          }
                          onClick={(e) => {
                            handleClickBadge(e, containsStatus);
                          }}
                          label="Not Provided"
                        ></Chip>
                      </Grid>
                    </Grid>
                  )}

                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !!slotDetailsOfTheDay?.length &&
                  !slotDetailsOfTheDay?.[0]?.isAvailable && (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid xs={12}>
                        <Tooltip
                          title={
                            ` {${slotDetailsOfTheDay[0]?.comments}`
                              ? `${slotDetailsOfTheDay[0]?.comments}`
                              : "Not given"
                          }
                          arrow={true}
                          placement="top"
                        >
                          <Chip
                            className={
                              isHr(loginInfo.current_role) && validateDate(weekDate)
                                ? `${styles.notAvailableStyle} cursorPointer`
                                : `${styles.notAvailableStyle}`
                            }
                            onClick={(e) => {
                              handleClickBadge(e, containsStatus);
                            }}
                            label={"Not Available"}
                          >
                            Not Available
                          </Chip>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}

                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !!slotDetailsOfTheDay?.length &&
                  slotDetailsOfTheDay?.[0]?.isAvailable &&
                  slotDetailsOfTheDay[0]?.slotTime.map((slot) => (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid item xs={12}>
                        <Chip
                          className={
                            isHr(loginInfo.current_role) && validateDate(weekDate)
                              ? `${styles.availableSlotStyle} cursorPointer`
                              : `${styles.availableSlotStyle}`
                          }
                          onClick={(e) => {
                            setSlotTimeForModal({ startTime: slot.startTime, endTime: slot.endTime })
                            handleClickBadge(e, containsStatus);
                          }}
                          label={`${extractTimeWithoutAMPM(slot.startTime)} 
                        -
                        ${extractTime(slot.endTime)}`}
                        ></Chip>
                      </Grid>
                    </Grid>
                  ))}
              </StayOnHoverOverlay>
                  </>) : (
                  <>
                                  {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !slotDetailsOfTheDay?.length && (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid item xs={12}>
                        <Chip
                          className={
                            isHr(loginInfo.current_role) && validateDate(weekDate)
                              ? `${styles.notProvidedSlotStyle} cursorPointer`
                              : `${styles.notProvidedSlotStyle}`
                          }
                          onClick={(e) => {
                            handleClickBadge(e, containsStatus);
                          }}
                          label="Not Provided"
                        ></Chip>
                      </Grid>
                    </Grid>
                  )}

                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !!slotDetailsOfTheDay?.length &&
                  !slotDetailsOfTheDay?.[0]?.isAvailable && (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid xs={12}>
                        <Tooltip
                          title={
                            ` {${slotDetailsOfTheDay[0]?.comments}`
                              ? `${slotDetailsOfTheDay[0]?.comments}`
                              : "Not given"
                          }
                          arrow={true}
                          placement="top"
                        >
                          <Chip
                            className={
                              isHr(loginInfo.current_role) && validateDate(weekDate)
                                ? `${styles.notAvailableStyle} cursorPointer`
                                : `${styles.notAvailableStyle}`
                            }
                            onClick={(e) => {
                              handleClickBadge(e, containsStatus);
                            }}
                            label={"Not Available"}
                          >
                            Not Available
                          </Chip>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}

                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !!slotDetailsOfTheDay?.length &&
                  slotDetailsOfTheDay?.[0]?.isAvailable &&
                  slotDetailsOfTheDay[0]?.slotTime.map((slot) => (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid item xs={12}>
                        <Chip
                          className={
                            isHr(loginInfo.current_role) && validateDate(weekDate)
                              ? `${styles.availableSlotStyle} cursorPointer`
                              : `${styles.availableSlotStyle}`
                          }
                          onClick={(e) => {
                            setSlotTimeForModal({ startTime: slot.startTime, endTime: slot.endTime })
                            handleClickBadge(e, containsStatus);
                          }}
                          label={`${extractTimeWithoutAMPM(slot.startTime)} 
                        -
                        ${extractTime(slot.endTime)}`}
                        ></Chip>
                      </Grid>
                    </Grid>
                  ))}
                  </>
                  )
              }
              </>
            ) : (
              <>
                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !slotDetailsOfTheDay?.length && (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid item xs={12}>
                        <Chip
                          className={
                            isHr(loginInfo.current_role) && validateDate(weekDate)
                              ? `${styles.notProvidedSlotStyle} cursorPointer`
                              : `${styles.notProvidedSlotStyle}`
                          }
                          onClick={(e) => {
                            handleClickBadge(e, containsStatus);
                          }}
                          label="Not Provided"
                        ></Chip>
                      </Grid>
                    </Grid>
                  )}

                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !!slotDetailsOfTheDay?.length &&
                  !slotDetailsOfTheDay?.[0]?.isAvailable && (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid xs={12}>
                        <Tooltip
                          title={
                            ` {${slotDetailsOfTheDay[0]?.comments}`
                              ? `${slotDetailsOfTheDay[0]?.comments}`
                              : "Not given"
                          }
                          arrow={true}
                          placement="top"
                        >
                          <Chip
                            className={
                              isHr(loginInfo.current_role) && validateDate(weekDate)
                                ? `${styles.notAvailableStyle} cursorPointer`
                                : `${styles.notAvailableStyle}`
                            }
                            onClick={(e) => {
                              handleClickBadge(e, containsStatus);
                            }}
                            label={"Not Available"}
                          >
                            Not Available
                          </Chip>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}

                {!stateToogle?.compact &&
                  length - 1 === indexMap &&
                  !doesActiveBookingExists() &&
                  !!slotDetailsOfTheDay?.length &&
                  slotDetailsOfTheDay?.[0]?.isAvailable &&
                  slotDetailsOfTheDay[0]?.slotTime.map((slot) => (
                    <Grid direction={"column"} spacing={1} gap={0.3} margin={0.5}>
                      <Grid item xs={12}>
                        <Chip
                          className={
                            isHr(loginInfo.current_role) && validateDate(weekDate)
                              ? `${styles.availableSlotStyle} cursorPointer`
                              : `${styles.availableSlotStyle}`
                          }
                          onClick={(e) => {
                            setSlotTimeForModal({ startTime: slot.startTime, endTime: slot.endTime })
                            handleClickBadge(e, containsStatus);
                          }}
                          label={`${extractTimeWithoutAMPM(slot.startTime)} 
                        -
                        ${extractTime(slot.endTime)}`}
                        ></Chip>
                      </Grid>
                    </Grid>
                  ))}
              </>
            )}

          </>
        </>
      )}

      <>
        {isBookingModalClicked && (
          <Grid
            direction={"column"}
            spacing={1}
            gap={0.3}
            margin={0.5}
            sx={{
              display:
                !stateToogle.compact &&
                `${showBadge(
                  INTERVIEW_STATUS_OPTIONS[booking.interview_status],
                  booking
                )}`,
            }}
          >
            <Grid xs={12}>
              <Chip
                className={
                  isHr(loginInfo.current_role) &&
                  validateCurrentWeekAndDate(weekDate)
                    ? `cursorPointer ${styles.bookingsBadgeStyle}`
                    : styles.bookingsBadgeStyle
                }
                sx={badgeStyle}
                onClick={(e) => onShowModal(e)}
                label={
                  (INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
                    INTERVIEW_STATUS_OPTIONS.SCHEDULED ||
                    INTERVIEW_STATUS_OPTIONS[booking.interview_status] ===
                      INTERVIEW_STATUS_OPTIONS.TO_BE_SCHEDULED) &&
                  !booking.isInterviewerDeleted
                    ? `${extractTimeWithoutAMPM(
                        booking.startTime
                      )} - ${extractTime(booking.endTime)}`
                    : booking?.isInterviewerDeleted
                    ? `${
                        INTERVIEW_STATUS_OPTIONS_SHORTFORM[
                          booking.interviewer_status
                        ]
                      }`
                    : `${
                        INTERVIEW_STATUS_OPTIONS_SHORTFORM[
                          booking.interview_status
                        ]
                      }`
                }
              ></Chip>
            </Grid>
          </Grid>
        )}
      </>
      <>
        {!stateToogle.compact
          ? validateDate(weekDate) &&
            isHr(loginInfo.current_role) &&
            length - 1 === indexMap &&
            doesScheduledBookingExists(bookingOfTheDay) && (
              <Grid
                direction={"column"}
                spacing={1}
                gap={0.3}
                margin={0.5}
                display={"flex"}
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  <Box
                    className={`${styles.addBookingStyle} cursorPointer`}
                    onClick={(e) => {
                      setaddBooking(false);
                      onShowModal(e, true);
                    }}
                  >
                    <img src={AddIcon} alt="add-icon" />
                  </Box>
                </Grid>
              </Grid>
            )
          : validateDate(weekDate) &&
            isHr(loginInfo.current_role) &&
            length - 1 === indexMap && (
              <Grid
                direction={"column"}
                spacing={1}
                gap={0.3}
                margin={0.5}
                display={"flex"}
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  <Box
                    className={`${styles.addBookingStyle} cursorPointer`}
                    onClick={(e) => {
                      setaddBooking(false);
                      onShowModal(e, true);
                    }}
                  >
                    <img src={AddIcon} alt="add-icon" />
                  </Box>
                </Grid>
              </Grid>
            )}
      </>

      {validateCurrentWeekAndDate(weekDate) &&
        isHr(loginInfo.current_role) &&
        showBookingModal && (
          <BookingModal
            slotTime={slotTimeForModal}
            clickedBookingInterviewerStatus = {clickedBookingInterviewerStatus}
            showBookingModal={showBookingModal}
            setShowBookingModal={onHideModal}
            rounds={rounds}
            bookingId={addBooking && booking?.bookingId}
            saveBookingForAvailableSlot={saveBookingForAvailableSlot}
            user={user}
            booking={addBooking && booking}
            panelMemberAvailabilityPayload={panelMemberAvailabilityPayload}
            email={email}
            weekDate={weekDate}
          />
        )}
    </>
  );
};

export default BookingOverlay;
