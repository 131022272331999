import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IOSSwitch } from "../../../components/Switch/IoSSwitch";
import AvailabilityReminderListModal from "./AvailabilityReminderListModal";
import WeekendAvailability from "./Weekend/WeekendAvailability";
import MarkAvailability from "./MarkAvailability";
import FilterView from "./FilterView";
import AvailabilityHeaderTabs from "./AvailabilityHeaderTabs";

import { resetAvailabilityFilterStatus } from "../../../redux/features/Availability/availabilitySlice";
import {getpanelGroupList} from "../../../redux/features/PanelGroup/panelGroup";

import { Grid } from "@mui/material";

import { startOfWeek, endOfWeek, isThisWeek, isThisMonth } from "date-fns";

import {
  ROLE_TYPES,
  SLOT_FILTER_OPTIONS,
  ROUND_FILTER_OPTIONS,
} from "../../../constants/index";

const { isHr, isSpoc, isAdmin, isPanelMember } = ROLE_TYPES;

const AvailabilityHeader = ({
  activePageNumber,
  setActivePageNumber,
  keyValue,
  weekendDriveDate,
  setWeekendDriveDate,
  startWeekDate,
  endWeekDate,
  weekDate,
  setWeekDate,
  setStartWeekDate,
  setEndWeekDate,
  loginInfo,
  competencyId,
  setCompetencyId,
  isMarkAvailabilityModalOpen,
  setIsMarkAvailabilityModalOpen,
  roundDesignationId,
  searchValue,
  setSearchValue,
  slotsProvided,
  setSlotsProvided,
  roundName,
  setRoundName,
  activedesignationId,
  setActiveDesignationId,
  panelGroupId,
  setPanelGroupId,
  subCompetency,
  setSubCompetency,
  filterIcon,
  setFilterIcon,
  designationIds,
  setDesignationIds,
  eligibleDesignationList,
  loggedInUserPanelDetails,
  signal,
  stateToogle,
  setStateToogle,
  setShowSearch,
  setPlaceholderName,
  setKeyValue,
  activeAccordion,
  setActiveAccordion,
  getDriveDates,
  weekendDriveSelected,
  setWeekendDriveSelected,
  selectedRecruiters,
  setSelectedRecruiters,
  weekenddesignationIds,
  optionsRecruiterEmail,
  setOptionsRecruiterEmail,
  weekendDriveList,
  startMonthDate,
  endMonthDate,
  monthDate,
  setMonthDate,
  setStartMonthDate,
  setEndMonthDate,
  searchParams,
  setSearchParams,
  providedSlots,
  roundsFilter,
  eligibleDesignationIds,
  interviewerDesignationIds,
  tagsFilter,
  subCompetencies,
}) => {
  let dispatch = useDispatch();
  const designations = useSelector(
    (state) => state?.rosterReducer?.activeDesignation
  );
  const weekdaysAvailabilityReminderStatus = useSelector(
    (state) => state?.availabilityReducer?.weekdaysAvailabilityReminderStatus
  );

  const [show, setShow] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [
    weekdaysAvailabilityReminderToggle,
    setWeekdaysAvailabilityReminderToogle,
  ] = useState(false);
  const [showAvailabilityBtn, setShowAvailabilityBtn] = useState(false);
  const [showSendReminderBtn, setShowSendReminderBtn] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (show) {
      setWeekdaysAvailabilityReminderToogle(weekdaysAvailabilityReminderStatus);
      setShowSendReminderBtn(weekdaysAvailabilityReminderStatus);
    }
  }, [
    setWeekdaysAvailabilityReminderToogle,
    weekdaysAvailabilityReminderStatus,
    show,
    loginInfo.current_role_competency_id,
  ]);
  useEffect(()=>{
    if(filterIcon){
      dispatch(
        getpanelGroupList({
          roleId: loginInfo.role_id,
          competencyId: loginInfo.current_role_competency_id,
        })
      );
    }
  },[filterIcon]);

  const onAvailabilityButtonClick = (e) => {
    setShowAvailabilityBtn(true);
  };
  let designationsArray = [];
  for (const designation in designations) {
    designationsArray.push(
      designations[designation].name +
        ":" +
        designation +
        ":" +
        designations[designation].abbreviatedName
    );
  }
  designationsArray = designationsArray.sort();
  let designationData = designationsArray.reduce((acc, item) => {
    acc[" " + item.split(":")[1]] = {
      name: item.split(":")[0],
      abbreviatedName: item.split(":")[2],
    };
    return acc;
  }, {});

  const setCurrentWeek = () => {
    const todayDate = new Date();
    setWeekDate(todayDate);
    setStartWeekDate(startOfWeek(todayDate, { weekStartsOn: 0 }));
    setEndWeekDate(endOfWeek(todayDate, { weekStartsOn: 0 }));
  };
  const setCurrentMonth = () => {
    const todayDate = new Date();
    setMonthDate(todayDate);
    setStartMonthDate(
      new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
    );
    setEndMonthDate(
      new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0)
    );
  };
  const currentMonthButtonDisable = () => {
    return isThisMonth(startMonthDate);
  };
  const currentWeekButtonDisable = () => {
    return isThisWeek(startWeekDate);
  };
  const switchPreviousWeek = () => {
    const date = new Date(weekDate);
    const preWeekDate = new Date(date.setDate(date.getDate() - 7));
    setStartWeekDate(startOfWeek(preWeekDate, { weekStartsOn: 0 }));
    setEndWeekDate(endOfWeek(preWeekDate, { weekStartsOn: 0 }));
    setWeekDate(preWeekDate);
  };
  const switchNextWeek = () => {
    const date = new Date(weekDate);
    const nextWeekDate = new Date(date.setDate(date.getDate() + 7));
    setStartWeekDate(startOfWeek(nextWeekDate, { weekStartsOn: 0 }));
    setEndWeekDate(endOfWeek(nextWeekDate, { weekStartsOn: 0 }));
    setWeekDate(nextWeekDate);
  };
  const handleChange = () => {
    setIsMarkAvailabilityModalOpen(true);
  };
  const updateWeekdaysAvailabilityReminderStatus = (e) => {
    if (
      loginInfo.role_id &&
      (isHr(loginInfo.current_role) || isSpoc(loginInfo.current_role))
    ) {
      setWeekdaysAvailabilityReminderToogle(e.target.checked);
      setIsChanged(true);
    }
  };
  const handleTabsChange = (event, newValue) => {
    setActiveAccordion(newValue);
    setCompetencyId(
      isAdmin(loginInfo.current_role)
        ? ""
        : loginInfo.current_role_competency_id
    );
    setActivePageNumber(1);
    setSubCompetency([]);
    setPanelGroupId([]);
    setRoundName(ROUND_FILTER_OPTIONS.ALL);
    setSlotsProvided(SLOT_FILTER_OPTIONS.ALL);
    setDesignationIds([]);
    setActiveDesignationId([]);
    setIsFilterApplied(false);
    setSelectedRecruiters([]);
    setSearchValue("");
    if (newValue === 0) {
      setKeyValue("weekdays");
      setSearchParams({ tab: "weekdays" });
      setShowSearch(!isPanelMember(loginInfo?.current_role) ? true : false);
      setSearchValue("");
      setPlaceholderName("Search by name or email");
    } else if (newValue === 1) {
      setKeyValue("weekend");
      setSearchParams({ tab: "weekend" });
      setShowSearch(!isPanelMember(loginInfo?.current_role) ? true : false);
      setSearchValue("");
      setPlaceholderName("Search by name or email");
    }
    dispatch(resetAvailabilityFilterStatus())
  };

  return (
    <Grid container alignItems="center" spacing={0.5}>
      <AvailabilityHeaderTabs
        onAvailabilityButtonClick={onAvailabilityButtonClick}
        setWeekendDriveDate={setWeekendDriveDate}
        getDriveDates={getDriveDates}
        handleTabsChange={handleTabsChange}
        weekendDriveDate={weekendDriveDate}
        setActivePageNumber={setActivePageNumber}
        activeAccordion={activeAccordion}
        switchPreviousWeek={switchPreviousWeek}
        setWeekendDriveSelected={setWeekendDriveSelected}
        startWeekDate={startWeekDate}
        loginInfo={loginInfo}
        endWeekDate={endWeekDate}
        switchNextWeek={switchNextWeek}
        setCurrentMonth={setCurrentMonth}
        setCurrentWeek={setCurrentWeek}
        currentMonthButtonDisable={currentMonthButtonDisable}
        currentWeekButtonDisable={currentWeekButtonDisable}
        loggedInUserPanelDetails={loggedInUserPanelDetails}
        handleChange={handleChange}
        setShow={setShow}
        isFilterApplied={isFilterApplied}
        setFilterIcon={setFilterIcon}
        filterIcon={filterIcon}
        stateToogle={stateToogle}
        setStateToogle={setStateToogle}
      />

      {isMarkAvailabilityModalOpen && (
        <MarkAvailability
          loginInfo={loginInfo}
          startDateOfWeek={startWeekDate}
          endDateOfWeek={endWeekDate}
          setStartWeekDate={setStartWeekDate}
          setEndWeekDate={setEndWeekDate}
          isMarkAvailabilityModalOpen={isMarkAvailabilityModalOpen}
          setIsMarkAvailabilityModalOpen={setIsMarkAvailabilityModalOpen}
          roundDesignationId={designationIds}
          activePageNumber={activePageNumber}
          searchValue={searchValue}
          slotsProvided={slotsProvided}
          roundName={roundName}
          activedesignationId={activedesignationId}
          panelGroupId={panelGroupId}
          subCompetency={subCompetency}
          loggedInUserPanelDetails={loggedInUserPanelDetails}
          signal={signal}
          startMonthDate={startMonthDate}
          endMonthDate={endMonthDate}
          monthDate={monthDate}
          setMonthDate={setMonthDate}
          setStartMonthDate={setStartMonthDate}
          setEndMonthDate={setEndMonthDate}
        />
      )}
      {filterIcon && (
        <FilterView
          keyValue={keyValue}
          setKeyValue={setKeyValue}
          loginInfo={loginInfo}
          competencyId={competencyId}
          setCompetencyId={setCompetencyId}
          startDateOfWeek={startWeekDate}
          endDateOfWeek={endWeekDate}
          activePageNumber={activePageNumber}
          setActivePageNumber={setActivePageNumber}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          slotsProvided={slotsProvided}
          setSlotsProvided={setSlotsProvided}
          roundName={roundName}
          setRoundName={setRoundName}
          activedesignationId={activedesignationId}
          setActiveDesignationId={setActiveDesignationId}
          panelGroupId={panelGroupId}
          setPanelGroupId={setPanelGroupId}
          subCompetency={subCompetency}
          setSubCompetency={setSubCompetency}
          filterIcon={filterIcon}
          setFilterIcon={setFilterIcon}
          designationIds={designationIds}
          setDesignationIds={setDesignationIds}
          eligibleDesignationList={eligibleDesignationList}
          signal={signal}
          setIsFilterApplied={setIsFilterApplied}
          weekendDriveDate={weekendDriveDate}
          weekendDriveSelected={weekendDriveSelected}
          selectedRecruiters={selectedRecruiters}
          setSelectedRecruiters={setSelectedRecruiters}
          weekenddesignationIds={weekenddesignationIds}
          optionsRecruiterEmail={optionsRecruiterEmail}
          setOptionsRecruiterEmail={setOptionsRecruiterEmail}
          providedSlots={providedSlots}
          roundsFilter={roundsFilter}
          eligibleDesignationIds={eligibleDesignationIds}
          interviewerDesignationIds={interviewerDesignationIds}
          tagsFilter={tagsFilter}
          subCompetencies={subCompetencies}
        />
      )}
      {show && (
        <AvailabilityReminderListModal
          setShow={setShow}
          show={show}
          designationData={designationData}
          loginInfo={loginInfo}
          IOSSwitch={IOSSwitch}
          weekdaysAvailabilityReminderToggle={
            weekdaysAvailabilityReminderToggle
          }
          updateWeekdaysAvailabilityReminderStatus={
            updateWeekdaysAvailabilityReminderStatus
          }
          setWeekdaysAvailabilityReminderToogle={
            setWeekdaysAvailabilityReminderToogle
          }
          showSendReminderBtn={showSendReminderBtn}
          setShowSendReminderBtn={setShowSendReminderBtn}
          isChanged={isChanged}
          setIsChanged={setIsChanged}
        />
      )}
      {showAvailabilityBtn &&
        loggedInUserPanelDetails.user_details_id &&
        (isPanelMember(loginInfo.current_role) ||
          isSpoc(loginInfo.current_role)) && (
          <WeekendAvailability
            show={showAvailabilityBtn}
            setShow={setShowAvailabilityBtn}
            loginInfo={loginInfo}
            competencyId={competencyId}
            roundDesignationId={roundDesignationId}
            activePageNumber={activePageNumber}
            searchValue={searchValue}
            slotsProvided={slotsProvided}
            roundName={roundName}
            activedesignationId={activedesignationId}
            weekendDriveList={weekendDriveList}
            driveDate={weekendDriveDate}
            keyValue={keyValue}
            panelGroupId={panelGroupId}
            weekendDriveSelected={weekendDriveSelected}
            subCompetency={subCompetency}
            selectedRecruiters={selectedRecruiters}
            setActivePageNumber={setActivePageNumber}
          />
        )}
    </Grid>
  );
};

export default AvailabilityHeader;
