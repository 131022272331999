import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../components/SvgIcon/icon";
import ExportActiveDataModal from "./ExportActiveDataModal";
import StayOnHoverOverlay from "../../../components/StayOnHoverOverlay";

import {
  resetRosterFilterStatus,
  getCompetencyIdForFilters,
} from "../../../redux/features/Roster/rosterSlice";

import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Tooltip,
  Divider,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";

import FilteredIcon from "../../../assets/icons/filtered-icon.svg";
import UnFilteredIcon from "../../../assets/icons/unfiltered.svg";

import {
  ROLE_TYPES,
  ROSTER_FILTER_TAB_CHECK_VALUE,
  LEGEND_OPTIONS_COLORS,
  LEGEND_OPTIONS,
} from "../../../constants";

import styles from "./style.module.scss";
import themes from "../../../theme/index";

const { isSpoc, isHr, isAdmin } = ROLE_TYPES;

const ColorChip = styled(Chip)({
  marginRight: "8px",
  marginBottom: "4px",
  width: "17px",
  height: "17px",
});

function ColorDisplay({ colorName, colorValue }) {
  return <ColorChip style={{ backgroundColor: colorValue }} />;
}

const TabsList = ({
  tabTitle,
  user,
  activeAccordion,
  handleChange,
  keyvalue,
  showAddTagModal,
  setShowAddTagModal,
  filtericon,
  setFiltericon,
  isFilterApplied,
  competencyList,
  competencyId,
  sortBy,
  roundName,
  designationIds,
  searchValue,
  activedesignationId,
  joiningDate,
  panelGroupId,
  subcompetency,
  searchParams,
}) => {
  let dispatch = useDispatch();
  const filterStatus = useSelector(
    (state) => state.rosterReducer?.isFilterApplied
  );
  const competencyIdState = useSelector(
    (state) => state.rosterReducer?.roster?.competencyIdState
  );

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    if (
      !isAdmin(user?.current_role) &&
      keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL &&
      searchParams.get("tabs") === ROSTER_FILTER_TAB_CHECK_VALUE.ALL
    ) {
      if (competencyIdState == "") {
        dispatch(
          resetRosterFilterStatus(!isAdmin(user?.current_role) ? true : false)
        );
        dispatch(getCompetencyIdForFilters(user?.current_role_competency_id));
      }
    }
  }, [keyvalue, user]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2} md={2} lg={2} xl={2}>
          <Typography variant="h6" className={"heading"}>
            {tabTitle()}
          </Typography>
        </Grid>
        <Grid container xs={5} md={5} lg={5} xl={5}>
          <Grid item xs={5.8} md={5.8} lg={5.8} xl={5.8}>
            <Tabs
              value={activeAccordion}
              onChange={handleChange}
              aria-label="tab"
              className={
                isHr(user.current_role) || isSpoc(user.current_role)
                  ? styles.tabshr
                  : styles.Tabs
              }
              sx={{
                minHeight: "30px",
                height: "30px",
                "& button": {
                  fontWeight: 400,
                  borderRadius: "19.5px",
                  height: "30px",
                  minHeight: "30px",
                  width: "105.25px",
                },
                "& button.Mui-selected": {
                  backgroundColor: "#FFFFFF",
                  color: themes.palette.secondary.main,
                  width: "115px",
                  fontWeight: 500,
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#D97D54",
                  height: 0,
                  top: 0,
                },
              }}
            >
              <Tab label="All" {...a11yProps(0)} className={styles.Tab} />
              <Tab label="Active" {...a11yProps(1)} className={styles.Tab} />
              {(isHr(user.current_role) || isSpoc(user.current_role)) && (
                <Tab
                  label="Suspended"
                  {...a11yProps(2)}
                  className={styles.Tab}
                />
              )}
              {(isHr(user.current_role) || isSpoc(user.current_role)) && (
                <Tab label="Tags" {...a11yProps(3)} className={styles.Tab} />
              )}
            </Tabs>
          </Grid>
        </Grid>

        {keyvalue !== ROSTER_FILTER_TAB_CHECK_VALUE.SUSPENDED && (
          <Grid
            container
            xs={5}
            md={5}
            lg={5}
            xl={5}
            alignItems="center"
            justifyContent="flex-end"
            spacing={0.5}
          >
            {keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.TAGS ? (
              <>
                {isSpoc(user.current_role) && (
                  <Grid alignItems={"center"}>
                    <Tooltip title="Add Tag" placement="top" arrow>
                      <Box
                        className={styles.addTagStyle}
                        component={"button"}
                        onClick={() => setShowAddTagModal(!showAddTagModal)}
                      >
                        <AddIcon />
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
              </>
            ) : (
              <>
                {(isHr(user.current_role) || isSpoc(user.current_role)) &&
                  keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ALL && (
                    <StayOnHoverOverlay
                      title={
                        <>
                          <Typography variant="sub1" fontSize={14}>
                            Legend
                          </Typography>
                          <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
                          <Grid container rowGap={1}>
                            {LEGEND_OPTIONS_COLORS.map((color, index) => (
                              <Grid item key={index}>
                                <ColorDisplay colorValue={color.value} />
                                <Typography
                                  sx={{
                                    display: "inline",
                                    color: "#404040",
                                    marginRight: 2,
                                  }}
                                  variant="sub1"
                                >
                                  {LEGEND_OPTIONS[index]}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      }
                    >
                      <InfoOutlinedIcon
                        className={`${styles.legendIcon} cursor-pointer`}
                      />
                    </StayOnHoverOverlay>
                  )}

                <Grid
                  item
                  xs={1}
                  md={1}
                  lg={1}
                  xl={1}
                  marginTop={0.4}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Tooltip title="Filter" placement="top" arrow>
                    <Box
                      className={
                        filterStatus
                          ? styles.filteredStyles
                          : styles.unfilteredStyles
                      }
                      onClick={() => {
                        setFiltericon(!filtericon);
                        if (
                          !isAdmin(user?.current_role) &&
                          competencyIdState == "" &&
                          searchParams.get("tabs") ===
                            ROSTER_FILTER_TAB_CHECK_VALUE.ALL
                        ) {
                          dispatch(
                            resetRosterFilterStatus(
                              !isAdmin(user?.current_role) ? true : false
                            )
                          );
                          dispatch(
                            getCompetencyIdForFilters(
                              user?.current_role_competency_id
                            )
                          );
                        } else {
                          if (!filterStatus) {
                            dispatch(resetRosterFilterStatus(false));
                          }
                        }
                      }}
                    >
                      <Icon
                        icon={filterStatus ? FilteredIcon : UnFilteredIcon}
                        width={"15px"}
                        height={"16.5px"}
                        color={filterStatus ? "#FFFFFF" : null}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
                {(isHr(user.current_role) || isSpoc(user.current_role)) &&
                  keyvalue === ROSTER_FILTER_TAB_CHECK_VALUE.ACTIVE && (
                    <Grid
                      item
                      xs={1}
                      md={1}
                      lg={1}
                      xl={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ExportActiveDataModal
                        competencyList={competencyList}
                        competencyId={competencyId}
                        sortBy={sortBy}
                        roundName={roundName}
                        designationIds={designationIds}
                        searchValue={searchValue}
                        activeDesignations={activedesignationId}
                        dateOfJoining={joiningDate}
                        panelGroupId={panelGroupId}
                        subCompetency={subcompetency}
                      />
                    </Grid>
                  )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TabsList;
