import React from "react";

import { Box, Button, Typography } from "@mui/material";

import NoDataIcon from "../../../assets/images/Group 8497.png";

import styles from "../NoData/style.module.scss";


const CentralError = () => {

  return (
    <Box className={styles.boxContainer}>
      <img src={`${NoDataIcon}`} alt="nodata" />
      <Typography className={styles.noData}>
        Something went wrong
      </Typography>
      <Button
        variant="outlined"
        className={styles.BackBtn}
        disabled={false}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Typography className={styles.btnText}>
          <a href="/" style={{ textDecoration: 'none', color: '#DE1186' }}> Refresh </a>
        </Typography>
      </Button>
    </Box>
  );
};

export default CentralError;
