import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AutoCompleteComponent from "../../../components/AutoComplete";

import { createRole } from "../../../redux/features/Admin/adminSlice";

import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
} from "@mui/material";

import closeIcon from "../../../assets/icons/close-icon.svg";

import { ROLES, ROLE_TYPES } from "../../../constants/index";

import styles from "./style.module.scss";
import theme from "../../../theme/index";

const { isAdmin, isHr, isSpoc } = ROLE_TYPES;

const AssignUserRoleModal = ({
  show,
  setShow,
  user,
  competencyList,
  roleType,
  setRoleType,
}) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [sortedCompetencyList, setSortedCompetencyList] = useState([]);
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);
  const [competencyId, setCompetencyId] = useState([
    {
      value: `${user.competency_id}`,
      label: competencyList[user.competency_id],
    },
  ]);

  useEffect(() => {
    return () => {
      setRoleType("");
    };
  }, [setRoleType]);
  useEffect(() => {
    setCompetencyId(
      isAdmin(roleType) ? [{ value: "1", label: "ADMIN" }] : competencyId
    );
  }, [roleType]);
  useEffect(() => {
    if (isHr(roleType) || isSpoc(roleType)) {
      const rolesData = user.role.filter((item) => item.user_role === roleType);
      setIsManager(user.ismanager);
      if (rolesData?.length) {
        let competencyOptions = rolesData?.map((item) => ({
          value: `${item.role_competency_id}`,
          label: competencyList[item.role_competency_id], // Use item.role_competency_id to fetch the correct label
        }));
        setCompetencyId(competencyOptions);
      }
    }
  }, [competencyList, roleType, user.ismanager, user.role]);
  useEffect(() => {
    setSortedCompetencyList(getSortedCompetencyList(competencyList));
  }, [competencyList]);

  const handleClose = () => setShow(false);
  const onFormSubmit = () => {
    if (competencyId.length > 0 && roleType) {
      dispatch(
        createRole({
          roleType,
          competencyId,
          userId: user.id,
          roleId: loginInfo.role_id,
          isManager: isManager,
        })
      );
      setError(false);
      setShow(false);
    } else {
      setError(true);
    }
  };
  const getSortedCompetencyList = (competencyList) => {
    let compListArray = [];
    for (const competency in competencyList) {
      compListArray.push({
        label: competencyList[competency],
        value: competency,
      });
    }
    compListArray = compListArray.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    return compListArray;
  };
  function handleCompetencySelect(e) {
    setCompetencyId(e);
  }
  return (
    <>
      <>
        <Dialog
          open={show}
          onClose={handleClose}
          PaperProps={{
            style: {
              height: "26rem",
              width: "35rem",
              minHeight: "30vh",
              maxHeight: "100vh",
              zIndex: 1300,
              borderRadius: "10px",
              boxShadow: "0px 6px 30px 0px #OD",
            },
          }}
        >
          <DialogTitle
            color={theme.palette.secondary.main}
            sx={{ padding: "30px 24px 0px" }}
          >
            Assign User Role
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 18,
              top: 30,
              color: (theme) => theme.palette.grey[500],
            }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <img src={closeIcon} alt="close-icon" />
          </IconButton>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  defaultValue={`${user.first_name} ${user.last_name}`}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <TextField
                  label="Competency"
                  variant="outlined"
                  fullWidth
                  defaultValue={user.competency_name}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <FormControl fullWidth>
                  <InputLabel id="role-label">Role*</InputLabel>
                  <Select
                    error={error}
                    fullWidth
                    labelId="role-label"
                    id="role-label"
                    placeholder="Role"
                    label="Role"
                    value={roleType}
                    multiline
                    displayEmpty
                    className={styles.roleInputStyle}
                    onChange={(event) => {
                      if (isAdmin(roleType))
                        setCompetencyId([
                          {
                            value: `${user.competency_id}`,
                            label: competencyList[user.competency_id],
                          },
                        ]);
                      setRoleType(event.target.value);
                    }}
                    required
                  >
                    {Object.values(ROLES)?.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {roleType === ROLES?.HR && (
                <Grid item xl={6} md={6} sx={{ display: "flex" }}>
                  <Checkbox
                    onChange={(e) => setIsManager(e.target.checked)}
                    checked={isManager}
                  />
                  <Typography
                    marginTop={1.5}
                    className={styles.designationList}
                  >
                    Is Manager?
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={12} xl={12} marginTop={0.5}>
                <AutoCompleteComponent
                  options={sortedCompetencyList}
                  disabled={isAdmin(roleType) ? true : false}
                  label={"Select Competency*"}
                  placeholder={"Competency"}
                  onChange={handleCompetencySelect}
                  selectedValue={competencyId}
                  toDisable={isAdmin(roleType)  && sortedCompetencyList}
                  chipDisabled={isAdmin(roleType)  && true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: "0px 24px 30px" }}>
            <Button
              fullWidth
              disabled={!(competencyId.length >= 1 && roleType)}
              className={
                !(competencyId.length >= 1 && roleType)
                  ? `${styles.saveBtnDisable}`
                  : `${styles.saveBtn}`
              }
              variant={"contained"}
              onClick={onFormSubmit}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              Create role
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default AssignUserRoleModal;
