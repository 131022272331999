import React from "react";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
} from "@mui/material";

import styles from "../style.module.scss";

function SlotCard({ icon, type, numberOfSlots, slotTitle }) {
  return (
    <Box marginTop={2}>
      <Card variant="outlined" className={`${styles.border} ${styles.shadow}`}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CardMedia
                image={icon}
                className={styles.slotCardIconBackground}
                sx={{ backgroundColor: type }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5" color="#3E2B82">
                {numberOfSlots}
              </Typography>
              <Typography color="#2E1C41">{slotTitle}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default SlotCard;
