import React from "react";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import { Toolbar, Box } from "@mui/material";

import { Toaster } from "react-hot-toast";

import styles from "./style.module.scss";

const Layout = (props) => {
  const { open, setOpen } = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Toaster />
      <Box display={"flex"}>
        <Navbar props={props} open={open} handleDrawerOpen={handleDrawerOpen} />
        <Sidebar
          props={props}
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />
        <Box
          component="main"
          className={open ? styles.openlayout : styles.layout}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
