import React, { useState } from "react";
import { useDispatch } from "react-redux";

import DatePickerComponent from "../../../components/DatePickerComponent";

import { changedateOfJoiningStatus } from "../../../redux/features/Roster/rosterSlice";

import {
  Modal,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close-icon.svg";

import { format } from "date-fns";

import { JOINING_DATE_VALUES } from "../../../constants";

import styles from "./style.module.scss";

const CustomDateModal = ({ show, setShow, setJoiningDate }) => {
  const dispatch= useDispatch();

  const [error, setError] = useState(false);
  const [periodFrom, setPeriodFrom] = useState(
    format(new Date().setDate(new Date().getDate() - 365), "yyyy-MM-dd")
  );
  const [periodTo, setPeriodTo] = useState(format(new Date(), "yyyy-MM-dd"));

  const handleClose = (e) => {
    setShow(false);
  };
  const validateForm = () => {
    let error = false;
    let d_period_from = periodFrom;
    let d_period_end = periodTo;
    let min_date = format(new Date().setFullYear(2015, 12, 31), "yyyy-MM-dd");
    if (
      !(
        d_period_from >= min_date &&
        d_period_end >= min_date &&
        d_period_from <= d_period_end
      )
    ) {
      error = true;
    }
    return error;
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    setJoiningDate({ from: periodFrom, to: periodTo });
    const isInvalid = validateForm();
    setError(isInvalid);
    dispatch(changedateOfJoiningStatus({date:{ from: new Date(periodFrom).toISOString(), to: new Date(periodTo).toISOString() },value:JOINING_DATE_VALUES.CUSTOM}));
    handleClose();
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        className={`${styles.modalBox} ${styles.modalSizeDate} ${styles.paddingdateBox}`}
      >
        <Typography color={"#2E1C41"} variant="h6" marginTop={1}>
          Select Date Of Joining
        </Typography>
        <IconButton
          onClick={(e) => handleClose(e)}
          sx={{ position: "absolute", right: 8, top: 17 }}
        >
          <img src={CloseIcon} alt="close-icon" />
        </IconButton>
        {error && <p style={{ color: "red" }}>Select Correct Date.</p>}
        <form>
          <Grid container direction={"column"} spacing={2}>
            <Grid item marginTop={2}>
              <DatePickerComponent
                label="Date of Joining From"
                value={periodFrom}
                handleChange={setPeriodFrom}
              />
            </Grid>
            <Grid item>
              <DatePickerComponent
                label="Date of Joining To"
                value={periodTo}
                handleChange={setPeriodTo}
              />
            </Grid>
          </Grid>
        </form>
        <Grid
          container
          spacing={1}
          className={styles.marginTop}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Button
              type="submit"
              onClick={(e) => onFormSubmit(e)}
              variant="contained"
              color="primary"
              fullWidth
              className={`${styles.searchBtn} ${styles.addUserRoleButtonStyle}`}
              sx={{ borderRadius: "5px" }}
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disabled={
                !periodFrom ||
                !periodTo ||
                new Date(periodFrom) > new Date(periodTo)
              }
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CustomDateModal;
