import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Layout from "../Layout";
import Roster from "./Roster";
import Reports from "./Reports";
import Document from "./DocumentRepository";
import Dashboard from "./Dashboard/index";
import Availability from "./Availability/index";
import Designations from "./Designations";
import ReportsConfig from "./ReportsConfiguration";
import ManageDL from "./ManageDL";
import WeekendDrive from "./WeekendDrive/index";
import NotFoundPage from "./NotFound/index"
import { getMenuItems } from "../../constants/index"
import ErrorBoundary from '../Main/Error/ErrorBoundary';

import { resetAdminFilterStatus } from "../../redux/features/Admin/adminSlice";
import { resetDriveFilterStatus } from "../../redux/features/WeekendDrive/weekendDriveSlice";
import { resetAvailabilityFilterStatus } from "../../redux/features/Availability/availabilitySlice";
import { resetRosterFilterStatus } from "../../redux/features/Roster/rosterSlice";

import { ROLE_TYPES } from "../../constants/index";
const {isAdmin} = ROLE_TYPES;

const Main = () => {
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [signal, setSignal] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [placeholderName, setPlaceholderName] = useState(
    "Search by name or email"
  );
  
  const dispatch=useDispatch();
  const location=useLocation();
  const loginInfo = useSelector((state) => state.authReducer?.loginInfo);

  const [prevPathname, setPrevPathname] = useState(null); 

  useEffect(() => {
    setPrevPathname(location.pathname);
  }, []); 

  useEffect(() => {
    if (prevPathname !== null && location.pathname !== prevPathname) {
      dispatch(resetAdminFilterStatus());
      dispatch(resetDriveFilterStatus());
      dispatch(resetAvailabilityFilterStatus())
      dispatch(resetRosterFilterStatus(false))
      setPrevPathname(location.pathname);
    }
  }, [dispatch, location.pathname, prevPathname]); 

  const handleSearchChange = (searchTerm) => {
    setSearch(searchTerm);
  };

  const allowedRoutes = getMenuItems(loginInfo?.current_role)?.map((item) => item.key);

  return (
    <div>
      <Layout
        onSearchChange={handleSearchChange}
        searchValue={search}
        showSearch={showSearch}
        placeholderName={placeholderName}
        setActivePageNumberForSearch={setActivePageNumber}
        setSignal={setSignal}
        setShowSearch={setShowSearch}
        open={open}
        setOpen={setOpen}
      >
        <ErrorBoundary>
        <Routes>
          {
            allowedRoutes.includes('ROSTER') && (
              <Route
                path="roster"
                element={
                  <Roster
                    searchValue={search}
                    setSearchValue={setSearch}
                    setShowSearch={setShowSearch}
                    setPlaceholderName={setPlaceholderName}
                    activePageNumber={activePageNumber}
                    setActivePageNumber={setActivePageNumber}
                    signal={signal}
                  />
                }
              />
            )
          }
          {
            allowedRoutes.includes('AVAILABILITY') && (
              <Route
                path="availability"
                element={
                  <Availability
                    searchValue={search}
                    setSearchValue={setSearch}
                    setShowSearch={setShowSearch}
                    setPlaceholderName={setPlaceholderName}
                    activePageNumber={activePageNumber}
                    setActivePageNumber={setActivePageNumber}
                    signal={signal}
                    open={open}
                  />
                }
              />
            )
          }
          {
            allowedRoutes.includes('WEEKEND_DRIVE') && (
              <Route path="weekendDrive" element={<WeekendDrive />} />
            )
          }

          {
            allowedRoutes.includes('DOCUMENT_REPOSITORY') && (
              <Route path="documentrepository" element={<Document />} />
            )
          }

          {
            allowedRoutes.includes('REPORTS') && (
              <Route path="reports" element={<Reports />} />
            )
          }

          {
            allowedRoutes.includes('DASHBOARD') && (
              <Route path="dashboard" element={<Dashboard />} />
            )
          }

          {
            allowedRoutes.includes('DESIGNATIONS') && (
              <Route path="designation" element={<Designations />} />
            )
          }

          {
            allowedRoutes.includes("REPORTS_CONFIGURATIONS") && (
              <Route path="adminConfigdl" element={<ReportsConfig />} />
            )
          }

          {
            allowedRoutes.includes("MANAGE_DL") && (
              <Route path="adminTab" element={<ManageDL />} />
            )
          }

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        </ErrorBoundary>
      </Layout>
    </div>
  );
};

export default Main;