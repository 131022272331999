import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { Box, Button, Typography } from "@mui/material";

import NoDataIcon from "../../../assets/images/Group 8497.png";

import { ROLE_TYPES } from "../../../constants";

import styles from "./style.module.scss";

const { isAdmin, isPanelMember, isSpoc, isHr } = ROLE_TYPES;

const Index = ({ data, disabled = false }) => {
  const nav = useNavigate();
  const user = useSelector((state) => state?.authReducer?.loginInfo);

  const redirectTo = () => {
    let routeTo = "";
    if (isAdmin(user?.current_role) || isSpoc(user?.current_role)) {
      routeTo = "/main/roster?tabs=all";
    } else if (isHr(user?.current_role)) {
      routeTo = "/main/dashboard";
    } else if (isPanelMember(user?.current_role)) {
      routeTo = "/main/Availability";
    }
    return routeTo;
  };

  return (
    <Box className={styles.boxContainer}>
      <img src={`${NoDataIcon}`} alt="nodata" />
      <Typography className={styles.noData}>
        {data ? data : "NO DATA AVAILABLE"}
      </Typography>
      <Button
        variant="outlined"
        className={styles.BackBtn}
        onClick={() => nav(redirectTo())}
        disabled={disabled}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Typography className={disabled ? styles.disableText : styles.btnText}>
          BACK TO HOME
        </Typography>
      </Button>
    </Box>
  );
};

export default Index;
