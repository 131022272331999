import React, { useState } from "react";
import { useDispatch } from "react-redux";

import EditDlConfigModal from "./CreateConfigDL.js";
import Icon from "../../../components/SvgIcon/icon";
import Spinner from "../../../components/Spinner/index.js";
import Pagination from "../../../components/Pagination/index.js";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal.js";

import {
  configDeleteDistributionListById,
  configFetchAllUserListForAdmin,
} from "../../../redux/features/Admin/adminSlice.js";

import {
  Typography,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  Grid,
  Chip,
} from "@mui/material";

import EditIcon from "../../../assets/icons/edit-selected-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";

import styles from "./style.module.scss";

const ReportsConfigTable = ({
  reportType,
  competencyId,
  selectedUserEmailId,
  setErrUserEmail,
  checkWeekType,
  setActivePageNumber,
  activePageNumber,
  pageCount,
  userCount,
  dlEmailList,
  setEditDlEmailId,
  loginInfo,
  setSelectedDlEmailId,
  setReportType,
  setSelectedOption,
  setCompetencyId,
  selectedDlEmailId,
  setSelectedUserEmailId,
  optionsFilterByCompetency,
  competencyIdFilter,
  editDlEmailId,
  DlEmail,
  DriveReportFilter,
  UserEmail,
  CompetencyFilter,
  showCreateConfigModal,
  setShowCreateConfigModal,
  loading,
}) => {
  const dispatch = useDispatch();

  const [selectedDl, setSelectedDl] = useState(null);
  const [showdeleteDlConfigModal, setShowdeleteDlConfigModal] = useState(false);

  const handlePageClick = (e, value) => {
    setActivePageNumber(value);
  };
  const handleConfigDLDelete = (user) => {
    dispatch(
      configDeleteDistributionListById({
        roleId: loginInfo.role_id,
        configId: user.config_id,
        pageNumber: 1,
        reportType: checkWeekType,
        competencyId: competencyIdFilter,
        dispatch,
      })
    );
    setActivePageNumber("1");
    setSelectedDl("");
  };
  const handleConfigDLEdit = (item) => {
    setShowCreateConfigModal(true);
    setEditDlEmailId(item.config_id);
    setReportType(item.report_type);
    setCompetencyId(item.competency_id);
    setSelectedDlEmailId(
      item?.ttn_mailing_emails?.length
        ? item?.ttn_mailing_emails.map(({ email, id }) => ({
            label: email,
            value: id,
          }))
        : []
    );
    setSelectedUserEmailId(
      item?.user_emails?.length
        ? item?.user_emails.map(({ email, id }) => ({
            label: email,
            value: id,
          }))
        : []
    );
    if (parseInt(item.competency_id) !== 1) {
      dispatch(
        configFetchAllUserListForAdmin({
          roleId: loginInfo.role_id,
          competencyId: parseInt(item.competency_id),
        })
      );
    }
  };

  const getItems = (user) => {
    return (
      <TableCell className={"tablebody"}>
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          spacing={1}
          display={"flex"}
        >
          <Grid item className={styles.tooltipAlign}>
            <Tooltip title="Edit Config DL Email" placement="top" arrow>
              <Typography>
                <Icon
                  icon={EditIcon}
                  width="20.71px"
                  height="16px"
                  leftPadding="16.2px"
                  rightPadding="16.2px"
                  click={() => handleConfigDLEdit(user)}
                />
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item className={styles.tooltipAlign}>
            <Tooltip title="Delete Config DL Email" placement="top" arrow>
              <Typography>
                <Icon
                  icon={DeleteIcon}
                  width="20.71px"
                  height="16px"
                  leftPadding="16.2px"
                  rightPadding="16.2px"
                  click={() => {
                    setShowdeleteDlConfigModal(true);
                    setSelectedDl(user);
                  }}
                />
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  if (loading) return <Spinner />;

  return (
    <Paper className={"globalTableLayoutStyle"}>
      <TableContainer
        className={"globalLayoutHeight globalTableContainerAlign"}
      >
        <Table aria-label="all-table" stickyHeader>
          <TableHead className={styles.tableHead}>
            <TableRow className={styles.tableRow}>
              <TableCell className={"tableheading"}>Report Type</TableCell>
              <TableCell className={"tableheading"}>Competency</TableCell>
              <TableCell className={"tableheading"} align="left">
                DL Email
              </TableCell>
              <TableCell className={"tableheading"} align="left">
                User Email
              </TableCell>

              <TableCell
                className={"tableheading"}
                align={"center"}
                paddingRight={5}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dlEmailList &&
              dlEmailList?.map((dlemail) => (
                <TableRow key={dlemail.id} sx={{ border: 0 }}>
                  <TableCell component="th" scope="row" className={"tablebody"}>
                    {dlemail.report_type
                      .toLowerCase()
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </TableCell>
                  <TableCell component="th" scope="row" className={"tablebody"}>
                    {dlemail.competency_name}
                  </TableCell>

                  <TableCell className={"tablebody"} align="left">
                    <Grid container>
                      {dlemail.ttn_mailing_emails ? (
                        dlemail.ttn_mailing_emails.map((mail, index) => (
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            key={index}
                            marginTop={0.5}
                          >
                            <Chip
                              label={`${mail.email}`}
                              sx={{
                                padding: 0,
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "12px",
                                borderRadius: "10px",
                                background: "#DE1186",
                                border: "1px solid #de1186",
                                color: "#fff",
                                fontWeight: 500,
                              }}
                              key={index}
                            />
                          </Grid>
                        ))
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell className={"tablebody"} align="left">
                    <Grid container flexWrap={"wrap"} spacing={0.5}>
                      {dlemail.user_emails?.map((mail, index) => (
                        <Grid item key={index}>
                          <Chip
                            label={`${mail.email}`}
                            className={`${styles.userEmailChip} `}
                            sx={{
                              padding: 0,
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "12px",
                              borderRadius: "10px",
                              background: "#fff",
                              border: "1px solid #de1186",
                              color: "#de1186",
                              fontWeight: 500,
                            }}
                            key={index}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>

                  {getItems(dlemail)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination
          pageCount={pageCount}
          userCount={userCount}
          handlePageClick={handlePageClick}
          activePageNumber={activePageNumber}
        />
      </TableContainer>
      {editDlEmailId !== null && showCreateConfigModal && (
        <EditDlConfigModal
          show={showCreateConfigModal}
          setShow={setShowCreateConfigModal}
          editDlEmailId={editDlEmailId}
          checkWeekType={checkWeekType}
          setActivePageNumber={setActivePageNumber}
          activePageNumber={activePageNumber}
          pageCount={pageCount}
          dlEmailList={dlEmailList}
          setEditDlEmailId={setEditDlEmailId}
          loginInfo={loginInfo}
          setSelectedDlEmailId={setSelectedDlEmailId}
          setReportType={setReportType}
          setSelectedOption={setSelectedOption}
          setCompetencyId={setCompetencyId}
          selectedDlEmailId={selectedDlEmailId}
          setSelectedUserEmailId={setSelectedUserEmailId}
          optionsFilterByCompetency={optionsFilterByCompetency}
          competencyIdFilter={competencyIdFilter}
          DlEmail={DlEmail}
          DriveReportFilter={DriveReportFilter}
          CompetencyFilter={CompetencyFilter}
          UserEmail={UserEmail}
          competencyId={competencyId}
          reportType={reportType}
          selectedUserEmailId={selectedUserEmailId}
          setErrUserEmail={setErrUserEmail}
        />
      )}
      {showdeleteDlConfigModal && (
        <DeleteConfirmationModal
          show={showdeleteDlConfigModal}
          setShow={setShowdeleteDlConfigModal}
          clickConfirm={() => handleConfigDLDelete(selectedDl)}
        />
      )}
    </Paper>
  );
};

export default ReportsConfigTable;
