import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, remove, put } from "../../../utils/app-utils";
import { BASE_URL } from "../../../constants/index";
import { toast } from "react-hot-toast";
import CustomToaster from "../../../components/Toaster/index";
import { TOAST_TYPE, TOAST_TIME, TOAST_TITLE } from "../../../constants/index";

const initialState = {
  loading: false,
  list: [],
  currentDocumentInfo: {},
};

export const fetchDocument = createAsyncThunk(
  "fetchDocument",
  async ({ competencyId, roleId }) => {
    try {
      const url = `${BASE_URL}/document/competency/${competencyId}`;
      const response = await get(url, { queryParams: { role_id: roleId } });
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const createDocument = createAsyncThunk(
  "createDocument",
  async ({ competencyId, documentInfo, roleId }, { dispatch }) => {
    try {
      const url = `${BASE_URL}/document/competency/${competencyId}`;
      const response = await post(
        url,
        { queryParams: { role_id: roleId } },
        { documentInfo }
      );
      dispatch(fetchDocument({ competencyId, roleId }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const deleteDocument = createAsyncThunk(
  "deleteDocument",
  async ({ documentId, competencyId, roleId }, { dispatch }) => {
    try {
      const url = `${BASE_URL}/document/${documentId}`;
      const response = await remove(url, { queryParams: { role_id: roleId } });
      dispatch(fetchDocument({ competencyId, roleId }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const updateDocumentsOrder = createAsyncThunk(
  "updateDocumentsOrder",
  async ({ toBeUpdateData, roleId, competencyId }, { dispatch }) => {
    try {
      const url = `${BASE_URL}/document/updateDocumentsOrder`;
      const response = await put(
        url,
        { queryParams: { role_id: roleId } },
        { toBeUpdateData, competencyId }
      );
      dispatch(fetchDocument({ roleId, competencyId }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
export const editDocumentByDocumentId = createAsyncThunk(
  "editDocumentByDocumentId",
  async ({ documentId, roleId, competencyId, label, link }, { dispatch }) => {
    try {
      const url = `${BASE_URL}/document/edit/${documentId}`;
      const response = await put(
        url,
        { queryParams: { role_id: roleId } },
        { label, link }
      );
      dispatch(fetchDocument({ roleId, competencyId }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
const documentSlice = createSlice({
  name: "Document",
  initialState,
  reducers: {
    resetDocumentState() {
      return {
        ...initialState,
      };
    },
    passingDocumentDetail(state, action) {
      state.currentDocumentInfo = {
        label: action.payload.label,
        link: action.payload.link,
        id: action.payload.id,
        competency_id: action.payload.competency_id,
        designation_id: action.payload.designation_id,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.list;
      })
      .addCase(fetchDocument.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error fetching document"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(createDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        if(action?.payload?.code === 409){
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={action?.payload?.message || 'Error while creating document'}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        }else{
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={action?.payload?.data?.message}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
        }
        state.loading = false;
      })
      .addCase(createDocument.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error occured while creating document"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error deleting document"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(updateDocumentsOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDocumentsOrder.fulfilled, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.SUCCESS}
            message={action?.payload?.data?.message}
            type={TOAST_TYPE.SUCCESS}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(updateDocumentsOrder.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Updating Link"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      })
      .addCase(editDocumentByDocumentId.pending, (state) => {
        state.loading = true;
      })
      .addCase(editDocumentByDocumentId.fulfilled, (state, action) => {
        if(action?.payload?.code === 409){
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.ERROR}
              message={action?.payload?.message || 'Error while editing document'}
              type={TOAST_TYPE.ERROR}
              time={TOAST_TIME.TWO}
            />
          );
        }else{
          toast.custom(
            <CustomToaster
              title={TOAST_TITLE.SUCCESS}
              message={action?.payload?.data?.message}
              type={TOAST_TYPE.SUCCESS}
              time={TOAST_TIME.TWO}
            />
          );
        }
        state.loading = false;
      })
      .addCase(editDocumentByDocumentId.rejected, (state, action) => {
        toast.custom(
          <CustomToaster
            title={TOAST_TITLE.ERROR}
            message="Error Updating Document Fields"
            type={TOAST_TYPE.ERROR}
            time={TOAST_TIME.TWO}
          />
        );
        state.loading = false;
      });
  },
});
export const { resetDocumentState, passingDocumentDetail } =
  documentSlice.actions;
export default documentSlice.reducer;
