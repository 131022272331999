import React, { forwardRef } from "react";

import { Alert, AlertTitle, Snackbar, Typography, Box } from "@mui/material";


import { TOAST_TYPE } from "../../constants";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

const CustomToaster = ({ title, message, type, time }) => {
  const [open, setOpen] = React.useState(true);

  let color;
  let AlertBorder;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  switch (type) {
    case "error":
      color = "#FDEEEC";
      AlertBorder = "#E7D2CA";
      break;

    case "success":
      color = "#F1F9F4";
      AlertBorder = "#D0E8D4";
      break;

    case "info":
      color = "#E7EEFA";
      AlertBorder = "#B6CCED";
      break;

    case "warning":
      color = "#FEF7E8";
      AlertBorder = "#F3E0BC";
      break;

    default:
      color = "#F1F9F4";
      break;
  }

  if (Object.values(TOAST_TYPE)?.includes(type)) {
    return (
      <Box>
        <Snackbar
          open={open}
          autoHideDuration={time}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarAlert
            onClick={handleClose}
            onClose={handleClose}
            severity={type}
            variant="outlined"
            sx={{ bgcolor: color, borderColor: AlertBorder, minWidth: "300px", maxWidth: '300px', alignItems: 'center' }}
            action={[]}
          >
            <Typography
              fontWeight="400px"
              fontSize="12px"
              lineHeight="18px"
              color="#2E1C41"
            >
              {message}
            </Typography>
          </SnackbarAlert>
        </Snackbar>
      </Box>
    );
  }
};

export default CustomToaster;
